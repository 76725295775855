import React, { useState } from 'react';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import InputField, { InputType } from 'src/components/shared/inputs/InputField';

import {
  AddEntryWrapper,
  FlexBox,
  InputWrapper,
  OptionWrapper,
  InputWrapperTopMargin8
} from './styles';
import { Tooltip, Box } from '@mui/material';
import MomentButton from '../shared/inputs/MomentButton';
import { MAX_HOURS_PER_DAY } from 'src/constants';
import {
  formatAsTitle,
  QUERY_DATE_FORMAT,
  roundToQuarterHour
} from 'src/shared/utils';
import { MomentDateSelect } from '../shared/inputs/MomentDateSelect';
import { Timesheet, TimesheetEntryClassification } from 'src/api/types';
import { CreateTimesheetEntryParams } from 'src/api/queries/createTimesheetEntry';

export interface IAddTimeOff {
  addEntry: (params: CreateTimesheetEntryParams) => void;
  timesheet: Timesheet | null;
  disabledDates: Moment[] | true[];
  selectedDates: Moment[];
  setDates: (dates: Moment[]) => void;
}

export const AddTimeOff = ({
  timesheet,
  addEntry,
  disabledDates,
  selectedDates,
  setDates
}: IAddTimeOff) => {
  const [hours, setHours] = useState(0);
  const [hoursDisabled, setHoursDisabled] = useState(false);
  const [classification, setClassification] = useState(
    TimesheetEntryClassification.PTO
  );
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleSubmit = () => {
    if (!timesheet) return;

    if (!canAdd) {
      setHasSubmitted(true);
      return;
    }

    setHasSubmitted(false);

    addEntry({
      hours: hours,
      classification,
      dates: selectedDates.map((mom: Moment) => mom.format(QUERY_DATE_FORMAT)),
      projectId: 1,
      timesheetId: timesheet.id
    });
    setHours(0);
    setHoursDisabled(false);
  };

  const dateIsValid =
    selectedDates.length &&
    _.every(selectedDates, (date: Moment) => date.isValid());

  const canAdd = dateIsValid && hours > 0;
  const errorTitle =
    hasSubmitted && !canAdd ? (
      <p>
        {!dateIsValid ? (
          <span>
            Date is Invalid
            <br />
          </span>
        ) : (
          ''
        )}
        {!hours ? (
          <span>
            Must have hours <br />
          </span>
        ) : (
          ''
        )}
      </p>
    ) : null;

  if (!timesheet) return null;

  const button = (
    <Box>
      <MomentButton
        title="Add Time"
        onClick={handleSubmit}
        disabled={hasSubmitted && !canAdd}
      />
    </Box>
  );

  return (
    <AddEntryWrapper>
      <FlexBox>
        <InputWrapperTopMargin8>
          <MomentDateSelect
            dates={selectedDates}
            setDates={setDates}
            minDate={
              timesheet ? moment(timesheet.startDate, 'YYYY-MM-DD') : moment()
            }
            maxDate={
              timesheet ? moment(timesheet.endDate, 'YYYY-MM-DD') : moment()
            }
            disabledDates={disabledDates}
          />
        </InputWrapperTopMargin8>
        <InputWrapper>
          <InputField
            className="normal-padding"
            margin="normal"
            fullWidth
            type={InputType.DROPDOWN}
            value={classification}
            modifyNewItemValue={(value: TimesheetEntryClassification) => {
              setClassification(value);
            }}
            validation="workday-type"
            options={[
              TimesheetEntryClassification.PTO,
              TimesheetEntryClassification.SICK_TIME,
              TimesheetEntryClassification.UNPAID
            ].map((workDayType: TimesheetEntryClassification) => ({
              id: workDayType,
              name: (
                <OptionWrapper>
                  {formatAsTitle(workDayType.toString())}
                </OptionWrapper>
              )
            }))}
          />
        </InputWrapper>
        <InputWrapper>
          <InputField
            disabled={hoursDisabled}
            margin="normal"
            label="Hours"
            fullWidth
            type={InputType.NUMBER}
            value={hours}
            modifyNewItemValue={(value: string) => {
              let num = parseFloat(value);
              if (num > MAX_HOURS_PER_DAY) {
                num = MAX_HOURS_PER_DAY;
              }
              if (num < 0) {
                num = 0;
              }
              setHours(roundToQuarterHour(num)); // round to nearest 0.25
            }}
            validation="hours"
          />
        </InputWrapper>
        {errorTitle ? <Tooltip title={errorTitle}>{button}</Tooltip> : button}
      </FlexBox>
    </AddEntryWrapper>
  );
};

export default AddTimeOff;
