import styled from 'src/theme';
import { Select, Box } from '@mui/material';
import { FlexBox } from 'src/components/InvoiceDetail/InvoiceSidebar/InvoiceDetailTabPane/TimesheetEntryPane/styles';

export const NonWorkingDayBox = styled(Box)`
  display: flex;
  margin-left: -8px;
`;

export const Entries = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const Entry = styled.div`
  padding: 16px;
  padding-bottom: 8px;
  svg {
    cursor: pointer;
  }

  &.inactive {
    svg {
      display: block;
    }
  }

  &.hidden {
    display: none;
  }
`;

export const SelectBox = styled(FlexBox)`
  flex: 0 0 220px;
`;

export const DescriptionBox = styled(FlexBox)`
  flex: 1 1 auto;
`;

export const Project = styled.div`
  display: flex;
`;

export const IconBox = styled.div`
  width: 30px;
`;

export const Description = styled.div`
  margin-left: 30px;
  width: 100%;
  padding: 20px 0 5px 10px;
  font-size: 16px;
  color: #a1a1a1;
`;

export const ClientDivisionLine = styled.div`
  margin: 0 10px;
`;

export const ClassificationBox = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  align-items: center;
  height: 50px;
  width: 160px;
`;

export const ClassificationTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  text-transform: capitalize;
`;

export const ClassificationHours = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 65px;
  margin-right: 10px;
  font-weight: normal;
  cursor: text;
`;

export const HoursDivision = styled.div`
  height: 25px;
  width 1px;
  background-color: #d4d4d4;
`;

export const BillableBox = styled.div`
  display: flex;
  justify-content: flex-end;
  width 165px;
  font-size: 14px;
`;

export const EntryHrsTitle = styled.div`
  display: flex;
  width 70%;
  justify-content: flex-end;
`;

export const EntrySumHours = styled.div`
  display: flex;
  width: 30%;
  justify-content: flex-end;
  margin-right: 24px;
  font-weight: normal;
`;

export const TotalHrsBox = styled(BillableBox)``;

export const Deliverable = styled.div`
  font-size: 12px;
`;
export const ClientCode = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

export const ProjectName = styled.div`
  font-size: 14px;
`;

export const CustomSelect = styled(Select)`
  flex: 0 0 220px;
  .MuiInputBase-root {
    cursor: pointer;
    line-height: inherit;
  }
  .MuiSelect-select {
    padding: 0 20px 0 0 !important;
    &:focus {
      background: transparent;
    }
  }
  .MuiSelect-icon {
    top: -3px;
  }
`;

export const HoursInputBox = styled.div`
  margin-left: 8px;
`;

export const HolidayBox = styled(Box)`
  padding: 16px 8px 24px;
`;
