import React from 'react';
import _ from 'lodash';
import { formatAsCurrency } from 'src/shared/utils';
import { SectionInfo } from './styles';
import { IInvoiceSidebarDetail } from 'src/shared/invoice-list/types';

interface IProps {
  invoiceSidebarDetail: IInvoiceSidebarDetail;
}

export default function Budget({ invoiceSidebarDetail }: IProps) {
  const {
    budgetPercentagePreference,
    estimatedCost,
    remainingHours,
    hoursUnapproved,
    hoursUninvoiced,
    hoursWorked,
    invoicingHours
  } = invoiceSidebarDetail.projectStats;

  return (
    <div>
      <h4 className="section-header">Budget</h4>
      <SectionInfo>
        <li className="section-info value-end">
          <span className="section-info-label">Budget Calculation</span>
          <span className="section-info-value">
            {_.capitalize(budgetPercentagePreference)}
          </span>
        </li>
        <li className="section-info value-end">
          <span className="section-info-label">Est. Cost</span>
          <span className="section-info-value">
            {formatAsCurrency(estimatedCost)}
          </span>
        </li>
        <li className="section-info value-end">
          <span className="section-info-label">Hours Worked</span>
          <span className="section-info-value">{hoursWorked} h</span>
        </li>
        <li className="section-info value-end">
          <span className="section-info-label">Invoiced Hours</span>
          <span className="section-info-value">{invoicingHours} h</span>
        </li>
        <li className="section-info value-end">
          <span className="section-info-label">Unapproved Invoice Hours</span>
          <span className="section-info-value">{hoursUninvoiced} h</span>
        </li>
        <li className="section-info value-end">
          <span className="section-info-label">Unapproved Timesheet Hours</span>
          <span className="section-info-value">{hoursUnapproved} h</span>
        </li>
        <li className="section-info value-end">
          <span className="section-info-label">Remaining Hours</span>
          <span className="section-info-value">{remainingHours} h</span>
        </li>
      </SectionInfo>
    </div>
  );
}
