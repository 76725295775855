import * as React from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/reducer';
import PrivateLayout from '../../layouts/PrivateLayout';
import PublicLayout from '../../layouts/PublicLayout';
import _ from 'lodash';
import { ToastContainer } from 'react-toastify';
import { toast } from 'src/services/toast';
import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from 'src/contexts/app-context';
import { permissionAllowed } from 'src/shared/utils';
import { useQuery } from 'react-query';
import { Api } from 'src/api/api';

export const App: FunctionComponent = () => {
  const { verfiedLoginState, isLoggedIn, currentUser } = useSelector(
    (store: IAppState) => store.auth
  );

  const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState(true);
  const [isRightDrawerOpen, setIsRightDrawerOpen] = useState(false);
  const [isLeftMobileDrawerOpen, setIsLeftMobileDrawerOpen] = useState(false);
  const toggleLeftDrawer = () => setIsLeftDrawerOpen(!isLeftDrawerOpen);
  const toggleRightDrawer = () => setIsRightDrawerOpen(!isRightDrawerOpen);
  const toggleLeftMobileDrawer = () =>
    setIsLeftMobileDrawerOpen(!isLeftMobileDrawerOpen);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!permissionAllowed(location.pathname, currentUser)) {
      toast('Permission to access this resource denied', 'error');
      navigate('/', { replace: true });
    }
  }, [currentUser, location.pathname]);

  const { data: quickBooksOnlineAuthResponse } = useQuery(
    [],
    Api.loginQuickBooksOnline,
    {
      retry: false,
      enabled: false
    }
  );

  if (!verfiedLoginState) return null;

  const appContext = {
    isAuthenticatedQuickBooksOnline:
      quickBooksOnlineAuthResponse?.status === 200,
    isLeftDrawerOpen,
    isRightDrawerOpen,
    isLeftMobileDrawerOpen,
    toggleLeftDrawer,
    toggleRightDrawer,
    toggleLeftMobileDrawer
  };

  return (
    <AppContext.Provider value={appContext}>
      {isLoggedIn ? <PrivateLayout /> : <PublicLayout />}
      <ToastContainer />
    </AppContext.Provider>
  );
};

export default App;
