import {
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import * as React from 'react';
import constants, { ANY_USER } from 'src/constants';
import messages from 'src/messages';
import Typography, { variants } from '../Typography';
import {
  CustomDrawer,
  DrawerContainer,
  DrawerToolbar,
  MenuTitle,
  MobileDrawer,
  SubList
} from './styles';
import { setWindowLocation } from 'src/shared/utils';
import ImpersonateContainer from '../../containers/ImpersonateContainer';
import { NavigateFunction } from 'react-router/lib/hooks';
import { FunctionComponent } from 'react';

interface ILeftDrawer {
  mobileDrawerIsOpened: boolean;
  drawerIsOpen: boolean;
  toogleMenuMobile: () => void;
  hideMenuClick: () => void;
  navigate: NavigateFunction;
  path: string;
  currentUser: any;
}

export const LeftDrawer: FunctionComponent<ILeftDrawer> = props => {
  const drawerContent = (isMobile: boolean) => {
    return (
      <DrawerContainer>
        <DrawerToolbar>
          <div onClick={homeClick}>
            <Typography variant={variants.SMALL_LOGO}>
              {messages.LOGO_TEXT}
            </Typography>
          </div>
          <IconButton
            onClick={isMobile ? props.toogleMenuMobile : props.hideMenuClick}
          >
            <Close />
          </IconButton>
        </DrawerToolbar>
        <List component="nav">
          {constants.MENUS.map(current => {
            const { currentUser } = props;
            const anyoneHasAccess = current.roles.find(cur => cur === ANY_USER);
            const currentUserRoles = currentUser ? currentUser.roles : [];
            const userHasAccess = current.roles.find(cur =>
              currentUserRoles.find((curls: any) => curls.name === cur)
            );
            if (anyoneHasAccess || userHasAccess) {
              return (
                <React.Fragment key={current.text}>
                  <MenuTitle>
                    <ListItemText inset={false} primary={current.text} />
                  </MenuTitle>
                  <SubList>
                    {current.inner.map(inner => {
                      const userHasParticularAccess = inner.roles.find(cur =>
                        currentUserRoles.find(
                          (curls: any) => curls.name === cur
                        )
                      );
                      const anyoneHasParticularAccess = inner.roles.find(
                        cur => cur === ANY_USER
                      );
                      if (
                        anyoneHasParticularAccess ||
                        userHasParticularAccess
                      ) {
                        // /base-path/:id/action <- the next lines get the base path to compare it with the inner.key
                        const { path } = props;
                        const basePath = path
                          .split('/')
                          .slice(0, 2)
                          .join('/');
                        return (
                          <ListItem
                            selected={basePath === inner.key}
                            id={inner.key}
                            key={inner.key}
                            button={true}
                            onClick={buttonClick}
                          >
                            <ListItemText key={inner.key} inset={false}>
                              {inner.text}
                            </ListItemText>
                          </ListItem>
                        );
                      }
                      return null;
                    })}
                  </SubList>
                </React.Fragment>
              );
            }
            return null;
          })}
        </List>
        {process.env.REACT_APP_BACKDOOR === 'true' && <ImpersonateContainer />}
      </DrawerContainer>
    );
  };

  const homeClick = () => {
    const url = '/';
    setWindowLocation(url);
  };

  const buttonClick = (event: any) => {
    let innerLocated: any | null = null;
    constants.MENUS.find((menu: any) => {
      innerLocated = menu.inner.find((inner: any) => {
        return inner.key === event.currentTarget.id;
      });

      return innerLocated;
    });
    if (innerLocated && innerLocated.implemented) {
      props.navigate(`${event.currentTarget.id}`);
    }
  };

  return (
    <>
      <Hidden mdUp={true}>
        <MobileDrawer
          variant="temporary"
          anchor={'left'}
          open={props.mobileDrawerIsOpened}
          onClose={props.toogleMenuMobile}
        >
          {drawerContent(true)}
        </MobileDrawer>
      </Hidden>
      <Hidden smDown={true} implementation="css">
        <CustomDrawer
          anchor="left"
          variant="permanent"
          open={props.drawerIsOpen}
        >
          {props.drawerIsOpen ? drawerContent(false) : null}
        </CustomDrawer>
      </Hidden>
    </>
  );
};

export default LeftDrawer;
