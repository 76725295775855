import styled, { theme } from '../../theme';

export const Container = styled.div`
  max-width: 43.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`;

export const Title = styled.h3`
  margin: 10px 0 0 0;
`;

export const Sub = styled.p`
  margin: 10px 0 20px 0;
`;

export const Content = styled.div`
  padding: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  font-family: ${theme.font};
  margin-bottom: 60px;
`;

export const LineItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 10px;
`;

export const Label = styled.div`
  display: flex;
  flex: 1;
`;

export const Value = styled.div`
  display: flex;
  flex: 2;
  justify-content: flex-end;
  padding-right: 20px;
`;
