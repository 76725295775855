import _ from 'lodash';
import { formatDateForApi } from 'src/shared/utils';
import Api from '../api';
import { Timesheet, TimesheetStatusEnum } from '../types';

export const fetchTeamTimesheets = async (timesheet: Timesheet) => {
  const stack: Timesheet[] = [timesheet];
  const teamTimesheets: Timesheet[] = [timesheet];

  while (stack.length > 0) {
    const currentTimesheet = stack.pop();

    if (currentTimesheet) {
      const currentTeamTimesheets = await Api.fetchTimesheets({
        page: 1,
        perPage: 100,
        managerId: currentTimesheet.owner.id,
        status: TimesheetStatusEnum.ALL,
        startDate: formatDateForApi(currentTimesheet.startDate),
        endDate: formatDateForApi(currentTimesheet.endDate)
      });

      _.each(currentTeamTimesheets, teamTimesheet => {
        const isNewTeamTimesheet = !_.some(teamTimesheets, timesheet => {
          return timesheet.id === teamTimesheet.id;
        });

        const isNotOnStack = !_.some(stack, timesheet => {
          return timesheet.id === teamTimesheet.id;
        });

        const isNewManagerTimesheet =
          teamTimesheet.owner.isManager && isNewTeamTimesheet && isNotOnStack;

        if (isNewManagerTimesheet) {
          stack.push(teamTimesheet);
        }

        if (isNewTeamTimesheet) {
          teamTimesheets.push(teamTimesheet);
        }
      });
    }
  }

  return teamTimesheets;
};
