import React from 'react';
import _ from 'lodash';
import MoreVert from '@mui/icons-material/MoreVert';
import moment, { Moment } from 'moment';
import InputField, { InputType } from 'src/components/shared/inputs/InputField';
import { Menu, MenuItem } from '@mui/material';
import {
  FlexBox,
  DayBox,
  DayBoxHeader,
  Bold14,
  GroupedBox,
  FlexBoxJustifyStart,
  DayBoxBody,
  InnerBodyWrapper,
  FlexBoxJustifyEnd,
  AlignLeftColumnFlexBox,
  MarginRight24,
  Subtitle12,
  GreenCheckCircle,
  GreyCancel,
  MarginHorizontal24,
  ClientIcon,
  ClientIconShiftedLeft,
  DateSpan,
  FlexBox16MarginBottom
} from './styles';
import { IInvoiceDetailView } from 'src/shared/invoice/types';
import { formatAsTitle, typeGuard } from 'src/shared/utils';
import { isNonClient } from 'src/shared/invoice/utils';
import { Color } from 'src/constants';
import {
  Deliverable,
  ProjectDetail,
  ProjectRole,
  TimesheetEntry,
  TimesheetEntryClassification
} from 'src/api/types';

const formatDate = (date: string) => {
  return moment(date).format('ddd, MMM D');
};

export const isTimesheetEntry = (
  x: any
): x is TimesheetEntry | ExpandedITimesheetEntry => {
  return x && x.isEmpty === undefined;
};

export interface ExpandedITimesheetEntry extends TimesheetEntry {
  project: ProjectDetail;
  role: ProjectRole;
  deliverable: Deliverable;
}

export const isExpandedTimesheetEntry = (
  x: any
): x is ExpandedITimesheetEntry => {
  return (
    (x && x.project && x.project !== undefined) ||
    x.classification === TimesheetEntryClassification.PTO ||
    x.classification === TimesheetEntryClassification.HOLIDAY ||
    x.classification === TimesheetEntryClassification.SICK_TIME ||
    x.classification === TimesheetEntryClassification.UNPAID
  );
};

const TimesheetEntryPane = ({
  hourItems,
  role,
  modifyItemValue,
  view,
  onAcceptChanges,
  onCancelChanges,
  dismissItem,
  openTimesheet,
  immediateChanges,
  isFullTimesheetDialog,
  clientColorList,
  resetItem,
  date,
  canEdit
}: {
  view: IInvoiceDetailView;
  hourItems: any[];
  role?: ProjectRole;
  onCancelChanges: (index: number) => void;
  onAcceptChanges: (index: number) => void;
  modifyItemValue: ({
    timesheetEntryId,
    dataKey,
    value
  }: {
    timesheetEntryId: number;
    dataKey: string;
    value: string | number | boolean;
  }) => void;
  dismissItem: (item: { hourList: any[] }) => void;
  openTimesheet?: () => void;
  immediateChanges?: boolean;
  isFullTimesheetDialog?: boolean;
  clientColorList?: string[];
  resetItem?: (timesheetEntryId: number) => void;
  date: Moment;
  canEdit: boolean;
}) => {
  const representativeItem = isFullTimesheetDialog
    ? _.find(hourItems, 'associatedToInvoice')
    : _.head(hourItems);
  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);

  const openMenu = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  let headerElement = null;
  if (representativeItem && representativeItem.modified) {
    headerElement = <span className="italic">modified</span>;
  }
  if (representativeItem && representativeItem.dismissed) {
    headerElement = <span className="italic">Dismissed</span>;
  }

  const timesheetEntryId = representativeItem ? representativeItem.id : null;
  if (representativeItem && representativeItem.edited && !immediateChanges) {
    headerElement = (
      <React.Fragment>
        <GreenCheckCircle
          onClick={() => {
            closeMenu();
            onAcceptChanges(timesheetEntryId);
          }}
          key="check"
        />
        <GreyCancel
          onClick={() => {
            closeMenu();
            onCancelChanges(timesheetEntryId);
          }}
          key="cancel"
        />
      </React.Fragment>
    );
  }
  const menu = canEdit ? (
    <React.Fragment>
      <MoreVert onClick={openMenu} key="menu-anchor" />
      <Menu
        key="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {isTimesheetEntry(representativeItem) && [
          <MenuItem
            onClick={() => {
              closeMenu();
              modifyItemValue({
                value: true,
                timesheetEntryId: timesheetEntryId,
                dataKey: 'edited'
              });
            }}
            key="edit"
          >
            Edit
          </MenuItem>,
          <MenuItem
            key="dismiss"
            onClick={() => {
              closeMenu();
              if (isTimesheetEntry(representativeItem)) {
                dismissItem({
                  hourList: [{ ...representativeItem }]
                });
              }
            }}
          >
            {representativeItem && representativeItem.dismissed
              ? 'Restore'
              : 'Dismiss'}
          </MenuItem>
        ]}
        {openTimesheet && (
          <MenuItem key="open-timesheet" onClick={openTimesheet}>
            Open Timesheet
          </MenuItem>
        )}
        {immediateChanges && (
          <MenuItem
            key="reset-item"
            onClick={() => {
              closeMenu();
              if (representativeItem) {
                return resetItem
                  ? resetItem(timesheetEntryId)
                  : Function.prototype;
              }
            }}
          >
            Reset
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  ) : null;

  const totalHours = _.reduce(
    hourItems,
    (sum: number, hourItem: TimesheetEntry) =>
      typeGuard.isNumber(hourItem.hours) ? sum + hourItem.hours : sum,
    0
  );

  const dayBoxTitle = `${formatDate(date.format())}${
    isFullTimesheetDialog && totalHours ? ` - ${totalHours} Hr` : ''
  }`;

  return (
    <DayBox key={representativeItem && representativeItem.date}>
      <DayBoxHeader>
        <FlexBox>
          <FlexBox>
            <FlexBox>
              <DateSpan>{dayBoxTitle}</DateSpan>
              {menu}
              <FlexBox>
                {_.map(clientColorList, color => (
                  <ClientIcon color={color} key={color} />
                ))}
              </FlexBox>
            </FlexBox>
          </FlexBox>
          {headerElement}
        </FlexBox>
        {!isTimesheetEntry(representativeItem) ? (
          <span className="italic">No invoice hours</span>
        ) : (
          <GroupedBox>
            <Bold14>Invoice Hr</Bold14>
            {representativeItem && representativeItem.dismissed ? (
              <MarginHorizontal24>--</MarginHorizontal24>
            ) : (
              <InputField
                disabled={!canEdit}
                value={representativeItem && representativeItem.invoicingHours}
                type={InputType.NUMBER}
                modifyNewItemValue={(value: string, dataKey: string) =>
                  modifyItemValue({
                    value: parseFloat(value),
                    timesheetEntryId: timesheetEntryId,
                    dataKey
                  })
                }
                validation="invoicingHours"
              />
            )}
          </GroupedBox>
        )}
      </DayBoxHeader>
      {_.map(hourItems, (hourItem: TimesheetEntry, idx: number) => {
        let timesheetPaneTitle =
          idx === 0 ? (
            <AlignLeftColumnFlexBox>
              <Bold14>{view.projectNickname}</Bold14>
              <Subtitle12>{role && role.name}</Subtitle12>
            </AlignLeftColumnFlexBox>
          ) : null;
        if (isFullTimesheetDialog && isExpandedTimesheetEntry(hourItem)) {
          // add call to organization endpoint to get color: https://griosf.atlassian.net/browse/MMNT-1981

          const color = isNonClient(hourItem)
            ? Color.YELLOW_GREEN
            : hourItem.project && hourItem.project.client.color;

          const deliverableName = isNonClient(hourItem)
            ? _.eq(hourItem.classification, TimesheetEntryClassification.PTO)
              ? TimesheetEntryClassification.PTO
              : formatAsTitle(hourItem.classification) // PTO is the only classification this doesn't work for
            : hourItem.deliverable && hourItem.deliverable.name;

          timesheetPaneTitle = (
            <AlignLeftColumnFlexBox>
              <FlexBoxJustifyStart>
                <ClientIconShiftedLeft color={color} />
                <Bold14>
                  {hourItem.project
                    ? `${hourItem.project.client.code} - `
                    : null}
                  {deliverableName}
                </Bold14>
              </FlexBoxJustifyStart>
              <Subtitle12>{hourItem.role && hourItem.role.name}</Subtitle12>
            </AlignLeftColumnFlexBox>
          );
        }

        const isNonBillable = !_.eq(
          hourItem.classification,
          TimesheetEntryClassification.BILLABLE
        );
        return isTimesheetEntry(hourItem) ? (
          <DayBoxBody
            key={hourItem.id}
            paddingRight={isFullTimesheetDialog ? '48px' : '24px'}
          >
            <InnerBodyWrapper>
              <FlexBoxJustifyStart>{timesheetPaneTitle}</FlexBoxJustifyStart>
              <FlexBoxJustifyEnd>
                <FlexBox16MarginBottom>
                  <MarginRight24>
                    {isNonBillable ? 'Non-Billable' : 'Billable'}
                  </MarginRight24>
                  <span>
                    {hourItem.nonBill
                      ? hourItem.nonBill.toFixed(2)
                      : hourItem.actual.toFixed(2)}
                  </span>
                </FlexBox16MarginBottom>
              </FlexBoxJustifyEnd>
              <InputField
                fullHeight={immediateChanges}
                value={hourItem.invoicingDescription || hourItem.description}
                type={InputType.LONG_STRING}
                validation="invoicingDescription"
                disabled={hourItem.id !== timesheetEntryId || !canEdit}
                modifyNewItemValue={(
                  value: string | boolean,
                  dataKey: string
                ) =>
                  modifyItemValue({
                    value,
                    timesheetEntryId: timesheetEntryId,
                    dataKey
                  })
                }
              />
            </InnerBodyWrapper>
          </DayBoxBody>
        ) : null;
      })}
    </DayBox>
  );
};

export default TimesheetEntryPane;
