import React, { useState } from 'react';
import _ from 'lodash';
import { Modal, InputAdornment, MenuItem, Box, Popover } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { now, periodsMoment } from 'src/shared/utils';
import {
  Footer,
  CancelButton,
  CreateButton,
  Header,
  Title,
  InputTitle,
  CustomInput,
  InputWrapper,
  SearchTextField
} from './styles';
import { StyledMenuItem } from '../styles';
import { IUninvoicedPeriod } from 'src/shared/invoice/types';
import { MAX_SEARCH_ITEMS } from 'src/constants';
import styled, { theme } from 'src/theme';
import { ProjectDetail } from 'src/api/types';

interface IInvoiceCreateModal {
  onConfirm: (projectId: number, period: string) => void;
  onCancel: () => void;
  searchInvoices: (query: string, active: boolean, perPage?: number) => void;
  activeProjects: ProjectDetail[];
  renderClientIcon: (color?: string) => JSX.Element;
}

const PaperDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 532px;
  background-color: ${theme.palette.background.paper};
  border: 1px solid #212121;
  border-radius: 5px;
  box-shadow: ${theme.shadows[5]};
  padding: 0;
  outline: none !important;
`;

const InvoiceCreateModal = ({
  onConfirm,
  onCancel,
  searchInvoices,
  activeProjects,
  renderClientIcon
}: IInvoiceCreateModal) => {
  const [query, setQuery] = useState('');
  const [period, setPeriod] = useState('');
  const [projectId, setProjectId] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [clientCode, setClientCode] = useState('');
  const [projectNickname, setProjectNickname] = useState('');
  const periodInitialState = [
    {
      key: '',
      name: 'Select Period'
    }
  ];
  const [periods, setPeriods] = useState(periodInitialState);

  const invoiceInputChanged = function({
    target: { value },
    currentTarget
  }: {
    target: { value: string };
    currentTarget: HTMLElement | null;
  }) {
    if (value.length > 1) {
      if (anchorEl === null && Boolean(currentTarget)) {
        setAnchorEl(currentTarget);
      }
      if (!_.eq(query, value)) {
        searchInvoices(value, true, MAX_SEARCH_ITEMS);
      }
    } else {
      setAnchorEl(null);
    }
    setQuery(value);
  };

  const handleClose = function() {
    setAnchorEl(null);
  };

  const menuItemClicked = function(
    activeProject: ProjectDetail,
    activeProjects: ProjectDetail[]
  ) {
    handleClose();
    invoiceInputChanged({ target: { value: '' }, currentTarget: null });
    setProjectId(activeProject.id);
    setClientCode(activeProject.client.code);
    setProjectNickname(activeProject.nickname);

    const currentPeriods = periodInitialState;
    const lastSixPeriods = periodsMoment(now(), 6);
    _.each(lastSixPeriods, period => {
      const startDate = period[0];
      const endDate = period[1];
      currentPeriods.push({
        key:
          startDate.format('YYYY-MM-DD') + ' - ' + endDate.format('YYYY-MM-DD'),
        name:
          startDate.format('MMMM') +
          ' ' +
          startDate.format('D') +
          ' - ' +
          endDate.format('D') +
          ', ' +
          startDate.format('YYYY')
      });
    });

    setPeriods(currentPeriods);
  };

  return (
    <Modal open onClose={onCancel} onBackdropClick={onCancel}>
      <PaperDiv>
        <Box display="flex" flexDirection="column">
          <Header>
            <Title>Invoice</Title>
          </Header>
          <InputWrapper>
            <SearchTextField
              onChange={invoiceInputChanged}
              key="search-deliverable-modal"
              fullWidth={true}
              value={query}
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              placeholder={
                projectId
                  ? `${clientCode} - ${projectNickname}`
                  : 'Search clients, projects, or deliverables'
              }
            ></SearchTextField>
            <Popover
              anchorEl={anchorEl}
              open={Boolean(activeProjects.length) && Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  transform: 'translateY(40px)'
                }
              }}
              disableAutoFocus
              disableEnforceFocus
            >
              {_.map(activeProjects, (activeProject: ProjectDetail) => (
                <StyledMenuItem
                  key={activeProject.id}
                  value={activeProject.id}
                  onClick={() =>
                    menuItemClicked(
                      activeProject,
                      _.filter(activeProjects, p => p.id === activeProject.id)
                    )
                  }
                >
                  {renderClientIcon(activeProject.client.color)}{' '}
                  <span>
                    {activeProject.client.code} - {activeProject.nickname}
                  </span>
                </StyledMenuItem>
              ))}
            </Popover>
            <InputTitle>Dates</InputTitle>
            <CustomInput
              key="period-filter"
              select
              onChange={({
                target: { value }
              }: React.ChangeEvent<HTMLInputElement>) => {
                setPeriod(value);
              }}
              label="Choose a time period"
              value={period}
              margin="normal"
              variant="outlined"
              name="period"
              disabled={!projectId}
              className="wider"
            >
              {periods.map((period: IUninvoicedPeriod) => (
                <MenuItem key={period.key} value={period.key}>
                  {period.name}
                </MenuItem>
              ))}
            </CustomInput>
          </InputWrapper>
          <Footer>
            <CreateButton
              disabled={projectId < 1 || !period}
              onClick={() => onConfirm(projectId, period)}
            >
              Create invoice
            </CreateButton>
            <CancelButton onClick={() => onCancel()}>Cancel</CancelButton>
          </Footer>
        </Box>
      </PaperDiv>
    </Modal>
  );
};

export default InvoiceCreateModal;
