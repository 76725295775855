import _ from 'lodash';
import moment, { Moment } from 'moment';
import {
  Deliverable,
  ProjectRole,
  TimesheetEntry,
  UserProjectRole
} from 'src/api/types';
import { ANY_USER, RoutePermission, ROUTE_PERMISSIONS } from 'src/constants';
import { IClient } from 'src/types/IClient';
import { ICurrentUser } from './auth/types';
import { removeUTCSignal } from './invoice/utils';
import typeGuardExport from './typeGuard';

export const QUERY_DATE_FORMAT = 'MM/DD/YY';
export const DEFAULT_ITEMS_PER_PAGE = 20;

export const isMysqlValidDate = (date: string) =>
  moment(
    moment(date).format('YYYY-MM-DD HH:mm:ss'),
    'YYYY-MM-DD HH:mm:ss',
    true
  ).isValid();

export const parseDateForDatepicker = (date: string | number) =>
  moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD');

// builds an array of Moment objects between start and end (inclusive)
export const createDaysArray = (
  start: string | Moment,
  end: string | Moment
) => {
  const dateStart = moment(start).startOf('day'); // june 1
  const dateEnd = moment(end).startOf('day'); // june 5
  const diffArray = _.range(dateEnd.diff(dateStart, 'days') + 1); // [0, 1, 2, 3, 4]
  return _.map(
    diffArray,
    (i: number) => dateStart.clone().add(i, 'days') // [ june 1 , june 2, june 3, june 4, june 5]
  );
};

export const createWeeksArray = (start: string | Moment, length: number) =>
  _.range(length).map((id: number) =>
    moment(start)
      .startOf('week')
      .add(id, 'weeks')
  );

export const firstWorkdayOfWeek = (day: Moment) =>
  day
    .clone()
    .startOf('week')
    .add(1, 'day');

export const firstWorkdayOfWeeks = (weeks: Moment[]) =>
  weeks.map(day => firstWorkdayOfWeek(day));

export const lastWorkdayOfWeek = (day: Moment) =>
  day
    .clone()
    .startOf('week')
    .add(5, 'days');

export const lastWorkdayOfWeeks = (weeks: Moment[]) =>
  weeks.map(day => lastWorkdayOfWeek(day));

export const isWeekday = (date: Moment) => date.isoWeekday() < 6;

export const isWeekend = (date: Moment) => !isWeekday(date);

export const formatAsISO8601 = (date: string): string =>
  moment.utc(date).format();

const addZeroPadding = (num: number): string =>
  _.padStart(num.toString(), 2, '0');

export const parseDate = (date: string): string =>
  moment(date, 'YYYY/MM/DD').format('YYYY-MM-DD');

export const formatAsMMDDYYYY = (date: string): string =>
  moment(date).format(QUERY_DATE_FORMAT);

export const nowForApi = () => moment().format(QUERY_DATE_FORMAT);

export const now = () => moment();

export const parseDateToMomentDate = (date: string | Date) => moment(date);

export const formatDateForApi = (date: string) =>
  moment(date).format(QUERY_DATE_FORMAT);

export const formatDatePeriod = (
  startDate: string,
  endDate: string,
  dedupeMonth?: boolean
) => {
  if (moment(endDate).isValid() && moment(startDate).isValid()) {
    // If we have valid start and end dates, return in format "Jun 10 - Jun 26, 2017"
    return `${moment(removeUTCSignal(startDate))
      .startOf('day')
      .format('MMM D')} - ${moment(removeUTCSignal(endDate))
      .startOf('day')
      .format(dedupeMonth ? 'D, YYYY' : 'MMM D, YYYY')}`;
  } else if (moment(startDate).isValid()) {
    // Otherwise if we just have a valid start date, return in format "Jun 10, 2017"
    return moment(startDate)
      .startOf('day')
      .format('MMM D, YYYY');
  }
  // Otherwise, return an empty string
  return '';
};
// shared by UserDetail and UserTableRow
export const getUserActivatedStatusMessage = (
  isActive: boolean,
  userName: string
): string => {
  const newStatus = isActive ? 'Activated' : 'Deactivated';
  return `User ${userName}'s status has been updated to ${newStatus}`;
};

export const getDefaultDate = (): string => {
  const date = new Date();
  return `${date.getFullYear()}-${addZeroPadding(
    date.getMonth() + 1
  )}-${addZeroPadding(date.getDate())}`;
};

export const capitalize = (s: string) => _.capitalize(s);
export const titlelize = (s: string) => _.startCase(s.replace(/_/g, ' '));

export function setWindowLocation(url: string): void {
  window.location.replace(url);
}

export const formatAsCurrency = (amt: number | string): string => {
  let val = amt;
  if (!amt) val = 0;
  if (typeGuard.isString(amt)) {
    val = Number(amt.replace(/\$*,*/gi, ''));
  }
  return val.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

export const formatAsDDMMYYYY = (date: string) =>
  date ? moment(date).format('MM/DD/YYYY') : '-';

export const periodStart = function(date = moment()) {
  const day = date.date() <= 15 ? 1 : 16;
  return moment(new Date(date.year(), date.month(), day));
};

export const periodEnd = function(date: Moment) {
  const day = date.date() <= 15 ? 15 : date.daysInMonth();
  return moment(new Date(date.year(), date.month(), day));
};

export const periodsMoment = (
  startingDate: Moment,
  numberOfPeriods: number
) => {
  let currentDate = startingDate;
  return _.range(numberOfPeriods).map((periodIndex: number) => {
    const startDate = periodStart(currentDate);
    const endDate = periodEnd(currentDate);
    currentDate = startDate.clone().subtract(1, 'days');
    return [startDate, endDate];
  });
};

export const formatAsTitle = (string: string) => {
  if (string.length <= 3) {
    return _(string)
      .split('')
      .map(_.capitalize)
      .join('')
      .replace(/ id/gi, ''); // get rid of any 'id'
  }
  return _(string)
    .snakeCase()
    .split('_')
    .map(_.capitalize)
    .join(' ')
    .replace(/ id/gi, ''); // get rid of any 'id'
};

export const isArrayOfObjectsEqual = (x: any, y: any) =>
  _.isEmpty(_.xorWith(x, y, _.isEqual));

export const toPercentage = (value: number) => value.toFixed(2);

export const getAveragePercentage = (values: number[]) => {
  const total = _.reduce(
    values,
    (sum: number, value: number) => sum + value,
    0
  );
  return toPercentage(total / values.length);
};

export const flattenObject = (
  o: object,
  prefix = '',
  result = {},
  keepNull = true
) => {
  if (
    _.isString(o) ||
    _.isNumber(o) ||
    _.isBoolean(o) ||
    (keepNull && _.isNull(o))
  ) {
    result[prefix] = o;
    return result;
  }

  if (_.isArray(o) || _.isPlainObject(o)) {
    for (const i in o) {
      let pref = prefix;
      if (_.isArray(o)) {
        pref = pref + `[${i}]`;
      } else {
        if (_.isEmpty(prefix)) {
          pref = i;
        } else {
          pref = prefix + '.' + i;
        }
      }
      flattenObject(o[i], pref, result, keepNull);
    }
    return result;
  }
  return result;
};

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const diff = (object: any, base: any) => {
  function changes(object: any, base: any) {
    return _.transform(object, function(result: any, value: any, key: any) {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
};

export const removeEmpty = (obj: object) => _.pickBy(obj, _.identity);

const fallbackCopyTextToClipboard = (value: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = value;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
};

export const copyTextToClipboard = (value: string) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(value);
    return;
  }
  navigator.clipboard.writeText(value).catch(function() {
    fallbackCopyTextToClipboard(value);
    return;
  });
};

export const isTouchDevice = () => {
  try {
    document.createEvent('TouchEvent');
    return true;
  } catch (e) {
    return false;
  }
};

let lastTouch: Moment | undefined;

export const isDoubleClick = () => {
  const now = moment();

  if (!lastTouch) {
    lastTouch = now;
    return false;
  }

  const diff = now.diff(lastTouch);
  lastTouch = now;

  if (diff < 600) {
    return true;
  } else {
    return false;
  }
};

const vw = () =>
  Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

export const getEnumKeyByEnumValue = (e: any, value: string | number) => {
  const keys = Object.keys(e).filter(x => e[x] === value);
  return keys.length > 0 ? keys[0] : null;
};

export const roundToQuarterHour = (num: number): number =>
  parseFloat((Math.round(num * 4) / 4).toFixed(2));

const isTablet = () => vw() > 1023 && vw() < 1280;
const isMediumDesktop = () => vw() > 1279 && vw() < 1440;

export const sizeQuery = {
  isTablet,
  isMediumDesktop
};

export const typeGuard = typeGuardExport;

export type UserProjectRoleView = {
  ref: string;
  deliverable: Deliverable;
  projectRole: ProjectRole;
  client: IClient;
  project: {
    id: number;
    name: string;
    nickname: string;
  };
};

export const userProjectRolesToViews = (
  userProjectRoles: UserProjectRole[]
): UserProjectRoleView[] => {
  return userProjectRoles
    .filter((userProjectRole: UserProjectRole) => userProjectRole.isVisible)
    .map(userProjectRoleToView);
};

export const userProjectRoleToView = (
  userProjectRole: UserProjectRole
): UserProjectRoleView => {
  const { project, client, deliverable, projectRole } = userProjectRole;
  const ref = userProjectRoleViewRef(deliverable, projectRole);
  const projectName = userProjectRole.project.name
    ? userProjectRole.project.name
    : '';
  return {
    client,
    deliverable,
    project: {
      ...project,
      name: projectName
    },
    projectRole,
    ref
  };
};

export const entryToUserProjectRoleView = (
  entry: TimesheetEntry
): UserProjectRoleView => {
  const { project, client, deliverable, role: projectRole } = entry;
  const ref = userProjectRoleViewRef(deliverable, projectRole);
  return {
    client,
    deliverable,
    project,
    projectRole,
    ref
  };
};

export const userProjectRoleViewRef = (
  deliverable: Deliverable,
  projectRole: ProjectRole
): string => {
  const ref = {
    deliverableId: deliverable.id,
    projectRoleId: projectRole.id
  };
  return JSON.stringify(ref);
};

export const findUserProjectRoleView = (
  userProductRoleViews: UserProjectRoleView[],
  deliverableId: number,
  projectRoleId: number
): UserProjectRoleView | undefined => {
  return _.find(userProductRoleViews, item => {
    return (
      item.projectRole.id === projectRoleId &&
      item.deliverable.id === deliverableId
    );
  });
};

export const parseResponseErrorMessage = (error: any) => {
  if (error.response?.data?.message) {
    return error.response.data.message;
  } else if (error.response?.data) {
    return _(error.response?.data)
      .keys()
      .map(key => `${key}: ${error.response?.data[key]}`)
      .join(', ');
  }
};

export const permissionAllowed = (
  pathName: string,
  currentUser?: ICurrentUser
) => {
  if (!currentUser) return true;

  const current: RoutePermission | undefined = _.find(
    ROUTE_PERMISSIONS,
    ({ route }: RoutePermission) => route.test(pathName)
  );
  if (!current || current.roles.indexOf(ANY_USER) !== -1) return true;

  const intersectingRoles = _(currentUser.roles)
    .map(({ name }: { name: string }) => name)
    .intersection(current.roles)
    .value();
  return !!intersectingRoles.length;
};
