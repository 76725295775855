import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import * as React from 'react';
import DialogTransition from '../DialogTransition';
import messages from 'src/messages';

interface IAddInvoiceEmail {
  open: boolean;
  handleClose?: () => void;
  add?: () => void;
  values?: any;
  errors?: any;
  loading: boolean;
  onChange?: (prop: string) => (event: any) => void;
}

const AddInvoiceEmail: React.FC<IAddInvoiceEmail> = ({
  open = true,
  handleClose,
  add,
  errors = {},
  values = {},
  onChange = () => undefined,
  loading = false
}) => (
  <Dialog
    open={open}
    TransitionComponent={DialogTransition}
    keepMounted={true}
    onClose={handleClose}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle id="alert-dialog-slide-title">
      {'Add - Contact Email'}
    </DialogTitle>
    <DialogContent>
      <TextField
        required={true}
        id="email-add-input"
        label={'Email'}
        value={values.email}
        error={errors.email}
        onChange={onChange('email')}
        margin="normal"
        fullWidth={true}
        variant="filled"
      />
      <TextField
        required={true}
        id="firstName-add-input"
        label={'First Name'}
        value={values.firstName}
        error={errors.firstName}
        onChange={onChange('firstName')}
        margin="normal"
        fullWidth={true}
        variant="filled"
      />
      <TextField
        required={false}
        id="lastName-add-input"
        label={'Last Name'}
        value={values.lastName}
        onChange={onChange('lastName')}
        margin="normal"
        fullWidth={true}
        variant="filled"
      />
    </DialogContent>
    <DialogActions>
      <Button disabled={loading} onClick={handleClose} color="primary">
        {messages.CANCEL}
      </Button>
      <Button
        disabled={loading}
        onClick={add}
        color="primary"
        variant="contained"
      >
        {messages.ADD}
      </Button>
    </DialogActions>
  </Dialog>
);

export default AddInvoiceEmail;
