import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IAppState } from 'src/reducer';
import * as authActions from 'src/shared/auth/actions';
import UserInfo from '../../components/UserInfo';
import { ICurrentUser } from '../../shared/auth/types';
import { getInitials } from 'src/shared/users/utils';
import { FunctionComponent, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

type IUserInfo = IUserInfoProps & IUserInfoDispatch;

interface IUserInfoProps {
  currentUser?: ICurrentUser;
}

interface IUserInfoDispatch {
  logout: () => void;
}

export const UserInfoContainer: FunctionComponent<IUserInfo> = props => {
  const navigate = useNavigate();

  const logout = () => {
    props.logout();
    navigate('/', { replace: true });
  };

  const initials = useMemo(() => {
    return props.currentUser ? getInitials(props.currentUser.fullName) : '';
  }, [props.currentUser]);

  return <UserInfo logout={logout} initials={initials} />;
};

const mapDispatchToProps = (dispatch: Dispatch): IUserInfoDispatch => {
  return bindActionCreators(
    {
      logout: authActions.actions.logout
    },
    dispatch
  );
};

const mapStateToProps = (state: IAppState): IUserInfoProps => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect<IUserInfoProps, IUserInfoDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(UserInfoContainer);
