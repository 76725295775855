import styled, { theme } from 'src/theme';
import AddCircle from '@mui/icons-material/AddCircle';
import Comment from '@mui/icons-material/Comment';
import { Box, Button } from '@mui/material';

export const TealAddIcon = styled(AddCircle)`
  color: #00838f;
  padding-right: 8px;
`;

export const StyledComment = styled(Comment)`
  padding-right: 8px;
`;

export const FlexBox = styled(Box)`
  display: flex;
`;

export const ColumnFlexBox = styled(FlexBox)`
  flex-direction: column;
`;

export const IndentedColumnFlexBox = styled(ColumnFlexBox)`
  padding-left: 8px;
`;

export const StaticTextField = styled(Box)`
  overflow: hidden;
  border-radius: 5px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  font-family: ${theme.font};
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  &.rejection {
    border-radius: 6px;
    background-color: rgba(255, 144, 144, 0.5);
    padding: 8px;
  }
`;

export const HoverableTextField = styled(StaticTextField)`
  padding: 6px;

  cursor: pointer;
  min-height: 51px;
  width: 306px;

  &:hover {
    background-color: #eee;
  }
`;

export const InvoiceNoteContainer = styled(ColumnFlexBox)``;

export const FlexBoxJustifyStartWithPointer = styled(FlexBox)`
  justify-content: start;
  cursor: pointer;
  margin-top: 8px;
`;

export const SpecialInstructionsHeader = styled(FlexBox)`
  justify-content: start;
  color: #212121;
  font-family: ${theme.font};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 10px;
`;

export const PaddedInstructionsBox = styled(FlexBox)`
  flex-direction: column;
  padding: 12px 16px;
  border-top: 1px solid #d4d4d4;
`;

export const MarginBottom16 = styled.div`
  margin-bottom: 16px;
`;

export const MarginLeftNeg20 = styled.div`
  margin-left: -24px;
  width: 320px;
`;

export const StyledPrimaryButton = styled(Button)`
  && {
    padding: 5px 16px;
    border-radius: 4px;
    background-color: #00838f;
    color: #ffffff;
    font-family: ${theme.font};
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    margin-right: 16px;
    text-transform: none;
  }
  &&:hover {
    background-color: #4ca8b0;
  }
  &&.MuiButton-root.Mui-disabled {
    color: #fff;
    background-color: #7fc1c7;
  }
`;
export const StyledCancelButton = styled(Button)`
  && {
    padding: 5px 16px;

    color: #00838f;
    height: 36px;
    font-family: ${theme.font};
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    text-transform: none;
  }
  &&:hover {
    background-color: #eee;
  }
`;

export const SectionPrimaryHeader = styled.div`
  color: #212121;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  font-family: ${theme.font};
  padding: 8px 0;
`;
