import { Grid, Box } from '@mui/material';

import styled, { theme } from 'src/theme';

export const StyledGrid = styled(Grid)`
  &.reduce-font {
    .MuiGrid-item {
      font-size: 12px;
      padding-left: 3px;
      padding-right: 3px;
    }
    .name-item {
      padding-left: 20px;
      white-space: nowrap;
      overflow: hidden;
    }
    .sortable {
      svg {
        display: none;
      }
    }
  }
`;

export const FlexGridItem = styled(Grid)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    font-size: 14px;
    color: #212121;
    font-family: ${theme.font};
  }

  &.name-item {
    padding-left: 12px;
  }
`;

export const FlexGridItemJustifyEnd = styled(FlexGridItem)`
  && {
    justify-content: flex-end;
  }
`;

export const FlexGridItemLongString = styled(FlexGridItem)`
  && {
    text-align: left;
    justify-content: start;
    align-items: top;
    padding: 8px 4px 8px 0;
    span {
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;

export const FlexGridItemHeader = styled(FlexGridItem)`
  && {
    font-weight: 600;
  }
  &.sortable {
    cursor: pointer;
  }
`;

export const FlexGridItemHeaderJustifyEnd = styled(FlexGridItemHeader)`
  && {
    justify-content: flex-end;
  }
`;

export const FlexGridItemHeaderJustifyStart = styled(FlexGridItemHeader)`
  && {
    justify-content: start;
  }
`;

export const FlexGridItemJustifyStart = styled(FlexGridItem)`
  && {
    justify-content: start;
  }
`;

export const VerticalFlexGridItem = styled(FlexGridItem)`
  && {
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }
`;

export const FlexBox = styled(Box)`
  display: flex;
`;

export const DeliverableName = styled.span`
  margin-bottom: 2px;
  line-height: 1.1em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
`;

export const RoleName = styled(DeliverableName)`
  font-size: 12px;
  margin-bottom: 0;
`;

export const TealSpan = styled.span`
  color: #005662;
`;

export const PaddingLeft16 = styled.div`
  padding-left: 16px;
  display: flex;
  align-items: center;
`;

export const StyledRow = styled(Grid)`
  && {
    border-bottom: 1px solid #d4d4d4;
    cursor: pointer;

    & svg.dismiss {
      display: none;
    }

    & svg.restore {
      visibility: hidden;
      margin-right: 8px;
    }

    &.row-0 {
      border-top: 1px solid #d4d4d4;
    }
  }
  :hover {
    background-color: ${theme.separatorBackground};
    cursor: pointer;
    & svg.dismiss {
      display: inline-block;
    }
    & svg.restore {
      visibility: visible;
    }
  }
  &.active {
    background-color: ${theme.separatorBackground};
  }
  &.reduce-font {
    div {
      padding-left: 5px;
      font-size: 12px;
    }
    svg {
      display: none;
    }
  }
`;

export const Italic = styled.span`
  font-style: italic;
`;
