import * as React from 'react';
import { Link } from 'react-router-dom';

const NotFoundContainer = () => (
  <React.Fragment>
    <h1>
      Not Found - This page might not be migrated yet{' '}
      <Link to="/">Go Home</Link>
    </h1>
  </React.Fragment>
);

export default NotFoundContainer;
