import React, { FunctionComponent, useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Impersonate from 'src/components/Impersonate';
import ImpersonateDialog from 'src/components/ImpersonateDialog';
import { IAppState } from 'src/reducer';
import * as authActions from 'src/shared/auth/actions';
import { ICurrentUser } from 'src/shared/auth/types';

type IImpersonateContainer = IImpersonateDispatch & IImpersonateStateProps;

interface IImpersonateDispatch {
  getImpersonateUsers: () => void;
  impersonateLogin: (userId: number) => void;
  stopImpersonate: () => void;
}

interface IImpersonateStateProps {
  loading: boolean;
  users: ICurrentUser[];
  isImpersonating: boolean;
  currentUser?: ICurrentUser;
}

export const ImpersonateContainer: FunctionComponent<IImpersonateContainer> = props => {
  const [selectedUser, setSelectedUser] = useState('default');
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (process.env.REACT_APP_BACKDOOR === 'true') {
      props.getImpersonateUsers();
    }
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    if (isFirstRender) return;

    // temporary until my-timesheets in react is pushed to prod
    // Use this.props.navigate(/my-timesheets, { replace: true }) at that point
    const origin = window.location.origin;
    window.location.assign(origin + '/');
  }, [props.isImpersonating, props.currentUser]);

  const { isImpersonating, loading, users, stopImpersonate } = props;

  if (isImpersonating) {
    return <ImpersonateDialog stopImpersonate={stopImpersonate} />;
  }

  const impersonateLogin = () => {
    if (selectedUser !== 'default') {
      props.impersonateLogin(Number(selectedUser));
    }
  };

  const userChanged = (event: React.BaseSyntheticEvent) => {
    setSelectedUser(event.target.value);
  };

  return (
    <Impersonate
      onUserChange={userChanged}
      loading={loading}
      users={users}
      selectedUser={selectedUser}
      onLoginClick={impersonateLogin}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch): IImpersonateDispatch => {
  return bindActionCreators(
    {
      getImpersonateUsers: authActions.actions.getImpersonateUsers,
      impersonateLogin: authActions.actions.impersonateLogin,
      stopImpersonate: authActions.actions.stopImpersonate
    },
    dispatch
  );
};

const mapStateToProps = (state: IAppState): IImpersonateStateProps => {
  const users = state.auth.impersonateUsers;
  const loading = state.auth.loadingImpersonate;
  const isImpersonating = state.auth.isImpersonating;
  const currentUser = state.auth.currentUser;
  return {
    isImpersonating,
    loading,
    users,
    currentUser
  };
};

export default connect<IImpersonateStateProps, IImpersonateDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(ImpersonateContainer);
