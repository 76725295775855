import Drawer, { DrawerProps } from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import * as React from 'react';
import styled, { theme } from '../../theme';

export const CustomDrawer: React.ComponentType<DrawerProps> = styled(
  ({ ...rest }) => <Drawer classes={{ paper: 'paper' }} {...rest} />
)`
  background-color: white;
  & .MuiDrawer-paper {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: relative;
    width: 100%;
    flex: 1;
    background-color: ${theme.secondaryBackground};
  }
` as any;

export const RightToggle = styled.div`
  height: 100%;
  cursor: pointer;
  width: 10px;
  background-color: ${theme.separatorBackground};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled(({ open, ...rest }) => <Hidden {...rest} />)`
  overflow: hidden;
  flex: 1;
  padding-top: 10px;
  display: flex;
  -webkit-transition: min-width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms; /* For Safari 3.1 to 6.0 */
  transition: min-width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  min-width: ${props => (props.open ? '60%' : '30%')};
  & > .custom-docked {
    flex: 1;
    width: 100%;
    height: 100%;
  }
`;
