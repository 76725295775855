import * as React from 'react';
import _ from 'lodash';
import InvoiceList from 'src/components/InvoiceList';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from 'src/reducer';
import { actions as invoiceActions } from 'src/shared/invoice-list/actions';
import { actions as invoiceDetailActions } from 'src/shared/invoice/actions';
import { actions as userActions } from 'src/shared/users/actions';
import { isAdmin as userIsAdmin } from 'src/shared/auth/utils';
import {
  IInvoiceListFilters,
  InvoiceCountType,
  InvoiceStatus,
  IInvoiceSidebarDetail
} from 'src/shared/invoice-list/types';
import { NewInvoiceState, NewInvoiceParams } from 'src/shared/invoice/types';
import { ICurrentUser } from 'src/shared/auth/types';
import { IEntityStateUsers } from 'src/shared/users/types';
import MessageModal from 'src/components/InvoiceDetailFooter/MessageModal';
import { formatDatePeriod } from 'src/shared/utils';
import {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from 'src/contexts/app-context';
import { useQuery } from 'react-query';
import { QueryKeys } from 'src/queries/queryKeys';
import { Api } from 'src/api/api';
import { useQuery as useGqlQuery } from '@apollo/client';
import { DEFAULT_ITEMS_PER_PAGE } from 'src/constants';
import { Invoice } from 'src/api/types';

type IInvoiceListContainerProps = IInvoiceListContainerReduxState &
  IInvoiceListContainerDispatch;

const InvoiceListContainer: FunctionComponent<IInvoiceListContainerProps> = props => {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const [hasStaleInvoiceData, setHasStaleInvoiceData] = useState(false);
  const [qbMemoModal, setQbMemoModal] = useState<number | null>(null);
  const { query: searchText, sortBy, sortDirection } = props.filters;
  const searchAll = props.filters.invoices === 'All Invoices';

  useEffect(() => {
    props.initFilters();
    props.getProjectManagers();
  }, []);

  useEffect(() => {
    if (!props.loadingStatusChange && hasStaleInvoiceData) {
      setHasStaleInvoiceData(false);
      refetchCounts();
      refetchInvoices();
    }
  }, [props.loadingStatusChange]);

  const { data: invoiceCounts, refetch: refetchCounts } = useQuery(
    [QueryKeys.invoiceCounts, searchAll, searchText],
    () => Api.fetchInvoiceCounts(searchAll, searchText)
  );

  const selectedInvoiceStatus: any = useMemo(() => {
    switch (props.filters.status) {
      case 'Created':
        return ['NEW', 'REJECTED'];
      case 'Submitted':
        return ['SUBMITTED'];
      case 'Approved':
        return ['APPROVED'];
      case 'Sent':
        return ['SENT'];
      case 'Paid':
        return ['PAID'];
      default:
        return null;
    }
  }, [props.filters.status]);

  const searchInvoicesArguments = useMemo(
    () => ({
      after: undefined,
      before: undefined,
      first: undefined,
      last: undefined,
      searchAll,
      searchText,
      status: selectedInvoiceStatus,
      sortBy: sortBy as any,
      sortDirection: sortDirection as any
    }),
    [props.filters]
  );

  const searchUninvoicedProjectsArguments = useMemo(
    () => ({
      after: undefined,
      before: undefined,
      first: undefined,
      last: undefined,
      searchAll,
      searchText,
      sortBy: sortBy as any,
      sortDirection: sortDirection as any
    }),
    [props.filters]
  );

  const { data: clients } = useQuery(QueryKeys.clients, () =>
    Api.fetchClients()
  );

  const { data: invoices, refetch: refetchInvoices } = useGqlQuery(
    Api.searchInvoices,
    {
      variables: {
        ...searchInvoicesArguments,
        first: DEFAULT_ITEMS_PER_PAGE
      },
      skip: selectedInvoiceStatus === null
    }
  );

  const {
    data: uninvoicedProjects,
    refetch: refetchUninvoicedProjects
  } = useGqlQuery(Api.searchUninvoicedProjects, {
    variables: {
      ...searchUninvoicedProjectsArguments,
      first: DEFAULT_ITEMS_PER_PAGE
    },
    skip: selectedInvoiceStatus !== null
  });

  const onSortPage = (sortBy: string, sortDirection: string) => {
    props.filterChanged({ key: 'sortBy', value: sortBy });
    props.filterChanged({ key: 'sortDirection', value: sortDirection });
  };

  const onItemClicked = (id: string | number) => {
    const urlVal = _.isString(id) || _.isNumber(id) ? id : 'new';
    navigate(`/invoicing/${urlVal}`);
  };

  const onClickCreateButton = ({
    filters,
    dismiss
  }: {
    filters: { project: number; period: string; client: number };
    dismiss?: boolean;
  }) => {
    props.getNewInvoiceView({
      filters: {
        ...filters,
        importTimesheets: false,
        createCallback: navigate
      },
      dismiss
    });
  };

  const changeStatus = ({
    id,
    status
  }: {
    id: number;
    status: InvoiceStatus;
  }) => {
    if (
      _.eq(status, InvoiceStatus.APPROVED) &&
      context.isAuthenticatedQuickBooksOnline
    ) {
      return setQbMemoModal(id);
    }
    setHasStaleInvoiceData(true);
    return props.changeStatus({ id, status });
  };

  const targetResponse =
    selectedInvoiceStatus === null ? uninvoicedProjects : invoices;
  const hasNextPage = targetResponse?.data.pageInfo.hasNextPage;
  const hasPreviousPage = targetResponse?.data.pageInfo.hasPreviousPage;
  const displayedInvoices = targetResponse?.data.nodes;
  const refetch =
    selectedInvoiceStatus === null
      ? refetchUninvoicedProjects
      : refetchInvoices;
  const refetchArguments =
    selectedInvoiceStatus === null
      ? searchUninvoicedProjectsArguments
      : searchInvoicesArguments;

  const onPrevPage = () => {
    refetch({
      ...refetchArguments,
      before: targetResponse?.data.pageInfo.startCursor,
      last: DEFAULT_ITEMS_PER_PAGE
    });
  };

  const onNextPage = () => {
    refetch({
      ...refetchArguments,
      after: targetResponse?.data.pageInfo.endCursor,
      first: DEFAULT_ITEMS_PER_PAGE
    });
  };

  const { currentUser, projectManagers, selectedInvoice } = props;
  let pmList = projectManagers;
  if (currentUser) {
    const isAdmin = userIsAdmin(currentUser);
    if (!isAdmin) {
      pmList = _.filter(projectManagers, { id: currentUser.id });
    }
  }

  let modal;
  if (qbMemoModal) {
    const approvingInvoice = _.find(displayedInvoices as Invoice[], ({ id }) =>
      _.eq(id, qbMemoModal)
    );
    if (approvingInvoice) {
      const initialMessage = `${
        approvingInvoice.clientName
      } invoice from Grio for ${formatDatePeriod(
        approvingInvoice.startDate,
        approvingInvoice.endDate,
        true
      )}`;
      modal = (
        <MessageModal
          isReject={false}
          initialMessage={initialMessage}
          onOk={(message: string) => {
            setQbMemoModal(null);
            props.changeStatus({
              status: InvoiceStatus.APPROVED,
              id: qbMemoModal,
              message
            });
          }}
          onCancel={() => setQbMemoModal(null)}
        />
      );
    }
  }

  return (
    <>
      {modal}
      <InvoiceList
        loading={props.loading}
        invoices={displayedInvoices}
        filters={props.filters}
        projectManagers={pmList}
        clientList={clients}
        filterChanged={props.filterChanged}
        goToDetail={onItemClicked}
        selectedItem={selectedInvoice}
        getNewInvoiceView={props.getNewInvoiceView}
        total={props.total}
        page={props.page}
        sortPage={onSortPage}
        counts={invoiceCounts}
        changeStatus={changeStatus}
        onClickCreateButton={onClickCreateButton}
        onSelectInvoice={props.onSelectInvoice}
        invoiceSidebarDetail={props.invoiceSidebarDetail}
        fetchMoreSidebarInvoices={props.fetchMoreSidebarInvoices}
        createNewInstruction={props.createNewInstruction}
        deleteInvoiceNote={props.deleteInvoiceNote}
        updateInvoiceNote={props.updateInvoiceNote}
        currentUser={currentUser}
        leftDrawerIsOpen={context.isLeftDrawerOpen}
        showFilters={props.showFilters}
        createNewInvoice={(params, dismiss) =>
          props.createNewInvoice(navigate, dismiss, params)
        }
        clearDetail={props.clearDetail}
        hasNextPage={hasNextPage}
        hasPrevPage={hasPreviousPage}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
      />
    </>
  );
};

interface IInvoiceListContainerReduxState {
  filters: IInvoiceListFilters;
  projectManagers: IEntityStateUsers;
  selectedInvoice?: Invoice | null;
  currentUser?: ICurrentUser;
  total: number;
  page: number;
  counts: InvoiceCountType;
  rightPanelOpen: boolean;
  invoiceSidebarDetail: IInvoiceSidebarDetail;
  loading: boolean;
  loadingStatusChange: boolean;
  showFilters: boolean;
}

export const mapStateToProps = (state: IAppState) => {
  const total = state.invoices.total;
  const page = state.invoices.page;
  const filters = state.invoices.filters;
  const selectedInvoice = state.invoices.selectedInvoice;
  const projectManagers = state.entities.projectManagers;
  const currentUser = state.auth.currentUser;
  const counts = state.invoices.counts;
  const rightPanelOpen = state.invoices.rightPanelOpen;
  const invoiceSidebarDetail = state.invoices.invoiceSidebarDetail;
  const loading = state.invoices.loading;
  const loadingStatusChange = state.invoice.loading;
  const showFilters = state.invoices.showFilters;
  return {
    filters,
    projectManagers,
    selectedInvoice,
    currentUser,
    total,
    page,
    counts,
    rightPanelOpen,
    invoiceSidebarDetail,
    loading,
    loadingStatusChange,
    showFilters
  };
};

interface IInvoiceListContainerDispatch {
  getProjectManagers: () => void;
  filterChanged: typeof invoiceActions.changeInvoiceFilter;
  getNewInvoiceView: ({
    filters,
    dismiss
  }: {
    filters: NewInvoiceState['filters'];
    dismiss?: boolean;
  }) => void;
  changeStatus: ({
    status,
    message,
    id
  }: {
    status: InvoiceStatus;
    message?: string;
    id?: number;
  }) => void;
  onSelectInvoice: typeof invoiceActions.selectInvoice;
  fetchMoreSidebarInvoices: () => void;
  createNewInstruction: ({
    text,
    typeOf
  }: {
    text: string;
    typeOf: number;
  }) => void;
  deleteInvoiceNote: ({ id }: { id: number }) => void;
  updateInvoiceNote: ({ text, id }: { text: string; id: number }) => void;
  createNewInvoice: (
    cb: (path: string) => void,
    dismiss?: boolean,
    params?: NewInvoiceParams
  ) => void;
  clearDetail: () => void;
  initFilters: () => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch
): IInvoiceListContainerDispatch => {
  return bindActionCreators(
    {
      getProjectManagers: userActions.getProjectManagers,
      filterChanged: invoiceActions.changeInvoiceFilter,
      getNewInvoiceView: invoiceDetailActions.hydrateInvoice,
      changeStatus: ({ id, status, message }) =>
        invoiceDetailActions.changeInvoiceStatus(status, message, id),
      onSelectInvoice: invoiceActions.selectInvoice,
      fetchMoreSidebarInvoices: invoiceActions.fetchMoreSidebarInvoices,
      initFilters: invoiceActions.initInvoiceListFilters,
      createNewInstruction: invoiceDetailActions.createNewInstruction,
      deleteInvoiceNote: invoiceActions.deleteInvoiceNote,
      updateInvoiceNote: invoiceActions.updateInvoiceNote,
      createNewInvoice: invoiceDetailActions.createNewInvoice,
      clearDetail: invoiceDetailActions.clearDetail
    },
    dispatch
  );
};

export default connect<
  IInvoiceListContainerReduxState,
  IInvoiceListContainerDispatch
>(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceListContainer);
