// This file is just full of ts errors
//   we just need to disable ts at this point
//   and wait for the refactor away from redux-saga

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import _ from 'lodash';
import moment from 'moment';
import { all, call, takeLatest, select } from 'redux-saga/effects';
import { call as typedCall, put, cancelled } from 'typed-redux-saga';
import {
  getInvoicesByProjectId as getInvoicesByProjectIdApi,
  fetchInvoices as fetchInvoicesApi,
  handleError,
  getProjects as getProjectsApi,
  getUninvoicedProjects,
  countInvoices,
  getProjectDetail,
  getClientProjects,
  getUser,
  fetchInvoiceNotes,
  deleteInvoiceNote as deleteInvoiceNoteApi,
  updateInvoiceNote as updateInvoiceNoteApi,
  IFetchInvoicesApiFilters
} from 'src/services/api';
import { Api } from 'src/api/api';
import { UninvoicedProject } from 'src/api/types';

import {
  getInvoiceListFilters,
  currentSelectedInvoice,
  currentSidebarInvoices
} from './selectors';
import { currentUserSelector } from '../auth/selectors';
import {
  ALL_MANAGERS,
  ALL_CLIENTS,
  ALL_ACTIVE,
  ALL,
  MY_INVOICES,
  ALL_INVOICES,
  PAST_INVOICES,
  SENT,
  UNINVOICED,
  InvoiceStartDate,
  IInvoiceResponseItem,
  invoiceStatusGroups,
  invoiceStatusList,
  IProjectEmail,
  INote,
  ENoteType,
  PENDING
} from './types';
import { initialInvoiceSidebarDetail } from './reducer';

import { ICurrentUser } from '../auth/types';
import { isAdmin } from 'src/shared/auth/utils';
import {
  actions,
  DELETE_INVOICE_NOTE,
  INIT_INVOICE_LIST_FILTERS
} from 'src/shared/invoice-list/actions';

import {
  formatAsCurrency,
  formatAsDDMMYYYY,
  QUERY_DATE_FORMAT,
  periodStart,
  formatDatePeriod
} from 'src/shared/utils';
import { sumHoursByClassification } from 'src/shared/invoice/utils';

import { DEFAULT_ITEMS_PER_PAGE } from 'src/constants';

import {
  actions as invoicesActions,
  Actions,
  GET_INVOICES_BY_PROJECT_ID,
  FETCH_INVOICES,
  CHANGE_INVOICE_FILTER,
  FETCH_INVOICE_COUNT,
  SEARCH_INVOICES,
  SELECT_INVOICE,
  FETCH_MORE_SIDEBAR_INVOICES,
  FETCH_SIDEBAR_DETAIL,
  UPDATE_INVOICE_NOTE,
  RESET_FILTER
} from './actions';

import { IInvoice } from './types';

import { formatDateForApi } from 'src/shared/utils';
import { selectLocalView } from '../invoice/selectors';
import { IUser } from '../users/types';
import { removeUTCSignal } from '../invoice/utils';
import { toast } from 'src/services/toast';
import { ProjectDetail } from 'src/api/types';
import { ProjectStatsUtil } from 'src/api/queries/fetchProjectDetail';

export const MAX_INVOICES_PER_PAGE = 3;
export const transformInvoice = (
  invoice: IInvoiceResponseItem,
  allProjects: ProjectDetail[],
  allNotes?: INote[]
) => {
  const project = allProjects.find(
    ({ id }: { id: number }) => id === invoice.projectId
  );
  const notes = allNotes
    ? allNotes.filter((note: INote) => note.invoice === invoice.id)
    : [];
  if (project && project.invoicingSpecialInstructions) {
    notes.push({
      text: project.invoicingSpecialInstructions,
      invoice: invoice.id,
      typeOf: ENoteType.INVOICE_SPECIAL_INSTRUCTION,
      createdAt: invoice.dateCreated,
      updatedAt: invoice.dateCreated
    });
  }

  return {
    ...invoice,
    projectNickname: project && project.nickname,
    projectName: project && project.name,
    projectId: project && project.id,
    projectIsActive: project && project.isActive,
    clientName: project && project.client && project.client.name,
    clientCode: project && project.client && project.client.code,
    clientId: project && project.client && project.client.id,
    clientColor: project && project.client && project.client.color,
    clientQBCustomerId:
      project && project.client && project.client.qbCustomerId,
    projectManager:
      project && project.projectManager && project.projectManager.fullName,
    projectManagerId:
      project && project.projectManager && project.projectManager.id,
    invoiceDate: invoice && formatAsDDMMYYYY(invoice.dateCreated),
    amount: formatAsCurrency(invoice.total),
    notes
  };
};

function* hydrateUserRows(userIds: number[]) {
  const userResp = yield call(getUser, userIds);
  return userResp.data.data;
}

export const reduceEntriesIntoUserRows = (
  allTimesheetEntries: { timesheet: { ownerId: number } }[]
) =>
  _(allTimesheetEntries)
    .map(
      ({ timesheet: { ownerId } }: { timesheet: { ownerId: number } }) =>
        ownerId
    )
    .uniq()
    .value();

function* transformInvoices(
  invoiceList: IInvoiceResponseItem[],
  skipNoteFetch?: boolean
) {
  const projectIds: number[] = _.uniq(
    invoiceList.map(({ projectId }: { projectId: number }) => projectId)
  );

  const response = yield call(getProjectsApi, {
    id: projectIds,
    withClient: true,
    withEmails: true,
    withProjectManager: true
  });

  const projects = response.data;
  let allNotes: INote[] = [];
  if (!skipNoteFetch) {
    allNotes = yield call(fetchAndHydrateNotes, {
      invoiceId: invoiceList.map((invoice: IInvoiceResponseItem) => invoice.id),
      users: []
    });
  }

  return invoiceList.map((i: IInvoiceResponseItem) =>
    transformInvoice(i, projects, allNotes)
  );
}

function transformUninvoicedProjects(uninvoiced: UninvoicedProject) {
  return {
    status: uninvoiced.ready ? 'UNINVOICED_READY' : 'UNINVOICED',
    amount: '--',
    hours: uninvoiced.totalHours,
    projectNickname: uninvoiced.project && uninvoiced.project.nickname,
    projectName: uninvoiced.project && uninvoiced.project.name,
    clientName: uninvoiced.client && uninvoiced.client.name,
    clientColor: uninvoiced.client && uninvoiced.client.color,
    clientCode: uninvoiced.client && uninvoiced.client.code,
    clientQBCustomerId:
      uninvoiced && uninvoiced.client && uninvoiced.client.qbCustomerId,
    projectManager:
      uninvoiced.projectManager &&
      `${uninvoiced.projectManager.firstName} ${uninvoiced.projectManager.lastName}`,
    projectManagerId: uninvoiced.projectManager && uninvoiced.projectManager.id,
    invoiceDate: formatDatePeriod(
      removeUTCSignal(uninvoiced.startDate),
      removeUTCSignal(uninvoiced.endDate),
      true
    ),
    endDate: uninvoiced.endDate,
    clientId: uninvoiced.client && uninvoiced.client.id,
    projectId: uninvoiced.project && uninvoiced.project.id,
    period: `${uninvoiced.startDate} - ${uninvoiced.endDate}`,
    notes: uninvoiced.project.invoicingSpecialInstructions
      ? [
          {
            text: uninvoiced.project.invoicingSpecialInstructions,
            invoice: null,
            typeOf: ENoteType.INVOICE_SPECIAL_INSTRUCTION,
            createdAt: uninvoiced.startDate
          }
        ]
      : null,
    canProceed: uninvoiced.ready
  };
}

function* constructFilters() {
  const {
    status: stringStatus,
    startDate: period,
    projectManager,
    client,
    projectId,
    invoices: invoicesFilter
  } = yield select(getInvoiceListFilters);

  let status = invoiceStatusGroups[stringStatus];
  if (_.eq(invoiceStatusGroups[stringStatus], UNINVOICED)) {
    status = UNINVOICED;
  }
  if (!_.isString(status)) {
    status = `${JSON.stringify(invoiceStatusGroups[stringStatus])}`;
  }

  if (projectId) {
    status = `${JSON.stringify(invoiceStatusGroups[ALL])}`;
  }

  let filters: {
    status: string;
    getTotalItems: boolean;
    endDate?: string;
    startDate?: string;
    projectManagerId?: number;
    clientId?: number;
    projectId?: number;
  } = {
    status,
    getTotalItems: true
  };

  let startDate;

  switch (period) {
    case InvoiceStartDate.LAST_MONTH:
      startDate = periodStart()
        .subtract(1, 'month')
        .format(QUERY_DATE_FORMAT);
      break;
    case InvoiceStartDate.LAST_3_MONTHS:
      startDate = periodStart()
        .subtract(3, 'month')
        .format(QUERY_DATE_FORMAT);
      break;
    case InvoiceStartDate.PAST_YEAR:
      startDate = periodStart()
        .subtract(1, 'year')
        .format(QUERY_DATE_FORMAT);
      break;
    default:
      break;
  }
  let endDate;
  if (startDate) {
    endDate = moment().format(QUERY_DATE_FORMAT);
  }

  let projectManagerId;
  if (projectManager !== ALL_MANAGERS) {
    projectManagerId = projectManager;
  }
  if (invoicesFilter === MY_INVOICES) {
    const currentUser = yield select(currentUserSelector);
    projectManagerId = currentUser.id;
  }

  let clientId;
  if (client !== ALL_CLIENTS) {
    clientId = client;
  }

  filters = {
    ...filters,
    endDate,
    startDate,
    projectManagerId,
    clientId,
    projectId
  };

  return filters;
}

type InvoiceActions = Actions & {
  payload?: {
    direction?: string;
    offset?: number;
    order?: string;
  };
};

export function* fetchUninvoices(filters: any) {
  const response = yield call(getUninvoicedProjects, filters);

  const uninvoicedProjects = response.data;
  const invoices = _.map(uninvoicedProjects, up =>
    transformUninvoicedProjects(up)
  );

  const total: number = invoices.length;
  return { invoices, total };
}

export function* fetchInvoices(action: InvoiceActions) {
  try {
    // Exit if it is not a fetch-invoiceable action
    if (
      ![FETCH_INVOICES, CHANGE_INVOICE_FILTER, RESET_FILTER].includes(
        action.type
      )
    ) {
      return;
    }

    const { status: invoiceListFiltersStatus } = yield select(
      getInvoiceListFilters
    );

    yield* put(invoicesActions.clearInvoiceDetail());
    const filters: IFetchInvoicesApiFilters = yield call(constructFilters);

    let fetchedPage = 1;
    let offset = 0;

    if (invoiceListFiltersStatus === PENDING) {
      const { invoices, total } = yield fetchUninvoices(filters);
      if (yield* cancelled()) {
        return;
      }
      return yield* put(
        invoicesActions.fetchInvoicesSucceeded({
          invoices,
          total,
          offset
        })
      );
    }

    let sortingFilters;
    if (action.payload) {
      // offset may not be provided, if no pagination
      offset = action.payload.offset || 0;

      fetchedPage = (offset + DEFAULT_ITEMS_PER_PAGE) / DEFAULT_ITEMS_PER_PAGE;

      const { direction, order } = action.payload;
      if (direction && order) {
        sortingFilters = {
          direction,
          order
        };
      }
    }

    const response = yield call(
      fetchInvoicesApi,
      fetchedPage,
      DEFAULT_ITEMS_PER_PAGE,
      filters,
      sortingFilters
    );

    const total = response.data.meta.total;
    const invoices = response.data.data;
    const transformedInvoices = yield call(transformInvoices, invoices);

    if (yield* cancelled()) {
      return;
    }
    yield* put(
      invoicesActions.fetchInvoicesSucceeded({
        invoices: transformedInvoices,
        total,
        offset
      })
    );
  } catch (e) {
    toast(
      'There was an error retrieving invoices with the current filters',
      'error'
    );
    yield call(handleError, e as Error);
  }
  return;
}

function* getInvoicesByProjectId(action: Actions) {
  try {
    if (action.type === GET_INVOICES_BY_PROJECT_ID) {
      const { projectId } = action.payload;
      const response = yield call(getInvoicesByProjectIdApi, projectId);
      const { data: responseUserData } = response.data;
      const transformedUserData: IInvoice[] = responseUserData;
      yield* put(
        invoicesActions.getInvoicesByProjectIdSucceded(transformedUserData)
      );
    }
  } catch (e) {
    toast('There was an error retrieving invoices for this project', 'error');
    yield call(handleError, e as Error);
  }
}

function* retrieveClientInvoiceCount(clientId: number, status?: string) {
  const {
    data: { count }
  } = yield call(countInvoices, { clientId, status });
  return count;
}

function* retrieveCounts() {
  const { invoices: invoicesFilter } = yield select(getInvoiceListFilters);
  let projectManagerId: number;
  if (invoicesFilter === MY_INVOICES) {
    const currentUser = yield select(currentUserSelector);
    projectManagerId = currentUser.id;
  }
  const countApiCalls = _(invoiceStatusList)
    .filter((v: string) => !_.includes([PAST_INVOICES, ALL_ACTIVE], v))
    .map((key: string) => {
      let status = invoiceStatusGroups[key];

      if (_.eq(status, UNINVOICED)) {
        return call(getUninvoicedProjects, {
          projectManagerId
        });
      }

      if (!_.isString(status)) {
        status = `${JSON.stringify(invoiceStatusGroups[key])}`;
      }

      const filters = {
        status,
        projectManagerId
      };

      return call(countInvoices, filters);
    })
    .value();

  const [
    {
      data: { count: saved }
    },
    {
      data: { count: submitted }
    },
    {
      data: { count: approved }
    },
    { data: uninvoiced },
    {
      data: { count: sent }
    }
  ] = yield all(countApiCalls);
  const counts = {
    saved,
    submitted,
    approved,
    uninvoiced: uninvoiced?.length || 0,
    sent
  };

  yield* put(invoicesActions.populateCountsValues(counts));
}

function* searchInvoices(action: Actions) {
  if (action.type === SEARCH_INVOICES) {
    const { query, active, perPage } = action.payload;
    let projectsQuery = null;
    if (active) {
      projectsQuery = yield call(getProjectsApi, {
        search: query,
        withClient: true,
        isActive: true
      });
      const relevantProjects: ProjectDetail[] = projectsQuery.data;
      yield* put(invoicesActions.populateActiveProjects(relevantProjects));
    } else {
      projectsQuery = yield call(getProjectsApi, {
        search: query,
        withClient: true,
        perPage: perPage || DEFAULT_ITEMS_PER_PAGE // limit number so it isn't a long dropdown list
      });
      const relevantProjects = projectsQuery.data;
      yield* put(invoicesActions.populateProjects(relevantProjects));
    }
  }
}

function* fetchAndHydrateNotes({
  invoiceId,
  users
}: {
  invoiceId?: number | number[];
  users: { id: number; fullName: string }[];
}) {
  if (invoiceId) {
    const notesReq = yield call(fetchInvoiceNotes, { invoiceId });
    if (_.eq(notesReq.status, 200)) {
      const data = notesReq.data;

      const missingUsers = _(data)
        .map(({ note: { userId } }: { note: { userId: number } }) => userId)
        .filter(
          (userId: number) =>
            !users.some(({ id }: { id: number }) => id === userId)
        )
        .uniq()
        .value();

      let fetchedUsers: IUser[] = [];
      if (missingUsers.length) {
        fetchedUsers = yield call(hydrateUserRows, missingUsers);
      }

      const allUsers = users.concat(fetchedUsers);
      const notes = _.map(data, function({
        note,
        invoice
      }: {
        note: { userId: number; typeOf: string };
        invoice: number;
      }) {
        const { userId } = note;
        const userObj = _.find(
          allUsers,
          ({ id, fullName }: { id: number; fullName: string }) => id === userId
        );

        const userName = userObj ? userObj.fullName : 'Grio';

        return {
          ...note,
          typeOf: ENoteType[note.typeOf],
          userName,
          invoice
        };
      });
      return yield all(notes);
    }
  }
  return [];
}

function* fetchSidebarDetail(action: Actions) {
  if (action.type === SELECT_INVOICE) {
    yield* put(actions.populateSidebarDetail(initialInvoiceSidebarDetail));
    const invoice = yield call(Api.fetchInvoice, action.payload.id);
    const clientId = invoice.clientId;
    const projectReq = yield call(getProjectDetail, invoice.projectId);
    const project = projectReq.data;
    const invoiceCount = yield call(
      retrieveClientInvoiceCount,
      clientId,
      `${invoiceStatusGroups[SENT]}`
    );
    const response = yield call(fetchInvoicesApi, 1, MAX_INVOICES_PER_PAGE, {
      clientId: invoice.clientId,
      status: `${invoiceStatusGroups[SENT]}`
    });
    const { data: invoiceResponseData } = response.data;
    const invoices: IInvoice[] = yield call(
      transformInvoices,
      invoiceResponseData,
      true
    );

    const projectStatsReq = yield* typedCall(getClientProjects, {
      projectId: invoice.projectId,
      status: invoice.projectIsActive
    });

    const projectStats = _.first(projectStatsReq.data.projects);
    const projectStatsUtil = new ProjectStatsUtil();
    projectStats.spent = projectStatsUtil.getSpentAmount(projectStats);
    projectStats.uninvoicedAmount = projectStatsUtil.getUninvoicedAmount(
      projectStats
    );
    projectStats.totalAmount = projectStatsUtil.getTotalAmount(projectStats);
    projectStats.hoursWorked = projectStatsUtil.hoursWorked(
      projectStats.invoicingHours,
      projectStats.hoursUninvoiced,
      projectStats.hoursUnapproved
    );
    let endDate;
    if (invoice.period) {
      [, endDate] = invoice.period
        .split(' - ')
        .map((v: string) => _.trim(removeUTCSignal(v)));
    } else {
      endDate = removeUTCSignal(invoice.endDate);
    }

    /* this retrieves 3 invoice periods for the selcted invoice to get recent members of the project  */
    const sixWeeksBeforeEnd = moment(endDate)
      .subtract(6, 'weeks')
      .format();
    const timesheetsReq = yield call(Api.fetchTimesheetEntries, {
      page: 1,
      perPage: 300,
      withProjectRoleId: true,
      withTimesheet: true,
      endDate: formatDateForApi(endDate),
      startDate: formatDateForApi(sixWeeksBeforeEnd),
      uninvoiced: !invoice.id,
      projectId: invoice.projectId
    });

    const timesheetEntries = timesheetsReq.data;

    let users = [];
    if (timesheetEntries.length) {
      const userResp = yield hydrateUserRows(
        reduceEntriesIntoUserRows(timesheetEntries)
      );
      users = userResp;
    }

    const billableHours = sumHoursByClassification(
      timesheetEntries,
      'billable'
    );
    const nonBillableHours = sumHoursByClassification(
      timesheetEntries,
      'non_billable'
    );

    const projectEmails = _(project.invoiceEmailTos)
      .map((e: IProjectEmail) => ({
        ...e,
        type: 'To'
      }))
      .concat(
        _.map(project.invoiceEmailCcs, (e: IProjectEmail) => ({
          ...e,
          type: 'Cc'
        }))
      )
      .value();

    let notes = [];
    if (invoice && invoice.id) {
      notes = yield call(fetchAndHydrateNotes, {
        invoiceId: invoice.id,
        users
      });
    }
    if (project.invoicingSpecialInstructions) {
      notes.push({
        text: project.invoicingSpecialInstructions
      });
    }

    yield* put(
      actions.populateSidebarDetail({
        deliverables: project.deliverables,
        projectEmails,
        users,
        projectStats,
        invoices,
        totalInvoices: invoiceCount,
        notes,
        billableHours,
        nonBillableHours
      })
    );
  }
}

function* fetchSidebarDetailFromDetailView(action: Actions) {
  if (action.type === FETCH_SIDEBAR_DETAIL) {
    const view = yield select(selectLocalView);
    if (view) {
      const { data: projectStatsReq } = yield* typedCall(getClientProjects, {
        projectId: view.projectId,
        status: view.projectIsActive
      });

      const projectStatsSerialized = _.first(projectStatsReq.projects);
      const projectStatsUtil = new ProjectStatsUtil();
      const projectStats: any = {
        ...projectStatsSerialized,
        hoursWorked: projectStatsUtil.hoursWorked(
          projectStatsSerialized.invoicingHours,
          projectStatsSerialized.hoursUninvoiced,
          projectStatsSerialized.hoursUnapproved
        ),
        remainingHours: projectStatsUtil.getRemainingHours(
          projectStatsSerialized.estimatedHours,
          projectStatsSerialized.invoicingHours,
          projectStatsSerialized.hoursUninvoiced,
          projectStatsSerialized.hoursUnapproved
        )
      };
      const clientId = view.clientId;
      const invoiceCount = yield call(
        retrieveClientInvoiceCount,
        clientId,
        `${invoiceStatusGroups[SENT]}`
      );

      const response = yield call(fetchInvoicesApi, 1, MAX_INVOICES_PER_PAGE, {
        clientId: view.clientId,
        status: `${invoiceStatusGroups[SENT]}`
      });
      const { data: invoiceResponseData } = response.data;
      const invoices: IInvoice[] = yield call(
        transformInvoices,
        invoiceResponseData,
        true
      );

      const notes = yield call(fetchAndHydrateNotes, {
        invoiceId: view.id,
        users: view.userRows
      });

      if (view.invoicingSpecialInstructions) {
        notes.push({
          text: view.invoicingSpecialInstructions
        });
      }

      yield* put(
        actions.populateSidebarDetail({
          deliverables: view.deliverables,
          projectEmails: view.projectEmails,
          users: view.userRows,
          projectStats,
          invoices,
          totalInvoices: invoiceCount,
          notes
        })
      );
    }
  }
}

function* fetchMoreSidebarInvoices(action: Actions) {
  const currentInvoices = yield select(currentSidebarInvoices);
  const invoice = yield select(currentSelectedInvoice);
  const view = yield select(selectLocalView);
  const clientId = invoice?.clientId || view?.clientId;
  const fetchedPage = Math.floor(
    currentInvoices.length / MAX_INVOICES_PER_PAGE
  );

  const response = yield call(
    fetchInvoicesApi,
    fetchedPage + 1,
    MAX_INVOICES_PER_PAGE,
    {
      clientId: clientId,
      status: `${invoiceStatusGroups[SENT]}`
    }
  );
  const { data: invoiceResponseData } = response.data;

  const invoices: IInvoice[] = yield call(
    transformInvoices,
    invoiceResponseData
  );

  yield* put(actions.concatInvoices(invoices));
}

function* deleteInvoiceNote(action: Actions) {
  if (action.type === DELETE_INVOICE_NOTE) {
    const { id } = action.payload;
    const req = yield call(deleteInvoiceNoteApi, { id });
    if (_.eq(req.status, 200)) {
      toast(`Invoice Note Deleted`, 'success');
    } else {
      const error = req.data.errors;
      toast(`Something went wrong: ${error}`, 'error');
    }
  }
}

function* updateInvoiceNote(action: Actions) {
  try {
    if (action.type === UPDATE_INVOICE_NOTE) {
      const { id, text } = action.payload;
      const req = yield call(updateInvoiceNoteApi, { id, text });
      if (_.eq(req.status, 200)) {
        toast(`Invoice Note Updated`, 'success');
      } else {
        const error = req.data.errors;
        toast(`Something went wrong: ${error}`, 'error');
      }
    }
  } catch (e) {
    const error = e.response ? e.response.data : 'No additional Data Available';
    toast(`Something went wrong: ${error}`, 'error');
  }
}

function* initInvoiceListFilters(action: Actions) {
  if (action.type === INIT_INVOICE_LIST_FILTERS) {
    const user: ICurrentUser = yield select(currentUserSelector);
    yield* put(actions.resetFilters());
    if (isAdmin(user)) {
      yield* put(
        actions.changeInvoiceFilter({ key: 'invoices', value: ALL_INVOICES })
      );
    }
  }
}

function* changeFilter(action: InvoiceActions) {
  action.payload;
  return yield* put(actions.fetchInvoices(action.payload || {}));
}

function* invoiceListSaga() {
  yield all([
    takeLatest(GET_INVOICES_BY_PROJECT_ID, getInvoicesByProjectId),
    takeLatest(FETCH_INVOICES, fetchInvoices),
    takeLatest(CHANGE_INVOICE_FILTER, changeFilter),
    takeLatest(FETCH_INVOICE_COUNT, retrieveCounts),
    takeLatest(SEARCH_INVOICES, searchInvoices),
    takeLatest(SELECT_INVOICE, fetchSidebarDetail),
    takeLatest(FETCH_SIDEBAR_DETAIL, fetchSidebarDetailFromDetailView),
    takeLatest(FETCH_MORE_SIDEBAR_INVOICES, fetchMoreSidebarInvoices),
    takeLatest(DELETE_INVOICE_NOTE, deleteInvoiceNote),
    takeLatest(UPDATE_INVOICE_NOTE, updateInvoiceNote),
    takeLatest(INIT_INVOICE_LIST_FILTERS, initInvoiceListFilters)
  ]);
}

export default invoiceListSaga;
