import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { ProjectMetric } from '../types';

export const createProjectMetric = (metric: ProjectMetric) =>
  callAPI<{ projectMetric: ProjectMetric }>({
    method: 'post',
    data: metric,
    url: '/project_metrics'
  })
    .then(response => response.data.projectMetric)
    .catch(error => {
      toast('Failed to create project metric', 'error');
      throw error;
    });
