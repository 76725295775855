import { Box } from '@mui/material';
import styled, { theme } from 'src/theme';
import { Accordion, AccordionSummary } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AccordionDetails } from '@mui/material';

export const ResourceBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 14px;
`;

export const InvoiceDrawer = styled.div`
  margin-top: 166px;
  height: calc(100vh - 126px);
  &.timesheet-page {
    margin-top: 156px;
    height: calc(100vh - 321px);
  }
  &.timesheet-page-past {
    margin-top: 137px;
    height: calc(100vh - 97px);
  }
  width: 384px;
  display: block;
  padding: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  border: ${theme.border};
`;

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & svg {
    cursor: pointer;
  }
  overflow: hidden;
`;

export const FlexBoxTitle = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 20px;
  & svg {
    cursor: pointer;
    margin-right: 21px;
  }
  overflow: hidden;
`;

export const ColumnFlexBox = styled(FlexBox)`
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 0 0;
  width: initial;
  margin-bottom: ${props => props['data-margin-bottom']} !important;

  &.timesheets-approval {
    padding: 0;
  }
`;

export const SidebarHeader = styled.div`
  && {
    color: #68797e;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    font-family: ${theme.typography.fontFamily};
  }
`;

export const HeaderIcon = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 100%;
  margin-right: 6px;
  background-color: ${props => props.color};
`;

export const TabIcon = styled(HeaderIcon)`
  width: 12px;
  height: 12px;
  margin-right: 10px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  overflow-y: visible;
  overflow-x: hidden;
  font-size: 14px;
  font-family: ${theme.typography.fontFamily};
  padding-bottom: 50px;
  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
  }
  .MuiAccordionDetails-root {
    padding: 8px 10px 24px;
`;

export const AccordionCustom = styled(Accordion)`
  &&
  {
    margin-top 0px;
    background-color: white;
    border-bottom: none !important;
    margin-bottom: 6px;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
    border: 1.4px solid #cdcdcd !important;
    border-radius: 4px;
    padding: 2px;
  }


`;

export const AccordionSummaryCustom = styled(AccordionSummary)`
  && {
    background-color: #f5f5f5;
    height: 35px;
    max-height: 35px !important;
    min-height: 35px !important;
    padding-left: 10px;
    padding-right: 34px;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
`;

export const AccordionDetailsCustom = styled(AccordionDetails)`
  && {
    display: flex;
    flex-direction: column;
  }
`;

export const AddCircleIconCustom = styled(AddCircleIcon)`
  color: #00838f;
  height: 40px !important;
  width: 40px !important;
  z-index: 2;
  position: absolute;
  right: 10px;
  background-color: #ffffff;
`;

export const CustomDivision = styled.hr`
  background-color: #e9e9e9 !important;
  height: 1px;
  border: none;
  margin: 0.5em;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
`;

export const NoContentBox = styled(Box)`
  padding-top: 116px;
  font-size: 14px;
  display: flex;
  stify-content: center;
`;

export const StatusContainer = styled.div`
  && {
    width: 100%;
  }
`;

export const FlexBoxProject = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
  }
`;

export const Tabs = styled.div`
  && {
    display: flex;
    width: 308px;
    margin-bottom: -21px;
    position: relative;
  }
`;

export const Tab = styled(Box)`
  && {
    color: #757575;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    flex-direction: row;
    display: flex;
    font-family: ${theme.typography.fontFamily};
    align-items: center;
    cursor: pointer;
    height: 50px;
    position: relative;
    z-index: 1;
    width: 154px;
    justify-content: center;
  }
`;

export const AddDivision = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & svg {
    cursor: pointer;
  }
  overflow: hidden;
  height: 40px;
  position: relative;
`;

export const TabEffect = styled.hr`
  position: absolute;
  bottom: 0;
  background-color: #000000 !important;
  height: 1px;
  border: none;
  margin: initial;
  margin-right: 0;
  width: 140px;
  z-index: 2;
`;

export const TabName = styled.span`
  && {
    display: flex;
  }
`;
