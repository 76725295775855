import React, { useState } from 'react';
import CustomTab from '../../components/CustomTab/styles';
import { Tab, Typography } from '@mui/material';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import messages from '../../messages';
import { CustomLink } from './styles';

const RightSideMenu: React.FC<any> = ({ timesheet, path }) => {
  const noTimesheetSelected = (
    <Typography style={{ marginTop: 10, textAlign: 'center' }} variant="h5">
      {messages.NO_TIMESHEET_SELECTED}
    </Typography>
  );
  const tabNames = ['timesheet', 'summary'];
  const currentTabPath = path.split('/')[3];
  const currentTabNumber = tabNames.indexOf(currentTabPath);
  const [currentTab, setCurrentTab] = useState(currentTabNumber);

  return timesheet ? (
    <React.Fragment>
      <CustomTab
        value={currentTab}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab
          icon={<LibraryBooksIcon />}
          label="Timesheet"
          onClick={() => {
            setCurrentTab(0);
          }}
          component={CustomLink}
          to={`/timesheet-approvals/${timesheet.id}/timesheet`}
        />
        <Tab
          icon={<EqualizerIcon />}
          label="Summary"
          onClick={() => {
            setCurrentTab(1);
          }}
          component={CustomLink}
          to={`/timesheet-approvals/${timesheet.id}/summary`}
        />
      </CustomTab>
    </React.Fragment>
  ) : (
    noTimesheetSelected
  );
};

export default RightSideMenu;
