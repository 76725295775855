import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TeamList from 'src/components/shared/RightSidebar/RightSidebarInfoPane/TeamList';
import { IInvoiceDetailView } from 'src/shared/invoice/types';
import {
  IInvoiceSidebarDetail,
  InvoiceStatus
} from 'src/shared/invoice-list/types';
import Budget from './Budget';
import Invoices from './Invoices';
import ProjectEmails from './ProjectEmails';
import SpecialInstructions from './SpecialInstructions';
import RejectionNotes from './RejectionNotes';

import {
  InvoicePopoverContainer,
  Header,
  HeaderIcon,
  HeaderTitle,
  InvoiceLink,
  Section,
  SectionInfo
} from './styles';

interface IProps {
  canEdit: boolean;
  invoiceSidebarDetail: IInvoiceSidebarDetail;
  view?: IInvoiceDetailView;
  onClose: () => void;
}

export default function InvoicePopover(props: IProps) {
  const { canEdit, invoiceSidebarDetail, view, onClose } = props;

  return (
    <InvoicePopoverContainer>
      <Header>
        <HeaderTitle>
          <HeaderIcon color={view?.clientColor} />
          <h2 className="title">
            {view?.clientCode} - {view?.projectNickname}
          </h2>
        </HeaderTitle>
        <IconButton className="close-button" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Header>
      <Section className="project-section">
        <h4 className="section-header">Project</h4>
        <SectionInfo>
          <li className="section-info">
            <span className="section-info-label">Client</span>
            <span className="section-info-value">
              <InvoiceLink to={`/client-management/${view?.clientId}`}>
                {view?.clientName}
              </InvoiceLink>
            </span>
          </li>
          <li className="section-info">
            <span className="section-info-label">Project</span>
            <span className="section-info-value">
              <InvoiceLink to={`/client-projects/${view?.projectId}/general`}>
                {view?.projectName}
              </InvoiceLink>
            </span>
          </li>
          <li className="section-info">
            <span className="section-info-label">Deliverable</span>
            <span className="section-info-value">
              {view?.deliverables &&
                view?.deliverables.map(d => d.name).join(', ')}
            </span>
          </li>
          <li className="section-info">
            <span className="section-info-label">QuickBooks</span>
            <span className="section-info-value">
              {view?.qbInvoiceId ? (
                <a
                  href={`${process.env.REACT_APP_QB_INVOICE_BASE_URL}/app/invoice?txnId=${view?.qbInvoiceId}`}
                >
                  View QuickBook Invoice
                </a>
              ) : (
                '--'
              )}
            </span>
          </li>
          <li className="section-info">
            {view && (
              <TeamList
                users={invoiceSidebarDetail.users.concat({
                  id: view.projectManagerId,
                  fullName: view?.projectManager
                })}
                projectManagerId={view?.projectManagerId}
              />
            )}
          </li>
        </SectionInfo>
      </Section>
      {view?.status === InvoiceStatus.REJECTED && (
        <Section>
          <RejectionNotes
            invoiceSidebarDetail={invoiceSidebarDetail}
            view={view}
          />
        </Section>
      )}
      <Section className="hours-section">
        <h4 className="section-header">Hours</h4>
        <SectionInfo>
          <li className="section-info value-end">
            <span className="section-info-label">Total Billable</span>
            <span className="section-info-value">
              {invoiceSidebarDetail.billableHours
                ? `${invoiceSidebarDetail.billableHours} h`
                : '--'}
            </span>
          </li>
          <li className="section-info value-end">
            <span className="section-info-label">Total Non-billable</span>
            <span className="section-info-value">
              {invoiceSidebarDetail.nonBillableHours
                ? `${invoiceSidebarDetail.nonBillableHours} h`
                : '--'}
            </span>
          </li>
        </SectionInfo>
      </Section>
      <Section className="special-instructions-section">
        <SpecialInstructions
          canEdit={canEdit}
          invoiceSidebarDetail={invoiceSidebarDetail}
        />
      </Section>
      <Section className="budget-section">
        <Budget invoiceSidebarDetail={invoiceSidebarDetail} />
      </Section>
      <Section className="invoice-section">
        <Invoices invoiceSidebarDetail={invoiceSidebarDetail} view={view} />
      </Section>
      <Section className="emails-section">
        <ProjectEmails invoiceSidebarDetail={invoiceSidebarDetail} />
      </Section>
    </InvoicePopoverContainer>
  );
}
