import React from 'react';

import {
  PeriodHeader,
  DisplayRow,
  ToggleButton,
  PrevIcon,
  NextIcon
} from './styles';

interface ITimesheetPeriod {
  timesheetDate: string;
  requestTimesheetById: (timesheetId: number) => void;
  previousId: number | null;
  nextId: number | null;
}

export class TimesheetPeriod extends React.Component<ITimesheetPeriod> {
  render() {
    const { timesheetDate, previousId, nextId } = this.props;

    const handleNextClick = (timesheetId: number | null) => {
      if (timesheetId) {
        this.props.requestTimesheetById(timesheetId);
      }
    };

    const handlePreviousClick = (timesheetId: number | null) => {
      if (timesheetId) {
        this.props.requestTimesheetById(timesheetId);
      }
    };

    return (
      <PeriodHeader>
        <DisplayRow>
          <DisplayRow>
            <ToggleButton
              className={previousId ? 'active' : 'disabled'}
              onClick={() => handlePreviousClick(previousId)}
            >
              <PrevIcon></PrevIcon>
              <div>Past</div>
            </ToggleButton>
            <div>{timesheetDate}</div>
            <ToggleButton
              className={nextId ? 'active' : 'disabled'}
              onClick={() => handleNextClick(nextId)}
            >
              <div>Next</div>
              <NextIcon></NextIcon>
            </ToggleButton>
          </DisplayRow>
        </DisplayRow>
      </PeriodHeader>
    );
  }
}

export default TimesheetPeriod;
