import { AxiosError } from 'axios';
import _ from 'lodash';
import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { IUserPayload } from 'src/shared/users/types';

export const createUser = (user: IUserPayload) =>
  callAPI({
    data: { user },
    method: 'post',
    url: '/users'
  })
    .then(response => {
      toast('User created', 'success');
      return response.data;
    })
    .catch(error => {
      toast(errorMessage(error), 'error');
      throw error;
    });

const errorMessage = (error: AxiosError<any>) => {
  const messages = ['There was an error creating the user'];

  try {
    Object.keys(error.response?.data).forEach(key => {
      error.response?.data[key].forEach((problem: string) => {
        messages.push(`${_.capitalize(key)} ${problem}`);
      });
    });
  } catch (error) {
    console.error(
      'found unexpected format when parsing error messages for failed user creation:',
      error
    );
  }

  return messages.join('. ');
};
