import { IconButton, InputAdornment, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Add from '@mui/icons-material/Add';
import FilterList from '@mui/icons-material/FilterList';
import Search from '@mui/icons-material/Search';
import * as React from 'react';
import {
  AutoSizer,
  Index,
  RowMouseEventHandlerParams
} from 'react-virtualized';
import { IListData } from 'src/shared/types';
import {
  AddFab,
  Container,
  CustomPopover,
  CustomTableStyled,
  CustomTextField,
  FiltersContainer,
  InputWrapper,
  ListContainer,
  NoRows,
  SearchButton
} from './styles';
import { FunctionComponent, useState } from 'react';

interface ICustomTableProps {
  showFilter?: boolean;
  loading?: boolean;
  data: IListData;
  onAddClick: () => void;
  goToDetail: (event: RowMouseEventHandlerParams) => void;
  children: React.ReactNode;
  selectedItem?: any;
}

interface IClick {
  currentTarget: HTMLElement;
}

interface ICustomTableState {
  anchorEl: HTMLElement | null;
  popoverOpen: boolean;
  change: string;
}

export const CustomTable: FunctionComponent<ICustomTableProps> = props => {
  const [state, setState] = useState<ICustomTableState>({
    anchorEl: null,
    change: '',
    popoverOpen: false
  });

  const filter = () => {
    const { showFilter } = props;

    if (!showFilter) {
      return null;
    }

    return (
      <FiltersContainer>
        <CustomTextField
          id="name-input"
          onChange={onFilterChange}
          value={''}
          margin="normal"
          variant="filled"
          fullWidth={true}
          placeholder="Enter the search"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="Advanced search" onClick={openPopover}>
                  <FilterList />
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
        />
        <CustomPopover
          open={state.popoverOpen}
          anchorEl={state.anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom'
          }}
          transformOrigin={{
            horizontal: 'left',
            vertical: 'top'
          }}
        >
          <InputWrapper>
            <TextField
              id="name-input"
              onChange={onFilterChange}
              label="Name"
              value={''}
              margin="normal"
              variant="filled"
              fullWidth={true}
            />
          </InputWrapper>
          <InputWrapper>
            <TextField
              id="name-input"
              onChange={onFilterChange}
              label="Name"
              value={''}
              margin="normal"
              variant="filled"
              fullWidth={true}
            />
          </InputWrapper>
          <InputWrapper>
            <TextField
              id="name-input"
              onChange={onFilterChange}
              label="Name"
              value={''}
              margin="normal"
              variant="filled"
              fullWidth={true}
            />
          </InputWrapper>
          <SearchButton variant="contained" onClick={openPopover}>
            Search
          </SearchButton>
          <SearchButton variant="contained" onClick={openPopover}>
            Clear
          </SearchButton>
        </CustomPopover>
      </FiltersContainer>
    );
  };

  const rowClassName = (event: any) => {
    if (props.selectedItem && props.data[event.index]) {
      if (props.data[event.index].id === props.selectedItem.id) {
        return 'active row';
      }
    }
    return 'row';
  };

  const onFilterChange = () => {
    setState({
      ...state,
      change: '123'
    });
  };

  const rowGetter = (event: Index) => {
    return props.data[event.index];
  };

  const openPopover = (event: IClick) => {
    setState({
      ...state,
      anchorEl: event.currentTarget,
      popoverOpen: true
    });
  };

  const closePopover = () => {
    setState({
      ...state,
      anchorEl: null,
      popoverOpen: false
    });
  };

  const noRows = () => {
    if (props.loading) {
      return (
        <NoRows>
          <CircularProgress />
        </NoRows>
      );
    }

    return (
      <NoRows>
        <p>Nothing here</p>
      </NoRows>
    );
  };

  const { children, loading, data } = props;

  return (
    <Container>
      {filter()}
      <ListContainer>
        <AutoSizer>
          {({ height, width }) => (
            <CustomTableStyled
              onRowClick={props.goToDetail}
              headerClassName="headerRow"
              rowClassName={rowClassName}
              headerHeight={50}
              height={height}
              rowCount={loading ? 0 : data.length}
              rowHeight={80}
              rowGetter={rowGetter}
              width={width}
              noRowsRenderer={noRows}
            >
              {children}
            </CustomTableStyled>
          )}
        </AutoSizer>
        <AddFab onClick={props.onAddClick} color="primary" aria-label="Add new">
          <Add />
        </AddFab>
      </ListContainer>
    </Container>
  );
};

export default CustomTable;
