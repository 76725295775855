import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';

export const rejectTimesheet = ({
  id,
  reason
}: {
  id: number;
  reason: string;
}) =>
  callAPI({
    method: 'post',
    url: `/timesheets/reject/${id}`,
    data: {
      timesheet: { rejectionReason: reason }
    }
  })
    .then(response => response.data)
    .catch(error => {
      toast('Failed to reject timesheet', 'error');
      throw error;
    });
