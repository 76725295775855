import React, { FunctionComponent, useEffect, useState } from 'react';
import _ from 'lodash';
import AddEntry from 'src/components/AddEntry/AddEntry';
import AddTimeOff from 'src/components/AddEntry/AddTimeOff';
import TimesheetEntryList, {
  ITimesheetEntryList
} from 'src/components/TimesheetEntryList';
import {
  CustomReaderModeIcon,
  ExpandingBox,
  TopContainer,
  AddEntryWrapper
} from './styles';
import TimesheetSidebar from 'src/components/TimesheetSidebar';
import { Tabs, Tab, TabName, TabEffect } from '../SideBarRight/styles';
import TimesheetPeriod from '../TimesheetSidebar/TimesheetEntryDialog/TimesheetPeriod';
import { formatDatePeriod, isWeekend } from 'src/shared/utils';
import LoadingPage from '../shared/LoadingPage';
import { ICurrentUser } from 'src/shared/auth/types';
import { UserClassification } from 'src/shared/users/types';
import moment, { Moment } from 'moment';
import { multiDateInterpolation } from 'src/components/shared/inputs/MomentDateSelect';
import {
  Holiday,
  Timesheet,
  TimesheetSidebarDetail,
  TimesheetStatusEnum,
  UserProjectRole
} from 'src/api/types';
import { CreateTimesheetEntryParams } from 'src/api/queries/createTimesheetEntry';

export type IUserTimesheet = ITimesheetEntryList & {
  requestTimesheetById: (timesheetId: number) => void;
  isLoading: boolean;
  timesheetSidebarDetail?: TimesheetSidebarDetail;
  fetchMoreTimesheets: () => void;
  openTimesheetRowEntryDialog?: (timesheetId: number) => void;
  user: ICurrentUser;
  timesheet: Timesheet;
  userProjectRoles: UserProjectRole[];
  addEntry: (entry: CreateTimesheetEntryParams) => void;
  leftDrawerIsOpen?: boolean;
};

export type IUserTimesheetState = {
  activeTab: number;
  rightPanelOpen: boolean;
  timesheetEntryDialog: boolean;
  dates: Moment[];
};

const EDITABLE_STATUS = [TimesheetStatusEnum.NEW, TimesheetStatusEnum.REJECTED];

export const UserTimesheet: FunctionComponent<IUserTimesheet> = props => {
  const [dates, setDates] = useState<Moment[]>([
    moment() // defaults selection to today's date
  ]);
  const [state, setState] = useState({
    activeTab: 0,
    rightPanelOpen: false,
    timesheetEntryDialog: false
  });

  useEffect(() => {
    if (!props.timesheet) return;

    sanitizeDates(props.timesheet);
  }, [props.timesheet]);

  const sanitizeDates = (timesheet: Timesheet) => {
    // remove any dates that fall outside the timesheet's date range
    const newDates = dates.slice().filter(date => {
      return !(
        date.isAfter(moment(timesheet.endDate).endOf('day')) ||
        date.isBefore(moment(timesheet.startDate).endOf('day'))
      );
    });
    // if no dates are selected, select timesheet's start date
    if (!newDates.length) {
      newDates.push(moment(timesheet.startDate));
    }
    setDates(newDates);
  };

  const toggleTabs = (index: number) => {
    setState({ ...state, activeTab: index });
  };

  const toggleRightPanel = () => {
    setState({ ...state, rightPanelOpen: !state.rightPanelOpen });
  };

  // multiSelect is true if either ctrl or command keys are pressed
  // at the same time the mouse click occurs
  const onSelectDay = (date: Moment, multiSelect: boolean) => {
    if (dates.some(d => d.isSame(date))) {
      // ignore if user has clicked an already selected date
      return;
    }

    if (!isWeekend(date) || state.activeTab === 0) {
      if (multiSelect) {
        const newDates = _.map(
          multiDateInterpolation(
            date.toDate(),
            _.map(dates, (d: Moment) => d.toDate())
          ),
          (d: Date) => moment(d)
        );
        setDates(newDates);
      } else {
        let newDates = [date];
        if (dates.length === 1) {
          const currentDate = dates[0];
          const isReselect = currentDate.isSame(date, 'day');
          if (isReselect) {
            newDates = [];
          }
        }
        setDates(newDates);
      }
    }
  };

  const { activeTab, rightPanelOpen } = state;
  const {
    userProjectRoles,
    timesheet,
    updateTimesheetEntry,
    deleteTimesheetEntry,
    isLoading,
    timesheetSidebarDetail,
    fetchMoreTimesheets,
    openTimesheetRowEntryDialog,
    user,
    leftDrawerIsOpen
  } = props;

  if (!timesheet) return null;

  if (isLoading) return <LoadingPage />;

  const timesheetDate = timesheet
    ? formatDatePeriod(timesheet.startDate, timesheet.endDate, true)
    : null;

  const tabs = [
    { title: 'Hours Worked', iconColor: null },
    { title: 'Time Off', iconColor: null }
  ];

  // Disabled holiday/weekends when Time Off Tab is selected
  const disabledDates = _.eq(user.classification, UserClassification.SALARIED)
    ? _(timesheet.timesheetDays)
        .map(
          ({
            holiday,
            date
          }: {
            holiday: Holiday | null;
            date: string;
          }): Moment | null => {
            const isTimeOffEntry = activeTab === 1;
            if (isTimeOffEntry) {
              const dayOfWeek = moment(date).weekday();
              const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
              return holiday || isWeekend ? moment(date, 'YYYY-MM-DD') : null;
            }

            return null;
          }
        )
        .compact()
        .value()
    : [];

  return (
    <React.Fragment>
      <TopContainer className={leftDrawerIsOpen ? '' : 'expanded'}>
        <ExpandingBox>
          <TimesheetPeriod
            timesheetDate={String(timesheetDate)}
            requestTimesheetById={props.requestTimesheetById}
            previousId={timesheet.previousId || null}
            nextId={timesheet.nextId || null}
          />
        </ExpandingBox>
        <CustomReaderModeIcon onClick={toggleRightPanel} />
      </TopContainer>
      <AddEntryWrapper className={rightPanelOpen ? 'compressed' : ''}>
        {_.includes(EDITABLE_STATUS, timesheet?.status) && (
          <React.Fragment>
            <Tabs>
              {tabs
                ? _.map(tabs, (tab, index) => (
                    <React.Fragment key={index}>
                      <Tab
                        key={index}
                        onClick={e => {
                          toggleTabs(index);
                        }}
                      >
                        <TabName>{tab.title}</TabName>
                      </Tab>
                      <TabEffect
                        key={`${index}-effect`}
                        className={
                          activeTab === 0
                            ? 'activeTabEffect'
                            : 'inactiveTabEffect'
                        }
                      />
                    </React.Fragment>
                  ))
                : null}
            </Tabs>
            {_.eq(activeTab, 0) && (
              <AddEntry
                addEntry={props.addEntry}
                userProjectRoles={userProjectRoles.filter(
                  userProjectRole => userProjectRole.isVisible
                )}
                timesheet={timesheet}
                disabledDates={disabledDates}
                selectedDates={dates}
                setDates={setDates}
              />
            )}
            {_.eq(activeTab, 1) && (
              <AddTimeOff
                addEntry={props.addEntry}
                timesheet={timesheet}
                disabledDates={disabledDates}
                selectedDates={dates}
                setDates={setDates}
              />
            )}
          </React.Fragment>
        )}
      </AddEntryWrapper>
      <TimesheetEntryList
        timesheet={timesheet}
        userProjectRoles={userProjectRoles}
        rightPanelOpen={rightPanelOpen}
        updateTimesheetEntry={updateTimesheetEntry}
        deleteTimesheetEntry={deleteTimesheetEntry}
        onSelectDay={onSelectDay}
        selectedDates={dates}
      />
      {rightPanelOpen && (
        <TimesheetSidebar
          timesheetSidebarDetail={timesheetSidebarDetail}
          toggleSidePanel={toggleRightPanel}
          hideTeamDetails={true}
          hideTimesheetLink={true}
          pageCss={
            _.eq(timesheet?.status, TimesheetStatusEnum.NEW)
              ? 'timesheet-page'
              : 'timesheet-page-past'
          }
          fetchMoreTimesheets={fetchMoreTimesheets}
          onClickTimesheet={openTimesheetRowEntryDialog}
        />
      )}
    </React.Fragment>
  );
};

export default UserTimesheet;
