import styled, { theme } from '../../theme';

export const LogoText = styled('h1')<{ center: boolean }>`
  font-size: 6rem;
  font-weight: 700;
  width: 100%;
  text-align: ${props => (props.center ? 'center' : 'initial')};
  @media only screen and (max-width: ${theme.breakpointsSelf.sm}) {
    font-size: 4rem;
  }
`;

export const SmallLogo = styled('h3')<{ center: boolean }>`
  font-size: 2rem;
  font-weight: 700;
  width: 100%;
  text-align: ${props => (props.center ? 'center' : 'initial')};
  color: ${theme.primaryColorInverted};
  padding-left: 5px;
`;

export const StyledP = styled('p')<{ center: boolean }>`
  width: 100%;
  font-size: 1rem;
  text-align: ${props => (props.center ? 'center' : 'initial')};
`;

export const Span = styled('span')<{ center: boolean }>`
  font-size: 1rem;
  text-align: ${props => (props.center ? 'center' : 'initial')};
`;

export const H1 = styled.h1`
  font-size: 3.75rem;
`;
