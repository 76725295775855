import { Button } from '@mui/material';
import styled from 'src/theme';

export const StyledButton = styled(Button)`
  && {
    background-color: #00838f;
    font-size: 16px;
    height: 36px;
    color: white;
    white-space: nowrap;
    padding: 6px 15px;
    margin: 8px 18px 0;
    text-transform: none;
    & span {
      width: 108px;
      font-weight: 200;
    }
  }

  &&:hover {
    background-color: #4ca8b0;
  }

  &&.MuiButton-root.Mui-disabled {
    color: #fff;
    background-color: #7fc1c7;
  }
`;
