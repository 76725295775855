import React, { useState } from 'react';
import _ from 'lodash';
import { InputAdornment, MenuItem } from '@mui/material';
import { CustomInput } from './styles';
import { validateNewValue } from 'src/components/InvoiceDetail/validations';
import { typeGuard } from 'src/shared/utils';
import Calendar from '@mui/icons-material/CalendarToday';
import { TimesheetEntryClassification } from 'src/api/types';
export enum InputType {
  DATE = 'date',
  NUMBER = 'number',
  CURRENCY = 'currency',
  STRING = 'text',
  DROPDOWN = 'dropdown',
  LONG_STRING = 'long string',
  INFINITE_CALENDAR = 'infinite-calendar'
}

interface IInputField {
  type: InputType;
  value: string | number | string[];
  modifyNewItemValue: (
    value: number | string | boolean,
    dataKey: string
  ) => void;
  validation: string;
  disabled?: boolean;
  autoFocus?: boolean;
  immediateChange?: boolean;
  fullHeight?: boolean;
  charLimit?: number;
  label?: string;
  required?: boolean;
  placeholder?: string;
  options?: {
    name: string | JSX.Element;
    id: number | TimesheetEntryClassification;
  }[];
  fullWidth?: boolean;
  momentTextField?: boolean;
  margin?: 'normal' | 'dense';
  className?: string;
  onClick?: () => void;
}

export const InputField = ({
  type,
  value,
  modifyNewItemValue,
  validation,
  disabled,
  autoFocus,
  immediateChange,
  fullHeight,
  charLimit,
  label,
  required = true,
  placeholder,
  options,
  fullWidth,
  momentTextField = true,
  margin = 'dense',
  className,
  onClick
}: IInputField) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(value);

  let displayedValue = value;
  if (isEditing) {
    displayedValue = editedValue;
  }
  if (!displayedValue) {
    displayedValue = '';
    if (_.eq(validation, 'invoicingHours') && !isEditing) {
      displayedValue = 0;
    }
  }

  const onChangeValueHandler = ({
    target: { value: newVal }
  }: {
    target: { value: string };
  }) => {
    const modifiedVal = charLimit ? newVal.slice(0, charLimit) : newVal;
    if (immediateChange) {
      modifyNewItemValue(modifiedVal, validation);
    }
    setEditedValue(modifiedVal);
  };

  switch (type) {
    case InputType.NUMBER:
    case InputType.CURRENCY:
    case InputType.STRING: {
      return (
        <CustomInput
          fullWidth={fullWidth}
          disabled={disabled}
          required={required}
          placeholder={placeholder}
          variant="outlined"
          margin={margin}
          classes={{
            root:
              _.eq(InputType.NUMBER, type) && !fullWidth ? 'number' : 'string'
          }}
          inputProps={{
            step: '0.25',
            min: '0'
          }}
          InputProps={{
            startAdornment: _.eq(type, InputType.CURRENCY) ? (
              <InputAdornment position="start">$</InputAdornment>
            ) : null,
            onBlur: ({
              target: { value: newVal }
            }: {
              target: { value: string };
            }) => {
              setIsEditing(false);
              modifyNewItemValue(
                validateNewValue(newVal, validation),
                validation // at blur we revert to nearest .25
              );
            },
            onFocus: () => {
              setEditedValue(value);
              setIsEditing(true);
            }
          }}
          value={
            _.eq(validation, 'invoicingHours') &&
            typeGuard.isNumber(displayedValue)
              ? displayedValue.toFixed(2)
              : displayedValue
          }
          onChange={onChangeValueHandler}
          type="string"
          label={label}
        />
      );
    }
    case InputType.LONG_STRING: {
      let v = displayedValue;
      if (
        !isEditing &&
        fullHeight &&
        typeGuard.isString(displayedValue) &&
        displayedValue.length > 86
      ) {
        v = displayedValue.substring(0, 85).concat('...');
      }
      if (disabled) {
        v = displayedValue;
      }

      return (
        <CustomInput
          className={`${className}`}
          fullWidth={fullWidth}
          placeholder={placeholder}
          autoFocus={autoFocus}
          disabled={disabled}
          multiline
          rows={fullHeight || disabled ? undefined : 2}
          maxRows={fullHeight ? 100 : undefined}
          required
          variant="standard"
          classes={{
            root: `${momentTextField ? 'long-string' : ''} ${
              fullHeight ? 'full-height' : ''
            }`
          }}
          InputProps={{
            onBlur: ({
              target: { value: newVal }
            }: {
              target: { value: string };
            }) => {
              setIsEditing(false);
              modifyNewItemValue(newVal, validation);
            },
            onFocus: () => {
              setEditedValue(value);
              setIsEditing(true);
            }
          }}
          value={v}
          onChange={onChangeValueHandler}
          type="string"
        />
      );
    }

    case InputType.DATE: {
      return (
        <CustomInput
          autoFocus={autoFocus}
          disabled={disabled}
          required
          variant="outlined"
          InputProps={{
            onBlur: ({
              target: { value: newVal }
            }: {
              target: { value: string };
            }) => {
              setIsEditing(false);
              modifyNewItemValue(newVal, validation);
            },
            onFocus: () => {
              setEditedValue(value);
              setIsEditing(true);
            }
          }}
          value={value}
          onChange={onChangeValueHandler}
          type="date"
        />
      );
    }

    case InputType.DROPDOWN: {
      return (
        <CustomInput
          className={`${className}`}
          placeholder="Select"
          label="Deliverable"
          classes={{
            root: ` ${!value ? 'invalid dropdown' : 'selected'}`
          }}
          margin={margin}
          variant="outlined"
          select
          onChange={(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            e.stopPropagation();
            modifyNewItemValue(e.target.value, validation);
          }}
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
            e.stopPropagation()
          }
          value={value}
        >
          <MenuItem value={0} disabled>
            Select
          </MenuItem>
          {options &&
            options.map((item: { id: number; name: string | JSX.Element }) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
        </CustomInput>
      );
    }

    case InputType.INFINITE_CALENDAR: {
      return (
        <CustomInput
          disabled={disabled}
          required
          variant="outlined"
          value={value}
          type="text"
          onClick={onClick}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Calendar />
              </InputAdornment>
            )
          }}
        />
      );
    }

    default: {
      return value ? <span>{value}</span> : null;
    }
  }
};

export default InputField;
