import React from 'react';
import { Modal, TextField, Button, Box } from '@mui/material';
import { CharacterWarningLimit } from 'src/components/InvoiceDetail/LineItemModal/styles';
import { SHORT_TEXT_CHAR_LIMIT } from 'src/constants';
import styled, { theme } from 'src/theme';

interface IMessageModal {
  onOk: (reason: string) => void;
  onCancel: () => void;
  isReject: boolean;
  initialMessage?: string;
}

const PaperDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: ${theme.palette.background.paper};
  border: 2px solid #000;
  box-shadow: ${theme.shadows[5]};
  padding: ${theme.spacing(2, 4, 3)};
`;

export const MessageModal = ({
  onOk,
  onCancel,
  isReject,
  initialMessage
}: IMessageModal) => {
  const [reason, setReason] = React.useState(initialMessage || '');
  const charLimit = isReject ? SHORT_TEXT_CHAR_LIMIT : 1000; // 1000 chars allowed for customer memo from QB
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open
      onClose={onCancel}
      onBackdropClick={onCancel}
    >
      <PaperDiv>
        <Box display="flex" flexDirection="column">
          <h2 id="simple-modal-title">{`Please enter a ${
            isReject ? 'rejection reason' : 'QB customer memo'
          }`}</h2>
          <TextField
            variant="outlined"
            multiline
            rows={4}
            autoFocus
            label={isReject ? 'Rejection Reason' : 'QB Customer Memo'}
            value={reason}
            onChange={({
              target: { value }
            }: {
              target: { value: string };
            }) => {
              if (value.length < charLimit) {
                setReason(value);
              } else {
                setReason(value.substring(0, charLimit));
              }
            }}
          />
          <CharacterWarningLimit>
            {charLimit - reason.length} characters remaining
          </CharacterWarningLimit>
          <Button
            variant="contained"
            color="secondary"
            disabled={!reason}
            onClick={() => onOk(reason)}
          >
            {isReject ? 'Reject' : 'Approve and create QB invoice'}
          </Button>
        </Box>
      </PaperDiv>
    </Modal>
  );
};

export default MessageModal;
