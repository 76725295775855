import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Avatar, Chip, CircularProgress, Typography } from '@mui/material';
import messages from 'src/messages';
import UserDetailActions from 'src/components/UserDetailActions';
import UserStatusChange from 'src/components/UserStatusChange';
import { currentUserSelector } from 'src/shared/auth/selectors';
import * as userActions from 'src/shared/users/actions';
import {
  Container,
  Content,
  Label,
  LineItem,
  Sub,
  Title,
  Value
} from './styles';
import { getUserDetail } from 'src/queries/getUserDetail';
import { patchUser } from 'src/mutations/user/patchUser';

type Props = {
  isCurrentUser: boolean;
};

const UserDetail = ({ isCurrentUser }: Props): JSX.Element => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const user = useSelector(currentUserSelector);
  const { id: paramsUserId } = useParams();
  const [isDisableModalOpen, setIsDisableModalOpen] = useState<boolean>(false);
  const userId = isCurrentUser ? user?.id : Number(paramsUserId);

  const {
    data: userDetail,
    error: userDetailError,
    isLoading: userDetailLoading
  } = useQuery(['userDetail', userId], ({ queryKey }) => {
    if (!queryKey[1]) return;
    return getUserDetail(queryKey[1]);
  });

  const changeStatusMutation = useMutation(patchUser, {
    onSuccess: user => {
      queryClient.setQueryData(['userDetail', userId], {
        ...userDetail,
        isActive: !userDetail.isActive
      });
      dispatch(userActions.actions.updateUserSucceeded(user, user, user.id));
    },
    onSettled: () => setIsDisableModalOpen(false)
  });

  const handleUserStatusChange = () => {
    changeStatusMutation.mutate({
      id: userDetail.id,
      isActive: !userDetail.isActive
    });
  };

  if (userDetailError) {
    return <div>An error has occurred.</div>;
  }

  if (userDetailLoading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (!userDetail) {
    return (
      <Typography variant="h5" align="center">
        {messages.NO_PERSON_SELECTED}
      </Typography>
    );
  }

  return (
    <Container>
      <Avatar>{userDetail.initials}</Avatar>
      <Title>{userDetail.fullName}</Title>
      <Sub>{userDetail.title}</Sub>
      <Content>
        <LineItem>
          <Label>{messages.LABELS.STATUS}</Label>
          <Value>
            {userDetail.isActive ? messages.ACTIVE : messages.DISABLED}
          </Value>
        </LineItem>
        <LineItem>
          <Label>{messages.LABELS.FIRST_NAME}</Label>
          <Value>{userDetail.firstName || '-'}</Value>
        </LineItem>
        <LineItem>
          <Label>{messages.LABELS.LAST_NAME}</Label>
          <Value>{userDetail.lastName || '-'}</Value>
        </LineItem>
        <LineItem>
          <Label>{messages.LABELS.START_DATE}</Label>
          <Value>
            {moment(userDetail.startDate).format('MM/DD/YYYY') || '-'}
          </Value>
        </LineItem>
        <LineItem>
          <Label>{messages.LABELS.EMAIL}</Label>
          <Value>{userDetail.email || '-'}</Value>
        </LineItem>
        <LineItem>
          <Label>{messages.LABELS.MANAGER}</Label>
          <Value>{userDetail.managerFullName}</Value>
        </LineItem>
        <LineItem>
          <Label>{messages.LABELS.EMPLOYMENT_TYPE}</Label>
          <Value>{userDetail.classification}</Value>
        </LineItem>
        <LineItem>
          <Label>{messages.LABELS.NOTIFICATION_PREF}</Label>
          <Value>{userDetail.notificationPreferenceNames}</Value>
        </LineItem>
        <LineItem>
          <Label>{messages.LABELS.ROLES}</Label>
          <Value>{userDetail.roleNames}</Value>
        </LineItem>
        <LineItem>
          <Label>{messages.LABELS.DEPARTMENTS}</Label>
          <Value>
            {userDetail.departmentNames.map((department: any) => (
              <Chip key={department} label={department} />
            ))}
          </Value>
        </LineItem>
      </Content>
      {!isCurrentUser && (
        <>
          <UserDetailActions
            userId={userId || -1}
            userStatus={userDetail.isActive}
            openDialog={() => setIsDisableModalOpen(true)}
            disabled={false}
          />
          <UserStatusChange
            performAction={handleUserStatusChange}
            deleteEnabled={userDetail.isActive}
            open={isDisableModalOpen}
            handleClose={() => setIsDisableModalOpen(false)}
          />
        </>
      )}
    </Container>
  );
};

export default UserDetail;
