import { Box } from '@mui/material';
import styled, { theme } from 'src/theme';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${theme.font};
`;

export const FlexBox16MarginBottom = styled(FlexBox)`
  margin-bottom: 16px;
`;

export const ColumnFlexBox = styled(FlexBox)`
  flex-direction: column;
`;

export const DayBox = styled(ColumnFlexBox)`
  border: 1px solid #d4d4d4;
  width: 100%;
`;

export const DayBoxHeader = styled(FlexBox)`
  background-color: #f5f5f5;
  color: #212121;
  height: 48px;
  font-size: 12px;
  font-family: ${theme.font};
  font-weight: 600;
  line-height: 18px;
  white-space: nowrap;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  .italic {
    font-style: italic;
    color: #757575;
    font-weight: lighter;
  }
  &.hoverable:hover {
    background-color: ${theme.button.primaryHoverColor};
    color: white;
    cursor: pointer;
  }
  &.weekend {
    background-color: ${theme.button.primaryDisabledColor};
    opacity: 0.8;
    color: ${theme.button.primaryDisabledTextColor};
  }
  &.selected {
    background-color: ${theme.button.primaryColor};
    opacity: 0.8;
    color: rgba(255, 255, 255, 0.8);
  }
  svg {
    cursor: pointer;
  }
  padding: 6px 16px;
`;

export const DayBoxBody = styled(FlexBox)`
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  font-family: ${theme.font};
  padding: 16px 24px 16px
    ${(props: { paddingRight?: string }) => props.paddingRight || '24px'};
  text-align: left;
`;

export const Bold14 = styled.span`
  font-family: ${theme.font};
  font-weight: 600;
  font-size: 14px;
  margin-right: 8px;
`;

export const Subtitle12 = styled.span`
  font-size: 12px;
  color: #212121;
`;

export const MarginRight24 = styled.div`
  margin-right: 24px;
  font-size: 14px;
`;

export const MarginHorizontal24 = styled(MarginRight24)`
  margin-left: 24px;
`;

export const AlignLeftColumnFlexBox = styled(ColumnFlexBox)`
  align-items: start;
`;

export const FlexBoxJustifyEnd = styled(FlexBox)`
  justify-content: flex-end;
  width: 100%;
`;

export const FlexBoxJustifyStart = styled(FlexBox)`
  justify-content: flex-start;
  width: 100%;
`;

export const GroupedBox = styled(FlexBox)`
  justify-content: center;
`;

export const InnerBodyWrapper = styled(ColumnFlexBox)`
  width: 100%;
`;

export const GreenCheckCircle = styled(CheckCircle)`
  color: #00b663;
  cursor: pointer;
`;

export const GreyCancel = styled(Cancel)`
  color: #737373;
  cursor: pointer;
`;

export const ClientIcon = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 100%;
  margin-right: 6px;
  background-color: ${props => props.color};
`;

export const ClientIconShiftedLeft = styled(ClientIcon)`
  margin-left: -24px;
`;

export const DateSpan = styled.span`
  padding-right: 8px;
`;
