import { Box } from '@mui/material';

import styled from 'src/theme';

export const ClientColorBox = styled(Box)`
  display: flex;
  align-items: center;

  .client-icon {
    margin-left: 0;
  }
`;

export const ClientBox = styled(Box)`
  color: #212121;
  font-size: 14px;
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
