import { IInvoice } from './invoice-list/types';
import { ResponseError } from './ui/types';
import _ from 'lodash';
import { IEntityStateUsersList } from './users/types';
import { Timesheet } from 'src/api/types';

const isString = (x: any): x is string => {
  return typeof x === 'string';
};

const isNumber = (x: any): x is number => {
  return typeof x === 'number';
};

const isTextArea = (x: any): x is HTMLTextAreaElement => {
  return typeof x.createTextRange !== 'undefined';
};

const isIInvoice = (x: any): x is IInvoice => {
  return typeof x.projectId === 'number' && typeof x.clientId === 'number';
};

const isITimesheet = (x: any): x is Timesheet => {
  return typeof x.id === 'number' && typeof x.approvalDueDate === 'string';
};

const isResponseError = (x: any): x is ResponseError => {
  const status = _.inRange(x.response?.status, 400, 599);
  return (
    typeof x.response !== undefined &&
    status &&
    typeof x.response.data === 'object' &&
    (typeof x.response.data.message === 'string' ||
      typeof x.response.data.code?.[0] === 'string')
  );
};

type QBResponseError = {
  response: {
    data: {
      error_type: string;
      error_message: string;
    };
  };
};

const isQBResponseError = (x: any): x is QBResponseError => {
  return (
    x &&
    x.response &&
    typeof x.response.data === 'object' &&
    typeof x.response.data.error_type === 'string'
  );
};

const isChangeEvent = (x: any): x is React.ChangeEvent => {
  return x && x.currentTarget && x.currentTarget.value;
};

const isIEntityStateUsersList = (x: any): x is IEntityStateUsersList => {
  return x && x.fullName;
};

const isElement = (x: any): x is Element => x && x.children;

export default {
  isString,
  isNumber,
  isTextArea,
  isIInvoice,
  isITimesheet,
  isResponseError,
  isQBResponseError,
  isChangeEvent,
  isIEntityStateUsersList,
  isElement
};
