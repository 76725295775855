import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';

export const fetchTimesheetEntries = (params: {
  invoiceId?: number;
  page: number;
  perPage: number;
  withProjectRoleId: boolean;
  withTimesheet: boolean;
  startDate?: string;
  endDate?: string;
  uninvoiced?: boolean;
  projectId?: number;
  timesheetId?: number;
}) =>
  callAPI({
    method: 'get',
    url: '/timesheet_entries',
    params,
    reqHeaders: {
      Accept: 'application/json'
    }
  })
    .then(response => response.data)
    .catch(error => {
      toast('Failed to fetch timesheet entries', 'error');
      throw error;
    });
