import { Moment } from 'moment';
import { IClient } from 'src/types/IClient';
import { IUser } from 'src/types/IUser';

export type AccountingCategories = {
  id: number;
  name: string;
  internal: boolean;
};

export enum EMetric {
  COMMUNICATIONS = 'COMMUNICATIONS',
  QUALITY = 'QUALITY',
  MORALE = 'MORALE',
  BUDGET = 'BUDGET',
  SCHEDULE = 'SCHEDULE'
}

export enum EVal {
  GOOD = 'GOOD',
  WARNING = 'WARNING',
  BAD = 'BAD'
}

export type QBCustomer = {
  displayName: string;
  id: number;
};

export type Department = {
  id: number;
  version: number;
  isActive: boolean;
  name: string;
  isDeletable: boolean;
};

export type DepartmentUser = {
  userId: number;
  departmentId: number;
  id: number;
};

export type Deliverable = {
  sow: number;
  name: string;
  type: string;
  estimatedCost: string;
  estimatedHours: string;
  estimatedEndDate: string;
  isActive: boolean;
  id: number;
  project: ProjectDetail;
  client: IClient;
};

export enum ENoteType {
  TIMESHEET_REJECTION_REASON = 'TIMESHEET_REJECTION_REASON'
}

export type Holiday = {
  id: number;
  version: number;
  date: Date;
  dateCreated: Date;
  lastUpdated: Date;
  name: string;
};

export type Invoice = {
  amount: number;
  clientCode: string;
  clientColor: string;
  clientId: string;
  clientName: string;
  clientQbCustomerId?: string;
  dateCreated: string;
  endDate: string;
  id: string;
  lastUpdated: string;
  projectId: string;
  projectIsActive: boolean;
  projectManagerId: string;
  projectManagerName: string;
  projectName: string;
  projectNickname: string;
  rejectionReason: string;
  startDate: string;
  status: string;
};

export type InvoiceCounts = {
  approved: number;
  new: number;
  sent: number;
  submitted: number;
  uninvoiced: number;
  paid: number;
};

export type InvoiceEmail = {
  id: number;
  email: number;
  firstName: number;
  lastName: string;
};

export type Note = {
  text: string;
  userFullName?: string;
  createdAt: Date;
  updatedAt: Date;
  typeOf: ENoteType;
  id?: number;
  timesheet: number;
  userId?: number;
};

export type Project = {
  budget_percentage_preference: string;
  client: {
    id: number;
    name: string;
    color: string;
    code: string;
    qbCustomerId: number | null;
  };
  clientId: number;
  dateCreated: string;
  deliverables: Deliverable[];
  id: number;
  invoiceEmailCcs: InvoiceEmail[];
  invoiceEmailTos: InvoiceEmail[];
  invoicingSpecialInstructions: string | null;
  isActive: boolean;
  lastUpdated: string;
  name: string;
  nickname: string;
  projectManager: {
    id: number;
    fullName: string;
  };
  projectManagerId: number;
  roles: ProjectRole[];
};

export type ProjectDetail = Project &
  ProjectStats2 & {
    budgetUsed: number;
    hoursRemaining: number;
    hoursWorked: number;
    totalAmount: number;
    spent: number;
    uninvoicedAmount: number;
  };

export type ProjectRoleHours = {
  hours: number;
  project: ProjectDetail;
  role: ProjectRole;
  client: IClient;
};

export type ProjectStats = {
  amountInvoiced: number;
  amountInvoicedAndUnsent: number;
  amountUnapproved: number;
  amountUninvoiced: number;
  budgetPercentagePreference?: string;
  clientColor: string;
  clientId: number;
  clientName: string;
  deliverableListString: string;
  estimatedCost: number;
  estimatedHours: number;
  hoursUnapproved: number;
  hoursUninvoiced: number;
  id: number;
  invoicingHours: number;
  lineItemsInvoiced: string;
  lineItemsUnapproved: string;
  metrics: ProjectMetric[];
  numTimesheets: number;
  projectManagerId: number;
  projectName: string;
  timesheetsApproved: number;
  timesheetsSubmitted: number;
  unsentInvoicedHours: number;
};

export type ProjectStats2 = ProjectStats & {
  budgetUsed: number;
  hoursRemaining: number;
};

export type ProjectCreateOptions = {
  accountingCategories: AccountingCategories[];
  deliverablesType: string[];
  departments: AccountingCategories[];
};

export type ProjectMetric = {
  status: EVal;
  metric: EMetric;
  projectId: number;
};

export type ProjectRole = {
  accountingCategoryId: number;
  departmentId: number;
  estimatedEndDate: string;
  name: string;
  projectId: number;
  rate: number;
  id: number;
};

export type Timesheet = {
  id: number;
  approvalDueDate: Date;
  dateCreated: Date;
  endDate: string;
  lastEmployeeOverdueNotification?: Date;
  lastUpdated?: Date;
  rejectionReason: string;
  startDate: string;
  status: string;
  submissionDueDate: Date;
  totalEntries: number;
  previousId?: number | null;
  nextId?: number | null;
  totalUtilization: number;
  totalBillUtilization: number;
  hasInvoices: boolean;
  timesheetDays: TimesheetRow[];
  averageWorkDay: number;
  isDirectManager?: boolean;
  owner: TimesheetOwner;
  totalWorkHours: number;
  notes: Note[];
  totalHours: number;
  canReopen: boolean;
};

export type TimesheetEntry = {
  date: string;
  version: number;
  dateCreated: Date;
  lastUpdated: Date;
  timesheetRowId: number;
  classification: TimesheetEntryClassification;
  timesheet: {
    ownerId: number;
    id: number;
    status: string;
  };
  projectRoleId: number;
  dismissed?: boolean;
  description: string;
  invoicingDescription?: string;
  status: string;
  id: number;
  invoicingHours: number;
  invoiceId: number | null;
  actual: number;
  deliverableId: number;
  hours: number;
  modified?: boolean;
  nonBill: number;
  edited?: boolean;
  project: ProjectDetail;
  associatedToInvoice?: boolean;
  deliverable: Deliverable;
  role: ProjectRole;
  client: IClient;
};

export enum TimesheetEntryClassification {
  BILLABLE = 'billable',
  NON_BILLABLE = 'non_billable',
  SICK_TIME = 'sick_time',
  PTO = 'PTO',
  UNPAID = 'unpaid_time_off',
  HOLIDAY = 'holiday'
}

export type TimesheetOwner = {
  id: number;
  classification: UserClassification;
  fullName: string;
  title: string;
  isManager: boolean;
  manager: {
    id: number;
    fullName: string;
  };
};

export type TimesheetRow = {
  holiday: Holiday;
  entries: TimesheetEntry[];
  date: Date | Moment;
};

export enum TimesheetSearchOrderEnum {
  MANAGER_FULLNAME = 'manager.fullName',
  OWNER_FULLNAME = 'owner.fullName',
  TOTAL_UTILIZATION = 'totalUtilization',
  TOTAL_BILL_UTILIZATION = 'totalBillUtilization',
  START_DATE = 'startDate',
  STATUS = 'status',
  STATUS_APPROVAL_VIEW = 'status.approvalView'
}

export type TimesheetSidebarDetail = {
  timesheet: Timesheet | null;
  timesheetsList: Timesheet[];
  totalTimesheets: number;
  teamTimesheets: Timesheet[];
  teamDetailsStartDate: string;
  teamDetailsEndDate: string;
  userProjectRoles: UserProjectRole[];
  isLoading: boolean;
};

export enum TimesheetStatusEnum {
  TO_APPROVE = 'SUBMITTED',
  CURRENT = 'CURRENT',
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  APPROVAL_VIEW = 'APPROVAL_VIEW',
  ALL = 'ALL',
  DISMISSED = 'DISMISSED'
}

export type UninvoicedProject = {
  clientColor: string;
  clientId: string;
  clientName: string;
  endDate: string;
  period: string;
  projectId: string;
  projectManagerName: string;
  projectName: string;
  projectNickname: string;
  startDate: string;
};

export enum UserClassification {
  CONTRACTOR = 'Contractor',
  HOURLY = 'Hourly',
  SALARIED = 'Salaried'
}

export type UserProjectRole = {
  id: number;
  deliverable: Deliverable;
  projectRole: ProjectRole;
  client: IClient;
  project: Project;
  isVisible: boolean;
  user: IUser;
  errors?: string[];
};

export type UserStats = {
  periods: {
    start: string;
    end: string;
  }[];
  users: {
    id: number;
    name: string;
    periods: {
      hours: number;
      invoicingHours: number;
      startDate: string;
    };
  }[];
};
