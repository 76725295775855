import Add from '@mui/icons-material/Add';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { AutoSizer, Column } from 'react-virtualized';
import {
  AddFab,
  Circle,
  Container,
  CustomTable,
  Disabled,
  Enabled
} from './styles';

interface IClientList {
  clients: any;
  onAddClick: () => void;
  goToDetail: (event: any) => void;
}

export const ClientList: FunctionComponent<IClientList> = props => {
  const rowGetter = (event: any) => {
    const key = Object.keys(props.clients)[event.index];
    return props.clients[key];
  };

  const renderStatus = (event: any) => {
    const status = event.cellData;
    if (status) {
      return <Enabled />;
    }

    return <Disabled />;
  };

  const renderColor = (event: any) => {
    const color = event.cellData;
    return <Circle color={color} />;
  };

  return (
    <Container>
      <AutoSizer>
        {({ height, width }) => (
          <CustomTable
            onRowClick={props.goToDetail}
            headerClassName="headerRow"
            rowClassName="row"
            headerHeight={50}
            height={height}
            rowCount={Object.keys(props.clients).length}
            rowHeight={80}
            rowGetter={rowGetter}
            width={width}
          >
            <Column
              cellRenderer={renderStatus}
              className="column"
              label="Status"
              dataKey="isActive"
              width={50}
              flexGrow={1}
            />
            <Column
              className="column"
              label="Code"
              dataKey="code"
              width={50}
              flexGrow={1}
            />
            <Column
              className="column"
              label="Color"
              dataKey="color"
              width={50}
              flexGrow={1}
              cellRenderer={renderColor}
            />
            <Column
              className="column"
              label="Name"
              dataKey="name"
              width={100}
              flexGrow={2}
            />
          </CustomTable>
        )}
      </AutoSizer>
      <AddFab onClick={props.onAddClick} color="primary" aria-label="Add new">
        <Add />
      </AddFab>
    </Container>
  );
};

export default ClientList;
