import React from 'react';
import { getInitials } from 'src/shared/users/utils';

import { Avatar as StyledAvatar } from './styles';

export const Avatar = ({
  name,
  backgroundColor
}: {
  name: string;
  backgroundColor?: string;
}) => (
  <StyledAvatar
    key={name}
    style={{ backgroundColor: backgroundColor || '#005662' }}
  >
    {getInitials(name)}
  </StyledAvatar>
);

export default Avatar;
