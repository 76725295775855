/**
 * Source of truth for React Query keys
 */
export const QueryKeys = {
  currentTimesheetId: 'currentTimesheetId',
  userProjectRoles: 'userProjectRoles',
  client: (id?: number | string) => ['client', Number(id)],
  clients: 'clients',
  projectStats: 'projectStats',
  project: (id?: number | string) => ['project', Number(id)],
  projectCreateOptions: 'projectCreateOptions',
  projectDetail: (id?: number | string) => ['projectDetail', Number(id)],
  projectUserStats: 'projectUserStats',
  deliverables: 'deliverables',
  projectRoles: 'projectRoles',
  getUser: 'getUser',
  managers: 'managers',
  departments: 'departments',
  departmentUsers: 'departmentUsers',
  invoiceCounts: 'invoiceCounts',
  timesheet: 'timesheet',
  timesheets: 'timesheets',
  timesheetSidebarDetail: 'timesheetSidebarDetail'
};
