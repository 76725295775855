import * as React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IAppState } from 'src/reducer';
import * as usersActions from 'src/shared/users/actions';
import {
  UserClassification,
  IUserResponse,
  IEntityStateUsers,
  IEntityStateManagersObject,
  IEntityStateRoles,
  IUserPayload
} from 'src/shared/users/types';
import UserEdit, { UserEditErrors } from '../../components/UserEdit';
import Validations from '../../validations';
import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Api } from 'src/api/api';
import { QueryKeys } from 'src/queries/queryKeys';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { DepartmentUser } from 'src/api/types';

interface IUserEditContainerDispatch {
  updateUser: (
    userData: IUserPayload,
    userId: number,
    callback: () => void
  ) => void;
  getManagers: () => void;
  getUser: (userId: number) => void;
  getRoles: () => void;
}

interface IUserEditContainerReduxProps {
  roles: IEntityStateRoles;
  managers: IEntityStateManagersObject;
  loading: boolean;
  users: IEntityStateUsers;
}

interface IUserEditContainerState {
  user: IUserResponse;
  errors: UserEditErrors;
}

type IUserEditContainerProps = IUserEditContainerReduxProps &
  IUserEditContainerDispatch;

export const UserEditContainer: FunctionComponent<IUserEditContainerProps> = props => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { users } = props;

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [state, setState] = useState<IUserEditContainerState>({
    user: {
      firstName: '',
      lastName: '',
      managerId: 0,
      notificationPreferences: [],
      roles: [],
      startDate: '2017-05-24',
      title: '',
      contractor: false,
      isActive: true,
      slackDisplayName: '',
      fullName: '',
      id: 0,
      email: '',
      classification: UserClassification.SALARIED
    },
    errors: {
      email: false,
      classification: false,
      firstName: false,
      lastName: false,
      managerId: false,
      notificationPreferences: false,
      roles: false,
      startDate: false,
      title: false,
      contractor: false,
      isActive: false,
      slackDisplayName: false,
      fullName: false,
      id: false
    }
  });

  const { data: departments = [] } = useQuery(
    QueryKeys.departments,
    Api.fetchDepartments
  );

  const { data: departmentUsers = [] } = useQuery(
    [QueryKeys.departmentUsers, id],
    () => Api.fetchDepartmentUsers(Number(id))
  );

  const { mutate: createDepartmentUser } = useMutation(
    Api.createDepartmentUser,
    {
      onSuccess: created => {
        queryClient.setQueryData<DepartmentUser[]>(
          [QueryKeys.departmentUsers, id],
          (prevState = []) => prevState.concat([created])
        );
      }
    }
  );

  const { mutate: deleteDepartmentUser } = useMutation(
    Api.deleteDepartmentUser,
    {
      onSuccess: (_, deletedId) => {
        queryClient.setQueryData<DepartmentUser[]>(
          [QueryKeys.departmentUsers, id],
          (prevState = []) => prevState.filter(du => du.id !== deletedId)
        );
      }
    }
  );

  useEffect(() => {
    props.getManagers();
    props.getRoles();
    if (id) {
      loadData();
    }
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    if (isFirstRender) return;

    if (id && users[id]) {
      setState({ ...state, user: users[id] });
    }
  }, [id, id ? users[id] : undefined]);

  const loadData = () => {
    if (id) {
      props.getUser(Number(id));
    }
  };

  const submitIsValid = () => {
    const {
      firstName,
      lastName,
      email,
      title,
      managerId,
      classification
    } = state.user;
    const errors = {
      email: !Validations.VALID_EMAIL_REGEX.test(email),
      employmentType: classification == null,
      firstName: !Validations.VALID_NAME_REGEX.test(firstName),
      lastName: !Validations.VALID_NAME_REGEX.test(lastName),
      manager: _.eq(managerId, 0),
      notificationPreferences: false,
      startDate: false,
      title: !Validations.VALID_TITLE_REGEX.test(title)
    };

    setState({
      ...state,
      errors
    });

    return !(
      errors.firstName ||
      errors.lastName ||
      errors.title ||
      errors.manager ||
      errors.employmentType
    );
  };

  const onFormChange = (prop: string, newValue: number | string | boolean) => {
    setState({ ...state, user: { ...state.user, [prop]: newValue } });
  };

  const onSave = () => {
    if (submitIsValid()) {
      props.updateUser(
        {
          ...state.user,
          roleIds: state.user.roles
        },
        Number(id),
        userUpdated
      );
    }
  };

  const onCancel = () => {
    navigate('/team-management', { replace: true });
  };

  const userUpdated = () => {
    navigate(`/team-management/${id}`, {
      replace: true
    });
  };

  return (
    <UserEdit
      departments={departments}
      departmentUsers={departmentUsers}
      loading={props.loading}
      errors={state.errors}
      onSave={onSave}
      onCancel={onCancel}
      managers={props.managers}
      roles={props.roles}
      onChange={onFormChange}
      user={state.user}
      createDepartmentUser={createDepartmentUser}
      onDeleteDepartmentUser={deleteDepartmentUser}
    />
  );
};

export const mapStateToProps = (state: IAppState) => {
  const managers = state.entities.managers;
  const roles = state.entities.roles;
  const loading = state.entities.loadingUserEdit;
  const { users } = state.entities;
  return {
    loading,
    managers,
    roles,
    users
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IUserEditContainerDispatch => {
  return bindActionCreators(
    {
      getManagers: usersActions.actions.getManagers,
      getRoles: usersActions.actions.getRoles,
      getUser: usersActions.actions.getUser,
      updateUser: usersActions.actions.updateUser
    },
    dispatch
  );
};

export default connect<
  IUserEditContainerReduxProps,
  IUserEditContainerDispatch
>(
  mapStateToProps,
  mapDispatchToProps
)(UserEditContainer);
