import styled, { theme } from '../../theme';
import Button from '@mui/material/Button';

export const SecondaryButton = styled(Button)`
  && {
    text-transform: capitalize;
    font-size: 14px;
    padding: 4px 15px;
    background-color: ${theme.secondaryBackground};
    color: ${theme.black};
    border: 1px solid ${theme.borderColor};
  }
`;
