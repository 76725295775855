import React from 'react';
import { TimesheetStatusEnum } from 'src/api/types';
import {
  ActionContainer,
  ButtonRow,
  ImpersonateActionButton,
  StyledButton
} from './styles';
import { IGetRowData, IActionProps, IImpersonateAction } from './types';

export const ImpersonateButton = ({
  timesheet,
  impersonate
}: IImpersonateAction) => (
  <ActionContainer>
    <ImpersonateActionButton
      color="primary"
      variant="contained"
      size="small"
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
        impersonate(timesheet.owner.id);
      }}
    >
      Impersonate
    </ImpersonateActionButton>
  </ActionContainer>
);

export const ReviewButton = ({ onClick }: { onClick: () => any }) => (
  <ActionContainer>
    <ButtonRow>
      <StyledButton
        onClick={(e: React.BaseSyntheticEvent) => {
          e.preventDefault();
          e.stopPropagation();
          onClick();
        }}
      >
        Review
      </StyledButton>
    </ButtonRow>
  </ActionContainer>
);

export const rowActions = ({
  canManageTimesheet,
  onClickReview,
  impersonate
}: IActionProps) => {
  return ({ rowData }: IGetRowData) => {
    switch (rowData.status) {
      case TimesheetStatusEnum.CURRENT:
        return (
          <ImpersonateButton timesheet={rowData} impersonate={impersonate} />
        );
      case TimesheetStatusEnum.TO_APPROVE:
        if (!canManageTimesheet(rowData.owner.manager.id)) return;

        return <ReviewButton onClick={() => onClickReview(rowData.id)} />;
      default:
        return;
    }
  };
};
