import React from 'react';
import {
  PaddingLessValueColumn,
  FlexBoxJustifyEnd
} from 'src/components/shared/RightSidebar/RightSidebarInfoPane/styles';
import {
  MarginLeft12Launch,
  OpenInvoiceDetailContainer,
  OpenInvoiceDetailContainerHeader
} from './styles';

export const OpenInvoiceDetail = ({
  onClickOpenInvoiceDetail
}: {
  onClickOpenInvoiceDetail: () => void;
}) => (
  <OpenInvoiceDetailContainer>
    <OpenInvoiceDetailContainerHeader>
      Invoice Details
    </OpenInvoiceDetailContainerHeader>
    <FlexBoxJustifyEnd onClick={onClickOpenInvoiceDetail}>
      <PaddingLessValueColumn>View detail page</PaddingLessValueColumn>
      <MarginLeft12Launch />
    </FlexBoxJustifyEnd>
  </OpenInvoiceDetailContainer>
);
