import styled, { theme } from '../../theme';
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import { Box } from '@mui/material';

export const CustomReaderModeIcon = styled(ChromeReaderModeOutlinedIcon)`
  && {
    cursor: pointer;
  }
`;

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TopContainer = styled(FlexBox)`
  padding: 16px;
  padding-bottom: 0;
  border-bottom: ${theme.border};
  position: fixed;
  top: 84px;
  width: calc(100% - 240px - 32px);
  background-color: ${theme.white};
  z-index: 3;

  &.expanded {
    width: calc(100% - 32px);
  }

  transition: all 0.01s linear;
`;

export const AddEntryWrapper = styled.div`
  margin-top: 90px;
  &.compressed {
    width: 67%;
  }
`;

export const ExpandingBox = styled.div`
  flex-grow: 1;
`;
