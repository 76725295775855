import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FilledInput,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import * as React from 'react';
import DialogTransition from '../DialogTransition';
import messages from 'src/messages';

interface IAddRole {
  open: boolean;
  handleClose?: () => void;
  add?: () => void;
  values?: any;
  errors?: any;
  loading: boolean;
  onChange?: (prop: string) => (event: any) => void;
  departments: any[];
  accountingCategories: any[];
}

const AddRole: React.FC<IAddRole> = ({
  open = true,
  handleClose,
  add,
  values = {},
  errors = {},
  onChange = () => undefined,
  loading = false,
  departments = [],
  accountingCategories = []
}) => (
  <Dialog
    open={open}
    TransitionComponent={DialogTransition}
    keepMounted={true}
    onClose={handleClose}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle id="alert-dialog-slide-title">{'Add - Role'}</DialogTitle>
    <DialogContent>
      <TextField
        required={true}
        id="name-add-input"
        label={'Description'}
        value={values.name}
        error={errors.name}
        onChange={onChange('name')}
        margin="normal"
        fullWidth={true}
        variant="outlined"
      />
      <FormControl variant="outlined" fullWidth={true} margin="normal">
        <InputLabel htmlFor="type-pref-add">{'Department'}</InputLabel>
        <Select
          multiple={false}
          value={values.departmentId}
          onChange={onChange('departmentId')}
          input={<FilledInput id="type-pref-add-chip" />}
        >
          {departments.map((current: any) => (
            <MenuItem key={current.id} value={current.id}>
              {current.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" fullWidth={true} margin="normal">
        <InputLabel htmlFor="type-pref-add">{'Category'}</InputLabel>
        <Select
          multiple={false}
          value={values.accountingCategoryId}
          onChange={onChange('accountingCategoryId')}
          input={<FilledInput id="accountingCategoryId-pref-add-chip" />}
        >
          {accountingCategories.map((current: any) => (
            <MenuItem key={current.id} value={current.id}>
              {current.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        required={true}
        id="estCost-add-input"
        label={'Rate'}
        value={values.rate}
        error={errors.rate}
        onChange={onChange('rate')}
        margin="normal"
        fullWidth={true}
        variant="outlined"
      />
      <TextField
        required={false}
        id="estimatedEndDate-add-input"
        label={'Estimated End Date'}
        value={values.estimatedEndDate}
        onChange={onChange('estimatedEndDate')}
        margin="normal"
        fullWidth={true}
        variant="outlined"
        type="date"
        InputLabelProps={{
          shrink: true
        }}
      />
      <TextField
        required={true}
        id="status-add-select"
        select={true}
        label={'Status'}
        value={values.isActive}
        onChange={onChange('isActive')}
        fullWidth={true}
        margin="normal"
        variant="outlined"
      >
        <MenuItem key={`status-1`} value={1}>
          Active
        </MenuItem>
        <MenuItem key={`status-0`} value={0}>
          Inactive
        </MenuItem>
      </TextField>
    </DialogContent>
    <DialogActions>
      <Button disabled={loading} onClick={handleClose} color="primary">
        {messages.CANCEL}
      </Button>
      <Button
        disabled={loading}
        onClick={add}
        color="primary"
        variant="contained"
      >
        {messages.ADD}
      </Button>
    </DialogActions>
  </Dialog>
);

export default AddRole;
