import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PropsWithChildren } from 'react';
import { Location } from 'history';
import { NavigateFunction } from 'react-router/lib/hooks';
import { ConnectedComponent } from 'react-redux';

/**
 * Custom hook for providing router access to class components
 * Remove once class components have been eliminated
 */
export type RouteComponentProps<T> = {
  location: Location;
  navigate: NavigateFunction;
  params: T;
};

export function withRouter(
  Component:
    | React.ComponentClass<PropsWithChildren<any>>
    | ConnectedComponent<any, any>
) {
  function ComponentWithRouterProp(props: PropsWithChildren<any>) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return (
      <Component
        {...props}
        location={location}
        navigate={navigate}
        params={params}
      />
    );
  }

  return ComponentWithRouterProp;
}
