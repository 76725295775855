import { Autocomplete, TextField } from '@mui/material';

import styled from 'src/theme';

export const CustomInput = styled(TextField)`
  && {
    width: 100%;
    input {
      font-size: 14px;
      padding: 1px 40px 1px 5px;
    }
    .MuiOutlinedInput-adornedStart {
      padding-left: 9px;
    }
  }
`;

export const FullWidthAutoComplete = styled(Autocomplete)`
  width: 100%;
`;
