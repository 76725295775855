import * as invoiceEmailActions from './actions';
import { IInvoiceEmailReducerState } from './types';

const initialState: IInvoiceEmailReducerState = {
  loadingCreateInvoiceEmail: false
};

const reducer = (
  state: IInvoiceEmailReducerState = initialState,
  action: invoiceEmailActions.Actions
) => {
  switch (action.type) {
    case invoiceEmailActions.CREATE_INVOICE_EMAIL: {
      return {
        ...state,
        loadingCreateInvoiceEmail: true
      };
    }
    case invoiceEmailActions.CREATE_INVOICE_EMAIL_SUCCEDED: {
      return {
        ...state,
        loadingCreateInvoiceEmail: false
      };
    }
    default:
      return {
        ...state
      };
  }
};

export default reducer;
