import styled, { theme } from '../../theme';
import { CircularProgress } from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
`;

export const CircularProgressStyled = styled(CircularProgress)`
  align-self: center;
`;

export const TimesheetListFiltersContainer = styled.div`
  border-bottom: 1px solid ${theme.borderColor};
`;
