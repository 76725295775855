import React from 'react';
import styled, { theme } from '../../theme';
import { CircularProgress } from '@mui/material';
import { Table, TableProps } from 'react-virtualized';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
`;

export const TableContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
`;

export const CircularProgressStyled = styled(CircularProgress)`
  align-self: center;
`;

export const UserProjectRoleAddContainer = styled.div`
  border-bottom: 1px solid ${theme.borderColor};
`;

// TODO: Needs to be put into a shared styles file/directory
export const CustomTable: React.ComponentType<TableProps> = styled(Table)`
  padding-top: 25px;
  & .row {
    box-sizing: border-box;
    border-bottom: 2px solid ${theme.primaryBackground};
    background-color: ${theme.secondaryBackground};
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: ${theme.font};
  }
  & .row:focus {
    outline: none;
  }
  & .row:hover {
    background-color: ${theme.separatorBackground};
    cursor: pointer;
    & .removeButton {
      display: inline;
    }
  }
  & .ReactVirtualized__Table__headerRow:hover {
    background-color: #fff;
    cursor: default;
  }
  & .ReactVirtualized__Table__headerRow {
    background-color: #fff;
    color: ${theme.secondaryColorInverted};
  }
  & .headerRow {
    cursor: pointer;
    outline: transparent;
  }
  & .column,
  .headerRow {
    margin-left: 10px;
    margin-right: 10px;
  }
  & .removeButton {
    display: none;
  }
` as any;
