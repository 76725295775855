import { Box, Button, Divider as MUIDivider } from '@mui/material';
import styled, { theme } from 'src/theme';

import TextField from '@mui/material/TextField';

export const Divider = styled(MUIDivider)`
  && {
    width: 352px;
    border-bottom: 1px solid #d4d4d4;
    margin: 24px 0 16px;
  }
`;

export const ModalTitle = styled.div`
  color: rgba(33, 33, 33, 0.87);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  font-family: ${theme.font};
`;

export const ModalSubTitle = styled.div`
  color: rgba(33, 33, 33, 0.87);
  font-family: ${theme.font};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 0;
`;

export const CharacterWarningLimit = styled.span`
  color: rgba(0, 0, 0, 0.6);
  font-family: ${theme.font};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;
`;

export const FlexBox = styled(Box)`
  display: flex;
  svg {
    cursor: pointer;
  }
`;

export const TopBorderFlexBox = styled(FlexBox)`
  border-top: 1px solid #d4d4d4;
  margin-top: 0;
  padding: 16px;
`;

export const FlexBoxSpaceBetween = styled(FlexBox)`
  justify-content: space-between;
  align-items: center;
`;

export const ModalTitleBar = styled(FlexBoxSpaceBetween)`
  padding: 16px 24px 8px;
  border-bottom: 1px solid #d4d4d4;
`;

export const ColumnFlexBox = styled(FlexBox)`
  flex-direction: column;
`;

export const PaddedColumnFlexBox = styled(ColumnFlexBox)`
  padding: 8px 24px;
`;

export const CustomInput = styled(TextField)`
  && {
    color: rgba(33, 33, 33, 0.87);
    font-family: ${theme.font};
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    margin-right: 20px;
    background-color: #ffffff;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    width: 352px;
    margin-top: 16px;
    margin-right: 0;
    & .MuiOutlinedInput-input {
      padding: 10.5px 8px;
    }
    & .MuiOutlinedInput-root {
      & .MuiOutlinedInput-input {
        font-size: 14px;
      }
    }
    & label {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 16px;
      transform: translate(14px, -8px) scale(1);
      color: rgba(33, 33, 33, 0.87);
      background-color: #fff;
    }
    &.dropdown {
      width: 100%;
      min-width: unset;
      & div {
        & div {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }
    &.date {
      width: 100%;
      margin-bottom: 12px;
      & .MuiOutlinedInput-root {
        & .MuiOutlinedInput-input {
          padding-left: 8px;
          padding-right: 1px;
        }
      }
    }
    &.invalid {
      & .MuiOutlinedInput-root {
        & .MuiOutlinedInput-input {
          color: rgba(0, 0, 0, 0.6);
          font-family: ${theme.font};
          font-size: 14px;
          font-style: italic;
        }
      }
    }
    &.number {
      width: 48px;
      border-radius: 5px;
      text-align: right;
      & .MuiOutlinedInput-input {
        cursor: pointer;
        padding: 10.5px 8px;
        text-align: right;
        font-size: 14px;
      }
    }
    &.long-string {
      & ::before {
        border: none !important;
      }
      div {
        padding: 8px;
        div {
          padding: 0;
        }
      }

      & .Mui-focused {
        border-radius: 5px;
        background-color: #fff;

        ::after {
          border: none;
        }
      }
    }
    &.long-string:hover {
    }

    & textarea {
      height: 100px !important;
      padding: 0 !important;
    }
  }
` as any;

export const StyledPrimaryButton = styled(Button)`
  && {
    height: 36px;
    width: 124px;
    border-radius: 4px;
    background-color: #00838f;
    color: #ffffff;
    font-family: ${theme.font};
    font-size: 16px;
    letter-spacing: 0;
    text-transform: none;
    margin-right: 16px;
    padding: 0;
  }
  &&:hover {
    background-color: #4ca8b0;
  }
  &&.MuiButton-root.Mui-disabled {
    color: #fff;
    background-color: #7fc1c7;
  }
`;
export const StyledCancelButton = styled(Button)`
  && {
    color: #00838f;
    height: 36px;
    width: 77px;
    font-family: ${theme.font};
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    text-transform: none;
    padding: 0;
  }
  &&:hover {
    background-color: #eee;
  }
`;
