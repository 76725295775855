// This file is just full of ts errors
//   we just need to disable ts at this point
//   and wait for the refactor away from redux-saga

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import _ from 'lodash';
import moment from 'moment';
import { all, put, takeLatest, select, call, cancel } from 'redux-saga/effects';
import { currentSelectedInvoice } from 'src/shared/invoice-list/selectors';
import {
  selectPersistedView,
  selectLocalView,
  getFixedLineItemId
} from 'src/shared/invoice/selectors';
import { currentUserSelector } from 'src/shared/auth/selectors';
import { transformInvoice } from 'src/shared/invoice-list/saga';
import {
  Actions,
  HYDRATE_INVOICE,
  CREATE_NEW_INVOICE,
  DELETE_INVOICE,
  DISMISS_ALL_TIMESHEETS,
  SAVE_LINE_ITEM,
  DELETE_LINE_ITEM,
  DISMISS_TIMESHEET_ROW,
  UPDATE_FIXED_LINE_ITEM,
  SAVE_TIMESHEET_ROW,
  SAVE_TIMESHEET_ENTRY,
  DOWNLOAD_FILE,
  UPLOAD_NEW_FILE,
  CHANGE_STATUS,
  SAVE_INVOICE,
  FETCH_TIMESHEET_ENTRIES,
  actions,
  CREATE_NEW_INSTRUCTION,
  EXPORT_TIMESHEET,
  DOWNLOAD_QB_PDF
} from './actions';
import {
  fetchInvoiceFixedLineItems,
  getUser,
  getProjectDetail,
  postInvoice,
  deleteInvoice as deleteInvoiceApi,
  postNewInvoiceLineItem,
  deleteLineItem as deleteLineItemApi,
  updateInvoice,
  updateInvoiceLineItem as updateInvoiceLineItemApi,
  updateInvoiceApi,
  getUploadFiles,
  downloadUploadFile,
  createNewFile,
  getProjects,
  createInvoiceNote,
  rejectInvoice as rejectInvoiceApi,
  fetchDownloadToken,
  fetchTimesheetSpreadsheet,
  approveInvoiceApi,
  reopenInvoiceApi
} from 'src/services/api';
import { Api } from 'src/api/api';
import {
  formatAsDDMMYYYY,
  formatDateForApi,
  formatAsISO8601,
  flattenObject,
  removeEmpty,
  nowForApi,
  formatDatePeriod,
  formatAsMMDDYYYY,
  typeGuard,
  parseResponseErrorMessage
} from 'src/shared/utils';
import {
  validItem,
  sumHoursByClassification,
  removeUTCSignal,
  sumInvoiceHours,
  invoicingHours
} from 'src/shared/invoice/utils';
import {
  IFixedLineItem,
  IUserEntry,
  ITimesheetRow,
  NewInvoiceState,
  IInvoiceDetailView
} from './types';
import { InvoiceStatus, ENoteType } from 'src/shared/invoice-list/types';
import { actions as invoiceListActions } from 'src/shared/invoice-list/actions';
import { toast } from 'src/services/toast';
import {
  ProjectDetail,
  TimesheetEntry,
  TimesheetEntryClassification,
  TimesheetStatusEnum
} from 'src/api/types';

interface IFixedLineItemResponseItem {
  amount: number;
  date: string;
  fileId?: number;
}

interface IUploadFileResponseItem {
  id?: number;
}

const flattenProject = (project: ProjectDetail) => ({
  projectName: project.name,
  clientName: project.client.name,
  projectId: project.id,
  projectManager: project.projectManager.fullName,
  projectManagerId: project.projectManager.id,
  clientId: project.client.id
});

const transformFixedLineItem = (fixedLineItem: IFixedLineItemResponseItem) => ({
  ...fixedLineItem,
  date: formatAsDDMMYYYY(fixedLineItem.date)
});

function* hydrateFixedLineItems(fixedLineItems: IFixedLineItemResponseItem[]) {
  const uploadFileIds: any[] = _(fixedLineItems) // typescript won't take number[]
    .map(({ fileId }: { fileId: number }) => fileId)
    .uniq()
    .compact()
    .value();
  let uploadFiles: IUploadFileResponseItem[];
  if (uploadFileIds.length) {
    const uploadFileReq = yield call(getUploadFiles, uploadFileIds);
    uploadFiles = uploadFileReq.data;
  }
  return _.map(fixedLineItems, (fixedLineItem: IFixedLineItemResponseItem) => {
    let file: IUploadFileResponseItem | undefined;
    if (fixedLineItem.fileId) {
      file = _.find(uploadFiles, ({ id }: { id: number }) =>
        _.eq(id, fixedLineItem.fileId)
      );
    }
    return {
      ...flattenObject({ file: { ...file } }),
      ...transformFixedLineItem(fixedLineItem)
    };
  });
}

const transformNewLineItemForPost = (fixedLineItem: IFixedLineItem) => ({
  ...fixedLineItem,
  date: formatAsISO8601(fixedLineItem.date)
});

export const transformTimesheetEntry = (timesheetEntry: TimesheetEntry) => ({
  ...timesheetEntry,
  invoicingHours: invoicingHours(timesheetEntry),
  date: removeUTCSignal(timesheetEntry.date)
});

const createHoursItem = (timesheetEntry: TimesheetEntry) => ({
  ...timesheetEntry,
  modified: Boolean(
    (timesheetEntry.invoicingHours &&
      !_.eq(timesheetEntry.hours, timesheetEntry.invoicingHours)) ||
      (timesheetEntry.invoicingDescription &&
        !_.eq(timesheetEntry.description, timesheetEntry.invoicingDescription))
  ),
  actual: timesheetEntry.hours,
  classification: timesheetEntry.classification,
  dismissed: timesheetEntry.dismissed,
  description:
    timesheetEntry.invoicingDescription || timesheetEntry.description,
  status: timesheetEntry.status,
  id: timesheetEntry.id,
  date: timesheetEntry.date,
  invoicingHours: timesheetEntry.invoicingHours,
  nonBill:
    timesheetEntry.classification !== TimesheetEntryClassification.BILLABLE
      ? timesheetEntry.hours
      : 0
});

const reduceEntriesIntoUserRows = (allTimesheetEntries: TimesheetEntry[]) => {
  const userEntries: IUserEntry[] = [];
  _.forEach(allTimesheetEntries, (timesheetEntry: TimesheetEntry) => {
    let currentUserEntry = userEntries.find(
      ({ userId, projectRoleId, deliverableId }: IUserEntry) =>
        _.isEqual(userId, timesheetEntry.timesheet.ownerId) &&
        _.isEqual(projectRoleId, timesheetEntry.projectRoleId) &&
        _.isEqual(deliverableId, timesheetEntry.deliverableId)
    );

    const hoursItem = createHoursItem(timesheetEntry);

    if (currentUserEntry) {
      currentUserEntry.hourList.push(hoursItem);
      if (currentUserEntry.timesheetApproval) {
        currentUserEntry.timesheetApproval = _.eq(
          timesheetEntry.timesheet.status,
          TimesheetStatusEnum.APPROVED
        );
      }
    } else {
      currentUserEntry = {
        timesheetId: timesheetEntry.timesheet.id,
        userId: timesheetEntry.timesheet.ownerId,
        projectRoleId: timesheetEntry.projectRoleId,
        deliverableId: timesheetEntry.deliverableId,
        hourList: [hoursItem],
        timesheetApproval: _.eq(
          timesheetEntry.timesheet.status,
          TimesheetStatusEnum.APPROVED
        )
      };
      userEntries.push(currentUserEntry);
    }
  });

  return userEntries;
};

function* hydrateUserRows(userRows: IUserEntry[], projectId: number) {
  const userIds = userRows.map((row: IUserEntry) => row.userId);
  const userResp = yield call(getUser, userIds);
  const users = userResp.data.data;

  const projectRoles = yield call(Api.fetchProjectRoles, {
    projectId
  });
  return [
    _.map(userRows, (row: IUserEntry) => {
      const projectRole = projectRoles.find(
        ({ id }: { id: number }) => id === row.projectRoleId
      );
      const dismissed = _.every(row.hourList, 'dismissed');
      const hours: number = dismissed
        ? 0
        : sumHoursByClassification(row.hourList);
      const invoiceHours: number = sumInvoiceHours(row.hourList);
      const rate = projectRole.rate;
      return {
        ...row,
        dismissed,
        hours,
        totalNonBillableHours: sumHoursByClassification(
          row.hourList,
          'non_billable'
        ),
        invoiceHours,
        projectRoleId: projectRole ? projectRole.id : null,
        rate,
        total: `$${invoiceHours * rate}`,
        ...users.find(({ id }: { id: number }) => id === row.userId)
      };
    }),
    projectRoles
  ];
}

function* fetchInvoiceViewData(action: Actions) {
  if (action.type === HYDRATE_INVOICE) {
    let selectedInvoice;
    let invoice;
    let project;
    let timesheetsReq;
    let fixedLineItems = [];
    let filters: NewInvoiceState['filters'] | null | undefined;
    if (!action.payload.filters) {
      // not a UNINVOICED invoice
      if (!action.payload.id) return;

      invoice = yield call(Api.fetchInvoice, action.payload.id);
      project = yield call(Api.fetchProjectDetail, invoice.projectId);
      selectedInvoice = transformInvoice(invoice, [project]);
      timesheetsReq = yield call(Api.fetchTimesheetEntries, {
        invoiceId: selectedInvoice.id || action.payload.id,
        page: 1,
        perPage: 300,
        withProjectRoleId: true,
        withTimesheet: true,
        omitTimesheetDays: true
      });
      const fixedLineItemsReq = yield call(fetchInvoiceFixedLineItems, {
        invoiceId: selectedInvoice.id
      });

      const dehydratedFixedLineItems = fixedLineItemsReq.data;
      fixedLineItems = yield call(
        hydrateFixedLineItems,
        dehydratedFixedLineItems
      );
    } else if (action.payload.filters) {
      const { filters: payloadFilters } = action.payload;
      filters = payloadFilters;
      const [startDate, endDate] = filters.period
        .split(' - ')
        .map(v => _.trim(v));
      const projectReq = yield call(getProjectDetail, filters.project);
      project = projectReq.data;
      selectedInvoice = {
        ...flattenProject(project),
        endDate: endDate,
        startDate: startDate,
        status: InvoiceStatus.UNINVOICED
      };
      if (filters.importTimesheets) {
        timesheetsReq = yield call(Api.fetchTimesheetEntries, {
          page: 1,
          perPage: 300,
          withProjectRoleId: true,
          withTimesheet: true,
          endDate: formatDateForApi(endDate),
          startDate: formatDateForApi(startDate),
          uninvoiced: true,
          projectId: filters.project
        });
      } else {
        timesheetsReq = [];
      }
    }

    const timesheetEntries = timesheetsReq.map(transformTimesheetEntry);
    // user rows
    const dehydratedUserRows = reduceEntriesIntoUserRows(timesheetEntries);
    const [userRows, projectRoles] = yield call(
      hydrateUserRows,
      dehydratedUserRows,
      selectedInvoice.projectId
    );

    const invoiceHours = sumInvoiceHours(timesheetEntries);

    const billableHours = sumHoursByClassification(
      timesheetEntries,
      'billable'
    );
    const nonBillableHours = sumHoursByClassification(
      timesheetEntries,
      'non_billable'
    );

    const view = {
      ...selectedInvoice,
      startDate: removeUTCSignal(selectedInvoice.startDate),
      endDate: removeUTCSignal(selectedInvoice.endDate),
      clientCode: project.client.code,
      clientColor: project.client.color,
      clientId: project.client.id,
      projectNickname: project.nickname,
      filters: filters ? filters : null,
      fixedLineItems,
      timesheetEntries,
      invoicePeriod: formatDatePeriod(
        selectedInvoice.startDate,
        selectedInvoice.endDate,
        true
      ),
      invoicingSpecialInstructions: project.invoicingSpecialInstructions,
      projectEmails: project.invoiceEmailTos
        .map((email: object) => ({ ...email, type: 'to' }))
        .concat(
          project.invoiceEmailCcs.map((email: object) => ({
            ...email,
            type: 'cc'
          }))
        ),
      userRows,
      canSubmit: timesheetEntries.length > 0 || fixedLineItems.length > 0,
      canProceed: _.every(
        userRows,
        ({ timesheetApproval }: { timesheetApproval: boolean }) =>
          timesheetApproval
      ),
      deliverables: project.deliverables,
      projectRoles,
      billableHours,
      nonBillableHours,
      invoiceHours
    };
    yield put(actions.populateHydratedInvoice(view));
    yield put(actions.setIsLoading(false));

    yield put(
      invoiceListActions.fetchSidebarDetailFromDetailView(
        { id: view.id },
        false
      )
    );
    if (filters && filters.createCallback) {
      const { dismiss } = action.payload;
      yield put(actions.createNewInvoice(filters.createCallback, dismiss));
    }
  }
}

function* createNewInvoice(action: Actions) {
  if (action.type === CREATE_NEW_INVOICE) {
    yield put(actions.setIsLoading(true));

    const currentView = yield select(selectPersistedView);
    const currentUser = yield select(currentUserSelector);
    const { dismiss, params } = action.payload;
    let newInvoice;
    if (params) {
      const [startDate, endDate] = params.period
        .split(' - ')
        .map(v => _.trim(v));
      const timesheetsReq = yield call(Api.fetchTimesheetEntries, {
        page: 1,
        perPage: 300,
        withProjectRoleId: false,
        withTimesheet: false,
        endDate: formatDateForApi(endDate),
        startDate: formatDateForApi(startDate),
        uninvoiced: true,
        projectId: params.projectId
      });
      const timesheetEntries = timesheetsReq.data;
      newInvoice = {
        startDate,
        endDate,
        projectId: params.projectId,
        entries: _(timesheetEntries)
          .map(({ id }: { id: number }) => id)
          .uniq()
      };
    } else {
      newInvoice = {
        startDate: currentView.startDate,
        endDate: currentView.endDate,
        projectId: currentView.projectId,
        entries: _(currentView.timesheetEntries)
          .map(({ id }: { id: number }) => id)
          .uniq()
      };
    }
    newInvoice = {
      ...newInvoice,
      creatorId: currentUser.id,
      status: InvoiceStatus.NEW
    };
    const invoiceCreationResp = yield call(postInvoice, newInvoice);
    if (_.eq(invoiceCreationResp.status, 201)) {
      const { callback } = action.payload;
      const invCreation = invoiceCreationResp.data;

      if (dismiss) {
        yield put(
          actions.changeInvoiceStatus(
            InvoiceStatus.DISMISSED,
            undefined,
            invCreation.id
          )
        );
        yield put(invoiceListActions.fetchInvoices({}));
        toast('Invoice Created and Dismissed', 'success');
      } else {
        callback.call(null, `/invoicing/${invCreation.id}`); // apply correct path to this.props.navigate method
        yield put(actions.hydrateInvoice({ id: invCreation.id }));
        toast('Invoice Created', 'success');
      }
    } else {
      const error = invoiceCreationResp.data.errors;
      toast(`Something went wrong: ${error}`, 'error');
    }
    yield put(actions.setIsLoading(false));
  }
}

function* deleteInvoice(action: Actions) {
  if (action.type === DELETE_INVOICE) {
    const currentView = yield select(selectPersistedView);
    const invoiceDeletionResp = yield call(deleteInvoiceApi, currentView.id);
    if (_.eq(invoiceDeletionResp.status, 204)) {
      const { callback } = action.payload;
      callback.call(null, `/invoicing`); // apply correct path to this.props.navigate method
      toast('Invoice Restarted', 'success');
    } else {
      const error = invoiceDeletionResp.data.errors;
      toast(`Something went wrong: ${error}`, 'error');
    }
  }
}

function* createLineItem(action: Actions) {
  if (action.type === SAVE_LINE_ITEM) {
    try {
      const { item } = action.payload;
      const currentView = yield select(selectPersistedView);
      const fileId = yield select(getFixedLineItemId);
      const postNewLineItemResp = yield call(postNewInvoiceLineItem, {
        ...transformNewLineItemForPost(item),
        invoiceId: currentView.id,
        fileId
      });
      if (_.eq(postNewLineItemResp.status, 201)) {
        yield put(actions.hydrateInvoice({ id: currentView.id }));
        toast('New Line Item Created', 'success');
      } else {
        const error = postNewLineItemResp.data.errors;
        toast(`Something went wrong: ${error}`, 'error');
      }
    } catch (e) {
      toast(`Something went wrong: ${e}`, 'error');
    }
  }
}

function* deleteLineItem(action: Actions) {
  if (action.type === DELETE_LINE_ITEM) {
    const currentView = yield select(selectPersistedView);
    const itemToDelete = action.payload;
    const deleteLineItemResp = yield call(deleteLineItemApi, itemToDelete.id);
    if (_.eq(deleteLineItemResp.status, 204)) {
      yield put(actions.hydrateInvoice({ id: currentView.id }));
      toast('Line Item Deleted', 'success');
    } else {
      const error = deleteLineItemResp.data.errors;
      toast(`Something went wrong: ${error}`, 'error');
    }
  }
}

function* dismissRow(action: Actions) {
  if (action.type === DISMISS_TIMESHEET_ROW) {
    const entries = _(action.payload.hourList)
      .map(({ id }: TimesheetEntry) => id)
      .value();
    const isEntry = entries.length === 1;
    const itemToDismiss = _.head(action.payload.hourList);
    let dismissed = false;
    if (itemToDismiss) {
      dismissed = !itemToDismiss.dismissed;
    }

    const currentView = yield select(selectPersistedView);

    const invoiceId = currentView.id;
    const invoice = { entries, dismissed };
    const updateInvoiceResp = yield call(updateInvoice, { invoiceId, invoice });
    if (_.eq(updateInvoiceResp.status, 200)) {
      yield put(actions.hydrateInvoice({ id: invoiceId }));
      toast(
        `Timesheet ${isEntry ? 'Entry' : 'Row'} ${
          dismissed ? 'dismissed' : 'restored'
        }`,
        'success'
      );
    } else {
      const error = updateInvoiceResp.data.errors;
      toast(`Something went wrong: ${error}`, 'error');
    }
  }
}

function* dismissAllTimesheets(action: Actions) {
  if (action.type === DISMISS_ALL_TIMESHEETS) {
    const view: IInvoiceDetailView = yield select(selectPersistedView);
    const { timesheetEntries, id: invoiceId } = view;
    const canDismissTimesheets = timesheetEntries.some(
      entry => entry.dismissed === false
    );

    const dismissed = canDismissTimesheets;
    const entries = _(timesheetEntries)
      .map(({ id }: TimesheetEntry) => id)
      .value();
    const invoice = { entries, dismissed };
    const updateInvoiceResp = yield call(updateInvoice, {
      invoiceId,
      invoice
    });
    if (_.eq(updateInvoiceResp.status, 200)) {
      yield put(actions.hydrateInvoice({ id: invoiceId.toString() }));
      toast(
        `Timesheet entries ${dismissed ? 'dismissed' : 'restored'}`,
        'success'
      );
    } else {
      const error = updateInvoiceResp.data.errors;
      toast(`Something went wrong: ${error}`, 'error');
    }
  }
}

function* updateFixedLineItem(action: Actions) {
  if (action.type === UPDATE_FIXED_LINE_ITEM) {
    const invoiceFixedLineItem = transformNewLineItemForPost(action.payload);
    if (invoiceFixedLineItem) {
      const currentView = yield select(selectPersistedView);
      const updateInvoiceResp = yield call(updateInvoiceLineItemApi, {
        invoiceFixedLineItem
      });
      if (_.eq(updateInvoiceResp.status, 200)) {
        yield put(actions.hydrateInvoice({ id: currentView.id }));
        toast('Fixed Line Item updated', 'success');
      } else {
        const error = updateInvoiceResp.data.errors;
        toast(`Something went wrong: ${error}`, 'error');
      }
    }
  }
}

function* updateTimesheetRow(action: Actions) {
  if (action.type === SAVE_TIMESHEET_ROW) {
    const currentView = yield select(selectPersistedView);
    const updatedTimesheetRow = action.payload;
    if (updatedTimesheetRow) {
      const invoice = _.omit(
        {
          // required due to lodash <> ts
          entries: _(updatedTimesheetRow.hourList)
            .map(({ id }: { id: number }) => id)
            .value(),
          ...updatedTimesheetRow
        },
        [
          'timesheetId',
          'id',
          'hourList',
          'timesheetApproval',
          'totalNonBillableHours',
          'rate',
          'modified',
          'dismissed',
          'startDate',
          'title',
          'contractor',
          'classification'
        ]
      );
      const updateInvoiceResp = yield call(updateInvoiceApi, {
        invoiceId: currentView.id,
        invoice
      });
      if (_.eq(updateInvoiceResp.status, 200)) {
        yield put(actions.hydrateInvoice({ id: currentView.id }));
        toast('Timesheet Row updated', 'success');
      } else {
        const error = updateInvoiceResp.data.errors;
        toast(`Something went wrong: ${error}`, 'error');
      }
    }
  }
}

function* updateTimesheetEntry(action: Actions) {
  // eventually, we may be able to use the timesheet side modification saga
  if (action.type === SAVE_TIMESHEET_ENTRY) {
    const currentView = yield select(selectPersistedView);
    const updatedTimesheetEntry = action.payload;
    if (updatedTimesheetEntry) {
      // any required here due to lodash <> ts
      const entry: any = _.omit(
        {
          ...updatedTimesheetEntry,
          invoicingDescription:
            updatedTimesheetEntry.invoicingDescription.slice(0, 499) ||
            updatedTimesheetEntry.description.slice(0, 499)
        },
        [
          'description',
          'classification',
          'actual',
          'hours',
          'date',
          'nonBill',
          'id',
          'modified',
          'dismissed',
          'edited',
          'timesheetId',
          'timesheet',
          'deliverableId',
          'projectRoleId',
          'invoiceId'
        ]
      );
      const updateTimesheetEntryResp = yield call(Api.updateTimesheetEntry, {
        id: updatedTimesheetEntry.id,
        entry
      });
      yield put(actions.hydrateInvoice({ id: currentView.id }));
    }
  }
}

function* downloadFile(action: Actions) {
  if (action.type === DOWNLOAD_FILE) {
    const blob = yield call(downloadUploadFile, action.payload);
    const currentView = yield select(selectPersistedView);
    const allUploadFiles = _(currentView.fixedLineItems)
      .map((fixedLineItem: IFixedLineItem) =>
        fixedLineItem.fileId
          ? { id: fixedLineItem.fileId, name: fixedLineItem['file.name'] }
          : null
      )
      .uniq()
      .compact()
      .value();
    const file = _.find(
      allUploadFiles,
      ({ id, name }: { id: number; name: string }) => _.eq(id, action.payload)
    );
    let name = 'download';
    if (file) {
      name = file.name;
    }
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = name;
    a.click();
  }
}

function* uploadNewFile(action: Actions) {
  if (action.type === UPLOAD_NEW_FILE) {
    const { file, lineItemId } = action.payload;
    const currentView = yield select(selectPersistedView);
    const formData = new FormData();
    formData.append('upload_file', file, file.name);
    const uploadNewFileReq = yield call(createNewFile, formData);
    const data = JSON.parse(uploadNewFileReq.target.response);
    const { id } = data;
    if (lineItemId) {
      // in the case we're adding an upload file to a fixed line item that already exists
      const updateInvoiceResp = yield call(updateInvoiceLineItemApi, {
        invoiceFixedLineItem: {
          fileId: id,
          id: lineItemId
        }
      });
      if (_.eq(updateInvoiceResp.status, 200)) {
        yield put(actions.hydrateInvoice({ id: currentView.id }));
        toast('New file uploaded', 'success');
      } else {
        const error = updateInvoiceResp.data.errors;
        toast(`Something went wrong: ${error}`, 'error');
      }
    } else {
      // during creation of a new fixed line item
      if (_.eq(uploadNewFileReq.target.status, 201)) {
        yield put(actions.populateFixedLineItemId({ id }));
        toast('New file uploaded', 'success');
      } else {
        const error = uploadNewFileReq.targeet.responseText;
        toast(`Something went wrong: ${error}`, 'error');
      }
    }
  }
}

function* rejectInvoice(action: Actions) {
  if (action.type === CHANGE_STATUS && action.payload.message) {
    yield put(actions.setIsLoading(true));

    const { message: rejectionReason } = action.payload;
    const currentView = yield select(selectPersistedView);

    const invoice = removeEmpty({
      rejectionReason,
      id: currentView.id
    });
    const updateInvoiceResp = yield call(rejectInvoiceApi, {
      invoice
    });

    if (_.eq(updateInvoiceResp.status, 200)) {
      yield put(actions.hydrateInvoice({ id: currentView.id }));
      toast('Invoice rejected', 'success');
    } else {
      const error = updateInvoiceResp.data.errors;
      toast(`Something went wrong: ${error}`, 'error');
    }
    yield put(actions.setIsLoading(false));
  }
}

function* changeInvoiceStatus(action: Actions) {
  let invoiceId;
  try {
    if (action.type === CHANGE_STATUS) {
      const { status, id } = action.payload;
      const currentView = yield select(selectLocalView);
      invoiceId = id ? id : currentView.id;

      yield put(actions.setIsLoading(true));
      const qbIsActive = yield Api.fetchQuickBooksOnlineStatus();
      let message = `Updating Invoice Status to ${status}`;
      let act;

      let rejectionReason;
      let sentDate;
      let paidDate;
      if (status === InvoiceStatus.REJECTED) {
        act = call(rejectInvoice, action);
      }
      if (_.eq(status, InvoiceStatus.SENT)) {
        sentDate = nowForApi();
      }
      if (_.eq(status, InvoiceStatus.PAID)) {
        paidDate = nowForApi();
      }
      if (_.eq(status, InvoiceStatus.APPROVED)) {
        let qbMemo;
        if (qbIsActive) {
          qbMemo = action.payload.message;
          message = `${message} and is creating a Quickbooks Invoice. This may take a few seconds`;
        }
        toast(message, 'info');
        act = yield call(approveInvoiceApi, {
          invoiceId,
          qbMemo
        });

        const { callback } = action.payload;
        if (callback) {
          yield callback.call(null, `/invoicing`); // apply correct path to this.props.navigate method
        }
      } else if (
        _.eq(status, InvoiceStatus.SUBMITTED) &&
        _.eq(currentView?.status, InvoiceStatus.APPROVED)
      ) {
        if (qbIsActive) {
          message = `${message} and is deleting the associated Quickbooks invoice`;
        }

        act = call(reopenInvoiceApi, {
          invoiceId
        });
      } else if (!act) {
        const invoice = removeEmpty({
          status,
          rejectionReason,
          sentDate,
          paidDate
        });

        act = call(updateInvoiceApi, {
          invoice,
          invoiceId
        });
      }

      toast(message, 'info');

      const updateInvoiceResp = yield act;
      if (updateInvoiceResp?.status) {
        if (_.eq(updateInvoiceResp.status, 200)) {
          if (_.eq(status, InvoiceStatus.SUBMITTED)) {
            const { callback } = action.payload;
            if (callback) {
              callback.call(null, `/invoicing`);
              toast(`The invoice was successfully submitted`, 'success');
            }
          } else {
            // Update the invoice list immediately
            yield put(
              invoiceListActions.changeInvoiceStatus(invoiceId, status)
            );
            toast(`Invoice status changed to ${status}`, 'success');
            // don't redirect from invoices screen
            if (!id) {
              yield put(actions.hydrateInvoice({ id: invoiceId }));
            } else {
              // instead, refresh invoice view
              yield put(invoiceListActions.fetchInvoices({}));
            }
          }
        } else {
          toast(`Something went wrong: ${updateInvoiceResp.message}`, 'error');
          // rehydrate anywho, because the invoice may still have updated, just failed on QB operations
          yield put(actions.hydrateInvoice({ id: invoiceId }));
        }
      }
    }
    yield put(actions.setIsLoading(false));
  } catch (e) {
    if (typeGuard.isQBResponseError(e)) {
      toast(e.response.data.error_message, 'error');
    } else if (typeGuard.isResponseError(e)) {
      toast(parseResponseErrorMessage(e), 'error');
    } else {
      toast('Something unexpected happened', 'error');
    }
    // ok to refresh page here
    yield put(actions.hydrateInvoice({ id: invoiceId }));
  }
}

function* saveInvoice(action: Actions) {
  if (action.type === SAVE_INVOICE) {
    yield put(actions.setIsLoading(true));

    let { view } = action.payload;
    let checkForDismissed = true;
    if (!view) {
      view = yield select(selectLocalView);
      checkForDismissed = false;
    }
    const { callback } = action.payload;
    let dismissItemPromises: any[] = [];

    if (view) {
      if (checkForDismissed) {
        const persistedView = yield select(selectPersistedView);
        _(view.userRows)
          .filter('modified')
          .each((timesheetRow: ITimesheetRow) => {
            const persistedRow = _.find(
              persistedView.userRows,
              (r: ITimesheetRow) => r.id === timesheetRow.id
            );
            if (!_.isEqual(timesheetRow, persistedRow)) {
              // This is not very efficient, as a new request is generated for every entry restored or dismissed
              // however, the requests are not large, and do not take a long time, and this is the clearest way to
              // ensure that every entry is correctly dismissed, or restored.
              // Additionally, this 'mass' dismiss/restore functionality is not used very often, if ever
              dismissItemPromises.push(
                _.map(timesheetRow.hourList, (entry: TimesheetEntry) => {
                  const oldEntry = _.find(
                    persistedView.timesheetEntries,
                    ({ id }: { id: number }) => _.eq(id, entry.id)
                  );
                  if (oldEntry && !_.eq(entry.dismissed, oldEntry.dismissed)) {
                    // these are then 'newly' dismissed entries
                    return put(
                      actions.dismissTimesheetRowItem({
                        ...timesheetRow,
                        hourList: [{ ...entry, dismissed: !entry.dismissed }]
                      })
                    );
                  }
                  return cancel();
                })
              );
            }
          });

        dismissItemPromises = _.flatten(dismissItemPromises);
      }

      const fixedLineItemPromises: any[] = _(view.fixedLineItems) // can be a cancel or put effect
        .filter('modified')
        .map((fixedLineItem: IFixedLineItem) => {
          if (validItem(fixedLineItem)) {
            if (fixedLineItem.new) {
              return put(actions.saveLineItem(fixedLineItem));
            }
            return put(actions.updateFixedLineItem(fixedLineItem));
          }
          return cancel(); // a redux-saga no-op
        })
        .value();

      let timesheetEntries: TimesheetEntry[] = [];
      const timesheetRowPromises = _(view.userRows)
        .filter('modified')
        .map(function(timesheetRow: ITimesheetRow) {
          timesheetEntries = _.concat(timesheetEntries, timesheetRow.hourList); // add modified entries to timesheetEntries
          if (validItem(timesheetRow)) {
            // do filtering
            return put(actions.saveTimesheetRow(timesheetRow));
          }
          return cancel(); // a redux-saga no-op
        })
        .value();

      const timesheetEntryPromises = _(timesheetEntries)
        .filter('edited')
        .map(function(timesheetEntry: TimesheetEntry) {
          if (validItem(timesheetEntry)) {
            return put(actions.saveTimesheetEntry(timesheetEntry));
          }
          return cancel(); // a redux-saga no-op
        })
        .value();
      const allPromises = _.concat(
        timesheetRowPromises,
        timesheetEntryPromises,
        fixedLineItemPromises,
        dismissItemPromises
      );
      if (allPromises.length) {
        yield all(allPromises);
      } else {
        const currentView = yield select(selectLocalView);
        yield put(actions.hydrateInvoice({ id: currentView.id }));
      }

      if (callback) {
        callback.call(null, `/invoicing`);
        toast(`The invoice was successfully saved`, 'success');
      }
    }
  }
}

function* fetchTimesheetEntries(action: Actions) {
  if (action.type === FETCH_TIMESHEET_ENTRIES) {
    const view = yield select(selectLocalView);
    const entries = yield call(Api.fetchTimesheetEntries, {
      page: 1,
      perPage: 300,
      withProjectRoleId: true,
      withTimesheet: true,
      endDate: formatDateForApi(view.endDate),
      startDate: formatDateForApi(view.startDate),
      timesheetId: action.payload.timesheetId
    });
    const deliverableIds = _(entries)
      .map(({ deliverableId }: { deliverableId: number }) => deliverableId)
      .uniq()
      .value();
    const deliverables = yield call(Api.fetchDeliverables, {
      id: deliverableIds
    });
    const projectIds = _(deliverables)
      .map(({ projectId }: { projectId: number }) => projectId)
      .uniq()
      .value();
    const projectsReq = yield call(getProjects, {
      id: projectIds,
      withClient: true,
      withRoles: true
    });
    const projects = projectsReq.data;
    const returnEntries = _.map(entries, (entry: TimesheetEntry) => {
      const deliverable = _.find(
        deliverables,
        ({ id }: { id: number }) => id === entry.deliverableId
      );
      const project = _.find(
        projects,
        ({ id }: { id: number }) => id === deliverable.projectId
      );
      const role =
        project && project.roles
          ? _.find(
              project.roles,
              ({ id }: { id: number }) => id === entry.projectRoleId
            )
          : {};
      return {
        ...createHoursItem(transformTimesheetEntry(entry)),
        project,
        deliverable,
        role,
        deliverableId: deliverable.id,
        projectRoleId: role.id,
        timesheet: entry.timesheet,
        hours: entry.hours
      };
    });
    yield put(actions.populateAllTimesheetEntriesForPeriod(returnEntries));
  }
}

function* createNewInstruction(action: Actions) {
  try {
    if (action.type === CREATE_NEW_INSTRUCTION) {
      let invoiceId;
      const view = yield select(selectLocalView);
      if (view) {
        invoiceId = view.id;
      } else {
        const invoice = yield select(currentSelectedInvoice);
        invoiceId = invoice.id;
      }

      const { text, typeOf } = action.payload;
      const postNoteReq = yield call(createInvoiceNote, {
        text,
        invoiceId,
        typeOf
      });
      if (_.eq(postNoteReq.status, 200) && postNoteReq.data) {
        const { note } = postNoteReq.data;
        const user = yield select(currentUserSelector);
        const currentTime = moment();
        yield put(
          invoiceListActions.pushNoteToSidebar({
            ...note,
            typeOf: ENoteType.INVOICE_SPECIAL_INSTRUCTION,
            userName: user.fullName,
            createdAt: currentTime,
            updatedAt: currentTime
          })
        );
      } else {
        const error = postNoteReq.data.errors;
        toast(`Something went wrong: ${error}`, 'error');
      }
    }
  } catch (e) {
    const error = e.response ? e.response.data : 'No additional Data Available';
    toast(`Something went wrong: ${error}`, 'error');
  }
}

function* exportTimesheet(action: Actions) {
  if (action.type === EXPORT_TIMESHEET) {
    let invoiceId;
    const view = yield select(selectLocalView);

    if (action.payload.id) {
      invoiceId = action.payload.id;
    } else {
      invoiceId = view.id;
    }

    const tokenReq = yield call(fetchDownloadToken, { invoiceId });
    const { downloadToken } = tokenReq.data;

    const blob = yield call(fetchTimesheetSpreadsheet, {
      invoiceId,
      downloadToken
    });
    const name = `${view.clientName}-${view.projectName}-${formatAsMMDDYYYY(
      view.startDate
    )}-${formatAsMMDDYYYY(view.endDate)}.xlsx`.replace(/\//gi, '_');
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = name;
    a.click();
  }
}

function* downloadQbPdf(action: Actions) {
  if (action.type === DOWNLOAD_QB_PDF) {
    const view = yield select(selectLocalView);
    const qbInvoiceId = view.qbInvoiceId;

    const blob = yield call(Api.fetchQuickBooksInvoicePdf, { qbInvoiceId });

    const name = `${view.clientName}-${view.projectName}-${formatAsMMDDYYYY(
      view.startDate
    )}-${formatAsMMDDYYYY(view.endDate)}.pdf`.replace(/\//gi, '_');
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = name;
    a.click();
  }
}

function* invoiceSaga() {
  yield all([
    takeLatest(HYDRATE_INVOICE, fetchInvoiceViewData),
    takeLatest(CREATE_NEW_INVOICE, createNewInvoice),
    takeLatest(DELETE_INVOICE, deleteInvoice),
    takeLatest(SAVE_LINE_ITEM, createLineItem),
    takeLatest(DELETE_LINE_ITEM, deleteLineItem),
    takeLatest(DISMISS_TIMESHEET_ROW, dismissRow),
    takeLatest(DISMISS_ALL_TIMESHEETS, dismissAllTimesheets),
    takeLatest(UPDATE_FIXED_LINE_ITEM, updateFixedLineItem),
    takeLatest(SAVE_TIMESHEET_ROW, updateTimesheetRow),
    takeLatest(SAVE_TIMESHEET_ENTRY, updateTimesheetEntry),
    takeLatest(DOWNLOAD_FILE, downloadFile),
    takeLatest(UPLOAD_NEW_FILE, uploadNewFile),
    takeLatest(CHANGE_STATUS, changeInvoiceStatus),
    takeLatest(SAVE_INVOICE, saveInvoice),
    takeLatest(FETCH_TIMESHEET_ENTRIES, fetchTimesheetEntries),
    takeLatest(CREATE_NEW_INSTRUCTION, createNewInstruction),
    takeLatest(EXPORT_TIMESHEET, exportTimesheet),
    takeLatest(DOWNLOAD_QB_PDF, downloadQbPdf)
  ]);
}

export default invoiceSaga;
