import createSagaMiddleware from 'redux-saga';
import authSaga from './shared/auth/saga';
import invoiceEmailsSaga from './shared/invoice-emails/saga';
import invoicesSaga from './shared/invoice-list/saga';
import invoiceSaga from './shared/invoice/saga';
import userSaga from './shared/users/saga';

export const sagaMiddleware = createSagaMiddleware();

export const run = () => {
  sagaMiddleware.run(userSaga);
  sagaMiddleware.run(authSaga);
  sagaMiddleware.run(invoicesSaga);
  sagaMiddleware.run(invoiceSaga);
  sagaMiddleware.run(invoiceEmailsSaga);
};
