import React, { useState } from 'react';
import CustomTab from '../../components/CustomTab/styles';
import { CircularProgress, Tab } from '@mui/material';
import ContactIcon from '@mui/icons-material/ContactMail';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SupervisedIcon from '@mui/icons-material/SupervisedUserCircle';
import WorkIcon from '@mui/icons-material/Work';
import { CustomLink } from './styles';
import {
  noProjectSelectedTab,
  generalTab,
  contactTab,
  deliverablesTab,
  rolesTab
} from './tabs';
import Flex from '../core/Flex';
import { Project, ProjectRole } from 'src/api/types';
import { IInvoice } from 'src/shared/invoice-list/types';

export type ProjectsRightSideMenu = {
  project?: Project;
  isLoading: boolean;
  changeProjectStatus: () => any;
  showStatusDialog: boolean;
  toggleStatusDialog: () => void;
  onEditClick: () => any;
  offset: number;
  toggleOffset: (direction: string) => () => void;
  invoices: IInvoice[];
  projectUserStats: any;
  projectCreateOptions: any;
  deleteInvoiceEmail: (id: any) => void;
  updateProjectData: (data: any, success: () => void) => void;
  createInvoiceEmail: (type: string, data: any, success: () => void) => void;
  loadingCreateInvoiceEmail: boolean;
  loadingAddDeliverable: boolean;
  loadingEditDeliverable: boolean;
  createDeliverable: (data: any, callback: () => void) => void;
  editDeliverable: (data: any, callback: () => void) => void;
  deleteDeliverable: (id: number, callback: () => void) => void;
  loadingAddRole: boolean;
  loadingEditRole: boolean;
  createRole: (data: Partial<ProjectRole>, callback: () => void) => void;
  editRole: (data: Partial<ProjectRole>, callback: () => void) => void;
  path: string;
};

const RightSideMenu: React.FC<ProjectsRightSideMenu> = ({
  project,
  isLoading,
  changeProjectStatus,
  showStatusDialog,
  toggleStatusDialog,
  onEditClick,
  offset,
  toggleOffset,
  invoices,
  projectUserStats,
  projectCreateOptions,
  deleteInvoiceEmail,
  updateProjectData,
  createInvoiceEmail,
  loadingCreateInvoiceEmail,
  loadingAddDeliverable,
  loadingEditDeliverable,
  createDeliverable,
  editDeliverable,
  deleteDeliverable,
  loadingAddRole,
  loadingEditRole,
  createRole,
  editRole,
  path
}) => {
  if (isLoading) {
    return (
      <Flex justifyContent="center" marginTop="24px">
        <CircularProgress></CircularProgress>
      </Flex>
    );
  }

  if (!project) return noProjectSelectedTab();

  const tabNames = ['general', 'contact', 'deliverables', 'roles'];
  const currentTabPath = path.split('/')[3];
  const currentTabNumber = tabNames.indexOf(currentTabPath);
  const [currentTab, setCurrentTab] = useState(currentTabNumber);
  const ProjectType = path.split('/')[1];
  const projectPath = ['', ProjectType, project.id].join('/');
  if (currentTabNumber !== currentTab) setCurrentTab(currentTabNumber);
  return (
    <>
      <CustomTab
        value={currentTab}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab
          icon={<DashboardIcon />}
          label="General"
          onClick={() => {
            setCurrentTab(0);
          }}
          to={projectPath + '/general'}
          component={CustomLink}
        />
        <Tab
          icon={<ContactIcon />}
          label="Contact"
          onClick={() => {
            setCurrentTab(1);
          }}
          component={CustomLink}
          to={projectPath + '/contact'}
        />
        <Tab
          icon={<WorkIcon />}
          label="Deliverables"
          onClick={() => {
            setCurrentTab(2);
          }}
          component={CustomLink}
          to={projectPath + '/deliverables'}
        />
        <Tab
          icon={<SupervisedIcon />}
          label="Roles"
          onClick={() => {
            setCurrentTab(3);
          }}
          to={projectPath + '/roles'}
          component={CustomLink}
        />
      </CustomTab>
      {currentTab === 0
        ? generalTab({
            changeProjectStatus,
            project,
            showStatusDialog,
            toggleStatusDialog,
            onEditClick,
            offset,
            toggleOffset,
            projectUserStats,
            invoices,
            projectPath
          })
        : null}
      {currentTab === 1
        ? contactTab({
            deleteInvoiceEmail,
            updateProjectData,
            createInvoiceEmail,
            loadingCreateInvoiceEmail,
            project,
            projectPath
          })
        : null}
      {currentTab === 2
        ? deliverablesTab({
            projectCreateOptions,
            loadingAddDeliverable,
            loadingEditDeliverable,
            createDeliverable,
            editDeliverable,
            deleteDeliverable,
            project,
            projectPath
          })
        : null}
      {currentTab === 3
        ? rolesTab({
            projectCreateOptions,
            loadingAddRole,
            loadingEditRole,
            createRole,
            editRole,
            project,
            projectPath
          })
        : null}
    </>
  );
};

export default RightSideMenu;
