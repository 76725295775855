import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  getImpersonateUsers as getImpersonateUsersApi,
  handleError,
  impersonateLogin as impersonateLoginApi,
  login as loginApi,
  logout as logoutApi
} from 'src/services/api';

import { ILoginResponse } from 'src/shared/auth/types';
import { IImpersonateUserResponse } from 'src/shared/users/types';
import * as userActions from '../users/actions';
import * as authActions from './actions';

function* getImpersonateUsers() {
  try {
    if (process.env.REACT_APP_BACKDOOR !== 'true') {
      throw new Error('Impersonate users feature not enabled');
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = yield call(getImpersonateUsersApi);
    const { data } = response;
    const transformedUserData: IImpersonateUserResponse[] = data;
    yield put(
      authActions.actions.getImpersonateUsersSucceeded(transformedUserData)
    );
  } catch (error) {
    handleError(error as Error);
  }
}

function* impersonateLogin(action: authActions.Actions) {
  if (action.type !== authActions.IMPERSONATE_LOGIN) return;
  try {
    const { userId } = action.payload;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = yield call(impersonateLoginApi, userId);
    const { data } = response;
    const transformedUserData: ILoginResponse = data;
    const { token } = transformedUserData;
    const originalToken = localStorage.getItem('moment_jwt');
    if (originalToken) {
      localStorage.setItem('moment_jwt', token);
      localStorage.setItem('original_moment_jwt', originalToken);
      yield put(authActions.actions.impersonateLoginSucceeded());
      yield put(userActions.actions.getCurrentUser());
    }
  } catch (error) {
    handleError(error as Error);
  }
}

function* stopImpersonate(action: authActions.Actions) {
  if (action.type !== authActions.STOP_IMPERSONATE) return;
  try {
    const token = localStorage.getItem('original_moment_jwt');
    if (token) {
      localStorage.setItem('moment_jwt', token);
      localStorage.removeItem('original_moment_jwt');
      yield put(authActions.actions.stopImpersonateSucceeded());
      yield put(userActions.actions.getCurrentUser());
    }
  } catch (error) {
    handleError(error as Error);
  }
}

function* login(action: authActions.Actions) {
  if (action.type === authActions.LOGIN) {
    const { code } = action.payload;
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response = yield call(loginApi, code);
      const { data } = response;
      const transformedUserData: ILoginResponse = data;
      const { token } = transformedUserData;
      localStorage.setItem('moment_jwt', token);
      yield put(authActions.actions.loginSucceeded());
      yield put(userActions.actions.getCurrentUser());
    } catch (error) {
      handleError(error as Error);
    }
  }
}

function* verifyActiveSession() {
  try {
    const token = localStorage.getItem('moment_jwt');
    const originalToken = localStorage.getItem('original_moment_jwt');
    if (token) {
      yield put(authActions.actions.loginSucceeded());
      yield put(userActions.actions.getCurrentUser());
      if (originalToken) {
        yield put(authActions.actions.impersonateLoginSucceeded());
      }
    } else {
      yield put(authActions.actions.logout());
    }
  } catch (error) {
    handleError(error as Error);
  }
}

function* logout(action: authActions.Actions) {
  try {
    if (action.type === authActions.LOGOUT) {
      if (!action.payload.force) {
        yield call(logoutApi);
      }
      yield put(authActions.actions.logoutSucceeded());
      localStorage.removeItem('moment_jwt');
      localStorage.removeItem('original_moment_jwt');
    }
  } catch (error) {
    handleError(error as Error);
  }
}

function* authSaga() {
  yield all([
    fork(verifyActiveSession),
    takeEvery(authActions.GET_IMPERSONATE_USERS, getImpersonateUsers),
    takeEvery(authActions.IMPERSONATE_LOGIN, impersonateLogin),
    takeEvery(authActions.STOP_IMPERSONATE, stopImpersonate),
    takeEvery(authActions.LOGIN, login),
    takeEvery(authActions.LOGOUT, logout)
  ]);
}

export default authSaga;
