import React, { useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Modal, MenuItem } from '@mui/material';
import {
  ColumnFlexBox,
  StyledPrimaryButton,
  StyledCancelButton,
  ModalTitle,
  PaddedColumnFlexBox,
  ModalSubTitle,
  FlexBoxSpaceBetween,
  Divider,
  CharacterWarningLimit,
  ModalTitleBar,
  TopBorderFlexBox,
  CustomInput
} from './styles';
import Close from '@mui/icons-material/Close';
import AddCircle from '@mui/icons-material/AddCircle';
import DragAndDrop from 'src/components/shared/DragAndDrop';
import {
  formatAsCurrency,
  parseDateForDatepicker,
  isMysqlValidDate
} from 'src/shared/utils';
import { SHORT_TEXT_CHAR_LIMIT } from 'src/constants';
import { IFixedLineItem } from 'src/shared/invoice/types';
import styled, { theme } from 'src/theme';
import { Deliverable } from 'src/api/types';

const PaperDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: ${theme.palette.background.paper};
  border: 1px solid #d4d4d4;
  box-shadow: ${theme.shadows[5]};
  border-radius: 5px;
  outline: none;
`;

const INITIAL_DATE_VALUE = 'Invalid Date';

export const LineItemModal = ({
  close,
  lineItemToEdit,
  onAddLineItem,
  deliverables,
  uploadNewFile,
  updateLineItem,
  addFile,
  attachedFile,
  removeFile,
  selectedItem
}: {
  close: () => void;
  lineItemToEdit?: IFixedLineItem;
  onAddLineItem: ({
    date,
    amount,
    description
  }: {
    date: string;
    amount: number;
    description: string;
    deliverableId: number;
    id: number;
  }) => void;
  updateLineItem: ({
    lineItem,
    index
  }: {
    lineItem: IFixedLineItem | null;
    index: number;
  }) => void;
  deliverables: Deliverable[];
  uploadNewFile: ({ file }: { file: File }) => void;
  addFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  removeFile: () => void;
  attachedFile?: File;
  selectedItem: number;
}) => {
  const isEditingLineItem = !!lineItemToEdit;

  let initialDescription = '';
  let initialDate = INITIAL_DATE_VALUE;
  let initialAmount = 0;
  let initialDeliverableId = 0;

  if (lineItemToEdit !== undefined) {
    const { amount, description, date, deliverableId } = lineItemToEdit;
    initialDescription = description;
    initialDate = date;
    initialAmount = Number(amount);
    initialDeliverableId = deliverableId;
  }

  const [description, setDescription] = useState<string>(initialDescription);
  const [date, setDate] = useState(initialDate);
  const [amount, setAmount] = useState<number | string>(initialAmount);
  const [deliverableId, setDeliverableId] = useState(initialDeliverableId);
  const [focus, setFocus] = useState('');
  const amountValid = false;
  const dateValid = isMysqlValidDate(date);

  return (
    <Modal open onBackdropClick={close}>
      <PaperDiv>
        <ModalTitleBar>
          <ModalTitle>Line Item</ModalTitle>
          <Close onClick={close} />
        </ModalTitleBar>
        <PaddedColumnFlexBox>
          <ColumnFlexBox>
            <ModalSubTitle>Description</ModalSubTitle>
            <CustomInput
              multiline
              rows={4}
              variant="outlined"
              classes={{
                root: `long-string ${!description.length && 'invalid'}`
              }}
              value={description}
              onChange={({
                target: { value: newVal }
              }: {
                target: { value: string };
              }) => {
                if (newVal.length < SHORT_TEXT_CHAR_LIMIT) {
                  setDescription(newVal);
                } else {
                  setDescription(newVal.substring(0, SHORT_TEXT_CHAR_LIMIT));
                }
              }}
              type="string"
              placeholder="Line Item Description"
            />
            <CharacterWarningLimit>
              {SHORT_TEXT_CHAR_LIMIT - description.length} characters remaining
            </CharacterWarningLimit>
          </ColumnFlexBox>
          <ColumnFlexBox>
            <CustomInput
              placeholder="Select"
              label="Deliverable"
              classes={{ root: `dropdown ${!deliverableId && 'invalid'}` }}
              size="small"
              margin="normal"
              variant="outlined"
              select={true}
              fullWidth={true}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                e.stopPropagation();
                setDeliverableId(parseInt(e.target.value));
              }}
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                e.stopPropagation()
              }
              value={deliverableId}
            >
              <MenuItem value={0} disabled>
                Select
              </MenuItem>
              {deliverables.map((item: { id: number; name: string }) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </CustomInput>
            <CustomInput
              variant="outlined"
              classes={{
                root: `date ${!moment(date).isValid() && 'invalid'}`
              }}
              value={parseDateForDatepicker(date)}
              onChange={({
                target: { value: newVal }
              }: {
                target: { value: string };
              }) => setDate(moment(newVal).format('MM/DD/YYYY'))}
              type="date"
              label="Date"
              placeholder={''}
              error={!dateValid && !_.eq(date, INITIAL_DATE_VALUE)}
            />
            <CustomInput
              variant="outlined"
              value={_.eq(focus, 'amount') ? amount : formatAsCurrency(amount)}
              classes={{
                root: `amount ${!amountValid && 'invalid'}`
              }}
              onChange={({
                target: { value: newVal }
              }: {
                target: { value: string };
              }) => setAmount(newVal)}
              onFocus={() => {
                setFocus('amount');
                if (!amount) {
                  setAmount('');
                }
              }}
              onBlur={({
                target: { value: newVal }
              }: {
                target: { value: string };
              }) => {
                setAmount(parseFloat(newVal));
                setFocus('');
              }}
              type="string"
              label="Amount"
            />
          </ColumnFlexBox>
          <Divider />
          <ColumnFlexBox>
            <FlexBoxSpaceBetween>
              <ModalSubTitle>Attachments</ModalSubTitle>
              {!attachedFile && (
                <React.Fragment>
                  <input
                    accept="image/*"
                    id={`contained-button-file`}
                    multiple
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      addFile(e);
                    }}
                  />
                  <label
                    htmlFor={`contained-button-file`}
                    style={{ cursor: 'pointer' }}
                  >
                    <AddCircle />
                  </label>
                </React.Fragment>
              )}
            </FlexBoxSpaceBetween>
            <DragAndDrop
              uploadNewFile={uploadNewFile}
              externalFile={attachedFile ? [attachedFile] : []}
              onRemoveFile={removeFile}
            />
          </ColumnFlexBox>
        </PaddedColumnFlexBox>

        <TopBorderFlexBox>
          <StyledPrimaryButton
            disabled={
              !(
                !!description.length &&
                Math.abs(Number(amount)) > 0 &&
                isMysqlValidDate(date) &&
                !!deliverableId
              )
            }
            onClick={() => {
              const newLineItemValue = {
                date: date,
                amount: Number(amount),
                description,
                deliverableId
              };
              if (isEditingLineItem && lineItemToEdit?.id !== undefined) {
                updateLineItem({
                  lineItem: { id: lineItemToEdit.id, ...newLineItemValue },
                  index: selectedItem
                });
              } else {
                onAddLineItem({ id: 0, ...newLineItemValue });
              }
              close();
            }}
          >
            {isEditingLineItem ? 'Save Changes' : 'Add Line Item'}
          </StyledPrimaryButton>
          <StyledCancelButton onClick={close}>Cancel</StyledCancelButton>
        </TopBorderFlexBox>
      </PaperDiv>
    </Modal>
  );
};

export default LineItemModal;
