import { toast } from 'src/services/toast';
import Api from '../api';
import { Project, ProjectDetail, ProjectStats2 } from '../types';

export const fetchProjectDetail = async (projectId: number | string) => {
  try {
    const project = await Api.fetchProject(projectId);
    const projectStats = (await Api.fetchProjectStats({ projectId }))
      .projects[0];
    return makeProjectDetail({ ...project, ...projectStats });
  } catch (error) {
    toast('Failed to fetch project detail', 'error');
    throw error;
  }
};

const makeProjectDetail = (project: Project & ProjectStats2) => {
  const projectStatsUtil = new ProjectStatsUtil();
  const spent: number = projectStatsUtil.getSpentAmount(project);
  const uninvoicedAmount: number = projectStatsUtil.getUninvoicedAmount(
    project
  );
  const totalAmount: number = projectStatsUtil.getTotalAmount(project);
  return {
    ...project,
    budgetUsed: calcUsedBudget(project),
    hoursRemaining: calcUsedHours(
      project.estimatedHours,
      project.invoicingHours,
      project.hoursUninvoiced,
      project.hoursUnapproved
    ),
    hoursWorked: projectStatsUtil.hoursWorked(
      project.invoicingHours,
      project.hoursUninvoiced,
      project.hoursUnapproved
    ),
    totalAmount: totalAmount,
    spent,
    uninvoicedAmount
  } as ProjectDetail;
};

export class ProjectStatsUtil {
  getSpentAmount(current: Project & ProjectStats2) {
    const spent =
      current.amountInvoiced + parseFloat(current.lineItemsInvoiced);

    return Number(spent.toFixed(0));
  }

  getUninvoicedAmount(current: Project & ProjectStats2) {
    const spent =
      current.amountUninvoiced + parseFloat(current.lineItemsUnapproved);

    return Number(spent.toFixed(0));
  }

  getTotalAmount(current: Project & ProjectStats2) {
    const total =
      this.getSpentAmount(current) +
      this.getUninvoicedAmount(current) +
      current.amountUnapproved;

    return Number(total.toFixed(0));
  }

  hoursWorked(
    invoicingHours: number,
    hoursUninvoiced: number,
    hoursUnapproved: number
  ) {
    const workedHours = invoicingHours + hoursUninvoiced + hoursUnapproved;

    return Number(workedHours.toFixed(2));
  }

  getRemainingHours(
    estimatedHours: number,
    invoicingHours: number,
    hoursUninvoiced: number,
    hoursUnapproved: number
  ) {
    const hoursWorked = this.hoursWorked(
      invoicingHours,
      hoursUninvoiced,
      hoursUnapproved
    );
    const remainingHours = estimatedHours - hoursWorked;

    return Number(remainingHours.toFixed(2));
  }
}

function calcUsedBudget(current: Project & ProjectStats2) {
  if (
    current.budgetPercentagePreference === 'hours' ||
    current.budgetPercentagePreference === 'none' ||
    !current.budgetPercentagePreference
  ) {
    const estimate = current.estimatedHours || 1;
    const workedHours = current.invoicingHours + current.hoursUninvoiced;
    return Number(((workedHours / estimate) * 100).toFixed(0));
  } else if (
    current.estimatedCost !== 0 &&
    (current.budgetPercentagePreference === 'dollars' ||
      (current.estimatedHours === 0 && !current.budgetPercentagePreference))
  ) {
    const spent =
      current.amountInvoiced +
      current.amountUninvoiced +
      parseFloat(current.lineItemsInvoiced);
    return Number(((spent / current.estimatedCost) * 100).toFixed(0));
  }

  return 0;
}

function calcUsedHours(
  estimated: number,
  invoicingHours: number,
  hoursUninvoiced: number,
  hoursUnapproved: number
) {
  const newEstimate = estimated || 1;
  const workedHours = invoicingHours + hoursUninvoiced + hoursUnapproved;

  return Number(((workedHours * 100) / newEstimate).toFixed(0));
}
