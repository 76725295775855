import * as React from 'react';
import { Checkbox as MCheckbox, CheckboxProps } from '@mui/material';
import { styled } from '@mui/system';

const MCheckboxStyled = styled(MCheckbox)``;

export type ICheckboxProps = Pick<
  CheckboxProps,
  | 'checked'
  | 'defaultChecked'
  | 'disabled'
  | 'id'
  | 'inputRef'
  | 'name'
  | 'onChange'
  | 'onClick'
  | 'required'
  | 'sx'
  | 'value'
>;

export const Checkbox = (props: ICheckboxProps) => {
  return <MCheckboxStyled {...props} />;
};

export default Checkbox;
