import { createClient } from './queries/createClient';
import { fetchClients } from './queries/fetchClients';
import { updateClient } from './queries/updateClient';
import { createUser } from './queries/createUser';
import { searchInvoices } from './queries/searchInvoices';
import { searchUninvoicedProjects } from './queries/searchUninvoicedProjects';
import { fetchInvoiceCounts } from './queries/fetchInvoiceCounts';
import { fetchInvoice } from './queries/fetchInvoice';
import { fetchNotificationType } from './queries/fetchNotificationType';
import { createNotification } from './queries/createNotification';
import { sendNotificationEmail } from './queries/sendNotificationEmail';
import { sendOverdueTimesheetNotification } from './queries/sendOverdueTimesheetNotification';
import { fetchDepartments } from './queries/fetchDepartments';
import { fetchDepartmentUsers } from './queries/fetchDepartmentUsers';
import { createDepartmentUser } from './queries/createDepartmentUser';
import { deleteDepartmentUser } from './queries/deleteDepartmentUser';
import { createProjectRole } from './queries/createProjectRole';
import { updateProjectRole } from './queries/updateProjectRole';
import { fetchProjectRoles } from './queries/fetchProjectRoles';
import { createDeliverable } from './queries/createDeliverable';
import { deleteDeliverable } from './queries/deleteDeliverable';
import { fetchDeliverables } from './queries/fetchDeliverables';
import { updateDeliverable } from './queries/updateDeliverable';
import { createProjectMetric } from './queries/createProjectMetric';
import { createUserProjectRole } from './queries/createUserProjectRole';
import { deleteUserProjectRole } from './queries/deleteUserProjectRole';
import { updateUserProjectRole } from './queries/updateUserProjectRole';
import { fetchUserProjectRoles } from './queries/fetchUserProjectRoles';
import { createQuickbooksCustomer } from './queries/createQuickbooksCustomer';
import { fetchQuickBooksOnlineStatus } from './queries/fetchQuickBooksOnlineStatus';
import { fetchQuickBooksCustomers } from './queries/fetchQuickBooksCustomers';
import { loginQuickBooksOnline } from './queries/loginQuickBooksOnline';
import { logoutQuickBooksOnline } from './queries/logoutQuickBooksOnline';
import { fetchQuickBooksInvoicePdf } from './queries/fetchQuickBooksInvoicePdf';
import { fetchProject } from './queries/fetchProject';
import { updateProject } from './queries/updateProject';
import { createProject } from './queries/createProject';
import { fetchProjectStats } from './queries/fetchProjectStats';
import { fetchProjectCreateOptions } from './queries/fetchProjectCreateOptions';
import { fetchProjectDetail } from './queries/fetchProjectDetail';
import { fetchProjectUserStats } from './queries/fetchProjectUserStats';
import { fetchCurrentTimesheet } from './queries/fetchCurrentTimesheet';
import { fetchTimesheet } from './queries/fetchTimesheet';
import { fetchTimesheetNotes } from './queries/fetchTimesheetNotes';
import { fetchTeamTimesheets } from './queries/fetchTeamTimesheets';
import { fetchTimesheetSidebarDetail } from './queries/fetchTimesheetSidebarDetail';
import { updateTimesheetEntry } from './queries/updateTimesheetEntry';
import { createTimesheetEntry } from './queries/createTimesheetEntry';
import { deleteTimesheetEntry } from './queries/deleteTimesheetEntry';
import { approveTimesheet } from './queries/approveTimesheet';
import { rejectTimesheet } from './queries/rejectTimesheet';
import { reopenTimesheet } from './queries/reopenTimesheet';
import { fetchTimesheets } from './queries/fetchTimesheets';
import { submitTimesheet } from './queries/submitTimesheet';
import { fetchCurrentTimesheetId } from './queries/fetchCurrentTimesheetId';
import { fetchTimesheetEntries } from './queries/fetchTimesheetEntries';

export const Api = {
  approveTimesheet,
  createClient,
  createDeliverable,
  createDepartmentUser,
  createNotification,
  createProject,
  createProjectMetric,
  createProjectRole,
  createQuickbooksCustomer,
  createTimesheetEntry,
  createUser,
  createUserProjectRole,
  deleteDeliverable,
  deleteDepartmentUser,
  deleteTimesheetEntry,
  deleteUserProjectRole,
  fetchCurrentTimesheet,
  fetchCurrentTimesheetId,
  fetchClients,
  fetchDeliverables,
  fetchDepartments,
  fetchDepartmentUsers,
  fetchInvoice,
  fetchInvoiceCounts,
  fetchNotificationType,
  fetchProject,
  fetchProjectCreateOptions,
  fetchProjectDetail,
  fetchProjectRoles,
  fetchProjectStats,
  fetchProjectUserStats,
  fetchQuickBooksInvoicePdf,
  fetchQuickBooksOnlineStatus,
  fetchQuickBooksCustomers,
  fetchTeamTimesheets,
  fetchTimesheet,
  fetchTimesheetEntries,
  fetchTimesheetNotes,
  fetchTimesheetSidebarDetail,
  fetchTimesheets,
  fetchUserProjectRoles,
  loginQuickBooksOnline,
  logoutQuickBooksOnline, // not in use. may not be needed
  rejectTimesheet,
  reopenTimesheet,
  searchInvoices,
  searchUninvoicedProjects,
  sendNotificationEmail,
  sendOverdueTimesheetNotification,
  submitTimesheet,
  updateClient,
  updateDeliverable,
  updateProject,
  updateProjectRole,
  updateTimesheetEntry,
  updateUserProjectRole
};

export default Api;
