import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { Project } from '../types';

export const updateProject = ({
  id,
  project
}: {
  id: number;
  project: any;
}) => {
  const payload = {
    ...project,
    budgetPercentagePreference: project.budgetPercentage,
    clientId: project.client,
    comms: getStatRep(project.comms),
    isActive: Boolean(project.status),
    morale: getStatRep(project.morale),
    projectManagerId: project.manager,
    quality: getStatRep(project.quality)
  };

  return callAPI<Project>({
    data: { project: payload },
    method: 'put',
    url: `/projects/${id}`
  })
    .then(response => response.data)
    .catch(error => {
      toast('Failed to update project', 'error');
      throw error;
    });
};

function getStatRep(stat: string) {
  switch (stat) {
    case 'good':
      return 0;
    case 'warning':
      return 1;
    case 'bad':
      return 2;
    default:
      return ''; // unknown
  }
}
