import React from 'react';
import _ from 'lodash';
import { TimeOffRow, TimeOffPanel } from './styles';
import { Tooltip } from '@mui/material';
import VacationIcon from '../Icons/vacationIcon.svg';
import SickIcon from '../Icons/sickIcon.svg';
import HolidayIcon from '../Icons/holidayIcon.svg';
import { theme } from 'src/theme';
import { Title } from '../styles';
import { ValueColumn } from 'src/components/shared/RightSidebar/RightSidebarInfoPane/styles';
import { UserClassification } from 'src/shared/users/types';
import Flex from 'src/components/core/Flex';
import {
  TimesheetEntry,
  TimesheetEntryClassification,
  TimesheetRow
} from 'src/api/types';

interface EmployeeTimeOffPaneProps {
  employeeClassification: UserClassification;
  rows: TimesheetRow[];
  showIconsOnly?: boolean;
  noPadding?: boolean;
}

const DAILY_HOURS = 8;

const EmployeeTimeOffPane = (props: EmployeeTimeOffPaneProps) => {
  const isContractor =
    props.employeeClassification === UserClassification.CONTRACTOR;
  const vacationHours = getTotalHours(
    props.rows,
    TimesheetEntryClassification.PTO
  );
  const sickHours = getTotalHours(
    props.rows,
    TimesheetEntryClassification.SICK_TIME
  );
  const holidayHours = isContractor ? 0 : getHolidayHours(props.rows);
  const showIconsOnly = props.showIconsOnly;
  const noPadding = props.noPadding;
  const borderData = showIconsOnly ? 'initial' : theme.border;

  return (
    <TimeOffPanel border-data={borderData}>
      <TimeOffRow className={noPadding ? 'no-padding' : ''}>
        <Flex alignItems="center">
          <Tooltip title="Vacation">
            <img src={VacationIcon} height={18} width={18} alt="vacation" />
          </Tooltip>
          {!showIconsOnly && <Title>Vacation</Title>}
        </Flex>
        <ValueColumn>{vacationHours} h</ValueColumn>
      </TimeOffRow>
      <TimeOffRow className={noPadding ? 'no-padding' : ''}>
        <Flex alignItems="center">
          <Tooltip title="Sick">
            <img src={SickIcon} height={18} width={18} alt="sick" />
          </Tooltip>
          {!showIconsOnly && <Title>Sick</Title>}
        </Flex>
        <ValueColumn>{sickHours} h</ValueColumn>
      </TimeOffRow>
      <TimeOffRow className={noPadding ? 'no-padding' : ''}>
        <Flex alignItems="center">
          <Tooltip title="Holiday">
            <img src={HolidayIcon} height={18} width={18} alt="holiday" />
          </Tooltip>
          {!showIconsOnly && <Title>Holiday</Title>}
        </Flex>
        <ValueColumn>{holidayHours} h</ValueColumn>
      </TimeOffRow>
    </TimeOffPanel>
  );
};

const getHolidayHours = (timesheetDays: {}) => {
  return _.reduce(
    timesheetDays,
    (sum: number, timesheetDay: TimesheetRow) =>
      timesheetDay.holiday ? sum + DAILY_HOURS : sum,
    0
  );
};

const getTotalHours = (timesheetDays: {}, workdayType: string) => {
  return _.reduce(
    timesheetDays,
    (sum: number, timesheetDay: TimesheetRow) =>
      _.reduce(
        timesheetDay.entries,
        (sum: number, entry: TimesheetEntry) =>
          _.eq(entry.classification, workdayType) ? sum + entry.hours : sum,
        0
      ) + sum,
    0
  );
};

export default EmployeeTimeOffPane;
