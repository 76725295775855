import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import InputField, { InputType } from 'src/components/shared/inputs/InputField';
import {
  AddEntryWrapper,
  FlexBox,
  InputWrapper,
  OptionWrapper,
  SubTitle,
  FlexBoxJustifyStart,
  InputWrapperTopMargin8
} from './styles';
import { QUERY_DATE_FORMAT, roundToQuarterHour } from 'src/shared/utils';
import { Checkbox, FormControlLabel, Tooltip, Box } from '@mui/material';
import MomentButton from '../shared/inputs/MomentButton';
import { ClientIcon } from '../InvoiceDetail/InvoiceSidebar/InvoiceDetailTabPane/TimesheetEntryPane/styles';
import { MAX_HOURS_PER_DAY, GRIO_ORG_ID } from 'src/constants';
import { MomentDateSelect } from '../shared/inputs/MomentDateSelect';
import {
  Timesheet,
  TimesheetEntryClassification,
  UserProjectRole
} from 'src/api/types';
import { CreateTimesheetEntryParams } from 'src/api/queries/createTimesheetEntry';

export interface IAddEntry {
  addEntry: (params: CreateTimesheetEntryParams) => void;
  userProjectRoles: UserProjectRole[];
  timesheet: Timesheet | null;
  disabledDates: Moment[] | true[];
  selectedDates: Moment[];
  setDates: (dates: Moment[]) => void;
}

export const AddEntry = ({
  addEntry,
  userProjectRoles,
  timesheet,
  disabledDates,
  setDates,
  selectedDates
}: IAddEntry) => {
  const [hours, setHours] = useState(0);

  const [description, setDescription] = useState('');
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [bucketId, setBucket] = useState(0);
  const [classification, setClassification] = useState(
    TimesheetEntryClassification.BILLABLE
  );
  const bucket = _.find(userProjectRoles, ({ id }: UserProjectRole) =>
    _.eq(id, bucketId)
  );
  const handleSubmit = () => {
    if (!timesheet) return;
    if (!canAdd) {
      setHasSubmitted(true);
      return;
    }
    setHasSubmitted(false);

    if (bucket) {
      addEntry({
        hours: roundToQuarterHour(hours),
        description,
        classification,
        dates: selectedDates.map((m: Moment) => m.format(QUERY_DATE_FORMAT)),
        deliverable: bucket.deliverable.id,
        projectRole: bucket.projectRole.id,
        startDate: moment(bucket.user.startDate).format(QUERY_DATE_FORMAT),
        timesheetId: timesheet.id
      });
      setHours(0);
      setDescription('');
      setBucket(0);
      setClassification(TimesheetEntryClassification.BILLABLE);
    }
  };

  let canBeBillable = true;
  if (bucket) {
    canBeBillable = !_.eq(bucket.client.id, GRIO_ORG_ID);
  }
  const dateIsValid =
    selectedDates.length &&
    _.every(selectedDates, (date: Moment) => date.isValid());
  const canAdd =
    dateIsValid &&
    hours > 0 &&
    bucket &&
    (_.eq(classification, TimesheetEntryClassification.BILLABLE)
      ? description.length
      : true);
  const errorTitle =
    hasSubmitted && !canAdd ? (
      <p>
        {!dateIsValid ? (
          <span>
            Date is Invalid
            <br />
          </span>
        ) : (
          ''
        )}
        {!hours ? (
          <span>
            Must have hours <br />
          </span>
        ) : (
          ''
        )}
        {!description.length ? (
          <span>
            Must have Description <br />
          </span>
        ) : (
          ''
        )}
        {!bucket ? (
          <span>
            Must have Deliverable <br />
          </span>
        ) : (
          ''
        )}
      </p>
    ) : null;

  if (!timesheet) return null;

  const button = (
    <Box>
      <MomentButton
        title="Add Time"
        onClick={handleSubmit}
        disabled={hasSubmitted && !canAdd}
        style={{ marginTop: 0 }}
      />
    </Box>
  );

  return (
    <AddEntryWrapper>
      <FlexBox>
        <InputWrapperTopMargin8>
          <MomentDateSelect
            dates={selectedDates}
            setDates={setDates}
            minDate={
              timesheet ? moment(timesheet.startDate, 'YYYY-MM-DD') : moment()
            }
            maxDate={
              timesheet ? moment(timesheet.endDate, 'YYYY-MM-DD') : moment()
            }
            disabledDates={disabledDates}
          />
        </InputWrapperTopMargin8>
        <InputWrapper>
          <InputField
            margin="normal"
            label="Hours"
            fullWidth
            type={InputType.NUMBER}
            value={hours}
            modifyNewItemValue={(value: string) => {
              let num = parseFloat(value);
              if (num > MAX_HOURS_PER_DAY) {
                num = MAX_HOURS_PER_DAY;
              }
              if (num < 0) {
                num = 0;
              }
              setHours(roundToQuarterHour(num)); // round to nearest 0.25
            }}
            validation="hours"
          />
        </InputWrapper>
        <InputWrapper>
          <InputField
            className="no-extra-padding"
            margin="normal"
            fullWidth
            type={InputType.DROPDOWN}
            value={bucketId}
            modifyNewItemValue={(value: number) => {
              const bucket = _.find(
                userProjectRoles,
                ({ id }: UserProjectRole) => _.eq(id, value)
              );
              if (bucket) {
                canBeBillable = !_.eq(bucket.client.id, GRIO_ORG_ID);
                if (
                  !canBeBillable &&
                  !_.eq(
                    classification,
                    TimesheetEntryClassification.NON_BILLABLE
                  )
                ) {
                  setClassification(TimesheetEntryClassification.NON_BILLABLE);
                }
                if (
                  canBeBillable &&
                  !_.eq(classification, TimesheetEntryClassification.BILLABLE)
                ) {
                  setClassification(TimesheetEntryClassification.BILLABLE);
                }
              }
              setBucket(value);
            }}
            validation="deliverable"
            options={userProjectRoles.map(
              ({
                id,
                deliverable,
                projectRole,
                client,
                project
              }: UserProjectRole) => ({
                id,
                name: (
                  <OptionWrapper>
                    <FlexBoxJustifyStart>
                      <ClientIcon color={client.color} />
                      {client.code} - {project.nickname}
                    </FlexBoxJustifyStart>
                    <FlexBox>
                      <SubTitle>
                        {deliverable.name} - {projectRole.name}
                      </SubTitle>
                    </FlexBox>
                  </OptionWrapper>
                )
              })
            )}
          />
        </InputWrapper>
        <InputWrapper>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!canBeBillable}
                checked={_.eq(
                  classification,
                  TimesheetEntryClassification.BILLABLE
                )}
                onChange={(
                  _e: React.ChangeEvent<HTMLInputElement>,
                  checked: boolean
                ) =>
                  setClassification(
                    checked
                      ? TimesheetEntryClassification.BILLABLE
                      : TimesheetEntryClassification.NON_BILLABLE
                  )
                }
              />
            }
            label="Billable"
          />
        </InputWrapper>
      </FlexBox>
      <FlexBox>
        <InputWrapper>
          <InputField
            margin="normal"
            immediateChange
            momentTextField={false}
            type={InputType.LONG_STRING}
            validation="description"
            modifyNewItemValue={(value: string) => setDescription(value)}
            value={description}
            placeholder="Description"
            fullHeight
          />
        </InputWrapper>
        {errorTitle ? <Tooltip title={errorTitle}>{button}</Tooltip> : button}
      </FlexBox>
    </AddEntryWrapper>
  );
};

export default AddEntry;
