import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { IClient } from 'src/types/IClient';
import { transformClientsResponse } from './utils';

const ERROR_MESSAGE = 'Failed to fetch clients';

export const fetchClients = (params?: {
  id?: number | string;
  isActive?: boolean;
  exceptSelf?: boolean;
  hasActiveProjects?: boolean;
}) =>
  callAPI({
    method: 'get',
    url: '/clients',
    params,
    transform: transformClientsResponse
  })
    .then(({ data }) => data as IClient[])
    .catch(error => {
      toast(ERROR_MESSAGE, 'error');
      throw error;
    });
