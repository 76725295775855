import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import { CirclePicker } from 'react-color';
import messages from 'src/messages';
import { Container, CustomButton, CustomInput } from './styles';
import { FunctionComponent, useContext } from 'react';
import { CLIENT_COLORS } from 'src/constants';
import { QBCustomerDropdown } from '../qb/QBCustomerDropdown';
import { AppContext } from 'src/contexts/app-context';

interface IClientEdit {
  values: {
    id: number;
    name: string;
    code: string;
    color: string;
    qbCustomerId?: number;
  };
  loading: boolean;
  errors: any;
  onChange: (props: string) => (event: any) => void;
  onSave: () => void;
  onCancel: () => void;
}

export const ClientEdit: FunctionComponent<IClientEdit> = props => {
  const context = useContext(AppContext);

  const colorChange = (value: any) => {
    props.onChange('color')({
      target: {
        value: value.hex
      }
    });
  };

  return (
    <Container>
      <h3>{'Edit Client'}</h3>
      <CustomInput
        required={true}
        error={props.errors.name}
        id="name-add-input"
        label={messages.LABELS.NAME}
        helperText={props.errors.name ? messages.REQUIRED : ''}
        value={props.values.name}
        onChange={props.onChange('name')}
        margin="normal"
        variant="outlined"
        fullWidth
      />
      <CustomInput
        required={true}
        id="code-add-input"
        error={props.errors.code}
        label={messages.LABELS.CODE}
        value={props.values.code}
        helperText={props.errors.code ? messages.REQUIRED : ''}
        onChange={props.onChange('code')}
        margin="normal"
        variant="outlined"
        fullWidth
      />
      {context.isAuthenticatedQuickBooksOnline && (
        <QBCustomerDropdown
          qbCustomers={[]}
          setCustomerId={id => console.log(id)}
          qbCustomerId={undefined}
        />
      )}
      <CirclePicker
        color={props.values.color}
        colors={CLIENT_COLORS}
        onChange={colorChange}
      />
      {props.loading ? (
        <CircularProgress />
      ) : (
        <React.Fragment>
          <CustomButton
            fullWidth={true}
            color="primary"
            onClick={props.onSave}
            variant="contained"
          >
            {messages.SAVE}
          </CustomButton>
          <CustomButton
            fullWidth={true}
            variant="contained"
            onClick={props.onCancel}
          >
            {messages.CANCEL}
          </CustomButton>
        </React.Fragment>
      )}
    </Container>
  );
};

export default ClientEdit;
