import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField
} from '@mui/material';
import messages from 'src/messages';

interface IRejectTimesheetDialog {
  open: boolean;
  onClose: () => void;
  onRejectTimesheet: (reason: string) => void;
}

const RejectTimesheetDialog: React.FC<IRejectTimesheetDialog> = ({
  open,
  onClose,
  onRejectTimesheet
}) => {
  const [reason, setReason] = useState('');
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {messages.REJECT_TIMESHEET_REASON}
        </DialogContentText>
        <TextField fullWidth onChange={e => setReason(e.target.value)} />
        <DialogActions>
          <Button
            onClick={() => {
              setReason('');
              onClose();
            }}
            color="primary"
          >
            {messages.CANCEL}
          </Button>
          <Button
            autoFocus
            onClick={() => {
              onRejectTimesheet(reason);
              setReason('');
            }}
            color="primary"
          >
            {messages.REJECT}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default RejectTimesheetDialog;
