import Avatar, { AvatarProps } from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid, { GridProps } from '@mui/material/Grid';
import styled from 'src/theme';

export const Container: React.ComponentType<GridProps> = styled(Grid)`` as any;

export const CustomAvatarButton = styled(Button)`
  && {
    padding: 0;
    min-width: 32px;
    width: 32px;
  }
` as any;

export const CustomAvatar: React.ComponentType<AvatarProps> = styled(Avatar)`
  && {
    background-color: #e0e0e0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    height: 32px;
    width: 32px;
  }
` as any;
