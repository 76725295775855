import React, { useContext } from 'react';
import { FlexBox, AlignSelfStart } from './styles';
import { QBIcon } from '../QBIcon';
import { AppContext } from 'src/contexts/app-context';

export const QBConnectedClientName = ({
  children,
  hasQBCustomerId
}: {
  children: JSX.Element | string;
  hasQBCustomerId: boolean;
}) => {
  const context = useContext(AppContext);

  return (
    <FlexBox>
      {children}
      <AlignSelfStart>
        {context.isAuthenticatedQuickBooksOnline && hasQBCustomerId && (
          <QBIcon />
        )}
      </AlignSelfStart>
    </FlexBox>
  );
};
