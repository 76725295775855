import React, { FunctionComponent, useContext, useState } from 'react';
import _ from 'lodash';

import { FlexBox, FlexBoxJustifyEnd } from './styles';
import InvoiceActionButtons from 'src/components/InvoiceDetailFooter/InvoiceActionButtons';
import { InvoiceStatus } from 'src/shared/invoice-list/types';
import MessageModal from 'src/components/InvoiceDetailFooter/MessageModal';
import { IInvoiceDetailView } from 'src/shared/invoice/types';
import { formatDatePeriod } from 'src/shared/utils';
import { AppContext } from 'src/contexts/app-context';

interface IInvoiceDetailFooter {
  isAdmin: boolean;
  isFinance: boolean;
  changeStatus: (status: InvoiceStatus, reason?: string) => void;
  deleteInvoice: () => void;
  createNewInvoice: (dismiss?: boolean) => void;
  view?: IInvoiceDetailView;
  saveInvoice: () => void;
  exportTimesheet: () => void;
  downloadQbPdf: () => void;
}

interface IInvoiceDetailFooterState {
  rejectModal: boolean;
  qbApproveModal: boolean;
}

const initialState = {
  rejectModal: false,
  qbApproveModal: false
};

const InvoiceDetailFooter: FunctionComponent<IInvoiceDetailFooter> = props => {
  const context = useContext(AppContext);

  const [state, setState] = useState<IInvoiceDetailFooterState>(initialState);

  const handleButtonClick = (e: string) => {
    switch (e) {
      case 'restart':
        props.deleteInvoice();
        break;
      case 'submit':
        props.changeStatus(InvoiceStatus.SUBMITTED);
        break;
      case 'reject':
        setState({ ...state, rejectModal: true });
        break;
      case 'approve':
        if (context.isAuthenticatedQuickBooksOnline) {
          setState({ ...state, qbApproveModal: true });
        } else {
          props.changeStatus(InvoiceStatus.APPROVED);
        }
        break;
      case 'reopen':
        props.changeStatus(InvoiceStatus.SUBMITTED);
        break;
      case 'send':
        props.changeStatus(InvoiceStatus.SENT);
        break;
      case 'unsend':
        props.changeStatus(InvoiceStatus.APPROVED);
        break;
      case 'paid':
        props.changeStatus(InvoiceStatus.PAID);
        break;
      case 'unpay':
        props.changeStatus(InvoiceStatus.SENT);
        break;
      case 'create':
        props.createNewInvoice();
        break;
      case 'recover':
        props.changeStatus(InvoiceStatus.IN_PROGRESS);
        break;
      case 'dismiss':
        if (props.view && _.eq(props.view.status, InvoiceStatus.UNINVOICED)) {
          props.createNewInvoice(true);
        } else {
          props.changeStatus(InvoiceStatus.DISMISSED);
        }
        break;
      case 'save':
        props.saveInvoice();
        break;
      case 'export':
        props.exportTimesheet();
        break;
      case 'download qb pdf':
        props.downloadQbPdf();
    }
  };

  const { view, isAdmin, isFinance } = props;
  const { rejectModal, qbApproveModal } = state;
  if (!view) {
    return null;
  }
  let modal;
  if (rejectModal || qbApproveModal) {
    const { clientName } = view;
    const initialMessage = qbApproveModal
      ? `${clientName} invoice from Grio for ${formatDatePeriod(
          view.startDate,
          view.endDate,
          true
        )}`
      : '';
    modal = (
      <MessageModal
        isReject={rejectModal}
        initialMessage={initialMessage}
        onOk={(reason: string) => {
          setState(initialState);
          props.changeStatus(
            rejectModal ? InvoiceStatus.REJECTED : InvoiceStatus.APPROVED,
            reason
          );
        }}
        onCancel={() => setState(initialState)}
      />
    );
  }

  return (
    <React.Fragment>
      {modal}
      <FlexBox>
        <FlexBoxJustifyEnd>
          {view && (
            <InvoiceActionButtons
              onClick={handleButtonClick}
              status={view.status}
              canProceed={view.canProceed}
              canSubmit={view.canSubmit}
              isAdmin={isAdmin}
              isFinance={isFinance}
              hasQuickBooksInvoiceId={!!view.qbInvoiceId}
            />
          )}
        </FlexBoxJustifyEnd>
      </FlexBox>
    </React.Fragment>
  );
};

export default InvoiceDetailFooter;
