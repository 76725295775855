import { Button, Box } from '@mui/material';
import { AddButton } from 'src/shared/add-button';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import styled, { theme } from 'src/theme';

export const FlexBox = styled(Box)`
  display: flex;
`;

export const InputWrapper = styled(FlexBox)`
  padding: 24px;
  flex-direction: column;
  p {
    color: ${theme.darkGray};
    font-family: ${theme.font};
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 48px;
  }
`;

export const Header = styled(Box)`
  border-bottom: 1px solid ${theme.borderColor};
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  svg {
    cursor: pointer;
  }
`;

export const Title = styled.div`
  color: ${theme.darkGray};
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  font-family: ${theme.font};
`;

export const InputTitle = styled(Title)`
  padding-top: 10px;
  font-size: 12px;
`;

export const CustomInput: React.ComponentType<TextFieldProps> = styled(
  TextField
)`
  && {
    font-size: 14px;
    width: 100%;
    margin-right: 10px;
    margin-top: 2px;
  }
` as any;

export const SearchTextField: React.ComponentType<TextFieldProps> = styled(
  TextField
)`
  && {
    font-size: 14px;
  }
` as any;

export const Footer = styled(Box)`
  border-top: 1px solid ${theme.borderColor};
  display: flex;
  justify-content: start;
  padding: 16px 24px;
`;

export const CancelButton = styled(Button)`
  && {
    border: 1px solid ${theme.black};
    border-radius: 5px;
    background-color: ${theme.white};
    height: 37px;
    width: 109px;
    margin-left: 16px;
    font-family: ${theme.font};
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    text-transform: none;
  }
`;

export const CreateButton = styled(AddButton)`
  && {
    color: ${theme.white} !important;
    text-transform: none;
    width: auto;
  }
  &&:disabled {
    background-color: ${theme.tealAlt};
  }
`;
