import * as React from 'react';
import ClientAdd from '../../components/ClientAdd';
import { FunctionComponent, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { QueryKeys } from 'src/queries/queryKeys';
import { Api } from 'src/api/api';
import { Color } from 'src/constants';
import { AppContext } from 'src/contexts/app-context';

interface IClientAddContainerState {
  code: string;
  color: string;
  errors: any;
  isCreatingQbCustomer: boolean;
  name: string;
  [x: string]: any;
}

export const ClientAddContainer: FunctionComponent = props => {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const [state, setState] = useState<IClientAddContainerState>({
    code: '',
    color: Color.MEXICAN_PINK,
    errors: {
      code: false,
      name: false
    },
    isCreatingQbCustomer: false,
    name: ''
  });
  const queryClient = useQueryClient();

  const { mutate: createClient, isLoading: isCreatingClient } = useMutation(
    Api.createClient,
    {
      onSuccess: ({ name, id }) => {
        queryClient.invalidateQueries(QueryKeys.clients);
        if (
          context.isAuthenticatedQuickBooksOnline &&
          state.isCreatingQbCustomer
        ) {
          Api.createQuickbooksCustomer({ name, id });
        }
        navigate('/client-management', { replace: true });
      }
    }
  );

  const values = () => {
    const { code, color, name } = state;

    return {
      code,
      color,
      name
    };
  };

  const errors = () => {
    const { code, color, name } = state.errors;

    return {
      code,
      color,
      name
    };
  };

  const submitIsValid = () => {
    const { code, color, name } = state;
    const errors = {
      code: code === '',
      color: color === '',
      name: name === ''
    };

    setState({
      ...state,
      errors
    });

    return !(errors.code || errors.name || errors.color);
  };

  const onFormChange = (prop: string) => (event: any) => {
    const newState = {
      ...state,
      [prop]: event.target.value
    };
    setState(newState);
  };

  const onSave = () => {
    if (submitIsValid()) {
      createClient({
        code: state.code,
        color: state.color,
        name: state.name
      });
    }
  };

  const onCancel = () => {
    navigate('/client-management', { replace: true });
  };

  return (
    <ClientAdd
      loading={isCreatingClient}
      errors={errors()}
      onSave={onSave}
      onCancel={onCancel}
      onChange={onFormChange}
      values={values()}
    />
  );
};

export default ClientAddContainer;
