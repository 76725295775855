import React, { FunctionComponent } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Tooltip } from '@mui/material';
import {
  ColumnFlexBoxWithBottomBorder,
  FlexBox,
  KeyColumn,
  KeyColumnHeader,
  ValueColumn,
  ValueColumnHeader,
  StyledHighlightOff
} from 'src/components/shared/RightSidebar/RightSidebarInfoPane/styles';
import {
  RejectionColumn,
  RejectionText,
  ViewTimesheet,
  LaunchIcon,
  ButtonBox,
  DetailHeader
} from './styles';
import { formatDatePeriod } from 'src/shared/utils';
import TimesheetUtilizationChart from 'src/components/TimesheetUtilizationChart';
import EmployeeTimeOffPane from '../EmployeeTimeOffPane';
import EmployeeTimesheetsPane from '../EmployeeTimesheetsPane';
import {
  SIDEBAR_CHART_HEIGHT,
  SIDEBAR_CHART_WIDTH
} from 'src/components/shared/RightSidebar/RightSidebarInfoPane/constants';
import TimesheetProjectsPane from '../TimesheetProjectsPane';
import { ENoteType, Note, Timesheet, TimesheetStatusEnum } from 'src/api/types';

interface IEmployeeDetailsPane {
  timesheet: Timesheet;
  timesheetsList: Timesheet[];
  showMoreTimesheets: () => void;
  totalTimesheets: number;
  hideTimesheetLink?: boolean;
  onClickViewTimesheet?: () => void;
  onClickTimesheet?: (timesheetId: number) => void;
}

export const EmployeeDetailsPane: FunctionComponent<IEmployeeDetailsPane> = props => {
  const {
    timesheet,
    timesheetsList,
    totalTimesheets,
    showMoreTimesheets,
    hideTimesheetLink
  } = props;

  let rejectionNote: Note = {
    text: timesheet?.rejectionReason || '',
    userFullName: timesheet.owner.manager.fullName,
    typeOf: ENoteType.TIMESHEET_REJECTION_REASON,
    timesheet: timesheet.id,
    createdAt: timesheet.dateCreated,
    updatedAt: timesheet.dateCreated
  };

  if (_.eq(timesheet.status, TimesheetStatusEnum.REJECTED)) {
    const notes = timesheet.notes;
    if (notes && notes.length) {
      const rejectionNotes = _(notes ? notes : [])
        .filter(
          (note: Note) => note.typeOf === ENoteType.TIMESHEET_REJECTION_REASON
        )
        .uniq()
        .compact()
        .sort((a: Note, b: Note) => (a.createdAt > b.createdAt ? -1 : 1))
        .value();

      const latest = _.head(rejectionNotes);
      if (latest) {
        rejectionNote = latest;
      }
    }
  }

  return (
    <React.Fragment>
      <ColumnFlexBoxWithBottomBorder>
        <FlexBox>
          <DetailHeader>
            {formatDatePeriod(timesheet.startDate, timesheet.endDate, true)}
          </DetailHeader>
          {!hideTimesheetLink && (
            <ViewTimesheet onClick={props.onClickViewTimesheet}>
              <ButtonBox>
                View timesheet
                <LaunchIcon />
              </ButtonBox>
            </ViewTimesheet>
          )}
        </FlexBox>
        <Tooltip title="Includes project, vacation, sick and holiday hours">
          <FlexBox>
            <KeyColumnHeader>Total Hours</KeyColumnHeader>
            <ValueColumnHeader>{timesheet.totalHours} h</ValueColumnHeader>
          </FlexBox>
        </Tooltip>
        <FlexBox>
          <KeyColumn>Status</KeyColumn>
          <ValueColumn>{_.capitalize(timesheet.status)}</ValueColumn>
        </FlexBox>
        {_.eq(timesheet.status, TimesheetStatusEnum.REJECTED) && rejectionNote && (
          <React.Fragment>
            <FlexBox>
              <KeyColumn>Rejected By</KeyColumn>
              <ValueColumn>
                {rejectionNote.userFullName} -{' '}
                {moment(rejectionNote.createdAt).format('MMM DD')}
              </ValueColumn>
            </FlexBox>
            <FlexBox>
              <RejectionColumn className="rejection-wrapper">
                <RejectionText className="rejection">
                  <StyledHighlightOff />
                  {rejectionNote.text}
                </RejectionText>
              </RejectionColumn>
            </FlexBox>
          </React.Fragment>
        )}
      </ColumnFlexBoxWithBottomBorder>
      <TimesheetProjectsPane timesheet={timesheet} />
      <EmployeeTimeOffPane
        employeeClassification={timesheet.owner.classification}
        rows={timesheet.timesheetDays}
      />
      <TimesheetUtilizationChart
        rows={timesheet.timesheetDays}
        width={SIDEBAR_CHART_WIDTH}
        height={SIDEBAR_CHART_HEIGHT}
      />
      <EmployeeTimesheetsPane
        timesheets={timesheetsList}
        showMoreTimesheets={showMoreTimesheets}
        totalTimesheets={totalTimesheets}
        onClickTimesheet={props.onClickTimesheet}
      />
    </React.Fragment>
  );
};

export default EmployeeDetailsPane;
