import styled, { theme } from 'src/theme';
import { PrimaryButton } from 'src/shared/primary-button';
import { SecondaryButton } from 'src/shared/secondary-button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

export const ChartAndUtilization = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    & > * {
      flex-grow: 2;
    }
    & > :first-child {
      align-self: start;
      border: none;
      flex-grow: 1;
    }
  }
`;

export const DivisionLine = styled.div`
  && {
    flex: 0 0 1px;
    height: 155px;
    margin-top: 5px;
    background-color: #dedede;
  }
`;

export const StyledDialog = styled(Dialog)`
  && {
    .MuiDialog-paperScrollPaper {
      max-height: calc(100% - 175px);
      overflow-y: hidden;
      .MuiDialogContent-root {
        padding: 0;
        overflow-x: hidden;
      }
    }
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  && {
    justify-content: flex-start;
    padding: 10px 20px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.15);
    z-index: 10;
  }
`;

export const Header = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100px;
  }
`;

export const UserTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 16px;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  margin-right: 15px !important;
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
  margin-right: 10px !important;
`;

export const DialogLoad = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 960px;
    height: 820px;
  }
`;

export const ConfirmationDialogContainer = styled.div`
  && {
    position: relative;
  }
`;

export const ErrorMessage = styled.span`
  font-family: ${theme.font};
  color: rgba(33, 33, 33, 0.87);
  font-size: 14px;
`;
