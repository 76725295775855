import styled, { theme } from '../../theme';
import { Avatar } from '@mui/material';

export const FlexBox = styled.div`
  align-items: center;
  background: #fff;
  border: 1px dashed ${theme.borderColor};
  border-radius: 4px;
  bottom: 0;
  display: flex;
  padding: 0 8px;
  right: 4px;
  z-index: 99999;
`;

export const StyledAvatar = styled(Avatar)`
  && {
    color: ${theme.primaryColor};
    background-color: transparent;
    text-transform: uppercase;
    border: ${theme.border};
    cursor: pointer;
    margin-top: 10px;
  }
  &&:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
