import React, { FunctionComponent } from 'react';
import {
  FlexBox,
  FlexBoxJustifyStart,
  Header,
  Dates,
  StyledStatusBox
} from './styles';
import { StatusIcon } from '../InvoiceList/InvoiceListTable/styles';
import { InvoiceStatus } from 'src/shared/invoice-list/types';
import { IInvoiceDetailView } from 'src/shared/invoice/types';
import { formatDatePeriod } from 'src/shared/utils';

interface IInvoiceDetailAppBar {
  view?: IInvoiceDetailView;
}

const InvoiceDetailAppBar: FunctionComponent<IInvoiceDetailAppBar> = props => {
  const { view } = props;

  const renderStatus = (status: string) => {
    let textStatus = '';
    let statusClass = '';
    switch (status) {
      case InvoiceStatus.NEW:
        textStatus = 'READY';
        statusClass = 'ready';
        break;
      case InvoiceStatus.IN_PROGRESS:
        textStatus = 'PENDING';
        statusClass = 'in-progress';
        break;
      case InvoiceStatus.REJECTED:
        textStatus = 'REJECTED';
        statusClass = 'rejected';
        break;
      default:
        break;
    }
    return (
      <StyledStatusBox>
        <StatusIcon className={statusClass} />
        {textStatus}
      </StyledStatusBox>
    );
  };

  if (!view) {
    return null;
  }

  return (
    <React.Fragment>
      <FlexBox>
        <FlexBoxJustifyStart>
          <Header>{`${view?.clientCode} - ${view?.projectNickname}`}</Header>
          <Dates>{formatDatePeriod(view.startDate, view.endDate, true)}</Dates>
          {renderStatus(view.status)}
        </FlexBoxJustifyStart>
      </FlexBox>
    </React.Fragment>
  );
};

export default InvoiceDetailAppBar;
