import _ from 'lodash';
import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { Note } from '../types';

export const fetchTimesheetNotes = (timesheetId: number | number[]) =>
  callAPI({
    method: 'get',
    url: '/timesheet_note',
    params: { timesheetId }
  })
    .then(response => {
      const flattenedNotes: Note[] = _.map(response.data, function({
        note,
        timesheet,
        userFullName
      }) {
        return {
          ...note,
          timesheet: timesheet,
          userFullName: userFullName
        };
      });

      return flattenedNotes;
    })
    .catch(error => {
      toast('Failed to fetch timesheet notes', 'error');
      throw error;
    });
