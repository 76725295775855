import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { FunctionComponent, useMemo } from 'react';
import constants from 'src/constants';
import messages from 'src/messages';
import { UserClassification } from 'src/shared/users/types';
import { Container, CustomButton } from './styles';
import { Select as MSelect } from '../core/Select';
import { Department } from 'src/api/types';

interface IUserAdd {
  values: any;
  roles: any;
  departments: Department[];
  managers: any;
  loading: boolean;
  errors: any;
  onChange: (props: string) => (event: any) => void;
  onSave: () => void;
  onCancel: () => void;
}

const UserAdd: FunctionComponent<IUserAdd> = props => {
  const roleSelectRenderer = (selected: any) => {
    return (
      <div>
        {selected.map((value: any) => (
          <Chip key={value} label={props.roles[value].name} />
        ))}
      </div>
    );
  };

  const notificationSelectRenderer = (selected: any) => {
    return (
      <div>
        {selected.map((value: any) => {
          const lab = constants.NOTIFICATION_PREF.find(
            (cur: any) => cur.key === value
          );
          return <Chip key={value} label={lab ? lab.label : null} />;
        })}
      </div>
    );
  };

  const departmentOptions = useMemo(() => {
    return props.departments.map(d => ({ id: d.id, label: d.name }));
  }, [props.departments]);

  return (
    <Container>
      <h3>{messages.ADD_MEMBER}</h3>
      <TextField
        required={true}
        error={props.errors.firstName}
        id="first-name-add-input"
        label={messages.LABELS.FIRST_NAME}
        helperText={props.errors.firstName ? messages.NAME_ERROR : ''}
        value={props.values.firstName}
        onChange={props.onChange('firstName')}
        margin="normal"
        fullWidth={true}
        variant="outlined"
      />
      <TextField
        required={true}
        id="last-name-add-input"
        error={props.errors.lastName}
        label={messages.LABELS.LAST_NAME}
        value={props.values.lastName}
        helperText={props.errors.lastName ? messages.NAME_ERROR : ''}
        onChange={props.onChange('lastName')}
        margin="normal"
        fullWidth={true}
        variant="outlined"
      />
      <TextField
        id="start-add-input"
        label={messages.LABELS.START_DATE}
        type="date"
        variant="outlined"
        margin="normal"
        fullWidth={true}
        value={props.values.startDate}
        onChange={props.onChange('startDate')}
        InputLabelProps={{
          shrink: true
        }}
      />
      <TextField
        error={props.errors.email}
        required={true}
        id="email-add-input"
        label={messages.LABELS.EMAIL}
        value={props.values.email}
        helperText={props.errors.email ? messages.EMAIL_ERROR : ''}
        onChange={props.onChange('email')}
        margin="normal"
        fullWidth={true}
        variant="outlined"
      />
      <FormControl
        error={props.errors.notificationPreference}
        variant="outlined"
        fullWidth={true}
        margin="normal"
      >
        <InputLabel htmlFor="notification-pref-add">
          {messages.LABELS.NOTIFICATION_PREF}
        </InputLabel>
        <Select
          multiple={true}
          value={props.values.notificationPreference}
          onChange={props.onChange('notificationPreference')}
          input={<FilledInput id="notification-pref-add-chip" />}
          renderValue={notificationSelectRenderer}
        >
          {constants.NOTIFICATION_PREF.map(current => (
            <MenuItem key={current.key} value={current.key}>
              {current.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        error={props.errors.title}
        required={true}
        id="title-add-input"
        label="Title"
        fullWidth={true}
        value={props.values.title}
        helperText={props.errors.title ? messages.TITLE_ERROR : ''}
        onChange={props.onChange('title')}
        margin="normal"
        variant="outlined"
      />
      <FormControl
        error={props.errors.roles}
        required={false}
        variant="outlined"
        fullWidth={true}
        margin="normal"
      >
        <InputLabel htmlFor="roles-add">{messages.LABELS.ROLES}</InputLabel>
        <Select
          multiple={true}
          value={props.values.roles}
          onChange={props.onChange('roles')}
          input={<FilledInput id="roles-add-chip" />}
          renderValue={roleSelectRenderer}
        >
          {Object.keys(props.roles)
            .filter(
              (current: any) =>
                props.roles[current].name !== constants.USER_ROLE
            )
            .map((current: any) => (
              <MenuItem
                key={`roles-l-${props.roles[current].id}`}
                value={props.roles[current].id}
              >
                {props.roles[current].name}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>
          {props.errors.roles ? messages.REQUIRED : ''}
        </FormHelperText>
      </FormControl>
      <TextField
        error={props.errors.manager}
        required={true}
        id="manager-add-select"
        select={true}
        label={messages.LABELS.MANAGER}
        value={props.values.manager}
        onChange={props.onChange('manager')}
        fullWidth={true}
        margin="normal"
        variant="outlined"
        helperText={props.errors.manager ? messages.REQUIRED : ''}
      >
        {Object.keys(props.managers).map((current: any) => (
          <MenuItem
            key={`managers-l-${props.managers[current].id}`}
            value={props.managers[current].id}
          >
            {props.managers[current].fullName}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        error={props.errors.employmentType}
        required={true}
        id="employment-add-select"
        select={true}
        label={messages.LABELS.EMPLOYMENT_TYPE}
        value={props.values.employmentType}
        onChange={props.onChange('employmentType')}
        fullWidth={true}
        margin="normal"
        variant="outlined"
      >
        {[
          UserClassification.SALARIED,
          UserClassification.HOURLY,
          UserClassification.CONTRACTOR
        ].map(current => (
          <MenuItem key={current} value={current}>
            {current}
          </MenuItem>
        ))}
      </TextField>
      <MSelect
        fullWidth
        label="Departments"
        multiple
        onChange={props.onChange('departmentIds')}
        options={departmentOptions}
        value={props.values.departmentIds}
        margin="normal"
      />
      {props.loading ? (
        <CircularProgress />
      ) : (
        <React.Fragment>
          <CustomButton
            fullWidth={true}
            color="primary"
            onClick={props.onSave}
            variant="contained"
          >
            {messages.SAVE}
          </CustomButton>
          <CustomButton
            fullWidth={true}
            variant="contained"
            onClick={props.onCancel}
          >
            {messages.CANCEL}
          </CustomButton>
        </React.Fragment>
      )}
    </Container>
  );
};

export default UserAdd;
