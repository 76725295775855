import * as React from 'react';
import GoogleLogin from 'react-google-login';
import messages from '../../messages';
import Typography, { variants } from '../Typography';
import { Container, VersionNumber } from './styles';

interface ILogin {
  version?: string;
  impersonate: boolean;
  onSuccess?: (event: any) => void;
  onError?: (event: any) => void;
}

const Login: React.FC<ILogin> = ({
  version,
  impersonate = false,
  onSuccess = (event: any) => console,
  onError = (event: any) => console
}) => (
  <Container>
    <Typography variant={variants.LOGO} center={true}>
      {messages.LOGO_TEXT}
    </Typography>
    <GoogleLogin
      clientId={process.env.REACT_APP_CLIENT_ID || ''}
      buttonText={messages.SIGN_IN_WITH_GOOGLE}
      onSuccess={onSuccess}
      onFailure={onError}
      responseType="code"
    />
    <VersionNumber>{`v-${version}`}</VersionNumber>
  </Container>
);

export default Login;
