import React, { useState } from 'react';
import _ from 'lodash';

import {
  FlexBoxJustifyStart,
  FlexBoxMarginBottom16
} from 'src/components/shared/RightSidebar/RightSidebarInfoPane/styles';

import InvoiceNote from './InvoiceNote';

import { INote, ENoteType } from 'src/shared/invoice-list/types';
import InputField, { InputType } from 'src/components/shared/inputs/InputField';
import { TealSpan } from 'src/components/InvoiceDetail/TimesheetRowTable/styles';

import {
  TealAddIcon,
  FlexBoxJustifyStartWithPointer,
  StyledComment,
  SpecialInstructionsHeader,
  PaddedInstructionsBox,
  ColumnFlexBox,
  MarginBottom16,
  StyledPrimaryButton,
  StyledCancelButton,
  MarginLeftNeg20,
  SectionPrimaryHeader,
  IndentedColumnFlexBox
} from './styles';
import { ICurrentUser } from 'src/shared/auth/types';
import { CharacterWarningLimit } from 'src/components/InvoiceDetail/LineItemModal/styles';
import { SHORT_TEXT_CHAR_LIMIT } from 'src/constants';

export const InvoiceNotes = ({
  notes,
  createNewInstruction,
  deleteInvoiceNote,
  updateInvoiceNote,
  canEdit,
  currentUser
}: {
  notes: INote[];
  createNewInstruction: ({ text }: { text: string }) => void;
  deleteInvoiceNote: ({ id }: { id: number }) => void;
  updateInvoiceNote: ({ text, id }: { text: string; id: number }) => void;
  canEdit: boolean;
  currentUser: ICurrentUser;
}) => {
  const [addInputOpen, toggleAddInput] = useState(false);
  const [newInstruction, changeNewInstruction] = useState('');

  const addInstruction = () => {
    toggleAddInput(false);
    changeNewInstruction('');
    createNewInstruction({ text: newInstruction });
  };

  const [invoiceNotes, otherNotes] = _.partition(notes, 'userName');

  return (
    <PaddedInstructionsBox>
      <SpecialInstructionsHeader>
        <StyledComment />
        Special Instructions
      </SpecialInstructionsHeader>
      <IndentedColumnFlexBox>
        <SectionPrimaryHeader>Invoice Instructions</SectionPrimaryHeader>

        {_(invoiceNotes)
          .filter(
            (note: INote) =>
              note.typeOf === ENoteType.INVOICE_SPECIAL_INSTRUCTION
          )
          .map((note: INote, i: number) => (
            <InvoiceNote
              key={i}
              note={note}
              deleteInvoiceNote={deleteInvoiceNote}
              updateInvoiceNote={updateInvoiceNote}
              currentUser={currentUser}
            />
          ))
          .value()}
        {addInputOpen ? (
          <ColumnFlexBox>
            <SectionPrimaryHeader>Add an instruction</SectionPrimaryHeader>
            <MarginBottom16>
              <MarginLeftNeg20>
                <InputField
                  autoFocus
                  validation={'instruction'}
                  type={InputType.LONG_STRING}
                  value={newInstruction}
                  modifyNewItemValue={(value: string) =>
                    changeNewInstruction(value.slice(0, SHORT_TEXT_CHAR_LIMIT))
                  }
                  immediateChange
                  charLimit={SHORT_TEXT_CHAR_LIMIT}
                />
              </MarginLeftNeg20>
              <CharacterWarningLimit>
                {SHORT_TEXT_CHAR_LIMIT - newInstruction.length} characters
                remaining
              </CharacterWarningLimit>
            </MarginBottom16>
            <FlexBoxJustifyStart>
              <StyledPrimaryButton onClick={addInstruction}>
                Save
              </StyledPrimaryButton>
              <StyledCancelButton onClick={() => toggleAddInput(false)}>
                Cancel
              </StyledCancelButton>
            </FlexBoxJustifyStart>
          </ColumnFlexBox>
        ) : (
          canEdit && (
            <FlexBoxMarginBottom16 onClick={() => toggleAddInput(true)}>
              <FlexBoxJustifyStartWithPointer>
                <TealAddIcon />
                <TealSpan>Instruction</TealSpan>
              </FlexBoxJustifyStartWithPointer>
            </FlexBoxMarginBottom16>
          )
        )}
        {otherNotes.length ? (
          <React.Fragment>
            <SectionPrimaryHeader>Other Instructions</SectionPrimaryHeader>

            {_.map(otherNotes, (note: INote, i: number) => (
              <InvoiceNote
                key={i}
                note={note}
                deleteInvoiceNote={deleteInvoiceNote}
                updateInvoiceNote={updateInvoiceNote}
                immutable
                currentUser={currentUser}
              />
            ))}
          </React.Fragment>
        ) : null}
      </IndentedColumnFlexBox>
    </PaddedInstructionsBox>
  );
};
