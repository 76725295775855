import * as _ from 'lodash';
import {
  createSelector,
  createSelectorCreator,
  defaultMemoize
} from 'reselect';

import { IAppState } from 'src/reducer';

export const currentUserSelector = (state: IAppState) => state.auth.currentUser;

// create a "selector creator" that uses lodash.isEqual instead of ===
const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  _.isEqual
);

export const currentUserRolesSelector = createDeepEqualSelector(
  currentUserSelector,
  user => {
    if (!user) {
      return [];
    }
    return _.map(user.roles, role => role.name);
  }
);

export const canAccessManagementSelector = createSelector(
  currentUserRolesSelector,
  roles =>
    _.some(roles, role =>
      _.includes(['ADMIN', 'FINANCE', 'MANAGER', 'PM'], role)
    )
);

export const isAdminSelector = createSelector(currentUserRolesSelector, roles =>
  _.includes(roles, 'ADMIN')
);

export const isFinanceSelector = createSelector(
  currentUserRolesSelector,
  roles => _.includes(roles, 'FINANCE')
);

export const isManagerSelector = createSelector(
  currentUserRolesSelector,
  roles => _.includes(roles, 'MANAGER')
);

export const isPMSelector = createSelector(currentUserRolesSelector, roles =>
  _.includes(roles, 'PM')
);

export const isPMOnlySelector = createSelector(
  currentUserRolesSelector,
  isPMSelector,
  (roles, isPM) =>
    isPM && !_.some(roles, role => _.includes(['ADMIN', 'FINANCE'], role))
);
