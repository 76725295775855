import * as React from 'react';
import * as Icons from '@mui/icons-material';
import { IconButton, IconButtonProps, SvgIconProps } from '@mui/material';
import { IconType } from 'src/shared/types';

export interface IIconProps
  extends Pick<SvgIconProps, 'color' | 'fontSize' | 'className' | 'sx'>,
    Pick<IconButtonProps, 'onClick'> {
  name: IconType;
  ref?:
    | ((instance: SVGSVGElement | null) => void)
    | React.RefObject<SVGSVGElement>
    | null
    | undefined;
}

export const Icon = React.forwardRef<SVGSVGElement, IIconProps>(
  ({ onClick, sx, ...props }, ref) => {
    const IconComponent = Icons[props.name];
    if (!IconComponent) return null;

    // render within button
    if (onClick) {
      return (
        <IconButton onClick={onClick} sx={sx}>
          <IconComponent ref={ref} {...props} />
        </IconButton>
      );
    }

    return <IconComponent ref={ref} sx={sx} {...props} />;
  }
);

export default Icon;
