import * as React from 'react';
import Button, { IButtonProps } from '.';

export const TextError = ({
  size = 'medium',
  width = 'auto',
  children,
  ...props
}: Omit<IButtonProps, 'color' | 'variant'>) => {
  return (
    <Button {...props} color="error" variant="text">
      {children}
    </Button>
  );
};

export default TextError;
