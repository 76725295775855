import React from 'react';
import { InformationPane } from '../InvoiceListSidebar/InformationPane';
import { IInvoiceSidebarDetail } from 'src/shared/invoice-list/types';
import { ICurrentUser } from 'src/shared/auth/types';
import { ClientIcon } from 'src/components/ProjectsList/styles';
import {
  CloseButton,
  InvoiceDetailPopover,
  PopoverTitle,
  StyledDialogContent,
  InvoiceTitle
} from './styles';
import { Invoice } from 'src/api/types';

interface IInvoicePopup {
  invoicePopupOpen: boolean;
  invoiceSidebarDetail: IInvoiceSidebarDetail;
  invoice: Invoice;
  fetchMoreInvoices: () => void;
  onClose: () => void;
  createNewInstruction: ({
    text,
    typeOf
  }: {
    text: string;
    typeOf: number;
  }) => void;
  deleteInvoiceNote: ({ id }: { id: number }) => void;
  updateInvoiceNote: ({ text, id }: { text: string; id: number }) => void;
  openInvoiceDetail: (id?: number) => void;

  canEdit: boolean;
  currentUser: ICurrentUser;
}

export const InvoicePopup = ({
  invoicePopupOpen,
  invoiceSidebarDetail,
  invoice,
  fetchMoreInvoices,
  onClose,
  createNewInstruction,
  deleteInvoiceNote,
  updateInvoiceNote,
  openInvoiceDetail,
  canEdit,
  currentUser
}: IInvoicePopup) => {
  return (
    <InvoiceDetailPopover
      open={invoicePopupOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <PopoverTitle>
        <InvoiceTitle>
          <ClientIcon color={invoice.clientColor} /> &nbsp; {invoice.clientCode}{' '}
          - {invoice.projectNickname}
        </InvoiceTitle>
        <CloseButton onClick={onClose} />
      </PopoverTitle>
      <StyledDialogContent>
        <InformationPane
          currentUser={currentUser}
          canEdit={canEdit}
          invoiceSidebarDetail={invoiceSidebarDetail}
          invoice={invoice}
          fetchMoreInvoices={fetchMoreInvoices}
          createNewInstruction={createNewInstruction}
          deleteInvoiceNote={deleteInvoiceNote}
          updateInvoiceNote={updateInvoiceNote}
          openInvoiceDetail={openInvoiceDetail}
        />
      </StyledDialogContent>
    </InvoiceDetailPopover>
  );
};

export default InvoicePopup;
