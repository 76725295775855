import * as React from 'react';
import TimesheetAppBar from 'src/components/TimesheetAppBar';
import { connect } from 'react-redux';
import { IAppState } from 'src/reducer';
import { UserClassification } from 'src/shared/users/types';
import _ from 'lodash';
import moment from 'moment';
import { ICurrentUser } from 'src/shared/auth/types';
import { FunctionComponent } from 'react';
import { Api } from 'src/api/api';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import { QueryKeys } from 'src/queries/queryKeys';
import { Holiday, TimesheetEntry, TimesheetStatusEnum } from 'src/api/types';
import { useNavigate } from 'react-router-dom';

export const TimesheetAppBarContainer: FunctionComponent<ITimesheetAppBarStateProps> = props => {
  const navigate = useNavigate();
  const { id: timesheetId } = useParams();

  const { data: timesheet } = useQuery(
    [QueryKeys.timesheet, timesheetId],
    () => Api.fetchTimesheet(Number(timesheetId)),
    { enabled: !!timesheetId }
  );

  const { mutate: submitTimesheet } = useMutation(
    () => Api.submitTimesheet(Number(timesheetId)),
    {
      onSuccess: async () => {
        const nextCurrentTimesheetId = await Api.fetchCurrentTimesheetId();
        navigate(`/timesheet/${nextCurrentTimesheetId}`);
      }
    }
  );

  const { user } = props;
  if (!timesheet) return null;
  if (
    !_.eq(timesheet.status, TimesheetStatusEnum.NEW) &&
    !_.eq(timesheet.status, TimesheetStatusEnum.REJECTED)
  ) {
    return null;
  }
  let readyToSubmit = false;

  if (timesheet && user) {
    if (_.eq(user?.classification, UserClassification.SALARIED)) {
      readyToSubmit = _.every(
        timesheet.timesheetDays,
        ({
          entries,
          holiday,
          date
        }: {
          entries: TimesheetEntry[];
          holiday: Holiday | null;
          date: string;
        }) => {
          const m = moment(date, 'YYYY-MM-DD');
          const dayOfTheWeek = m.isoWeekday();
          if (_.eq(dayOfTheWeek, 6) || _.eq(dayOfTheWeek, 7)) {
            return true;
          } else {
            return (entries && entries.length) || holiday;
          }
        }
      );
    } else {
      readyToSubmit = true;
    }
  }
  return (
    <TimesheetAppBar
      submitTimesheet={submitTimesheet}
      readyToSubmit={readyToSubmit}
    />
  );
};

export const mapStateToProps = (state: IAppState) => {
  const user = state.auth.currentUser;
  return {
    user
  };
};

interface ITimesheetAppBarStateProps {
  user?: ICurrentUser;
}

export default connect<ITimesheetAppBarStateProps>(mapStateToProps)(
  TimesheetAppBarContainer
);
