import { Box as MUIBox } from '@mui/material';
import styled, { theme } from 'src/theme';

export const StyledBox = styled(MUIBox)`
  color: #757575;
  width: 352px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  border: 1px dashed #d4d4d4;
  border-radius: 5px;
  font-family: ${theme.font};
  margin-top: 8px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
`;
