import { callAPI } from 'src/services/api';
import { IUserPayload, IUserResponse } from 'src/shared/users/types';
import { toast } from 'src/services/toast';

export const patchUser = async (
  user: Partial<IUserPayload> & { id: number }
): Promise<IUserResponse> =>
  callAPI<IUserResponse>({
    data: { user },
    method: 'patch',
    url: `/users/${user.id}`
  })
    .then(response => {
      toast('User updated', 'success');
      return response.data;
    })
    .catch(error => {
      toast('There was an error updating the user', 'error');
      throw error;
    });
