import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import {
  entryToUserProjectRoleView,
  titlelize,
  userProjectRolesToViews,
  userProjectRoleViewRef,
  isWeekend,
  UserProjectRoleView
} from 'src/shared/utils';
import {
  Entry,
  Entries,
  Project,
  IconBox,
  ClassificationBox,
  ClassificationTitle,
  ClassificationHours,
  HoursDivision,
  BillableBox,
  EntryHrsTitle,
  EntrySumHours,
  TotalHrsBox,
  CustomSelect,
  HoursInputBox,
  NonWorkingDayBox,
  HolidayBox,
  DescriptionBox
} from './styles';
import {
  FlexBox,
  DayBox,
  DayBoxHeader,
  Bold14,
  FlexBoxJustifyStart,
  AlignLeftColumnFlexBox,
  Subtitle12,
  ClientIcon,
  ClientIconShiftedLeft,
  DateSpan,
  FlexBoxJustifyEnd
} from 'src/components/InvoiceDetail/InvoiceSidebar/InvoiceDetailTabPane/TimesheetEntryPane/styles';
import MoreVert from '@mui/icons-material/MoreVert';
import Delete from '@mui/icons-material/Delete';
import { MenuItem } from '@mui/material';
import InputField, { InputType } from 'src/components/shared/inputs/InputField';
import typeGuard from 'src/shared/typeGuard';
import { Color, GRIO_ORG_ID } from 'src/constants';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import {
  Deliverable,
  Holiday,
  TimesheetEntry,
  TimesheetEntryClassification,
  UserProjectRole
} from 'src/api/types';
import { UpdateTimesheetEntryParams } from 'src/api/queries/updateTimesheetEntry';

export const UserTimesheetEntryPane = ({
  date,
  entries,
  userProjectRoles,
  updateTimesheetEntry,
  canEdit,
  canChangeDeliverable,
  deleteTimesheetEntry,
  holiday,
  onSelectDay,
  selected,
  showEntriesDefault
}: {
  date: string;
  entries: TimesheetEntry[];
  userProjectRoles: UserProjectRole[];
  updateTimesheetEntry: (id: number, data: UpdateTimesheetEntryParams) => void;
  canEdit?: boolean;
  canChangeDeliverable?: boolean;
  deleteTimesheetEntry?: (id: number) => void;
  holiday?: Holiday;
  onSelectDay?: (date: Moment, multiSelect: boolean) => void;
  selected?: boolean;
  showEntriesDefault: boolean;
}) => {
  const [edit, setEdit] = useState('');
  const [hovered, setHovered] = useState(-1);
  const [showEntries, setShowEntries] = useState(showEntriesDefault);
  let totalHours = 0;
  let billableHours = 0;

  const userProjectRoleViews: UserProjectRoleView[] = userProjectRolesToViews(
    userProjectRoles
  );

  const userProjectRoleViewIndex = _.keyBy(userProjectRoleViews, 'ref');

  entries.forEach(entry => {
    const { deliverable, role: projectRole } = entry;

    const viewRef = userProjectRoleViewRef(deliverable, projectRole);
    const view = userProjectRoleViewIndex[viewRef];
    if (!view) {
      const userProjectRoleView = entryToUserProjectRoleView(entry);
      userProjectRoleViews.push(userProjectRoleView);
    }
  });

  const viewIndex = _.groupBy(userProjectRoleViews, item => {
    return item.client.id === GRIO_ORG_ID ? 'grio' : 'other';
  });

  const grioUserProjectRoleViews = _.filter(viewIndex['grio'], view => {
    return (
      view.project.name !== 'Sick Time' &&
      view.project.name !== 'PTO' &&
      view.project.name !== 'Unpaid'
    );
  });
  const otherUserProjectRoleViews = viewIndex['other'];

  if (entries) {
    entries.forEach((item: TimesheetEntry) => {
      if (item.classification === TimesheetEntryClassification.BILLABLE) {
        billableHours += item.hours;
      }
      totalHours += item.hours;
    });
  }

  const dayBoxTitle = moment(date).format('ddd, MMM D');

  const fixedNumbers = (val: number | string) => {
    if (typeGuard.isNumber(val)) {
      return val.toFixed(2);
    }
    return parseFloat(val).toFixed(2);
  };

  const onChange = (value: string | number, key: string, entryId: number) => {
    const newValue: undefined | number | Deliverable | string = value;
    const entry = _.find(entries, entry => entry.id === entryId);
    if (entry && newValue) {
      if (!_.eq(entry[key], newValue)) {
        entry[key] = newValue;
        updateTimesheetEntry(entry.id, { [key]: newValue });
      }
    }
    setEdit('');
  };

  const onChangeDeliverableRole = (value: string, entry: TimesheetEntry) => {
    const { deliverableId, projectRoleId } = JSON.parse(value);
    const changed =
      deliverableId !== entry.deliverable.id || projectRoleId !== entry.role.id;
    if (changed) {
      const userProjectRole = _.find(userProjectRoleViews, item => {
        return (
          item.deliverable.id === deliverableId &&
          item.projectRole.id === projectRoleId
        );
      });
      if (userProjectRole) {
        entry.client = userProjectRole.client;
        entry.deliverable = userProjectRole.deliverable;
        entry.role = userProjectRole.projectRole;
        updateTimesheetEntry(entry.id, {
          deliverable: deliverableId,
          projectRole: projectRoleId
        });
      }
    }
    setEdit('');
  };

  const doSelectDay = (arg: Moment, multiSelect: boolean) =>
    onSelectDay && onSelectDay(arg, multiSelect);

  const toggleShowEntries = () => {
    setShowEntries(!showEntries);
  };

  return (
    <DayBox>
      <DayBoxHeader
        className={`${onSelectDay ? 'hoverable' : ''} 
        ${isWeekend(moment(date)) ? 'weekend' : ''} 
        ${selected ? 'selected' : ''}`}
        onClick={e => {
          const multiSelect = e.metaKey || e.ctrlKey;
          doSelectDay(moment(date), multiSelect);
          toggleShowEntries();
        }}
      >
        <FlexBox>
          <DateSpan>{dayBoxTitle}</DateSpan>
          <MoreVert key="menu-anchor" />
          {entries ? (
            <FlexBox>
              {_(entries)
                .map((item, index) => (
                  <ClientIcon color={item.client.color} key={index} />
                ))
                .value()}
            </FlexBox>
          ) : null}
        </FlexBox>
        <FlexBox>
          <TotalHrsBox>
            <EntryHrsTitle>Total Hr</EntryHrsTitle>
            <EntrySumHours>{fixedNumbers(totalHours)}</EntrySumHours>
          </TotalHrsBox>
          <HoursDivision></HoursDivision>
          <BillableBox>
            <ClassificationTitle>Billable Hr</ClassificationTitle>
            <ClassificationHours>
              {fixedNumbers(billableHours)}
            </ClassificationHours>
          </BillableBox>
        </FlexBox>
      </DayBoxHeader>
      {holiday ? (
        <Entries>
          <Entry>
            <HolidayBox>
              <FlexBoxJustifyStart>
                <ClientIcon color={Color.YELLOW_GREEN} />
                <Bold14>{holiday?.name}</Bold14>
              </FlexBoxJustifyStart>
            </HolidayBox>
          </Entry>
        </Entries>
      ) : null}
      <Entries>
        {entries.map((item: TimesheetEntry, index: number) => {
          const isHoursWorkedEntry =
            [
              TimesheetEntryClassification.NON_BILLABLE,
              TimesheetEntryClassification.BILLABLE
            ].indexOf(item.classification) > -1;
          const deleteIcon = (
            <Delete
              onClick={() =>
                deleteTimesheetEntry
                  ? deleteTimesheetEntry(item.id)
                  : Function.prototype
              }
            />
          );
          const value = userProjectRoleViewRef(item.deliverable, item.role);
          const views =
            item.client.id === GRIO_ORG_ID
              ? grioUserProjectRoleViews
              : otherUserProjectRoleViews;
          return (
            <Entry
              key={index}
              className={`${_.eq(index, hovered) ? '' : 'inactive'} ${
                showEntries ? '' : 'hidden'
              }`}
              onMouseOver={() =>
                canEdit ? setHovered(index) : Function.prototype
              }
              onMouseOut={() => (canEdit ? setHovered(-1) : Function.prototype)}
            >
              {isHoursWorkedEntry ? (
                <FlexBox>
                  {userProjectRoleViews.length ? (
                    <CustomSelect
                      disabled={!(canEdit || canChangeDeliverable)}
                      value={value}
                      onChange={(e: SelectChangeEvent) =>
                        onChangeDeliverableRole(e.target.value, item)
                      }
                      disableUnderline
                      variant="standard"
                    >
                      {_(views)
                        .map((view: UserProjectRoleView) => (
                          <MenuItem key={view.ref} value={view.ref}>
                            <Project>
                              <IconBox></IconBox>
                              <AlignLeftColumnFlexBox>
                                <FlexBoxJustifyStart>
                                  <ClientIconShiftedLeft
                                    color={view.client.color}
                                  />
                                  <Bold14>
                                    {view.client.code} - {view.project.nickname}
                                  </Bold14>
                                </FlexBoxJustifyStart>
                                <Subtitle12>
                                  {view.deliverable.name} -{' '}
                                  {view.projectRole.name}
                                </Subtitle12>
                              </AlignLeftColumnFlexBox>
                            </Project>
                          </MenuItem>
                        ))
                        .value()}
                    </CustomSelect>
                  ) : (
                    <FlexBox />
                  )}
                  <DescriptionBox
                    onClick={() =>
                      canEdit
                        ? setEdit(`description - ${item.id}`)
                        : Function.prototype
                    }
                  >
                    <InputField
                      fullHeight
                      disabled={!canEdit}
                      className={!canEdit ? 'moment-disabled' : ''}
                      type={InputType.LONG_STRING}
                      validation="description"
                      value={item.description}
                      modifyNewItemValue={(value: string) =>
                        onChange(value, 'description', item.id)
                      }
                    />

                    {canEdit && isHoursWorkedEntry && deleteIcon}
                  </DescriptionBox>
                  <ClassificationBox
                    onClick={() =>
                      canEdit
                        ? setEdit(`hours - ${item.id}`)
                        : Function.prototype
                    }
                  >
                    <ClassificationTitle>
                      {item.classification ===
                      TimesheetEntryClassification.BILLABLE
                        ? 'Billable Hr'
                        : 'Non-Billable Hr'}
                    </ClassificationTitle>
                    {_.eq(edit, `hours - ${item.id}`) ? (
                      <HoursInputBox>
                        <InputField
                          type={InputType.NUMBER}
                          validation="hours"
                          value={item.hours}
                          modifyNewItemValue={(value: string) =>
                            onChange(Number(value), 'hours', item.id)
                          }
                        />
                      </HoursInputBox>
                    ) : (
                      <ClassificationHours>
                        {fixedNumbers(item.hours)}
                      </ClassificationHours>
                    )}
                  </ClassificationBox>
                </FlexBox>
              ) : (
                <Entry>
                  <NonWorkingDayBox>
                    <FlexBoxJustifyStart>
                      <ClientIcon color={Color.YELLOW_GREEN} />
                      <Bold14>{titlelize(item.classification)}</Bold14>
                    </FlexBoxJustifyStart>
                    <ClassificationBox>
                      <ClassificationTitle>
                        {item.classification ===
                        TimesheetEntryClassification.BILLABLE
                          ? 'Billable Hr'
                          : 'Non-Billable Hr'}
                      </ClassificationTitle>
                      {fixedNumbers(item.hours)}
                    </ClassificationBox>
                  </NonWorkingDayBox>
                  <FlexBoxJustifyEnd>{deleteIcon}</FlexBoxJustifyEnd>
                </Entry>
              )}
            </Entry>
          );
        })}
      </Entries>
    </DayBox>
  );
};

export default UserTimesheetEntryPane;
