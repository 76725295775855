import * as React from 'react';
import { Button as MButton, ButtonProps } from '@mui/material';
import { styled } from '@mui/system';
import { OutlinedUnstyled } from './OutlinedUnstyled';
import { TextError } from './TextError';
import { IconType } from 'src/shared/types';
import Icon from '../Icon';

export interface IButtonProps
  extends Pick<
    ButtonProps,
    | 'id'
    | 'color'
    | 'children'
    | 'variant'
    | 'size'
    | 'sx'
    | 'disabled'
    | 'onClick'
  > {
  width?: 'full' | 'auto';
  /**
   * Select an icon from the material UI icon library. A full list of available icons is available for reference at Icon/Library.
   */
  icon?: IconType;
  iconPosition?: 'left' | 'right';
}

const MButtonStyled = styled(MButton)`
  box-shadow: none;

  &:hover,
  &:focus {
    box-shadow: none;
  }
`;

export const Button = ({
  size = 'medium',
  color = 'primary',
  variant = 'contained',
  width = 'auto',
  iconPosition = 'left',
  sx,
  children,
  ...props
}: IButtonProps) => {
  let icon = null;

  const isIconLeft = iconPosition === 'left';

  if (props?.icon) {
    const iconMargin = '0.25em';

    icon = (
      <Icon
        name={props.icon}
        sx={
          isIconLeft ? { marginRight: iconMargin } : { marginLeft: iconMargin }
        }
      />
    );
  }

  return (
    <MButtonStyled
      {...props}
      color={color}
      variant={variant}
      fullWidth={width === 'full'}
      size={size}
      sx={{
        ...sx,
        whiteSpace: 'nowrap',
        display: 'inline-flex',
        flexDirection: isIconLeft ? 'row' : 'row-reverse'
      }}
    >
      <>
        {props?.icon && icon}
        {children}
      </>
    </MButtonStyled>
  );
};

// Export Button variants
Button.OutlinedUnstyled = OutlinedUnstyled;
Button.TextError = TextError;

export default Button;
