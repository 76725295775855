import { Table, TableProps } from 'react-virtualized';
import styled, { theme } from '../../theme';

export const BottomBorder = styled.div`
  width: 100%;
  margin: 10px 0 10px 0;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Separator = styled.div`
  display: flex;
  flex: 1;
  width: calc(100% - 20px);
  border-bottom: 2px solid ${theme.primaryBackground};
  margin-top: 30px;
  align-items: center;
  & h1 {
    color: ${props => props.color};
  }
`;

export const Title = styled.h3`
  margin: 10px 0 0 0;
  text-align: left;
  width: 90%;
`;

export const Sub = styled.p`
  margin: 10px 0 10px 0;
  text-align: left;
  width: 90%;
`;

export const Disabled = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: ${theme.secondaryColorInverted};
`;

export const Enabled = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: ${theme.green};
`;

export const Content = styled.div`
  padding: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  font-family: ${theme.font};
  margin-bottom: 60px;
`;

export const NoMarginContent = styled(Content)`
  margin-bottom: 10px;
  padding-bottom: 0;
`;

export const Toggler = styled(Content)`
  margin-bottom: 80px !important;
  padding-bottom: 0;
  flex-direction: row;
  justify-content: space-around;
`;
export const TogglerSimple = styled(Toggler)`
  margin-bottom: 0 !important;
  padding: 0 !important;
  flex-direction: row;
  justify-content: space-around;
`;

export const LineItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 10px;
`;

export const Label = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
`;

export const Value = styled.div`
  display: flex;
  flex: 2;
  justify-content: flex-end;
  padding-right: 20px;
  align-items: center;
`;

export const ListContainer = styled.div`
  margin: 0 10px 10px 10px;
  width: 90%;
  height: 300px;
`;

export const CustomTable: React.ComponentType<TableProps> = styled(Table)`
  & .row {
    box-sizing: border-box;
    background-color: ${theme.primaryBackground};
    display: -webkit-box;
    height: 50px !important;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: ${theme.font};
  }
  & .row:focus {
    outline: none;
  }
  & .row:hover {
    background-color: ${theme.separatorBackground};
    cursor: pointer;
  }
  & .ReactVirtualized__Table__headerRow:hover {
    background-color: ${theme.secondaryBackground};
    cursor: default;
  }
  & .ReactVirtualized__Table__headerRow {
    background-color: ${theme.secondaryBackground};
    color: ${theme.secondaryColorInverted};
  }
  & .column,
  .headerRow {
    margin-left: 10px;
    margin-right: 10px;
  }
` as any;
