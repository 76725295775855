import _ from 'lodash';
import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { parseResponseErrorMessage, typeGuard } from 'src/shared/utils';
import { IClient } from 'src/types/IClient';
import { transformClientResponse } from './utils';

const UNIQUENESS_ERROR_MESSAGE = 'This client code is already in use';
const GENERAL_ERROR_MESSAGE = 'There was an error updating the client';
const SUCCESS_MESSAGE = 'Client updated';

export const updateClient = (client: Partial<IClient> & { id: number }) =>
  callAPI({
    data: { client },
    method: 'put',
    url: `/clients/${client.id}`,
    transform: transformClientResponse
  })
    .then(({ data: { client } }) => {
      toast(SUCCESS_MESSAGE, 'success');
      return client as IClient;
    })
    .catch(error => {
      toast(makeErrorMessage(error), 'error');
      throw error;
    });

const makeErrorMessage = (error: any) => {
  const {
    data: { code, error: errorMessage }
  } = error.response;

  if (code[0] === 'has already been taken') {
    return UNIQUENESS_ERROR_MESSAGE;
  } else if (typeGuard.isResponseError(error)) {
    return parseResponseErrorMessage(error);
  } else if (errorMessage?.length < 50) {
    return errorMessage;
  } else {
    return GENERAL_ERROR_MESSAGE;
  }
};
