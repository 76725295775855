import React, { useState } from 'react';
import _ from 'lodash';
import InfiniteCalendar, {
  Calendar,
  withMultipleDates
} from 'react-infinite-calendar';
import moment, { Moment } from 'moment';
import { Modal, Checkbox, FormControlLabel } from '@mui/material';
import InputField, { InputType } from '../InputField';
import { PaperDiv, width, FlexBox, calendarTheme } from './styles';
import { ButtonNoBorder } from '../button-no-border';
import { isWeekday, createDaysArray } from 'src/shared/utils';

const MultipleDatesCalendar = withMultipleDates(Calendar);
interface IMomentDateSelect {
  dates: Moment[];
  setDates: (dates: Moment[]) => void;
  minDate: Moment;
  maxDate: Moment;
  disabledDates?: Moment[] | true[];
}

export const multiDateInterpolation = (date: Date, selected: Date[]) => {
  const newSelected = selected;
  const simpleDates = _.map(selected, (d: Date) => d.getDate());
  const index = simpleDates.indexOf(date.getDate());
  if (index > -1) {
    newSelected.splice(index, 1);
  } else {
    newSelected.push(date);
  }
  return newSelected;
};

export const MomentDateSelect = ({
  dates,
  setDates,
  minDate,
  maxDate,
  disabledDates
}: IMomentDateSelect) => {
  const [open, setOpen] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const onCheckboxChangeHandler = (checked: boolean) => {
    if (checked) {
      setDates(
        _(createDaysArray(minDate, maxDate))
          .filter(
            (d: Moment) =>
              isWeekday(d) &&
              !_.find(disabledDates, (dd: Moment) => d.isSame(dd, 'day'))
          )
          .value()
      );
    } else {
      setDates([]);
    }
    setAllChecked(checked);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <InputField
        type={InputType.INFINITE_CALENDAR}
        value={_(dates)
          .sort((m1: Moment, m2: Moment) => (m1.isAfter(m2) ? 1 : -1))
          .map((m: Moment, i: number) =>
            !i ? m.format('MMMM-D') : m.format('D')
          )
          .value()}
        modifyNewItemValue={() => Function.prototype}
        onClick={() => setOpen(!open)}
        validation="multi-date"
      />
      <Modal open={open} onClose={handleClose}>
        <PaperDiv>
          <InfiniteCalendar
            displayOptions={{
              showHeader: false,
              showTodayHelper: false
            }}
            width={width}
            height={336}
            Component={MultipleDatesCalendar}
            interpolateSelection={multiDateInterpolation}
            selected={_.map(dates, (m: Moment) => m.toDate())}
            onSelect={(selectedDate: Date) => {
              const newSelected = multiDateInterpolation(
                selectedDate,
                _.map(dates, (d: Moment) => d.toDate())
              );
              setDates(_.map(newSelected, (d: Date) => moment(d)));
            }}
            minDate={minDate.toDate()}
            maxDate={maxDate.toDate()}
            min={minDate.toDate()}
            max={maxDate.toDate()}
            disabledDates={_.map(disabledDates, (m: Moment) => m.toDate())}
            theme={calendarTheme}
          />
          <FlexBox>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allChecked}
                  onChange={(_e: React.ChangeEvent, checked: boolean) =>
                    onCheckboxChangeHandler(checked)
                  }
                />
              }
              label="All workdays"
            />

            <ButtonNoBorder
              className="neutral"
              onClick={() => {
                setAllChecked(false);
                setDates([]);
              }}
            >
              Clear
            </ButtonNoBorder>
          </FlexBox>
        </PaperDiv>
      </Modal>
    </>
  );
};
