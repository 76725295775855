import { Typography } from '@mui/material';
import * as React from 'react';
import messages from '../../messages';
import ProjectsStatusChange from 'src/components/ProjectsStatusChange';
import ProjectsDetailActions from 'src/components/ProjectsDetailActions';
import ProjectsDetail from '../../components/ProjectsDetail';
import ProjectsDetailContact from 'src/components/ProjectsDetailContact';
import ProjectsDetailDeliverables from 'src/components/ProjectsDetailDeliverables';
import ProjectsDetailRoles from 'src/components/ProjectsDetailRoles';
import { Project, ProjectRole } from 'src/api/types';
import { IInvoice } from 'src/shared/invoice-list/types';

export type ProjectsGeneralTab = {
  changeProjectStatus: () => any;
  project?: Project;
  showStatusDialog: boolean;
  toggleStatusDialog: () => void;
  onEditClick: () => any;
  offset: number;
  toggleOffset: (direction: string) => () => void;
  projectUserStats: any;
  invoices: IInvoice[];
  projectPath: string;
};

export type ProjectContactTab = {
  deleteInvoiceEmail: (id: any) => void;
  updateProjectData: (data: any, success: () => void) => void;
  createInvoiceEmail: (type: string, data: any, success: () => void) => void;
  loadingCreateInvoiceEmail: boolean;
  project?: Project;
  projectPath: string;
};

export type ProjectDeliverablesTab = {
  projectCreateOptions: any;
  loadingAddDeliverable: boolean;
  loadingEditDeliverable: boolean;
  createDeliverable: (data: any, callback: () => void) => void;
  editDeliverable: (data: any, callback: () => void) => void;
  deleteDeliverable: (id: number, callback: () => void) => void;
  project?: Project;
  projectPath: string;
};

export type ProjectRolesTab = {
  projectCreateOptions: any;
  loadingAddRole: boolean;
  loadingEditRole: boolean;
  createRole: (data: Partial<ProjectRole>, callback: () => void) => void;
  editRole: (data: Partial<ProjectRole>, callback: () => void) => void;
  project?: Project;
  projectPath: string;
};

export const noProjectSelectedTab = () => (
  <Typography style={{ marginTop: 10, textAlign: 'center' }} variant="h5">
    {messages.NO_PROJECT_SELECTED}
  </Typography>
);

export const generalTab: React.FC<ProjectsGeneralTab> = ({
  changeProjectStatus,
  project,
  showStatusDialog,
  toggleStatusDialog,
  onEditClick,
  offset,
  toggleOffset,
  projectUserStats,
  invoices,
  projectPath
}) => (
  <>
    <ProjectsStatusChange
      performAction={changeProjectStatus}
      deleteEnabled={project ? !!project.isActive : true}
      open={showStatusDialog}
      handleClose={toggleStatusDialog}
    />
    <ProjectsDetailActions
      onEditClick={onEditClick}
      projectStatus={project ? !!project.isActive : true}
      openDialog={toggleStatusDialog}
      disabled={!project}
    />
    <ProjectsDetail
      offset={offset}
      toggleOffset={toggleOffset}
      projectUserStats={projectUserStats}
      invoices={invoices}
      selectedProject={project}
      projectPath={projectPath}
    />
  </>
);

export const contactTab: React.FC<ProjectContactTab> = ({
  deleteInvoiceEmail,
  updateProjectData,
  createInvoiceEmail,
  loadingCreateInvoiceEmail,
  project,
  projectPath
}) => (
  <>
    <ProjectsDetailContact
      deleteInvoiceEmail={deleteInvoiceEmail}
      updateProjectData={updateProjectData}
      createInvoiceEmail={createInvoiceEmail}
      loadingCreateInvoiceEmail={loadingCreateInvoiceEmail}
      selectedProject={project}
      path={projectPath}
    />
  </>
);

export const deliverablesTab: React.FC<ProjectDeliverablesTab> = ({
  projectCreateOptions,
  loadingAddDeliverable,
  loadingEditDeliverable,
  createDeliverable,
  editDeliverable,
  deleteDeliverable,
  project,
  projectPath
}) => (
  <>
    <ProjectsDetailDeliverables
      deliverableTypes={projectCreateOptions.deliverableTypes}
      loadingAddDeliverable={loadingAddDeliverable}
      loadingEditDeliverable={loadingEditDeliverable}
      createDeliverable={createDeliverable}
      deleteDeliverable={deleteDeliverable}
      editDeliverable={editDeliverable}
      selectedProject={project}
      path={projectPath}
    />
  </>
);

export const rolesTab: React.FC<ProjectRolesTab> = ({
  projectCreateOptions,
  loadingAddRole,
  loadingEditRole,
  createRole,
  editRole,
  project,
  projectPath
}) => (
  <>
    <ProjectsDetailRoles
      accountingCategories={projectCreateOptions.accountingCategories}
      departments={projectCreateOptions.departments}
      loadingAddRole={loadingAddRole}
      loadingEditRole={loadingEditRole}
      createRole={createRole}
      editRole={editRole}
      selectedProject={project}
      path={projectPath}
    />
  </>
);
