import { AxiosResponse } from 'axios';
import { toast } from 'src/services/toast';
import { capitalize } from 'src/shared/utils';

export const transformClientsResponse = (response: AxiosResponse) => {
  return {
    ...response,
    data: response.data.map(transformClient)
  };
};

export const transformClientResponse = (response: AxiosResponse) => {
  const mappedData = {
    ...response.data,
    client: transformClient(response.data.client)
  };

  return {
    ...response,
    data: mappedData
  };
};

const transformClient = (client: any) => ({
  ...client,
  label: client.name
});

export const handleProjectRoleUpsertError = (error: any) => {
  error = error.data;
  if (error.estimatedEndDate)
    toast(capitalize(error.estimatedEndDate), 'error');
  if (error.accountingCategory)
    toast(capitalize(error.accountingCategory), 'error');
  if (error.notInternalDepartmentType)
    toast(capitalize(error.notInternalDepartmentType), 'error');
};
