import React, { FunctionComponent, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'src/hooks/withRouter';
import { IAppState } from 'src/reducer';
import UserTimesheet from 'src/components/UserTimesheet';
import { ICurrentUser } from 'src/shared/auth/types';
import { AppContext } from 'src/contexts/app-context';
import { useMutation, useQuery } from 'react-query';
import { Api } from 'src/api/api';
import { QueryKeys } from 'src/queries/queryKeys';
import { MAX_SIDEBAR_TIMESHEETS_PER_PAGE } from 'src/constants';
import { useNavigate, useParams } from 'react-router-dom';

interface IUserTimesheetContainerReduxState {
  user?: ICurrentUser;
  leftDrawerIsOpen?: boolean;
}

type IUserTimesheetContainer = RouteComponentProps<{ id?: string }> &
  IUserTimesheetContainerReduxState;

export const UserTimesheetContainer: FunctionComponent<IUserTimesheetContainer> = props => {
  const { id: timesheetId } = useParams();
  const navigate = useNavigate();

  const [sidebarTimesheetsPageSize, setSidebarTimesheetsPageSize] = useState(
    MAX_SIDEBAR_TIMESHEETS_PER_PAGE
  );

  useQuery([QueryKeys.currentTimesheetId], Api.fetchCurrentTimesheetId, {
    onSuccess: currentTimesheetId => {
      navigate(`/timesheet/${currentTimesheetId}`);
    },
    enabled: !timesheetId
  });

  const {
    data: timesheet,
    isLoading: isLoadingTimesheet,
    refetch: refetchTimesheet
  } = useQuery(
    [QueryKeys.timesheet, timesheetId],
    () => Api.fetchTimesheet(Number(timesheetId)),
    { enabled: !!timesheetId }
  );

  const {
    data: timesheetSidebarDetail,
    refetch: refetchTimesheetSidebarDetail
  } = useQuery(
    [
      QueryKeys.timesheetSidebarDetail,
      timesheet?.id,
      sidebarTimesheetsPageSize
    ],
    () => {
      return Api.fetchTimesheetSidebarDetail({
        timesheetId: timesheet?.id as number, // cast is safe because this query is only enabled if timesheet is present
        timesheetsPageSize: sidebarTimesheetsPageSize
      });
    },
    {
      keepPreviousData: true, // ensures contents of sidebar remain while refetching after sidebarTimesheetsPageSize has changed
      enabled: !!timesheet
    }
  );

  const { mutate: updateTimesheetEntry } = useMutation(
    Api.updateTimesheetEntry
  );

  const { mutate: createTimesheetEntry } = useMutation(
    Api.createTimesheetEntry,
    {
      onSuccess: () => {
        refetchTimesheet();
        refetchTimesheetSidebarDetail();
      }
    }
  );

  const { mutate: deleteTimesheetEntry } = useMutation(
    Api.deleteTimesheetEntry,
    {
      onSuccess: () => {
        refetchTimesheet();
        refetchTimesheetSidebarDetail();
      }
    }
  );

  const onRequestTimesheetById = (id: number) => {
    navigate(`/timesheet/${id}`);
    setSidebarTimesheetsPageSize(MAX_SIDEBAR_TIMESHEETS_PER_PAGE);
  };

  const increaseSidebarTimesheetsPageSize = () => {
    setSidebarTimesheetsPageSize(
      sidebarTimesheetsPageSize + MAX_SIDEBAR_TIMESHEETS_PER_PAGE
    );
  };

  const context = useContext(AppContext);

  const { data: userProjectRoles = [] } = useQuery(
    QueryKeys.userProjectRoles,
    Api.fetchUserProjectRoles
  );

  const { user } = props;

  if (!user || !timesheet) return null;

  return (
    <UserTimesheet
      addEntry={createTimesheetEntry}
      userProjectRoles={userProjectRoles}
      timesheet={timesheet}
      updateTimesheetEntry={(id, entry) => updateTimesheetEntry({ id, entry })}
      deleteTimesheetEntry={deleteTimesheetEntry}
      requestTimesheetById={onRequestTimesheetById}
      isLoading={isLoadingTimesheet}
      timesheetSidebarDetail={timesheetSidebarDetail}
      fetchMoreTimesheets={increaseSidebarTimesheetsPageSize}
      user={user}
      leftDrawerIsOpen={context.isLeftDrawerOpen}
    />
  );
};

const mapStateToProps = (
  state: IAppState
): IUserTimesheetContainerReduxState => ({
  user: state.auth.currentUser
});

export default withRouter(
  connect<IUserTimesheetContainerReduxState>(mapStateToProps)(
    UserTimesheetContainer
  )
);
