import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Box } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { IInvoiceDetailView } from 'src/shared/invoice/types';
import {
  ENoteType,
  IInvoiceSidebarDetail,
  INote,
  InvoiceStatus
} from 'src/shared/invoice-list/types';
import styled, { theme } from 'src/theme';
import { SectionInfo } from './styles';

const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
`;

const RejectionPill = styled(Box)`
  align-items: center;
  background-color: rgba(176, 0, 32, 0.1);
  border-radius: 16px;
  display: flex;
  font-family: ${theme.font};
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  padding: 4px 16px 4px 4px;
`;

const RejectionText = styled.div`
  color: rgba(33, 33, 33, 0.87);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
`;

const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  && {
    color: #e02020;
    margin-right: 8px;
    height: 20px;
    padding: 0;
    width: 20px;
  }
`;

interface IProps {
  invoiceSidebarDetail: IInvoiceSidebarDetail;
  view?: IInvoiceDetailView;
}

export default function InvoiceRejectionNotes({
  invoiceSidebarDetail,
  view
}: IProps) {
  let rejectionNote: INote = {
    text: view?.rejectionReason || '',
    userName: '',
    typeOf: ENoteType.INVOICE_REJECTION_REASON,
    invoice: view?.id || 0,
    createdAt: view?.creationDate || '',
    updatedAt: view?.creationDate || ''
  };

  if (view?.status === InvoiceStatus.REJECTED) {
    const { notes } = invoiceSidebarDetail;
    if (notes && notes.length) {
      const rejectionNotes = _(notes ? notes : [])
        .filter(
          (note: INote) => note.typeOf === ENoteType.INVOICE_REJECTION_REASON
        )
        .uniq()
        .compact()
        .sort((a: INote, b: INote) => (a.createdAt > b.createdAt ? -1 : 1))
        .value();

      const latest = _.head(rejectionNotes);
      if (latest) {
        rejectionNote = latest;
      }
    }
  }

  return (
    <React.Fragment>
      <FlexBox>
        <RejectionPill>
          <StyledErrorOutlineIcon />
          REJECTION NOTES
        </RejectionPill>
      </FlexBox>
      <SectionInfo>
        <li className="section-info">
          <span className="section-info-label">Rejected By</span>
          <span className="section-info-value">
            {rejectionNote.userName} on{' '}
            {moment(rejectionNote.createdAt).format('MMM DD')}
          </span>
        </li>
      </SectionInfo>
      <RejectionText>{rejectionNote.text}</RejectionText>
    </React.Fragment>
  );
}
