import { IconButton, Typography } from '@mui/material';
import SkipNext from '@mui/icons-material/SkipNext';
import SkipPrevious from '@mui/icons-material/SkipPrevious';
import moment from 'moment';
import * as React from 'react';
import { AutoSizer, Column } from 'react-virtualized';
import messages from '../../messages';
import ProjectDetailHeader from 'src/components/ProjectsDetailHeader';

import {
  Container,
  CustomTable,
  Label,
  LineItem,
  SubLineItem,
  ListContainer,
  NoMarginContent,
  Separator,
  Toggler,
  TogglerSimple,
  Value,
  CustomLink,
  InlineLabel
} from './styles';
import Currency from '../../components/Currency';
import { IInvoice } from '../../shared/invoice-list/types';
import { FunctionComponent, useState } from 'react';

interface IProjectsDetail {
  selectedProject: any;
  invoices: IInvoice[];
  projectUserStats: any;
  offset: number;
  projectPath: string;
  toggleOffset: (direction: string) => () => void;
}

export const ProjectsDetail: FunctionComponent<IProjectsDetail> = props => {
  const [currentStatToShow, setCurrentStatToShow] = useState(0);

  const getStatTitle = () => {
    const { projectUserStats } = props;
    if (projectUserStats.periods.length > 0) {
      const current = projectUserStats.periods[currentStatToShow];
      const start = moment(current.start).format('MM/DD/YYYY');
      const end = moment(current.end).format('MM/DD/YYYY');
      return `${start} - ${end}`;
    }

    return '-';
  };

  const rowGetter = (event: any) => {
    return props.invoices[event.index];
  };

  const userStatsRowGetter = (event: any) => {
    return props.projectUserStats.users[event.index];
  };

  const dateGetter = (event: any) => {
    return moment(event.rowData.creationDate).format('MM/DD/YYYY');
  };

  const amountGetter = (event: any) => {
    const value = Math.round(event.rowData.total);
    if (isNaN(value)) {
      return '-';
    }
    const formatted = value.toString().replace(/\d(?=(\d{3})+$)/g, '$&,');
    return `$ ${formatted}`;
  };

  const userCurrentPeriodGetter = (event: any) => {
    const current = event.rowData.periods.find(
      (cur: any) =>
        cur.startDate ===
        props.projectUserStats.periods[currentStatToShow].start
    );
    if (current) return current.hours;
    return '-';
  };

  const getColor = (data: number) => {
    const transformed = isNaN(data) ? 0 : data;
    if (transformed >= 0 && transformed < 75) {
      return 'green';
    } else if (transformed >= 75 && transformed <= 99) {
      return 'orange';
    }

    return 'red';
  };

  const nextTimesheet = () => {
    setCurrentStatToShow(currentStatToShow + 1);
  };

  const prevTimesheet = () => {
    setCurrentStatToShow(currentStatToShow - 1);
  };

  const noRows = () => {
    return (
      <Typography align="center" variant="subtitle1">
        {messages.NO_DATA}
      </Typography>
    );
  };

  const timesheetTitle = () => {
    let period = messages.NO_PERIOD;
    if (props.projectUserStats.periods.length > 0) {
      const start = moment(props.projectUserStats.periods[0].start).format(
        'MM/DD/YYYY'
      );
      const last =
        props.projectUserStats.periods[
          props.projectUserStats.periods.length - 1
        ];
      const end = moment(last.end).format('MM/DD/YYYY');
      period = `${start} to ${end}`;
    }
    return `Team Members - ${period}`;
  };

  const calcRemainingHours = (selectedProject: any) => {
    const remaining =
      (selectedProject.estimatedHours || 0) -
      (selectedProject.hoursWorked || 0);
    return isNaN(remaining) ? '0' : remaining;
  };

  const getBudget = (selectedProject: any) => {
    const { projectPath } = props;
    const deliverablesPath = [projectPath, 'deliverables'].join('/');
    const addBudgetLink = (
      <CustomLink to={deliverablesPath}> {'Add Budget'} </CustomLink>
    );
    if (isNaN(selectedProject.budgetUsed)) return 'Loading...';
    return selectedProject.estimatedHours + selectedProject.estimatedCost === 0
      ? addBudgetLink
      : selectedProject.budgetUsed + '% used';
  };

  const { selectedProject } = props;

  return (
    <Container>
      <>
        <ProjectDetailHeader selectedProject={selectedProject} />
        <Separator>
          <Typography variant="h5">{`Project Special Instructions`}</Typography>
        </Separator>
        <NoMarginContent>
          <LineItem>
            <Label>
              <Value>
                {selectedProject.invoicingSpecialInstructions ||
                  messages.NO_SPECIAL_INSTRUCTIONS}
              </Value>
            </Label>
          </LineItem>
        </NoMarginContent>
        <Separator color={getColor(selectedProject.budgetUsed)}>
          <Typography variant="h5">
            <InlineLabel>Budget: {getBudget(selectedProject)}</InlineLabel>
          </Typography>
        </Separator>
        <NoMarginContent>
          <LineItem>
            <Label>Budget Calculation</Label>
            <Value>{`${(
              selectedProject.budgetPercentagePreference || 'hours'
            ).toUpperCase()}`}</Value>
          </LineItem>
          <LineItem>
            <Label>Est. Cost</Label>
            <Value>
              <Currency>{selectedProject.estimatedCost}</Currency>
            </Value>
          </LineItem>
          <LineItem>
            <Label>Total Amount</Label>
            <Value>
              <Currency>{selectedProject.totalAmount}</Currency>
            </Value>
          </LineItem>
          <SubLineItem>
            <Label>Invoiced Amount</Label>
            <Value>
              <Currency>{selectedProject.spent}</Currency>
            </Value>
          </SubLineItem>
          <SubLineItem>
            <Label>Unapproved Invoice Amount</Label>
            <Value>
              <Currency>{selectedProject.uninvoicedAmount}</Currency>
            </Value>
          </SubLineItem>
          <SubLineItem>
            <Label>Unapproved Timesheet Amount</Label>
            <Value>
              <Currency>{selectedProject.amountUnapproved}</Currency>
            </Value>
          </SubLineItem>
          <LineItem>
            <Label>Remaining Budget</Label>
            <Value>
              <Currency>
                {(selectedProject.estimatedCost || 0) -
                  (selectedProject.spent || 0)}
              </Currency>
            </Value>
          </LineItem>
          <LineItem>
            <Label>Est. Hours</Label>
            <Value>{`${selectedProject.estimatedHours || '0'}h`}</Value>
          </LineItem>
          <LineItem>
            <Label>Hours worked</Label>
            <Value>{`${
              isNaN(selectedProject.hoursWorked)
                ? '0'
                : selectedProject.hoursWorked
            }h`}</Value>
          </LineItem>
          <SubLineItem>
            <Label>Invoiced Hours</Label>
            <Value>{`${
              isNaN(selectedProject.invoicingHours)
                ? '0'
                : selectedProject.invoicingHours
            }h`}</Value>
          </SubLineItem>
          <SubLineItem>
            <Label>Unapproved Invoice Hours</Label>
            <Value>{`${
              isNaN(selectedProject.hoursUninvoiced)
                ? '0'
                : selectedProject.hoursUninvoiced
            }h`}</Value>
          </SubLineItem>
          <SubLineItem>
            <Label>Unapproved Timesheet Hours</Label>
            <Value>{`${
              isNaN(selectedProject.hoursUnapproved)
                ? '0'
                : selectedProject.hoursUnapproved
            }h`}</Value>
          </SubLineItem>
          <LineItem>
            <Label>Remaining Hours</Label>
            <Value>{`${calcRemainingHours(selectedProject)}h`}</Value>
          </LineItem>
        </NoMarginContent>
        <Separator>
          <Typography variant="h5">{`Invoices`}</Typography>
        </Separator>
        <ListContainer>
          <AutoSizer>
            {({ width, height }) => (
              <CustomTable
                headerClassName="headerRow"
                rowClassName="row"
                headerHeight={50}
                height={height}
                noRowsRenderer={noRows}
                rowCount={props.invoices.length}
                rowHeight={52}
                rowGetter={rowGetter}
                width={width}
              >
                <Column
                  className="column"
                  label="Date"
                  dataKey="creationDate"
                  width={100}
                  flexGrow={2}
                  cellDataGetter={dateGetter}
                />
                <Column
                  className="column"
                  label="Amount"
                  dataKey="total"
                  width={100}
                  flexGrow={1}
                  cellDataGetter={amountGetter}
                />
                <Column
                  className="column"
                  label="Status"
                  dataKey="status"
                  width={100}
                  flexGrow={1}
                />
              </CustomTable>
            )}
          </AutoSizer>
        </ListContainer>
        <Separator>
          <Typography variant="h5">{timesheetTitle()}</Typography>
          <TogglerSimple>
            <IconButton onClick={props.toggleOffset('back')}>
              <SkipPrevious />
            </IconButton>
            <IconButton
              disabled={props.offset === 0}
              onClick={props.toggleOffset('next')}
            >
              <SkipNext />
            </IconButton>
          </TogglerSimple>
        </Separator>
        <ListContainer>
          <AutoSizer>
            {({ height, width }) => (
              <CustomTable
                headerClassName="headerRow"
                rowClassName="row"
                headerHeight={50}
                height={height}
                rowCount={props.projectUserStats.users.length}
                rowHeight={52}
                rowGetter={userStatsRowGetter}
                width={width}
                noRowsRenderer={noRows}
              >
                <Column
                  className="column"
                  label="Member"
                  dataKey="name"
                  width={100}
                  flexGrow={2}
                />
                <Column
                  className="column"
                  label={`${getStatTitle()}`}
                  dataKey="periods"
                  width={100}
                  flexGrow={1}
                  cellDataGetter={userCurrentPeriodGetter}
                />
              </CustomTable>
            )}
          </AutoSizer>
        </ListContainer>
        <Toggler>
          <IconButton
            disabled={!(currentStatToShow > 0)}
            onClick={prevTimesheet}
          >
            <SkipPrevious />
          </IconButton>
          <IconButton
            disabled={
              !(currentStatToShow < props.projectUserStats.periods.length - 1)
            }
            onClick={nextTimesheet}
          >
            <SkipNext />
          </IconButton>
        </Toggler>
      </>
      )
    </Container>
  );
};

export default ProjectsDetail;
