import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import * as React from 'react';
import messages from 'src/messages';

interface IProjectsStatusChange {
  open: boolean;
  deleteEnabled: boolean;
  handleClose: () => void;
  performAction: () => void;
}

const ProjectsStatusChange: React.FC<IProjectsStatusChange> = ({
  open = false,
  deleteEnabled = true,
  handleClose = () => console,
  performAction = () => console
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {deleteEnabled
        ? messages.DISABLE_TITLE_PROJECT
        : messages.ENABLE_TITLE_PROJECT}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {deleteEnabled
          ? messages.DISABLE_BODY_PROJECT
          : messages.ENABLE_BODY_PROJECT}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        {messages.CANCEL}
      </Button>
      <Button onClick={performAction} color="primary" autoFocus={true}>
        {messages.OK}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ProjectsStatusChange;
