import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { Project } from '../types';

export const createProject = (project: any) => {
  const payload = {
    ...project,
    budgetPercentagePreference: project.budgetPercentage,
    clientId: project.client,
    isActive: Boolean(project.status),
    projectManagerId: project.manager,
    invoicingSpecialInstructions: project.invoicingSpecialInstructions
  };

  return callAPI<Project>({
    data: { project: payload },
    method: 'post',
    url: '/projects'
  })
    .then(response => {
      toast('Project created', 'success');
      return response.data;
    })
    .catch(error => {
      toast('Failed to create project', 'error');
      throw error;
    });
};
