import { Box } from '@mui/material';
import { Table, TableProps } from 'react-virtualized';
import styled, { theme } from 'src/theme';

export const SortBox = styled(Box)`
  align-items: center;
  display: flex;
  white-space: nowrap;
`;

export const CustomTable: React.ComponentType<TableProps> = styled(Table)`
  font-size: 14px;

  & .row {
    box-sizing: border-box;
    border-bottom: 2px solid ${theme.primaryBackground};
    background-color: ${theme.secondaryBackground};
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: ${theme.font};
  }
  & .row:focus {
    outline: none;
  }
  & .row:hover {
    background-color: ${theme.separatorBackground};
    cursor: pointer;
  }
  & .row:hover .dismiss-col .dismissButton {
    opacity: 1;
  }
  & .row:hover .info-col .infoButton {
    opacity: 1;
  }
  & .row.active {
    background-color: ${theme.separatorBackground};
  }
  & .ReactVirtualized__Table__headerRow:hover {
    background-color: ${theme.primaryBackground};
    cursor: default;
  }
  & .ReactVirtualized__Table__headerRow {
    background-color: #fff;
    color: ${theme.secondaryColorInverted};
  }

  .headerRow {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 600;
  }

  & .column.dismiss-col {
    min-width: 20px;
    display: inline;
  }

  & .red {
    color: ${theme.red};
  }

  & .hidden {
    opacity: 0;
  }
` as any;

export const CustomTableAmountColumn = styled(Box)<{
  readonly $isMyInvoices: boolean;
}>`
  display: flex;
  justify-content: ${props =>
    props.$isMyInvoices ? 'flex-start' : 'flex-start'};
`;

export const CustomTableDismissColumn = styled(Box)`
  display: flex;
  width: 24px;
  padding: 0 24px;
`;

export const CustomTableInfoColumn = styled(Box)`
  display: flex;
  width: 24px;
  padding-left: 24px;
`;

export const JustifyEndFlexBox = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

export const Fixed = styled(Box)`
  position: fixed;
  z-index: 3;
  top: 250px;
  background-color: #fff;
  width: calc(100% - 242px);
  border-bottom: 1px solid #d4d4d4;

  &.shifted-down {
    top: 410px;
  }
`;

export const TableWrapper = styled.div`
  margin-top: 100px;
  height: calc(100% - 110px);

  &.compressed {
    width: calc(100% - 384px);
  }

  &.shifted-down {
    margin-top: 370px;
    height: calc(100% - 370px);
  }

  &.shrink-text {
    .row {
      .MuiBox-root,
      .column {
        font-size: 12px;

        button {
          font-size: 12px;
        }

        .client-color {
          display: none;
        }
      }

      .client-name-project {
        margin-left: 20px;
      }

      .headerRow {
        padding-left: 10px;
      }
    }
  }
`;

export const StatusBox = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: rgba(33, 33, 33, 0.87);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
`;

export const StatusIcon = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 10px;
  &.ready {
    background-color: rgba(0, 182, 99, 0.87);
  }
  &.in-progress {
    background-color: #f7b500;
  }
  &.rejected {
    background-color: #b00020;
  }
`;
