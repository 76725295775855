import { Box } from '@mui/material';
import styled from 'src/theme';

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
`;

export const AlignSelfStart = styled(Box)`
  align-self: flex-start;
  margin-left: 3px;
`;
