import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import _, { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'src/hooks/withRouter';
import { bindActionCreators, Dispatch } from 'redux';
import { IAppState } from 'src/reducer';
import {
  CircularProgressStyled,
  Container,
  TimesheetListFiltersContainer
} from './styles';
import * as authActions from 'src/shared/auth/actions';
import TimesheetListFilters from 'src/components/TimesheetListFilters';
import * as usersActions from 'src/shared/users/actions';
import TimesheetsList from 'src/components/TimesheetsList';
import TimesheetRejectDialog from 'src/components/TimesheetRejectDialog';
import TimesheetSidebar from 'src/components/TimesheetSidebar';
import {
  ITimesheetContainer,
  ITimesheetContainerDispatch,
  ITimesheetContainerProps,
  ITimeSheetContainerState
} from './types';
import { Pagination } from '@mui/material';
import typeGuard from 'src/shared/typeGuard';
import { TableHeaderProps } from 'react-virtualized';
import TimesheetEntryDialog from 'src/components/TimesheetSidebar/TimesheetEntryDialog';
import {
  ADMIN,
  MANAGER,
  MAX_SIDEBAR_TIMESHEETS_PER_PAGE,
  PM
} from 'src/constants';
import { useMutation, useQuery } from 'react-query';
import { QueryKeys } from 'src/queries/queryKeys';
import { Api } from 'src/api/api';
import {
  Timesheet,
  TimesheetSearchOrderEnum,
  TimesheetStatusEnum
} from 'src/api/types';
import { QUERY_DATE_FORMAT } from 'src/shared/utils';
import moment from 'moment';

const headerToDbColumn = {
  employee: TimesheetSearchOrderEnum.OWNER_FULLNAME,
  manager: TimesheetSearchOrderEnum.MANAGER_FULLNAME,
  period: TimesheetSearchOrderEnum.START_DATE,
  status: TimesheetSearchOrderEnum.STATUS
};

type TimesheetListFilters = {
  direction: 'desc' | 'asc';
  managerId?: number;
  order: TimesheetSearchOrderEnum;
  ownerId?: number;
  page: number;
  perPage: number;
  search: string;
  status: TimesheetStatusEnum;
};

export const TimesheetContainer: FunctionComponent<ITimesheetContainer> = props => {
  const hasRole = (role: string) =>
    props.currentUser.roles.find((cur: any) => cur.name === role);

  const defaultListFilters = {
    direction: 'desc' as const,
    managerId: hasRole(ADMIN) || hasRole(MANAGER) ? props.currentUser.id : 0,
    order: TimesheetSearchOrderEnum.STATUS,
    ownerId: undefined,
    page: 1,
    perPage: 10,
    search: '',
    status: TimesheetStatusEnum.APPROVAL_VIEW
  };

  const [hideInactiveUsers, setHideInactiveUsers] = useState(true);
  const [listFilters, setListFilters] = useState<TimesheetListFilters>(
    defaultListFilters
  );
  const [isRightPanelOpen, setIsRightPanelOpen] = useState(false);
  const [isTimesheetEntryDialogOpen, setIsTimesheetEntryDialogOpen] = useState(
    false
  );
  const [selectedTimesheetId, setSelectedTimesheetId] = useState<number>();
  const [state, setState] = useState<ITimeSheetContainerState>({
    employeeName: '',
    showRejectDialog: false,
    toReject: null
  });
  const [sidebarTimesheetsPageSize, setSidebarTimesheetsPageSize] = useState(
    MAX_SIDEBAR_TIMESHEETS_PER_PAGE
  );
  const [teamTimesheetsStartDate, setTeamTimesheetsStartDate] = useState<
    string
  >();

  const {
    data: timesheets,
    isLoading: isLoadingTimesheets,
    refetch: refetchTimesheets
  } = useQuery([QueryKeys.timesheets, listFilters], () => {
    return Api.fetchTimesheets(listFilters);
  });

  const {
    data: timesheetSidebarDetail,
    isLoading: isLoadingTimesheetSidebarDetail,
    refetch: refetchTimesheetSidebarDetail
  } = useQuery(
    [
      QueryKeys.timesheetSidebarDetail,
      selectedTimesheetId,
      sidebarTimesheetsPageSize
    ],
    () => {
      if (!selectedTimesheetId) return;

      return Api.fetchTimesheetSidebarDetail({
        timesheetId: selectedTimesheetId,
        timesheetsPageSize: sidebarTimesheetsPageSize
      });
    },
    {
      keepPreviousData: true // ensures contents of sidebar remain while refetching after sidebarTimesheetsPageSize has changed
    }
  );

  const { data: teamTimesheets } = useQuery(
    [QueryKeys.timesheets, timesheetSidebarDetail, teamTimesheetsStartDate],
    () => {
      // only fetch team timesheets if selected timesheet is owned by a manager
      if (!timesheetSidebarDetail?.timesheet?.owner.isManager) return;

      const startDate = moment(
        teamTimesheetsStartDate || timesheetSidebarDetail.timesheet.startDate
      ).format(QUERY_DATE_FORMAT);
      const endDate = moment(startDate)
        .add(14, 'day')
        .format(QUERY_DATE_FORMAT);

      return Api.fetchTimesheets({
        page: 1,
        perPage: 100,
        managerId: timesheetSidebarDetail.timesheet.owner.id,
        status: TimesheetStatusEnum.ALL,
        startDate: startDate,
        endDate: endDate
      });
    }
  );

  const { mutate: approveTimesheet } = useMutation(Api.approveTimesheet, {
    onSuccess: () => {
      refetchTimesheets();
      refetchTimesheetSidebarDetail();
    }
  });
  const { mutate: rejectTimesheet } = useMutation(Api.rejectTimesheet, {
    onSuccess: () => {
      refetchTimesheets();
      refetchTimesheetSidebarDetail();
    }
  });
  const { mutate: reopenTimesheet } = useMutation(Api.reopenTimesheet, {
    onSuccess: () => {
      refetchTimesheets();
      refetchTimesheetSidebarDetail();
    }
  });
  const { mutate: updateTimesheetEntry } = useMutation(
    Api.updateTimesheetEntry,
    { onSuccess: refetchTimesheetSidebarDetail }
  );

  useEffect(() => {
    if (hasRole(ADMIN)) {
      props.fetchManagers();
    }
    props.getUsers();
  }, []);

  const closeTimesheetEntryDialog = () => {
    setIsTimesheetEntryDialogOpen(false);
  };

  const totalEntries = () => {
    return timesheets && timesheets.length ? timesheets[0].totalEntries : 0;
  };

  const paginationCount = () => {
    return Math.ceil(totalEntries() / listFilters.perPage);
  };

  const isVisiblePagination = () => {
    return totalEntries() > listFilters.perPage;
  };

  const goToBaseUrl = () => {
    props.navigate(`/timesheet-approvals`);
  };

  const toggleRightPanel = () => {
    setIsRightPanelOpen(!isRightPanelOpen);
  };

  const onChangeSearch = (search: string) => {
    setListFilters({ ...listFilters, search });
  };

  const onSortChange = (headerInfo: TableHeaderProps) => {
    const order = headerToDbColumn[headerInfo.dataKey];
    const direction = (() => {
      if (listFilters.order !== order) {
        return 'desc';
      } else {
        return listFilters.direction === 'asc' ? 'desc' : 'asc';
      }
    })();

    setListFilters({
      ...listFilters,
      order,
      direction
    });
  };

  const onRejectTimesheet = (timesheet: Timesheet, reason: string) => {
    if (isEmpty(reason)) {
      return;
    }
    setState(state => ({ ...state, showRejectDialog: false }));
    rejectTimesheet({ id: timesheet.id, reason });
    setIsTimesheetEntryDialogOpen(false);
  };

  const onApproveTimesheet = (timesheet: Timesheet) => {
    approveTimesheet(timesheet.id);
  };

  const onReopenTimesheet = (timesheet: Timesheet) => {
    reopenTimesheet(timesheet.id);
  };

  const onClickReview = (timesheetId: number) => {
    if (timesheetId !== selectedTimesheetId) {
      setSelectedTimesheetId(timesheetId);
    }
    setIsTimesheetEntryDialogOpen(true);
    setSelectedTimesheetId(timesheetId);
    setIsRightPanelOpen(true);
  };

  const onClickViewTimesheet = () => {
    setIsTimesheetEntryDialogOpen(true);
  };

  const onClickTimesheet = (timesheetId: number) => {
    if (timesheetId !== selectedTimesheetId) {
      setSelectedTimesheetId(timesheetId);
    }
    setSelectedTimesheetId(timesheetId);
    setIsRightPanelOpen(true);
  };

  const onDoubleClickTimesheet = (timesheetId: number) => {
    if (timesheetId !== selectedTimesheetId) {
      setSelectedTimesheetId(timesheetId);
    }
    setIsTimesheetEntryDialogOpen(true);
    setSelectedTimesheetId(timesheetId);
    setIsRightPanelOpen(true);
  };

  const rowClassName = (timesheetId: number) => {
    return `row ${selectedTimesheetId === timesheetId ? 'active' : ''}`;
  };

  const onClickUser = (userId?: number) => {
    setIsRightPanelOpen(false);
    if (userId) {
      setListFilters({
        ...defaultListFilters,
        managerId: undefined,
        ownerId: userId,
        status: TimesheetStatusEnum.ALL
      });
      goToBaseUrl();
    } else {
      setListFilters(defaultListFilters);
    }
  };

  const canManageTimesheet = (timesheetManagerId: number) => {
    if (hasRole(ADMIN)) return true;
    if (!hasRole(MANAGER)) return false;
    return props.currentUser.id === timesheetManagerId;
  };

  const showMyReportsToggle =
    (hasRole(ADMIN) || (hasRole(MANAGER) && hasRole(PM))) &&
    !listFilters.ownerId;

  const onClickEveryone = () => {
    setListFilters({
      ...listFilters,
      managerId: undefined
    });
  };

  const onClickMyReports = () => {
    setListFilters({
      ...listFilters,
      managerId: props.currentUser.id
    });
  };

  const onClickNextTeamTimesheets = () => {
    const currentStartDate = moment(
      teamTimesheetsStartDate || timesheetSidebarDetail?.timesheet?.startDate
    );
    const nextStartDate = (() => {
      const firstDayOfMonth = moment(currentStartDate).startOf('month');

      if (currentStartDate.isSame(firstDayOfMonth)) {
        return moment(currentStartDate).add(15, 'day');
      } else {
        return moment(currentStartDate)
          .add(1, 'month')
          .startOf('month');
      }
    })();

    setTeamTimesheetsStartDate(nextStartDate.format('MM/DD/YY'));
  };

  const onClickPrevTeamTimesheets = () => {
    const currentStartDate = moment(
      teamTimesheetsStartDate || timesheetSidebarDetail?.timesheet?.startDate
    );
    const nextStartDate = (() => {
      const firstDayOfMonth = moment(currentStartDate).startOf('month');

      if (currentStartDate.isSame(firstDayOfMonth)) {
        return moment(currentStartDate)
          .subtract(1, 'month')
          .add(15, 'day');
      } else {
        return firstDayOfMonth;
      }
    })();

    setTeamTimesheetsStartDate(nextStartDate.format('MM/DD/YY'));
  };

  const increaseSidebarTimesheetsPageSize = () => {
    setSidebarTimesheetsPageSize(
      sidebarTimesheetsPageSize + MAX_SIDEBAR_TIMESHEETS_PER_PAGE
    );
  };

  const selectedUser = useMemo(() => {
    return props.usersList.find(users => users.id === listFilters.ownerId);
  }, [props.usersList, listFilters.ownerId]);

  const users = useMemo(() => {
    const search = listFilters.search || '';
    const searchLowerCase = search.toLowerCase();
    return props.usersList.filter(user => {
      if (hideInactiveUsers && !user.isActive) return false;
      if (!user.fullName.toLowerCase().includes(searchLowerCase)) return false;
      return true;
    });
  }, [props.usersList, hideInactiveUsers, listFilters.search]);

  return (
    <>
      {isTimesheetEntryDialogOpen && (
        <TimesheetEntryDialog
          closeTimesheetEntryDialog={closeTimesheetEntryDialog}
          approveTimesheet={onApproveTimesheet}
          reopenTimesheet={onReopenTimesheet}
          rejectTimesheet={onRejectTimesheet}
          timesheetSidebarDetail={timesheetSidebarDetail}
          updateTimesheetEntry={(id, entry) =>
            updateTimesheetEntry({ id, entry })
          }
          requestTimesheetById={setSelectedTimesheetId}
          canManageTimesheet={canManageTimesheet}
          isLoading={isLoadingTimesheetSidebarDetail}
        />
      )}
      <TimesheetListFiltersContainer>
        <TimesheetListFilters
          isHidingInactiveUsers={hideInactiveUsers}
          isSelectedMyReports={listFilters.managerId === props.currentUser.id}
          loadingUsers={props.loadingUsers}
          onChangeSearch={onChangeSearch}
          onClickEveryone={onClickEveryone}
          onClickMyReports={onClickMyReports}
          onClickHideInactiveUsers={() =>
            setHideInactiveUsers(!hideInactiveUsers)
          }
          onClickUser={onClickUser}
          rightPanelOpen={isRightPanelOpen}
          search={listFilters.search}
          selectedUserName={selectedUser?.fullName}
          showMyReportsToggle={showMyReportsToggle}
          toggleRightPanel={toggleRightPanel}
          users={users}
        />
      </TimesheetListFiltersContainer>
      <Container>
        {isLoadingTimesheets ? (
          <CircularProgressStyled />
        ) : (
          <>
            <TimesheetsList
              data={timesheets}
              approveTimesheet={onApproveTimesheet}
              impersonate={id => props.impersonateLogin(id)}
              onSortChange={onSortChange}
              rightPanelOpen={isRightPanelOpen}
              rowClassName={rowClassName}
              canManageTimesheet={canManageTimesheet}
              sortBy={listFilters.order}
              sortDirection={listFilters.direction}
              onClickReview={onClickReview}
              onClickTimesheet={onClickTimesheet}
              onDoubleClickTimesheet={onDoubleClickTimesheet}
            />
            {isVisiblePagination() && (
              <Pagination
                count={paginationCount()}
                onChange={(_e, page) =>
                  setListFilters({ ...listFilters, page })
                }
                page={listFilters.page}
              />
            )}
          </>
        )}
        {isRightPanelOpen && (
          <TimesheetSidebar
            timesheetSidebarDetail={timesheetSidebarDetail}
            toggleSidePanel={toggleRightPanel}
            fetchMoreTimesheets={increaseSidebarTimesheetsPageSize}
            onClickViewTimesheet={onClickViewTimesheet}
            onClickTimesheet={onClickTimesheet}
            pageCss="timesheets-approval"
            isLoading={isLoadingTimesheetSidebarDetail}
            teamTimesheets={teamTimesheets}
            onClickNextTeamTimesheets={onClickNextTeamTimesheets}
            onClickPrevTeamTimesheets={onClickPrevTeamTimesheets}
            teamTimesheetsStartDate={
              teamTimesheetsStartDate ||
              timesheetSidebarDetail?.timesheet?.startDate
            }
          />
        )}
      </Container>
      <TimesheetRejectDialog
        open={state.showRejectDialog}
        onClose={() =>
          setState(state => ({ ...state, showRejectDialog: false }))
        }
        onRejectTimesheet={reason => {
          if (typeGuard.isITimesheet(state.toReject)) {
            rejectTimesheet({ id: state.toReject.id, reason });
          }
          return Function.prototype;
        }}
      />
    </>
  );
};

export const mapStateToProps = (state: IAppState) => {
  const { loadingUsers, usersList, managers } = state.entities;
  const currentUser = state.auth.currentUser;

  return {
    loadingUsers,
    currentUser,
    usersList,
    managers
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): ITimesheetContainerDispatch => {
  return bindActionCreators(
    {
      getUsers: usersActions.actions.getUsers,
      impersonateLogin: authActions.actions.impersonateLogin,
      fetchManagers: usersActions.actions.getManagers
    },
    dispatch
  );
};

export default withRouter(
  connect<ITimesheetContainerProps, ITimesheetContainerDispatch>(
    mapStateToProps,
    mapDispatchToProps
  )(TimesheetContainer)
);
