import React from 'react';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { Container } from './styles';
import TimesheetEntriesPane from 'src/components/TimesheetSidebar/TimesheetEntryDialog/TimesheetEntriesPane';
import {
  Holiday,
  Timesheet,
  TimesheetEntry,
  TimesheetStatusEnum,
  UserProjectRole
} from 'src/api/types';
import { UpdateTimesheetEntryParams } from 'src/api/queries/updateTimesheetEntry';

export interface ITimesheetEntryList {
  timesheet: Timesheet | null;
  userProjectRoles: UserProjectRole[];
  updateTimesheetEntry: (id: number, entry: UpdateTimesheetEntryParams) => void;
  deleteTimesheetEntry: (id: number) => void;
}

export interface ITimesheetEntryListAdditional {
  rightPanelOpen: boolean;
  onSelectDay: (date: Moment, multiSelect: boolean) => void;
  selectedDates: Moment[];
}

export const TimesheetEntryList = ({
  timesheet,
  userProjectRoles,
  rightPanelOpen,
  updateTimesheetEntry,
  deleteTimesheetEntry,
  onSelectDay,
  selectedDates
}: ITimesheetEntryList & ITimesheetEntryListAdditional) => {
  if (!timesheet) return null;
  return (
    <Container className={rightPanelOpen ? 'compressed' : ''}>
      {_.map(
        timesheet.timesheetDays,
        ({
          date,
          entries,
          holiday
        }: {
          date: string;
          entries: TimesheetEntry[];
          holiday: Holiday;
        }) => (
          <TimesheetEntriesPane
            canEdit={
              _.eq(timesheet.status, TimesheetStatusEnum.NEW) ||
              _.eq(timesheet.status, TimesheetStatusEnum.REJECTED)
            }
            key={date}
            holiday={holiday}
            entries={entries}
            date={date}
            updateTimesheetEntry={updateTimesheetEntry}
            userProjectRoles={userProjectRoles}
            deleteTimesheetEntry={deleteTimesheetEntry}
            onSelectDay={onSelectDay}
            selected={_.some(selectedDates, (d: Moment) =>
              d.isSame(moment(date, 'YYYY-MM-DD'), 'date')
            )}
            showEntriesDefault={false}
          />
        )
      )}
    </Container>
  );
};

export default TimesheetEntryList;
