import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { ProjectCreateOptions } from '../types';

export const fetchProjectCreateOptions = () =>
  callAPI<ProjectCreateOptions>({
    method: 'get',
    url: '/projects/create_options'
  })
    .then(response => response.data)
    .catch(error => {
      toast('Failed to fetch project create options', 'error');
      throw error;
    });
