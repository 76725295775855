import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';

export const submitTimesheet = (id: number) =>
  callAPI({
    url: `/timesheets/submit/${id}`,
    method: 'POST'
  })
    .then(response => {
      toast('Timesheet submitted', 'success');
      return response.data;
    })
    .catch(error => {
      toast('Failed to submit timesheet', 'error');
      throw error;
    });
