import _ from 'lodash';
import moment from 'moment';

export const isPositiveValue = (val?: number | string) => _.gt(val, 0);

export const isNotEmpty = (val?: number | string) => val && !_.isEmpty(val);
export const isValidDate = (val?: string) =>
  moment(val, 'MM/DD/YYYY').isValid();

export const isPositiveValueOrZero = (val?: number | string) => _.gte(val, 0);

/*eslint-disable no-useless-escape*/
const regexValidations = {
  VALID_EMAIL_REGEX: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  VALID_NAME_REGEX: /^[a-zA-Z\s\-]+$/,
  VALID_TITLE_REGEX: /^[a-zA-Z0-9\-\"\,\.\s]+$/
};

export default regexValidations;
