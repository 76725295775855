import React from 'react';
import { Modal } from '@mui/material';
import { Footer, CancelButton, LeaveButton, Wrapper } from './styles';
import styled, { theme } from '../../../theme';

interface IConfirmCloseDialogModal {
  onConfirm: () => void;
  onCancel: () => void;
  question: string | JSX.Element;
}

const PaperDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 532px;
  background-color: ${theme.palette.background.paper};
  border: 1px solid #212121;
  border-radius: 5px;
  box-shadow: ${theme.shadows[5]};
  padding: 0;
  outline: none !important;
`;

export const ConfirmModal = ({
  onConfirm,
  onCancel,
  question
}: IConfirmCloseDialogModal) => {
  return (
    <Modal open onClose={onCancel} onBackdropClick={onCancel}>
      <PaperDiv>
        <Wrapper>
          <p>{question}</p>
          <Footer>
            <LeaveButton onClick={() => onConfirm()}>Confirm</LeaveButton>
            <CancelButton onClick={() => onCancel()}>Cancel</CancelButton>
          </Footer>
        </Wrapper>
      </PaperDiv>
    </Modal>
  );
};

export default ConfirmModal;
