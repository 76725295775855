import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@mui/material';
import * as React from 'react';
import { FunctionComponent, useRef, useState } from 'react';
import messages from 'src/messages';
import { Container, CustomAvatarButton, CustomAvatar } from './styles';

interface IUserInfoProps {
  initials: string;
  logout: () => void;
}

export const UserInfo: FunctionComponent<IUserInfoProps> = props => {
  const popperAnchor = useRef(null);

  const [popperIsOpen, setPopperIsOpen] = useState(false);

  const openPopper = () => {
    setPopperIsOpen(!popperIsOpen);
  };

  const handleLogout = (event: any) => {
    handleClose(event);
    props.logout();
  };

  const handleClose = (event: any) => {
    if (popperAnchor.current === event.target) {
      return;
    }
    setPopperIsOpen(false);
  };

  const { initials } = props;

  return (
    <Container>
      <CustomAvatarButton ref={popperAnchor} onClick={openPopper}>
        <CustomAvatar>{initials}</CustomAvatar>
      </CustomAvatarButton>
      <Popper
        placement="bottom-end"
        open={popperIsOpen}
        anchorEl={popperAnchor.current}
        transition={true}
        disablePortal={true}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem onClick={handleLogout}>{messages.LOGOUT}</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Container>
  );
};

export default UserInfo;
