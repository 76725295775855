import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { UserStats } from '../types';

export const fetchProjectUserStats = (projectId: number, offset?: number) =>
  callAPI<UserStats>({
    method: 'get',
    params: {
      dateOffset: offset || 0,
      id: projectId
    },
    url: `/projects/user_stats`
  })
    .then(response => response.data)
    .catch(error => {
      toast('Failed to fetch project user stats', 'error');
      throw error;
    });
