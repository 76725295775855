export default {
  ACTIVE: 'ACTIVE',
  ADD: 'Add',
  ADMIN: {
    DEPARTMENTS: 'Departments',
    DISCONNECT_QB: 'Disconnect Quickbooks',
    QUICKBOOKS_TITLE: 'QuickBooks'
  },
  ADD_ALLOCATION: 'Add Allocation',
  ADD_CLIENT: 'Add Client',
  ADD_MEMBER: 'Add Team member',
  ADD_PROJECT: 'Add Project',
  ALL: 'All',
  ALL_DEPARTMENTS: 'All departments',
  ANY: 'ANY',
  ANY_CLIENT: 'ANY CLIENT',
  ANY_MANAGER: 'ANY MANAGER',
  APPROVE: 'Approve',
  NO_ACTIVE_PROJECTS: 'There are no active projects right now',
  NO_POTENTIAL_PROJECTS: 'There are no potential projects right now',
  IMPERSONATE_ENABLED:
    'QA Impersonate is enabled - Choose a user from the list to impersonate',
  CANCEL: 'Cancel',
  CREATE: 'Create',
  DISABLE: 'Disable',
  DISABLED: 'DISABLED',
  DISABLE_BODY:
    'The user will not be able to perform any further actions once disabled',
  DISABLE_BODY_CLIENT:
    'The client will not be able to perform any further actions once disabled',
  DISABLE_BODY_PROJECT:
    'The project will be thrown into the disabled project list',
  DISABLE_TITLE: 'Are you sure you want to disable this user?',
  DISABLE_TITLE_CLIENT: 'Are you sure you want to disable this client?',
  DISABLE_TITLE_PROJECT: 'Are you sure you want to disable this project?',
  ERRORS: {
    GENERIC_ERROR: 'Something went wrong.'
  },
  IMPERSONATE: 'Impersonate',
  EDIT: 'Update',
  EDIT_MEMBER: 'Edit Member',
  EMAIL_ERROR: 'Required - Invalid email format',
  ENABLE_BODY:
    'The user will be able to perform all actions assigned to his roles',
  ENABLE_BODY_CLIENT: 'The client will be able to perform all actions',
  ENABLE_BODY_PROJECT: 'The project will be usable again',
  ENABLE_TITLE: 'Are you sure you want to re-enable this user?',
  ENABLE_TITLE_CLIENT: 'Are you sure you want to re-enable this client?',
  ENABLE_TITLE_PROJECT: 'Are you sure you want to re-enable this project?',
  ERROR_LOGIN: 'Google auth connection refused.',
  FIND_EMPLOYEE: 'Find Employee',
  INACTIVE: 'INACTIVE',
  LABELS: {
    ACTIVE: 'Active',
    ACTIVE_PROJECT: 'Active Project',
    PROJECT: 'Project',
    PROJECTS: 'Projects',
    POTENTIAL_PROJECT: 'Potential Project',
    BUDGET_PERCENTAGE: 'Budget %',
    ACTIVE_CLIENT: 'Active Client',
    CLIENT: 'Client',
    CODE: 'Code',
    COLOR: 'Color',
    COMMS: 'Communication',
    CREATION_DATE: 'Creation Date',
    DELIVERABLE: 'Deliverable',
    EMAIL: 'Email',
    EMPLOYMENT_TYPE: 'Employment Type',
    FIRST_NAME: 'First Name',
    HOURS_PER_DAY: 'Hours/Day',
    INVOICE: 'Invoice',
    LAST_NAME: 'Last Name',
    MANAGER: 'Manager',
    PROJECT_MANAGER: 'Project Manager',
    DEPARTMENT: 'Department',
    DEPARTMENTS: 'Departments',
    ADD_DEPARTMENT: 'Add Department',
    NEW: 'New',
    MORALE: 'Morale',
    NAME: 'Name',
    NICKNAME: 'Nickname',
    NOTIFICATION_PREF: 'Notification Preference',
    NOTE: 'Note',
    PERIOD: 'Period',
    QUALITY: 'Quality',
    RESOURCE: 'Resource',
    ROLES: 'Roles',
    REJECTION_REASON: 'Rejection Reason',
    SLACK_NAME: 'Slack Display Name',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    STATUS: 'Status',
    PROJECT_SPECIAL_INST: 'Special Instructions',
    TOTAL: 'Total',
    TYPE: 'Type'
  },
  LOADING: 'Loading...',
  LOGOUT: 'Logout',
  LOGO_TEXT: 'moment',
  NAME_ERROR: 'Required - can only contain letters, hyphens and spaces',
  NO_INVOICE_SELECTED: 'No Invoice Selected',
  NO_PERSON_SELECTED: 'No Person Selected',
  NO_PROJECT_SELECTED: 'No project selected',
  NO_SPECIAL_INSTRUCTIONS: 'No special instructions provided.',
  NO_DATA: 'No data',
  NO_PERIOD: 'No period available',
  NO_RESOURCES_MATCH: 'No Resources match the current filters',
  OK: 'Ok',
  OPEN_SIDE_PANEL: 'Open side panel',
  POTENTIAL: 'Potential',
  PROJECT_VIEW: 'Project View',
  REMOVE: 'Remove',
  REQUIRED: 'Required',
  RESOURCES: 'Resources',
  RESOURCE_VIEW: 'Resource View',
  SAVE: 'Save',
  SELECT_USER: 'Select User',
  SEND: 'Send',
  SHOW_BENCHED_RESOURCES: 'Show benched resources only',
  SHOW_PINNED_RESOURCES: 'Show pinned resources only',
  SHOW_PAST_WEEK: 'Show past week',
  SHOW_NEXT_WEEK: 'Show next week',
  SIGN_IN_WITH_GOOGLE: 'Sign in with Google',
  SUBMIT: 'Submit',
  TITLE_ERROR: 'can only contain alphanumeric characters and " , . -',
  USERS: 'Users',
  WHO_ARE: 'Who are',
  DIRECT_MANAGER: 'Direct managing',
  ALL_TIMESHEETS: 'All timesheets',
  PENDING_APPROVALS: 'Pending Approvals',
  CURRENT_TIMESHEETS: 'Current Timesheets',
  PAST_TIMESHEETS: 'Past Timesheets',
  NO_TIMESHEET_SELECTED: 'No timesheet selected',
  REJECT_TIMESHEET_REASON: 'Rejection reason',
  REJECT: 'Reject',
  IMPERSONATE_SWITCH: 'Switch back',
  IMPERSONATE_MESSAGE: 'You are currently impersonating another user'
};
