import React from 'react';
import { styled } from '@mui/material/styles';
import { Dialog as MDialog, DialogProps } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button, { IButtonProps } from './Button';
import Box from './Box';
import Icon from './Icon';

const DialogStyled = styled(MDialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface IFooterButton extends IButtonProps {
  id: string;
}

export interface IDialogProps
  extends Pick<DialogProps, 'children' | 'onClose' | 'open' | 'maxWidth'> {
  title?: string;
  /**
   * Renders buttons in footer by passing an array of FooterButton
   */
  footerButtons?: IFooterButton[];
}

const renderFooterButtons = (
  footerButtons: IButtonProps[]
): React.ReactNode => {
  return footerButtons.map(b => (
    <Button key={b.id} {...b}>
      {b.children}
    </Button>
  ));
};

export const Dialog = ({
  maxWidth = 'sm',
  open,
  onClose,
  ...props
}: IDialogProps) => {
  return (
    <DialogStyled open={open} onClose={onClose} fullWidth maxWidth={maxWidth}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2
          }}
        >
          <Box>{props.title}</Box>
        </DialogTitle>
        {onClose && (
          <Box
            sx={{
              p: 1
            }}
          >
            <Icon name="Close" onClick={onClose as any} color="inherit" />
          </Box>
        )}
      </Box>
      <DialogContent dividers>{props.children}</DialogContent>
      {props.footerButtons && (
        <DialogActions>
          {renderFooterButtons(props.footerButtons)}
        </DialogActions>
      )}
    </DialogStyled>
  );
};

export default Dialog;
