import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { DepartmentUser } from '../types';

export const createDepartmentUser = ({
  userId,
  departmentId
}: {
  userId: number;
  departmentId: number;
}) =>
  callAPI<{ departmentUser: DepartmentUser }>({
    method: 'post',
    url: '/department_user',
    data: { departmentId, userId }
  })
    .then(response => response.data.departmentUser)
    .catch(error => {
      toast('Failed to create department user', 'error');
      throw error;
    });
