import React, { FunctionComponent, useContext } from 'react';
import RightSideMenu from 'src/components/TimesheetsRightMenu';
import TimesheetUtilization from 'src/components/TimesheetSidebar/TimesheetUtilization';
import TimesheetUtilizationChart from 'src/components/TimesheetUtilizationChart';
import { noTimesheetSelected } from 'src/containers/TimesheetContainer/aux';
import messages from '../../messages';
import { AutoSizer } from 'react-virtualized';
import { useLocation, useParams } from 'react-router-dom';
import { AppContext } from 'src/contexts/app-context';
import { useQuery } from 'react-query';
import { QueryKeys } from 'src/queries/queryKeys';
import { Api } from 'src/api/api';

export const TimesheetApprovalsSummaryTabContainer: FunctionComponent = () => {
  const context = useContext(AppContext);
  const params = useParams();
  const location = useLocation();

  const { data: timesheet } = useQuery([QueryKeys.timesheet, params.id], () => {
    if (!params.id) return;

    return Api.fetchTimesheet(Number(params.id));
  });

  const { pathname } = location;
  const proportion = context.isRightDrawerOpen ? 0.35 : 0.65;
  return timesheet ? (
    <React.Fragment>
      <RightSideMenu timesheet={timesheet} path={pathname} />
      <TimesheetUtilization timesheet={timesheet} />
      <AutoSizer>
        {({ height, width }) => (
          <TimesheetUtilizationChart
            rows={timesheet.timesheetDays}
            width={width}
            height={height - height * proportion}
          />
        )}
      </AutoSizer>
    </React.Fragment>
  ) : (
    noTimesheetSelected(messages.NO_TIMESHEET_SELECTED)
  );
};

export default TimesheetApprovalsSummaryTabContainer;
