import { toast } from 'react-toastify';
import { callAPI } from 'src/services/api';

export const logoutQuickBooksOnline = () =>
  callAPI({
    method: 'get',
    url: '/quickbooks/logout_of_qbo'
  }).catch(e => {
    toast('Failed to log out of QuickBooksOnline');
    throw e;
  });
