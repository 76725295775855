import { Avatar as MUIAvatar } from '@mui/material';
import styled, { theme } from 'src/theme';

export const Avatar = styled(MUIAvatar)`
  && {
    background-color: #616161;
    margin: 0 4px;
    height: 36px;
    width: 36px;
    font-size: 16px;
    font-weight: 500;
    font-family: ${theme.font};
  }
`;
