import { Drawer, Box, Typography, Tabs, Tab } from '@mui/material';
import styled from 'src/theme';

export const RightDrawer = styled(Drawer)`
  & .MuiPaper-root {
    height: calc(100vh - 166px);
    width: 384px;
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 3;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

export const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 0;

  svg {
    cursor: pointer;
  }
`;

export const StickyColumnFlexBox = styled(FlexBox)`
  flex-direction: column;
  position: sticky;
  top: 0;
  padding: 16px;
  padding-bottom: 0;
  background-color: #fff;
  z-index: 5;
  border-top: 1px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
`;

export const PaddedScrollingBox = styled(Box)`
  display: block;
  width: 382px;
  padding-bottom: 50px;
`;

export const SidebarHeader = styled(Typography)`
  && {
    color: #212121;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
  }
`;

export const StyledTabs = styled(Tabs)`
  padding-left: 0;
`;

export const StyledTab = styled(Tab)`
  padding: 16px;
`;

export const FixedBox = styled(Box)`
  position: fixed;
  top: 40%;
  right: 175px;
`;
