import * as React from 'react';
import styled, { theme } from '../../theme';

export const Container = styled.main`
  display: flex;
  flex: 1;
  height: 100%;
  background-color: #fff;
`;

interface IMainContent {
  children: React.ReactNode;
  opened: boolean;
}

export const MainContent: React.ComponentType<IMainContent> = styled(
  ({ opened, ...rest }) => <div {...rest} />
)`
  flex-grow: 1;
  transition: ${props =>
    !props.opened
      ? theme.transitions.create('margin', {
          duration: theme.transitions.duration.leavingScreen,
          easing: theme.transitions.easing.sharp
        })
      : theme.transitions.create('margin', {
          duration: theme.transitions.duration.enteringScreen,
          easing: theme.transitions.easing.easeOut
        })};
  margin-left: ${props => (!props.opened ? `-2px` : '0')};
  min-width: 650px;
  flex: 1;
  display: flex;
` as React.ComponentType<IMainContent>;
