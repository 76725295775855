import * as React from 'react';
import InvoiceAppBar from 'src/components/InvoiceAppBar';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from 'src/reducer';
import { actions as invoicesActions } from 'src/shared/invoice-list/actions';
import { actions as invoiceDetailActions } from 'src/shared/invoice/actions';
import {
  STATUS_FILTER,
  PROJECT_ID_FILTER
} from 'src/shared/invoice-list/constants';
import { ALL } from 'src/shared/invoice-list/types';
import { NewInvoiceParams } from 'src/shared/invoice/types';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProjectDetail } from 'src/api/types';

export interface IInvoiceAppBarContainerStateProps {
  projects: ProjectDetail[];
  activeProjects: ProjectDetail[];
}

type IInvoiceAppBarContainerProps = IInvoiceAppBarContainerStateProps &
  IInvoiceAppBarContainerDispatch;

export const InvoiceAppBarContainer: FunctionComponent<IInvoiceAppBarContainerProps> = props => {
  const navigate = useNavigate();

  const onSetInvoiceProjectFilter = (id: number) => {
    // need to set the status filter to all before doing the projectId search,
    // or the ui and request state are not matched
    // (projectId query queries against all status)
    props.filterChanged({ key: STATUS_FILTER, value: ALL });
    props.filterChanged({ key: PROJECT_ID_FILTER, value: id });
    props.disableFilters();
  };

  return (
    <InvoiceAppBar
      searchInvoices={props.searchInvoices}
      projects={props.projects}
      activeProjects={props.activeProjects}
      setInvoiceProjectFilter={onSetInvoiceProjectFilter}
      createNewInvoice={params =>
        props.createNewInvoice(navigate, false, params)
      }
    />
  );
};

export const mapStateToProps = (state: IAppState) => {
  const projects = state.invoices.projects;
  const activeProjects = state.invoices.activeProjects;
  return {
    projects,
    activeProjects
  };
};

interface IInvoiceAppBarContainerDispatch {
  searchInvoices: (query: string, active: boolean, perPage?: number) => void;
  createNewInvoice: (
    redirect: (path: string) => void,
    dismiss?: boolean,
    params?: NewInvoiceParams
  ) => void;
  filterChanged: ({
    key,
    value
  }: {
    key: string;
    value: number | string;
  }) => void;
  disableFilters: () => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch
): IInvoiceAppBarContainerDispatch => {
  return bindActionCreators(
    {
      searchInvoices: invoicesActions.searchInvoices,
      filterChanged: invoicesActions.changeInvoiceFilter,
      disableFilters: invoicesActions.disableFilters,
      createNewInvoice: invoiceDetailActions.createNewInvoice
    },
    dispatch
  );
};

export default connect<
  IInvoiceAppBarContainerStateProps,
  IInvoiceAppBarContainerDispatch
>(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceAppBarContainer);
