import * as React from 'react';
import ProjectsListContainer from 'src/containers/ProjectsListContainer';
import { IAppState } from 'src/reducer';
import { connect } from 'react-redux';
import { FunctionComponent } from 'react';

interface IProjectsListContainerProps {
  currentUser: any;
}

export const ClientProjectsListContainer: FunctionComponent<IProjectsListContainerProps> = props => {
  const getSelectedManagerId = () => {
    const hasAdminRole = props.currentUser.roles.find(
      (cur: any) => cur.name === 'ADMIN'
    );
    return !hasAdminRole ? Number(props.currentUser.id) : null;
  };

  return (
    <ProjectsListContainer
      route={'client-projects'}
      selectedManager={getSelectedManagerId()}
      isInHouse={false}
    />
  );
};

export const getUser = (state: IAppState) => {
  return { currentUser: state.auth.currentUser };
};

export default connect(getUser)(ClientProjectsListContainer);
