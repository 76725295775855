import _ from 'lodash';
import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { parseResponseErrorMessage, typeGuard } from 'src/shared/utils';

export const fetchQuickBooksCustomers = () =>
  callAPI<{ Id: string; DisplayName: string }[]>({
    method: 'get',
    url: '/quickbooks/fetch_all_customers'
  })
    .then(response => {
      return response.data.map(customer => ({
        id: parseInt(customer.Id, 10),
        displayName: customer.DisplayName
      }));
    })
    .catch(e => {
      if (typeGuard.isResponseError(e)) {
        toast(parseResponseErrorMessage(e), 'error');
      } else {
        toast('Failed to fetch QuickBooks customers', 'error');
      }
      throw e;
    });
