import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { ProjectRole } from '../types';

export const fetchProjectRoles = ({ projectId }: { projectId: number }) =>
  callAPI<ProjectRole[]>({
    method: 'get',
    url: '/project_roles',
    params: { projectId },
    reqHeaders: {
      Accept: 'application/json'
    }
  })
    .then(response => response.data)
    .catch(error => {
      toast('Failed to fetch project roles', 'error');
      throw error;
    });
