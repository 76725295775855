import React, { FunctionComponent, useRef, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { InputAdornment, Popover } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { NewInvoiceParams } from 'src/shared/invoice/types';
import { ClientProject } from '../InvoiceList/ClientProject';
import InvoiceCreateModal from './InvoiceCreateModal';
import {
  ClientIcon,
  CloseButton,
  FlexBox,
  NewInvoiceButton,
  StyledMenuItem,
  StyledSearch
} from './styles';
import { actions } from 'src/shared/invoice-list/actions';
import { ProjectDetail } from 'src/api/types';

interface IInvoiceAppBar {
  searchInvoices: (query: string, active: boolean, perPage?: number) => void;
  projects: ProjectDetail[];
  activeProjects: ProjectDetail[];
  setInvoiceProjectFilter: (id: number) => void;
  createNewInvoice: (params: NewInvoiceParams) => void;
}

interface IInvoiceAppBarState {
  query?: string;
  openSearchResults: boolean;
  invoiceCreateModalOpen: boolean;
}

type IInvoiceAppBarProps = IInvoiceAppBar & IInvoiceAppBarDispatch;

export const InvoiceAppBar: FunctionComponent<IInvoiceAppBarProps> = props => {
  const [state, setState] = useState<IInvoiceAppBarState>({
    query: '',
    openSearchResults: false,
    invoiceCreateModalOpen: false
  });

  const searchBarEl = useRef<HTMLDivElement | null>(null);

  const onChangeQuery = (query: string) => {
    setState({ ...state, query });
    props.changeInvoiceFilter({ key: 'query', value: query });
  };

  const MenuItemClicked = (id: number) => {
    handleClose();
    props.setInvoiceProjectFilter(id);
  };

  const handleClose = () => {
    setOpenSearchResults(false);
  };

  const setOpenSearchResults = (openSearchResults: boolean) => {
    setState({ ...state, openSearchResults });
  };

  const renderClientProject = (project: ProjectDetail) => {
    const { code, color } = project.client;
    return (
      <ClientProject
        clientName={project.nickname}
        projectNickname={code}
        clientColor={color}
      />
    );
  };

  const renderClientIcon = (color?: string) => {
    if (!color) {
      return <ClientIcon color="black" />;
    }
    return <ClientIcon color={color} />;
  };

  const onCloseButtonClick = () => {
    handleClose();
    setState({ ...state, query: '' });
    props.changeInvoiceFilter({ key: 'query', value: '' });
  };

  const onClickOpenInvoiceCreateModal = () => {
    setState({ ...state, invoiceCreateModalOpen: true });
  };

  const onClickCloseInvoiceCreateModal = () => {
    setState({ ...state, invoiceCreateModalOpen: false });
  };

  const { projects, activeProjects, searchInvoices } = props;
  const { query, openSearchResults, invoiceCreateModalOpen } = state;

  return (
    <FlexBox>
      <NewInvoiceButton onClick={onClickOpenInvoiceCreateModal}>
        <span> New Invoice </span>
        <AddCircleIcon />
      </NewInvoiceButton>
      <StyledSearch
        onChange={({ target: { value } }) => onChangeQuery(value)}
        key="project-manager-filter"
        fullWidth={true}
        value={query}
        margin="normal"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: query && (
            <InputAdornment position="end">
              <CloseButton
                aria-label="clear search"
                onClick={onCloseButtonClick}
              >
                <CloseIcon />
              </CloseButton>
            </InputAdornment>
          )
        }}
        placeholder="Search clients and projects"
        ref={searchBarEl}
      ></StyledSearch>
      <Popover
        anchorEl={searchBarEl.current}
        open={Boolean(projects.length) && openSearchResults}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        PaperProps={{
          style: {
            transform: 'translateY(50px)',
            width: searchBarEl.current?.offsetWidth || 0
          }
        }}
        disableAutoFocus
        disableEnforceFocus
      >
        {_.map(projects, (project: ProjectDetail) => (
          <StyledMenuItem
            key={project.id}
            value={project.id}
            onClick={() => MenuItemClicked(project.id)}
          >
            {renderClientProject(project)}
          </StyledMenuItem>
        ))}
      </Popover>
      {invoiceCreateModalOpen && (
        <InvoiceCreateModal
          onConfirm={(projectId, period) => {
            props.createNewInvoice({
              projectId,
              period
            });
            onClickCloseInvoiceCreateModal();
          }}
          onCancel={() => {
            onClickCloseInvoiceCreateModal();
          }}
          searchInvoices={searchInvoices}
          activeProjects={activeProjects}
          renderClientIcon={renderClientIcon}
        />
      )}
    </FlexBox>
  );
};

interface IInvoiceAppBarDispatch {
  initInvoiceListFilters: () => void;
  changeInvoiceFilter: typeof actions.changeInvoiceFilter;
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      initInvoiceListFilters: actions.initInvoiceListFilters,
      changeInvoiceFilter: actions.changeInvoiceFilter
    },
    dispatch
  );
};

export default connect<IInvoiceAppBar, IInvoiceAppBarDispatch>(
  null,
  mapDispatchToProps
)(InvoiceAppBar);
