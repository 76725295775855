import * as React from 'react';
import RightDrawer from '../RightDrawer';
import {
  Container,
  RightContainer,
  SimpleContainer,
  ScrolledContainer
} from './styles';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';

type IPrivateRouteTemplate = {
  mainComponent: any;
  rightComponent?: any;
  showRightOnSm: boolean;
  exact?: boolean;
  path?: string;
  leftOpened: boolean;
  rightOpened: boolean;
  rightToggle: () => void;
  backRoute?: string;
  isCurrentUser?: boolean;
};

export const PrivateRouteTemplate: FunctionComponent<IPrivateRouteTemplate> = props => {
  const navigate = useNavigate();

  const smClose = () => {
    navigate(props.backRoute || '', { replace: true });
  };

  const {
    mainComponent: MainComponent,
    rightComponent: RightComponent,
    ...rest
  } = props;

  if (typeof RightComponent !== 'undefined') {
    return (
      <Container>
        <ScrolledContainer>
          <MainComponent {...rest} />
        </ScrolledContainer>
        <RightDrawer
          onSmClose={smClose}
          showOnSm={props.showRightOnSm}
          toggle={props.rightToggle}
          opened={props.rightOpened}
        >
          <RightContainer>
            <RightComponent {...rest} />
          </RightContainer>
        </RightDrawer>
      </Container>
    );
  }

  return (
    <SimpleContainer>
      <MainComponent {...rest} />
    </SimpleContainer>
  );
};

export default PrivateRouteTemplate;
