import { callAPI } from 'src/services/api';

export const sendNotificationEmail = (params: {
  id: number;
  sender: number;
  override: boolean;
}) =>
  callAPI({
    params,
    method: 'get',
    url: '/notifications/send_email'
  });
