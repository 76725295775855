import { IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import * as React from 'react';
import { AutoSizer, Column } from 'react-virtualized';
import messages from '../../messages';
import AddInvoiceCCEmail from './AddInvoiceCCEmail';
import AddInvoiceEmail from './AddInvoiceEmail';
import { Container, CustomTable, ListContainer, Separator } from './styles';
import ProjectHeader from '../ProjectsDetailHeader';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FunctionComponent, useState } from 'react';

const initialContactValues = {
  ccEmail: '',
  ccFirstName: '',
  ccLastName: '',
  email: '',
  firstName: '',
  lastName: '',
  specialInstructions: ''
};
const initialContactValuesErrors = {
  ccEmail: false,
  ccFirstName: false,
  ccLastName: false,
  email: false,
  firstName: false,
  lastName: false,
  specialInstructions: false
};

interface IProjectsDetailContact {
  selectedProject: any;
  updateProjectData: (data: any, success: () => void) => void;
  createInvoiceEmail: (type: string, data: any, success: () => void) => void;
  deleteInvoiceEmail: (id: any) => void;
  loadingCreateInvoiceEmail: boolean;
  path: string;
}

interface IProjectsDetailContactState {
  openEditSpecialInstructions: boolean;
  openAddInvoiceEmail: boolean;
  openAddInvoiceCCEmail: boolean;
  contactValues: any;
  contactValuesErrors: any;
  [x: string]: any;
}

export const ProjectsDetailContact: FunctionComponent<IProjectsDetailContact> = props => {
  const location = useLocation();
  const navigate = useNavigate();

  const [state, setState] = useState<IProjectsDetailContactState>(() => {
    const projectPath = location.pathname.split('/');
    return {
      contactValues: initialContactValues,
      contactValuesErrors: initialContactValuesErrors,
      openAddInvoiceCCEmail: projectPath.includes('add-cc'),
      openAddInvoiceEmail: projectPath.includes('add'),
      openEditSpecialInstructions: false
    };
  });

  const rowGetter = (event: any) => {
    return props.selectedProject.invoiceEmailTos[event.index];
  };

  const rowGetterCcs = (event: any) => {
    return props.selectedProject.invoiceEmailCcs[event.index];
  };

  const noRows = () => {
    return (
      <Typography align="center" variant="subtitle1">
        No data
      </Typography>
    );
  };

  const renderTrashButton = (value: any) => {
    return (
      <IconButton size="small" onClick={deleteCurrent(value)}>
        <DeleteIcon />
      </IconButton>
    );
  };

  const deleteCurrent = (id: any) => () => {
    props.deleteInvoiceEmail(id);
  };

  const openAddInvoiceEmail = () => {
    setState({
      ...state,
      openAddInvoiceEmail: true
    });
  };

  const openAddInvoiceCCEmail = () => {
    setState({
      ...state,
      openAddInvoiceCCEmail: true
    });
  };

  const onChange = (prop: string) => (event: any) => {
    setState({
      ...state,
      contactValues: {
        ...state.contactValues,
        [prop]: event.target.value
      }
    });
  };

  const onSuccess = () => {
    navigate(`${props.path}/contact`);
    setState({
      contactValues: initialContactValues,
      contactValuesErrors: initialContactValuesErrors,
      openAddInvoiceCCEmail: false,
      openAddInvoiceEmail: false,
      openEditSpecialInstructions: false
    });
  };

  const createInvoiceEmail = () => {
    const { contactValues } = state;
    if (contactValues.email && contactValues.firstName) {
      props.createInvoiceEmail(
        'to',
        {
          email: contactValues.email,
          firstName: contactValues.firstName,
          lastName: contactValues.lastName
        },
        onSuccess
      );
    } else {
      setState({
        ...state,
        contactValuesErrors: {
          ...contactValues.contactValuesErrors,
          email: !contactValues.email,
          firstName: !contactValues.firstName
        }
      });
    }
  };

  const createInvoiceCCEmail = () => {
    const { contactValues } = state;
    if (contactValues.ccEmail && contactValues.ccFirstName) {
      props.createInvoiceEmail(
        'cc',
        {
          email: contactValues.ccEmail,
          firstName: contactValues.ccFirstName,
          lastName: contactValues.ccLastName
        },
        onSuccess
      );
    } else {
      setState({
        ...state,
        contactValuesErrors: {
          ...contactValues.contactValuesErrors,
          ccEmail: !contactValues.ccEmail,
          ccFirstName: !contactValues.ccFirstName
        }
      });
    }
  };

  const { selectedProject } = props;
  const { path } = props;

  return (
    <Container>
      {selectedProject ? (
        <React.Fragment>
          <ProjectHeader selectedProject={selectedProject} />
          <Separator>
            <Typography variant="h5">{`Contact Emails`}</Typography>
            <Link to={`${path}/contact/add`}>
              <IconButton onClick={openAddInvoiceEmail}>
                <AddIcon />
              </IconButton>
            </Link>
          </Separator>
          <AddInvoiceEmail
            errors={state.contactValuesErrors}
            values={state.contactValues}
            add={createInvoiceEmail}
            handleClose={onSuccess}
            loading={props.loadingCreateInvoiceEmail}
            open={state.openAddInvoiceEmail}
            onChange={onChange}
          />
          <ListContainer>
            <AutoSizer>
              {({ width, height }) => (
                <CustomTable
                  headerClassName="headerRow"
                  rowClassName="row"
                  headerHeight={50}
                  height={height}
                  noRowsRenderer={noRows}
                  rowCount={props.selectedProject.invoiceEmailTos.length}
                  rowHeight={52}
                  rowGetter={rowGetter}
                  width={width}
                >
                  <Column
                    className="column"
                    label=""
                    dataKey="id"
                    width={30}
                    flexGrow={1}
                    cellRenderer={renderTrashButton}
                  />
                  <Column
                    className="column"
                    label="Name"
                    dataKey="firstName"
                    width={75}
                    flexGrow={10}
                  />
                  <Column
                    className="column"
                    label="Email"
                    dataKey="email"
                    width={125}
                    flexGrow={20}
                  />
                </CustomTable>
              )}
            </AutoSizer>
          </ListContainer>
          <Separator>
            <Typography variant="h5">{`Contact CC Emails`}</Typography>
            <Link to={`${path}/contact/add-cc`}>
              <IconButton onClick={openAddInvoiceCCEmail}>
                <AddIcon />
              </IconButton>
            </Link>
          </Separator>
          <AddInvoiceCCEmail
            errors={state.contactValuesErrors}
            values={state.contactValues}
            add={createInvoiceCCEmail}
            handleClose={onSuccess}
            loading={props.loadingCreateInvoiceEmail}
            open={state.openAddInvoiceCCEmail}
            onChange={onChange}
          />
          <ListContainer>
            <AutoSizer>
              {({ width, height }) => (
                <CustomTable
                  headerClassName="headerRow"
                  rowClassName="row"
                  headerHeight={50}
                  height={height}
                  noRowsRenderer={noRows}
                  rowCount={props.selectedProject.invoiceEmailCcs.length}
                  rowHeight={52}
                  rowGetter={rowGetterCcs}
                  width={width}
                >
                  <Column
                    className="column"
                    label=""
                    dataKey="id"
                    width={30}
                    flexGrow={1}
                    cellRenderer={renderTrashButton}
                  />
                  <Column
                    className="column"
                    label="Name"
                    dataKey="firstName"
                    width={75}
                    flexGrow={10}
                  />
                  <Column
                    className="column"
                    label="Email"
                    dataKey="email"
                    width={125}
                    flexGrow={20}
                  />
                </CustomTable>
              )}
            </AutoSizer>
          </ListContainer>
        </React.Fragment>
      ) : (
        <Typography style={{ marginTop: 10 }} variant="h5">
          {messages.NO_PROJECT_SELECTED}
        </Typography>
      )}
    </Container>
  );
};

export default ProjectsDetailContact;
