import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { CirclePicker } from 'react-color';
import messages from 'src/messages';
import { Container, CustomButton } from './styles';
import { FunctionComponent, useContext } from 'react';
import { CLIENT_COLORS } from 'src/constants';
import { AppContext } from 'src/contexts/app-context';

interface IClientAdd {
  values: any;
  loading: boolean;
  errors: any;
  onChange: (props: string) => (event: any) => void;
  onSave: () => void;
  onCancel: () => void;
}

export const ClientAdd: FunctionComponent<IClientAdd> = props => {
  const context = useContext(AppContext);
  const colorChange = (value: any) => {
    props.onChange('color')({
      target: {
        value: value.hex
      }
    });
  };

  return (
    <Container>
      <h3>{messages.ADD_CLIENT}</h3>
      <TextField
        required={true}
        error={props.errors.name}
        id="name-add-input"
        label={messages.LABELS.NAME}
        helperText={props.errors.name ? messages.REQUIRED : ''}
        value={props.values.name}
        onChange={props.onChange('name')}
        margin="normal"
        fullWidth={true}
        variant="filled"
      />
      {context.isAuthenticatedQuickBooksOnline && (
        <FormControl fullWidth>
          <FormControlLabel
            label="Create a Customer in Quickbooks"
            control={
              <Checkbox
                name="pending-project-check"
                checked={props.values.isCreatingQbCustomer}
                onChange={(_, checked: boolean) =>
                  props.onChange('isCreatingQbCustomer')(checked)
                }
              />
            }
          />
        </FormControl>
      )}
      <TextField
        required={true}
        id="code-add-input"
        error={props.errors.code}
        label={messages.LABELS.CODE}
        value={props.values.code}
        helperText={props.errors.code ? messages.REQUIRED : ''}
        onChange={props.onChange('code')}
        margin="normal"
        fullWidth={true}
        variant="filled"
      />
      <Typography variant="subtitle1" align="left" gutterBottom={true}>
        Color
      </Typography>
      <CirclePicker
        color={props.values.color}
        colors={CLIENT_COLORS}
        onChange={colorChange}
      />
      {props.loading ? (
        <CircularProgress />
      ) : (
        <React.Fragment>
          <CustomButton
            fullWidth={true}
            color="primary"
            onClick={props.onSave}
            variant="contained"
          >
            {messages.SAVE}
          </CustomButton>
          <CustomButton
            fullWidth={true}
            variant="contained"
            onClick={props.onCancel}
          >
            {messages.CANCEL}
          </CustomButton>
        </React.Fragment>
      )}
    </Container>
  );
};

export default ClientAdd;
