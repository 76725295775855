import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Grid } from '@mui/material';
import { PartialDividerMarginBottom16 } from 'src/components/shared/RightSidebar/RightSidebarInfoPane/styles';
import {
  Container,
  Header,
  GridItem,
  HeaderGridItem,
  UnderlinedGridItem
} from './styles';
import { formatAsMMDDYYYY } from 'src/shared/utils';
import { IInvoice } from 'src/shared/invoice-list/types';
import { Invoice } from 'src/api/types';

export const OutstandingInvoices = ({
  showMoreInvoices,
  invoices,
  invoice,
  totalInvoices,
  openInvoiceDetail
}: {
  showMoreInvoices: () => void;
  invoices: IInvoice[];
  invoice: IInvoice | Invoice;
  totalInvoices: number;
  openInvoiceDetail: (id: number) => void;
}) => {
  const now = moment();
  return (
    <Container>
      <Header>
        {invoice.clientName} Outstanding Invoices ({totalInvoices})
      </Header>
      <Grid container>
        <HeaderGridItem item xs={3}>
          Project
        </HeaderGridItem>
        <HeaderGridItem item xs={3}>
          Due Date
        </HeaderGridItem>
        <HeaderGridItem item xs={6}>
          Status
        </HeaderGridItem>
        <PartialDividerMarginBottom16 />
        {_.map(invoices, (i: IInvoice, idx: number) => {
          const daysDiff = i.sentDate ? now.diff(i.sentDate, 'days') : '--';

          let color = '#212121';
          if (daysDiff > 30) {
            color = '#FA6400';
          }
          if (daysDiff > 60) {
            color = '#E02020';
          }
          return (
            <React.Fragment key={i.id + '-' + idx}>
              <UnderlinedGridItem
                item
                xs={3}
                onClick={() => openInvoiceDetail(i.id)}
              >
                {i.projectName}:
              </UnderlinedGridItem>

              <GridItem item xs={3}>
                {i.sentDate ? formatAsMMDDYYYY(i.sentDate) : '--'}
              </GridItem>

              <GridItem item xs={6} style={{ color }}>
                {i.status} {daysDiff} days ago
              </GridItem>
            </React.Fragment>
          );
        })}
        {totalInvoices > invoices.length && (
          <UnderlinedGridItem item xs={3} onClick={showMoreInvoices}>
            Show more
          </UnderlinedGridItem>
        )}
      </Grid>
    </Container>
  );
};
