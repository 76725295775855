import * as React from 'react';
import ProjectsListContainer from 'src/containers/ProjectsListContainer';
import { GRIO_ORG_ID } from 'src/constants';
import { FunctionComponent } from 'react';

export const InHouseProjectsListContainer: FunctionComponent = () => (
  <ProjectsListContainer
    route={'inhouse-projects'}
    client={GRIO_ORG_ID}
    selectedManager={null}
    isInHouse={true}
  />
);

export default InHouseProjectsListContainer;
