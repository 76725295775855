import React, { FunctionComponent, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { getAveragePercentage, formatDatePeriod } from 'src/shared/utils';
import {
  ColumnFlexBoxWithBottomBorder,
  FlexBox,
  KeyColumn,
  ValueColumn,
  HighlightedValueColumn,
  SectionHeader,
  ColumnFlexBoxWithFullBottomBorder,
  SectionHeaderNoBottomPadding
} from 'src/components/shared/RightSidebar/RightSidebarInfoPane/styles';
import { HeaderContainer, NextIcon, PrevIcon, NoData } from './styles';
import TimesheetUtilizationChart from 'src/components/TimesheetUtilizationChart';
import TeamList from 'src/components/shared/RightSidebar/RightSidebarInfoPane/TeamList';
import {
  SIDEBAR_CHART_HEIGHT,
  SIDEBAR_CHART_WIDTH
} from 'src/components/shared/RightSidebar/RightSidebarInfoPane/constants';
import { Timesheet, TimesheetOwner } from 'src/api/types';
interface ITeamDetailsPane {
  owner: TimesheetOwner;
  onClickNextTeamTimesheets?: () => void;
  onClickPrevTeamTimesheets?: () => void;
  teamTimesheets: Timesheet[];
  teamTimesheetsStartDate?: string;
}

export const TeamDetailsPane: FunctionComponent<ITeamDetailsPane> = props => {
  const getTeamTimesheetDays = (teamTimesheets: Timesheet[]) => {
    return _.reduce(
      teamTimesheets,
      (teamTimesheetRows, teamTimesheet) =>
        _.concat(teamTimesheetRows, teamTimesheet.timesheetDays),
      []
    );
  };

  const getTeamMembers = (teamTimesheets: Timesheet[]) => {
    return _.map(teamTimesheets, function({ owner }) {
      return {
        id: owner.id,
        fullName: owner.fullName
      };
    });
  };

  const hasNext = (date?: string) => {
    if (!date) return false;

    const today = moment();
    const timesheetDate = moment(date);
    return timesheetDate.isBefore(today);
  };

  const { teamTimesheets, teamTimesheetsStartDate, owner } = props;

  const billables = _.map(
    teamTimesheets,
    timesheet => timesheet.totalBillUtilization
  );
  const utilizations = _.map(
    teamTimesheets,
    timesheet => timesheet.totalUtilization
  );

  const teamTimesheetsEndDate = useMemo(() => {
    let result;
    const startDate = moment(teamTimesheetsStartDate);
    const beginningOfMonth = moment(startDate).startOf('month');
    if (startDate.isSame(beginningOfMonth)) {
      result = startDate.add(14, 'day');
    } else {
      result = startDate.endOf('month');
    }
    return result.format('MM/DD/YY');
  }, [teamTimesheetsStartDate]);

  const header = (
    <HeaderContainer>
      <PrevIcon onClick={props.onClickPrevTeamTimesheets} />
      <SectionHeader>
        {teamTimesheetsStartDate &&
          teamTimesheetsEndDate &&
          formatDatePeriod(
            teamTimesheetsStartDate,
            teamTimesheetsEndDate,
            true
          )}
      </SectionHeader>
      {hasNext(teamTimesheetsEndDate) && (
        <NextIcon onClick={props.onClickNextTeamTimesheets} />
      )}
    </HeaderContainer>
  );

  return (
    <React.Fragment>
      {!_.isEmpty(teamTimesheets) && (
        <ColumnFlexBoxWithFullBottomBorder>
          <ColumnFlexBoxWithBottomBorder>
            {header}
            <FlexBox>
              <KeyColumn>Manager</KeyColumn>
              <HighlightedValueColumn to={`/team-management/${owner.id}`}>
                {owner.fullName}
              </HighlightedValueColumn>
            </FlexBox>
            <FlexBox>
              <KeyColumn>Reports</KeyColumn>
              <ValueColumn>{teamTimesheets.length}</ValueColumn>
            </FlexBox>
            <FlexBox>
              <KeyColumn>Billable %</KeyColumn>
              <ValueColumn>{getAveragePercentage(billables)}</ValueColumn>
            </FlexBox>
            <FlexBox>
              <KeyColumn>Utilization %</KeyColumn>
              <ValueColumn>{getAveragePercentage(utilizations)}</ValueColumn>
            </FlexBox>
            <TimesheetUtilizationChart
              rows={getTeamTimesheetDays(teamTimesheets)}
              width={SIDEBAR_CHART_WIDTH}
              height={SIDEBAR_CHART_HEIGHT}
            />
          </ColumnFlexBoxWithBottomBorder>
          <SectionHeaderNoBottomPadding>Team</SectionHeaderNoBottomPadding>
          <TeamList
            users={getTeamMembers(teamTimesheets)}
            projectManagerId={owner.manager.id}
          />
        </ColumnFlexBoxWithFullBottomBorder>
      )}
      {_.isEmpty(teamTimesheets) && (
        <ColumnFlexBoxWithFullBottomBorder>
          {header}
          <NoData>No Data</NoData>
        </ColumnFlexBoxWithFullBottomBorder>
      )}
    </React.Fragment>
  );
};

export default TeamDetailsPane;
