import React from 'react';
import styled, { theme } from 'src/theme';
import Box, { BoxProps } from '@mui/material/Box';

type CustomSubNav = ICustomAppBar & BoxProps;
interface ICustomAppBar {
  drawerClosed: boolean;
}

export const FlexBox = styled(Box)`
  display: flex;
  font-family: ${theme.font};
  justify-content: space-between;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;

export const SubNavWrapper: React.ComponentType<CustomSubNav> = styled(
  ({ drawerClosed, ...rest }) => <FlexBox {...rest} />
)`
  justify-content: space-between;
  position: fixed;
  top: 84px;
  left: 240px;
  right: 0;
  z-index: 1201;
  background-color: #fff;
  height: 90px;
  &.left-drawer-closed {
    left: 0;
  }

  transition: ${props =>
    props.drawerClosed
      ? theme.transitions.create(['left', 'width'], {
          duration: theme.transitions.duration.enteringScreen,
          easing: theme.transitions.easing.easeIn
        })
      : theme.transitions.create(['left', 'width'], {
          duration: theme.transitions.duration.leavingScreen,
          easing: theme.transitions.easing.sharp
        })};
` as React.ComponentType<CustomSubNav>;
