import React from 'react';
import { Panel, Row, ClientIcon } from '../styles';
import TimesheetsUtil from 'src/shared/timesheets/util';
import _ from 'lodash';
import {
  KeyColumn,
  ValueColumn
} from 'src/components/shared/RightSidebar/RightSidebarInfoPane/styles';
import { Title } from '../styles';
import Flex from 'src/components/core/Flex';
import { Timesheet } from 'src/api/types';

interface ITimesheetProjectsPane {
  timesheet: Timesheet;
}

export class TimesheetProjectsPane extends React.Component<
  ITimesheetProjectsPane
> {
  render() {
    const timesheetsUtil = new TimesheetsUtil();
    const projects = timesheetsUtil.hoursByProjectAndRole(
      this.props.timesheet.timesheetDays
    );
    return (
      <Panel>
        {_.map(projects, projectRoleHours => {
          return (
            <Row key={projectRoleHours.project.name}>
              <KeyColumn>
                <Flex alignItems="center">
                  <ClientIcon color={projectRoleHours.client.color} />
                  <Title>
                    <div>
                      <strong>{projectRoleHours.client.code}</strong>
                      &nbsp;
                      <em>{projectRoleHours.project.name}</em>
                    </div>
                    <div>{projectRoleHours.role.name}</div>
                  </Title>
                </Flex>
              </KeyColumn>
              <ValueColumn>{projectRoleHours.hours} h</ValueColumn>
            </Row>
          );
        })}
      </Panel>
    );
  }
}

export default TimesheetProjectsPane;
