import * as _ from 'lodash';

import * as authActions from 'src/shared/auth/actions';
import * as userActions from './actions';
import { IEntityState } from './types';

const initialState = {
  loadingUserAdd: false,
  loadingUserEdit: false,
  loadingUsers: false,
  managers: {},
  projectManagers: {},
  roles: {},
  updateUserCompleted: false,
  users: {},
  usersList: []
};

const reducer = (
  state: IEntityState = initialState,
  action: userActions.Actions | authActions.Actions
) => {
  switch (action.type) {
    case userActions.GET_USERS: {
      return {
        ...state,
        loadingUsers: true
      };
    }
    case userActions.UPDATE_USER: {
      return {
        ...state,
        loadingUserEdit: true
      };
    }
    case userActions.UPDATE_USER_FAILED: {
      return {
        ...state,
        loadingUserEdit: false
      };
    }
    case userActions.ADD_USER: {
      return {
        ...state,
        loadingUserAdd: true
      };
    }
    case userActions.ADD_USER_FAILED: {
      return {
        ...state,
        loadingUserAdd: false
      };
    }
    case userActions.ADD_USER_SUCCEEDED: {
      const { responseUserData, userData } = action.payload;
      return {
        ...state,
        loadingUserAdd: false,
        updateUserCompleted: true,
        users: {
          ...state.users,
          [responseUserData.result]: {
            ...responseUserData.entities.users[responseUserData.result],
            ...{ notificationPreferences: userData.notificationPreferences }
          }
        }
      };
    }
    case userActions.DELETE_USER_SUCCEEDED: {
      const { userId } = action.payload;
      const oldUsers = { ...state.users };
      const filteredUsers = _.reject(oldUsers, ['id', userId]);
      return {
        ...state,
        updateUserCompleted: true,
        users: filteredUsers
      };
    }
    case userActions.GET_MANAGERS_SUCCEEDED: {
      const { userData } = action.payload;
      return {
        ...state,
        managers: userData.entities.managers
      };
    }
    case userActions.GET_PROJECT_MANAGERS_SUCCEEDED: {
      const { userData } = action.payload;
      return {
        ...state,
        projectManagers: userData.entities.managers
      };
    }
    case userActions.GET_ROLES_SUCCEEDED: {
      const { roleData } = action.payload;
      return {
        ...state,
        roles: roleData.entities.roles
      };
    }
    case userActions.GET_USER_HAS_RECORDS_FAILED: {
      const { userId } = action.payload;
      const oldUserData = { ...state.users[userId] };
      return {
        ...state,
        users: {
          ...state.users,
          [userId]: { ...oldUserData, ...{ canBeDeleted: false } }
        }
      };
    }
    case userActions.GET_USER_HAS_RECORDS_SUCCEEDED: {
      const { userData, userId } = action.payload;
      const canBeDeleted = !userData[0];
      const oldUserData = { ...state.users[userId] };
      return {
        ...state,
        users: {
          ...state.users,
          [userId]: { ...oldUserData, ...{ canBeDeleted } }
        }
      };
    }
    case userActions.GET_USER_SUCCEEDED: {
      const { userData, userId } = action.payload;
      if (_.isEmpty(userData.entities)) {
        return state;
      }
      const oldUserData = { ...state.users[userId] };
      return {
        ...state,
        users: {
          ...state.users,
          [userId]: {
            ...oldUserData,
            ...userData.entities.users[userId]
          }
        }
      };
    }
    case userActions.GET_USERS_SUCCEEDED: {
      const { roles, usersList } = action.payload;
      return {
        ...state,
        loadingUsers: false,
        roles: { ...roles },
        usersList: [...usersList]
      };
    }
    case authActions.LOGOUT: {
      return {
        ...state,
        ...initialState
      };
    }
    case userActions.RESET_USER_UPDATE_FLAG: {
      return {
        ...state,
        updateUserCompleted: false
      };
    }
    case userActions.UPDATE_USER_SUCCEEDED: {
      const { responseUserData, userData, userId } = action.payload;
      const oldUserData = { ...state.users[userId] };
      return {
        ...state,
        loadingUserEdit: false,
        updateUserCompleted: true,
        users: {
          ...state.users,
          [userId]: {
            ...oldUserData,
            ...responseUserData.entities.users[responseUserData.result],
            ...{
              notificationPreferences: userData.notificationPreferences
            }
          }
        }
      };
    }
    default:
      return state;
  }
};

export default reducer;
