import styled, { theme } from 'src/theme';
import { Box, Button } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';

export const Title = styled.div`
  color: #212121;
  font-family: ${theme.font};
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 8px;
`;

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  font-family: ${theme.font};
`;

export const ViewBar = styled(Box)`
  align-items: center;
  border-top: ${theme.border};
  display: flex;
  padding-left: 8px;
`;

export const ViewSpan = styled.div`
  color: #212121;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  font-family: ${theme.font};
`;

export const ColumnFlexBox = styled(FlexBox)`
  flex-direction: column;
`;

export const DialogSubTitle = styled(FlexBox)`
  justify-content: space-between;
`;

export const SubTitleCompartment = styled(FlexBox)`
  flex-direction: column;
  border-right: ${theme.border};
  margin: 8px 0;
  padding: 16px;
  align-items: start;

  &:last-child {
    border-right: 0;
  }
`;

export const SubTitleFullBorderCompartment = styled(SubTitleCompartment)`
  margin: 0;
`;

export const CompartmentTitle = styled.div`
  color: #757575;
  font-family: ${theme.font};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 4px;
`;

export const CompartmentValue = styled.div`
  color: #212121;
  font-family: ${theme.font};
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
`;

export const CompartmentOriginalValue = styled.div`
  color: #757575;
  font-family: ${theme.font};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
`;

export const DialogHeader = styled(Box)`
  position: sticky;
  top: 0;
`;

export const DialogTitle = styled(FlexBox)`
  justify-content: space-between;
  align-items: center;
  border-bottom: ${theme.border};
  margin-left: 0;
`;

export const SaveButton = styled(Button)`
  && {
    width: 108px;
    border-radius: 5px;
    background-color: #000000;
    text-transform: none;
    color: #fff;
    font-size: 16px;
  }
  &&:hover {
    background-color: #212121;
  }

  &.MuiButton-root.Mui-disabled {
    background-color: ${theme.primaryBackground};
  }
`;

export const CancelButton = styled(Button)`
  && {
    width: 108px;
    border: 1px solid #000000;
    border-radius: 5px;
    background-color: #ffffff;
    margin: 0 16px;
    text-transform: none;
    font-size: 16px;
  }
`;

export const HourViewButton = styled(Button)`
  && {
    padding: 8px;
    color: #212121;
    font-family: ${theme.font};
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    text-transform: none;
    white-space: nowrap;
    margin: 8px;
  }
  &&.highlighted {
    background-color: #eeeeee;
  }
`;

export const FullBorderLeftFlexBox = styled(FlexBox)`
  border-left: ${theme.border};
  padding: 24px;
`;

export const PaddedFlexBox = styled(FlexBox)`
  padding: 16px;
`;

export const StyledDialogContent = styled(DialogContent)`
  && {
    padding: 0;
  }
`;

export const EditableCompartment = styled(Box)`
  display: flex;
`;
