import MenuItem from '@mui/material/MenuItem';
import DeleteSearch from '@mui/icons-material/Close';
import * as React from 'react';
import messages from 'src/messages';
import { Container, CustomFab, CustomInput, InnerContainer } from './styles';

interface IClientListFilters {
  clearFilters: () => void;
  roles?: any;
  name?: string | number;
  noFilter?: boolean;
  selectedRole?: string | number;
  status?: string | number;
  onChange: (prop: string) => (event: any) => void;
}

const ClientListFilters: React.FC<IClientListFilters> = ({
  clearFilters,
  noFilter = true,
  status = 'any',
  name = '',
  onChange
}) => (
  <Container>
    <InnerContainer>
      <CustomInput
        id="status-select"
        select={true}
        label="Display"
        value={status}
        onChange={onChange('status')}
        margin="normal"
        variant="filled"
      >
        <MenuItem key={'any'} value={'any'}>
          {messages.ANY}
        </MenuItem>
        <MenuItem key={'active'} value={0}>
          {messages.ACTIVE}
        </MenuItem>
        <MenuItem key={'disabled'} value={1}>
          {messages.DISABLED}
        </MenuItem>
      </CustomInput>
      <CustomInput
        id="name-input"
        onChange={onChange('name')}
        label="Name"
        value={name}
        margin="normal"
        variant="filled"
      />
      <CustomFab
        onClick={clearFilters}
        disabled={noFilter}
        color="secondary"
        size="small"
        aria-label="Delete search"
      >
        <DeleteSearch />
      </CustomFab>
    </InnerContainer>
  </Container>
);

export default ClientListFilters;
