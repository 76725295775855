import styled, { theme } from 'src/theme';
import { ValueColumn } from 'src/components/shared/RightSidebar/RightSidebarInfoPane/styles';
import { StaticTextField } from 'src/components/InvoiceList/InvoiceListSidebar/InformationPane/InvoiceNotes/styles';
import Launch from '@mui/icons-material/Launch';

import { Box, Typography } from '@mui/material';

export const RejectionColumn = styled(ValueColumn)`
  padding-right: 0;
`;

export const RejectionText = styled(StaticTextField)`
  margin: 0;
`;

export const DetailHeader = styled(Typography)`
  && {
    font-family: ${theme.font}
    font-size: 28px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: left;
    padding: 16px 0;
    color: #212121;
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${theme.teal};
  .MuiSvgIcon-root {
    fill: ${theme.teal};
  }
`;

export const LaunchIcon = styled(Launch)`
  && {
    margin-left: 10px;
    padding-right: 0;
  }
`;

export const ViewTimesheet = styled(Box)`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;
