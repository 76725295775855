import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { roundToQuarterHour } from 'src/shared/utils';
import { TimesheetEntryClassification } from '../types';

export type CreateTimesheetEntryParams = {
  hours: number;
  description?: string;
  dates: string[]; // MM/DD/YY
  classification: TimesheetEntryClassification;
  deliverable?: number;
  projectRole?: number;
  startDate?: string; // MM/DD/YY (start date of user)
  timesheetId: number;
  projectId?: number;
};

export const createTimesheetEntry = (params: CreateTimesheetEntryParams) =>
  callAPI({
    url: '/timesheet_entries',
    method: 'post',
    data: {
      entry: {
        ...params,
        hours: roundToQuarterHour(params.hours)
      }
    }
  })
    .then(response => response.data.entries)
    .catch(error => {
      toast('Failed to create TimesheetEntry', 'error');
      throw error;
    });
