import Launch from '@mui/icons-material/Launch';
import { Box } from '@mui/material';
import styled from 'src/theme';
import { SectionPrimaryHeader } from 'src/components/shared/RightSidebar/RightSidebarInfoPane/styles';

export const MarginLeft12Launch = styled(Launch)`
  margin-left: 12px;
  height: 18px;
  width: 18px;
`;

export const OpenInvoiceDetailContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
  margin: 16px 16px 0 16px;
`;

export const OpenInvoiceDetailContainerHeader = styled(SectionPrimaryHeader)`
  && {
    padding-left: 0;
  }
`;
