import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { Invoice } from '../types';

export const fetchInvoice = (id: string | number) =>
  callAPI<Invoice>({
    method: 'get',
    url: `/invoices/${id}`,
    reqHeaders: {
      Accept: 'application/json'
    }
  })
    .then(response => response.data)
    .catch(error => {
      toast('Failed to fetch invoice', 'error');
      throw error;
    });
