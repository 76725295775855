import {
  notificationResourceTypes,
  OVERDUE_TIMESHEET_APPROVAL
} from 'src/constants';
import { toast } from 'src/services/toast';
import { ICurrentUser } from 'src/shared/auth/types';
import { Api } from '../api';

export const sendOverdueTimesheetNotification = async (
  currentUser: ICurrentUser,
  managerId: number,
  timesheetId: number
) => {
  try {
    const notificationType = await Api.fetchNotificationType({
      notificationCategory: OVERDUE_TIMESHEET_APPROVAL
    });
    const notificationTypeId = notificationType.data.id;
    const data = {
      userId: managerId,
      resourceId: timesheetId,
      resourceType: notificationResourceTypes.TIMESHEET,
      notificationTypeId
    };
    const newNotificationReq = await Api.createNotification(data);
    const newNotification = newNotificationReq.data.notification;
    const sendEmailReq = await Api.sendNotificationEmail({
      id: newNotification.id,
      sender: currentUser.id,
      override: true
    });
    if (sendEmailReq.status === 200) {
      const sendEmailData = sendEmailReq.data;
      toast(`${sendEmailData.message}`, 'success');
    }
  } catch (e) {
    toast(
      `An error has occurred while contacting project manager:
        ${e}
        Please try later again.`,
      'error'
    );
    throw e;
  }
};
