import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';

export const fetchCurrentTimesheetId = () =>
  callAPI<number>({
    url: '/timesheets/current/id',
    method: 'get'
  })
    .then(response => response.data)
    .catch(error => {
      toast('Failed to fetch current timesheet id', 'error');
      throw error;
    });
