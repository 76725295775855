import * as React from 'react';
import InvoiceDetailAppBar from 'src/components/InvoiceDetailAppBar';
import { connect } from 'react-redux';
import { IAppState } from 'src/reducer';
import { IInvoiceDetailView } from 'src/shared/invoice/types';
import { FunctionComponent } from 'react';

export interface IInvoiceDetailAppBarContainerStateProps {
  view?: IInvoiceDetailView;
}
type IInvoiceDetailAppBarContainerProps = IInvoiceDetailAppBarContainerStateProps;

export const InvoiceDetailAppBarContainer: FunctionComponent<IInvoiceDetailAppBarContainerProps> = props => (
  <InvoiceDetailAppBar view={props.view} />
);

export const mapStateToProps = (state: IAppState) => ({
  view: state.invoice.localView
});

export default connect<IInvoiceDetailAppBarContainerStateProps>(
  mapStateToProps
)(InvoiceDetailAppBarContainer);
