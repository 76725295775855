import styled, { theme } from 'src/theme';
import {
  SectionPrimaryHeader,
  ColumnFlexBoxWithTopBorder
} from 'src/components/shared/RightSidebar/RightSidebarInfoPane/styles';
import { Row, Value } from '../styles';

export const SectionHeader = styled(SectionPrimaryHeader)`
  && {
    padding: 12px 16px;
  }
`;

export const TableRow = styled(Row)`
  && {
    font-family: 'Source Sans Pro', sans-serif !important;
    width: 100%;
    padding: 8px 18px;
  }
`;

export const NoPaddingValue = styled(Value)`
  && {
    padding: 0;
  }
`;

export const HeaderValue = styled(Value)`
  && {
    color: ${theme.black};
  }
`;

export const HighlightedValue = styled.div`
  && {
    display: flex;
    flex-direction: column;
    font-family: ${theme.font};
    width: 67%;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    color: #00838f;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
  }
`;

export const ColumnFlexBoxWithTopPaddedBorder = styled(
  ColumnFlexBoxWithTopBorder
)`
  && {
    margin: 0 16px;
    padding: 0;
  }
`;

export const SeeMore = styled(TableRow)`
  color: #00838f;
  cursor: pointer;
`;
