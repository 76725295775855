import AppBar, { AppBarProps } from '@mui/material/AppBar';
import * as React from 'react';
import styled, { theme } from '../../theme';
import { Link } from 'react-router-dom';

type TCustomAppBar = ICustomAppBar & AppBarProps;
interface ICustomAppBar {
  drawerOpened: boolean;
}

export const CustomAppBar: React.ComponentType<TCustomAppBar> = styled(
  ({ drawerOpened, ...rest }) => <AppBar {...rest} />
)`
  margin-left: ${props => (props.drawerOpened ? theme.drawerWidthLeft : '0')};
  padding: 12px 24px;

  && {
    background-color: ${theme.secondaryBackground};
    color: ${theme.primaryColor};
    box-shadow: none;
    border-bottom: ${theme.border};
    z-index: 1201;
    transition: ${props =>
      props.drawerOpened
        ? theme.transitions.create(['margin', 'width'], {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.easeOut
          })
        : theme.transitions.create(['margin', 'width'], {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp
          })};
    @media only screen and (min-width: ${theme.breakpointsSelf.md}) {
      width: ${props =>
        props.drawerOpened ? `calc(100% - ${theme.drawerWidthLeft})` : '100%'};
    }
  }
` as any;

export const PageTitle = styled.h3`
  color: ${theme.primaryColor};
  font-size: 24px;
  font-weight: 200;
  margin: 0;
`;

export const SeparatorLeft = styled.div`
  width: 0px;
  height: 48px;
`;

export const CustomLink = styled(Link)`
  align-items: center;
  color: #00838f;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  text-decoration: none;

  .appBarTitle {
    margin-left: 8px;
  }
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FlexBoxGrow = styled.div`
  display: flex;
  align-items: center;
`;
