import { IAppState } from 'src/reducer';

export const getInvoiceListFilters = (state: IAppState) =>
  state.invoices.filters;

export const currentSelectedInvoice = (state: IAppState) =>
  state.invoices.selectedInvoice;

export const currentSidebarInvoices = (state: IAppState) =>
  state.invoices.invoiceSidebarDetail.invoices;

export const totalInvoices = (state: IAppState) =>
  state.invoices.invoiceSidebarDetail.totalInvoices;
