import React from 'react';
import { AutoSizer, Column, TableCellProps } from 'react-virtualized';
import AddProjects from 'src/components/MyProjects/AddProjects';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { userProjectRolesQuery } from 'src/queries/projects';
import { QueryKeys } from 'src/queries/queryKeys';
import {
  getClient,
  getDeliverable,
  getProject,
  getProjectCode,
  getRole
} from './getters';
import { IUpdateVisibilityParams } from './types';
import {
  Container,
  CircularProgressStyled,
  CustomTable,
  TableContainer,
  UserProjectRoleAddContainer
} from './styles';
import Icon from 'src/components/core/Icon';
import Checkbox from 'src/components/core/Checkbox';
import Tooltip from 'src/components/core/Tooltip';
import { toast } from 'src/services/toast';
import { UserProjectRole } from 'src/api/types';
import { Api } from 'src/api/api';

/**
 * In the application, this is the "My Projects" page
 */
const MyProjects = (): JSX.Element => {
  const queryClient = useQueryClient();

  const { isLoading, error, data: projectRoles = [] } = useQuery<
    UserProjectRole[],
    Error
  >(QueryKeys.userProjectRoles, userProjectRolesQuery);

  const updateRoleMutation = useMutation<
    UserProjectRole,
    Error,
    IUpdateVisibilityParams
  >(async ({ roleId, isVisible }) => {
    const result = await Api.updateUserProjectRole({ id: roleId, isVisible });
    if (result) {
      toast('Project visibility was successfully updated', 'success');
      queryClient.setQueryData<UserProjectRole[]>(
        QueryKeys.userProjectRoles,
        old => {
          if (!old) return [];
          return old.map(role =>
            role.id === roleId ? { ...role, isVisible } : role
          );
        }
      );
    } else {
      toast('Unable to update project', 'error');
    }
    return result;
  });

  const deleteRoleMutation = useMutation(async (roleId: number) => {
    const result = await Api.deleteUserProjectRole(roleId);
    if (result) {
      toast('Project was successfully removed', 'success');
      queryClient.setQueryData<UserProjectRole[]>('userProjectRoles', old =>
        old ? old.filter(role => role.id !== roleId) : []
      );
    } else {
      toast('Unable to remove project', 'error');
    }
  });

  if (error) {
    return <div>{`An error has occurred: ${error.message}`}</div>;
  }

  const renderProjectRoleVisibility = (event: TableCellProps) => {
    return (
      <Checkbox
        name="project-role-visibility-check"
        value={event.cellData}
        checked={event.cellData}
        onClick={() =>
          updateRoleMutation.mutate({
            roleId: event.rowData.id,
            isVisible: !event.cellData
          })
        }
      />
    );
  };

  const renderRemoveButton = (event: TableCellProps) => {
    return (
      <Tooltip title="Remove">
        <Icon
          name="RemoveCircle"
          color="error"
          className="removeButton"
          onClick={() => deleteRoleMutation.mutate(event.rowData.id)}
        />
      </Tooltip>
    );
  };

  const commonColumnProps = {
    className: 'column',
    flexGrow: 1,
    width: 1
  };

  return (
    <>
      <UserProjectRoleAddContainer>
        <AddProjects />
      </UserProjectRoleAddContainer>
      <Container>
        {isLoading ? (
          <CircularProgressStyled />
        ) : (
          <TableContainer>
            <AutoSizer>
              {({ height, width }) => (
                <CustomTable
                  data={projectRoles}
                  headerClassName="headerRow"
                  headerHeight={50}
                  height={height}
                  rowCount={projectRoles.length}
                  rowClassName="row"
                  rowGetter={({ index }) => projectRoles[index]}
                  rowHeight={60}
                  width={width}
                >
                  <Column
                    {...commonColumnProps}
                    cellRenderer={renderProjectRoleVisibility}
                    dataKey="isVisible"
                    flexGrow={2}
                    label="Visibility"
                  />
                  <Column
                    {...commonColumnProps}
                    cellDataGetter={getClient}
                    dataKey="client"
                    label={'Client'}
                  />
                  <Column
                    {...commonColumnProps}
                    cellDataGetter={getProject}
                    dataKey="project"
                    label="Project Description"
                  />
                  <Column
                    {...commonColumnProps}
                    cellDataGetter={getDeliverable}
                    dataKey="deliverable"
                    label="Deliverable"
                  />
                  <Column
                    {...commonColumnProps}
                    cellDataGetter={getRole}
                    dataKey="role"
                    label="Role"
                  />
                  <Column
                    {...commonColumnProps}
                    cellDataGetter={getProjectCode}
                    dataKey="projectCode"
                    label="Project Code"
                  />
                  <Column
                    {...commonColumnProps}
                    cellRenderer={renderRemoveButton}
                    dataKey="removeProject"
                    flexGrow={1}
                  />
                </CustomTable>
              )}
            </AutoSizer>
          </TableContainer>
        )}
      </Container>
    </>
  );
};

export default MyProjects;
