import React from 'react';
import MoreVert from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';

export const MoreVertMenu = ({
  event,
  openEdit,
  deleteDeliverable
}: {
  event: any;
  openEdit: (event: any) => void;
  deleteDeliverable: (event: any) => void;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);

  const openMenu = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const menu = (
    <React.Fragment>
      <MoreVert onClick={openMenu} key="menu-anchor" />
      <Menu
        key="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem
          onClick={() => {
            closeMenu();
            openEdit(event);
          }}
          key="edit"
        >
          Edit
        </MenuItem>
        <MenuItem
          key="dismiss"
          onClick={() => {
            closeMenu();
            deleteDeliverable(event);
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
  return menu;
};

export default MoreVertMenu;
