import { ActionCreatorsMapObject } from 'redux';

export interface IAction<T extends string> {
  type: T;
}

export interface IPayloadAction<T extends string, P> extends IAction<T> {
  payload: P;
}

// supports all actions with type and optional payload
export function createAction<T extends string>(type: T): IAction<T>;
export function createAction<T extends string, P>(
  type: T,
  payload: P
): IPayloadAction<T, P>;
export function createAction<T extends string, P>(type: T, payload?: P) {
  return payload === undefined ? { type } : { type, payload };
}

export type IListData = any[];

export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<
  A[keyof A]
>;

export type IconType =
  | 'Abc'
  | 'AccessAlarm'
  | 'AccessAlarms'
  | 'Accessibility'
  | 'AccessibilityNew'
  | 'Accessible'
  | 'AccessibleForward'
  | 'AccessTime'
  | 'AccountBalance'
  | 'AccountBalanceWallet'
  | 'AccountBox'
  | 'AccountCircle'
  | 'AccountTree'
  | 'AcUnit'
  | 'Adb'
  | 'Add'
  | 'AddAlarm'
  | 'AddAlert'
  | 'AddAPhoto'
  | 'AddBox'
  | 'AddBusiness'
  | 'AddCard'
  | 'Addchart'
  | 'AddCircle'
  | 'AddCircleOutline'
  | 'AddComment'
  | 'AddIcCall'
  | 'AddLink'
  | 'AddLocation'
  | 'AddLocationAlt'
  | 'AddModerator'
  | 'AddPhotoAlternate'
  | 'AddReaction'
  | 'AddRoad'
  | 'AddShoppingCart'
  | 'AddTask'
  | 'AddToDrive'
  | 'AddToHomeScreen'
  | 'AddToPhotos'
  | 'AddToQueue'
  | 'AdfScanner'
  | 'Adjust'
  | 'AdminPanelSettings'
  | 'AdUnits'
  | 'Agriculture'
  | 'Air'
  | 'Airlines'
  | 'AirlineSeatFlat'
  | 'AirlineSeatFlatAngled'
  | 'AirlineSeatIndividualSuite'
  | 'AirlineSeatLegroomExtra'
  | 'AirlineSeatLegroomNormal'
  | 'AirlineSeatLegroomReduced'
  | 'AirlineSeatReclineExtra'
  | 'AirlineSeatReclineNormal'
  | 'AirlineStops'
  | 'AirplanemodeActive'
  | 'AirplanemodeInactive'
  | 'AirplaneTicket'
  | 'Airplay'
  | 'AirportShuttle'
  | 'Alarm'
  | 'AlarmAdd'
  | 'AlarmOff'
  | 'AlarmOn'
  | 'Album'
  | 'AlignHorizontalCenter'
  | 'AlignHorizontalLeft'
  | 'AlignHorizontalRight'
  | 'AlignVerticalBottom'
  | 'AlignVerticalCenter'
  | 'AlignVerticalTop'
  | 'AllInbox'
  | 'AllInclusive'
  | 'AllOut'
  | 'AlternateEmail'
  | 'AltRoute'
  | 'Analytics'
  | 'Anchor'
  | 'Android'
  | 'Animation'
  | 'Announcement'
  | 'Aod'
  | 'Apartment'
  | 'Api'
  | 'AppBlocking'
  | 'Apple'
  | 'AppRegistration'
  | 'Approval'
  | 'Apps'
  | 'AppSettingsAlt'
  | 'AppShortcut'
  | 'AppsOutage'
  | 'Architecture'
  | 'Archive'
  | 'ArrowBack'
  | 'ArrowBackIos'
  | 'ArrowBackIosNew'
  | 'ArrowCircleDown'
  | 'ArrowCircleLeft'
  | 'ArrowCircleRight'
  | 'ArrowCircleUp'
  | 'ArrowDownward'
  | 'ArrowDropDown'
  | 'ArrowDropDownCircle'
  | 'ArrowDropUp'
  | 'ArrowForward'
  | 'ArrowForwardIos'
  | 'ArrowLeft'
  | 'ArrowRight'
  | 'ArrowRightAlt'
  | 'ArrowUpward'
  | 'Article'
  | 'ArtTrack'
  | 'AspectRatio'
  | 'Assessment'
  | 'Assignment'
  | 'AssignmentInd'
  | 'AssignmentLate'
  | 'AssignmentReturn'
  | 'AssignmentReturned'
  | 'AssignmentTurnedIn'
  | 'Assistant'
  | 'AssistantDirection'
  | 'AssistantPhoto'
  | 'AssuredWorkload'
  | 'Atm'
  | 'AttachEmail'
  | 'AttachFile'
  | 'Attachment'
  | 'AttachMoney'
  | 'Attractions'
  | 'Attribution'
  | 'AudioFile'
  | 'Audiotrack'
  | 'AutoAwesome'
  | 'AutoAwesomeMosaic'
  | 'AutoAwesomeMotion'
  | 'AutoDelete'
  | 'AutoFixHigh'
  | 'AutoFixNormal'
  | 'AutoFixOff'
  | 'AutofpsSelect'
  | 'AutoGraph'
  | 'AutoMode'
  | 'Autorenew'
  | 'AutoStories'
  | 'AvTimer'
  | 'BabyChangingStation'
  | 'Backpack'
  | 'Backspace'
  | 'Backup'
  | 'BackupTable'
  | 'Badge'
  | 'BakeryDining'
  | 'Balance'
  | 'Balcony'
  | 'Ballot'
  | 'BarChart'
  | 'BatchPrediction'
  | 'Bathroom'
  | 'Bathtub'
  | 'Battery0Bar'
  | 'Battery1Bar'
  | 'Battery20'
  | 'Battery2Bar'
  | 'Battery30'
  | 'Battery3Bar'
  | 'Battery4Bar'
  | 'Battery50'
  | 'Battery5Bar'
  | 'Battery60'
  | 'Battery6Bar'
  | 'Battery80'
  | 'Battery90'
  | 'BatteryAlert'
  | 'BatteryCharging20'
  | 'BatteryCharging30'
  | 'BatteryCharging50'
  | 'BatteryCharging60'
  | 'BatteryCharging80'
  | 'BatteryCharging90'
  | 'BatteryChargingFull'
  | 'BatteryFull'
  | 'BatterySaver'
  | 'BatteryStd'
  | 'BatteryUnknown'
  | 'BeachAccess'
  | 'Bed'
  | 'BedroomBaby'
  | 'BedroomChild'
  | 'BedroomParent'
  | 'Bedtime'
  | 'BedtimeOff'
  | 'Beenhere'
  | 'Bento'
  | 'BikeScooter'
  | 'Biotech'
  | 'Blender'
  | 'BlindsClosed'
  | 'Block'
  | 'Bloodtype'
  | 'Bluetooth'
  | 'BluetoothAudio'
  | 'BluetoothConnected'
  | 'BluetoothDisabled'
  | 'BluetoothDrive'
  | 'BluetoothSearching'
  | 'BlurCircular'
  | 'BlurLinear'
  | 'BlurOff'
  | 'BlurOn'
  | 'Bolt'
  | 'Book'
  | 'Bookmark'
  | 'BookmarkAdd'
  | 'BookmarkAdded'
  | 'BookmarkBorder'
  | 'BookmarkRemove'
  | 'Bookmarks'
  | 'BookOnline'
  | 'BorderAll'
  | 'BorderBottom'
  | 'BorderClear'
  | 'BorderColor'
  | 'BorderHorizontal'
  | 'BorderInner'
  | 'BorderLeft'
  | 'BorderOuter'
  | 'BorderRight'
  | 'BorderStyle'
  | 'BorderTop'
  | 'BorderVertical'
  | 'Boy'
  | 'BrandingWatermark'
  | 'BreakfastDining'
  | 'Brightness1'
  | 'Brightness2'
  | 'Brightness3'
  | 'Brightness4'
  | 'Brightness5'
  | 'Brightness6'
  | 'Brightness7'
  | 'BrightnessAuto'
  | 'BrightnessHigh'
  | 'BrightnessLow'
  | 'BrightnessMedium'
  | 'BrokenImage'
  | 'BrowseGallery'
  | 'BrowserNotSupported'
  | 'BrowserUpdated'
  | 'BrunchDining'
  | 'Brush'
  | 'BubbleChart'
  | 'BugReport'
  | 'Build'
  | 'BuildCircle'
  | 'Bungalow'
  | 'BurstMode'
  | 'BusAlert'
  | 'Business'
  | 'BusinessCenter'
  | 'Cabin'
  | 'Cable'
  | 'Cached'
  | 'Cake'
  | 'Calculate'
  | 'CalendarMonth'
  | 'CalendarToday'
  | 'CalendarViewDay'
  | 'CalendarViewMonth'
  | 'CalendarViewWeek'
  | 'Call'
  | 'CallEnd'
  | 'CallMade'
  | 'CallMerge'
  | 'CallMissed'
  | 'CallMissedOutgoing'
  | 'CallReceived'
  | 'CallSplit'
  | 'CallToAction'
  | 'Camera'
  | 'CameraAlt'
  | 'CameraEnhance'
  | 'CameraFront'
  | 'CameraIndoor'
  | 'CameraOutdoor'
  | 'CameraRear'
  | 'CameraRoll'
  | 'Cameraswitch'
  | 'Campaign'
  | 'Cancel'
  | 'CancelPresentation'
  | 'CancelScheduleSend'
  | 'CandlestickChart'
  | 'CarCrash'
  | 'CardGiftcard'
  | 'CardMembership'
  | 'CardTravel'
  | 'Carpenter'
  | 'CarRental'
  | 'CarRepair'
  | 'Cases'
  | 'Casino'
  | 'Cast'
  | 'CastConnected'
  | 'CastForEducation'
  | 'Castle'
  | 'CatchingPokemon'
  | 'Category'
  | 'Celebration'
  | 'CellTower'
  | 'CellWifi'
  | 'CenterFocusStrong'
  | 'CenterFocusWeak'
  | 'Chair'
  | 'ChairAlt'
  | 'Chalet'
  | 'ChangeCircle'
  | 'ChangeHistory'
  | 'ChargingStation'
  | 'Chat'
  | 'ChatBubble'
  | 'ChatBubbleOutline'
  | 'Check'
  | 'CheckBox'
  | 'CheckBoxOutlineBlank'
  | 'CheckCircle'
  | 'CheckCircleOutline'
  | 'Checkroom'
  | 'ChevronLeft'
  | 'ChevronRight'
  | 'ChildCare'
  | 'ChildFriendly'
  | 'ChromeReaderMode'
  | 'Church'
  | 'Circle'
  | 'CircleNotifications'
  | 'Class'
  | 'CleanHands'
  | 'CleaningServices'
  | 'Clear'
  | 'ClearAll'
  | 'Close'
  | 'ClosedCaption'
  | 'ClosedCaptionDisabled'
  | 'ClosedCaptionOff'
  | 'CloseFullscreen'
  | 'Cloud'
  | 'CloudCircle'
  | 'CloudDone'
  | 'CloudDownload'
  | 'CloudOff'
  | 'CloudQueue'
  | 'CloudSync'
  | 'CloudUpload'
  | 'Co2'
  | 'Code'
  | 'CodeOff'
  | 'Coffee'
  | 'CoffeeMaker'
  | 'Collections'
  | 'CollectionsBookmark'
  | 'Colorize'
  | 'ColorLens'
  | 'Comment'
  | 'CommentBank'
  | 'CommentsDisabled'
  | 'Commit'
  | 'Commute'
  | 'Compare'
  | 'CompareArrows'
  | 'CompassCalibration'
  | 'Compress'
  | 'Computer'
  | 'ConfirmationNumber'
  | 'ConnectedTv'
  | 'ConnectingAirports'
  | 'ConnectWithoutContact'
  | 'Construction'
  | 'Contactless'
  | 'ContactMail'
  | 'ContactPage'
  | 'ContactPhone'
  | 'Contacts'
  | 'ContactSupport'
  | 'ContentCopy'
  | 'ContentCut'
  | 'ContentPaste'
  | 'ContentPasteGo'
  | 'ContentPasteOff'
  | 'ContentPasteSearch'
  | 'Contrast'
  | 'ControlCamera'
  | 'ControlPoint'
  | 'ControlPointDuplicate'
  | 'Cookie'
  | 'CoPresent'
  | 'CopyAll'
  | 'Copyright'
  | 'Coronavirus'
  | 'CorporateFare'
  | 'Cottage'
  | 'Countertops'
  | 'Create'
  | 'CreateNewFolder'
  | 'CreditCard'
  | 'CreditCardOff'
  | 'CreditScore'
  | 'Crib'
  | 'CrisisAlert'
  | 'Crop'
  | 'Crop169'
  | 'Crop32'
  | 'Crop54'
  | 'Crop75'
  | 'CropDin'
  | 'CropFree'
  | 'CropLandscape'
  | 'CropOriginal'
  | 'CropPortrait'
  | 'CropRotate'
  | 'CropSquare'
  | 'Css'
  | 'CurrencyBitcoin'
  | 'CurrencyExchange'
  | 'CurrencyFranc'
  | 'CurrencyLira'
  | 'CurrencyPound'
  | 'CurrencyRuble'
  | 'CurrencyRupee'
  | 'CurrencyYen'
  | 'CurrencyYuan'
  | 'Curtains'
  | 'CurtainsClosed'
  | 'Cyclone'
  | 'Dangerous'
  | 'DarkMode'
  | 'Dashboard'
  | 'DashboardCustomize'
  | 'DataArray'
  | 'DataObject'
  | 'DataSaverOff'
  | 'DataSaverOn'
  | 'DataThresholding'
  | 'DataUsage'
  | 'DateRange'
  | 'Deblur'
  | 'Deck'
  | 'Dehaze'
  | 'Delete'
  | 'DeleteForever'
  | 'DeleteOutline'
  | 'DeleteSweep'
  | 'DeliveryDining'
  | 'DensityLarge'
  | 'DensityMedium'
  | 'DensitySmall'
  | 'DepartureBoard'
  | 'Description'
  | 'Deselect'
  | 'DesignServices'
  | 'DesktopAccessDisabled'
  | 'DesktopMac'
  | 'DesktopWindows'
  | 'Details'
  | 'DeveloperBoard'
  | 'DeveloperBoardOff'
  | 'DeveloperMode'
  | 'DeviceHub'
  | 'Devices'
  | 'DevicesFold'
  | 'DevicesOther'
  | 'DeviceThermostat'
  | 'DeviceUnknown'
  | 'DialerSip'
  | 'Dialpad'
  | 'Diamond'
  | 'Difference'
  | 'Dining'
  | 'DinnerDining'
  | 'Directions'
  | 'DirectionsBike'
  | 'DirectionsBoat'
  | 'DirectionsBus'
  | 'DirectionsCar'
  | 'DirectionsOff'
  | 'DirectionsRailway'
  | 'DirectionsRun'
  | 'DirectionsSubway'
  | 'DirectionsTransit'
  | 'DirectionsWalk'
  | 'DirtyLens'
  | 'DisabledByDefault'
  | 'DiscFull'
  | 'Discount'
  | 'DisplaySettings'
  | 'Dns'
  | 'Dock'
  | 'DocumentScanner'
  | 'DoDisturb'
  | 'DoDisturbAlt'
  | 'DoDisturbOff'
  | 'DoDisturbOn'
  | 'Domain'
  | 'DomainAdd'
  | 'DomainDisabled'
  | 'DomainVerification'
  | 'Done'
  | 'DoneAll'
  | 'DoneOutline'
  | 'DoNotDisturb'
  | 'DoNotDisturbAlt'
  | 'DoNotDisturbOff'
  | 'DoNotDisturbOn'
  | 'DoNotDisturbOnTotalSilence'
  | 'DoNotStep'
  | 'DoNotTouch'
  | 'DonutLarge'
  | 'DonutSmall'
  | 'DoorBack'
  | 'Doorbell'
  | 'DoorFront'
  | 'DoorSliding'
  | 'DoubleArrow'
  | 'DownhillSkiing'
  | 'Download'
  | 'DownloadDone'
  | 'DownloadForOffline'
  | 'Downloading'
  | 'Drafts'
  | 'DragHandle'
  | 'DragIndicator'
  | 'DriveEta'
  | 'DriveFileMove'
  | 'DriveFileRenameOutline'
  | 'DriveFolderUpload'
  | 'Dry'
  | 'DryCleaning'
  | 'Duo'
  | 'Dvr'
  | 'DynamicFeed'
  | 'DynamicForm'
  | 'Earbuds'
  | 'EarbudsBattery'
  | 'East'
  | 'EdgesensorHigh'
  | 'EdgesensorLow'
  | 'Edit'
  | 'EditAttributes'
  | 'EditLocation'
  | 'EditLocationAlt'
  | 'EditNotifications'
  | 'EditOff'
  | 'EditRoad'
  | 'Egg'
  | 'EggAlt'
  | 'EighteenMp'
  | 'EightK'
  | 'EightKPlus'
  | 'EightMp'
  | 'EightteenMp'
  | 'Eject'
  | 'Elderly'
  | 'ElderlyWoman'
  | 'ElectricalServices'
  | 'ElectricBike'
  | 'ElectricBolt'
  | 'ElectricCar'
  | 'ElectricMeter'
  | 'ElectricMoped'
  | 'ElectricRickshaw'
  | 'ElectricScooter'
  | 'Elevator'
  | 'ElevenMp'
  | 'Email'
  | 'EmergencyRecording'
  | 'EmergencyShare'
  | 'EMobiledata'
  | 'EmojiEmotions'
  | 'EmojiEvents'
  | 'EmojiFlags'
  | 'EmojiFoodBeverage'
  | 'EmojiNature'
  | 'EmojiObjects'
  | 'EmojiPeople'
  | 'EmojiSymbols'
  | 'EmojiTransportation'
  | 'EnergySavingsLeaf'
  | 'Engineering'
  | 'EnhancedEncryption'
  | 'Equalizer'
  | 'Error'
  | 'ErrorOutline'
  | 'Escalator'
  | 'EscalatorWarning'
  | 'Euro'
  | 'EuroSymbol'
  | 'Event'
  | 'EventAvailable'
  | 'EventBusy'
  | 'EventNote'
  | 'EventRepeat'
  | 'EventSeat'
  | 'EvStation'
  | 'ExitToApp'
  | 'Expand'
  | 'ExpandCircleDown'
  | 'ExpandLess'
  | 'ExpandMore'
  | 'Explicit'
  | 'Explore'
  | 'ExploreOff'
  | 'Exposure'
  | 'Extension'
  | 'ExtensionOff'
  | 'Face'
  | 'Facebook'
  | 'FaceRetouchingNatural'
  | 'FaceRetouchingOff'
  | 'FactCheck'
  | 'Factory'
  | 'FamilyRestroom'
  | 'Fastfood'
  | 'FastForward'
  | 'FastRewind'
  | 'Favorite'
  | 'FavoriteBorder'
  | 'Fax'
  | 'FeaturedPlayList'
  | 'FeaturedVideo'
  | 'Feed'
  | 'Feedback'
  | 'Female'
  | 'Fence'
  | 'Festival'
  | 'FiberDvr'
  | 'FiberManualRecord'
  | 'FiberNew'
  | 'FiberPin'
  | 'FiberSmartRecord'
  | 'FifteenMp'
  | 'FileCopy'
  | 'FileDownload'
  | 'FileDownloadDone'
  | 'FileDownloadOff'
  | 'FileOpen'
  | 'FilePresent'
  | 'FileUpload'
  | 'Filter'
  | 'Filter1'
  | 'Filter2'
  | 'Filter3'
  | 'Filter4'
  | 'Filter5'
  | 'Filter6'
  | 'Filter7'
  | 'Filter8'
  | 'Filter9'
  | 'Filter9Plus'
  | 'FilterAlt'
  | 'FilterAltOff'
  | 'FilterBAndW'
  | 'FilterCenterFocus'
  | 'FilterDrama'
  | 'FilterFrames'
  | 'FilterHdr'
  | 'FilterList'
  | 'FilterListOff'
  | 'FilterNone'
  | 'FilterTiltShift'
  | 'FilterVintage'
  | 'FindInPage'
  | 'FindReplace'
  | 'Fingerprint'
  | 'FireExtinguisher'
  | 'Fireplace'
  | 'FirstPage'
  | 'Fitbit'
  | 'FitnessCenter'
  | 'FitScreen'
  | 'FiveG'
  | 'FiveK'
  | 'FiveKPlus'
  | 'FiveMp'
  | 'FivteenMp'
  | 'Flag'
  | 'FlagCircle'
  | 'Flaky'
  | 'Flare'
  | 'FlashAuto'
  | 'FlashlightOff'
  | 'FlashlightOn'
  | 'FlashOff'
  | 'FlashOn'
  | 'Flatware'
  | 'Flight'
  | 'FlightClass'
  | 'FlightLand'
  | 'FlightTakeoff'
  | 'Flip'
  | 'FlipCameraAndroid'
  | 'FlipCameraIos'
  | 'FlipToBack'
  | 'FlipToFront'
  | 'Flood'
  | 'Flourescent'
  | 'FlutterDash'
  | 'FmdBad'
  | 'FmdGood'
  | 'Folder'
  | 'FolderCopy'
  | 'FolderDelete'
  | 'FolderOff'
  | 'FolderOpen'
  | 'FolderShared'
  | 'FolderSpecial'
  | 'FolderZip'
  | 'FollowTheSigns'
  | 'FontDownload'
  | 'FontDownloadOff'
  | 'FoodBank'
  | 'Forest'
  | 'ForkLeft'
  | 'ForkRight'
  | 'FormatAlignCenter'
  | 'FormatAlignJustify'
  | 'FormatAlignLeft'
  | 'FormatAlignRight'
  | 'FormatBold'
  | 'FormatClear'
  | 'FormatColorFill'
  | 'FormatColorReset'
  | 'FormatColorText'
  | 'FormatIndentDecrease'
  | 'FormatIndentIncrease'
  | 'FormatItalic'
  | 'FormatLineSpacing'
  | 'FormatListBulleted'
  | 'FormatListNumbered'
  | 'FormatListNumberedRtl'
  | 'FormatOverline'
  | 'FormatPaint'
  | 'FormatQuote'
  | 'FormatShapes'
  | 'FormatSize'
  | 'FormatStrikethrough'
  | 'FormatTextdirectionLToR'
  | 'FormatTextdirectionRToL'
  | 'FormatUnderlined'
  | 'Fort'
  | 'Forum'
  | 'Forward'
  | 'Forward10'
  | 'Forward30'
  | 'Forward5'
  | 'ForwardToInbox'
  | 'Foundation'
  | 'FourGMobiledata'
  | 'FourGPlusMobiledata'
  | 'FourK'
  | 'FourKPlus'
  | 'FourMp'
  | 'FourteenMp'
  | 'FreeBreakfast'
  | 'Fullscreen'
  | 'FullscreenExit'
  | 'Functions'
  | 'Gamepad'
  | 'Games'
  | 'Garage'
  | 'GasMeter'
  | 'Gavel'
  | 'Gesture'
  | 'GetApp'
  | 'Gif'
  | 'GifBox'
  | 'Girl'
  | 'Gite'
  | 'GitHub'
  | 'GMobiledata'
  | 'GolfCourse'
  | 'Google'
  | 'GppBad'
  | 'GppGood'
  | 'GppMaybe'
  | 'GpsFixed'
  | 'GpsNotFixed'
  | 'GpsOff'
  | 'Grade'
  | 'Gradient'
  | 'Grading'
  | 'Grain'
  | 'GraphicEq'
  | 'Grass'
  | 'Grid3x3'
  | 'Grid4x4'
  | 'GridGoldenratio'
  | 'GridOff'
  | 'GridOn'
  | 'GridView'
  | 'Group'
  | 'GroupAdd'
  | 'GroupRemove'
  | 'Groups'
  | 'GroupWork'
  | 'GTranslate'
  | 'Hail'
  | 'Handshake'
  | 'Handyman'
  | 'Hardware'
  | 'Hd'
  | 'HdrAuto'
  | 'HdrAutoSelect'
  | 'HdrEnhancedSelect'
  | 'HdrOff'
  | 'HdrOffSelect'
  | 'HdrOn'
  | 'HdrOnSelect'
  | 'HdrPlus'
  | 'HdrStrong'
  | 'HdrWeak'
  | 'Headphones'
  | 'HeadphonesBattery'
  | 'Headset'
  | 'HeadsetMic'
  | 'HeadsetOff'
  | 'Healing'
  | 'HealthAndSafety'
  | 'Hearing'
  | 'HearingDisabled'
  | 'HeartBroken'
  | 'HeatPump'
  | 'Height'
  | 'Help'
  | 'HelpCenter'
  | 'HelpOutline'
  | 'Hevc'
  | 'Hexagon'
  | 'HideImage'
  | 'HideSource'
  | 'Highlight'
  | 'HighlightAlt'
  | 'HighlightOff'
  | 'HighQuality'
  | 'Hiking'
  | 'History'
  | 'HistoryEdu'
  | 'HistoryToggleOff'
  | 'Hive'
  | 'Hls'
  | 'HlsOff'
  | 'HMobiledata'
  | 'HolidayVillage'
  | 'Home'
  | 'HomeMax'
  | 'HomeMini'
  | 'HomeRepairService'
  | 'HomeWork'
  | 'HorizontalRule'
  | 'HorizontalSplit'
  | 'Hotel'
  | 'HotTub'
  | 'HourglassBottom'
  | 'HourglassDisabled'
  | 'HourglassEmpty'
  | 'HourglassFull'
  | 'HourglassTop'
  | 'House'
  | 'Houseboat'
  | 'HouseSiding'
  | 'HowToReg'
  | 'HowToVote'
  | 'HPlusMobiledata'
  | 'Html'
  | 'Http'
  | 'Https'
  | 'Hub'
  | 'Hvac'
  | 'Icecream'
  | 'IceSkating'
  | 'Image'
  | 'ImageAspectRatio'
  | 'ImageNotSupported'
  | 'ImageSearch'
  | 'ImagesearchRoller'
  | 'ImportantDevices'
  | 'ImportContacts'
  | 'ImportExport'
  | 'Inbox'
  | 'IndeterminateCheckBox'
  | 'Info'
  | 'Input'
  | 'InsertChart'
  | 'InsertComment'
  | 'InsertDriveFile'
  | 'InsertEmoticon'
  | 'InsertInvitation'
  | 'InsertLink'
  | 'InsertPageBreak'
  | 'InsertPhoto'
  | 'Insights'
  | 'Instagram'
  | 'InstallDesktop'
  | 'InstallMobile'
  | 'IntegrationInstructions'
  | 'Interests'
  | 'InterpreterMode'
  | 'Inventory'
  | 'Inventory2'
  | 'InvertColors'
  | 'InvertColorsOff'
  | 'IosShare'
  | 'Iron'
  | 'Iso'
  | 'Javascript'
  | 'JoinFull'
  | 'JoinInner'
  | 'JoinLeft'
  | 'JoinRight'
  | 'Kayaking'
  | 'KebabDining'
  | 'Key'
  | 'Keyboard'
  | 'KeyboardAlt'
  | 'KeyboardArrowDown'
  | 'KeyboardArrowLeft'
  | 'KeyboardArrowRight'
  | 'KeyboardArrowUp'
  | 'KeyboardBackspace'
  | 'KeyboardCapslock'
  | 'KeyboardCommandKey'
  | 'KeyboardControlKey'
  | 'KeyboardDoubleArrowDown'
  | 'KeyboardDoubleArrowLeft'
  | 'KeyboardDoubleArrowRight'
  | 'KeyboardDoubleArrowUp'
  | 'KeyboardHide'
  | 'KeyboardOptionKey'
  | 'KeyboardReturn'
  | 'KeyboardTab'
  | 'KeyboardVoice'
  | 'KeyOff'
  | 'KingBed'
  | 'Kitchen'
  | 'Kitesurfing'
  | 'Label'
  | 'LabelImportant'
  | 'LabelOff'
  | 'Lan'
  | 'Landscape'
  | 'Landslide'
  | 'Language'
  | 'Laptop'
  | 'LaptopChromebook'
  | 'LaptopMac'
  | 'LaptopWindows'
  | 'LastPage'
  | 'Launch'
  | 'Layers'
  | 'LayersClear'
  | 'Leaderboard'
  | 'LeakAdd'
  | 'LeakRemove'
  | 'LegendToggle'
  | 'Lens'
  | 'LensBlur'
  | 'LibraryAdd'
  | 'LibraryAddCheck'
  | 'LibraryBooks'
  | 'LibraryMusic'
  | 'Light'
  | 'Lightbulb'
  | 'LightbulbCircle'
  | 'LightMode'
  | 'LinearScale'
  | 'LineAxis'
  | 'LineStyle'
  | 'LineWeight'
  | 'Link'
  | 'LinkedCamera'
  | 'LinkedIn'
  | 'LinkOff'
  | 'Liquor'
  | 'List'
  | 'ListAlt'
  | 'LiveHelp'
  | 'LiveTv'
  | 'Living'
  | 'LocalActivity'
  | 'LocalAirport'
  | 'LocalAtm'
  | 'LocalBar'
  | 'LocalCafe'
  | 'LocalCarWash'
  | 'LocalConvenienceStore'
  | 'LocalDining'
  | 'LocalDrink'
  | 'LocalFireDepartment'
  | 'LocalFlorist'
  | 'LocalGasStation'
  | 'LocalGroceryStore'
  | 'LocalHospital'
  | 'LocalHotel'
  | 'LocalLaundryService'
  | 'LocalLibrary'
  | 'LocalMall'
  | 'LocalMovies'
  | 'LocalOffer'
  | 'LocalParking'
  | 'LocalPharmacy'
  | 'LocalPhone'
  | 'LocalPizza'
  | 'LocalPlay'
  | 'LocalPolice'
  | 'LocalPostOffice'
  | 'LocalPrintshop'
  | 'LocalSee'
  | 'LocalShipping'
  | 'LocalTaxi'
  | 'LocationCity'
  | 'LocationDisabled'
  | 'LocationOff'
  | 'LocationOn'
  | 'LocationSearching'
  | 'Lock'
  | 'LockClock'
  | 'LockOpen'
  | 'LockReset'
  | 'Login'
  | 'LogoDev'
  | 'Logout'
  | 'Looks'
  | 'Looks3'
  | 'Looks4'
  | 'Looks5'
  | 'Looks6'
  | 'LooksOne'
  | 'LooksTwo'
  | 'Loop'
  | 'Loupe'
  | 'LowPriority'
  | 'Loyalty'
  | 'LteMobiledata'
  | 'LtePlusMobiledata'
  | 'Luggage'
  | 'LunchDining'
  | 'Lyrics'
  | 'Mail'
  | 'MailLock'
  | 'MailOutline'
  | 'Male'
  | 'Man'
  | 'ManageAccounts'
  | 'ManageHistory'
  | 'ManageSearch'
  | 'Map'
  | 'MapsHomeWork'
  | 'MapsUgc'
  | 'Margin'
  | 'MarkAsUnread'
  | 'MarkChatRead'
  | 'MarkChatUnread'
  | 'MarkEmailRead'
  | 'MarkEmailUnread'
  | 'Markunread'
  | 'MarkUnreadChatAlt'
  | 'MarkunreadMailbox'
  | 'Masks'
  | 'Maximize'
  | 'MediaBluetoothOff'
  | 'MediaBluetoothOn'
  | 'Mediation'
  | 'MedicalInformation'
  | 'MedicalServices'
  | 'Medication'
  | 'MedicationLiquid'
  | 'MeetingRoom'
  | 'Memory'
  | 'Menu'
  | 'MenuBook'
  | 'MenuOpen'
  | 'Merge'
  | 'MergeType'
  | 'Message'
  | 'Mic'
  | 'MicExternalOff'
  | 'MicExternalOn'
  | 'MicNone'
  | 'MicOff'
  | 'Microwave'
  | 'MilitaryTech'
  | 'Minimize'
  | 'MinorCrash'
  | 'MiscellaneousServices'
  | 'MissedVideoCall'
  | 'Mms'
  | 'MobiledataOff'
  | 'MobileFriendly'
  | 'MobileOff'
  | 'MobileScreenShare'
  | 'Mode'
  | 'ModeComment'
  | 'ModeEdit'
  | 'ModeEditOutline'
  | 'ModeFanOff'
  | 'ModelTraining'
  | 'ModeNight'
  | 'ModeOfTravel'
  | 'ModeStandby'
  | 'MonetizationOn'
  | 'Money'
  | 'MoneyOff'
  | 'MoneyOffCsred'
  | 'Monitor'
  | 'MonitorHeart'
  | 'MonitorWeight'
  | 'MonochromePhotos'
  | 'Mood'
  | 'MoodBad'
  | 'Moped'
  | 'More'
  | 'MoreHoriz'
  | 'MoreTime'
  | 'MoreVert'
  | 'Mosque'
  | 'MotionPhotosAuto'
  | 'MotionPhotosOff'
  | 'Mouse'
  | 'MoveDown'
  | 'MoveToInbox'
  | 'MoveUp'
  | 'Movie'
  | 'MovieCreation'
  | 'MovieFilter'
  | 'Moving'
  | 'Mp'
  | 'MultilineChart'
  | 'MultipleStop'
  | 'Museum'
  | 'MusicNote'
  | 'MusicOff'
  | 'MusicVideo'
  | 'MyLocation'
  | 'Nat'
  | 'Nature'
  | 'NaturePeople'
  | 'NavigateBefore'
  | 'NavigateNext'
  | 'Navigation'
  | 'NearbyError'
  | 'NearbyOff'
  | 'NearMe'
  | 'NearMeDisabled'
  | 'NestCamWiredStand'
  | 'NetworkCell'
  | 'NetworkCheck'
  | 'NetworkLocked'
  | 'NetworkPing'
  | 'NetworkWifi'
  | 'NetworkWifi1Bar'
  | 'NetworkWifi2Bar'
  | 'NetworkWifi3Bar'
  | 'NewReleases'
  | 'Newspaper'
  | 'NextPlan'
  | 'NextWeek'
  | 'Nfc'
  | 'Nightlife'
  | 'Nightlight'
  | 'NightlightRound'
  | 'NightShelter'
  | 'NightsStay'
  | 'NineK'
  | 'NineKPlus'
  | 'NineMp'
  | 'NineteenMp'
  | 'NoAccounts'
  | 'NoBackpack'
  | 'NoCell'
  | 'NoCrash'
  | 'NoDrinks'
  | 'NoEncryption'
  | 'NoEncryptionGmailerrorred'
  | 'NoFlash'
  | 'NoFood'
  | 'NoiseAware'
  | 'NoiseControlOff'
  | 'NoLuggage'
  | 'NoMeals'
  | 'NoMeetingRoom'
  | 'NoPhotography'
  | 'NordicWalking'
  | 'North'
  | 'NorthEast'
  | 'NorthWest'
  | 'NoSim'
  | 'NoStroller'
  | 'NotAccessible'
  | 'Note'
  | 'NoteAdd'
  | 'NoteAlt'
  | 'Notes'
  | 'NotificationAdd'
  | 'NotificationImportant'
  | 'Notifications'
  | 'NotificationsActive'
  | 'NotificationsNone'
  | 'NotificationsOff'
  | 'NotificationsPaused'
  | 'NotInterested'
  | 'NotListedLocation'
  | 'NoTransfer'
  | 'NotStarted'
  | 'Numbers'
  | 'OfflineBolt'
  | 'OfflinePin'
  | 'OfflineShare'
  | 'OilBarrel'
  | 'OndemandVideo'
  | 'OnDeviceTraining'
  | 'OneK'
  | 'OneKk'
  | 'OneKPlus'
  | 'OnlinePrediction'
  | 'Opacity'
  | 'OpenInBrowser'
  | 'OpenInFull'
  | 'OpenInNew'
  | 'OpenInNewOff'
  | 'OpenWith'
  | 'OtherHouses'
  | 'Outbound'
  | 'Outbox'
  | 'OutdoorGrill'
  | 'Outlet'
  | 'Output'
  | 'Padding'
  | 'Pages'
  | 'Pageview'
  | 'Paid'
  | 'Palette'
  | 'Panorama'
  | 'PanoramaFishEye'
  | 'PanoramaHorizontal'
  | 'PanoramaHorizontalSelect'
  | 'PanoramaPhotosphere'
  | 'PanoramaPhotosphereSelect'
  | 'PanoramaVertical'
  | 'PanoramaVerticalSelect'
  | 'PanoramaWideAngle'
  | 'PanoramaWideAngleSelect'
  | 'PanTool'
  | 'PanToolAlt'
  | 'Paragliding'
  | 'Park'
  | 'PartyMode'
  | 'Password'
  | 'Pattern'
  | 'Pause'
  | 'PauseCircle'
  | 'PauseCircleOutline'
  | 'PausePresentation'
  | 'Payment'
  | 'Payments'
  | 'PedalBike'
  | 'Pending'
  | 'PendingActions'
  | 'Pentagon'
  | 'People'
  | 'PeopleAlt'
  | 'PeopleOutline'
  | 'Percent'
  | 'PermCameraMic'
  | 'PermContactCalendar'
  | 'PermDataSetting'
  | 'PermDeviceInformation'
  | 'PermIdentity'
  | 'PermMedia'
  | 'PermPhoneMsg'
  | 'PermScanWifi'
  | 'Person'
  | 'PersonAdd'
  | 'PersonAddAlt'
  | 'PersonAddAlt1'
  | 'PersonAddDisabled'
  | 'PersonalVideo'
  | 'PersonOff'
  | 'PersonOutline'
  | 'PersonPin'
  | 'PersonPinCircle'
  | 'PersonRemove'
  | 'PersonRemoveAlt1'
  | 'PersonSearch'
  | 'PestControl'
  | 'PestControlRodent'
  | 'Pets'
  | 'Phishing'
  | 'Phone'
  | 'PhoneAndroid'
  | 'PhoneBluetoothSpeaker'
  | 'PhoneCallback'
  | 'PhoneDisabled'
  | 'PhoneEnabled'
  | 'PhoneForwarded'
  | 'PhoneInTalk'
  | 'PhoneIphone'
  | 'Phonelink'
  | 'PhonelinkErase'
  | 'PhonelinkLock'
  | 'PhonelinkOff'
  | 'PhonelinkRing'
  | 'PhonelinkSetup'
  | 'PhoneLocked'
  | 'PhoneMissed'
  | 'PhonePaused'
  | 'Photo'
  | 'PhotoAlbum'
  | 'PhotoCamera'
  | 'PhotoCameraBack'
  | 'PhotoCameraFront'
  | 'PhotoFilter'
  | 'PhotoLibrary'
  | 'PhotoSizeSelectActual'
  | 'PhotoSizeSelectLarge'
  | 'PhotoSizeSelectSmall'
  | 'Php'
  | 'Piano'
  | 'PianoOff'
  | 'PictureAsPdf'
  | 'PictureInPicture'
  | 'PictureInPictureAlt'
  | 'PieChart'
  | 'PieChartOutline'
  | 'Pin'
  | 'Pinch'
  | 'PinDrop'
  | 'Pinterest'
  | 'PivotTableChart'
  | 'Pix'
  | 'Place'
  | 'Plagiarism'
  | 'PlayArrow'
  | 'PlayCircle'
  | 'PlayCircleOutline'
  | 'PlayDisabled'
  | 'PlayForWork'
  | 'PlayLesson'
  | 'PlaylistAdd'
  | 'PlaylistAddCheck'
  | 'PlaylistAddCheckCircle'
  | 'PlaylistAddCircle'
  | 'PlaylistPlay'
  | 'PlaylistRemove'
  | 'Plumbing'
  | 'PlusOne'
  | 'Podcasts'
  | 'PointOfSale'
  | 'Policy'
  | 'Poll'
  | 'Polyline'
  | 'Pool'
  | 'PortableWifiOff'
  | 'Portrait'
  | 'PostAdd'
  | 'Power'
  | 'PowerInput'
  | 'PowerOff'
  | 'PowerSettingsNew'
  | 'PrecisionManufacturing'
  | 'PregnantWoman'
  | 'PresentToAll'
  | 'Preview'
  | 'PriceChange'
  | 'PriceCheck'
  | 'Print'
  | 'PrintDisabled'
  | 'PriorityHigh'
  | 'PrivacyTip'
  | 'ProductionQuantityLimits'
  | 'Propane'
  | 'PropaneTank'
  | 'Psychology'
  | 'Public'
  | 'PublicOff'
  | 'Publish'
  | 'PublishedWithChanges'
  | 'PunchClock'
  | 'PushPin'
  | 'QrCode'
  | 'QrCode2'
  | 'QrCodeScanner'
  | 'QueryBuilder'
  | 'QueryStats'
  | 'QuestionAnswer'
  | 'QuestionMark'
  | 'Queue'
  | 'QueueMusic'
  | 'QueuePlayNext'
  | 'Quickreply'
  | 'Quiz'
  | 'Radar'
  | 'Radio'
  | 'RadioButtonChecked'
  | 'RadioButtonUnchecked'
  | 'RailwayAlert'
  | 'RamenDining'
  | 'RampLeft'
  | 'RampRight'
  | 'RateReview'
  | 'RawOff'
  | 'RawOn'
  | 'ReadMore'
  | 'Receipt'
  | 'ReceiptLong'
  | 'RecentActors'
  | 'Recommend'
  | 'RecordVoiceOver'
  | 'Rectangle'
  | 'Reddit'
  | 'Redeem'
  | 'Redo'
  | 'ReduceCapacity'
  | 'Refresh'
  | 'RememberMe'
  | 'Remove'
  | 'RemoveCircle'
  | 'RemoveCircleOutline'
  | 'RemoveDone'
  | 'RemoveFromQueue'
  | 'RemoveModerator'
  | 'RemoveRedEye'
  | 'RemoveRoad'
  | 'RemoveShoppingCart'
  | 'Reorder'
  | 'Repeat'
  | 'RepeatOn'
  | 'RepeatOne'
  | 'RepeatOneOn'
  | 'Replay'
  | 'Replay10'
  | 'Replay30'
  | 'Replay5'
  | 'Reply'
  | 'ReplyAll'
  | 'Report'
  | 'ReportGmailerrorred'
  | 'ReportOff'
  | 'ReportProblem'
  | 'RequestPage'
  | 'RequestQuote'
  | 'ResetTv'
  | 'RestartAlt'
  | 'Restaurant'
  | 'RestaurantMenu'
  | 'Restore'
  | 'RestoreFromTrash'
  | 'RestorePage'
  | 'Reviews'
  | 'RiceBowl'
  | 'RingVolume'
  | 'RMobiledata'
  | 'Rocket'
  | 'RocketLaunch'
  | 'RollerShades'
  | 'RollerShadesClosed'
  | 'RollerSkating'
  | 'Roofing'
  | 'Room'
  | 'RoomPreferences'
  | 'RoomService'
  | 'Rotate90DegreesCcw'
  | 'Rotate90DegreesCw'
  | 'RotateLeft'
  | 'RotateRight'
  | 'RoundaboutLeft'
  | 'RoundaboutRight'
  | 'Route'
  | 'Router'
  | 'Rowing'
  | 'RssFeed'
  | 'Rsvp'
  | 'Rtt'
  | 'Rule'
  | 'RuleFolder'
  | 'RunCircle'
  | 'RunningWithErrors'
  | 'RvHookup'
  | 'SafetyCheck'
  | 'SafetyDivider'
  | 'Sailing'
  | 'Sanitizer'
  | 'Satellite'
  | 'SatelliteAlt'
  | 'Save'
  | 'SaveAlt'
  | 'SaveAs'
  | 'SavedSearch'
  | 'Savings'
  | 'Scale'
  | 'Scanner'
  | 'ScatterPlot'
  | 'Schedule'
  | 'ScheduleSend'
  | 'Schema'
  | 'School'
  | 'Science'
  | 'Score'
  | 'Scoreboard'
  | 'ScreenLockLandscape'
  | 'ScreenLockPortrait'
  | 'ScreenLockRotation'
  | 'ScreenRotation'
  | 'ScreenRotationAlt'
  | 'ScreenSearchDesktop'
  | 'ScreenShare'
  | 'Screenshot'
  | 'ScreenshotMonitor'
  | 'ScubaDiving'
  | 'Sd'
  | 'SdCard'
  | 'SdCardAlert'
  | 'SdStorage'
  | 'Search'
  | 'SearchOff'
  | 'Security'
  | 'SecurityUpdate'
  | 'SecurityUpdateGood'
  | 'SecurityUpdateWarning'
  | 'Segment'
  | 'SelectAll'
  | 'SelfImprovement'
  | 'Sell'
  | 'Send'
  | 'SendAndArchive'
  | 'SendTimeExtension'
  | 'SendToMobile'
  | 'SensorDoor'
  | 'SensorOccupied'
  | 'Sensors'
  | 'SensorsOff'
  | 'SensorWindow'
  | 'SentimentDissatisfied'
  | 'SentimentNeutral'
  | 'SentimentSatisfied'
  | 'SentimentSatisfiedAlt'
  | 'SentimentVeryDissatisfied'
  | 'SentimentVerySatisfied'
  | 'SetMeal'
  | 'Settings'
  | 'SettingsAccessibility'
  | 'SettingsApplications'
  | 'SettingsBackupRestore'
  | 'SettingsBluetooth'
  | 'SettingsBrightness'
  | 'SettingsCell'
  | 'SettingsEthernet'
  | 'SettingsInputAntenna'
  | 'SettingsInputComponent'
  | 'SettingsInputComposite'
  | 'SettingsInputHdmi'
  | 'SettingsInputSvideo'
  | 'SettingsOverscan'
  | 'SettingsPhone'
  | 'SettingsPower'
  | 'SettingsRemote'
  | 'SettingsSuggest'
  | 'SettingsSystemDaydream'
  | 'SettingsVoice'
  | 'SevenK'
  | 'SevenKPlus'
  | 'SevenMp'
  | 'SeventeenMp'
  | 'SevereCold'
  | 'Share'
  | 'ShareLocation'
  | 'Shield'
  | 'ShieldMoon'
  | 'Shop'
  | 'Shop2'
  | 'ShoppingBag'
  | 'ShoppingBasket'
  | 'ShoppingCart'
  | 'ShoppingCartCheckout'
  | 'ShopTwo'
  | 'Shortcut'
  | 'ShortText'
  | 'ShowChart'
  | 'Shower'
  | 'Shuffle'
  | 'ShuffleOn'
  | 'ShutterSpeed'
  | 'Sick'
  | 'SignalCellular0Bar'
  | 'SignalCellular1Bar'
  | 'SignalCellular2Bar'
  | 'SignalCellular3Bar'
  | 'SignalCellular4Bar'
  | 'SignalCellularAlt'
  | 'SignalCellularAlt1Bar'
  | 'SignalCellularAlt2Bar'
  | 'SignalCellularConnectedNoInternet0Bar'
  | 'SignalCellularConnectedNoInternet1Bar'
  | 'SignalCellularConnectedNoInternet2Bar'
  | 'SignalCellularConnectedNoInternet3Bar'
  | 'SignalCellularConnectedNoInternet4Bar'
  | 'SignalCellularNodata'
  | 'SignalCellularNoSim'
  | 'SignalCellularNull'
  | 'SignalCellularOff'
  | 'SignalWifi0Bar'
  | 'SignalWifi1Bar'
  | 'SignalWifi1BarLock'
  | 'SignalWifi2Bar'
  | 'SignalWifi2BarLock'
  | 'SignalWifi3Bar'
  | 'SignalWifi3BarLock'
  | 'SignalWifi4Bar'
  | 'SignalWifi4BarLock'
  | 'SignalWifiBad'
  | 'SignalWifiConnectedNoInternet4'
  | 'SignalWifiOff'
  | 'SignalWifiStatusbar4Bar'
  | 'SignalWifiStatusbarConnectedNoInternet4'
  | 'SignalWifiStatusbarNull'
  | 'SignLanguage'
  | 'Signpost'
  | 'SimCard'
  | 'SimCardAlert'
  | 'SimCardDownload'
  | 'SingleBed'
  | 'Sip'
  | 'SixK'
  | 'SixKPlus'
  | 'SixMp'
  | 'SixteenMp'
  | 'SixtyFps'
  | 'SixtyFpsSelect'
  | 'Skateboarding'
  | 'SkipNext'
  | 'SkipPrevious'
  | 'Sledding'
  | 'Slideshow'
  | 'SlowMotionVideo'
  | 'SmartButton'
  | 'SmartDisplay'
  | 'Smartphone'
  | 'SmartScreen'
  | 'SmartToy'
  | 'SmokeFree'
  | 'SmokingRooms'
  | 'Sms'
  | 'SmsFailed'
  | 'SnippetFolder'
  | 'Snooze'
  | 'Snowboarding'
  | 'Snowmobile'
  | 'Snowshoeing'
  | 'Soap'
  | 'SocialDistance'
  | 'SolarPower'
  | 'Sort'
  | 'SortByAlpha'
  | 'Sos'
  | 'SoupKitchen'
  | 'Source'
  | 'South'
  | 'SouthAmerica'
  | 'SouthEast'
  | 'SouthWest'
  | 'Spa'
  | 'SpaceBar'
  | 'SpatialAudio'
  | 'SpatialAudioOff'
  | 'SpatialTracking'
  | 'Speaker'
  | 'SpeakerGroup'
  | 'SpeakerNotes'
  | 'SpeakerNotesOff'
  | 'SpeakerPhone'
  | 'Speed'
  | 'Spellcheck'
  | 'Splitscreen'
  | 'Spoke'
  | 'Sports'
  | 'SportsBar'
  | 'SportsBaseball'
  | 'SportsBasketball'
  | 'SportsCricket'
  | 'SportsEsports'
  | 'SportsFootball'
  | 'SportsGolf'
  | 'SportsGymnastics'
  | 'SportsHandball'
  | 'SportsHockey'
  | 'SportsKabaddi'
  | 'SportsMartialArts'
  | 'SportsMma'
  | 'SportsMotorsports'
  | 'SportsRugby'
  | 'SportsScore'
  | 'SportsSoccer'
  | 'SportsTennis'
  | 'SportsVolleyball'
  | 'Square'
  | 'SquareFoot'
  | 'SsidChart'
  | 'StackedBarChart'
  | 'StackedLineChart'
  | 'Stadium'
  | 'Stairs'
  | 'Star'
  | 'StarBorder'
  | 'StarBorderPurple500'
  | 'StarHalf'
  | 'StarOutline'
  | 'StarPurple500'
  | 'StarRate'
  | 'Stars'
  | 'Start'
  | 'StayCurrentLandscape'
  | 'StayCurrentPortrait'
  | 'StayPrimaryLandscape'
  | 'StayPrimaryPortrait'
  | 'StickyNote2'
  | 'Stop'
  | 'StopCircle'
  | 'StopScreenShare'
  | 'Storage'
  | 'Store'
  | 'Storefront'
  | 'StoreMallDirectory'
  | 'Storm'
  | 'Straight'
  | 'Straighten'
  | 'Stream'
  | 'Streetview'
  | 'StrikethroughS'
  | 'Stroller'
  | 'Style'
  | 'SubdirectoryArrowLeft'
  | 'SubdirectoryArrowRight'
  | 'Subject'
  | 'Subscript'
  | 'Subscriptions'
  | 'Subtitles'
  | 'SubtitlesOff'
  | 'Subway'
  | 'Summarize'
  | 'Superscript'
  | 'SupervisedUserCircle'
  | 'SupervisorAccount'
  | 'Support'
  | 'SupportAgent'
  | 'Surfing'
  | 'SurroundSound'
  | 'SwapCalls'
  | 'SwapHoriz'
  | 'SwapHorizontalCircle'
  | 'SwapVert'
  | 'SwapVerticalCircle'
  | 'Swipe'
  | 'SwipeDown'
  | 'SwipeDownAlt'
  | 'SwipeLeft'
  | 'SwipeLeftAlt'
  | 'SwipeRight'
  | 'SwipeRightAlt'
  | 'SwipeUp'
  | 'SwipeUpAlt'
  | 'SwipeVertical'
  | 'SwitchAccessShortcut'
  | 'SwitchAccessShortcutAdd'
  | 'SwitchAccount'
  | 'SwitchCamera'
  | 'SwitchLeft'
  | 'SwitchRight'
  | 'SwitchVideo'
  | 'Synagogue'
  | 'Sync'
  | 'SyncAlt'
  | 'SyncDisabled'
  | 'SyncLock'
  | 'SyncProblem'
  | 'SystemSecurityUpdate'
  | 'SystemSecurityUpdateGood'
  | 'SystemSecurityUpdateWarning'
  | 'SystemUpdate'
  | 'SystemUpdateAlt'
  | 'Tab'
  | 'TableBar'
  | 'TableChart'
  | 'TableRestaurant'
  | 'TableRows'
  | 'Tablet'
  | 'TabletAndroid'
  | 'TabletMac'
  | 'TableView'
  | 'TabUnselected'
  | 'Tag'
  | 'TagFaces'
  | 'TakeoutDining'
  | 'TapAndPlay'
  | 'Tapas'
  | 'Task'
  | 'TaskAlt'
  | 'TaxiAlert'
  | 'Telegram'
  | 'TempleBuddhist'
  | 'TempleHindu'
  | 'TenMp'
  | 'Terminal'
  | 'Terrain'
  | 'TextDecrease'
  | 'TextFields'
  | 'TextFormat'
  | 'TextIncrease'
  | 'TextRotateUp'
  | 'TextRotateVertical'
  | 'TextRotationAngledown'
  | 'TextRotationAngleup'
  | 'TextRotationDown'
  | 'TextRotationNone'
  | 'Textsms'
  | 'TextSnippet'
  | 'Texture'
  | 'TheaterComedy'
  | 'Theaters'
  | 'Thermostat'
  | 'ThermostatAuto'
  | 'ThirteenMp'
  | 'ThirtyFps'
  | 'ThirtyFpsSelect'
  | 'ThreeDRotation'
  | 'ThreeGMobiledata'
  | 'ThreeK'
  | 'ThreeKPlus'
  | 'ThreeMp'
  | 'ThreeP'
  | 'ThreeSixty'
  | 'ThumbDown'
  | 'ThumbDownAlt'
  | 'ThumbDownOffAlt'
  | 'ThumbsUpDown'
  | 'ThumbUp'
  | 'ThumbUpAlt'
  | 'ThumbUpOffAlt'
  | 'Thunderstorm'
  | 'Timelapse'
  | 'Timeline'
  | 'Timer'
  | 'Timer10'
  | 'Timer10Select'
  | 'Timer3'
  | 'Timer3Select'
  | 'TimerOff'
  | 'TimesOneMobiledata'
  | 'TimeToLeave'
  | 'TipsAndUpdates'
  | 'TireRepair'
  | 'Title'
  | 'Toc'
  | 'Today'
  | 'ToggleOff'
  | 'ToggleOn'
  | 'Token'
  | 'Toll'
  | 'Tonality'
  | 'Topic'
  | 'Tornado'
  | 'TouchApp'
  | 'Tour'
  | 'Toys'
  | 'TrackChanges'
  | 'Traffic'
  | 'Train'
  | 'Tram'
  | 'TransferWithinAStation'
  | 'Transform'
  | 'Transgender'
  | 'TransitEnterexit'
  | 'Translate'
  | 'TravelExplore'
  | 'TrendingDown'
  | 'TrendingFlat'
  | 'TrendingUp'
  | 'TripOrigin'
  | 'Try'
  | 'Tsunami'
  | 'Tty'
  | 'Tune'
  | 'Tungsten'
  | 'TurnedIn'
  | 'TurnedInNot'
  | 'TurnLeft'
  | 'TurnRight'
  | 'TurnSlightLeft'
  | 'TurnSlightRight'
  | 'Tv'
  | 'TvOff'
  | 'TwelveMp'
  | 'TwentyFourMp'
  | 'TwentyOneMp'
  | 'TwentyThreeMp'
  | 'TwentyTwoMp'
  | 'TwentyZeroMp'
  | 'Twitter'
  | 'TwoK'
  | 'TwoKPlus'
  | 'TwoMp'
  | 'TwoWheeler'
  | 'Umbrella'
  | 'Unarchive'
  | 'Undo'
  | 'UnfoldLess'
  | 'UnfoldMore'
  | 'Unpublished'
  | 'Unsubscribe'
  | 'Upcoming'
  | 'Update'
  | 'UpdateDisabled'
  | 'Upgrade'
  | 'Upload'
  | 'UploadFile'
  | 'Usb'
  | 'UsbOff'
  | 'UTurnLeft'
  | 'UTurnRight'
  | 'Vaccines'
  | 'VapeFree'
  | 'VapingRooms'
  | 'Verified'
  | 'VerifiedUser'
  | 'VerticalAlignBottom'
  | 'VerticalAlignCenter'
  | 'VerticalAlignTop'
  | 'VerticalShades'
  | 'VerticalShadesClosed'
  | 'VerticalSplit'
  | 'Vibration'
  | 'VideoCall'
  | 'Videocam'
  | 'VideoCameraBack'
  | 'VideoCameraFront'
  | 'VideocamOff'
  | 'VideoFile'
  | 'VideogameAsset'
  | 'VideogameAssetOff'
  | 'VideoLabel'
  | 'VideoLibrary'
  | 'VideoSettings'
  | 'VideoStable'
  | 'ViewAgenda'
  | 'ViewArray'
  | 'ViewCarousel'
  | 'ViewColumn'
  | 'ViewComfy'
  | 'ViewComfyAlt'
  | 'ViewCompact'
  | 'ViewCompactAlt'
  | 'ViewCozy'
  | 'ViewDay'
  | 'ViewHeadline'
  | 'ViewInAr'
  | 'ViewKanban'
  | 'ViewList'
  | 'ViewModule'
  | 'ViewQuilt'
  | 'ViewSidebar'
  | 'ViewStream'
  | 'ViewTimeline'
  | 'ViewWeek'
  | 'Vignette'
  | 'Villa'
  | 'Visibility'
  | 'VisibilityOff'
  | 'VoiceChat'
  | 'Voicemail'
  | 'VoiceOverOff'
  | 'Volcano'
  | 'VolumeDown'
  | 'VolumeMute'
  | 'VolumeOff'
  | 'VolumeUp'
  | 'VolunteerActivism'
  | 'VpnKey'
  | 'VpnKeyOff'
  | 'VpnLock'
  | 'Vrpano'
  | 'Wallpaper'
  | 'Warehouse'
  | 'Warning'
  | 'WarningAmber'
  | 'Wash'
  | 'Watch'
  | 'WatchLater'
  | 'WatchOff'
  | 'Water'
  | 'WaterDamage'
  | 'WaterfallChart'
  | 'Waves'
  | 'WbAuto'
  | 'WbCloudy'
  | 'WbIncandescent'
  | 'WbIridescent'
  | 'WbShade'
  | 'WbSunny'
  | 'WbTwilight'
  | 'Wc'
  | 'Web'
  | 'WebAsset'
  | 'WebAssetOff'
  | 'Webhook'
  | 'Weekend'
  | 'West'
  | 'WhatsApp'
  | 'Whatshot'
  | 'WheelchairPickup'
  | 'WhereToVote'
  | 'Widgets'
  | 'Wifi'
  | 'Wifi1Bar'
  | 'Wifi2Bar'
  | 'WifiCalling'
  | 'WifiCalling3'
  | 'WifiChannel'
  | 'WifiFind'
  | 'WifiLock'
  | 'WifiOff'
  | 'WifiPassword'
  | 'WifiProtectedSetup'
  | 'WifiTethering'
  | 'WifiTetheringError'
  | 'WifiTetheringOff'
  | 'Window'
  | 'WindPower'
  | 'WineBar'
  | 'Woman'
  | 'Work'
  | 'WorkHistory'
  | 'WorkOff'
  | 'WorkOutline'
  | 'WorkspacePremium'
  | 'Workspaces'
  | 'WrapText'
  | 'WrongLocation'
  | 'Wysiwyg'
  | 'Yard'
  | 'YouTube'
  | 'YoutubeSearchedFor'
  | 'ZoomIn'
  | 'ZoomInMap'
  | 'ZoomOut'
  | 'ZoomOutMap';

export type Without<T, K> = Pick<T, Exclude<keyof T, K>>;
