import styled, { theme } from '../../../../theme';
import Button from '@mui/material/Button';

export const ButtonNoBorder = styled(Button)`
  && {
    text-transform: capitalize;
    font-size: 14px;
    padding: 5px 10px;
    background-color: transparent;
    color: ${theme.red};
    border: none;
    &&:hover {
      color: ${theme.secondaryBackground};
      background: ${theme.red};
    }

    &.neutral {
      color: ${theme.palette.primary.main};
      border: ${theme.border};
      &&:hover {
        color: ${theme.primaryColor};
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
`;
