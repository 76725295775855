import * as React from 'react';
import _ from 'lodash';
import { TableCellRenderer } from 'react-virtualized';
import Add from '@mui/icons-material/Add';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import Restore from '@mui/icons-material/Restore';
import ConfirmModal from 'src/components/shared/ConfirmModal';
import LineItemModal from 'src/components/InvoiceDetail/LineItemModal';
import LineItemTable from 'src/components/InvoiceDetail/LineItemTable';
import TimesheetRowTable from 'src/components/InvoiceDetail/TimesheetRowTable';
import { InputType } from 'src/components/shared/inputs/InputField';
import LoadingPage from 'src/components/shared/LoadingPage';
import {
  ENoteType,
  IInvoiceSidebarDetail
} from 'src/shared/invoice-list/types';
import {
  IInvoiceDetailView,
  IFixedLineItem,
  ITimesheetRow,
  NewInvoiceState
} from 'src/shared/invoice/types';
import { isEditableStatus, sumInvoiceTotal } from 'src/shared/invoice/utils';
import { isAdmin } from 'src/shared/auth/utils';
import { ICurrentUser } from 'src/shared/auth/types';
import { formatDatePeriod, formatAsCurrency, diff } from 'src/shared/utils';
import InvoiceDetailSubNav from './InvoiceDetailSubNav';
import InvoicePopover from './InvoicePopover';
import InvoiceEntryDialog from './InvoiceSidebar/InvoiceEntryDialog';
import {
  TableHeader,
  Container,
  AddLineItemButton,
  DismissAllInvoicesButton
} from './styles';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Deliverable, ProjectRole, TimesheetEntry } from 'src/api/types';

export interface IInvoiceDetailProps {
  invoiceSidebarDetail: IInvoiceSidebarDetail;
  fetchMoreInvoices: () => void;
  view?: IInvoiceDetailView;
  saveInvoice: ({ view }: { view: IInvoiceDetailView }) => void;
  saveTimesheetRow: (item: ITimesheetRow) => void;
  saveTimesheetEntry: (item: TimesheetEntry) => void;
  saveLineItem: (item: IFixedLineItem) => void;
  deleteLineItem: (item: IFixedLineItem) => void;
  dismissAllTimesheets: () => void;
  dismissTimesheetRowItem: (item: ITimesheetRow) => void;
  updateFixedLineItem: (fixedLineItem: IFixedLineItem) => void;
  downloadFile: (id: number) => void;
  uploadNewFile: ({
    lineItemId,
    file
  }: {
    lineItemId?: number;
    file: File;
  }) => void;
  isNew: boolean;
  clearDetail: () => void;
  getNewInvoiceView: ({
    filters
  }: {
    filters: NewInvoiceState['filters'];
  }) => void;
  createNewInvoice: (dismiss?: boolean) => void;
  storeFilters?: NewInvoiceState['filters'] | null;
  sendOverdueTimesheetNotification: (
    managerId: number,
    timesheetId: number
  ) => any;
  isAdmin: boolean;
  modifyLocalView: (mutatedView: IInvoiceDetailView) => void;
  cancelTimesheetEntryChanges: (
    userRowIndex: number,
    timesheetEntryId: number
  ) => void;
  retrieveAllTimesheetEntriesFromPeriod: (timesheetId: number) => void;
  removeAttachedFileFromNewLineItem: () => void;
  createNewInstruction: ({
    text,
    typeOf
  }: {
    text: string;
    typeOf: number;
  }) => void;
  deleteInvoiceNote: ({ id }: { id: number }) => void;
  updateInvoiceNote: ({ text, id }: { text: string; id: number }) => void;
  user?: ICurrentUser;
  openInvoiceDetail: (id: number) => void;
  leftDrawerIsOpen: boolean;
}

export interface IInvoiceColumn {
  width: number;
  className: string;
  dataKey: string;
  isActionButton?: boolean;
  type?: InputType;
  items?: Deliverable[] | ProjectRole[];
  label?: string;
  cellRenderer?: TableCellRenderer;
  sortable?: boolean;
}

interface IInvoiceDetailState {
  dismissAllConfirmModalOpen: boolean;
  isPopoverOpen: boolean;
  sidebarOpen: boolean;
  selectedItem: number;
  lineItemModalOpen: boolean;
  attachedFile?: File;
  selectedType: string;
  timesheetDialogOpen: boolean;
  calculatedTotal: string;
  loading: boolean;
}

export const InvoiceDetail: FunctionComponent<IInvoiceDetailProps> = props => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [state, setState] = useState<IInvoiceDetailState>({
    dismissAllConfirmModalOpen: false,
    isPopoverOpen: false,
    sidebarOpen: true,
    selectedItem: -1,
    lineItemModalOpen: false,
    selectedType: '',
    timesheetDialogOpen: false,
    loading: true,
    calculatedTotal: formatAsCurrency(0)
  });

  useEffect(() => {
    setIsFirstRender(false);
    if (props.view || props.isNew) {
      setState({ ...state, loading: false });
    }
  }, []);

  useEffect(() => {
    if (isFirstRender) return;

    if (state.loading && props.view) {
      setState({ ...state, loading: false });
    }
  }, [props.view]);

  const invoiceAmount = useMemo(() => {
    const { view } = props;
    if (!view) return '--';
    if (view.id) return view.amount;

    // calculate the amount if the invoice doesn't exist
    return formatAsCurrency(sumInvoiceTotal(view));
  }, [props.view]);

  const toggleDismissAllModal = () => {
    setState({
      ...state,
      dismissAllConfirmModalOpen: !state.dismissAllConfirmModalOpen
    });
  };

  const onClickNewLineItem = () => {
    resetSelectedItem();
    openLineItemModal();
  };

  const renderEmployeeView = () => {
    const { view } = props;
    const { selectedItem, selectedType, sidebarOpen } = state;

    if (!view) {
      return null;
    }

    const canEdit = canCanEdit();
    const canDismissAll = canCanDismissAll();

    return (
      <React.Fragment>
        <TableHeader>
          Personnel for {formatDatePeriod(view.startDate, view.endDate, true)}
          {canEdit && (
            <DismissAllInvoicesButton
              startIcon={canDismissAll ? <RemoveCircle /> : <Restore />}
              onClick={toggleDismissAllModal}
            >
              {canDismissAll ? 'Dismiss All' : 'Restore All'}
            </DismissAllInvoicesButton>
          )}
        </TableHeader>
        <TimesheetRowTable
          data={view.userRows}
          dismissItem={props.dismissTimesheetRowItem}
          projectRoles={view.projectRoles}
          deliverables={view.deliverables}
          canEdit={canEdit}
          setSelectedItem={(idx: number) => setSelectedItem(idx, 'userRows')}
          selectedItem={selectedType === 'userRows' ? selectedItem : -1}
          sendOverdueTimesheetNotification={
            props.sendOverdueTimesheetNotification
          }
          onDoubleClick={openTimesheetDialog}
          sidebarOpen={sidebarOpen}
        />
      </React.Fragment>
    );
  };

  const setSelectedItem = (selectedItem: number, selectedType: string) => {
    setState({ ...state, selectedItem, selectedType, sidebarOpen: true });
  };

  const resetSelectedItem = () => {
    setState(state => ({
      ...state,
      selectedItem: -1,
      selectedType: '',
      sidebarOpen: false
    }));
  };

  const canCanDismissAll = () => {
    const view = props.view;

    return (
      view && view.timesheetEntries.some(entry => entry.dismissed === false)
    );
  };

  const canCanEdit = () => {
    return props.view
      ? isEditableStatus(props.view.status, props.isAdmin)
      : false;
  };

  const openLineItemModal = () => {
    setState(state => ({ ...state, lineItemModalOpen: true }));
  };

  const closeLineItemModal = () => {
    removeFile();
    resetSelectedItem();
    setState({ ...state, lineItemModalOpen: false });
  };

  const updateLineItem = ({
    lineItem,
    index
  }: {
    lineItem: IFixedLineItem;
    index: number;
  }) => {
    modLineItem({ lineItem, index });
    props.updateFixedLineItem(lineItem);
  };

  const modLineItem = ({
    lineItem,
    index
  }: {
    lineItem: IFixedLineItem | null;
    index: number;
  }) => {
    const { view: newView } = props;

    if (newView && newView.fixedLineItems && lineItem) {
      const fixedLineItems = newView.fixedLineItems;
      fixedLineItems.splice(index, 1, lineItem);
      newView.fixedLineItems = _.compact(fixedLineItems);
      props.modifyLocalView(newView);
    }
  };

  const renderLineItemView = () => {
    const { isAdmin, view } = props;
    const { selectedItem, selectedType } = state;
    if (!view) {
      return null;
    }
    return (
      <React.Fragment>
        <TableHeader>
          Fixed Line Items for{' '}
          {formatDatePeriod(view.startDate, view.endDate, true)}
          {canCanEdit() && (
            <AddLineItemButton startIcon={<Add />} onClick={onClickNewLineItem}>
              Line Item
            </AddLineItemButton>
          )}
        </TableHeader>
        <LineItemTable
          data={view.fixedLineItems}
          deleteItem={props.deleteLineItem}
          deliverables={props.view ? props.view.deliverables : []}
          canEdit={
            props.view ? isEditableStatus(props.view.status, isAdmin) : false
          }
          downloadFile={props.downloadFile}
          onEditLineItem={() => openLineItemModal()}
          modLineItem={modLineItem}
          selectedItem={selectedType === 'fixedLineItems' ? selectedItem : -1}
          setSelectedItem={(idx: number) =>
            setSelectedItem(idx, 'fixedLineItems')
          }
        />
      </React.Fragment>
    );
  };

  const getFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();
    if (e.target && e.target.files) {
      const file: File | undefined = _.head(e.target.files);
      if (file) {
        setAttachedFile({ file });
        reader.onloadend = () => {
          props.uploadNewFile({ file });
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const setAttachedFile = ({ file }: { file: File }) => {
    setState({ ...state, attachedFile: file });
  };

  const removeFile = () => {
    setState({ ...state, attachedFile: undefined });
    props.removeAttachedFileFromNewLineItem();
  };

  const openTimesheetDialog = () => {
    const { selectedItem } = state;
    const { view, retrieveAllTimesheetEntriesFromPeriod } = props;
    if (view && selectedItem > -1) {
      retrieveAllTimesheetEntriesFromPeriod(
        view.userRows[selectedItem].timesheetId
      );
    }
    setState({ ...state, timesheetDialogOpen: true });
  };

  const closeTimesheetDialog = () => {
    resetSelectedItem();
    setState({ ...state, timesheetDialogOpen: false });
  };

  const saveTimesheet = ({ view }: { view: IInvoiceDetailView }) => {
    props.saveInvoice({ view });
    if (!_.isEmpty(diff(view, props.view))) {
      setState({ ...state, loading: true });
    }
  };

  const toggleInvoicePopover = (value: boolean) => {
    setState({ ...state, isPopoverOpen: value });
  };

  const { invoiceSidebarDetail, view, isNew, user } = props;
  const {
    dismissAllConfirmModalOpen,
    isPopoverOpen,
    selectedItem,
    lineItemModalOpen,
    attachedFile,
    timesheetDialogOpen,
    calculatedTotal,
    loading
  } = state;

  if (!user) {
    return null;
  }
  if (loading) {
    return <LoadingPage />;
  }

  const canEdit =
    user && view ? isEditableStatus(view.status, isAdmin(user)) : false;

  const hasInvoiceInstructions = invoiceSidebarDetail.notes.some(
    n => !n.userName || n.typeOf === ENoteType.INVOICE_SPECIAL_INSTRUCTION
  );

  return (
    <Container>
      <InvoiceDetailSubNav
        {...view}
        isNew={isNew && !view}
        amount={invoiceAmount}
        leftDrawerIsOpen={props.leftDrawerIsOpen}
        onInvoiceDetailsClick={() => toggleInvoicePopover(true)}
        hasInstructions={hasInvoiceInstructions}
      >
        {isPopoverOpen && (
          <InvoicePopover
            canEdit={canEdit}
            invoiceSidebarDetail={invoiceSidebarDetail}
            view={view}
            onClose={() => toggleInvoicePopover(false)}
          />
        )}
      </InvoiceDetailSubNav>
      {renderEmployeeView()}
      {renderLineItemView()}
      {lineItemModalOpen && canEdit && view && (
        <LineItemModal
          close={closeLineItemModal}
          lineItemToEdit={props.view?.fixedLineItems[state.selectedItem]}
          onAddLineItem={(lineItem: IFixedLineItem) => {
            props.saveLineItem(lineItem);
            removeFile();
          }}
          deliverables={view.deliverables}
          uploadNewFile={({ file }: { file: File }) => {
            props.uploadNewFile({ file });
            setAttachedFile({ file });
          }}
          addFile={getFile}
          attachedFile={attachedFile}
          removeFile={removeFile}
          updateLineItem={updateLineItem}
          selectedItem={
            state.selectedType === 'fixedLineItems' ? state.selectedItem : -1
          }
        />
      )}
      {timesheetDialogOpen && view && (
        <InvoiceEntryDialog
          view={view}
          selectedItem={selectedItem}
          closeTimesheetDialog={closeTimesheetDialog}
          saveInvoice={saveTimesheet}
          canEdit={canEdit}
          amount={view?.total ? view.total : calculatedTotal}
        />
      )}
      {dismissAllConfirmModalOpen && view && (
        <ConfirmModal
          onConfirm={() => {
            props.dismissAllTimesheets();
            toggleDismissAllModal();
          }}
          onCancel={() => {
            toggleDismissAllModal();
          }}
          question={`Are you sure you want to ${
            canCanDismissAll() ? 'dismiss' : 'restore'
          } all invoices?`}
        />
      )}
    </Container>
  );
};

export default InvoiceDetail;
