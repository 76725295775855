import _ from 'lodash';
import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';

export const deleteDeliverable = (deliverableId: number) =>
  callAPI({
    method: 'DELETE',
    url: `/deliverables/${deliverableId}`
  })
    .then(() => {
      return deliverableId;
    })
    .catch(e => {
      const error = _.get(e, 'response', null);
      const msg = error.data.sow || 'Failed to delete deliverable';
      toast(msg, 'error');
      throw e;
    });
