import React from 'react';
import { Box } from '@mui/material';
import styled from 'src/theme';

const ProjectManagerColumn = styled(Box)`
  display: flex;
`;

interface IProjectManagerCellProps {
  projectManager: string;
}

export const ProjectManagerCell = (props: IProjectManagerCellProps) => {
  const { projectManager } = props;
  return <ProjectManagerColumn> {projectManager}</ProjectManagerColumn>;
};
