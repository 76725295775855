import { MAX_SIDEBAR_TIMESHEETS_PER_PAGE } from 'src/constants';
import TimesheetsUtil from 'src/shared/timesheets/util';
import Api from '../api';
import { TimesheetSidebarDetail, TimesheetStatusEnum } from '../types';

export const fetchTimesheetSidebarDetail = async ({
  timesheetId,
  timesheetsPageSize = MAX_SIDEBAR_TIMESHEETS_PER_PAGE
}: {
  timesheetId: number;
  timesheetsPageSize?: number;
}) => {
  const [timesheet, notes, userProjectRoles] = await Promise.all([
    Api.fetchTimesheet(timesheetId),
    Api.fetchTimesheetNotes(timesheetId),
    Api.fetchUserProjectRoles()
  ]);
  timesheet.notes = notes;
  const timesheets = await Api.fetchTimesheets({
    page: 0,
    perPage: timesheetsPageSize,
    ownerId: timesheet.owner.id,
    status: TimesheetStatusEnum.APPROVED,
    order: 'startDate',
    direction: 'desc'
  });
  const timesheetsUtil = new TimesheetsUtil();
  const averageWorkDay = timesheetsUtil.averageWorkday(
    timesheet.timesheetDays,
    timesheet.owner.classification
  );
  timesheet.averageWorkDay = parseFloat(averageWorkDay.toFixed(1));
  timesheet.totalUtilization = parseInt(timesheet.totalUtilization.toFixed(2));
  timesheet.totalBillUtilization = parseInt(
    timesheet.totalBillUtilization.toFixed(2)
  );
  timesheet.totalHours =
    Math.round(
      (timesheetsUtil.totalHours(
        timesheet.timesheetDays,
        timesheet.owner.classification
      ) +
        Number.EPSILON) *
        100
    ) / 100;
  const totalTimesheets =
    timesheets.length > 0 ? timesheets[0].totalEntries : 0;

  const teamTimesheets = timesheet.owner.isManager
    ? await Api.fetchTeamTimesheets(timesheet)
    : [];

  return {
    timesheet: timesheet,
    timesheetsList: timesheets,
    totalTimesheets: totalTimesheets,
    teamTimesheets: teamTimesheets,
    teamDetailsStartDate: timesheet.startDate,
    teamDetailsEndDate: timesheet.endDate,
    userProjectRoles,
    isLoading: false
  } as TimesheetSidebarDetail;
};
