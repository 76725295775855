import React from 'react';
import { Pagination } from '@mui/material';
import { IPaginatorProps } from './types';

/**
 * NOTE: can likely remove once refactored away from class components.
 * It is simple enough to implement in components directly.
 */
const Paginator: React.FC<IPaginatorProps> = ({
  total,
  onPageUpdate,
  perPage
}) => {
  const numOfPages = Math.ceil(total / perPage);

  const updatePage = (page: number) => {
    onPageUpdate(page);
  };

  return (
    <Pagination
      count={numOfPages}
      onChange={(event, page) => updatePage(page)}
    />
  );
};

export default Paginator;
