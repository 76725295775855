import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';

export const deleteUserProjectRole = (id: number) =>
  callAPI({
    method: 'delete',
    url: `/user_project_prefs/${id}`
  }).catch(error => {
    toast('Failed to update user project role', 'error');
    throw error;
  });
