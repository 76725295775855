import * as React from 'react';
import ClientDetailActions from 'src/components/ClientDetailActions';
import ClientStatusChange from 'src/components/ClientStatusChange';
import ClientDetail from '../../components/ClientDetail';
import { FunctionComponent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { QueryKeys } from 'src/queries/queryKeys';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Api } from 'src/api/api';

export const ClientDetailContainer: FunctionComponent = props => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showStatusDialog, setShowStatusDialog] = useState(false);
  const queryClient = useQueryClient();

  const { data: selectedClient } = useQuery(QueryKeys.client(id), () => {
    if (!id) return null;
    return Api.fetchClients({ id }).then(response => response[0]);
  });

  const { mutateAsync: updateClient } = useMutation(Api.updateClient, {
    onSuccess: updatedClient => {
      queryClient.invalidateQueries(QueryKeys.clients);
      queryClient.setQueryData(QueryKeys.client(id), updatedClient);
      navigate(`/client-management/${id}`, { replace: true });
    }
  });

  const toggleStatusDialog = () => {
    setShowStatusDialog(!showStatusDialog);
  };

  const changeClientStatus = () => {
    if (!selectedClient) return;

    const { id, code, color, isActive, name } = selectedClient;

    updateClient({
      id,
      code,
      color,
      isActive: !isActive,
      name
    }).then(() => {
      toggleStatusDialog();
    });
  };

  const pushToEdit = () => {
    navigate(`/client-management/${id}/edit`);
  };

  return (
    <React.Fragment>
      <ClientStatusChange
        performAction={changeClientStatus}
        deleteEnabled={selectedClient ? selectedClient.isActive : true}
        open={showStatusDialog}
        handleClose={toggleStatusDialog}
      />
      <ClientDetailActions
        onEditClick={pushToEdit}
        clientStatus={selectedClient ? selectedClient.isActive : true}
        openDialog={toggleStatusDialog}
        disabled={!selectedClient}
      />
      <ClientDetail selectedClient={selectedClient} />
    </React.Fragment>
  );
};

export default ClientDetailContainer;
