import CloseIcon from '@mui/icons-material/Close';
import { Popover } from '@mui/material';
import { DialogContent } from '@mui/material';
import styled, { theme } from 'src/theme';

export const CloseButton = styled(CloseIcon)`
  && {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;

export const InvoiceDetailPopover = styled(Popover)`
  && {
    padding: 15px;
    margin: 10px;
    margin-top: 100px;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  && {
    padding: 0;
  }
`;

export const PopoverTitle = styled.div`
  && {
    position: sticky;
    top: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 12px 15px 12px;
    background-color: ${theme.white};
  }
`;

export const InvoiceTitle = styled.div`
  && {
    display: flex;
    align-items: center;
    font-family: ${theme.font};
    font-size: 20px;
  }
`;
