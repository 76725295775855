import * as React from 'react';
import _ from 'lodash';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import SortIcon from '@mui/icons-material/ImportExport';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Tooltip } from '@mui/material';
import { InvoiceDate } from '../InvoiceDate';
import { InvoicePopup } from '../InvoicePopup';
import { ClientProject } from '../ClientProject';
import { actions as invoiceActions } from 'src/shared/invoice-list/actions';

import {
  SortBox,
  CustomTable,
  CustomTableAmountColumn,
  CustomTableDismissColumn,
  CustomTableInfoColumn,
  StatusBox,
  StatusIcon,
  TableWrapper
} from './styles';

import {
  AutoSizer,
  Column,
  RowMouseEventHandlerParams,
  SortDirectionType,
  TableCellProps
} from 'react-virtualized';

import {
  InvoiceStatus,
  IInvoiceSidebarDetail,
  DISMISSED,
  SENT,
  PAID,
  SUBMITTED,
  IInvoiceListFilters,
  MY_INVOICES
} from 'src/shared/invoice-list/types';
import { NewInvoiceState, NewInvoiceParams } from 'src/shared/invoice/types';
import { isAdmin } from 'src/shared/auth/utils';
import { ICurrentUser } from 'src/shared/auth/types';
import { ProjectManagerCell } from '../ProjectManagerCell';
import { FunctionComponent, useState } from 'react';
import numeral from 'numeral';
import CursorPaginator from 'src/components/core/CursorPagination';
import { Invoice, UninvoicedProject } from 'src/api/types';

interface IInvoiceStatusList {
  invoices?: (Invoice | UninvoicedProject)[];
  filters: IInvoiceListFilters;
  goToDetail: (id: string | number) => void;
  selectedItem?: Invoice | null;
  getNewInvoiceView: ({
    filters
  }: {
    filters: NewInvoiceState['filters'];
  }) => void;
  sortPage: (sortBy: string, sortDirection: string) => void;
  total: number;
  page: number;
  changeStatus: ({ status, id }: { status: InvoiceStatus; id: number }) => void;
  onClickCreateButton: ({
    filters,
    dismiss
  }: {
    filters: { project: number; period: string; client: number };
    dismiss?: boolean;
  }) => void;
  onSelectInvoice: typeof invoiceActions.selectInvoice;
  currentUser: ICurrentUser;
  createNewInvoice: (params: NewInvoiceParams, dismiss?: boolean) => void;
  invoiceSidebarDetail: IInvoiceSidebarDetail;
  fetchMoreInvoices: () => void;
  createNewInstruction: ({
    text,
    typeOf
  }: {
    text: string;
    typeOf: number;
  }) => void;
  deleteInvoiceNote: ({ id }: { id: number }) => void;
  updateInvoiceNote: ({ text, id }: { text: string; id: number }) => void;
  openInvoiceDetail: (id?: number) => void;
  canEdit: boolean;
  clearDetail: () => void;
  loading: boolean;
  hasNextPage?: boolean;
  hasPrevPage?: boolean;
  onNextPage: () => void;
  onPrevPage: () => void;
}

interface IInvoiceStatusListState {
  invoicePopupOpen: boolean;
}

const InvoiceListTable: FunctionComponent<IInvoiceStatusList> = props => {
  const invoices = props.invoices || [];

  const [state, setState] = useState<IInvoiceStatusListState>({
    invoicePopupOpen: false
  });

  const handleRowClick = (e: RowMouseEventHandlerParams) => {
    props.clearDetail();
    if (e && e.rowData && e.rowData.id) {
      props.goToDetail(e.rowData.id.toString());
    } else {
      const { clientId, projectId, startDate, endDate } = e.rowData;
      props.getNewInvoiceView({
        filters: {
          client: clientId,
          project: projectId,
          period: `${startDate} - ${endDate}`,
          importTimesheets: true
        }
      });
      props.goToDetail('new');
    }
  };

  const onCloseInvoicePopup = () => {
    setState({ ...state, invoicePopupOpen: false });
  };

  const onOpenInvoicePopup = () => {
    setState({ ...state, invoicePopupOpen: true });
  };

  const rowClassName = (event: RowMouseEventHandlerParams) => {
    const invoice = invoices[event.index];
    if (props.selectedItem && invoice) {
      if (
        (invoice as Invoice).id === props.selectedItem.id.toString() ||
        _.eq(invoices[event.index], props.selectedItem)
      ) {
        return 'active row';
      }
    }
    return 'row';
  };

  const rowGetter = ({ index }: { index: number }) => {
    if (!invoices?.length) return undefined;

    return invoices[index];
  };

  const renderClientProject = (event: TableCellProps) => {
    const { clientName, projectNickname, clientColor } = event.rowData;
    return (
      <ClientProject
        clientName={clientName}
        projectNickname={projectNickname}
        clientColor={clientColor}
      />
    );
  };

  const renderInvoiceDate = (event: TableCellProps) => {
    return <InvoiceDate {...event} />;
  };

  const projectManagerRenderer = (event: TableCellProps) => {
    const { projectManagerName } = event.rowData;
    const pm = (projectManagerName as string) || '';
    return <ProjectManagerCell projectManager={pm} />;
  };

  const renderAmount = (event: TableCellProps) => {
    const {
      filters: { invoices }
    } = props;

    const amount =
      event.cellData === undefined
        ? '--'
        : numeral(event.cellData).format('$0,0.00');

    return (
      <CustomTableAmountColumn $isMyInvoices={invoices === MY_INVOICES}>
        {amount}
      </CustomTableAmountColumn>
    );
  };

  const renderStatus = (event: TableCellProps) => {
    const {
      rowData: { status }
    } = event;
    let textStatus = '';
    let statusClass = '';
    switch (status) {
      case InvoiceStatus.NEW:
        textStatus = 'READY';
        statusClass = 'ready';
        break;
      case InvoiceStatus.IN_PROGRESS:
        textStatus = 'IN-PROGRESS';
        statusClass = 'in-progress';
        break;
      case InvoiceStatus.REJECTED:
        textStatus = 'REJECTED';
        statusClass = 'rejected';
        break;
      case undefined:
        textStatus = 'PENDING';
        statusClass = 'in-progress';
        break;
      default:
        break;
    }
    return (
      <StatusBox>
        <StatusIcon className={statusClass} />
        {textStatus}
      </StatusBox>
    );
  };

  const onClickActionButton = (rowData: {
    nextStatus: InvoiceStatus;
    id: number;
  }) => {
    const { nextStatus, id } = rowData;
    props.changeStatus({ status: nextStatus, id });
  };

  const renderInfoIcon = (e: TableCellProps) => {
    const { rowData, rowIndex } = e;
    return (
      <CustomTableInfoColumn>
        <ErrorOutlineIcon
          className="infoButton hidden"
          onClick={(e: any) => {
            e.stopPropagation();
            props.onSelectInvoice({
              id: rowData.id,
              index: rowIndex,
              invoice: rowData
            });
            onOpenInvoicePopup();
          }}
        />
      </CustomTableInfoColumn>
    );
  };

  const renderDismissIcon = (e: TableCellProps) => {
    const {
      rowData: { status, id, projectId, period, canProceed }
    } = e;
    const { currentUser } = props;
    let displayDismiss =
      !_.some(
        [DISMISSED, SENT, PAID],
        (s: string) => _.upperCase(s) === status
      ) && !(status === _.upperCase(SUBMITTED) && !isAdmin(currentUser));
    if (_.eq(status, 'UNINVOICED')) {
      displayDismiss = canProceed;
    }

    return (
      <CustomTableDismissColumn>
        {displayDismiss && (
          <Tooltip title="Dismiss" className="dismissButton">
            <RemoveCircleRoundedIcon
              className="red hidden"
              onClick={(e: any) => {
                e.stopPropagation();
                if (!id) {
                  // then this is an uninvoiced deliverables row, and we have to first create and invoice,
                  // and then dismiss the new invoice
                  return props.createNewInvoice(
                    {
                      projectId,
                      period
                    },
                    true
                  );
                }
                return onClickActionButton({
                  nextStatus: InvoiceStatus.DISMISSED,
                  id
                });
              }}
            />
          </Tooltip>
        )}
      </CustomTableDismissColumn>
    );
  };

  const tableHeaderRenderer = (
    col: { headerRenderer?: () => React.ReactNode; sortable: boolean },
    sortDirection?: string,
    sortBy?: string
  ) => {
    return ({ label, dataKey }: { dataKey: string; label: any }) => {
      if (dataKey === 'amount') {
        const {
          filters: { invoices }
        } = props;
        return (
          <CustomTableAmountColumn $isMyInvoices={invoices === MY_INVOICES}>
            {label}
          </CustomTableAmountColumn>
        );
      }

      if (sortBy === dataKey) {
        return (
          <SortBox>
            {label}{' '}
            {sortDirection === 'ASC' ? <ArrowUpward /> : <ArrowDownward />}
          </SortBox>
        );
      }

      if (col.sortable) {
        return (
          <SortBox>
            {label}
            <SortIcon />
          </SortBox>
        );
      }

      if (col.headerRenderer) {
        return col.headerRenderer();
      }

      return <Box>{label}</Box>;
    };
  };

  let columns: any = [
    {
      dataKey: 'PROJECT',
      className: 'client-name-project',
      label: 'Client Project',
      cellRenderer: renderClientProject,
      sortable: true,
      width: 1
    },
    {
      dataKey: 'DATE',
      label: 'Invoice Period',
      className: 'invoicing-date-row',
      cellRenderer: renderInvoiceDate,
      sortable: true,
      width: 5
    },
    {
      dataKey: 'invoicePM',
      label: 'Project Manager',
      className: 'invoice-project-manager',
      cellRenderer: projectManagerRenderer,
      width: 1
    },
    {
      dataKey: 'amount',
      label: 'Amount',
      cellRenderer: renderAmount,
      flexGrow: 3,
      flexShrink: 3,
      width: 5
    },
    {
      dataKey: 'STATUS',
      cellRenderer: renderStatus,
      label: 'Status',
      sortable: props.filters.status !== 'Pending',
      flexGrow: 3,
      flexShrink: 3,
      width: 5
    }
  ];

  if (props.filters.status !== 'Pending') {
    columns = columns.concat([
      {
        dataKey: 'invoicePopup',
        cellRenderer: renderInfoIcon,
        className: 'info-col',
        flexGrow: 1,
        flexShrink: 1,
        headerRenderer: () => <CustomTableInfoColumn />,
        label: '',
        width: 1
      },
      {
        dataKey: '',
        cellRenderer: renderDismissIcon,
        className: 'dismiss-col',
        flexGrow: 1,
        flexShrink: 1,
        headerRenderer: () => (
          <CustomTableDismissColumn></CustomTableDismissColumn>
        ),
        label: '',
        width: 1
      }
    ]);
  }

  const {
    selectedItem,
    invoiceSidebarDetail,
    fetchMoreInvoices,
    createNewInstruction,
    deleteInvoiceNote,
    updateInvoiceNote,
    openInvoiceDetail,
    canEdit,
    currentUser,
    hasNextPage,
    hasPrevPage,
    onNextPage,
    onPrevPage
  } = props;
  const { invoicePopupOpen } = state;
  const { sortBy, sortDirection } = props.filters;

  return (
    <>
      {invoicePopupOpen && selectedItem && (
        <InvoicePopup
          invoicePopupOpen={invoicePopupOpen}
          invoice={selectedItem}
          onClose={onCloseInvoicePopup}
          invoiceSidebarDetail={invoiceSidebarDetail}
          fetchMoreInvoices={fetchMoreInvoices}
          createNewInstruction={createNewInstruction}
          deleteInvoiceNote={deleteInvoiceNote}
          updateInvoiceNote={updateInvoiceNote}
          openInvoiceDetail={openInvoiceDetail}
          canEdit={canEdit}
          currentUser={currentUser}
        />
      )}
      <React.Fragment>
        <TableWrapper>
          <AutoSizer key="as">
            {({ height, width }) => (
              <CustomTable
                onRowClick={handleRowClick}
                headerClassName="headerRow"
                rowClassName={rowClassName}
                headerHeight={50}
                height={height}
                rowCount={invoices?.length}
                rowHeight={60}
                rowGetter={rowGetter}
                width={width}
                sort={({ sortBy, sortDirection }) => {
                  props.sortPage(sortBy, sortDirection);
                }}
                sortBy={sortBy}
                sortDirection={sortDirection as SortDirectionType}
              >
                {_.map(columns, (col: any, index: number) => (
                  <Column
                    key={index}
                    className={`column ${col.className && col.className}`}
                    label={col.label}
                    dataKey={col.dataKey}
                    width={col.width}
                    flexGrow={col.flexGrow || 5}
                    flexShrink={col.flexShrink || 0}
                    cellRenderer={col.cellRenderer || undefined}
                    headerRenderer={tableHeaderRenderer(
                      col,
                      sortDirection,
                      sortBy
                    )}
                    disableSort={!col.sortable}
                    headerStyle={{ paddingLeft: '24px' }}
                    style={{ paddingLeft: '24px' }}
                  />
                ))}
              </CustomTable>
            )}
          </AutoSizer>
        </TableWrapper>
        <CursorPaginator
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
        />
      </React.Fragment>
    </>
  );
};

export default InvoiceListTable;
