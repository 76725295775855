import styled from '../../theme';

export const Container = styled.div`
  padding: 65px 0px 10px 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

export const ScrolledContainer = styled.div`
  display: flex;
  flex: 2;
  height: 100%;
  overflow-x: scroll;
  flex-direction: column;
  padding: 0px 10px 5px 10px;
`;

export const SimpleContainer = styled.div`
  flex: 1;
  display: flex;
  padding: 80px 0 10px 0px;
  flex-direction: column;
`;

export const RightContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
`;
