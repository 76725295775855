import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IAppState } from 'src/reducer';
import * as invoiceEmailActions from 'src/shared/invoice-emails/actions';
import * as invoicesActions from 'src/shared/invoice-list/actions';
import RightSideMenu from 'src/components/ProjectsRightMenu';
import { IInvoice } from '../../shared/invoice-list/types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Api } from 'src/api/api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Deliverable,
  InvoiceEmail,
  ProjectDetail,
  ProjectRole
} from 'src/api/types';
import { fetchProjectDetail } from 'src/api/queries/fetchProjectDetail';
import { QueryKeys } from 'src/queries/queryKeys';

type IProjectsDetailContainerAllProps = IProjectsDetailContainerProps &
  IProjectsDetailContainerStoreProps &
  IProjectsDetailContainerDispatch;

interface IProjectsDetailContainerProps {
  backRoute: string;
}

interface IProjectsDetailContainerStoreProps {
  invoices: IInvoice[];
  loadingCreateInvoiceEmail: boolean;
}

interface IProjectsDetailContainerDispatch {
  getInvoicesByProjectId: (projectId: number) => void;
  createInvoiceEmail: typeof invoiceEmailActions.actions.createInvoiceEmail;
  deleteInvoiceEmail: typeof invoiceEmailActions.actions.deleteInvoiceEmail;
}

export const ProjectsDetailContainer: FunctionComponent<IProjectsDetailContainerAllProps> = props => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [state, setState] = useState({
    currentTab: 0,
    offset: 0,
    showStatusDialog: false
  });
  const { id } = params;

  /* effects */
  useEffect(() => {
    if (!id) return;

    props.getInvoicesByProjectId(Number(id));
  }, [id]);

  /* helpers */
  const addDeliverableLocally = (deliverable: Deliverable) => {
    queryClient.setQueryData(
      QueryKeys.project(id),
      (oldData: ProjectDetail) => {
        return {
          ...oldData,
          deliverables: oldData.deliverables.concat([deliverable])
        };
      }
    );
  };

  const addEmailLocally = (email: InvoiceEmail, type: string) => {
    queryClient.setQueryData(
      QueryKeys.project(id),
      (oldData: ProjectDetail) => {
        const emailContainerKey =
          type === 'cc' ? 'invoiceEmailCcs' : 'invoiceEmailTos';
        return {
          ...oldData,
          [emailContainerKey]: oldData[emailContainerKey].concat([email])
        };
      }
    );
  };

  const addRoleLocally = (role: ProjectRole) => {
    queryClient.setQueryData(
      QueryKeys.project(id),
      (oldData: ProjectDetail) => {
        return {
          ...oldData,
          roles: oldData.roles.concat([role])
        };
      }
    );
  };

  const removeDeliverableLocally = (deliverableId: number) => {
    queryClient.setQueryData(
      QueryKeys.project(id),
      (oldData: ProjectDetail) => {
        return {
          ...oldData,
          deliverables: oldData.deliverables.filter(d => d.id !== deliverableId)
        };
      }
    );
  };

  const removeEmailLocally = (emailId: number) => {
    queryClient.setQueryData(
      QueryKeys.project(id),
      (oldData: ProjectDetail) => ({
        ...oldData,
        invoiceEmailCcs: oldData.invoiceEmailCcs.filter(e => e.id !== emailId),
        invoiceEmailTos: oldData.invoiceEmailTos.filter(e => e.id !== emailId)
      })
    );
  };

  const updateDeliverableLocally = (deliverable: Deliverable) => {
    queryClient.setQueryData(
      QueryKeys.project(id),
      (oldData: ProjectDetail) => {
        const deliverables = oldData.deliverables.slice();
        const targetIndex = deliverables.findIndex(
          d => d.id === deliverable.id
        );
        deliverables.splice(targetIndex, 1, deliverable);
        return { ...oldData, deliverables };
      }
    );
  };

  const updateRoleLocally = (role: ProjectRole) => {
    queryClient.setQueryData(
      QueryKeys.project(id),
      (oldData: ProjectDetail) => {
        const roles = oldData.roles.slice();
        const targetIndex = roles.findIndex(r => r.id === role.id);
        roles.splice(targetIndex, 1, role);
        return { ...oldData, roles };
      }
    );
  };

  /* queries & mutations */
  const {
    data: project,
    refetch: fetchProject,
    isLoading: isLoadingProject
  } = useQuery(
    QueryKeys.project(id),
    () => {
      if (!id) return;

      return fetchProjectDetail(id);
    },
    { cacheTime: 0 }
  );

  const { data: projectUserStats } = useQuery(
    [QueryKeys.projectUserStats, Number(id), state.offset],
    () => Api.fetchProjectUserStats(Number(id), state.offset),
    { enabled: Boolean(id) }
  );

  const { data: projectCreateOptions } = useQuery(
    [QueryKeys.projectCreateOptions],
    () => Api.fetchProjectCreateOptions()
  );

  const { mutate: updateProject } = useMutation(Api.updateProject, {
    onSuccess: () => {
      fetchProject();
      queryClient.invalidateQueries(QueryKeys.projectStats); // causes projects refetch in ProjectsListContainer
    }
  });

  const {
    mutate: createDeliverable,
    isLoading: isCreatingDeliverable
  } = useMutation(Api.createDeliverable, {
    onSuccess: addDeliverableLocally
  });

  const {
    mutate: updateDeliverable,
    isLoading: isUpdatingDeliverable
  } = useMutation(Api.updateDeliverable, {
    onSuccess: updateDeliverableLocally
  });

  const { mutate: deleteDeliverable } = useMutation(Api.deleteDeliverable, {
    onSuccess: removeDeliverableLocally
  });

  const {
    mutate: createProjectRole,
    isLoading: isCreatingProjectRole
  } = useMutation(Api.createProjectRole, { onSuccess: addRoleLocally });

  const {
    mutate: updateProjectRole,
    isLoading: isUpdatingProjectRole
  } = useMutation(Api.updateProjectRole, { onSuccess: updateRoleLocally });

  /* view event handlers */
  const onChangeProjectStatus = () => {
    if (!project) return;

    onToggleStatusDialog();
    updateProject({
      id: project.id,
      project: {
        budgetPercentage: project.budgetPercentagePreference,
        client: project.clientId,
        manager: project.projectManagerId,
        name: project.name,
        nickname: project.nickname,
        status: Number(!project.isActive)
      }
    });
  };

  const onCreateInvoiceEmail = (
    type: string,
    data: any,
    success: () => void
  ) => {
    if (!project) return;

    props.createInvoiceEmail(project.id, { type, ...data }, createdEmail => {
      addEmailLocally(createdEmail, type);
      success();
    });
  };

  const onCreateRole = (data: Partial<ProjectRole>, callback: () => void) => {
    if (!project) return;

    createProjectRole({ projectId: project.id, data }, { onSuccess: callback });
  };

  const onDeleteInvoiceEmail = (id: any) => {
    if (!project) return;

    props.deleteInvoiceEmail(id.cellData, project.id, removeEmailLocally);
  };

  const onEditRole = (data: Partial<ProjectRole>, callback: () => void) => {
    if (!project) return;

    updateProjectRole({ projectId: project.id, data }, { onSuccess: callback });
  };

  const onCreateDeliverable = (data: any, callback: () => void) => {
    if (!project) return;

    createDeliverable(
      {
        projectId: project.id,
        data: { ...data, isActive: Boolean(data.isActive) }
      },
      { onSuccess: callback }
    );
  };

  const onDeleteDeliverable = (deliverableId: number, callback: () => void) => {
    deleteDeliverable(deliverableId, {
      onSuccess: () => {
        callback();
      }
    });
  };

  const onEditClick = () => {
    navigate(`${props.backRoute}/${Number(id)}/edit`);
  };

  const onUpdateDeliverable = (data: any, callback: () => void) => {
    if (!project) return;

    updateDeliverable(
      {
        projectId: project.id,
        data: { ...data, isActive: Boolean(data.isActive) }
      },
      { onSuccess: callback }
    );
  };

  const onToggleOffset = (direction: string) => () => {
    let newOffset = state.offset;
    if (direction === 'next') {
      newOffset = newOffset + 15;
    } else {
      newOffset = newOffset - 15;
    }
    setState({ ...state, offset: newOffset });
  };

  const onToggleStatusDialog = () => {
    setState({
      ...state,
      showStatusDialog: !state.showStatusDialog
    });
  };

  const onUpdateProjectData = (data: any, success: () => void) => {
    if (!project) return;

    const selectedProject = project;
    const updateObject = {
      budgetPercentage: selectedProject.budgetPercentagePreference,
      client: selectedProject.clientId,
      manager: selectedProject.projectManagerId,
      name: selectedProject.name,
      nickname: selectedProject.nickname,
      status: Number(selectedProject.isActive)
    };
    Object.keys(data).forEach(current => {
      updateObject[current] = data[current];
    });
    updateProject(
      { id: selectedProject.id, project: updateObject },
      { onSuccess: success }
    );
  };

  return (
    <RightSideMenu
      project={project}
      isLoading={isLoadingProject}
      changeProjectStatus={onChangeProjectStatus}
      showStatusDialog={state.showStatusDialog}
      toggleStatusDialog={onToggleStatusDialog}
      onEditClick={onEditClick}
      offset={state.offset}
      toggleOffset={onToggleOffset}
      invoices={props.invoices}
      projectUserStats={projectUserStats}
      projectCreateOptions={projectCreateOptions}
      deleteInvoiceEmail={onDeleteInvoiceEmail}
      updateProjectData={onUpdateProjectData}
      createInvoiceEmail={onCreateInvoiceEmail}
      loadingCreateInvoiceEmail={props.loadingCreateInvoiceEmail}
      loadingAddDeliverable={isCreatingDeliverable}
      loadingEditDeliverable={isUpdatingDeliverable}
      createDeliverable={onCreateDeliverable}
      editDeliverable={onUpdateDeliverable}
      deleteDeliverable={onDeleteDeliverable}
      loadingAddRole={isCreatingProjectRole}
      loadingEditRole={isUpdatingProjectRole}
      createRole={onCreateRole}
      editRole={onEditRole}
      path={location.pathname}
    />
  );
};

export const mapStateToProps = (state: IAppState) => {
  return {
    invoices: state.invoices.invoicesByProjectId,
    loadingCreateInvoiceEmail: state.invoicesEmail.loadingCreateInvoiceEmail
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IProjectsDetailContainerDispatch => {
  return bindActionCreators(
    {
      createInvoiceEmail: invoiceEmailActions.actions.createInvoiceEmail,
      deleteInvoiceEmail: invoiceEmailActions.actions.deleteInvoiceEmail,
      getInvoicesByProjectId: invoicesActions.actions.getInvoicesByProjectId
    },
    dispatch
  );
};

export default connect<
  IProjectsDetailContainerStoreProps,
  IProjectsDetailContainerDispatch
>(
  mapStateToProps,
  mapDispatchToProps
)(ProjectsDetailContainer);
