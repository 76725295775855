import styled, { theme } from 'src/theme';
import { Box } from '@mui/material';

export const FileListItem = styled.div`
  margin-top: 8px;
  margin-bottom: 16px;
  li {
    list-style: none;
    color: #00838f;
    font-family: ${theme.font};
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    cursor: pointer;
  }
`;

export const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    color: rgba(0, 0, 0, 0.6);
  }
`;
