import { ActionsUnion, createAction } from 'src/shared/types';
import { IImpersonateUserResponse, IUserPayload } from 'src/shared/users/types';

export const GET_IMPERSONATE_USERS = 'GET_IMPERSONATE_USERS';
export const GET_IMPERSONATE_USERS_SUCCEEDED =
  'GET_IMPERSONATE_USERS_SUCCEEDED';
export const IMPERSONATE_LOGIN = 'IMPERSONATE_LOGIN';
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCEEDED = 'LOGOUT_SUCCEEDED';
export const IMPERSONATE_SUCCEEDED = 'IMPERSONATE_SUCCEEDED';
export const STOP_IMPERSONATE = 'STOP_IMPERSONATE';
export const STOP_IMPERSONATE_SUCCEEDED = 'STOP_IMPERSONATE_SUCCEEDED';
export const LOAD_ORIGINAL_USER = 'LOAD_ORIGINAL_USER';
export const LOAD_ORIGINAL_USER_SUCCEEDED = 'LOAD_ORIGNAL_USER_SUCCEEDED';

const getImpersonateUsers = () => createAction(GET_IMPERSONATE_USERS, {});

const getImpersonateUsersSucceeded = (userData: IImpersonateUserResponse[]) =>
  createAction(GET_IMPERSONATE_USERS_SUCCEEDED, { userData });

const impersonateLoginSucceeded = () => createAction(IMPERSONATE_SUCCEEDED);

const stopImpersonate = () => createAction(STOP_IMPERSONATE);

const stopImpersonateSucceeded = () => createAction(STOP_IMPERSONATE_SUCCEEDED);

const impersonateLogin = (userId: number) =>
  createAction(IMPERSONATE_LOGIN, { userId });

const login = (code: string) => createAction(LOGIN, { code });

const loginSucceeded = () => createAction(LOGIN_SUCCEEDED);

const logout = (force?: boolean) =>
  createAction(LOGOUT, {
    force
  });

const logoutSucceeded = () => createAction(LOGOUT_SUCCEEDED, {});

const loadOriginalUser = () => createAction(LOAD_ORIGINAL_USER, {});

const loadOriginalUserSucceeded = (user: IUserPayload) =>
  createAction(LOAD_ORIGINAL_USER_SUCCEEDED, { user });

export const actions = {
  getImpersonateUsers,
  getImpersonateUsersSucceeded,
  impersonateLogin,
  login,
  loginSucceeded,
  logout,
  logoutSucceeded,
  impersonateLoginSucceeded,
  stopImpersonate,
  stopImpersonateSucceeded,
  loadOriginalUser,
  loadOriginalUserSucceeded
};

export type Actions = ActionsUnion<typeof actions>;
