import React from 'react';
import { StyledButton } from './styles';
import { ButtonProps } from '@mui/material/Button';

interface MomentButtonProps extends ButtonProps {
  title: string;
}

export const MomentButton = ({ title, ...rest }: MomentButtonProps) => (
  <StyledButton {...rest}>{title}</StyledButton>
);

export default MomentButton;
