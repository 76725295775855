import { getTokenFromLocalStorage } from 'src/services/api';

export const fetchQuickBooksInvoicePdf = ({
  qbInvoiceId
}: {
  qbInvoiceId: number;
}) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const url = `${process.env.REACT_APP_API_URL}/quickbooks/fetch_qb_invoice_pdf?qb_invoice_id=${qbInvoiceId}`;
    xhr.open('GET', url, true);
    const token = getTokenFromLocalStorage();

    xhr.setRequestHeader('Authorization', token);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const blob = xhr.response;
      if (blob) resolve(blob);
      else reject();
    };
    xhr.send();
  });
