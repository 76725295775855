import Popper from '@mui/material/Popper';

import * as React from 'react';
import styled, { theme } from 'src/theme';

export const StyledPopper = styled(({ ...rest }) => (
  <Popper {...rest} />
))`&& z-index: 1;
  &[x-placement*="bottom"] .arrow{

    width: 0;
    height: 0;
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
    border-bottom: 1em solid ${theme.primaryColor};
    margin-top: -0.9em;

    &:before {
      border-width: '0 1em 1em 1em';
      border-color: 'transparent transparent ${theme.primaryColor} transparent';
    }
  }

  &[x-placement*="top"] .arrow{

    bottom: 0;
    width: 0;
    height: 0;
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
    border-top: 1em solid ${theme.primaryColor};
    margin-bottom: -0.9em;

    &:before {
      border-width: 1em 1em 0 1em;
      border-color: white transparent transparent transparent;
    }
  }

  &[x-placement*="right"] .arrow{

    left: 0;
    width: 0;
    height: 0;
    border-top: 1em solid transparent;
    border-bottom: 1em solid transparent;
    border-right: 1em solid ${theme.primaryColor};
    margin-left: -0.9em;

    &:before {
      border-width: 1em 1em 1em 0;
      border-color: transparent white transparent transparent;
    }
  }

  &[x-placement*="left"] .arrow{

    right: 0;
    width: 0;
    height: 0;
    border-top: 1em solid transparent;
    border-bottom: 1em solid transparent;
    border-left: 1em solid ${theme.primaryColor};
    margin-right: -0.9em;

    &:before {
      border-width: 1em 0 1em 1em;
      border-color: transparent transparent transparent white;
    }
  }

  .arrow {
    position: absolute;
    font-size: 7px;
    width: 3em;
    height: 3em;

    &:before {
      content: '""',
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
    }
  }

  .inner {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.secondaryBackground};
    color: ${theme.primaryColor};
    border: ${theme.primaryColor} 1px solid;

    & svg {
      cursor: pointer;
    }
  }

}
` as any;
