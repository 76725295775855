import styled, { theme } from '../../theme';
import { Button } from '@mui/material';

export const Container = styled.div`
  overflow: auto;
  padding-bottom: 100px;
  padding-right: 0;
  padding-top: 100px;
`;

export const TableHeader = styled.div`
  margin-top: 36px;
  margin-right: 84px;
  color: #212121;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
  padding-left: 16px;
  font-family: ${theme.font};
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
`;

export const AddLineItemButton = styled(Button)`
  && {
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 0 16px 0 8px;
    text-transform: none;
    color: #00838f;
    height: 28px;
    white-space: nowrap;
  }
`;

export const DismissAllInvoicesButton = styled(Button)`
  && {
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 0 16px 0 8px;
    text-transform: none;
    color: #00838f;
    height: 28px;
    white-space: nowrap;
  }
`;
