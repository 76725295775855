import { Box, Typography, Divider } from '@mui/material';
import HighlightOff from '@mui/icons-material/HighlightOff';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import styled, { theme } from 'src/theme';
import { Link } from 'react-router-dom';

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & svg {
    cursor: pointer;
    padding-right: 18px;
  }
  overflow: hidden;
`;

export const ColumnFlexBox = styled(FlexBox)`
  flex-direction: column;
  padding: 0 18px 0 18px;
  width: 348px;
`;

export const AlignStartColumnFlexBox = styled(ColumnFlexBox)`
  align-items: start;
`;

export const SectionHeader = styled(Typography)`
  && {
    font-family: ${theme.font}
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    text-align: left;
    width: 100%;
    padding: 16px 9px;
    color: #212121;
  }
`;

export const SectionHeaderNoBottomPadding = styled(SectionHeader)`
  && {
    padding-bottom: 0;
  }
`;

export const HalfColumn = styled(Box)`
  font-family: ${theme.font};
  width: 50%;
  text-align: left;
  color: #212121;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  padding: 8px 16px 8px 0;
`;

export const KeyColumn = styled(HalfColumn)`
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  width: 33%;

  &.with-sub-value {
    padding-bottom: 26px;
  }
`;

export const KeyColumnHeader = styled(KeyColumn)`
  font-weight: 600;
`;

export const ValueColumn = styled(HalfColumn)`
  display: flex;
  flex-direction: column;
  width: 67%;
  text-align: right;
  white-space: nowrap;

  &.align-left {
    text-align: left;
  }
  &.rejection-wrapper {
    width: 500px;

    div {
      font-size: 14px !important;
      line-height: 18px !important;
    }

    svg {
      padding-right: 8px;
      padding-left: 4px;
    }
  }
  &.rejection-wrapper-invoice {
    width: fit-content;

    div {
      color: rgba(0, 0, 0, 0.87);
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 16px;
      padding: 3px 12px 3px 3px;
      margin: 0;
      border-radius: 16px;
      background-color: rgba(176, 0, 32, 0.1);
    }

    svg {
      color: #b00020;
      padding-right: 5px;
    }
  }
`;

export const ValueColumnHeader = styled(ValueColumn)`
  font-weight: 600;
`;

export const SubValueColumn = styled.span`
  color: ${theme.black};
  opacity: 0.6;
  font-size: 12px;
`;

export const HighlightedValueColumn = styled(Link)`
  && {
    display: flex;
    flex-direction: column;
    font-family: ${theme.font};
    width: 67%;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    padding: 8px 16px 8px 0;
    color: #00838f;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
  }
`;

export const FlexBoxJustifyStart = styled(FlexBox)`
  justify-content: start;
`;

export const FlexBoxJustifyEnd = styled(FlexBox)`
  justify-content: flex-end;
  cursor: pointer;
`;

export const PartialDivider = styled(Divider)`
  width: 352px;
`;

export const PartialDividerMarginBottom16 = styled(PartialDivider)`
  && {
    margin-bottom: 16px;
  }
`;

export const SectionPrimaryHeader = styled(SectionHeader)`
  && {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const FlexBoxMarginBottom16 = styled(FlexBox)`
  margin-bottom: 16px;
`;

export const FlexBoxMarginBottom4 = styled(FlexBox)`
  margin-bottom: 4px;
`;

export const FlexBoxMarginBottom16PaddingHoriz16 = styled(
  FlexBoxMarginBottom16
)`
  padding: 0;
  width: 348px;
  font-family: ${theme.font};
  svg {
    visibility: hidden;
  }
  &:hover {
    svg {
      visibility: visible;
    }
  }
`;

export const ColumnFlexBoxWithTopBorder = styled(ColumnFlexBox)`
  border-top: 1px solid #d4d4d4;
`;

export const ColumnFlexBoxWithBottomBorder = styled(ColumnFlexBox)`
  border-bottom: 1px solid #d4d4d4;
  margin: 0 18px;
  padding: 0;
`;

export const ColumnFlexBoxWithFullBottomBorder = styled(ColumnFlexBox)`
  border-bottom: 1px solid #d4d4d4;
  margin: 0;
  padding: 0 18px;
`;

export const PaddingLessValueColumn = styled(ValueColumn)`
  padding: 0;
  width: unset;
`;

export const StyledHighlightOff = styled(HighlightOff)`
  && {
    color: #e02020;
    margin-right: 8px;
    height: 20px;
    width: 20px;
    cursor: default;
  }
`;

export const SubLineItem = styled(FlexBox)`
  margin-left: 20px;
  text-align: left;
  width: 90%;
`;

export const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  && {
    color: #e02020;
    margin-right: 8px;
    height: 20px;
    width: 20px;
    cursor: default;
  }
`;

export const RejectionText = styled.div`
  color: rgba(33, 33, 33, 0.87);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
`;
