import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { FunctionComponent } from 'react';
import messages from 'src/messages';
import Flex from '../core/Flex';
import { Container, CustomButton } from './styles';

interface IProjectsEdit {
  values: any;
  managers: any;
  clients?: any[];
  isLoading: boolean;
  isUpdating: boolean;
  errors: any;
  onChange: (props: string) => (event: any) => void;
  onSave: () => void;
  onCancel: () => void;
}

export const ProjectsEdit: FunctionComponent<IProjectsEdit> = props => {
  if (props.isLoading) {
    return (
      <Flex justifyContent="center" marginTop="24px">
        <CircularProgress />
      </Flex>
    );
  }

  return (
    <Container>
      <h3>{messages.EDIT}</h3>
      <TextField
        required={true}
        error={props.errors.name}
        id="name-add-input"
        label={messages.LABELS.NAME}
        helperText={props.errors.name ? messages.REQUIRED : ''}
        value={props.values.name}
        onChange={props.onChange('name')}
        margin="normal"
        fullWidth={true}
        variant="outlined"
      />
      <TextField
        required={true}
        id="nickname-add-input"
        error={props.errors.nickname}
        label={messages.LABELS.NICKNAME}
        value={props.values.nickname}
        helperText={props.errors.nickname ? messages.REQUIRED : ''}
        onChange={props.onChange('nickname')}
        margin="normal"
        fullWidth={true}
        variant="outlined"
      />
      <TextField
        error={props.errors.manager}
        required={true}
        id="manager-add-select"
        select={true}
        label={messages.LABELS.PROJECT_MANAGER}
        value={props.values.manager}
        onChange={props.onChange('manager')}
        fullWidth={true}
        margin="normal"
        variant="outlined"
        helperText={props.errors.manager ? messages.REQUIRED : ''}
      >
        {Object.keys(props.managers).map((current: any) => (
          <MenuItem
            key={`managers-l-${props.managers[current].id}`}
            value={props.managers[current].id}
          >
            {props.managers[current].fullName}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        error={props.errors.client}
        required={true}
        id="client-add-select"
        select={true}
        label={messages.LABELS.CLIENT}
        value={props.values.client}
        onChange={props.onChange('client')}
        fullWidth={true}
        margin="normal"
        variant="outlined"
        disabled={true}
        helperText={props.errors.client ? messages.REQUIRED : ''}
      >
        {props.clients
          ? props.clients.map((current: any) => (
              <MenuItem key={`clients-l-${current.id}`} value={current.id}>
                {current.name}
              </MenuItem>
            ))
          : []}
      </TextField>
      <TextField
        error={props.errors.budgetPercentage}
        required={true}
        id="client-add-select"
        select={true}
        label={messages.LABELS.BUDGET_PERCENTAGE}
        value={props.values.budgetPercentage}
        onChange={props.onChange('budgetPercentage')}
        fullWidth={true}
        margin="normal"
        variant="outlined"
        helperText={props.errors.budgetPercentage ? messages.REQUIRED : ''}
      >
        <MenuItem key={`budget-1`} value={'hours'}>
          Hours
        </MenuItem>
        <MenuItem key={`budget-0`} value={'dollars'}>
          Dollars
        </MenuItem>
      </TextField>
      <TextField
        error={props.errors.status}
        required={true}
        id="client-add-select"
        select={true}
        label={messages.LABELS.STATUS}
        value={props.values.status}
        onChange={props.onChange('status')}
        fullWidth={true}
        margin="normal"
        variant="outlined"
        helperText={props.errors.status ? messages.REQUIRED : ''}
      >
        <MenuItem key={`status-1`} value={1}>
          Active
        </MenuItem>
        <MenuItem key={`status-0`} value={0}>
          Inactive
        </MenuItem>
      </TextField>
      <TextField
        required={true}
        id="specialInstructions-add-textarea"
        error={props.errors.invoicingSpecialInstructions}
        label={messages.LABELS.PROJECT_SPECIAL_INST}
        value={props.values.invoicingSpecialInstructions || ''}
        helperText={
          props.errors.invoicingSpecialInstructions ? messages.REQUIRED : ''
        }
        onChange={props.onChange('invoicingSpecialInstructions')}
        margin="normal"
        fullWidth={true}
        variant="outlined"
        multiline={true}
      />
      {props.isUpdating ? (
        <CircularProgress />
      ) : (
        <React.Fragment>
          <CustomButton
            fullWidth={true}
            color="primary"
            onClick={props.onSave}
            variant="contained"
          >
            {messages.SAVE}
          </CustomButton>
          <CustomButton
            fullWidth={true}
            variant="contained"
            onClick={props.onCancel}
          >
            {messages.CANCEL}
          </CustomButton>
        </React.Fragment>
      )}
    </Container>
  );
};

export default ProjectsEdit;
