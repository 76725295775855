import * as React from 'react';
import {
  MenuItem,
  SelectChangeEvent,
  SelectProps,
  styled,
  TextField,
  TextFieldProps
} from '@mui/material';

export interface SelectOption {
  id: string | number;
  label: string;
}

export interface ISelectProps
  extends Pick<
      TextFieldProps,
      | 'disabled'
      | 'id'
      | 'sx'
      | 'fullWidth'
      | 'required'
      | 'error'
      | 'helperText'
      | 'margin'
    >,
    Pick<SelectProps, 'multiple'> {
  label: string;
  onChange: (selection: string[] | number[]) => void;
  options: SelectOption[];
  value: string[];
}

const TextFieldStyled = styled(TextField)`
  div[role='button'] > * {
    display: none; /* because a span is added that creates vertical space when none is selected */
  }
`;

export const Select = (props: ISelectProps) => {
  const {
    disabled,
    error,
    fullWidth,
    helperText,
    id,
    label,
    margin,
    multiple,
    options,
    required,
    sx,
    value
  } = props;

  const onChange = (event: SelectChangeEvent<string | string[]>) => {
    const { value } = event.target;
    const selection = Array.isArray(value) ? value : [value];
    props.onChange(selection);
  };

  const onClickNone = () => props.onChange([]);

  return (
    <TextFieldStyled
      disabled={disabled}
      fullWidth={fullWidth}
      helperText={helperText}
      id={id}
      required={required}
      error={error}
      label={label}
      margin={margin}
      onChange={(e: any) => onChange(e)}
      select
      SelectProps={{
        multiple: multiple
      }}
      sx={sx}
      value={value}
      variant="outlined"
    >
      <MenuItem onClick={onClickNone}>None</MenuItem>
      {options.map(option => (
        <MenuItem key={option.id} value={option.id}>
          {option.label}
        </MenuItem>
      ))}
    </TextFieldStyled>
  );
};

export default Select;
