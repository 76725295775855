import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginContainer from '../../containers/LoginContainer';
import NotFoundContainer from '../../containers/NotFoundContainer';
import { Container } from './styles';

const PublicLayout = () => (
  <Container>
    <Routes>
      <Route path="/" element={<LoginContainer />} />
      <Route element={<NotFoundContainer />} />
    </Routes>
  </Container>
);

export default PublicLayout;
