import _ from 'lodash';
import { InvoiceStatus } from 'src/shared/invoice-list/types';
import {
  ITimesheetRow,
  IFixedLineItem,
  IInvoiceDetailView
} from 'src/shared/invoice/types';

import { typeGuard } from 'src/shared/utils';

import {
  isPositiveValue,
  isNotEmpty,
  isValidDate,
  isPositiveValueOrZero
} from 'src/validations';
import moment, { Moment } from 'moment';
import { TimesheetEntry, TimesheetEntryClassification } from 'src/api/types';

export const isEditableStatus = (status: InvoiceStatus, isAdmin?: boolean) =>
  _([
    InvoiceStatus.NEW,
    InvoiceStatus.REJECTED,
    InvoiceStatus.IN_PROGRESS,
    isAdmin ? InvoiceStatus.SUBMITTED : null
  ])
    .compact()
    .some((s: InvoiceStatus) => _.eq(s, status));

export const isReviewStatus = (status: InvoiceStatus) =>
  _.some(
    [
      InvoiceStatus.SUBMITTED,
      InvoiceStatus.APPROVED,
      InvoiceStatus.SENT,
      InvoiceStatus.PAID
    ],
    (s: InvoiceStatus) => _.eq(s, status)
  );

export const isCreateStatus = (status: InvoiceStatus) =>
  _.some([InvoiceStatus.UNINVOICED], (s: InvoiceStatus) => _.eq(s, status));

export type Options = IFixedLineItem | TimesheetEntry | ITimesheetRow;

export const isIFixedLineItem = (item: Options): item is IFixedLineItem => {
  if ((item as IFixedLineItem).amount) {
    return true;
  }
  return false;
};

export const isITimesheetRow = (item: Options): item is ITimesheetRow => {
  if ((item as ITimesheetRow).projectRoleId) {
    return true;
  }
  return false;
};
export const isITimesheetEntry = (item: Options): item is TimesheetEntry => {
  if ((item as TimesheetEntry).classification) {
    return true;
  }
  return false;
};

export const validItem = (item: Options) => {
  if (isIFixedLineItem(item)) {
    // 'tis a line item
    return (
      isPositiveValue(item.amount) &&
      isNotEmpty(item.description) &&
      isPositiveValue(item.deliverableId) &&
      isValidDate(item.date)
    );
  }
  if (isITimesheetRow(item)) {
    // 'tis a Timesheet row
    return (
      isPositiveValue(item.deliverableId) && isPositiveValue(item.projectRoleId)
    );
  }
  if (isITimesheetEntry(item)) {
    // 'tis a Timesheet entry
    return (
      isPositiveValueOrZero(item.invoicingHours) && isNotEmpty(item.description)
    );
  }

  return false;
};

export const sumHoursByKey = (entries: TimesheetEntry[], key: string): number =>
  _.reduce(
    entries,
    (sum: number, entry: TimesheetEntry): number => {
      let val = entry[key];
      if (typeGuard.isString(val)) {
        val = parseFloat(val);
      }
      if (typeGuard.isNumber(val)) {
        return sum + val;
      }
      return sum;
    },
    0
  );

export const invoicingHours = (timesheetEntry: TimesheetEntry) => {
  if (
    _.eq(
      timesheetEntry.classification,
      TimesheetEntryClassification.NON_BILLABLE
    )
  ) {
    if (!timesheetEntry.invoiceId) {
      return 0;
    }
    if (!timesheetEntry.invoicingHours) {
      return 0;
    }

    return timesheetEntry.invoicingHours;
  }
  if (
    _.eq(timesheetEntry.classification, TimesheetEntryClassification.PTO) ||
    _.eq(timesheetEntry.classification, TimesheetEntryClassification.HOLIDAY) ||
    _.eq(
      timesheetEntry.classification,
      TimesheetEntryClassification.SICK_TIME
    ) ||
    _.eq(timesheetEntry.classification, TimesheetEntryClassification.UNPAID)
  ) {
    return 0;
  }
  if (
    _.eq(timesheetEntry.classification, TimesheetEntryClassification.HOLIDAY)
  ) {
    return 0;
  }
  if (timesheetEntry.dismissed) {
    return 0;
  }
  return _.isNull(timesheetEntry.invoicingHours)
    ? timesheetEntry.hours
    : timesheetEntry.invoicingHours;
};

export const sumInvoiceHours = (timesheetEntries: TimesheetEntry[]) =>
  _.reduce(
    timesheetEntries,
    (sum: number, entry: TimesheetEntry): number => {
      let val = invoicingHours(entry);
      if (typeGuard.isString(val)) {
        val = parseFloat(val);
      }
      if (typeGuard.isNumber(val)) {
        return sum + val;
      }
      return sum;
    },
    0
  );

export const sumHoursByClassification = (
  entries: {
    hours: number;
    classification: string;
    dismissed?: boolean;
    description: string;
    status: string;
  }[],
  classTest?: string
): number =>
  _.reduce(
    entries,
    (
      sum: number,
      {
        hours,
        classification
      }: {
        hours: number;
        classification: string;
      }
    ): number => {
      if (typeGuard.isNumber(hours)) {
        if (!classTest) {
          return sum + hours;
        }
        return _.isEqual(classification, classTest) ? sum + hours : sum;
      }
      return sum;
    },
    0
  );

/*

Fn: RemoveUTCSignal -> takes a date time string and removes the Z at the end of it.

This is necessary because moment passes around a lot of strings with Z concated to the end.
The Z signifies a UTC date-time, meaning that the action occurred at the specified time in UTC timezone.
This had led to a lot of buggy behavior when viewing things in PST.
*/

export const removeUTCSignal = (str: string) =>
  str ? str.replace(/Z$/, '') : '';

export const sumInvoiceTotal = (view: IInvoiceDetailView) => {
  const userRowsTotal = _(view.userRows)
    .map(({ hourList }: { hourList: TimesheetEntry[] }) => hourList)
    .flatten()
    .reduce(
      (sum: number, entry: TimesheetEntry) => {
        const role = _.find(
          view.projectRoles,
          ({ id, rate }: { id: number; rate: number }) =>
            id === entry.projectRoleId
        );
        if (role) {
          if (!entry.dismissed) {
            return sum + entry.invoicingHours * role.rate;
          }
        }
        return sum + 0;
      },

      0
    );
  const fixedLineItemsTotal = _(view.fixedLineItems).reduce(
    (sum: number, { amount }: { amount: number | string }) => {
      if (typeGuard.isNumber(amount)) {
        return sum + amount;
      }
      return sum + parseFloat(amount);
    },
    0
  );
  const modifiedTotal = fixedLineItemsTotal + userRowsTotal;
  return modifiedTotal;
};

export const isNonClient = (hourItem: TimesheetEntry) =>
  _.eq(hourItem.classification, TimesheetEntryClassification.HOLIDAY) ||
  _.eq(hourItem.classification, TimesheetEntryClassification.SICK_TIME) ||
  _.eq(hourItem.classification, TimesheetEntryClassification.PTO) ||
  _.eq(hourItem.classification, TimesheetEntryClassification.UNPAID);

export const isTwoPeriodsOld = (endDate: Moment) => {
  const twoPeriodsAgo = moment(endDate)
    .add(1, 'month')
    .subtract(2, 'weeks');
  return twoPeriodsAgo.isBefore(moment());
};
