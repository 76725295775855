import Chip from '@mui/material/Chip';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Settings from '@mui/icons-material/Settings';
import Close from '@mui/icons-material/Close';

import styled, { theme } from 'src/theme';

export const StyledChip = styled(Chip)``;

export const StyledPopover = styled(Popover)`
  && {
    background: rgba(0, 0, 0, 0);
  }
`;

export const PopoverContent = styled(Box)`
  && {
    display: flex;
    margin-left: 12px;
  }
`;

export const StyledSettings = styled(Settings)`
  && {
    cursor: pointer;
    visibility: hidden;
    transition: 0.5s ease-in;
    &.visible {
      visibility: visible;
    }
    &.open {
      transform: rotate(0.4turn);
    }
  }
`;

export const ChipContainer = styled(Box)`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    font-family: ${theme.font};
  }
`;

export const RedCancel = styled(Close)`
  && {
    color: ${theme.red};
    cursor: pointer;
    display: none;

    &.visible {
      display: inline-block;
    }
  }
`;

export const StarBox = styled(Box)`
  && {
    display: flex;
    padding-right: 12px;
    cursor: pointer;
    font-family: ${theme.font};
  }
`;

export const FlexBoxJustifyStart = styled(Box)`
  display: flex;
  justify-content: start;
  align-items: center;
`;
