import { combineReducers } from 'redux';
import auth from './shared/auth/reducer';
import { IAuthState } from './shared/auth/types';
import invoicesEmail from './shared/invoice-emails/reducer';
import { IInvoiceEmailReducerState } from './shared/invoice-emails/types';
import invoices from './shared/invoice-list/reducer';
import { IInvoiceListReducerState } from './shared/invoice-list/types';
import invoice from './shared/invoice/reducer';
import { IInvoiceDetailState } from './shared/invoice/types';
import entities from './shared/users/reducer';
import { IEntityState } from './shared/users/types';

export interface IAppState {
  auth: IAuthState;
  entities: IEntityState;
  invoices: IInvoiceListReducerState;
  invoice: IInvoiceDetailState;
  invoicesEmail: IInvoiceEmailReducerState;
  notifications: {};
}

const rootReducer = combineReducers({
  auth,
  entities,
  invoices,
  invoice,
  invoicesEmail
});

export default rootReducer;
