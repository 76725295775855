import Drawer, { DrawerProps } from '@mui/material/Drawer';
import List, { ListProps } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import * as React from 'react';
import styled, { theme } from '../../theme';

export const CustomDrawer: React.ComponentType<DrawerProps> = styled(
  ({ ...rest }) => <Drawer classes={{ paper: 'paper' }} {...rest} />
)`
  width: ${props => (props.open ? theme.drawerWidth : '0')};
  && {
    transition: ${props =>
      props.open
        ? theme.transitions.create('width', {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.sharp
          })
        : theme.transitions.create('width', {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp
          })};
  }
  flex-shrink: 0;
  & .paper {
    border: 0px;
    width: ${props => (props.open ? theme.drawerWidth : '0')};
    background-color: ${theme.primaryBackgroundInverted};
    transition: ${props =>
      props.open
        ? theme.transitions.create('width', {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.sharp
          })
        : theme.transitions.create('width', {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp
          })};
  }
  & .paper button {
    color: ${theme.secondaryBackground};
  }
` as any;

export const MobileDrawer: React.ComponentType<DrawerProps> = styled(
  ({ ...rest }) => <Drawer classes={{ paper: 'paper' }} {...rest} />
)`
  width: ${theme.drawerWidth};
  & .paper {
    width: ${theme.drawerWidth};
    background-color: ${theme.primaryBackgroundInverted};
  }
  & .paper button {
    color: ${theme.secondaryBackground};
  }
` as any;

export const DrawerToolbar = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 64px;
  div {
    width: 100%;
    h3 {
      cursor: pointer;
    }
  }

  svg {
    height: 24px;
    width: 24px;
    padding: 4px;
  }
`;

export const Container = styled.main`
  display: flex;
  flex: 1;
  height: 100%;
`;

export const DrawerContainer = styled.div`
  & li {
    color: ${theme.primaryColorInverted};
    font-family: 'Source Sans Pro', sans-serif !important;
  }
`;

export const MenuTitle = styled(ListItem)`
  & span {
    color: ${theme.secondaryColor};
    font-size: 1rem;
  }
`;

export const SubList: React.ComponentType<ListProps> = styled(props => (
  <List component="div" {...props} />
))`
  && {
    padding-left: 15px;
  }
  && :hover {
    opacity 1;
  }
  && .Mui-selected span {
    opacity 1;
  }
  & span {
    color: ${theme.primaryColorInverted};
    font-size: 1rem;
    opacity: 0.5;
  }
` as any;
