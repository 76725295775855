import React from 'react';
import {
  Column,
  AutoSizer,
  TableHeaderProps,
  HeaderMouseEventHandlerParams
} from 'react-virtualized';
import { Container, CustomTable } from './styles';
import { rowActions } from './actions';
import {
  getEmployee,
  getTotalUtilization,
  getBillableUtilization,
  getPeriod
} from './getters';
import { ITimesheetsListProps } from './types';
import { SortToggleIcon } from '../core/SortToggleIcon';
import { Flex } from '../core/Flex';
import { TimesheetSearchOrderEnum } from 'src/api/types';

const TimesheetsList: React.FC<ITimesheetsListProps> = ({
  canManageTimesheet,
  data,
  impersonate,
  onSortChange,
  rightPanelOpen,
  rowClassName,
  sortBy,
  sortDirection,
  onClickReview,
  onClickTimesheet,
  onDoubleClickTimesheet
}) => {
  const cols = [
    {
      cellRenderer: getEmployee,
      className: 'column',
      dataKey: 'employee',
      flexGrow: 2,
      label: 'Employee',
      sortable: true
    },
    {
      cellDataGetter: getPeriod,
      className: 'column',
      dataKey: 'period',
      flexGrow: 1,
      label: 'Period',
      sortable: true
    },
    {
      cellDataGetter: getTotalUtilization,
      className: 'column',
      dataKey: TimesheetSearchOrderEnum.TOTAL_UTILIZATION,
      flexGrow: 1,
      label: 'Utilization %'
    },
    {
      cellDataGetter: getBillableUtilization,
      className: 'column',
      dataKey: TimesheetSearchOrderEnum.TOTAL_BILL_UTILIZATION,
      flexGrow: 1,
      label: 'Billable %'
    },
    {
      className: 'column',
      dataKey: 'totalHours',
      flexGrow: 1,
      label: 'Total Hr'
    },
    {
      className: 'column',
      dataKey: 'status',
      flexGrow: 1,
      label: 'Status',
      sortable: true
    },
    {
      cellRenderer: rowActions({
        canManageTimesheet,
        onClickReview,
        impersonate
      }),
      className: 'column action',
      dataKey: 'impersonate',
      flexGrow: 1
    }
  ];

  const renderHeader = (props: TableHeaderProps) => {
    const sortToggleIconValue = (() => {
      if (
        (props.dataKey === 'employee' && sortBy === 'owner.fullName') ||
        (props.dataKey === 'period' && sortBy === 'startDate') ||
        (props.dataKey === 'status' && sortBy === 'status')
      ) {
        return sortDirection;
      } else {
        return null;
      }
    })();

    return (
      <Flex
        alignItems="center"
        style={{ cursor: props.disableSort ? 'default' : 'pointer' }}
      >
        {props.label}
        {!props.disableSort && <SortToggleIcon value={sortToggleIconValue} />}
      </Flex>
    );
  };

  const onHeaderClick = (params: HeaderMouseEventHandlerParams) => {
    const { dataKey } = params;
    const column = cols.find(c => c.dataKey === dataKey);
    if (!column?.sortable) return;

    onSortChange(params);
  };

  if (!data) return null;

  return (
    <Container className={rightPanelOpen ? 'compressed' : ''}>
      <AutoSizer>
        {({ height, width }) => (
          <CustomTable
            onRowClick={info => onClickTimesheet(info.rowData.id)}
            onHeaderClick={onHeaderClick}
            onRowDoubleClick={e => onDoubleClickTimesheet(e.rowData.id)}
            data={data}
            headerClassName="headerRow"
            headerHeight={50}
            height={height}
            rowClassName={({ index }) => rowClassName(data[index]?.id)}
            rowCount={data.length}
            rowGetter={({ index }) => data[index]}
            rowHeight={60}
            width={width}
          >
            {cols.map(col => (
              <Column
                key={col.dataKey}
                {...col}
                width={1}
                headerRenderer={renderHeader}
                disableSort={!col.sortable}
              />
            ))}
          </CustomTable>
        )}
      </AutoSizer>
    </Container>
  );
};

export default TimesheetsList;
