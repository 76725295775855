import { ActionsUnion, createAction } from 'src/shared/types';
import {
  IInvoice,
  InvoiceFilterPayload,
  InvoiceCountType,
  IInvoiceSidebarDetail,
  INote
} from './types';
import { InvoiceStatus } from 'src/shared/invoice-list/types';
import { Invoice, ProjectDetail } from 'src/api/types';

export const GET_INVOICES_BY_PROJECT_ID = 'GET_INVOICES_BY_PROJECT_ID';
export const FETCH_INVOICES = 'FETCH_INVOICES';
export const FETCH_INVOICES_SUCCEEDED = 'FETCH_INVOICES_SUCCEEDED';
export const CHANGE_INVOICE_FILTER = 'CHANGE_INVOICE_FILTER';
export const CHANGE_INVOICE_STATUS = 'CHANGE_INVOICE_STATUS';
export const FETCH_INVOICE_COUNT = 'FETCH_INVOICE_COUNT';
export const GET_INVOICES_BY_PROJECT_ID_SUCCEDED =
  'GET_INVOICES_BY_PROJECT_ID_SUCCEDED';
export const CLEAR_INVOICE_DETAIL = 'CLEAR_INVOICE_DETAIL';
export const POPULATE_COUNTS = 'POPULATE_COUNTS';
export const SEARCH_INVOICES = 'SEARCH_INVOICES';
export const POPULATE_PROJECTS_ON_INVOICE_VIEW =
  'POPULATE_PROJECTS_ON_INVOICE_VIEW';
export const POPULATE_ACTIVE_PROJECTS_ON_INVOICE_VIEW =
  'POPULATE_ACTIVE_PROJECTS_ON_INVOICE_VIEW';
export const TOGGLE_RIGHT_PANEL = 'TOGGLE_RIGHT_PANEL';
export const SELECT_INVOICE = 'SELECT_INVOICE';
export const POPULATE_SIDEBAR_DETAIL = 'POPULATE_INVOICE_SIDEBAR_DETAIL';
export const FETCH_MORE_SIDEBAR_INVOICES = 'FETCH_MORE_SIDEBAR_INVOICES';
export const CONCAT_INVOICES = 'CONCAT_INVOICES';
export const FETCH_SIDEBAR_DETAIL = 'FETCH_SIDEBAR_DETAIL';
export const PUSH_NOTE_TO_SIDEBAR = 'PUSH_NOTE_TO_SIDEBAR';
export const DELETE_INVOICE_NOTE = 'DELETE_INVOICE_NOTE';
export const UPDATE_INVOICE_NOTE = 'UPDATE_INVOICE_NOTE';
export const INIT_INVOICE_LIST_FILTERS = 'INIT_INVOICE_LIST_FILTERS';
export const RESET_FILTER = 'RESET_FILTER';
export const DISABLE_FILTERS = 'DISABLE_FILTERS';

export const INVOICE_MUTATORS = [CHANGE_INVOICE_FILTER];

const getInvoicesByProjectId = (projectId: number) =>
  createAction(GET_INVOICES_BY_PROJECT_ID, {
    projectId
  });
const getInvoicesByProjectIdSucceded = (invoicesByProjectId: IInvoice[]) =>
  createAction(GET_INVOICES_BY_PROJECT_ID_SUCCEDED, {
    invoicesByProjectId
  });

const fetchInvoices = ({
  offset,
  order,
  direction
}: {
  offset?: number;
  order?: string;
  direction?: string;
}) => createAction(FETCH_INVOICES, { offset, order, direction });

const fetchInvoicesSucceeded = ({
  invoices,
  total,
  offset
}: {
  invoices: IInvoice[];
  total: number;
  offset: number;
}) => createAction(FETCH_INVOICES_SUCCEEDED, { invoices, total, offset });

const changeInvoiceFilter = (payload: InvoiceFilterPayload) =>
  createAction(CHANGE_INVOICE_FILTER, payload);

const changeInvoiceStatus = (id: number, status: InvoiceStatus) =>
  createAction(CHANGE_INVOICE_STATUS, { id, status });

const fetchInvoicesCount = () => createAction(FETCH_INVOICE_COUNT);

const clearInvoiceDetail = () => createAction(CLEAR_INVOICE_DETAIL);

const populateCountsValues = (counts: InvoiceCountType) =>
  createAction(POPULATE_COUNTS, counts);

const searchInvoices = (query: string, active: boolean, perPage?: number) =>
  createAction(SEARCH_INVOICES, { query, active, perPage });

const populateProjects = (projects: ProjectDetail[]) =>
  createAction(POPULATE_PROJECTS_ON_INVOICE_VIEW, projects);

const populateActiveProjects = (projects: ProjectDetail[]) =>
  createAction(POPULATE_ACTIVE_PROJECTS_ON_INVOICE_VIEW, projects);

const toggleRightPanel = () => createAction(TOGGLE_RIGHT_PANEL);

const selectInvoice = ({
  id,
  index,
  invoice
}: {
  id?: number;
  index?: number;
  invoice: Invoice;
}) => createAction(SELECT_INVOICE, { id, index, invoice });

const fetchSidebarDetailFromDetailView = (
  { id }: { id: number },
  hideLoading?: boolean
) => createAction(FETCH_SIDEBAR_DETAIL, { id, hideLoading });

const populateSidebarDetail = (payload: IInvoiceSidebarDetail) =>
  createAction(POPULATE_SIDEBAR_DETAIL, payload);

const fetchMoreSidebarInvoices = () =>
  createAction(FETCH_MORE_SIDEBAR_INVOICES);

const concatInvoices = (invoices: IInvoice[]) =>
  createAction(CONCAT_INVOICES, invoices);

const pushNoteToSidebar = (note: INote) =>
  createAction(PUSH_NOTE_TO_SIDEBAR, note);

const deleteInvoiceNote = ({ id }: { id: number }) =>
  createAction(DELETE_INVOICE_NOTE, { id });

const updateInvoiceNote = ({ id, text }: { id: number; text: string }) =>
  createAction(UPDATE_INVOICE_NOTE, { id, text });

const initInvoiceListFilters = () =>
  createAction(INIT_INVOICE_LIST_FILTERS, {});

const resetFilters = () => createAction(RESET_FILTER, {});

const disableFilters = () => createAction(DISABLE_FILTERS, {});

export const actions = {
  getInvoicesByProjectId,
  getInvoicesByProjectIdSucceded,
  fetchInvoices,
  fetchInvoicesSucceeded,
  changeInvoiceFilter,
  changeInvoiceStatus,
  clearInvoiceDetail,
  populateCountsValues,
  searchInvoices,
  populateProjects,
  populateActiveProjects,
  toggleRightPanel,
  selectInvoice,
  populateSidebarDetail,
  fetchMoreSidebarInvoices,
  concatInvoices,
  fetchSidebarDetailFromDetailView,
  pushNoteToSidebar,
  deleteInvoiceNote,
  updateInvoiceNote,
  fetchInvoicesCount,
  initInvoiceListFilters,
  resetFilters,
  disableFilters
};

export type Actions = ActionsUnion<typeof actions>;
