import { IAppState } from 'src/reducer';

export const getInvoiceListFilters = (state: IAppState) =>
  state.invoices.filters;

export const selectPersistedView = (state: IAppState) => state.invoice.view;

export const selectLocalView = (state: IAppState) => state.invoice.localView;

export const getFixedLineItemId = (state: IAppState) =>
  state.invoice.fixedLineItemId;
