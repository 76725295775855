import React, { useState, useContext } from 'react';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import { InvoiceStatus } from 'src/shared/invoice-list/types';
import { FlexBox, Button, SubmitButton } from './styles';
import { formatAsTitle } from 'src/shared/utils';
import { AppContext } from 'src/contexts/app-context';

interface IInvoiceActionButtons {
  onClick: (key: string | number | null) => void;
  status: InvoiceStatus;
  canProceed: boolean;
  canSubmit: boolean;
  isAdmin: boolean;
  isFinance: boolean;
  hasQuickBooksInvoiceId: boolean;
}

const send = <SubmitButton key="send">Send</SubmitButton>;
const save = <Button key="save">Save</Button>;
const paid = <SubmitButton key="paid">Paid</SubmitButton>;
const unpay = <Button key="unpay">Mark Unpaid</Button>;
const unsend = <Button key="unsend">Un-send</Button>;
const reopen = <Button key="reopen">Reopen</Button>;
const recover = <Button key="recover">Recover</Button>;
const exportButton = <Button key="export">Export</Button>;
const downloadQbPdfButton = (
  <Button key="download qb pdf">Download QB PDF</Button>
);
const approve = <SubmitButton key="approve">Approve</SubmitButton>;

export const InvoiceActionButtons: React.FC<IInvoiceActionButtons> = ({
  onClick,
  status,
  canProceed,
  canSubmit,
  isAdmin,
  isFinance,
  hasQuickBooksInvoiceId
}) => {
  const context = useContext(AppContext);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(
    null
  );

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const create = canProceed ? (
    <SubmitButton key="create">Create</SubmitButton>
  ) : (
    <Tooltip
      title={
        !canProceed ? 'All Timesheets must be approved before proceeding' : ''
      }
    >
      <div>
        <SubmitButton key="create" disabled={true}>
          Create
        </SubmitButton>
      </div>
    </Tooltip>
  );

  const submit = (
    <SubmitButton key="submit" disabled={!canSubmit}>
      Submit
    </SubmitButton>
  );

  let buttons: ReturnType<typeof Button>[] = [];
  let menu: string[] = [];

  switch (status) {
    case InvoiceStatus.APPROVED:
      if (isAdmin || isFinance) {
        buttons = [exportButton, send, reopen];
        if (context.isAuthenticatedQuickBooksOnline && hasQuickBooksInvoiceId) {
          buttons.unshift(downloadQbPdfButton);
        }
      }
      break;
    case InvoiceStatus.SUBMITTED:
      if (isAdmin) {
        buttons = [approve, save];
        menu = ['reject', 'dismiss'];
      }
      break;
    case InvoiceStatus.NEW:
    case InvoiceStatus.IN_PROGRESS:
    case InvoiceStatus.REJECTED:
      buttons = [submit, save];
      menu = ['dismiss', 'restart'];
      break;
    case InvoiceStatus.UNINVOICED:
      buttons = [create];
      menu = ['dismiss'];
      break;
    case InvoiceStatus.SENT:
      if (isAdmin || isFinance) {
        buttons = [paid, unsend];
      }
      break;
    case InvoiceStatus.DISMISSED:
      buttons = [recover];
      break;
    case InvoiceStatus.PAID:
      buttons = [unpay];
      break;
    default:
      break;
  }

  return (
    <FlexBox>
      {menu.length && canProceed ? (
        <React.Fragment>
          <Button onClick={openMenu}>More Actions</Button>
          <Menu anchorEl={anchorEl} open={menuOpen} onClose={closeMenu}>
            {menu.map((i: string) => (
              <MenuItem
                onClick={() => {
                  closeMenu();
                  onClick(i);
                }}
                key={i}
              >
                {formatAsTitle(i)}
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      ) : null}
      {buttons.map((e: JSX.Element) =>
        React.cloneElement(e, {
          onClick: () => onClick(e.key)
        })
      )}
    </FlexBox>
  );
};

export default InvoiceActionButtons;
