import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import _ from 'lodash';
import { put } from 'redux-saga/effects';

import * as authActions from 'src/shared/auth/actions';
import {
  IGetUserParams,
  IUserPayload,
  IUserSearchParams
} from 'src/shared/users/types';
import { removeEmpty } from 'src/shared/utils';

const transformUserResponse = (response: AxiosResponse) => ({
  ...response,
  data: {
    ...response.data,
    data: response.data.data.map((user: any) => ({
      ...user,
      label: user.fullName
    }))
  }
});

export const getTokenFromLocalStorage = (): string => {
  return localStorage.getItem('moment_jwt') || '';
};

export const getAllocations = () =>
  callAPI({
    method: 'get',
    url: '/allocations'
  });

export const getAllocationsTree = () =>
  callAPI({
    method: 'get',
    url: '/allocation_projects/tree'
  });

export const createProject = (project: any) =>
  callAPI({
    data: { project },
    method: 'post',
    url: '/projects'
  });

export const getProjects = (params: any) =>
  callAPI({
    params,
    method: 'get',
    url: '/projects'
  });

export const createInvoiceEmail = (projectId: any, invoiceEmail: any) =>
  callAPI({
    data: {
      projectId: projectId,
      ...invoiceEmail
    },
    method: 'post',
    url: '/invoice_emails'
  });

export const deleteInvoiceEmail = (id: any) =>
  callAPI({
    method: 'delete',
    url: `/invoice_emails/${id}`
  });

export const updateProject = (id: number, project: any) =>
  callAPI({
    data: { project },
    method: 'put',
    url: `/projects/${id}`
  });

export const deleteUser = (userId: number) =>
  callAPI({
    method: 'delete',
    url: `/users/${userId}`
  });

export const getImpersonateUsers = () =>
  callAPI({
    method: 'get',
    url: '/users_list'
  });

export const getCurrentUser = () =>
  callAPI({
    method: 'get',
    url: '/users/current'
  });

export const getProjectCreateOptions = () =>
  callAPI({
    method: 'get',
    url: '/projects/create_options'
  });

export const getManagers = () =>
  callAPI({
    method: 'get',
    params: {
      isActive: true,
      roleName: 'manager'
    },
    url: '/users'
  });

export const getProjectManagers = () =>
  callAPI({
    method: 'get',
    params: {
      isActive: true,
      roleName: 'PM'
    },
    url: '/users',
    transform: transformUserResponse
  });

export const getInvoicesByProjectId = (projectId: number) =>
  callAPI({
    method: 'get',
    params: {
      direction: 'desc',
      order: 'invoice.date_created',
      page: 1,
      perPage: 15,
      project_id: projectId,
      total: true
    },
    url: '/invoices'
  });

export const getRoles = () =>
  callAPI({
    method: 'get',
    url: '/roles'
  });

export const getUser = (userId: number | number[], params?: IGetUserParams) =>
  callAPI({
    method: 'get',
    params: {
      ...params,
      ...{ id: userId }
    },
    url: '/users'
  });

export const getProjectDetail = (projectId: number | string | number[]) =>
  callAPI({
    method: 'get',
    url: `/projects/${projectId}`
  });

export const getProjectUserStats = (projectId: number, offset?: number) =>
  callAPI({
    method: 'get',
    params: {
      dateOffset: offset || 0,
      id: projectId
    },
    url: `/projects/user_stats`
  });

export const getUsers = (params?: IUserSearchParams) =>
  callAPI({
    method: 'get',
    url: '/users',
    params
  });

interface GetClientProjectsParams {
  status?: boolean | null;
  managerId?: number | null;
  clientId?: number | null;
  projectId?: any | null;
  page?: number | null;
  perPage?: number | null;
  name?: string | null;
}

interface GetClientProjectsResult {
  projects: any[];
  total: number;
}

export const getClientProjects = async ({
  status,
  managerId,
  clientId,
  projectId,
  page,
  perPage,
  name
}: GetClientProjectsParams): Promise<AxiosResponse<
  GetClientProjectsResult
>> => {
  const body: any = {
    method: 'get',
    params: {
      page,
      currentProjectsPage: true,
      isActive: status,
      perPage: perPage,
      projectManagerOnly: 'All Projects',
      projectId: projectId,
      searchName: name
    },
    url: '/projects/stats'
  };
  if (managerId) {
    body.params.projectManagerId = managerId;
  }
  if (clientId) {
    body.params.clientId = clientId;
  }
  return await callAPI<GetClientProjectsResult>(body);
};

export const impersonateLogin = (userId: number) =>
  callAPI({
    data: { userId: userId },
    method: 'post',
    url: '/auth/impersonate'
  });

export interface IFetchInvoicesApiFilters {
  status?: string | null;
  clientId?: number;
  order?: string;
  direction?: string;
  projectManagerId?: number;
  startDate?: string;
  endDate?: string;
  search?: string;
  projectId?: number;
}

export const fetchInvoices = (
  page = 0,
  perPage: number,
  filters: IFetchInvoicesApiFilters,
  sortingFilters?: { direction: string; order: string }
) =>
  callAPI({
    method: 'get',
    url: '/invoices',
    params: {
      page,
      perPage: perPage,
      ...filters,
      total: true,
      ...sortingFilters
    }
  });

export const countInvoices = (filters: IFetchInvoicesApiFilters) =>
  callAPI({
    method: 'get',
    url: '/invoices/count',
    params: {
      ...filters
    }
  });

interface UninvoicedProjectsParams {
  projectManagerId?: number;
  search?: string;
}

export const getUninvoicedProjects = (params?: UninvoicedProjectsParams) =>
  callAPI({
    method: 'get',
    url: '/projects/uninvoiced',
    params
  });

export const postInvoice = (invoice: any) =>
  callAPI({
    method: 'post',
    url: '/invoices',
    reqHeaders: {
      Accept: 'application/json'
    },
    data: { invoice }
  });

export const updateInvoice = ({
  invoiceId,
  invoice
}: {
  invoiceId: number;
  invoice: object;
}) =>
  callAPI({
    method: 'PUT',
    url: `/invoices/${invoiceId}`,
    reqHeaders: {
      Accept: 'application/json'
    },
    data: { invoice } // api expects "invoice": { ...invoiceData }
  });

export const deleteInvoice = (id: number) =>
  callAPI({
    method: 'delete',
    url: `/invoices/${id}`
  });

export const rejectInvoice = ({ invoice }: { invoice: object }) =>
  callAPI({
    method: 'PUT',
    url: `/invoices/reject`,
    data: { invoice } // api expects "invoice": { ...invoiceData }
  });

export const postNewInvoiceLineItem = (newLineItem: any) =>
  callAPI({
    method: 'post',
    url: '/invoice_fixed_line_items',
    data: newLineItem
  });

export const updateInvoiceLineItem = ({
  invoiceFixedLineItem
}: {
  invoiceFixedLineItem: any;
}) =>
  callAPI({
    method: 'put',
    url: `/invoice_fixed_line_items/${invoiceFixedLineItem.id}`,
    data: { invoiceFixedLineItem }
  });

export const approveInvoiceApi = ({
  invoiceId,
  qbMemo
}: {
  invoiceId: number;
  qbMemo?: string;
}) =>
  callAPI({
    method: 'get',
    url: `/invoices/approve`,
    params: removeEmpty({ id: invoiceId, qbMemo })
  });

export const reopenInvoiceApi = ({ invoiceId }: { invoiceId: number }) =>
  callAPI({
    method: 'get',
    url: `/invoices/reopen`,
    params: { id: invoiceId }
  });

export const updateInvoiceApi = ({
  invoiceId,
  invoice
}: {
  invoiceId: number;
  invoice: any; // can't seem to type this out without bizarre TS errors. It is just a diff of an IInvoice object
}) =>
  callAPI({
    method: 'put',
    url: `/invoices/${invoiceId}`,
    data: removeEmpty({ invoice })
  });

export const deleteLineItem = (id: number) =>
  callAPI({
    method: 'delete',
    url: `/invoice_fixed_line_items/${id}`
  });

export const fetchInvoiceFixedLineItems = ({
  invoiceId
}: {
  invoiceId: number;
}) =>
  callAPI({
    method: 'get',
    url: '/invoice_fixed_line_items',
    params: { invoiceId }
  });

export const login = (code: string) =>
  callAPI({
    data: { code },
    method: 'post',
    url: '/auth/google'
  });

export const getUploadFiles = (id: number[]) =>
  callAPI({
    method: 'get',
    url: '/upload_file',
    params: { id }
  });

export const downloadUploadFile = (id: number) =>
  // ugly, I know. But the fetch api doesn't work with files
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const url = `${process.env.REACT_APP_API_URL}`;
    xhr.open('GET', `${url}/upload_file/${id}/download`, true);
    const token = getTokenFromLocalStorage();
    xhr.setRequestHeader('Authorization', token);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const blob = xhr.response;
      if (blob) resolve(blob);
      else reject();
    };
    xhr.send();
  });

export const createNewFile = (formData: FormData) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', '/api/upload_file', true);
    const token = getTokenFromLocalStorage();

    xhr.setRequestHeader('Authorization', token);
    xhr.onload = res => {
      if (res) resolve(res);
      else reject();
    };
    xhr.send(formData);
  });

export const logout = () =>
  callAPI({
    method: 'post',
    url: '/auth/sign_out'
  });

export const updateUser = (userData: IUserPayload, userId: number) =>
  callAPI({
    data: {
      user: userData
    },
    method: 'put',
    url: `/users/${userId}`
  });

export const fetchInvoiceNotes = ({
  invoiceId
}: {
  invoiceId: number | number[];
}) =>
  callAPI({
    method: 'get',
    url: '/invoice_note',
    params: { invoiceId }
  });

export const createInvoiceNote = ({
  invoiceId,
  text,
  typeOf
}: {
  invoiceId: number;
  text: string;
  typeOf: number;
}) =>
  callAPI({
    method: 'post',
    url: '/invoice_note',
    data: {
      invoiceId,
      text,
      typeOf
    }
  });

export const deleteInvoiceNote = ({ id }: { id: number }) =>
  callAPI({
    method: 'delete',
    url: `/invoice_note/${id}`
  });

export const updateInvoiceNote = ({ id, text }: { id: number; text: string }) =>
  callAPI({
    method: 'put',
    url: `/invoice_note/${id}`,
    data: { text }
  });

export const fetchDownloadToken = ({ invoiceId }: { invoiceId: number }) =>
  callAPI({
    method: 'get',
    url: `/invoices/${invoiceId}?download=true`,
    reqHeaders: {
      Accept: 'application/json'
    }
  });

export const fetchTimesheetSpreadsheet = ({
  downloadToken,
  invoiceId
}: {
  downloadToken: string;
  invoiceId: number;
}) =>
  // fetch api does not work with files afaicfo (as far as I can figure out)
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const url = `${process.env.REACT_APP_API_URL}`;
    xhr.open(
      'GET',
      `${url}/invoices/${invoiceId}?format=xlsx&download_token=${downloadToken}`,
      true
    );
    const token = getTokenFromLocalStorage();
    xhr.setRequestHeader('Authorization', token);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const blob = xhr.response;
      if (blob) resolve(blob);
      else reject();
    };
    xhr.send();
  });

interface IAPIOptions {
  data?: any;
  method: string;
  params?: any;
  url: string;
  reqHeaders?: object;
  transform?: (source: any) => any;
}

export const callAPI = <T = any>(
  options: IAPIOptions
): Promise<AxiosResponse<T>> => {
  const config: AxiosRequestConfig = {};
  const token = getTokenFromLocalStorage();
  if (token) {
    config.headers = {
      ...options.reqHeaders,
      Authorization: `Bearer ${token}`
    };
  }
  const url = `${process.env.REACT_APP_API_URL}${options.url}`;
  _.assign(config, options, { url });
  return axios
    .request<T>(config)
    .then(response =>
      options.transform ? options.transform(response) : response
    )
    .catch(error => {
      throw error;
    });
};

export const handleError = function*(error: Error) {
  const status = _.get(error, 'response.status', null);
  if (status === 401) {
    yield put(authActions.actions.logout(true));
  } else if (status === 403) {
    window.location.assign('/');
  }
};
