import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';

export const createQuickbooksCustomer = ({
  name,
  id
}: {
  name: string;
  id: number;
}) =>
  callAPI({
    method: 'get',
    url: '/quickbooks/create_new_customer',
    params: { name, id }
  })
    .then(() => {
      toast('New customer added to Quickbooks', 'success');
    })
    .catch(error => {
      toast('Failed to add customer to Quickboocks', 'error');
      throw error;
    });
