import React from 'react';
import { toast as toastify, TypeOptions } from 'react-toastify';
import GlobalAlert from 'src/components/GlobalAlert';

// calculates best duration assuming that the average english reader can read 1 character every 50 miliseconds (source: https://ux.stackexchange.com/a/85898)
const bestDuration = (message: string) => {
  const minDuration = 2000; // arbitrary minimum
  const maxDuration = 7000; // arbitrary maximum
  return Math.min(Math.max(message.length * 50, minDuration), maxDuration);
};

export const toast = (
  message: string,
  type: TypeOptions = 'info',
  duration?: number | false
) => {
  const autoCloseDuration =
    duration === false ? false : duration || bestDuration(message);

  toastify(<GlobalAlert type={type} open={true} message={message} />, {
    autoClose: autoCloseDuration,
    closeButton: false,
    hideProgressBar: true
  });
};
