import { createGlobalStyle } from './index';

const globalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;

    &:not(svg) {
      // cant currently apply box-sizing:border-box to svgs because many svgs have padding and/or 
      // margin styles applied, which when combined with width/height styles and border-box, causes
      // a reduction of the dimensions of the svg's content, distorting it, and rendering it 
      // virtually invisible in some cases
      box-sizing: border-box;
    }
  }

  html, body, div#root {
    font-family: 'Source Sans Pro', sans-serif;
    height: 100%;
    background-color: #ffffff;
  }
  body {
    color: black;
    margin: 0;

    // This will control the font size based on the screen width.
    // It will also determine the spacing sizes for all elements
    // that have styles built with 'rem' units
    font-size: 13px;
    line-height: 1.6;

    @media only screen and (min-width: 600px) {
      font-size: 14px;
    }
    @media only screen and (min-width: 1280px) {
      font-size: 15px;
    }
  }
  .ReactVirtualized__Grid:focus {
    outline: none;
  }
  .ReactVirtualized__Table__headerRow, .ReactVirtualized__Table__row {
    display: flex;
    flex: 1;
    flex-direction: row;
  }
  #allocation-type-add-input .MuiContainer-root {
    padding-left: 13%;
  }
  .with-opacity {
    opacity: 0.7;
  }
  .last-infinite-allocation {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
  .startAllocation {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .endAllocation {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .rotateArrowUp{
    animation-name: arrowUp;
    animation-duration: .5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    cursor: pointer;
  }

  .rotateArrowDown{
    animation-name: arrowDown;
    animation-duration: .5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    cursor: pointer;
  }
  @keyframes arrowUp {
    from {transform: rotate(0deg);}
    to {transform: rotate(-180deg);}
  }

  @keyframes arrowDown {
    from {transform: rotate(-180deg);}
    to {transform: rotate(0deg);}
  }

  .activeTabEffect{
    animation-name: activeEffect;
    animation-duration: .2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }

  .inactiveTabEffect{
    animation-name: inactiveEffect;
    animation-duration: .2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }


  @keyframes inactiveEffect {
    from {margin-left: 8px;}
    to {margin-left: 162px; }
  }

  @keyframes activeEffect {
    from {margin-left: 162px;}
    to {margin-left: 8px; }
  }

  .showOverflowHeader{
    padding-bottom: 0 !important;
    padding-top: 40px;
  }

  .disabled{
    cursor: initial;
    opacity: 0.5;
  }

  .Cal__MonthList__root {
    background-color: rgba(0,0,0,0.05);
    div {
      div {
        background-color: #fff;
      }
    }
}

`;

export default globalStyle;
