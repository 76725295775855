import { Box } from '@mui/material';
import styled, { theme } from 'src/theme';

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const FlexBoxJustifyStart = styled(FlexBox)`
  justify-content: flex-start;
`;

export const ColumnFlexBox = styled(FlexBox)`
  flex-direction: column;
`;

export const AddEntryWrapper = styled(ColumnFlexBox)`
  padding: 16px 60px 16px 16px;
  width: 95%;
  padding-bottom: 48px;
`;

export const InputWrapper = styled(Box)`
  width: 100%;
  margin-left: 8px;
`;

export const InputWrapperTopMargin8 = styled(InputWrapper)`
  margin-top: 8px;
`;

export const OptionWrapper = styled.div`
  font-family: ${theme.font};
  display: flex;
  flex-direction: column;
`;

export const SubTitle = styled.div`
  font-family: ${theme.font};
  color: #555555;
  font-size: 10px;
  font-weight: 300;
  line-height: 14px;
`;
