import * as React from 'react';
import ProjectsAddContainer from 'src/containers/ProjectsAddContainer';
import { GRIO_ORG_ID } from 'src/constants';
import { FunctionComponent } from 'react';

export const InHouseProjectsAddContainer: FunctionComponent = () => (
  <ProjectsAddContainer
    client={GRIO_ORG_ID}
    route={'inhouse-projects'}
    isClientDisabled={true}
  />
);

export default InHouseProjectsAddContainer;
