import React from 'react';
import { SortDirectionType } from 'react-virtualized';
import { Container } from 'src/components/ProjectsList/styles';
import { ClientIcon } from 'src/components/ProjectsList/styles';
import {
  IInvoiceListFilters,
  InvoiceStatus,
  STATUS_FILTER,
  INVOICES_FILTER,
  IInvoiceSidebarDetail
} from 'src/shared/invoice-list/types';
import { NewInvoiceState, NewInvoiceParams } from 'src/shared/invoice/types';
import { IEntityStateUsers } from 'src/shared/users/types';
import InvoiceListTable from './InvoiceListTable';
import InvoiceSubNav from './InvoiceSubNav';
import { ClientItem } from './InvoiceSubNav/styles';
import { isEditableStatus } from 'src/shared/invoice/utils';
import { ICurrentUser } from 'src/shared/auth/types';
import { isAdmin } from 'src/shared/auth/utils';
import { typeGuard } from 'src/shared/utils';
import { FunctionComponent, useState } from 'react';
import { IClient } from 'src/types/IClient';
import { actions as invoiceActions } from 'src/shared/invoice-list/actions';
import { Invoice, InvoiceCounts, UninvoicedProject } from 'src/api/types';

interface IInvoiceList {
  invoices?: (Invoice | UninvoicedProject)[];
  goToDetail: (id: string | number) => void;
  changeStatus: ({ id, status }: { id: number; status: InvoiceStatus }) => void;
  selectedItem?: Invoice | null;
  filterChanged: (payload: object) => void;
  filters: IInvoiceListFilters;
  projectManagers: IEntityStateUsers;
  clientList?: IClient[];
  getNewInvoiceView: ({
    filters
  }: {
    filters: NewInvoiceState['filters'];
  }) => void;
  sortPage: (sortBy: string, sortDirection: string) => void;
  total: number;
  page: number;
  counts?: InvoiceCounts;
  onClickCreateButton: ({
    filters,
    dismiss
  }: {
    filters: { project: number; period: string; client: number };
    dismiss?: boolean;
  }) => void;
  onSelectInvoice: typeof invoiceActions.selectInvoice;
  invoiceSidebarDetail: IInvoiceSidebarDetail;
  fetchMoreSidebarInvoices: () => void;
  createNewInstruction: ({
    text,
    typeOf
  }: {
    text: string;
    typeOf: number;
  }) => void;
  deleteInvoiceNote: ({ id }: { id: number }) => void;
  updateInvoiceNote: ({ text, id }: { text: string; id: number }) => void;
  currentUser?: ICurrentUser;
  loading: boolean;
  leftDrawerIsOpen: boolean;
  createNewInvoice: (params: NewInvoiceParams, dismiss?: boolean) => void;
  showFilters: boolean;
  clearDetail: () => void;
  hasNextPage?: boolean;
  hasPrevPage?: boolean;
  onNextPage: () => void;
  onPrevPage: () => void;
}

interface IInvoiceListState {
  loading: boolean;
  sortBy?: string;
  sortDirection?: SortDirectionType;
  rightPanelOpen: boolean;
}

const InvoiceList: FunctionComponent<IInvoiceList> = props => {
  const [state, setState] = useState<IInvoiceListState>({
    loading: false,
    rightPanelOpen: false
  });

  const openInvoiceDetail = (id?: number) => {
    const { selectedItem, clearDetail } = props;
    clearDetail();
    if (typeGuard.isIInvoice(selectedItem) && selectedItem.period) {
      const { clientId, projectId, period } = selectedItem;
      props.getNewInvoiceView({
        filters: {
          client: clientId,
          project: projectId,
          period,
          importTimesheets: true
        }
      });
    }
    const val =
      props.selectedItem && props.selectedItem.id ? props.selectedItem.id : id;
    return val ? props.goToDetail(val) : Function.prototype;
  };

  const renderClientElement = () => {
    const { invoices } = props;
    if (invoices && invoices.length > 0) {
      const invoice = invoices[0];
      const { clientColor, clientName, projectNickname } = invoice;
      return (
        <ClientItem>
          <ClientIcon color={clientColor} /> &nbsp; {clientName} -{' '}
          {projectNickname}
        </ClientItem>
      );
    } else {
      return <div />;
    }
  };

  const filterChanged = (newFilters: any) => {
    setState({ ...state, loading: true });
    props.filterChanged(newFilters);
  };

  const onSelectInvoice: typeof invoiceActions.selectInvoice = ({
    id,
    index,
    invoice
  }) => {
    setState({ ...state, rightPanelOpen: true });
    return props.onSelectInvoice({ id, index, invoice });
  };

  const onStatusFilterChange = (value: string) => {
    if (value === 'Pending' && props.filters.sortBy === 'STATUS') {
      filterChanged({ key: 'sortBy', value: 'DATE' });
      filterChanged({ key: 'sortDirection', value: 'DESC' });
    }
    filterChanged({ key: STATUS_FILTER, value });
  };

  const { selectedItem, currentUser, filters, showFilters } = props;
  let canEdit = false;
  if (selectedItem && currentUser) {
    canEdit = isEditableStatus(
      selectedItem.status as InvoiceStatus,
      isAdmin(currentUser)
    );
  }
  if (!currentUser) {
    return null;
  }

  return (
    <Container>
      <InvoiceSubNav
        counts={props.counts}
        onClickStatusFilter={onStatusFilterChange}
        onClickInvoicesFilter={(value: string) => {
          filterChanged({ key: INVOICES_FILTER, value });
        }}
        currentStatus={filters.status}
        currentInvoicesFilter={filters.invoices}
        isAdmin={isAdmin(currentUser)}
        leftDrawerIsOpen={props.leftDrawerIsOpen}
        showFilters={showFilters}
        clientElement={renderClientElement()}
      />
      <InvoiceListTable
        {...props}
        onSelectInvoice={onSelectInvoice}
        currentUser={currentUser}
        filters={filters}
        invoiceSidebarDetail={props.invoiceSidebarDetail}
        fetchMoreInvoices={props.fetchMoreSidebarInvoices}
        createNewInstruction={props.createNewInstruction}
        deleteInvoiceNote={props.deleteInvoiceNote}
        updateInvoiceNote={props.updateInvoiceNote}
        openInvoiceDetail={openInvoiceDetail}
        canEdit={canEdit}
        clearDetail={props.clearDetail}
      />
    </Container>
  );
};

export default InvoiceList;
