import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { Project } from '../types';

export const fetchProject = (projectId: string | number) =>
  callAPI<Project>({
    method: 'get',
    url: `/projects/${projectId}`
  })
    .then(response => response.data)
    .catch(error => {
      toast('Failed to fetch project roles', 'error');
      throw error;
    });
