import { callAPI } from 'src/services/api';
import { ProjectRole } from '../types';
import { handleProjectRoleUpsertError } from './utils';

export const updateProjectRole = ({
  projectId,
  data
}: {
  projectId: number;
  data: Partial<ProjectRole>;
}) =>
  callAPI<{ projectRole: ProjectRole }>({
    data: {
      projectRole: {
        projectId: projectId,
        ...data
      }
    },
    method: 'PUT',
    url: `/project_roles/${data.id}`
  })
    .then(response => response.data.projectRole)
    .catch(error => {
      handleProjectRoleUpsertError(error);
      throw error;
    });
