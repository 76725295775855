import { Typography } from '@mui/material';
import { QBConnectedClientName } from 'src/components/qb/QBConnectedClientName';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import messages from '../../messages';
import {
  Circle,
  Container,
  Content,
  Label,
  LineItem,
  Sub,
  Title,
  Value
} from './styles';

interface IClientDetail {
  selectedClient: any;
}

export const ClientDetail: FunctionComponent<IClientDetail> = ({
  selectedClient
}) => (
  <Container>
    {selectedClient ? (
      <React.Fragment>
        <QBConnectedClientName hasQBCustomerId={!!selectedClient.qbCustomerId}>
          <Title>{selectedClient.name}</Title>
        </QBConnectedClientName>
        <Sub>{selectedClient.code}</Sub>
        <Content>
          <LineItem>
            <Label>{messages.LABELS.STATUS}</Label>
            <Value>
              {selectedClient.isActive ? messages.ACTIVE : messages.DISABLED}
            </Value>
          </LineItem>
          <LineItem>
            <Label>{messages.LABELS.CREATION_DATE}</Label>
            <Value>
              {moment(selectedClient.dateCreated).format('MM/DD/YYYY')}
            </Value>
          </LineItem>
          <LineItem>
            <Label>{messages.LABELS.COLOR}</Label>
            <Value>
              <Circle color={selectedClient.color} />
            </Value>
          </LineItem>
        </Content>
      </React.Fragment>
    ) : (
      <Typography variant="h5">{'No Client Selected'}</Typography>
    )}
  </Container>
);

export default ClientDetail;
