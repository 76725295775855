import { callAPI } from 'src/services/api';
import {
  Deliverable,
  Project,
  ProjectRole,
  UserProjectRole
} from 'src/api/types';

export const userProjectRolesQuery = async (): Promise<UserProjectRole[]> => {
  const res = await callAPI<UserProjectRole[]>({
    url: '/user_project_prefs',
    method: 'get'
  });

  return res.data;
};

export const projectsQuery = async (params: {
  clientId: number;
  isActive: boolean;
}): Promise<Project[]> => {
  const res = await callAPI<Project[]>({
    params,
    method: 'get',
    url: '/projects'
  });
  return res.data;
};

export const deliverablesQuery = async (params: {
  projectId: number;
  isActive: boolean;
}): Promise<Deliverable[]> => {
  const res = await callAPI<Deliverable[]>({
    method: 'get',
    params,
    url: '/deliverables',
    reqHeaders: {
      Accept: 'application/json'
    }
  });
  return res.data;
};

export const projectRolesQuery = async (params: {
  projectId: number;
}): Promise<ProjectRole[]> => {
  const res = await callAPI<ProjectRole[]>({
    method: 'get',
    url: '/project_roles',
    params,
    reqHeaders: {
      Accept: 'application/json'
    }
  });
  return res.data;
};
