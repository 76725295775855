import _ from 'lodash';
import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { Deliverable } from '../types';

export const updateDeliverable = ({
  projectId,
  data
}: {
  projectId: number;
  data: Partial<Deliverable>;
}) =>
  callAPI<{ deliverable: Deliverable }>({
    data: {
      deliverable: {
        projectId: projectId,
        ...data
      }
    },
    method: 'PUT',
    url: `/deliverables/${data.id}`
  })
    .then(response => response.data.deliverable)
    .catch(e => {
      const error = _.get(e, 'response', null);
      const msg = error.data.sow || 'Failed to update deliverable';
      toast(msg, 'error');
      throw e;
    });
