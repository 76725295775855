import styled from '../../theme';

export const Container = styled.div`
  && {
    overflow: scroll;
    &.compressed {
      width: calc(100% - 384px);
    }
  }
` as any;
