import { IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertMenu from './MoreVertMenu';
import * as React from 'react';
import { AutoSizer, Column } from 'react-virtualized';
import constants from 'src/constants';
import messages from '../../messages';
import AddDeliverable from './AddDeliverable';
import EditDeliverable from './EditDeliverable';
import ProjectDetailHeader from 'src/components/ProjectsDetailHeader';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { find } from 'lodash';
import {
  Container,
  CustomTable,
  DisabledSmall,
  EnabledSmall,
  ListContainer,
  Separator
} from './styles';
import { FunctionComponent, useState } from 'react';

const initialDeliverablesValue = {
  estimatedCost: '',
  estimatedEndDate: '',
  estimatedHours: '',
  isActive: 1,
  name: '',
  sow: '',
  type: constants.PROJECT_FIXED_BID_TYPE
};

const initialDeliverablesValueErrors = {
  estimatedCost: false,
  estimatedEndDate: false,
  estimatedHours: false,
  isActive: false,
  name: false,
  sow: false,
  type: false
};

interface IProjectDetailDeliverables {
  selectedProject: any;
  loadingAddDeliverable: boolean;
  loadingEditDeliverable: boolean;
  deliverableTypes: string[];
  createDeliverable: (data: any, callback: () => void) => void;
  editDeliverable: (data: any, callback: () => void) => void;
  deleteDeliverable: (id: number, callback: () => void) => void;
  path: string;
}

interface IProjectsDetailDeliverablesState {
  deliverableAddValues: any;
  deliverableAddValuesError: any;
  deliverableEditValues: any;
  deliverableEditValuesError: any;
  deliverableDeleteId: any;
  openEditDeliverable: boolean;
  openAddDeliverable: boolean;
}

export const ProjectsDetailDeliverables: FunctionComponent<IProjectDetailDeliverables> = props => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<{ id?: string; deliverableId?: string }>();

  const getDeliverableInitialEditValues = (deliverableId: string) => {
    const { deliverables } = props.selectedProject;
    const deliverable = find(deliverables, ['id', parseInt(deliverableId)]);
    const {
      estimatedCost,
      estimatedEndDate,
      estimatedHours,
      isActive
    } = getDeliverableCleanData(deliverable);
    return {
      ...deliverable,
      estimatedCost: estimatedCost,
      estimatedEndDate: estimatedEndDate,
      estimatedHours: estimatedHours,
      isActive: isActive
    };
  };

  const getDeliverableCleanData = (deliverable: any) => {
    const estimatedCost =
      deliverable.estimatedCost === null ? '' : deliverable.estimatedCost;
    const estimatedEndDate = deliverable.estimatedEndDate
      ? deliverable.estimatedEndDate.substring(0, 10)
      : '';
    const estimatedHours =
      deliverable.estimatedHours === null ? '' : deliverable.estimatedHours;
    const isActive = Number(deliverable.isActive);
    return { estimatedCost, estimatedEndDate, estimatedHours, isActive };
  };

  const [state, setState] = useState<IProjectsDetailDeliverablesState>(() => {
    const projectPath = location.pathname.split('/');
    const deliverableId = params.deliverableId;
    return {
      deliverableAddValues: initialDeliverablesValue,
      deliverableAddValuesError: initialDeliverablesValueErrors,
      deliverableEditValues: deliverableId
        ? getDeliverableInitialEditValues(deliverableId)
        : initialDeliverablesValue,
      deliverableEditValuesError: initialDeliverablesValueErrors,
      deliverableDeleteId: deliverableId,
      openAddDeliverable: projectPath.includes('add'),
      openEditDeliverable: projectPath.includes('edit')
    };
  });

  const renderStatus = (event: any) => {
    const status = event.cellData;
    if (status) {
      return <EnabledSmall />;
    }

    return <DisabledSmall />;
  };

  const renderType = (event: any) => {
    const type = event.cellData;
    return type.replace(/_/g, ' ');
  };

  const rowGetter = (event: any) => {
    return props.selectedProject.deliverables[event.index];
  };

  const noRows = () => {
    return (
      <Typography align="center" variant="subtitle1">
        No data
      </Typography>
    );
  };

  const openAddDeliverable = () => {
    setState({ ...state, openAddDeliverable: true });
  };

  const openEdit = (event: any) => {
    const deliverable = event.rowData;
    const {
      estimatedCost,
      estimatedEndDate,
      estimatedHours,
      isActive
    } = getDeliverableCleanData(deliverable);
    setState({
      ...state,
      deliverableEditValues: {
        ...deliverable,
        estimatedCost: estimatedCost,
        estimatedEndDate: estimatedEndDate,
        estimatedHours: estimatedHours,
        isActive: isActive
      },
      deliverableDeleteId: deliverable.id,
      openEditDeliverable: true
    });
    const { selectedProject } = props;
    const projectPath = projectTypeRoute();
    navigate(
      `/${projectPath}/${selectedProject.id}/deliverables/edit/${deliverable.id}`
    );
  };

  const onChange = (prop: string) => (event: any) => {
    setState({
      ...state,
      deliverableAddValues: {
        ...state.deliverableAddValues,
        [prop]: event.target.value
      }
    });
  };

  const onChangeEdit = (prop: string) => (event: any) => {
    setState({
      ...state,
      deliverableEditValues: {
        ...state.deliverableEditValues,
        [prop]: event.target.value
      }
    });
  };

  const onSuccess = () => {
    const { selectedProject } = props;
    const projectPath = projectTypeRoute();
    navigate(`/${projectPath}/${selectedProject.id}/deliverables`);
    setState({
      ...state,
      deliverableAddValues: initialDeliverablesValue,
      deliverableAddValuesError: initialDeliverablesValueErrors,
      deliverableEditValues: initialDeliverablesValue,
      deliverableEditValuesError: initialDeliverablesValueErrors,
      openAddDeliverable: false,
      openEditDeliverable: false
    });
  };

  const projectTypeRoute = () => {
    // this getter returns client-projects or inhouse-projects
    // props.path can be /client-projects/:projectId or /inhouse-projects/:projectId
    const projectType = props.path.split('/')[1];
    return projectType;
  };

  const validateDeliverableInput = (values: any) => {
    const {
      type,
      name,
      estimatedCost,
      estimatedHours,
      estimatedEndDate
    } = values;

    const isNegative = (v: string) => parseFloat(v) < 0;

    const isInvalid = (v: string, isBudget = false) =>
      !v || v === '0' || (isBudget && isNegative(v));
    let errors = {
      ...initialDeliverablesValueErrors,
      estimatedEndDate: isInvalid(estimatedEndDate),
      name: isInvalid(name),
      estimatedCost: isNegative(estimatedCost),
      estimatedHours: isNegative(estimatedHours)
    };

    switch (type) {
      case constants.PROJECT_FIXED_BID_TYPE:
        errors = {
          ...errors,
          estimatedCost: isInvalid(estimatedCost, true)
        };
        break;
      case constants.PROJECT_TIME_MATERIALS_TYPE:
        errors = {
          ...errors,
          estimatedHours: isInvalid(estimatedHours, true)
        };
        break;
      default:
        break;
    }

    return {
      errors,
      isValid: Object.values(errors).every(e => !e)
    };
  };

  const putPostDeliverable = () => {
    const { openEditDeliverable: isEdit } = state;
    const validation = validateDeliverableInput(
      isEdit ? state.deliverableEditValues : state.deliverableAddValues
    );

    if (validation.isValid) {
      if (isEdit) {
        props.editDeliverable(state.deliverableEditValues, onSuccess);
      } else {
        props.createDeliverable(state.deliverableAddValues, onSuccess);
      }
    } else {
      setState({
        ...state,
        deliverableAddValuesError: validation.errors,
        deliverableEditValuesError: validation.errors
      });
    }
  };

  const deleteDeliverable = (event: any) => {
    const deliverable = event.rowData;
    const deliverableId = deliverable
      ? deliverable.id
      : state.deliverableDeleteId;
    props.deleteDeliverable(deliverableId, onSuccess);
  };

  const { selectedProject } = props;
  const projectPath = projectTypeRoute();

  return (
    <Container>
      {selectedProject ? (
        <React.Fragment>
          <ProjectDetailHeader selectedProject={selectedProject} />
          <Separator>
            <Typography variant="h5">{`Deliverables`}</Typography>
            <Link to={`/${projectPath}/${selectedProject.id}/deliverables/add`}>
              <IconButton onClick={openAddDeliverable}>
                <AddIcon />
              </IconButton>
            </Link>
          </Separator>
          <AddDeliverable
            errors={state.deliverableAddValuesError}
            types={props.deliverableTypes}
            open={state.openAddDeliverable}
            loading={props.loadingAddDeliverable}
            values={state.deliverableAddValues}
            add={putPostDeliverable}
            handleClose={onSuccess}
            onChange={onChange}
          />
          <EditDeliverable
            types={props.deliverableTypes}
            open={state.openEditDeliverable}
            loading={props.loadingEditDeliverable}
            values={state.deliverableEditValues}
            errors={state.deliverableEditValuesError}
            edit={putPostDeliverable}
            remove={deleteDeliverable}
            handleClose={onSuccess}
            onChange={onChangeEdit}
          />
          <ListContainer>
            <AutoSizer>
              {({ width, height }) => (
                <CustomTable
                  headerClassName="headerRow"
                  rowClassName="row"
                  headerHeight={50}
                  height={height}
                  noRowsRenderer={noRows}
                  rowCount={props.selectedProject.deliverables.length}
                  rowHeight={52}
                  rowGetter={rowGetter}
                  width={width}
                >
                  <Column
                    cellRenderer={renderStatus}
                    className="column centered"
                    label="Status"
                    dataKey="isActive"
                    width={40}
                    flexGrow={1}
                  />
                  <Column
                    className="column centered"
                    label="SOW"
                    dataKey="sow"
                    width={40}
                    flexGrow={1}
                  />
                  <Column
                    className="column"
                    label="Name"
                    dataKey="name"
                    width={110}
                    flexGrow={1}
                  />
                  <Column
                    cellRenderer={renderType}
                    className="column"
                    label="Type"
                    dataKey="type"
                    width={250}
                    flexGrow={2}
                  />
                  <Column
                    cellRenderer={event => (
                      <MoreVertMenu
                        event={event}
                        openEdit={openEdit}
                        deleteDeliverable={deleteDeliverable}
                      />
                    )}
                    className="column centered more-menu"
                    dataKey="options"
                    width={10}
                    flexGrow={1}
                  />
                </CustomTable>
              )}
            </AutoSizer>
          </ListContainer>
        </React.Fragment>
      ) : (
        <Typography style={{ marginTop: 10 }} variant="h5">
          {messages.NO_PROJECT_SELECTED}
        </Typography>
      )}
    </Container>
  );
};

export default ProjectsDetailDeliverables;
