import { gql, TypedDocumentNode } from '@apollo/client';
import { UninvoicedProject } from '../types';

export type SearchUninvoicedProjectsResponse =
  | {
      data: {
        nodes: UninvoicedProject[];
        pageInfo: {
          hasNextPage: boolean;
          hasPreviousPage: boolean;
          startCursor: string;
          endCursor: string;
        };
      };
    }
  | undefined;

export type SearchUninvoicedProjectsVariables = {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  searchAll?: boolean;
  searchText?: string;
  sortBy?: 'PROJECT' | 'DATE' | 'STATUS';
  sortDirection?: 'ASC' | 'DESC';
};

export const searchUninvoicedProjects: TypedDocumentNode<
  SearchUninvoicedProjectsResponse,
  SearchUninvoicedProjectsVariables
> = gql`
  query searchUninvoicedProjects(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchAll: Boolean
    $searchText: String
    $sortBy: SearchInvoicesSortBy
    $sortDirection: SortDirection
  ) {
    data: searchUninvoicedProjects(
      after: $after
      before: $before
      first: $first
      last: $last
      searchAll: $searchAll
      searchText: $searchText
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      nodes {
        clientColor
        clientId
        clientName
        endDate
        projectId
        projectManagerName
        projectName
        projectNickname
        startDate
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;
