import { callAPI } from 'src/services/api';

export const fetchNotificationType = (params: {
  notificationCategory: string;
}) =>
  callAPI({
    params,
    method: 'get',
    url: '/notification_types/show_by_category'
  });
