import { get } from 'lodash';
import { UserProjectRole } from 'src/api/types';

export interface IGetRowData {
  rowData: UserProjectRole;
}

export const getClient = ({ rowData }: IGetRowData) =>
  get(rowData, 'client.name');

export const getProject = ({ rowData }: IGetRowData) =>
  get(rowData, 'project.name');

export const getDeliverable = ({ rowData }: IGetRowData) =>
  get(rowData, 'deliverable.name');

export const getRole = ({ rowData }: IGetRowData) =>
  get(rowData, 'projectRole.name');

export const getProjectCode = ({ rowData }: IGetRowData) =>
  get(rowData, 'project.nickname');
