import styled, { theme } from 'src/theme';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export const PeriodHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-family: ${theme.font};
  padding: 16px 0px 16px 0px;
  font-weight: 600;
`;

export const DisplayRow = styled.div`
  display: flex;
  align-items: center;
`;

export const ToggleButton = styled.div`
  display: flex;
  align-items: center;
  margin: 0 15px;
  cursor: pointer;
  font-weight: normal;
  color: ${theme.teal};
`;

export const NextIcon = styled(NavigateNextIcon)`
  && {
    padding-left: 10px;
  }
`;

export const PrevIcon = styled(NavigateBeforeIcon)`
  && {
    padding-right: 10px;
  }
`;
