import React from 'react';
import {
  Disabled,
  Enabled,
  Label,
  LineItem,
  NoMarginContent,
  Value
} from './styles';
import messages from '../../messages';
import { QBConnectedClientName } from '../qb/QBConnectedClientName';

const ProjectHeader: React.FC<any> = ({ selectedProject }) => (
  <NoMarginContent>
    <LineItem>
      <Label>
        {messages.LABELS.PROJECT} {messages.LABELS.NAME}
      </Label>
      <Value> {selectedProject.name} </Value>
    </LineItem>

    <LineItem>
      <Label>
        {messages.LABELS.PROJECT} {messages.LABELS.NICKNAME}
      </Label>
      <Value> {selectedProject.nickname} </Value>
    </LineItem>

    <LineItem>
      <Label>
        {messages.LABELS.PROJECT} {messages.LABELS.STATUS}
      </Label>
      <Value>{selectedProject.isActive ? <Enabled /> : <Disabled />}</Value>
    </LineItem>

    <LineItem>
      <Label> {messages.LABELS.CLIENT} </Label>
      <Value>
        <QBConnectedClientName
          hasQBCustomerId={!!selectedProject.client.qbCustomerId}
        >
          {selectedProject.client.name}
        </QBConnectedClientName>
      </Value>
    </LineItem>

    <LineItem>
      <Label> {messages.LABELS.PROJECT_MANAGER} </Label>
      <Value> {selectedProject.projectManager.fullName} </Value>
    </LineItem>
  </NoMarginContent>
);
export default ProjectHeader;
