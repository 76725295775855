import TextField from '@mui/material/TextField';

import styled, { theme } from 'src/theme';

export const CustomInput = styled(TextField)`
  && {
    font-family: ${theme.font}
    margin-right: 20px;
    background-color: #ffffff;
    &.dropdown {
      width: 100%;
      min-width: unset;
      & div {
        & .MuiSelect-root {
          padding: 18px 14px;
        }
      }
    }
    &.normal-padding {
      & div {
        &.MuiSelect-root {
          padding: 18px 8px;
        }
      }
    }
    &.selected {
      & div {
        &.MuiSelect-root {
          padding: 18px 8px;
        }
      }
    }
    &.selected.no-extra-padding {
      & div {
        &.MuiSelect-root {
          padding: 8px 8px;
        }
        &.MuiSelect-select {
          padding: 10px;
        }
      }
    }
    &.date {
      width: 100%;
      margin-bottom: 12px;
      & .MuiOutlinedInput-root {
        & .MuiOutlinedInput-inputMarginDense {
          padding-left: 1px;
          padding-right: 1px;
        }
      }
    }
    &.number {
      width: 48px;
      border-radius: 5px;
      text-align: right;
      & .MuiOutlinedInput-input {
        cursor: pointer;
        padding: 10.5px 8px;
        text-align: right;
        font-size: 14px;
      }
    }
    &.long-string {
      width: 100%;
      margin: 0 20px;
      & ::before {
        border: none !important;
      }

      & .Mui-focused {
        border: 1px solid #d4d4d4;
        border-radius: 5px;
        background-color: #fff;

        ::after {
          border: none;
        }
      }
      & textarea {
        padding: 6px;
      }
      & .MuiInputBase-multiline {
        padding: 0;

        &.MuiInputBase-root.Mui-disabled {
          color: ${theme.black};
        }
      }
    }
    &.long-string:hover {
      background-color: #eee;
    }
    &.long-string.moment-disabled:hover {
      background-color: #fff;
    }
    width: 100%;
  }
`;
