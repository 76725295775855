import * as React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { IAppState } from 'src/reducer';
import * as userActions from 'src/shared/users/actions';
import { IEntityStateUsers, IUserResponse } from 'src/shared/users/types';
import ProjectsList from '../../components/ProjectsList';
import ProjectsListFilters from '../../components/ProjectsListFilters';
import withPagination from 'src/components/withPagination';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { QueryKeys } from 'src/queries/queryKeys';
import { Api } from 'src/api/api';
import { IClient } from 'src/types/IClient';
import { DEFAULT_FETCH_PROJECTS_PER_PAGE } from 'src/api/queries/fetchProjectStats';
import { useThrottle } from 'react-use';

const PaginatedProjectsList = withPagination(ProjectsList, 'projects');

type ProjectsListParamsContainerState = {
  client?: number;
  route: string;
  selectedManager: null | number;
  isInHouse: boolean;
};

type IProjectsListContainer = IProjectsListContainerProps &
  IProjectsListContainerDispatch &
  ProjectsListParamsContainerState;

interface IProjectsListContainerDispatch {
  getManagers: () => void;
}

interface IProjectsListContainerProps {
  managers: IEntityStateUsers;
  currentUser: any;
}

export const ProjectsListContainer: FunctionComponent<IProjectsListContainer> = props => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string>();
  const throttledSearchText = useThrottle(searchText, 1000);
  const [clientId, setClientId] = useState<number | undefined>(props.client);
  const [managerId, setManagerId] = useState<number>();
  const [status, setStatus] = useState(true);
  const [page, setPage] = useState(1);
  const { id } = useParams();

  useEffect(() => {
    props.getManagers();
  }, [location.pathname.split('/')[1]]);

  const {
    data: projectsResponse,
    isFetching: isLoadingProjects,
    refetch: refetchProjects
  } = useQuery(
    [
      QueryKeys.projectStats,
      clientId,
      managerId,
      page,
      throttledSearchText,
      status
    ],
    () => {
      return Api.fetchProjectStats({
        clientId,
        managerId,
        page,
        searchText: throttledSearchText,
        status
      });
    },
    {
      select: response => {
        response.projects = response.projects.map((project: any) => {
          if (project.metrics) {
            project.metrics.forEach((metric: any) => {
              project[metric.metric] = metric.status;
            });
          }
          return project;
        });
        return response;
      }
    }
  );
  const projects = projectsResponse?.projects;
  const total = projectsResponse?.total;
  const selectedProject = projects?.find(p => p.id === Number(id));

  const { data: clients } = useQuery<IClient[]>(QueryKeys.clients, () =>
    Api.fetchClients({ isActive: true, hasActiveProjects: true })
  );

  const clearFilters = () => {
    setSearchText('');
    setClientId(undefined);
    setManagerId(undefined);
    setStatus(true);
    setPage(1);
    navigate(`/${props.route}`);
  };

  const redirectToAdd = () => {
    const { route } = props;
    navigate(`/${route}/add`);
  };

  const goToDetail = (event: any) => {
    const { route } = props;
    navigate(`/${route}/${event.rowData.id}/general`);
  };

  const { mutate: createProjectMetric } = useMutation(Api.createProjectMetric, {
    onSuccess: () => refetchProjects()
  });

  const managers = useMemo(() => {
    return Object.keys(props.managers)
      .map(key => props.managers[key] as IUserResponse)
      .filter(m => m.isActive);
  }, [props.managers]);

  const shouldHideManagerFilter = useMemo(() => {
    return (
      props.currentUser &&
      !props.currentUser.roles.find((cur: any) => cur.name === 'ADMIN')
    );
  }, [props.currentUser]);

  return (
    <React.Fragment>
      <ProjectsListFilters
        clearFilters={clearFilters}
        clientId={clientId}
        clients={clients}
        managerId={managerId}
        managers={managers}
        onChangeClientId={setClientId}
        onChangeManagerId={setManagerId}
        onChangeSearchText={setSearchText}
        onChangeStatus={setStatus}
        shouldHideManager={shouldHideManagerFilter}
        shouldHideClients={props.isInHouse}
        searchText={searchText}
        status={status}
      />
      <PaginatedProjectsList
        goToDetail={goToDetail}
        onAddClick={redirectToAdd}
        items={projects || []}
        totalItems={total || 0}
        updatePage={setPage}
        selectedItem={selectedProject}
        changeMetric={createProjectMetric}
        perPage={DEFAULT_FETCH_PROJECTS_PER_PAGE}
        loading={isLoadingProjects}
        page={page}
      />
    </React.Fragment>
  );
};

export const mapStateToProps = (state: IAppState) => {
  const managers = state.entities.projectManagers;
  const currentUser = state.auth.currentUser;
  return {
    currentUser,
    managers
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IProjectsListContainerDispatch => {
  return bindActionCreators(
    {
      getManagers: userActions.actions.getProjectManagers
    },
    dispatch
  );
};

export default connect<
  IProjectsListContainerProps,
  IProjectsListContainerDispatch
>(
  mapStateToProps,
  mapDispatchToProps
)(ProjectsListContainer);
