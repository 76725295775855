import EnableIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import * as React from 'react';
import { CustomFab, CustomGrid } from './styles';

interface IClientDetailActions {
  disabled: boolean;
  openDialog?: () => void;
  clientStatus: boolean;
  onEditClick: () => void;
}

const ClientDetailActions: React.FC<IClientDetailActions> = ({
  disabled = true,
  openDialog = () => console,
  clientStatus = true,
  onEditClick
}) => (
  <CustomGrid direction="row" justifyContent="center" container={true}>
    <CustomFab
      onClick={onEditClick}
      color="primary"
      disabled={disabled}
      aria-label="Edit"
    >
      <EditIcon />
    </CustomFab>
    <CustomFab
      onClick={openDialog}
      color="secondary"
      disabled={disabled}
      aria-label="Delete"
    >
      {clientStatus ? <DeleteIcon /> : <EnableIcon />}
    </CustomFab>
  </CustomGrid>
);

export default ClientDetailActions;
