import { Deliverable, Invoice, ProjectDetail } from 'src/api/types';

export const ALL_MANAGERS = '0';
export const ALL_CLIENTS = 0;
export const STATUS_FILTER = 'status';
export const START_DATE_FILTER = 'startDate';
export const PROJECT_MANAGER_FILTER = 'projectManager';
export const CLIENT_FILTER = 'client';
export const INVOICES_FILTER = 'invoices';
export const QUERY_FILTER = 'query';
export const SORT_BY_FILTER = 'sortBy';
export const SORT_DIRECTION_FILTER = 'sortDirection';

export const MY_INVOICES = 'My Invoices';
export const ALL_INVOICES = 'All Invoices';
export const PENDING = 'Pending';
export const SAVED_AND_REJECTED = 'Created';
export const SUBMITTED = 'Submitted';
export const APPROVED = 'Approved';
export const PAST_INVOICES = 'Past Invoices';
export const UNINVOICED = 'Ready to Invoice';
export const ALL_ACTIVE = 'All Active';
export const ALL_SENT = 'All Sent';
export const ALL = 'All';
export const SENT = 'Sent';
export const PAID = 'Paid';
export const DUE = 'Due';
export const OVERDUE = 'Overdue';
export const DISMISSED = 'Dismissed';
export const NEW = 'New';
export const REJECTED = 'Rejected';
export const IN_PROGRESS = 'In Progress';
export const CREATED = 'Created';

// FIXME: Not used
export const statusMenu = [
  {
    subGroup: 'Active Invoices',
    subMenu: [ALL_ACTIVE, UNINVOICED, SAVED_AND_REJECTED, SUBMITTED, APPROVED]
  },
  {
    subGroup: 'Sent Invoices',
    subMenu: [SENT, DISMISSED]
  }
];

export const invoiceStatusList = [
  SAVED_AND_REJECTED,
  SUBMITTED,
  APPROVED,
  UNINVOICED,
  ALL_ACTIVE,
  SENT
];

export enum InvoiceStatus {
  ALL = 'ALL',
  DISMISSED = 'DISMISSED',
  SENT = 'SENT',
  PAID = 'PAID',
  APPROVED = 'APPROVED',
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
  REJECTED = 'REJECTED',
  UNINVOICED = 'UNINVOICED'
}

export const invoiceStatusGroups = {
  [SAVED_AND_REJECTED]: [
    InvoiceStatus.NEW,
    InvoiceStatus.IN_PROGRESS,
    InvoiceStatus.REJECTED
  ], // MMNT-1716 to fix lines below
  [DISMISSED]: `[\"DISMISSED\"]`, // eslint-disable-line
  [SUBMITTED]: `[\"SUBMITTED\"]`, // eslint-disable-line
  [APPROVED]: `[\"APPROVED\"]`, //  eslint-disable-line
  [PAST_INVOICES]: [
    InvoiceStatus.DISMISSED,
    InvoiceStatus.SENT,
    InvoiceStatus.PAID
  ],
  [UNINVOICED]: UNINVOICED,
  [SENT]: `[\"SENT\"]`, // eslint-disable-line
  [ALL_SENT]: [InvoiceStatus.SENT, InvoiceStatus.PAID],
  [ALL_ACTIVE]: [
    InvoiceStatus.NEW,
    InvoiceStatus.IN_PROGRESS,
    InvoiceStatus.REJECTED,
    InvoiceStatus.SUBMITTED,
    InvoiceStatus.APPROVED
  ],
  [PAID]: [InvoiceStatus.PAID],
  [ALL]: [
    InvoiceStatus.SENT,
    InvoiceStatus.PAID,
    InvoiceStatus.NEW,
    InvoiceStatus.IN_PROGRESS,
    InvoiceStatus.REJECTED,
    InvoiceStatus.SUBMITTED,
    InvoiceStatus.APPROVED
  ]
};

export interface IInvoice {
  id: number;
  projectId: number;
  creatorId: number;
  creationDate: string;
  endDate: string;
  lastUpdated: string;
  status: InvoiceStatus;
  paidDate: string;
  sentDate: string;
  total: number;
  hours?: number;
  clientCode?: string;
  clientName: string;
  projectName: string;
  projectNickname: string;
  projectManager: string;
  projectManagerId: number;
  projectIsActive: boolean;
  rejectionReason?: string;
  startDate: string;
  specialInstructions?: string;
  clientId: number;
  period?: string; // for uninvoiced
  canProceed?: boolean; // for uninvoiced
  clientQBCustomerId?: number;
  clientColor?: string;
}

export interface IInvoiceResponseItem {
  id: number;
  creatorId: number;
  version: number;
  dateCreated: string;
  endDate: string;
  lastUpdated: string;
  projectId: number;
  rejectionReason: null | string;
  sentDate: null | string;
  specialInstructions: null | string;
  startDate: string;
  status: InvoiceStatus;
  paidDate: null | string;
  total: number;
}

export interface IInvoiceDisplay {
  clientName: string;
  projectName: string;
  amount: string;
  invoiceDate: string;
  paidDate: string;
  status: string;
}

export interface IInvoiceListReducerState {
  invoicesByProjectId: IInvoice[];
  invoices: IInvoice[];
  filters: IInvoiceListFilters;
  selectedInvoice?: Invoice | null;
  total: number; // for pagination
  page: number; // for pagination
  counts: InvoiceCountType; // for header count values
  projects: ProjectDetail[];
  activeProjects: ProjectDetail[];
  rightPanelOpen: boolean;
  invoiceSidebarDetail: IInvoiceSidebarDetail;
  loading: boolean;
  showFilters: boolean;
}

export interface IInvoiceListFilters {
  [STATUS_FILTER]?: string;
  [START_DATE_FILTER]?: InvoiceStartDate;
  [PROJECT_MANAGER_FILTER]?: string;
  [CLIENT_FILTER]?: number;
  [INVOICES_FILTER]?: string;
  [QUERY_FILTER]?: string;
  [SORT_BY_FILTER]?: string;
  [SORT_DIRECTION_FILTER]?: string;
}

export interface InvoiceFilter {
  fromFilter: IInvoiceListFilters;
  custom: {
    page?: number;
    queryHistoricalData?: boolean;
  };
}

export interface InvoiceFilterPayload {
  key: string;
  value: string | number;
  offset?: number;
  direction?: string;
  order?: string;
}

export enum InvoiceStartDate {
  LAST_MONTH = 'Last Month',
  LAST_3_MONTHS = 'Last 3 Months',
  PAST_YEAR = 'Past Year',
  ALL = 'All Time'
}

export type InvoiceCountType = {
  uninvoiced: number;
  saved: number;
  submitted: number;
  approved: number;
  sent: number;
};

export type IProjectEmail = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  type: string;
};

export enum ENoteType {
  INVOICE_SPECIAL_INSTRUCTION = 0,
  PROJECT_SPECIAL_INSTRUCTION = 1,
  INVOICE_REJECTION_REASON = 2
}

export type INote = {
  text: string;
  userName?: string;
  createdAt: string;
  updatedAt: string;
  typeOf: ENoteType;
  id?: number;
  invoice: number;
  userId?: number;
};

export type IInvoiceSidebarDetail = {
  deliverables: Deliverable[];
  users: { id: number; fullName: string }[];
  projectStats: any;
  invoices: IInvoice[];
  totalInvoices: number;
  projectEmails: IProjectEmail[];
  notes: INote[];
  billableHours?: number;
  nonBillableHours?: number;
};
