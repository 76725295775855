import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';

export const fetchQuickBooksOnlineStatus = () =>
  callAPI({
    method: 'get',
    url: '/quickbooks/get_qbo_status'
  })
    .then(response => response.status === 200)
    .catch(e => {
      toast('Failed to fetch QuickBooksOnline status', 'error');
      throw e;
    });
