import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import App from './containers/App';
import store from './store';
import { theme } from './theme';
import GlobalStyle from './theme/global';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'react-infinite-calendar/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './toast.css';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './api/apollo_client';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <DndProvider backend={HTML5Backend}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={apolloClient}>
          <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <GlobalStyle />
              <BrowserRouter basename={process.env.PUBLIC_URL}>
                <App />
              </BrowserRouter>
            </LocalizationProvider>
          </QueryClientProvider>
        </ApolloProvider>
      </ThemeProvider>
    </Provider>
  </DndProvider>
);

// Prevents HMR from clearing Redux store
if (module.hot) {
  module.hot.accept('./containers/App', () => {
    root.render(
      <DndProvider backend={HTML5Backend}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <>
              <GlobalStyle />
              <BrowserRouter basename={process.env.PUBLIC_URL}>
                <App />
              </BrowserRouter>
            </>
          </ThemeProvider>
        </Provider>
      </DndProvider>
    );
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
