import EnableIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import * as React from 'react';
import { CustomFab, CustomGrid } from './styles';

interface IProjectsDetailActions {
  disabled: boolean;
  openDialog?: () => void;
  projectStatus: boolean;
  onEditClick: () => void;
}

const ProjectsDetailActions: React.FC<IProjectsDetailActions> = ({
  disabled = true,
  openDialog = () => console,
  projectStatus = true,
  onEditClick
}) => (
  <CustomGrid direction="row" justifyContent="center" container={true}>
    {disabled ? null : (
      <React.Fragment>
        <CustomFab
          onClick={onEditClick}
          color="primary"
          disabled={disabled}
          aria-label="Edit"
        >
          <EditIcon />
        </CustomFab>
        <CustomFab
          onClick={openDialog}
          color="secondary"
          disabled={disabled}
          aria-label="Delete"
        >
          {projectStatus ? <DeleteIcon /> : <EnableIcon />}
        </CustomFab>
      </React.Fragment>
    )}
  </CustomGrid>
);

export default ProjectsDetailActions;
