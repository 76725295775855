import Button, { ButtonProps } from '@mui/material/Button';
import styled from '../../theme';

export const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`;

export const CustomButton: React.ComponentType<ButtonProps> = styled(Button)`
  && {
    margin: 10px 0 10px 0;
  }
` as any;
