import { IInvoiceDetailState } from './types';
import * as invoiceDetailActions from './actions';

import _ from 'lodash';
const initialState: IInvoiceDetailState = {
  loading: false,
  invoice: null,
  localView: undefined,
  fixedLineItemId: null,
  filters: {
    importTimesheets: true,
    client: 0,
    project: 0,
    period: ''
  }
};

const reducer = (
  state: IInvoiceDetailState = initialState,
  action: invoiceDetailActions.Actions
) => {
  switch (action.type) {
    case invoiceDetailActions.POPULATED_HYDRATED_INVOICE: {
      return {
        ...state,
        view: _.cloneDeep(action.payload),
        localView: action.payload,
        loading: false
      };
    }
    case invoiceDetailActions.CLEAR_INVOICE_DETAIL: {
      return {
        ...state,
        view: null,
        localView: null,
        filters: null
      };
    }
    case invoiceDetailActions.HYDRATE_INVOICE: {
      return {
        ...state,
        filters: action.payload.filters,
        loading: true
      };
    }
    case invoiceDetailActions.MODIFY_LOCAL_VIEW: {
      return {
        ...state,
        localView: { ...action.payload } // need to clone object to re-render
      };
    }

    case invoiceDetailActions.RESET_TIMESHEET_ENTRY: {
      const { userRowIndex, timesheetEntryId } = action.payload;
      if (state.view) {
        const oldTimesheetEntry = _.find(
          state.view.userRows[userRowIndex].hourList,
          { id: timesheetEntryId }
        );
        if (state.localView && oldTimesheetEntry) {
          const newLocalView = _.cloneDeep(state.localView);
          newLocalView.userRows[userRowIndex].hourList = _.map(
            newLocalView.userRows[userRowIndex].hourList,
            entry => (entry.id === timesheetEntryId ? oldTimesheetEntry : entry)
          );
          return {
            ...state,
            localView: newLocalView
          };
        }
      }
      return {
        ...state
      };
    }

    case invoiceDetailActions.POPULATE_FIXED_LINE_ITEM_ID: {
      const { id } = action.payload;
      return {
        ...state,
        fixedLineItemId: id
      };
    }

    case invoiceDetailActions.POPULATE_TIMESHEET_ENTRIES: {
      const { entries } = action.payload;
      return {
        ...state,
        localView: {
          ...state.localView,
          timesheetDialogEntries: entries
        }
      };
    }

    case invoiceDetailActions.SET_IS_INVOICE_DETAIL_LOADING: {
      const { loading } = action.payload;
      return {
        ...state,
        loading
      };
    }

    case invoiceDetailActions.SAVE_INVOICE: {
      return {
        ...state,
        loading: true
      };
    }

    default:
      return {
        ...state
      };
  }
};

export default reducer;
