import styled from 'src/theme';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export const HeaderContainer = styled.div`
  && {
    display: flex;
    align-self: flex-start;
  }
`;

export const NextIcon = styled(NavigateNextIcon)`
  && {
    padding: 13px 0 0 0;
    cursor: pointer;
  }
`;

export const PrevIcon = styled(NavigateBeforeIcon)`
  && {
    padding: 13px 0 0 0;
    cursor: pointer;
  }
`;

export const NoData = styled.div`
  && {
    padding: 145px;
  }
`;
