import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import Copy from '@mui/icons-material/FileCopy';
import TeamList from 'src/components/shared/RightSidebar/RightSidebarInfoPane/TeamList';

import {
  IProjectEmail,
  InvoiceStatus,
  IInvoiceSidebarDetail,
  ENoteType,
  INote,
  IInvoice
} from 'src/shared/invoice-list/types';

import {
  FlexBox,
  ColumnFlexBox,
  SectionHeader,
  KeyColumn,
  ValueColumn,
  SectionPrimaryHeader,
  ColumnFlexBoxWithTopBorder,
  FlexBoxMarginBottom16PaddingHoriz16,
  AlignStartColumnFlexBox,
  HighlightedValueColumn,
  SubLineItem,
  StyledErrorOutlineIcon,
  RejectionText
} from 'src/components/shared/RightSidebar/RightSidebarInfoPane/styles';
import {
  formatDatePeriod,
  formatAsCurrency,
  copyTextToClipboard
} from 'src/shared/utils';
import { OutstandingInvoices } from 'src/components/InvoiceList/InvoiceListSidebar/InformationPane/OutstandingInvoices';
import { InvoiceNotes } from './InvoiceNotes';
import { OpenInvoiceDetail } from './OpenInvoiceDetail';
import { ICurrentUser } from 'src/shared/auth/types';
import { removeUTCSignal } from 'src/shared/invoice/utils';
import { StaticTextField } from './InvoiceNotes/styles';
import { QBConnectedClientName } from 'src/components/qb/QBConnectedClientName';
import { Color } from 'src/constants';
import { Deliverable, Invoice } from 'src/api/types';

const BUDGET_RED = '#d0021b';
const BUDGET_ORANGE = '#ef8802';

interface IInformationPane {
  invoiceSidebarDetail: IInvoiceSidebarDetail;
  invoice: IInvoice | Invoice;
  fetchMoreInvoices: () => void;
  createNewInstruction: ({
    text,
    typeOf
  }: {
    text: string;
    typeOf: number;
  }) => void;
  deleteInvoiceNote: ({ id }: { id: number }) => void;
  updateInvoiceNote: ({ text, id }: { text: string; id: number }) => void;
  canEdit: boolean;
  currentUser: ICurrentUser;
  isInvoiceList?: boolean;
  openInvoiceDetail: (id?: number) => void;
}

export class InformationPane extends React.Component<IInformationPane> {
  constructor(props: any) {
    super(props);
    this.showMoreInvoices = this.showMoreInvoices.bind(this);
  }

  render() {
    const {
      invoiceSidebarDetail,
      invoice,
      canEdit,
      currentUser,
      isInvoiceList,
      openInvoiceDetail
    } = this.props;
    const invoices = invoiceSidebarDetail.invoices;

    let budgetColor = BUDGET_RED;
    if (invoiceSidebarDetail.projectStats) {
      const budgetUsedPercentage =
        invoiceSidebarDetail.projectStats.amountInvoiced /
        invoiceSidebarDetail.projectStats.estimatedCost;
      if (invoice.projectIsActive) {
        if (budgetUsedPercentage < 1) {
          budgetColor = BUDGET_ORANGE;
        }
        if (budgetUsedPercentage < 0.75) {
          budgetColor = Color.GREEN_PIGMENT;
        }
      } else {
        if (budgetUsedPercentage < 1.1) {
          budgetColor = BUDGET_ORANGE;
        }
        if (budgetUsedPercentage < 1) {
          budgetColor = Color.GREEN_PIGMENT;
        }
      }
    }
    const { notes } = invoiceSidebarDetail;
    let rejectionNote: INote = {
      text: invoice?.rejectionReason || '',
      userName: 'Account Manager',
      typeOf: ENoteType.INVOICE_REJECTION_REASON,
      invoice: Number(invoice.id),
      createdAt:
        (invoice as IInvoice).creationDate || (invoice as Invoice).dateCreated,
      updatedAt: invoice.lastUpdated
    };
    if (_.eq(invoice.status, InvoiceStatus.REJECTED)) {
      if (notes && notes.length) {
        const rejectionNotes = _(notes ? notes : [])
          .filter(
            (note: INote) => note.typeOf === ENoteType.INVOICE_REJECTION_REASON
          )
          .uniq()
          .compact()
          .sort((a: INote, b: INote) => (a.createdAt > b.createdAt ? -1 : 1))
          .value();

        const latest = _.head(rejectionNotes);
        if (latest) {
          rejectionNote = latest;
        }
      }
    }
    return (
      <React.Fragment>
        <ColumnFlexBox>
          <SectionHeader>Project</SectionHeader>
          <FlexBox>
            <KeyColumn>Client</KeyColumn>
            <HighlightedValueColumn
              to={`/client-management/${invoice.clientId}`}
            >
              <QBConnectedClientName
                hasQBCustomerId={!!(invoice as Invoice).clientQbCustomerId}
              >
                {invoice.clientName}
              </QBConnectedClientName>
            </HighlightedValueColumn>
          </FlexBox>
          <FlexBox>
            <KeyColumn>Project</KeyColumn>
            <HighlightedValueColumn
              to={`/client-projects/${invoice.projectId}/general`}
            >
              {invoice.projectName}
            </HighlightedValueColumn>
          </FlexBox>
          <FlexBox>
            <KeyColumn>Deliverables</KeyColumn>
            <ValueColumn className={'align-left'}>
              {_.map(
                invoiceSidebarDetail.deliverables,
                (deliverable: Deliverable) => deliverable.name
              ).join(', ')}
            </ValueColumn>
          </FlexBox>
          <FlexBox>
            <KeyColumn>Invoice Period</KeyColumn>
            <ValueColumn className={'align-left'}>
              {formatDatePeriod(
                removeUTCSignal(invoice.startDate),
                removeUTCSignal(invoice.endDate),
                true
              )}
            </ValueColumn>
          </FlexBox>
          <FlexBox>
            <KeyColumn>Status</KeyColumn>
            <ValueColumn className={'align-left'}>{invoice.status}</ValueColumn>
          </FlexBox>
          {_.eq(invoice.status, InvoiceStatus.REJECTED) &&
            invoice.rejectionReason && (
              <React.Fragment>
                <FlexBox>
                  <ValueColumn className="rejection-wrapper-invoice">
                    <StaticTextField className="rejection">
                      <StyledErrorOutlineIcon />
                      REJECTION NOTES
                    </StaticTextField>
                  </ValueColumn>
                </FlexBox>
                <FlexBox>
                  <KeyColumn>Rejected By</KeyColumn>
                  <ValueColumn className={'align-left'}>
                    {rejectionNote.userName} -{' '}
                    {moment(rejectionNote.createdAt).format('MMM DD')}
                  </ValueColumn>
                </FlexBox>
                <FlexBox>
                  <RejectionText>{rejectionNote.text}</RejectionText>
                </FlexBox>
              </React.Fragment>
            )}
        </ColumnFlexBox>
        {isInvoiceList && openInvoiceDetail && (
          <OpenInvoiceDetail onClickOpenInvoiceDetail={openInvoiceDetail} />
        )}
        <TeamList
          users={invoiceSidebarDetail.users.concat({
            id: Number(invoice.projectManagerId),
            fullName:
              (invoice as IInvoice).projectManager ||
              (invoice as Invoice).projectManagerName
          })}
          projectManagerId={Number(invoice.projectManagerId)}
        />
        <ColumnFlexBoxWithTopBorder>
          <SectionHeader>Hours</SectionHeader>
          <FlexBox>
            <KeyColumn>Total Billable</KeyColumn>
            <ValueColumn>{invoiceSidebarDetail.billableHours}</ValueColumn>
          </FlexBox>
          <FlexBox>
            <KeyColumn>Total Non-billable</KeyColumn>
            <ValueColumn>{invoiceSidebarDetail.nonBillableHours}</ValueColumn>
          </FlexBox>
          &nbsp;
        </ColumnFlexBoxWithTopBorder>
        <InvoiceNotes
          canEdit={canEdit}
          notes={notes}
          createNewInstruction={({ text }: { text: string }) =>
            this.props.createNewInstruction({
              text,
              typeOf: ENoteType.INVOICE_SPECIAL_INSTRUCTION
            })
          }
          currentUser={currentUser}
          deleteInvoiceNote={this.props.deleteInvoiceNote}
          updateInvoiceNote={this.props.updateInvoiceNote}
        />
        <ColumnFlexBoxWithTopBorder>
          <SectionPrimaryHeader>Budget</SectionPrimaryHeader>
          <FlexBox>
            <KeyColumn>Budget Calculation</KeyColumn>
            <ValueColumn>
              {invoiceSidebarDetail.projectStats &&
                _.capitalize(
                  invoiceSidebarDetail.projectStats.budgetPercentagePreference
                )}
            </ValueColumn>
          </FlexBox>
          <FlexBox>
            <KeyColumn>Est. Cost</KeyColumn>
            <ValueColumn>
              {invoiceSidebarDetail.projectStats &&
                formatAsCurrency(
                  invoiceSidebarDetail.projectStats.estimatedCost
                )}
            </ValueColumn>
          </FlexBox>
          <FlexBox>
            <KeyColumn>Total Amount</KeyColumn>
            <ValueColumn>
              {invoiceSidebarDetail.projectStats &&
                formatAsCurrency(invoiceSidebarDetail.projectStats.totalAmount)}
            </ValueColumn>
          </FlexBox>
          <SubLineItem>
            <KeyColumn>Invoiced Amount</KeyColumn>
            <ValueColumn>
              {invoiceSidebarDetail.projectStats &&
                formatAsCurrency(invoiceSidebarDetail.projectStats.spent)}
            </ValueColumn>
          </SubLineItem>
          <SubLineItem>
            <KeyColumn>Unapproved Invoice Amount</KeyColumn>
            <ValueColumn>
              {invoiceSidebarDetail.projectStats &&
                formatAsCurrency(
                  invoiceSidebarDetail.projectStats.uninvoicedAmount
                )}
            </ValueColumn>
          </SubLineItem>
          <SubLineItem>
            <KeyColumn>Unapproved Timesheet Amount</KeyColumn>
            <ValueColumn>
              {invoiceSidebarDetail.projectStats &&
                formatAsCurrency(
                  invoiceSidebarDetail.projectStats.amountUnapproved
                )}
            </ValueColumn>
          </SubLineItem>
          <FlexBox>
            <KeyColumn>Remaining Budget</KeyColumn>
            <ValueColumn>
              <span style={{ color: budgetColor }}>
                {invoiceSidebarDetail.projectStats &&
                  formatAsCurrency(
                    invoiceSidebarDetail.projectStats.estimatedCost -
                      invoiceSidebarDetail.projectStats.totalAmount
                  )}
              </span>
            </ValueColumn>
          </FlexBox>
          <FlexBox>
            <KeyColumn>Est. Hours</KeyColumn>
            <ValueColumn>
              {invoiceSidebarDetail.projectStats &&
                invoiceSidebarDetail.projectStats.estimatedHours}{' '}
              h
            </ValueColumn>
          </FlexBox>
          <FlexBox>
            <KeyColumn>Hours Worked</KeyColumn>
            <ValueColumn>
              {invoiceSidebarDetail.projectStats &&
                invoiceSidebarDetail.projectStats.hoursWorked}{' '}
              h
            </ValueColumn>
          </FlexBox>
          <SubLineItem>
            <KeyColumn>Invoiced Hours</KeyColumn>
            <ValueColumn>
              {invoiceSidebarDetail.projectStats &&
                invoiceSidebarDetail.projectStats.invoicingHours}{' '}
              h
            </ValueColumn>
          </SubLineItem>
          <SubLineItem>
            <KeyColumn>Unapproved Invoice Hours</KeyColumn>
            <ValueColumn>
              {invoiceSidebarDetail.projectStats &&
                invoiceSidebarDetail.projectStats.hoursUninvoiced}{' '}
              h
            </ValueColumn>
          </SubLineItem>
          <SubLineItem>
            <KeyColumn>Unapproved Timesheet Hours</KeyColumn>
            <ValueColumn>
              {invoiceSidebarDetail.projectStats &&
                invoiceSidebarDetail.projectStats.hoursUnapproved}{' '}
              h
            </ValueColumn>
          </SubLineItem>
          <FlexBox>
            <KeyColumn>Remaining Hours</KeyColumn>
            <ValueColumn>
              {invoiceSidebarDetail.projectStats &&
                invoiceSidebarDetail.projectStats.estimatedHours -
                  invoiceSidebarDetail.projectStats.hoursWorked}{' '}
              h
            </ValueColumn>
          </FlexBox>
        </ColumnFlexBoxWithTopBorder>
        <ColumnFlexBoxWithTopBorder>
          <OutstandingInvoices
            invoices={invoices}
            invoice={invoice}
            totalInvoices={invoiceSidebarDetail.totalInvoices}
            showMoreInvoices={this.showMoreInvoices}
            openInvoiceDetail={this.props.openInvoiceDetail}
          />
        </ColumnFlexBoxWithTopBorder>
        <ColumnFlexBoxWithTopBorder>
          <SectionPrimaryHeader>Project Emails</SectionPrimaryHeader>
          <AlignStartColumnFlexBox>
            {_.map(
              invoiceSidebarDetail.projectEmails,
              (projectEmail: IProjectEmail) => (
                <FlexBoxMarginBottom16PaddingHoriz16 key={projectEmail.email}>
                  <div>{projectEmail.type}:</div>
                  <div>{projectEmail.email}</div>
                  <div>
                    <Copy
                      onClick={() => copyTextToClipboard(projectEmail.email)}
                    />
                  </div>
                </FlexBoxMarginBottom16PaddingHoriz16>
              )
            )}
          </AlignStartColumnFlexBox>
        </ColumnFlexBoxWithTopBorder>
      </React.Fragment>
    );
  }

  private showMoreInvoices() {
    const { invoiceSidebarDetail } = this.props;
    if (
      invoiceSidebarDetail.totalInvoices > invoiceSidebarDetail.invoices.length
    ) {
      this.props.fetchMoreInvoices();
    }
  }
}

export default InformationPane;
