import * as React from 'react';
import { connect } from 'react-redux';
import { IAppState } from 'src/reducer';
import LeftDrawer from '../../components/LeftDrawer';
import { FunctionComponent, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from 'src/contexts/app-context';

interface ILeftDrawerProps {
  currentUser: any;
}

export const LeftDrawerContainer: FunctionComponent<ILeftDrawerProps> = props => {
  const context = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <LeftDrawer
      currentUser={props.currentUser}
      drawerIsOpen={context.isLeftDrawerOpen}
      mobileDrawerIsOpened={context.isLeftMobileDrawerOpen}
      toogleMenuMobile={context.toggleLeftMobileDrawer}
      hideMenuClick={context.toggleLeftDrawer}
      navigate={navigate}
      path={location.pathname}
    />
  );
};

export const mapStateToProps = (state: IAppState) => ({
  currentUser: state.auth.currentUser
});

export default connect<ILeftDrawerProps>(mapStateToProps)(LeftDrawerContainer);
