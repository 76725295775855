import React from 'react';
import Dialog, { IFooterButton } from 'src/components/core/Dialog';
import { outlinedUnstyledBaseProps } from 'src/components/core/Button/OutlinedUnstyled';

interface IConfirmCloseDialogModal {
  onLeave: () => void;
  onCancel: () => void;
}

const ConfirmCloseDialogModal = ({
  onLeave,
  onCancel
}: IConfirmCloseDialogModal) => {
  const footerButtons: IFooterButton[] = [
    {
      id: '0',
      children: 'Cancel',
      onClick: () => onCancel(),
      ...outlinedUnstyledBaseProps
    },
    {
      id: '1',
      children: 'Leave',
      onClick: () => onLeave()
    }
  ];

  return (
    <Dialog open onClose={onCancel} footerButtons={footerButtons}>
      <p>
        There are unsaved changes that will be lost if you decide to leave.{' '}
        <strong>Do you want to leave without saving?</strong>
      </p>
    </Dialog>
  );
};

export default ConfirmCloseDialogModal;
