import { Box, IconButton, MenuItem, TextField } from '@mui/material';
import styled, { theme } from '../../theme';

export const StyledSearch = styled(TextField)`
  &.MuiFormControl-marginNormal {
    margin-bottom: 0;
    margin-top: 0;
  }

  .MuiInputBase-input {
    font-size: 14px;
    padding: 9px 0 9px;
    color: ${theme.darkGray};
  }
  .MuiSvgIcon-root {
    height: 18px;
    width: 18px;
    max-width: 320px;
    fill: ${theme.teal};
  }
  .MuiOutlinedInput-adornedStart {
    padding-left: 8px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: ${theme.border};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  && {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    max-width: 320px;
  }
`;

export const NewInvoiceButton = styled.div`
  && {
    display: flex;
    font-size: 16px;
    margin-right: 35px;
    text-transform: capitalize;
    white-space: nowrap;
    color: ${theme.teal};
    cursor: pointer;

    svg {
      padding-left: 8px;
      width: 20px;
      height: 20px;
    }
  }
`;

export const FlexBox = styled(Box)`
  position: absolute;
  right: 115px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
`;

export const ClientIcon = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 100%;
  margin-left: 5px;
  background-color: ${props => props.color};
`;

export const CloseButton = styled(IconButton)`
  && {
    padding: 0;
  }

  .MuiSvgIcon-root {
    fill: rgba(0, 0, 0, 0.87);
  }
`;
