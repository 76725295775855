import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';

export const loginQuickBooksOnline = () =>
  callAPI({
    method: 'get',
    url: '/quickbooks/login_to_qbo'
  })
    .then(res => {
      const win = window.open(res.data, '_blank');
      if (win) {
        win.focus();
      }
      return res;
    })
    .catch(e => {
      toast('Failed to login to QuickBooksOnline', 'error');
      throw e;
    });
