import { callAPI } from 'src/services/api';

export const createNotification = (data: {
  userId: number;
  resourceId: number;
  resourceType: string;
  notificationTypeId: number;
}) =>
  callAPI({
    data,
    method: 'post',
    url: '/notifications'
  });
