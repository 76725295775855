import React from 'react';
import { Box } from '@mui/material';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import { FileListItem, FlexBox } from './styles';

export interface FileListProps {
  files: File[] | { name: string }[];
  onClickFileName?: () => void;
  onRemoveFile?: () => void;
}

const FileList: React.FC<FileListProps> = ({
  files,
  onClickFileName,
  onRemoveFile
}) => {
  function list(files: any[]) {
    const label = (file: File | { name: string }) => file.name;
    return files.map((file: any) => (
      <FlexBox key={file.name}>
        {label(file)}
        <RemoveCircle onClick={onRemoveFile} />
      </FlexBox>
    ));
  }
  const fileList = list(files);
  return (
    <Box>
      <FileListItem onClick={onClickFileName}>{fileList}</FileListItem>
    </Box>
  );
};

export default FileList;
