import Fab, { FabProps } from '@mui/material/Fab';
import Grid, { GridProps } from '@mui/material/Grid';
import styled from '../../theme';

export const CustomGrid: React.ComponentType<GridProps> = styled(Grid)`
  position: absolute;
  bottom: 10px;
  && {
    width: calc(100% - 20px);
  }
` as any;

export const CustomFab: React.ComponentType<FabProps> = styled(Fab)`
  && {
    margin: 5px;
    z-index: 300;
  }
` as any;
