import { call, put, takeEvery } from 'redux-saga/effects';
import {
  deleteUser as deleteUserApi,
  getCurrentUser as getCurrentUserApi,
  getManagers as getManagersApi,
  getProjectManagers as getProjectManagersApi,
  getRoles as getRolesApi,
  getUser as getUserApi,
  getUsers as getUsersApi,
  handleError,
  updateUser as updateUserApi
} from 'src/services/api';
import { toast } from 'src/services/toast';
import * as userActions from './actions';
import { usersNormalizer } from './normalizer';
import { IGetUserParams, IRole, IUserEntities, IUserResponse } from './types';
import { Api } from 'src/api/api';

function* addUser(action: userActions.Actions) {
  if (action.type === userActions.ADD_USER) {
    try {
      const { userData, callback } = action.payload;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const createdUser = yield call(Api.createUser, userData);
      yield put(userActions.actions.addUserSucceeded(createdUser, userData));
      yield call(callback);
    } catch (error) {
      yield put(userActions.actions.addUserFailed());
      yield call(handleError, error as Error);
    }
  }
}

function* deleteUser(action: userActions.Actions) {
  if (action.type === userActions.DELETE_USER) {
    const { userId } = action.payload;
    try {
      yield call(deleteUserApi, userId);
      yield put(userActions.actions.deleteUserSucceeded(userId));
    } catch (error) {
      yield call(handleError, error as Error);
    }
  }
}

function* getCurrentUser(action: userActions.Actions) {
  if (action.type === userActions.GET_CURRENT_USER) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response = yield call(getCurrentUserApi);
      const { data: userData } = response;
      const transformedUserData: IUserResponse[] = userData;
      yield put(
        userActions.actions.getCurrentUserSucceeded(transformedUserData)
      );
    } catch (error) {
      yield call(handleError, error as Error);
    }
  }
}

function* getManagers() {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = yield call(getManagersApi);
    const { data } = response;
    const { data: userData } = data;
    const transformedUserData: IUserResponse[] = userData;
    yield put(userActions.actions.getManagersSucceeded(transformedUserData));
  } catch (error) {
    yield call(handleError, error as Error);
  }
}

function* getProjectManagers() {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = yield call(getProjectManagersApi);
    const { data } = response;
    const { data: userData } = data;
    const transformedUserData: IUserResponse[] = userData;
    yield put(
      userActions.actions.getProjectManagersSucceeded(transformedUserData)
    );
  } catch (error) {
    yield call(handleError, error as Error);
  }
}

function* getRoles() {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = yield call(getRolesApi);
    const { data: roleData } = response;
    const transformedRoleData: IRole[] = roleData;
    yield put(userActions.actions.getRolesSucceeded(transformedRoleData));
  } catch (error) {
    yield call(handleError, error as Error);
  }
}

function* getUser(action: userActions.Actions) {
  const isgetUserRecordsType = action.type === userActions.GET_USER_HAS_RECORDS;
  if (
    action.type === userActions.GET_USER ||
    action.type === userActions.GET_USER_HAS_RECORDS
  ) {
    const { userId } = action.payload;
    try {
      const options: IGetUserParams = {};
      const {
        getUserHasRecords,
        getUserHasRecordsSucceeded,
        getUserSucceeded
      } = userActions.actions;
      if (isgetUserRecordsType) {
        options.hasRecords = true;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response = yield call(getUserApi, userId, options);
      const { data } = response;
      const { data: userData } = data;
      const transformedUserData: IUserResponse[] = userData;
      let successAction;
      if (isgetUserRecordsType) {
        successAction = getUserHasRecordsSucceeded(transformedUserData, userId);
      } else {
        successAction = getUserSucceeded(transformedUserData, userId);
      }
      yield put(successAction);
      if (!isgetUserRecordsType && !transformedUserData[0].isActive) {
        yield put(getUserHasRecords(userId));
      }
    } catch (error) {
      if (isgetUserRecordsType) {
        yield put(userActions.actions.getUserHasRecordsFailed(userId));
      }
      yield call(handleError, error as Error);
    }
  }
}

export function* getUsers(action: userActions.Actions) {
  try {
    let params;
    if ('params' in action.payload) params = action.payload.params;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = yield call(getUsersApi, params);
    const {
      data: { data: userData }
    } = response;
    const transformedUserData: IUserEntities = usersNormalizer(userData);
    const { users, roles } = transformedUserData.entities;
    const usersList = Object.values(users).map(user => {
      return {
        fullName: user.fullName,
        id: user.id,
        isActive: user.isActive,
        roles: user.roles,
        title: user.title,
        managerId: user.managerId,
        managerFullName: ''
      };
    });
    yield put(userActions.actions.getUsersSucceeded(usersList, roles));
  } catch (error) {
    yield put(userActions.actions.getUsersSucceeded([], []));
    yield call(handleError, error as Error);
  }
}

function* updateUser(action: userActions.Actions) {
  if (action.type === userActions.UPDATE_USER) {
    const { userData, userId, callback } = action.payload;
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response = yield call(updateUserApi, userData, userId);
      const { data: responseUserData } = response;
      const transformedUserData: IUserResponse = responseUserData;
      yield put(
        userActions.actions.updateUserSucceeded(
          transformedUserData,
          userData,
          userId
        )
      );
      toast('User updated', 'success');
      if (callback) {
        yield call(callback);
      }
    } catch (error) {
      yield put(userActions.actions.updateUserFailed());
      toast('There was an error updating the user', 'error');
      yield call(handleError, error as Error);
    }
  }
}

function* userSaga() {
  yield takeEvery(userActions.ADD_USER, addUser);
  yield takeEvery(userActions.DELETE_USER, deleteUser);
  yield takeEvery(userActions.GET_CURRENT_USER, getCurrentUser);
  yield takeEvery(userActions.GET_MANAGERS, getManagers);
  yield takeEvery(userActions.GET_PROJECT_MANAGERS, getProjectManagers);
  yield takeEvery(userActions.GET_ROLES, getRoles);
  yield takeEvery(userActions.GET_USER, getUser);
  yield takeEvery(userActions.GET_USER_HAS_RECORDS, getUser);
  yield takeEvery(userActions.GET_USERS, getUsers);
  yield takeEvery(userActions.UPDATE_USER, updateUser);
}

export default userSaga;
