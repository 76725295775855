import { MenuItem } from '@mui/material';
import * as React from 'react';
import { ICurrentUser } from 'src/shared/auth/types';
import messages from '../../messages';
import { CustomInput } from 'src/components/ProjectsListFilters/styles';
import { FlexBox, StyledAvatar } from './styles';

interface IImpersonate {
  loading: boolean;
  users?: ICurrentUser[];
  selectedUser: string | number;
  onUserChange: (...args: any[]) => void;
  onLoginClick: (...args: any[]) => void;
}

const Impersonate: React.FC<IImpersonate> = ({
  loading,
  users = [],
  onUserChange,
  selectedUser,
  onLoginClick
}) => (
  <FlexBox>
    <CustomInput
      id="users-select"
      select={true}
      onChange={onUserChange}
      label={messages.IMPERSONATE}
      value={selectedUser}
      margin="normal"
      variant="outlined"
      SelectProps={{
        MenuProps: { PaperProps: { style: { maxHeight: '325px' } } }
      }}
    >
      <MenuItem key={'default'} value={'default'}>
        {loading ? messages.LOADING : messages.SELECT_USER}
      </MenuItem>

      {users.map(current => (
        <MenuItem key={current.id} value={current.id}>
          {`${current.fullName}`}
        </MenuItem>
      ))}
    </CustomInput>
    <StyledAvatar onClick={onLoginClick} variant="rounded">
      {messages.IMPERSONATE.substring(0, 3)}
    </StyledAvatar>
  </FlexBox>
);

export default Impersonate;
