import React, { FunctionComponent, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  InvoiceDrawer,
  ColumnFlexBox,
  SidebarHeader,
  TabIcon,
  ContentContainer,
  AddCircleIconCustom,
  CustomDivision,
  FlexBoxTitle,
  StatusContainer,
  FlexBoxProject,
  Tabs,
  Tab,
  AddDivision,
  TabEffect,
  TabName,
  HeaderIcon
} from './styles';

interface ISideBarRight {
  shouldShowHeader?: boolean;
  headerTitle: string;
  headerIcon?: string;
  tabContent?: React.ReactNode;
  tabs?: { title: string; iconColor?: string }[];
  pageCss?: string;
  toggleSidePanel: () => void;
  openProjectDialog?: () => void;
  handleTabChange?: (newValue: number) => void;
  defaultActiveTab?: number;
}

export const SideBarRight: FunctionComponent<ISideBarRight> = props => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [activeTab, setActiveTab] = useState<number | undefined>(0);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }

    const { tabs, handleTabChange, defaultActiveTab } = props;

    if (defaultActiveTab && defaultActiveTab >= 0) {
      setActiveTab(defaultActiveTab);
    } else if (tabs && activeTab !== undefined && activeTab >= tabs.length) {
      const activeTabIndex = tabs.length - 1;
      setActiveTab(activeTabIndex);
      if (handleTabChange) handleTabChange(activeTabIndex);
    }
  }, [props]);

  const {
    shouldShowHeader = true,
    headerTitle,
    toggleSidePanel,
    openProjectDialog,
    tabContent,
    handleTabChange,
    headerIcon,
    tabs,
    pageCss
  } = props;

  return (
    <InvoiceDrawer className={pageCss ? pageCss : ''}>
      <ColumnFlexBox
        className={pageCss ? pageCss : ''}
        data-margin-bottom={openProjectDialog ? '10px' : '-20px'}
      >
        {shouldShowHeader && (
          <FlexBoxTitle>
            <SidebarHeader>
              {headerIcon ? <HeaderIcon color={headerIcon} /> : null}
              {headerTitle}
            </SidebarHeader>
            <CloseIcon onClick={toggleSidePanel} />
          </FlexBoxTitle>
        )}
        <Tabs>
          {tabs
            ? tabs.map((tab, index) => (
                <React.Fragment key={index}>
                  <Tab
                    key={index}
                    onClick={e => {
                      setActiveTab(index);
                      if (handleTabChange) {
                        handleTabChange(index);
                      }
                    }}
                  >
                    {tab.iconColor ? <TabIcon color={tab.iconColor} /> : null}
                    <TabName>{tab.title}</TabName>
                  </Tab>
                  <TabEffect
                    key={`${index}-effect`}
                    className={
                      activeTab === 0 ? 'activeTabEffect' : 'inactiveTabEffect'
                    }
                  />
                </React.Fragment>
              ))
            : null}
        </Tabs>
        <AddDivision>
          {openProjectDialog ? (
            <AddCircleIconCustom onClick={openProjectDialog} />
          ) : null}
          <CustomDivision />
        </AddDivision>
      </ColumnFlexBox>
      <StatusContainer>
        <FlexBoxProject>
          <ContentContainer>{tabContent}</ContentContainer>
        </FlexBoxProject>
      </StatusContainer>
    </InvoiceDrawer>
  );
};

export default SideBarRight;
