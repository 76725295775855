import { Divider, Avatar as MUIAvatar } from '@mui/material';
import styled, { theme } from 'src/theme';
import {
  FlexBoxJustifyStart,
  KeyColumn
} from 'src/components/shared/RightSidebar/RightSidebarInfoPane/styles';

export const PersonnelHeader = styled(KeyColumn)`
  color: rgba(0, 0, 0, 0.6);
  font-family: ${theme.font};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  padding-right: 0;
`;

export const StyledDivider = styled(Divider)`
  && {
    background-color: ${theme.borderColor};
  }
`;

export const Avatar = styled(MUIAvatar)`
  && {
    background-color: #eeeeee;
    margin: 0 5px;
    height: 31px;
    width: 31px;
    font-size: 12px;
    font-weight: 500;
    font-family: ${theme.font};
    border: 1px solid #fff;
    color: rgba(0, 0, 0, 0.87);

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const OverflowAvatar = styled(Avatar)`
  && {
    background-color: ${theme.white};
    color: #00838f;
    border: 1px solid #00838f;
  }
`;

export const TeamListWrapper = styled(FlexBoxJustifyStart)`
  padding: 20px;
`;
