import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'src/hooks/withRouter';
import { OutstandingInvoices } from 'src/components/InvoiceList/InvoiceListSidebar/InformationPane/OutstandingInvoices';
import { IInvoiceDetailView } from 'src/shared/invoice/types';
import { actions as invoiceActions } from 'src/shared/invoice-list/actions';
import { IInvoiceSidebarDetail } from 'src/shared/invoice-list/types';
import styled from 'src/theme';

const StyledContainer = styled(OutstandingInvoices)`
  .invoices {
    padding: 0 !important;
  }
`;

interface IDispatch {
  fetchMoreSidebarInvoices: () => void;
}

interface IProps extends IDispatch {
  invoiceSidebarDetail: IInvoiceSidebarDetail;
  view?: IInvoiceDetailView;
}

function Invoices({
  fetchMoreSidebarInvoices,
  navigate,
  invoiceSidebarDetail,
  view
}: IProps & RouteComponentProps<{ id?: string }>) {
  const invoices = invoiceSidebarDetail.invoices;

  const showMoreInvoices = () => {
    const numInvoices = invoices.length;
    if (invoiceSidebarDetail.totalInvoices > numInvoices) {
      fetchMoreSidebarInvoices();
    }
  };

  const openInvoiceDetail = (id: number) => {
    navigate(`/invoicing/${id}`);
  };

  if (!view) return <React.Fragment></React.Fragment>;

  return (
    <StyledContainer
      invoices={invoices}
      invoice={view}
      totalInvoices={invoiceSidebarDetail.totalInvoices}
      showMoreInvoices={showMoreInvoices}
      openInvoiceDetail={openInvoiceDetail}
    />
  );
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatch => {
  return bindActionCreators(
    {
      fetchMoreSidebarInvoices: invoiceActions.fetchMoreSidebarInvoices
    },
    dispatch
  );
};

export default withRouter(connect(null, mapDispatchToProps)(Invoices));
