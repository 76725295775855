import * as React from 'react';
import ClientEdit from '../../components/ClientEdit';
import { FunctionComponent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { QueryKeys } from 'src/queries/queryKeys';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Api } from 'src/api/api';
import _ from 'lodash';
import { Color } from 'src/constants';

interface IClientEditContainerState {
  errors: any;
  name: string;
  code: string;
  color: string;
  [x: string]: any;
}

export const ClientEditContainer: FunctionComponent = props => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [state, setState] = useState<IClientEditContainerState>({
    code: '',
    color: Color.MEXICAN_PINK,
    errors: {
      code: false,
      name: false
    },
    name: ''
  });
  const queryClient = useQueryClient();

  const { data: selectedClient, isLoading } = useQuery(
    QueryKeys.client(id),
    () => {
      if (!id) return null;
      return Api.fetchClients({ id }).then(_.first);
    },
    {
      onSuccess: selected => {
        if (!selected) return;

        setState({
          ...state,
          code: selected.code || '',
          color: selected.color || Color.MEXICAN_PINK,
          name: selected.name || '',
          qbCustomerId: selected.qbCustomerId
        });
      }
    }
  );

  const { mutate: updateClient } = useMutation(Api.updateClient, {
    onSuccess: updatedClient => {
      queryClient.invalidateQueries(QueryKeys.clients);
      queryClient.setQueryData(QueryKeys.client(id), updatedClient);
      navigate(`/client-management/${id}`, { replace: true });
    }
  });

  const values = () => {
    const { code, color, name, qbCustomerId } = state;

    return {
      code,
      color,
      name,
      id: Number(id),
      qbCustomerId
    };
  };

  const errors = () => {
    const { code, color, name } = state.errors;

    return {
      code,
      color,
      name
    };
  };

  const submitIsValid = () => {
    const { code, color, name } = state;
    const errors = {
      code: code === '',
      color: color === '',
      name: name === ''
    };

    setState({ ...state, errors });

    return !(errors.code || errors.name || errors.color);
  };

  const onFormChange = (prop: string) => (event: any) => {
    const newState = {
      ...state,
      [prop]: event.target.value
    };
    setState(newState);
  };

  const onSave = () => {
    if (submitIsValid()) {
      updateClient({
        id: Number(id),
        code: state.code,
        color: state.color,
        isActive: selectedClient?.isActive,
        name: state.name
      });
    }
  };

  const onCancel = () => {
    navigate(`/client-management/${id}`, { replace: true });
  };

  return (
    <ClientEdit
      loading={isLoading}
      errors={errors()}
      onSave={onSave}
      onCancel={onCancel}
      onChange={onFormChange}
      values={values()}
    />
  );
};

export default ClientEditContainer;
