import React, { FunctionComponent } from 'react';
import { toPercentage, formatDatePeriod } from 'src/shared/utils';
import _ from 'lodash';
import { ColumnFlexBox } from 'src/components/shared/RightSidebar/RightSidebarInfoPane/styles';
import {
  SectionHeader,
  TableRow,
  ColumnFlexBoxWithTopPaddedBorder,
  NoPaddingValue,
  HighlightedValue,
  HeaderValue,
  SeeMore
} from './styles';
import { Timesheet } from 'src/api/types';

interface IEmployeeTimeheetsPane {
  timesheets: Timesheet[];
  showMoreTimesheets: () => void;
  totalTimesheets: number;
  onClickTimesheet?: (timesheetId: number) => void;
}

export const EmployeeTimesheetsPane: FunctionComponent<IEmployeeTimeheetsPane> = props => {
  const {
    timesheets,
    showMoreTimesheets,
    totalTimesheets,
    onClickTimesheet
  } = props;

  const timesheetPeriod = (timesheet: Timesheet) =>
    formatDatePeriod(timesheet.startDate, timesheet.endDate, true);

  return (
    <React.Fragment>
      <SectionHeader>All Timesheets</SectionHeader>
      <ColumnFlexBox>
        <TableRow className="highlighted">
          <HeaderValue>Period</HeaderValue>
          <HeaderValue>Utilization %</HeaderValue>
        </TableRow>
      </ColumnFlexBox>
      <ColumnFlexBoxWithTopPaddedBorder>
        {_.map(timesheets, timesheet => (
          <TableRow key={timesheet.id}>
            {onClickTimesheet && (
              <HighlightedValue onClick={() => onClickTimesheet(timesheet.id)}>
                {timesheetPeriod(timesheet)}
              </HighlightedValue>
            )}
            {!onClickTimesheet && (
              <NoPaddingValue>{timesheetPeriod(timesheet)}</NoPaddingValue>
            )}
            <NoPaddingValue>
              {toPercentage(timesheet.totalUtilization)}
            </NoPaddingValue>
          </TableRow>
        ))}
        {totalTimesheets > timesheets.length && (
          <SeeMore onClick={showMoreTimesheets}>See more</SeeMore>
        )}
      </ColumnFlexBoxWithTopPaddedBorder>
    </React.Fragment>
  );
};

export default EmployeeTimesheetsPane;
