import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Paginator from '../Paginator';
import { IPaginatedComponentProps, IPaginatedComponentState } from './types';

const withPagination = (WrappedComponent: any, itemsSetName: string) => {
  return (props: IPaginatedComponentProps) => {
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [state, setState] = useState<IPaginatedComponentState>({
      paginatedItems: props.items.slice(0, props.perPage),
      page: 1
    });

    useEffect(() => {
      if (isFirstRender) {
        setIsFirstRender(false);
        return;
      }
    }, []);

    useEffect(() => {
      let items;
      if (props.updatePage) {
        items = props.items.slice(0, perPage());
      } else {
        const offset = (state.page - 1) * perPage();
        items = props.items.slice(offset, offset + perPage());
      }
      setState({
        ...state,
        paginatedItems: items
      });
    }, [props.items]);

    const getWrappedComponentProps = () => ({
      goToDetail: props.goToDetail,
      onAddClick: props.onAddClick,
      selectedItem: props.selectedItem,
      changeMetric: props.changeMetric,
      loading: props.loading,
      [itemsSetName]: state.paginatedItems
    });

    const getPaginatedProps = () => {
      return {
        total: props.totalItems,
        onPageUpdate: updatePage,
        perPage: perPage(),
        page: props.page
      };
    };

    const perPage = () => {
      return props.perPage || 15;
    };

    const updatePage = (page: number) => {
      if (props.updatePage) {
        props.updatePage(page, perPage());
        setState({ ...state, page });
      } else {
        const start = (page - 1) * perPage();
        const end = start + perPage();
        const paginatedItems = props.items.slice(start, end);
        setState({ page, paginatedItems });
      }
    };

    return (
      <>
        <WrappedComponent {...getWrappedComponentProps()} />
        <Paginator {...getPaginatedProps()} />
      </>
    );
  };
};

export default withPagination;
