import { ActionsUnion, createAction } from 'src/shared/types';
import { IInvoiceEmail } from './types';

export const CREATE_INVOICE_EMAIL = 'CREATE_INVOICE_EMAIL';
export const CREATE_INVOICE_EMAIL_SUCCEDED = 'CREATE_INVOICE_EMAIL_SUCCEDED';
export const DELETE_INVOICE_EMAIL = 'DELETE_INVOICE_EMAIL';

const createInvoiceEmail = (
  projectId: number,
  invoiceEmailData: IInvoiceEmail,
  callback: (response: any) => void
) =>
  createAction(CREATE_INVOICE_EMAIL, {
    callback,
    invoiceEmailData,
    projectId
  });

const createInvoiceEmailSucceded = () =>
  createAction(CREATE_INVOICE_EMAIL_SUCCEDED, {});

const deleteInvoiceEmail = (
  id: any,
  projectId: number,
  callback?: (deletedId: any) => any
) => createAction(DELETE_INVOICE_EMAIL, { id, projectId, callback });

export const actions = {
  createInvoiceEmail,
  createInvoiceEmailSucceded,
  deleteInvoiceEmail
};

export type Actions = ActionsUnion<typeof actions>;
