import React from 'react';
import { CircularProgress } from '@mui/material';
import { CenteredContainer } from './styles';

export const LoadingPage = () => (
  <CenteredContainer>
    <CircularProgress />
  </CenteredContainer>
);

export default LoadingPage;
