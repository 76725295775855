import * as React from 'react';
import { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Login from 'src/components/Login';
import messages from 'src/messages';
import { IAppState } from 'src/reducer';
import { toast } from 'src/services/toast';
import * as authActions from 'src/shared/auth/actions';

interface ILoginReduxDispatch {
  login: (code: string) => void;
}

export const LoginContainer: FunctionComponent<ILoginReduxDispatch> = props => {
  const onLoginSuccess = (event: any) => {
    props.login(event.code);
  };

  const onLoginError = (_event: any) => {
    toast(messages.ERROR_LOGIN, 'error');
  };

  return (
    <Login
      onSuccess={onLoginSuccess}
      onError={onLoginError}
      version={process.env.VERSION}
      impersonate={Boolean(process.env.REACT_APP_BACKDOOR) || false}
    />
  );
};

const mapStateToProps = (state: IAppState) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch): ILoginReduxDispatch => {
  return bindActionCreators(
    {
      login: authActions.actions.login
    },
    dispatch
  );
};

export default connect<{}, ILoginReduxDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
