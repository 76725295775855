import React, { FunctionComponent, useState } from 'react';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Paper, { PaperProps } from '@mui/material/Paper';
import Close from '@mui/icons-material/Close';
import Create from '@mui/icons-material/Create';
import Draggable from 'react-draggable';
import TimesheetEntryPane, {
  isTimesheetEntry
} from 'src/components/InvoiceDetail/InvoiceSidebar/InvoiceDetailTabPane/TimesheetEntryPane';
import { EditDeliverableRoleModal } from '../../EditDeliverableRoleModal';
import {
  Title,
  SubTitleCompartment,
  SubTitleFullBorderCompartment,
  DialogSubTitle,
  CompartmentTitle,
  CompartmentValue,
  CompartmentOriginalValue,
  ColumnFlexBox,
  DialogHeader,
  DialogTitle,
  FlexBox,
  CancelButton,
  SaveButton,
  FullBorderLeftFlexBox,
  PaddedFlexBox,
  StyledDialogContent,
  ViewSpan,
  HourViewButton,
  ViewBar,
  EditableCompartment
} from './styles';
import {
  IInvoiceDetailView,
  ITimesheetRow,
  ITimesheetEntryWithRate
} from 'src/shared/invoice/types';
import { Avatar } from 'src/components/Avatar';
import { formatAsCurrency, createDaysArray } from 'src/shared/utils';
import ConfirmCloseDialogModal from './ConfirmCloseDialogModal';
import MoreVertMenu from './MoreVertMenu';
import { sumInvoiceTotal, isNonClient } from 'src/shared/invoice/utils';
import { Color } from 'src/constants';
import { Deliverable, ProjectRole, TimesheetEntry } from 'src/api/types';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title2"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

interface IInvoiceEntryDialog {
  amount: string | number;
  canEdit: boolean;
  selectedItem: number;
  view: IInvoiceDetailView;
  closeTimesheetDialog: () => void;
  saveInvoice: ({ view }: { view: IInvoiceDetailView }) => void;
}

interface IInvoiceEntryDialogState {
  view: IInvoiceDetailView;
  fullTimesheetView: boolean;
  confirmCloseModal: boolean;
  isDeliverableModalOpen: boolean;
  isRoleModalOpen: boolean;
}

export const InvoiceEntryDialog: FunctionComponent<IInvoiceEntryDialog> = props => {
  const [state, setState] = useState<IInvoiceEntryDialogState>({
    view: _.cloneDeep(props.view),
    fullTimesheetView: false,
    confirmCloseModal: false,
    isDeliverableModalOpen: false,
    isRoleModalOpen: false
  });

  const getItemFromProvidedView = (view: IInvoiceDetailView) => {
    const { selectedItem } = props;

    const item = view.userRows[selectedItem];
    const deliverable: Deliverable | undefined = _.find(
      view.deliverables,
      ({ id }: Deliverable) => _.eq(id, item.deliverableId)
    );
    const role = _.find(view.projectRoles, ({ id }: ProjectRole) =>
      _.eq(id, item.projectRoleId)
    );
    return {
      item,
      deliverable,
      role
    };
  };

  const deliverableInvoiceHours = (local?: boolean) => {
    const { view } = local ? state : props;
    const item = getItemFromProvidedView(view);
    const { deliverable } = item;
    if (deliverable) {
      return _(view.userRows)
        .map(({ hourList }: { hourList: TimesheetEntry[] }) => hourList)
        .flatten()
        .reduce(
          (sum: number, entry: TimesheetEntry) =>
            entry.deliverableId === deliverable.id && !entry.dismissed
              ? sum + entry.invoicingHours
              : sum,
          0
        );
    }
    return 0;
  };

  const deliverableInvoiceAmount = (local?: boolean) => {
    const { view } = local ? state : props;
    const { deliverable, role } = getItemFromProvidedView(view);
    if (deliverable && role) {
      return _(view.userRows)
        .map(
          ({ hourList, rate }: { hourList: TimesheetEntry[]; rate: number }) =>
            hourList.map((e: TimesheetEntry) => ({ ...e, rate })) //include rate in timesheet entry here
        )
        .flatten()
        .reduce(
          (sum: number, entry: ITimesheetEntryWithRate) =>
            entry.deliverableId === deliverable.id && !entry.dismissed
              ? sum + entry.invoicingHours * entry.rate
              : sum,
          0
        );
    }
    return 0;
  };

  const getDeliverable = (local?: boolean) => {
    const { view } = local ? state : props;
    const { item } = getItemFromProvidedView(view);

    const deliverable: Deliverable | undefined = _.find(
      view.deliverables,
      ({ id }: Deliverable) => _.eq(id, item.deliverableId)
    );

    return deliverable;
  };

  const getProjectRole = (local?: boolean) => {
    const { view } = local ? state : props;
    const { item } = getItemFromProvidedView(view);

    const role = _.find(view.projectRoles, ({ id }: ProjectRole) =>
      _.eq(id, item.projectRoleId)
    );

    return role;
  };

  const renderTimesheetEntries = () => {
    const { view, fullTimesheetView } = state;
    const {
      view: { timesheetDialogEntries }
    } = props;
    const { item, role, deliverable } = getItemFromProvidedView(view);
    if (!item || !view) return null;
    const hourList = fullTimesheetView
      ? timesheetDialogEntries?.map((entry: TimesheetEntry) => {
          const modifiedEntry = _.find(
            item.hourList,
            (hourItem: TimesheetEntry) => hourItem.id === entry.id
          );
          if (modifiedEntry) {
            return {
              ...modifiedEntry,
              project: entry.project,
              associatedToInvoice: !entry.nonBill,
              deliverable: entry.deliverable,
              role: entry.role
            };
          }
          return { ...entry, project: entry.project };
        })
      : item.hourList;
    const dateList = createDaysArray(view.startDate, view.endDate);
    return _.map(dateList, (date: Moment) => {
      let entries: any[] = [];
      const clientColorList: string[] = [];
      _.each(hourList, (item: TimesheetEntry) => {
        if (date.isSame(moment(item.date), 'day')) {
          entries.push(item);
          const color = isNonClient(item)
            ? Color.YELLOW_GREEN
            : item.project?.client.color;
          if (color && clientColorList.indexOf(color) < 0) {
            clientColorList.push(color);
          }
        }
      });
      if (!entries.length) {
        entries = [
          {
            date: date.format('MM/DD/YYYY'),
            modified: false,
            invoicingHours: 0,
            nonBill: 0,
            actual: 0,
            invoicingDescription: '',
            description: '',
            isEmpty: true
          }
        ];
      }
      return (
        <TimesheetEntryPane
          date={date}
          key={_.head(entries).date}
          hourItems={entries.sort(
            ({
              projectRoleId,
              deliverableId,
              nonBill
            }: {
              projectRoleId: number;
              deliverableId: number;
              nonBill: boolean;
            }) =>
              !nonBill &&
              role &&
              projectRoleId === role.id &&
              deliverable &&
              deliverableId === deliverable.id
                ? -1
                : 1
          )}
          role={role}
          modifyItemValue={modifyItemValue}
          view={view}
          onAcceptChanges={onAcceptChanges}
          onCancelChanges={onCancelChanges}
          dismissItem={dismissItem}
          immediateChanges
          isFullTimesheetDialog={fullTimesheetView}
          clientColorList={clientColorList}
          resetItem={resetItem}
          canEdit={props.canEdit}
        />
      );
    });
  };

  const modifyItemValue = ({
    timesheetEntryId,
    dataKey,
    value
  }: {
    timesheetEntryId: number;
    dataKey: string;
    value: string | number | boolean;
  }) => {
    const { view } = state;
    const { selectedItem } = props;

    const { item } = getItemFromProvidedView(view);

    const { hourList } = item;
    if (hourList) {
      let newData = _.cloneDeep(hourList);
      const editItem = _.find(newData, {
        id: timesheetEntryId
      });
      if (editItem && isTimesheetEntry(editItem)) {
        editItem[dataKey] = value;
        editItem.edited = true;
        newData = _.map(newData, row =>
          row.id === timesheetEntryId ? editItem : row
        );
        view.userRows[selectedItem] = {
          ...view.userRows[selectedItem],
          hourList: newData,
          modified: true
        };
        setState({ ...state, view });
      }
    }
  };

  const calculateAmounts = () => {
    const { view } = state;
    return sumInvoiceTotal(view);
  };

  const onCancelChanges = () => {
    return Function.prototype;
  };

  const onAcceptChanges = () => {
    return Function.prototype;
  };

  const dismissItem = (item: ITimesheetRow, forceValue?: boolean) => {
    const timesheetEntry = _.head(item.hourList);
    if (timesheetEntry) {
      const timesheetEntryId = timesheetEntry.id;
      const value =
        forceValue !== undefined ? forceValue : !timesheetEntry.dismissed;
      modifyItemValue({
        timesheetEntryId,
        dataKey: 'dismissed',
        value
      });
      if (!value) {
        modifyItemValue({
          timesheetEntryId,
          dataKey: 'invoicingHours',
          value: !timesheetEntry.nonBill ? timesheetEntry.hours : 0
        });
      }
    }
  };

  const onClickClose = () => {
    if (_.some(state.view.userRows, 'modified')) {
      setState({ ...state, confirmCloseModal: true });
    } else {
      props.closeTimesheetDialog();
    }
  };

  const onClickSave = () => {
    props.saveInvoice({ view: state.view });
    props.closeTimesheetDialog();
  };

  const dismissAllItems = (forceValue: boolean) => {
    const { view } = state;
    const { item } = getItemFromProvidedView(view);

    _.each(item.hourList, (entry: TimesheetEntry) =>
      dismissItem({ ...item, hourList: [entry] }, forceValue)
    );
  };

  const resetAllItems = () => {
    const { view } = state;
    const { item } = getItemFromProvidedView(view);

    _.each(item.hourList, (entry: TimesheetEntry) => {
      resetItem(entry.id);
    });
  };

  const resetItem = (timesheetEntryId: number) => {
    const { view } = state;
    const { selectedItem } = props;

    const { item } = getItemFromProvidedView(view);
    const { hourList } = item;
    if (hourList) {
      const editItem = _.find(hourList, {
        id: timesheetEntryId
      });
      if (editItem) {
        const { item: originalItem } = getItemFromProvidedView(props.view);
        const { hourList: originalHourList } = originalItem;
        const originalEditItem = _.find(originalHourList, {
          id: timesheetEntryId
        });
        if (originalEditItem) {
          const newHourList = _.map(hourList, row =>
            row.id === timesheetEntryId ? originalEditItem : row
          );
          view.userRows[selectedItem] = {
            ...view.userRows[selectedItem],
            hourList: newHourList,
            modified: true
          };
        }
      }
    }
    setState({ ...state, view });
  };

  const findConflicts = () => {
    const { view: originalView } = props;
    const { item: changedViewItem } = getItemFromProvidedView(state.view);

    const deliverable = getDeliverable();
    const role = getProjectRole();

    let conflicts: {
      deliverableId: number;
      projectRoleId: number;
    }[] = [];
    if (changedViewItem && deliverable && role) {
      if (
        _.some(originalView.userRows, ({ id }) => id === changedViewItem.id)
      ) {
        const conflictItems = _.filter(
          originalView.userRows,
          ({ id }) =>
            id === changedViewItem.id &&
            (deliverable.id === changedViewItem.deliverableId ||
              role.id === changedViewItem.projectRoleId)
        );
        conflicts = _.map(
          conflictItems,
          ({ deliverableId, projectRoleId }) => ({
            deliverableId,
            projectRoleId
          })
        );
      }
    }
    return conflicts.filter(
      ({ deliverableId, projectRoleId }) =>
        !(deliverableId === deliverable?.id && projectRoleId === role?.id)
    );
  };

  const closeDeliverableModal = () => {
    setState({ ...state, isDeliverableModalOpen: false });
  };

  const setDeliverable = (id: number) => {
    const { isDeliverableModalOpen } = state;
    if (isDeliverableModalOpen) {
      saveTimesheetRow('deliverableId', id);
    }

    closeDeliverableModal();
  };

  const closeRoleModal = () => {
    setState({ ...state, isRoleModalOpen: false });
  };

  const setRole = (id: number) => {
    const { isRoleModalOpen } = state;
    if (isRoleModalOpen) {
      saveTimesheetRow('projectRoleId', id);
    }

    closeRoleModal();
  };

  const saveTimesheetRow = (attr: string, value: number) => {
    const { selectedItem } = props;
    const { view } = state;
    if (view) {
      const { userRows: data } = view;
      if (data) {
        const newData = _.cloneDeep(data);
        const newUserRows = newData[selectedItem];
        if (newUserRows) {
          newUserRows[attr] = value;
          newUserRows.modified = true;
          view.userRows = newData;
          setState({ ...state, view });
        }
      }
    }
  };

  const onEditDeliverable = () => {
    if (props.canEdit) {
      setState({ ...state, isDeliverableModalOpen: true });
    }
  };

  const onEditRole = () => {
    if (props.canEdit) {
      setState({ ...state, isRoleModalOpen: true });
    }
  };

  const {
    confirmCloseModal,
    fullTimesheetView,
    isDeliverableModalOpen,
    isRoleModalOpen
  } = state;

  const { amount, canEdit, view } = props;
  const { item } = getItemFromProvidedView(state.view);

  const deliverable = getDeliverable(true);
  const role = getProjectRole(true);

  if (!role) return null;

  return (
    <React.Fragment>
      {isDeliverableModalOpen && deliverable && role ? (
        <EditDeliverableRoleModal
          list={view.deliverables}
          onSave={val => setDeliverable(val)}
          onCancel={() => closeDeliverableModal()}
          current={deliverable.id}
          currentOther={role.id}
          title="Deliverable"
          projectNickname={view.projectNickname}
          conflicts={findConflicts()}
        />
      ) : null}
      {isRoleModalOpen && deliverable && role ? (
        <EditDeliverableRoleModal
          list={view.projectRoles}
          onSave={val => setRole(val)}
          onCancel={() => closeRoleModal()}
          current={role.id}
          currentOther={deliverable.id}
          title="Role"
          projectNickname={view.projectNickname}
          conflicts={findConflicts()}
        />
      ) : null}
      <div>
        {confirmCloseModal && (
          <ConfirmCloseDialogModal
            onCancel={() => setState({ ...state, confirmCloseModal: false })}
            onLeave={() => props.closeTimesheetDialog()}
          />
        )}
        <Dialog
          open
          PaperComponent={PaperComponent}
          maxWidth="md"
          fullWidth
          componentsProps={{
            backdrop: {
              style: {
                backgroundColor: 'rgba(0, 0, 0, 0.3)'
              }
            }
          }}
        >
          <DialogHeader
            role="div"
            style={{ cursor: 'move' }}
            id="draggable-dialog-title"
          >
            <DialogTitle>
              <PaddedFlexBox>
                <Avatar name={item.fullName} />
                <Title>{item.fullName}</Title>
              </PaddedFlexBox>
              <FlexBox>
                <PaddedFlexBox>
                  {canEdit && (
                    <SaveButton
                      onClick={onClickSave}
                      disabled={!_.some(state.view.userRows, 'modified')}
                    >
                      Save
                    </SaveButton>
                  )}
                  <CancelButton onClick={onClickClose}>
                    {canEdit ? 'Cancel' : 'Close'}
                  </CancelButton>
                  {canEdit && (
                    <MoreVertMenu
                      showDismiss={!_.every(item.hourList, 'dismissed')}
                      dismissItems={dismissAllItems}
                      resetAllItems={resetAllItems}
                    />
                  )}
                </PaddedFlexBox>
                <FullBorderLeftFlexBox>
                  <Close onClick={onClickClose} style={{ cursor: 'pointer' }} />
                </FullBorderLeftFlexBox>
              </FlexBox>
            </DialogTitle>
            <DialogSubTitle>
              <FlexBox>
                <SubTitleFullBorderCompartment>
                  <CompartmentTitle>Total Invoice Amt</CompartmentTitle>
                  <CompartmentValue>
                    {formatAsCurrency(calculateAmounts())}
                  </CompartmentValue>
                  <CompartmentOriginalValue>
                    {formatAsCurrency(amount)}
                  </CompartmentOriginalValue>
                </SubTitleFullBorderCompartment>
                <SubTitleCompartment>
                  <CompartmentTitle>Deliverable Invoice Hr</CompartmentTitle>
                  <CompartmentValue>
                    {deliverableInvoiceHours(true)}
                  </CompartmentValue>
                  <CompartmentOriginalValue>
                    {deliverableInvoiceHours()}
                  </CompartmentOriginalValue>
                </SubTitleCompartment>
                <SubTitleCompartment>
                  <CompartmentTitle>Deliverable Invoice Amt</CompartmentTitle>
                  <CompartmentValue>
                    {formatAsCurrency(deliverableInvoiceAmount(true))}
                  </CompartmentValue>
                  <CompartmentOriginalValue>
                    {formatAsCurrency(deliverableInvoiceAmount())}
                  </CompartmentOriginalValue>
                </SubTitleCompartment>
                <SubTitleCompartment>
                  <CompartmentTitle>Deliverable</CompartmentTitle>
                  <EditableCompartment>
                    <div>
                      <CompartmentValue>{deliverable?.name}</CompartmentValue>
                      <CompartmentOriginalValue>
                        {getDeliverable()?.name}
                      </CompartmentOriginalValue>
                    </div>
                    {canEdit && (
                      <IconButton
                        onClick={onEditDeliverable}
                        id="deliverableId"
                      >
                        <Create />
                      </IconButton>
                    )}
                  </EditableCompartment>
                </SubTitleCompartment>
                <SubTitleCompartment>
                  <CompartmentTitle>Role</CompartmentTitle>
                  <EditableCompartment>
                    <div>
                      <CompartmentValue>{role?.name}</CompartmentValue>
                      <CompartmentOriginalValue>
                        {formatAsCurrency(role?.rate || 0)}
                      </CompartmentOriginalValue>
                    </div>
                    {canEdit && (
                      <IconButton onClick={onEditRole} id="roleId">
                        <Create />
                      </IconButton>
                    )}
                  </EditableCompartment>
                </SubTitleCompartment>
              </FlexBox>
            </DialogSubTitle>
          </DialogHeader>
          <ViewBar>
            <ViewSpan>View: </ViewSpan>
            <HourViewButton
              onClick={() => setState({ ...state, fullTimesheetView: true })}
              className={fullTimesheetView ? 'highlighted' : ''}
            >
              All hours
            </HourViewButton>
            <HourViewButton
              onClick={() => setState({ ...state, fullTimesheetView: false })}
              className={fullTimesheetView ? '' : 'highlighted'}
            >
              Invoice hours
            </HourViewButton>
          </ViewBar>
          <StyledDialogContent>
            <ColumnFlexBox>{renderTimesheetEntries()}</ColumnFlexBox>
          </StyledDialogContent>
        </Dialog>
      </div>
    </React.Fragment>
  );
};

export default InvoiceEntryDialog;
