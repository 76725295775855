import EnableIcon from '@mui/icons-material/Check';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';
import { CustomFab, CustomGrid } from './styles';
import { useNavigate } from 'react-router-dom';

interface IUserDetailActions {
  disabled: boolean;
  openDialog: () => void;
  userId: number;
  userStatus: boolean;
}

const UserDetailActions: React.FC<IUserDetailActions> = ({
  disabled = true,
  openDialog,
  userId,
  userStatus = true
}) => {
  const navigate = useNavigate();

  return (
    <CustomGrid direction="row" justifyContent="center" container={true}>
      <CustomFab
        onClick={() => navigate(`/team-management/${userId}/edit`)}
        color="primary"
        disabled={disabled}
        aria-label="Edit"
      >
        <Tooltip title="Edit User">
          <EditIcon />
        </Tooltip>
      </CustomFab>
      <CustomFab
        onClick={openDialog}
        color="secondary"
        disabled={disabled}
        aria-label="Delete"
      >
        {userStatus ? (
          <Tooltip title="Disable User">
            <HighlightOffIcon fontSize="large" />
          </Tooltip>
        ) : (
          <Tooltip title="Enable User">
            <EnableIcon />
          </Tooltip>
        )}
      </CustomFab>
    </CustomGrid>
  );
};

export default UserDetailActions;
