import { createTheme } from '@mui/material/styles';
import { Color } from 'src/constants';
import * as styledComponents from 'styled-components';

const mainPaletteColors = {
  RED: '#FF0000',
  DARK_GREY: '#2B4E56',
  WHITE: '#FFF',
  BLACK: '#000',
  YELLOW: '#FDB833'
};

const themeMaterial = createTheme({
  typography: {
    allVariants: {
      fontSize: 'inherit',
      fontFamily: 'inherit'
    }
  },
  palette: {
    error: {
      main: mainPaletteColors.RED
    },
    primary: {
      main: '#00838F',
      light: '#4fb3bf',
      dark: '#005662',
      contrastText: '#ffffff'
    },
    secondary: {
      main: mainPaletteColors.BLACK
    }
  }
});

const theme = {
  breakpointsSelf: {
    xl: '1920px',
    lg: '1280px',
    md: '960px',
    sm: '600px',
    xs: '0px'
  },
  drawerWidth: '240px',
  drawerWidthLeft: '242px',
  font: '"Source Sans Pro", sans-serif !important',
  googleBlue: '#4285F4',
  green: Color.YELLOW_GREEN,
  primaryBackground: '#eaebec',
  primaryBackgroundInverted: mainPaletteColors.DARK_GREY,
  primaryColor: '#000',
  primaryColorInverted: '#f7f7f9',
  red: mainPaletteColors.RED,
  white: mainPaletteColors.WHITE,
  black: mainPaletteColors.BLACK,
  yellow: mainPaletteColors.YELLOW,
  teal: '#00838F',
  tealAlt: '#4ca8b0',
  tealLight: '#DDE9EA',
  tealDark: '#005662',
  darkGray: '#212121',
  secondaryBackground: 'white',
  secondaryColor: '#c2c2c2',
  secondaryColorInverted: '#68797E',
  separatorBackground: '#F4F4F4',
  smallDrawer: '0px',
  borderColor: '#D4D4D4',
  border: '1px solid #D4D4D4',
  borderRadius: {
    sm: '0.25rem',
    md: '0.5rem',
    lg: '0.75rem'
  },
  button: {
    primaryColor: '#00838f',
    lightenedPrimaryColor: 'rgba(0, 131, 143, 0.7)',
    primaryHoverColor: '#005662',
    primaryDisabledColor: 'rgba(0,0,0,0.18)',
    primaryDisabledTextColor: '#757575'
  },
  ...themeMaterial
};

const { default: styled, css, createGlobalStyle, keyframes } = styledComponents;

export { css, createGlobalStyle, keyframes, theme };
export default styled;
