import styled from '../../theme';

export const Container = styled.div`
  display: flex;
  flex: 1;
  height: 70%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 500px;
`;

export const GoogleSignInButton = styled.div`
  background-color: ${props => props.theme.googleBlue};
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 50px;
  border-radius: 3px;
  border: none;
  color: ${props => props.theme.primaryColorInverted};
  align-items: center;
  & span {
    font-size: 16px;
    display: flex;
    flex: 1;
    justify-content: center;
  }
  & :hover {
    opacity: 0.6;
  }
  & :active {
    opacity: 0.6;
  }
`;

export const GoogleImage = styled.img`
  width: 33px;
  height: 33px;
  padding: 0 10px 0 10px;
  box-sizing: content-box;
`;

export const VersionNumber = styled.span`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;
