import React from 'react';
import { Column } from 'react-virtualized';
import { IUserListProps } from '../../shared/users/types';
import CustomTable from '../CustomTable/index';
import { Disabled, Enabled } from './styles';

const UserListRefactor = (props: IUserListProps) => {
  const getColumns = (): JSX.Element[] => {
    const baseProps = {
      className: 'column',
      width: 100
    };
    const columnsDefinition = [
      {
        dataKey: 'fullName',
        flexGrow: 2,
        key: 'fullName',
        label: 'Name'
      },
      {
        dataKey: 'title',
        flexGrow: 1,
        key: 'title',
        label: 'Title'
      },
      {
        dataKey: 'managerFullName',
        flexGrow: 2,
        key: 'managerFullName',
        label: 'Manager'
      },
      {
        cellRenderer: ({ rowData: { isActive } }: any): JSX.Element =>
          isActive ? <Enabled /> : <Disabled />,
        dataKey: 'status',
        flexGrow: 0.1,
        key: 'status',
        label: 'Status',
        style: { position: 'relative' as const, left: '2%' }
      }
    ];

    return columnsDefinition.map(col => <Column {...col} {...baseProps} />);
  };

  return (
    <CustomTable data={props.users} showFilter={false} {...props}>
      {getColumns()}
    </CustomTable>
  );
};

export default UserListRefactor;
