import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'src/hooks/withRouter';
import { bindActionCreators, Dispatch } from 'redux';
import { IAppState } from 'src/reducer';
import * as usersActions from 'src/shared/users/actions';
import {
  IEntityStateRoles,
  IEntityStateUsers,
  UserClassification
} from 'src/shared/users/types';
import UserAdd from '../../components/UserAdd';
import Validations from '../../validations';
import { FunctionComponent, useEffect, useState } from 'react';
import moment from 'moment';
import { MUI_TEXT_FIELD_DATE_FORMAT } from 'src/constants';
import { useQuery } from 'react-query';
import { QueryKeys } from 'src/queries/queryKeys';
import { Api } from 'src/api/api';

type IUserAddContainerProps = RouterProps &
  IUserAddContainerReduxProps &
  IUserAddContainerDispatch;

type RouterProps = RouteComponentProps<{ id?: string }>;

interface IUserAddContainerDispatch {
  addUser: (callback: () => void, userData: any) => void;
  getManagers: () => void;
}

interface IUserAddContainerReduxProps {
  roles: IEntityStateRoles;
  managers: IEntityStateUsers;
  loading: boolean;
}

interface IUserAddContainerState {
  departmentIds: number[];
  errors: any;
  firstName: string;
  lastName: string;
  startDate: string;
  email: string;
  slackName: string;
  manager: string | number;
  notificationPreference: any;
  title: string;
  roles: any;
  employmentType: UserClassification;
  [x: string]: any;
}

export const UserAddContainer: FunctionComponent<IUserAddContainerProps> = props => {
  const [state, setState] = useState<IUserAddContainerState>({
    departmentIds: [],
    email: '',
    employmentType: UserClassification.SALARIED,
    errors: {
      email: false,
      employmentType: false,
      firstName: false,
      lastName: false,
      manager: false,
      notificationPreference: false,
      roles: false,
      slackName: false,
      startDate: false,
      title: false
    },
    firstName: '',
    lastName: '',
    manager: '',
    notificationPreference: [],
    roles: [],
    slackName: '',
    startDate: moment().format(MUI_TEXT_FIELD_DATE_FORMAT),
    title: ''
  });

  const { data: departments = [] } = useQuery(
    QueryKeys.departments,
    Api.fetchDepartments
  );

  useEffect(() => {
    props.getManagers();
  }, []);

  const values = () => {
    const {
      departmentIds,
      email,
      employmentType,
      firstName,
      lastName,
      manager,
      notificationPreference,
      roles,
      slackName,
      startDate,
      title
    } = state;

    return {
      departmentIds,
      email,
      employmentType,
      firstName,
      lastName,
      manager,
      notificationPreference,
      roles,
      slackName,
      startDate,
      title
    };
  };

  const errors = () => {
    const {
      firstName,
      lastName,
      startDate,
      email,
      slackName,
      notificationPreference,
      title,
      manager,
      roles,
      employmentType
    } = state.errors;

    return {
      email,
      employmentType,
      firstName,
      lastName,
      manager,
      notificationPreference,
      roles,
      slackName,
      startDate,
      title
    };
  };

  const submitIsValid = () => {
    const {
      firstName,
      lastName,
      email,
      title,
      manager,
      employmentType
    } = state;
    const errors = {
      email: !Validations.VALID_EMAIL_REGEX.test(email),
      employmentType: employmentType === null,
      firstName: !Validations.VALID_NAME_REGEX.test(firstName),
      lastName: !Validations.VALID_NAME_REGEX.test(lastName),
      manager: manager === '',
      notificationPreference: false,
      startDate: false,
      title: !Validations.VALID_TITLE_REGEX.test(title)
    };

    setState({
      ...state,
      errors
    });

    return !(
      errors.firstName ||
      errors.lastName ||
      errors.title ||
      errors.manager ||
      errors.employmentType
    );
  };

  const onFormChange = (prop: string) => (event: any) => {
    const newState = {
      ...state,
      [prop]: event.target ? event.target.value : event
    };
    setState(newState);
  };

  const onSaveComplete = () => {
    props.navigate('/team-management', { replace: true });
  };

  const onSave = () => {
    if (submitIsValid()) {
      const result = {
        classification: state.employmentType,
        departmentIds: state.departmentIds,
        email: state.email,
        firstName: state.firstName,
        isActive: true,
        lastName: state.lastName,
        managerId: state.manager,
        notificationPreferences: state.notificationPreference.map((cur: any) =>
          cur.toLowerCase()
        ),
        roleIds: state.roles,
        slackDisplayName: state.slackName,
        startDate: state.startDate,
        title: state.title
      };
      props.addUser(onSaveComplete, result);
    }
  };

  const onCancel = () => {
    props.navigate('/team-management', { replace: true });
  };

  return (
    <UserAdd
      departments={departments}
      errors={errors()}
      loading={props.loading}
      managers={props.managers}
      onCancel={onCancel}
      onChange={onFormChange}
      onSave={onSave}
      roles={props.roles}
      values={values()}
    />
  );
};

export const mapStateToProps = (state: IAppState) => {
  const managers = state.entities.managers;
  const roles = state.entities.roles;
  const loading = state.entities.loadingUserAdd;
  return {
    loading,
    managers,
    roles
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IUserAddContainerDispatch => {
  return bindActionCreators(
    {
      addUser: usersActions.actions.addUser,
      getManagers: usersActions.actions.getManagers
    },
    dispatch
  );
};

export default withRouter(
  connect<IUserAddContainerReduxProps, IUserAddContainerDispatch>(
    mapStateToProps,
    mapDispatchToProps
  )(UserAddContainer)
);
