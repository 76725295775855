import React from 'react';
import _ from 'lodash';
import { Tooltip } from '@mui/material';
import {
  StyledDivider,
  Avatar,
  OverflowAvatar,
  TeamListWrapper,
  PersonnelHeader
} from './styles';
import {
  ValueColumn,
  FlexBoxJustifyStart
} from 'src/components/shared/RightSidebar/RightSidebarInfoPane/styles';
import { getInitials } from 'src/shared/users/utils';

interface ITeamList {
  users: { fullName: string; id: number }[];
  projectManagerId: number;
}

const MAX_PERSONNEL_SHOWN = 4;

export const TeamList = ({ users, projectManagerId }: ITeamList) => (
  <TeamListWrapper className="team-list">
    <PersonnelHeader>Personnel</PersonnelHeader>
    <ValueColumn>
      <FlexBoxJustifyStart>
        {_(users)
          .uniqBy('fullName')
          .sort(({ id }: { id: number }) =>
            _.eq(projectManagerId, id) ? -1 : 1
          )
          .map(
            (
              { fullName, id }: { fullName: string; id: number },
              index: number,
              array: { fullName: string; id: number }[]
            ) => {
              if (index === MAX_PERSONNEL_SHOWN) {
                return (
                  <Tooltip
                    style={{ zIndex: 7 }}
                    key={index}
                    title={_(array)
                      .slice(MAX_PERSONNEL_SHOWN)
                      .map(
                        ({
                          fullName,
                          tooltipIndex
                        }: {
                          fullName: string;
                          tooltipIndex: number;
                        }) => (
                          <React.Fragment key={fullName}>
                            <span>{fullName}</span>
                            <br />
                          </React.Fragment>
                        )
                      )
                      .value()}
                  >
                    <OverflowAvatar key="overflow">
                      +{array.length - MAX_PERSONNEL_SHOWN}
                    </OverflowAvatar>
                  </Tooltip>
                );
              }
              if (index > MAX_PERSONNEL_SHOWN) return null;

              return (
                <React.Fragment key={index}>
                  <Tooltip title={fullName} style={{ zIndex: index + 1 }}>
                    <Avatar key={fullName}>{getInitials(fullName)}</Avatar>
                  </Tooltip>
                  {_.eq(projectManagerId, id) && (
                    <StyledDivider orientation="vertical" flexItem />
                  )}
                </React.Fragment>
              );
            }
          )
          .value()}
      </FlexBoxJustifyStart>
    </ValueColumn>
  </TeamListWrapper>
);

export default TeamList;
