import { Button, Typography } from '@mui/material';
import styled, { theme } from 'src/theme';
import { FlexBox } from 'src/components/SubNav/styles';
import Flex from 'src/components/core/Flex';

export const SubNavContent = styled(FlexBox)`
  border: 1px solid ${theme.borderColor};
  border-radius: 8px;
  height: 69px;
  margin: 16px 24px;
`;

export const JustifyEndFlexBox = styled(FlexBox)`
  align-items: center;
  margin-left: auto;
  margin-right: 16px;
`;

export const ColumnFlexBox = styled(FlexBox)`
  align-items: flex-start;
  border-right: 1px solid ${theme.borderColor};
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-left: 16px;
  width: 176px;

  &:last-child {
    border-right: 0;
  }
`;

const PartialBorderColumnFlexBox = styled(ColumnFlexBox)`
  padding-left: 16px;
  margin: 16px 0;
`;

export const RightOnlyBorderColumnFlexBox = styled(
  PartialBorderColumnFlexBox
)``;

export const BoxLabel = styled(Typography)`
  && {
    font-family: Source Sans Pro Semibold;
    color: #757575;
    font-size: 12px;
  }
`;

export const BoxValue = styled(Typography)`
  && {
    color: rgba(0, 0, 0, 0.87);
    font-size: 20px;
  }
`;

export const ProjectManagerBoxValue = styled(BoxValue)`
  && {
    font-size: 14px;
    padding: 4px 0;
  }
`;

export const InstructionsBoxValue = styled(BoxValue)`
  && {
    font-size: 14px;
    color: #212121;
    padding: 4px 0;
  }
`;

export const InvoiceDetailsButton = styled(Button)`
  && {
    color: #00838f;
    font-size: 14px;
    text-transform: none;
  }

  .info-icon {
    fill: #00838f;
    font-size: 18px;
    margin-left: 4px;
  }
`;

export const ViewButton = styled(Flex)`
  align-items: center;
  color: ${theme.button.primaryColor};
  height: 29px;
  margin-left: 6px;
  cursor: pointer;
`;
