import React, { FunctionComponent } from 'react';
import { FlexBox } from './styles';
import MomentButton from '../shared/inputs/MomentButton';
export interface ITimesheetAppBar {
  submitTimesheet: () => void;
  readyToSubmit: boolean;
}

export const TimesheetAppBar: FunctionComponent<ITimesheetAppBar> = props => (
  <FlexBox>
    <MomentButton
      title="Submit"
      onClick={props.submitTimesheet}
      disabled={!props.readyToSubmit}
    />
  </FlexBox>
);

export default TimesheetAppBar;
