import CircularProgress from '@mui/material/CircularProgress';
import _, { camelCase, startCase } from 'lodash';
import * as React from 'react';
import { FunctionComponent, useMemo } from 'react';
import {
  DELIVERABLE_MAP,
  DELIVERABLE_MAP_INVERTED,
  DELIVERABLE_OPTIONS
} from 'src/constants';
import messages from 'src/messages';
import Select from 'src/components/core/Select';
import { IEntityStateUsers } from 'src/shared/users/types';
import { Container, CustomButton } from './styles';
import InputField, { InputType } from '../core/Input';
import { IClient } from 'src/types/IClient';

interface IProjectsAdd {
  values: any;
  managers: IEntityStateUsers;
  clients?: IClient[];
  loading: boolean;
  errors: any;
  isClientDisabled: boolean;
  onChange: (prop: string, value: string) => void;
  onSave: () => void;
  onCancel: () => void;
}

export const ProjectsAdd: FunctionComponent<IProjectsAdd> = props => {
  const managerOptions = useMemo(() => Object.values(props.managers), [
    props.managers
  ]);

  const selectedDeliverable = useMemo(() => {
    const label = startCase(props.values.budgetPercentage);
    const id = DELIVERABLE_MAP_INVERTED[label];
    return id ? [id] : [];
  }, [props.values.budgetPercentage]);

  const onChangeClient = (selection: string[]) => {
    props.onChange('client', selection[0]);
  };

  const onChangeManager = (selection: string[]) => {
    props.onChange('manager', selection[0]);
  };

  const onChangeDeliverable = (selection: string[]) => {
    const id = selection[0];
    const label = DELIVERABLE_MAP[id];
    props.onChange('budgetPercentage', camelCase(label));
  };

  const selectSx = { marginTop: '16px', marginBottom: '8px' };

  return (
    <Container>
      <h3>{messages.ADD_PROJECT}</h3>
      <InputField
        id="name-add-input"
        type={InputType.STRING}
        error={props.errors.name}
        helperText={props.errors.name ? messages.REQUIRED : ''}
        modifyNewItemValue={(value: string) => props.onChange('name', value)}
        required={true}
        label={messages.LABELS.NAME}
        value={props.values.name}
        margin="normal"
        fullWidth={true}
        // offset default margin in input component
        // TODO: remove default right margin in input component
        sx={{ marginRight: 0 }}
      />
      <InputField
        id="nickname-add-input"
        type={InputType.STRING}
        required={true}
        error={props.errors.nickname}
        label={messages.LABELS.NICKNAME}
        value={props.values.nickname}
        helperText={props.errors.nickname ? messages.REQUIRED : ''}
        modifyNewItemValue={(value: string) =>
          props.onChange('nickname', value)
        }
        fullWidth={true}
        variant="outlined"
        sx={{ marginRight: 0 }}
      />
      <Select
        error={props.errors.manager}
        fullWidth
        helperText={props.errors.manager ? messages.REQUIRED : ''}
        label={messages.LABELS.PROJECT_MANAGER}
        options={managerOptions}
        onChange={onChangeManager}
        required
        sx={selectSx}
        value={props.values.manager}
      />
      <Select
        error={props.errors.client}
        fullWidth
        label={messages.LABELS.CLIENT}
        options={props.clients || []}
        onChange={onChangeClient}
        required
        sx={selectSx}
        value={props.values.client}
      />
      <Select
        error={props.errors.budgetPercentage}
        fullWidth
        label={messages.LABELS.BUDGET_PERCENTAGE}
        onChange={onChangeDeliverable}
        options={DELIVERABLE_OPTIONS}
        required
        sx={selectSx}
        value={selectedDeliverable}
      />
      <InputField
        id="specialInstructions-add-textarea"
        type={InputType.STRING}
        required={true}
        error={props.errors.invoicingSpecialInstructions}
        label={messages.LABELS.PROJECT_SPECIAL_INST}
        value={props.values.invoicingSpecialInstructions || ''}
        helperText={
          props.errors.invoicingSpecialInstructions ? messages.REQUIRED : ''
        }
        modifyNewItemValue={(value: string) =>
          props.onChange('invoicingSpecialInstructions', value)
        }
        margin="normal"
        fullWidth={true}
        variant="outlined"
        multiline={true}
        sx={{ marginRight: 0 }}
      />
      {props.loading ? (
        <CircularProgress />
      ) : (
        <React.Fragment>
          <CustomButton
            fullWidth={true}
            color="primary"
            onClick={props.onSave}
            variant="contained"
          >
            {messages.SAVE}
          </CustomButton>
          <CustomButton
            fullWidth={true}
            variant="contained"
            onClick={props.onCancel}
          >
            {messages.CANCEL}
          </CustomButton>
        </React.Fragment>
      )}
    </Container>
  );
};

export default ProjectsAdd;
