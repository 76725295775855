import React, { useState, useCallback } from 'react';
import { DropTargetMonitor } from 'react-dnd';
import _ from 'lodash';
import FileList, { FileListProps } from './FileList';
import TargetBox, { TargetBoxProps } from './TargetBox';

const DragAndDrop = ({
  uploadNewFile,
  externalFile,
  onClickFileName,
  onRemoveFile
}: {
  uploadNewFile: ({ file }: { file: File }) => void;
  externalFile: File[] | { name: string }[];
  onClickFileName?: () => void;
  onRemoveFile?: () => void;
}) => {
  const [droppedFiles, setDroppedFiles] = useState<File[]>([]);

  const handleFileDrop = useCallback(
    (item: TargetBoxProps, monitor: DropTargetMonitor) => {
      if (monitor) {
        const files = monitor.getItem<FileListProps>().files as File[];
        setDroppedFiles(files);
        const file: File | undefined = _.head(files);
        if (file) {
          uploadNewFile({ file });
        }
      }
    },
    [uploadNewFile]
  );
  const files = droppedFiles.length ? droppedFiles : externalFile;

  return (
    <>
      {files.length === 0 && (
        <TargetBox
          onDrop={handleFileDrop}
          lineItemCanBeAdded={files.length < 1}
        />
      )}
      <FileList
        files={files}
        onClickFileName={onClickFileName}
        onRemoveFile={onRemoveFile}
      />
    </>
  );
};

export default DragAndDrop;
