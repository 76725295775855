import styled, { theme } from '../../theme';
import Button from '@mui/material/Button';

export const PrimaryButton = styled(Button)`
  && {
    text-transform: capitalize;
    font-size: 14px;
    padding: 4px 15px;
    background-color: ${theme.button.primaryColor};
    color: ${theme.white};
    &:hover {
      background-color: ${theme.button.primaryHoverColor};
    }
    &:disabled {
      background-color: ${theme.button.primaryDisabledColor};
      color: ${theme.button.primaryDisabledTextColor};
    }
  }
`;
