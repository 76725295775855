import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Box, Button, MenuItem } from '@mui/material';
import Error from '@mui/icons-material/Error';

import styled, { theme } from 'src/theme';

export const CustomInput: React.ComponentType<TextFieldProps> = styled(
  TextField
)`
  && {
    min-width: 240px;
    margin: 24px;
    &.dropdown {
      min-width: unset;
      & div {
        & div {
          padding-left: 12px;
          padding-right: 8px;
          display: flex;
          justify-content: space-between;
          svg {
            margin-right: 24px;
          }
        }
      }
      label {
        color: #212121;
        font-family: ${theme.font};
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
      }
    }
  }
` as any;

export const Header = styled(Box)`
  border-bottom: 1px solid #d4d4d4;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  svg {
    cursor: pointer;
  }
`;

export const Footer = styled(Box)`
  border-top: 1px solid #d4d4d4;
  display: flex;
  justify-content: start;
  padding: 16px 24px;
`;

export const SaveButton = styled(Button)`
  && {
    border: 1px solid #000;
    border-radius: 5px;
    background-color: #000000;
    padding: 5px;
    text-transform: none;
    color: #fff;
    width: 108px;
    height: 36px;
    margin-right: 20px;
    font-family: ${theme.font};
  }
  &&:hover {
    background-color: #212121;
  }
`;

export const CancelButton = styled(Button)`
  && {
    border: 1px solid #212121;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 5px 18px;
    text-transform: none;
    color: #212121;
    width: 108px;
    font-family: ${theme.font};
    height: 36px;
  }
`;

export const Title = styled.div`
  color: #212121;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  font-family: ${theme.font};
`;

export const SubTitle = styled(Title)`
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
`;

export const FlexBox = styled(Box)`
  font-family: ${theme.font};

  display: flex;
  justify-content: space-between;
`;

export const RatesBox = styled(FlexBox)`
  margin: 24px;
  flex-direction: column;
  width: 352px;
  justify-content: start;
  span {
    margin-bottom: 16px;
  }
`;

export const WarningBox = styled(Box)`
  margin: 24px;
  margin-bottom: 0;

  font-family: ${theme.font};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
`;

export const Warning = styled.span`
  && {
    color: #e02020;
  }
`;

export const StyledError = styled(Error)`
  color: #e02020;
`;

export const StyledMenuItem = styled(MenuItem)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
