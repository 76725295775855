import styled, { theme } from 'src/theme';

export const Container = styled.div`
  background: #fff;
  bottom: 0;
  display: flex;
  z-index: 99999;
`;

export const SpanButton = styled.div`
  cursor: pointer;
  border: 1px dashed ${theme.borderColor};
  border-radius: 4px;
  width: 60px;
`;

export const ActionButton = styled.div`
  cursor: pointer;
  border: 1px dashed ${theme.borderColor};
  border-radius: 4px;
  width: 60px;
  color: black;
`;
