import React, { FunctionComponent } from 'react';
import InactiveSortIcon from '@mui/icons-material/ImportExport';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

export interface SortIconProps {
  value?: 'asc' | 'desc' | null;
}

export const SortToggleIcon: FunctionComponent<SortIconProps> = ({ value }) => {
  if (!value) {
    return <InactiveSortIcon />;
  } else if (value === 'desc') {
    return <ArrowDownward />;
  } else {
    return <ArrowUpward />;
  }
};
