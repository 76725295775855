import React, { useState } from 'react';
import _ from 'lodash';
import { MenuItem } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  deliverablesQuery,
  projectRolesQuery,
  projectsQuery
} from 'src/queries/projects';
import { typeGuard } from 'src/shared/utils';
import { Container, CustomInput } from './styles';
import { QueryKeys } from 'src/queries/queryKeys';
import Button from 'src/components/core/Button';
import { toast } from 'src/services/toast';
import { Api } from 'src/api/api';
import { IClient } from 'src/types/IClient';
import {
  Deliverable,
  Project,
  ProjectRole,
  UserProjectRole
} from 'src/api/types';

/**
 * Toolbar for adding projects in "My Projects" page
 */
const AddProjects = (): JSX.Element => {
  const [selectedClientId, setSelectedClientId] = useState<number | null>();
  const [selectedProjectId, setSelectedProjectId] = useState<number | null>();
  const [selectedDeliverableId, setSelectedDeliverableId] = useState<
    number | null
  >();
  const [selectedRoleId, setSelectedRoleId] = useState<number | null>();

  const queryClient = useQueryClient();

  const { data: clients = [] } = useQuery(QueryKeys.clients, () =>
    Api.fetchClients({ isActive: true, hasActiveProjects: true })
  );

  const { data: projects = [] } = useQuery<Project[], Error>(
    [QueryKeys.projectStats, selectedClientId],
    () =>
      projectsQuery({
        clientId: selectedClientId || 0,
        isActive: true
      }),
    {
      enabled: typeGuard.isNumber(selectedClientId)
    }
  );

  const { data: deliverables = [] } = useQuery<Deliverable[], Error>(
    [QueryKeys.deliverables, selectedProjectId],
    () =>
      deliverablesQuery({
        projectId: selectedProjectId || 0,
        isActive: true
      }),
    {
      enabled: typeGuard.isNumber(selectedProjectId)
    }
  );

  const { data: roles = [] } = useQuery<ProjectRole[], Error>(
    [QueryKeys.projectRoles, selectedProjectId],
    () =>
      projectRolesQuery({
        projectId: selectedProjectId || 0
      }),
    {
      enabled: typeGuard.isNumber(selectedProjectId)
    }
  );

  const createRoleMutation = useMutation(async () => {
    if (selectedDeliverableId && selectedRoleId) {
      const result = await Api.createUserProjectRole({
        deliverableId: selectedDeliverableId,
        projectRoleId: selectedRoleId,
        isVisible: true
      });
      if (!result.errors) {
        toast('Project was successfully added', 'success');
        queryClient.setQueryData<UserProjectRole[]>(
          QueryKeys.userProjectRoles,
          old => (old ? [...old, result] : [result])
        );
      } else {
        toast(
          result.errors.reduce((agg, cur) => `${agg}\n${cur}`),
          'error'
        );
      }
    }
  });

  return (
    <Container>
      <CustomInput
        key="client"
        select
        onChange={({ target: { value } }) => {
          setSelectedClientId(Number.parseInt(value));
          setSelectedProjectId(null);
          setSelectedDeliverableId(null);
          setSelectedRoleId(null);
        }}
        label="Client"
        value={selectedClientId || ''}
        margin="normal"
        variant="outlined"
      >
        {_.map(clients, (client: IClient) => (
          <MenuItem key={client.name} value={client.id}>
            {client.name}
          </MenuItem>
        ))}
      </CustomInput>
      <CustomInput
        key="project"
        select
        disabled={_.isEmpty(projects)}
        onChange={({ target: { value } }) => {
          setSelectedProjectId(Number.parseInt(value));
          setSelectedDeliverableId(null);
          setSelectedRoleId(null);
        }}
        label="Project"
        value={selectedProjectId || ''}
        margin="normal"
        variant="outlined"
      >
        {_.map(projects, (project: Project) => (
          <MenuItem key={project.name} value={project.id}>
            {project.name}
          </MenuItem>
        ))}
      </CustomInput>
      <CustomInput
        key="deliverable"
        select
        disabled={_.isEmpty(deliverables)}
        onChange={({ target: { value } }) => {
          setSelectedDeliverableId(Number.parseInt(value));
        }}
        label="Deliverable"
        value={selectedDeliverableId || ''}
        margin="normal"
        variant="outlined"
      >
        {_.map(deliverables, (deliverable: Deliverable) => (
          <MenuItem key={deliverable.name} value={deliverable.id}>
            {deliverable.name}
          </MenuItem>
        ))}
      </CustomInput>
      <CustomInput
        key="role"
        select
        disabled={_.isEmpty(roles)}
        onChange={({ target: { value } }) => {
          setSelectedRoleId(Number.parseInt(value));
        }}
        label="Role"
        value={selectedRoleId || ''}
        margin="normal"
        variant="outlined"
      >
        {_.map(roles, (role: ProjectRole) => (
          <MenuItem key={role.name} value={role.id}>
            {role.name}
          </MenuItem>
        ))}
      </CustomInput>
      <Button
        icon="Add"
        disabled={selectedDeliverableId == null || selectedRoleId == null}
        onClick={() => createRoleMutation.mutate()}
        sx={{
          marginLeft: '1rem',
          marginTop: '8px' // added to account for spacing above inputs
        }}
      >
        Add Project
      </Button>
    </Container>
  );
};

export default AddProjects;
