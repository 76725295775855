import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import AppBarContainer from 'src/containers/AppBarContainer';
import ClientAddContainer from 'src/containers/ClientAddContainer';
import ClientDetailContainer from 'src/containers/ClientDetailContainer';
import ClientEditContainer from 'src/containers/ClientEditContainer';
import ClientListContainer from 'src/containers/ClientListContainer';
import InvoiceListContainer from 'src/containers/InvoiceListContainer';
import InvoiceDetailContainer from 'src/containers/InvoiceDetailContainer';
import LeftDrawerContainer from 'src/containers/LeftDrawerContainer';
import NotFoundContainer from 'src/containers/NotFoundContainer';
import InHouseProjectsAddContainer from 'src/containers/InHouseProjectsAddContainer';
import ClientProjectsAddContainer from 'src/containers/ClientProjectsAddContainer';
import ProjectsDetailContainer from 'src/containers/ProjectsDetailContainer';
import ClientProjectsEditContainer from 'src/containers/ClientProjectsEditContainer';
import InHouseProjectsEditContainer from 'src/containers/InHouseProjectsEditContainer';
import ClientProjectsListContainer from 'src/containers/ClientProjectsListContainer';
import InHouseProjectsListContainer from 'src/containers/InHouseProjectsListContainer';
import TimesheetContainer from 'src/containers/TimesheetContainer';
import UserAddContainer from 'src/containers/UserAddContainer';
import UserEditContainer from 'src/containers/UserEditContainer';
import UserListContainer from 'src/containers/UserListContainer';
import MyProjects from 'src/components/MyProjects';
import TimesheetApprovalsSummaryTabContainer from 'src/containers/TimesheetApprovalSummaryTabContainer';
import UserTimesheetContainer from 'src/containers/UserTimesheetContainer';
import PrivateRouteTemplate from '../../components/PrivateRouteTemplate';
import { Container, MainContent } from './styles';
import UserDetail from '../../components/UserDetail';
import { AppContext } from 'src/contexts/app-context';
import { useContext } from 'react';

export const PrivateLayout = () => {
  const appContext = useContext(AppContext);

  return (
    <Container>
      <AppBarContainer />
      <LeftDrawerContainer />
      <MainContent opened={appContext.isLeftDrawerOpen}>
        <Routes>
          <Route
            path="/team-management"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                showRightOnSm={false}
                mainComponent={UserListContainer}
                rightComponent={UserDetail}
              />
            }
          />
          <Route
            path="/team-management/add"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                showRightOnSm={true}
                mainComponent={UserListContainer}
                rightComponent={UserAddContainer}
              />
            }
          />
          <Route
            path="/team-management/:id/edit"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/team-management"
                showRightOnSm={true}
                mainComponent={UserListContainer}
                rightComponent={UserEditContainer}
              />
            }
          />
          <Route
            path="/team-management/:id"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/team-management"
                showRightOnSm={true}
                mainComponent={UserListContainer}
                rightComponent={UserDetail}
              />
            }
          />
          <Route
            path="/client-projects"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                showRightOnSm={false}
                mainComponent={ClientProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/inhouse-projects"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                showRightOnSm={false}
                mainComponent={InHouseProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/client-projects/add"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                showRightOnSm={true}
                backRoute="/client-projects"
                mainComponent={ClientProjectsListContainer}
                rightComponent={ClientProjectsAddContainer}
              />
            }
          />
          <Route
            path="/inhouse-projects/add"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                showRightOnSm={true}
                backRoute="/inhouse-projects"
                mainComponent={InHouseProjectsListContainer}
                rightComponent={InHouseProjectsAddContainer}
              />
            }
          />
          <Route
            path="/client-projects/:id"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/client-projects"
                showRightOnSm={true}
                mainComponent={ClientProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/client-projects/:id/general"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/client-projects"
                showRightOnSm={true}
                mainComponent={ClientProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/client-projects/:id/contact"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/client-projects"
                showRightOnSm={true}
                mainComponent={ClientProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/client-projects/:id/contact/add"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/client-projects"
                showRightOnSm={true}
                mainComponent={ClientProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/client-projects/:id/contact/add-cc"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/client-projects"
                showRightOnSm={true}
                mainComponent={ClientProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/client-projects/:id/deliverables"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/client-projects"
                showRightOnSm={true}
                mainComponent={ClientProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/client-projects/:id/deliverables/add"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/client-projects"
                showRightOnSm={true}
                mainComponent={ClientProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/client-projects/:id/deliverables/edit/:deliverableId"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/client-projects"
                showRightOnSm={true}
                mainComponent={ClientProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/client-projects/:id/roles"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/client-projects"
                showRightOnSm={true}
                mainComponent={ClientProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/client-projects/:id/roles/add"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/client-projects"
                showRightOnSm={true}
                mainComponent={ClientProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/client-projects/:id/roles/edit/:roleId"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/client-projects"
                showRightOnSm={true}
                mainComponent={ClientProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/inhouse-projects/:id"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/inhouse-projects"
                showRightOnSm={true}
                mainComponent={InHouseProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/inhouse-projects/:id/general"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/inhouse-projects"
                showRightOnSm={true}
                mainComponent={InHouseProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/inhouse-projects/:id/contact"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/inhouse-projects"
                showRightOnSm={true}
                mainComponent={InHouseProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/inhouse-projects/:id/contact/add"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/inhouse-projects"
                showRightOnSm={true}
                mainComponent={InHouseProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/inhouse-projects/:id/contact/add-cc"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/inhouse-projects"
                showRightOnSm={true}
                mainComponent={InHouseProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/inhouse-projects/:id/deliverables"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/inhouse-projects"
                showRightOnSm={true}
                mainComponent={InHouseProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/inhouse-projects/:id/deliverables/add"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/inhouse-projects"
                showRightOnSm={true}
                mainComponent={InHouseProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/inhouse-projects/:id/deliverables/edit/:deliverableId"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/inhouse-projects"
                showRightOnSm={true}
                mainComponent={InHouseProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/inhouse-projects/:id/roles"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/inhouse-projects"
                showRightOnSm={true}
                mainComponent={InHouseProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/inhouse-projects/:id/roles/add"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/inhouse-projects"
                showRightOnSm={true}
                mainComponent={InHouseProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/inhouse-projects/:id/roles/edit/:roleId"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/inhouse-projects"
                showRightOnSm={true}
                mainComponent={InHouseProjectsListContainer}
                rightComponent={ProjectsDetailContainer}
              />
            }
          />
          <Route
            path="/client-projects/:id/edit"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/client-projects"
                showRightOnSm={true}
                mainComponent={ClientProjectsListContainer}
                rightComponent={ClientProjectsEditContainer}
              />
            }
          />
          <Route
            path="/inhouse-projects/:id/edit"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/inhouse-projects"
                showRightOnSm={true}
                mainComponent={InHouseProjectsListContainer}
                rightComponent={InHouseProjectsEditContainer}
              />
            }
          />
          <Route
            path="/client-management"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                showRightOnSm={false}
                mainComponent={ClientListContainer}
                rightComponent={ClientDetailContainer}
              />
            }
          />
          <Route
            path="/client-management/add"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                showRightOnSm={true}
                backRoute="/client-management"
                mainComponent={ClientListContainer}
                rightComponent={ClientAddContainer}
              />
            }
          />
          <Route
            path="/client-management/:id/edit"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/client-management"
                showRightOnSm={true}
                mainComponent={ClientListContainer}
                rightComponent={ClientEditContainer}
              />
            }
          />
          <Route
            path="/client-management/:id"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                backRoute="/client-management"
                showRightOnSm={true}
                mainComponent={ClientListContainer}
                rightComponent={ClientDetailContainer}
              />
            }
          />
          <Route
            path="/invoicing"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                rightOpened={appContext.isRightDrawerOpen}
                leftOpened={false}
                showRightOnSm={false}
                mainComponent={InvoiceListContainer}
              />
            }
          />
          <Route
            path="/invoicing/:id"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                rightOpened={false}
                leftOpened={false}
                backRoute="/invoicing"
                showRightOnSm={false}
                mainComponent={InvoiceDetailContainer}
              />
            }
          />
          <Route
            path="/timesheet-approvals"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={false}
                showRightOnSm={false}
                mainComponent={TimesheetContainer}
              />
            }
          />
          <Route
            path="/timesheet-approvals/:id/summary"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                showRightOnSm={false}
                mainComponent={TimesheetContainer}
                rightComponent={TimesheetApprovalsSummaryTabContainer}
              />
            }
          />
          <Route
            path="/projects"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={false}
                showRightOnSm={false}
                mainComponent={MyProjects}
              />
            }
          />
          <Route
            path="/"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                rightOpened={false}
                leftOpened={false}
                showRightOnSm={false}
                mainComponent={UserTimesheetContainer}
              />
            }
          />
          <Route
            path="/timesheet"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                rightOpened={false}
                leftOpened={false}
                showRightOnSm={false}
                mainComponent={UserTimesheetContainer}
              />
            }
          />
          <Route
            path="/timesheet/:id"
            element={
              <PrivateRouteTemplate
                rightToggle={appContext.toggleRightDrawer}
                rightOpened={false}
                leftOpened={false}
                showRightOnSm={false}
                mainComponent={UserTimesheetContainer}
              />
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRouteTemplate
                showRightOnSm={false}
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                mainComponent={UserDetail}
                isCurrentUser
              />
            }
          />
          <Route
            element={
              <PrivateRouteTemplate
                showRightOnSm={false}
                rightToggle={appContext.toggleRightDrawer}
                leftOpened={appContext.isLeftDrawerOpen}
                rightOpened={appContext.isRightDrawerOpen}
                mainComponent={NotFoundContainer}
              />
            }
          />
        </Routes>
      </MainContent>
    </Container>
  );
};

export default PrivateLayout;
