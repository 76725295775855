import * as React from 'react';
import { Box as MBox, BoxProps } from '@mui/material';

export const Box = React.forwardRef<HTMLDivElement, BoxProps>(
  ({ children, ...props }, ref) => {
    return (
      <MBox {...props} ref={ref}>
        {children}
      </MBox>
    );
  }
);

export default Box;
