import React from 'react';
import { Footer } from 'src/components/shared/ConfirmModal/styles';
import { Dialog, CustomPrimaryButton, CustomSecondaryButton } from './styles';

interface IConfirmationDialogModal {
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  description: string;
  confirmText: string;
  cancelText: string;
}

export const ConfirmationDialog = ({
  onConfirm,
  onCancel,
  title,
  description,
  confirmText,
  cancelText
}: IConfirmationDialogModal) => {
  return (
    <Dialog>
      <h4>{title}</h4>
      <p>{description}</p>
      <Footer>
        <CustomPrimaryButton onClick={() => onCancel()}>
          {cancelText}
        </CustomPrimaryButton>
        <CustomSecondaryButton onClick={() => onConfirm()}>
          {confirmText}
        </CustomSecondaryButton>
      </Footer>
    </Dialog>
  );
};

export default ConfirmationDialog;
