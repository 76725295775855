import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getTokenFromLocalStorage } from 'src/services/api';

const httpLink = createHttpLink({ uri: 'api/graphql' });

const authLink = setContext((_request, { headers }) => ({
  headers: {
    ...headers,
    authorization: `Bearer ${getTokenFromLocalStorage()}`
  }
}));

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      // cache-and-network means use data in cache if available, and also
      // make an api request to update cache with fresh data.
      // additional policies: https://www.apollographql.com/docs/react/data/queries#supported-fetch-policies
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network'
    }
  }
});
