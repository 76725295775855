import React, { useState, useEffect } from 'react';
import { CustomChart } from './styles';
import { CircularProgress } from '@mui/material';
import _ from 'lodash';
import { TimesheetEntry, TimesheetRow } from 'src/api/types';

interface TimesheetUtilizationChartProps {
  rows: TimesheetRow[];
  width: number;
  height: number;
}

const TimesheetUtilizationChart = (props: TimesheetUtilizationChartProps) => {
  const [projects, setProjects] = useState({});

  useEffect(() => {
    const entries = props.rows.map(row => _.get(row, 'entries', [])).flat(1);
    const pjs = _.groupBy(entries, e => e.project.name);
    if (!_.isEqual(pjs, projects)) {
      setProjects(pjs);
    }
  }, [props.rows, projects]);

  return (
    <CustomChart
      width="auto"
      height="auto"
      chartType="PieChart"
      loader={<CircularProgress />}
      data={getUtilization(projects)}
      options={getChartOptions(projects, props.width, props.height)}
    />
  );
};

TimesheetUtilizationChart.defaultProps = {
  width: 350,
  height: 150
};

const getUtilization = (projects: {}) => {
  const data = Object.keys(projects).map(key => {
    const hours = projects[key].reduce(
      (prev: number, current: TimesheetEntry) => prev + current.hours,
      0
    );
    return [key, hours];
  });
  return [['Project', 'Hours'], ...data];
};

const getChartOptions = (projects: {}, width: number, height: number) => {
  const colors = Object.keys(projects).map(key => {
    return projects[key][0].client.color;
  });
  return {
    legend: {
      position: 'labeled',
      labeledValueText: 'both'
    },
    enableInteractivity: true,
    chartArea: {
      left: '5%',
      right: '5%'
    },
    width,
    height,
    colors
  };
};

export default TimesheetUtilizationChart;
