import _ from 'lodash';
import { MAX_HOURS_PER_DAY } from 'src/constants';

export const validateNewValue = (value: number | string, dataKey: string) => {
  let newValue = value;
  switch (dataKey) {
    case 'invoicingHours':
      if (typeof newValue === 'string') {
        newValue = parseFloat(newValue);
      }
      if (typeof newValue === 'number') {
        if (_.lt(newValue, 0)) {
          return 0;
        }
        if (_.gt(newValue, MAX_HOURS_PER_DAY)) {
          return MAX_HOURS_PER_DAY;
        }
        if (!_.eq(newValue % 4, 0)) {
          return (Math.round(newValue * 4) / 4).toFixed(2); // round to nearest .25
        }
        return newValue;
      }
      break;
    case 'amount':
      if (typeof newValue === 'string') {
        newValue = parseFloat(newValue);
      }
      if (typeof newValue === 'number') {
        return newValue.toFixed(2);
      }
      return newValue;
    default:
      return value;
  }
  return newValue;
};
