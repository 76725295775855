import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { ProjectStats, ProjectStats2 } from '../types';

export const DEFAULT_FETCH_PROJECTS_PER_PAGE = 20;

export const fetchProjectStats = ({
  clientId,
  managerId,
  page,
  perPage = DEFAULT_FETCH_PROJECTS_PER_PAGE,
  projectId,
  searchText,
  status
}: {
  clientId?: number | string;
  managerId?: number | string;
  page?: number;
  perPage?: number;
  projectId?: number | string;
  searchText?: string;
  status?: boolean;
}) =>
  callAPI<{ projects: ProjectStats[]; total: number }>({
    method: 'get',
    params: {
      page,
      currentProjectsPage: true,
      isActive: status,
      perPage: perPage,
      projectManagerOnly: 'All Projects',
      projectId: projectId,
      searchName: searchText,
      projectManagerId: managerId,
      clientId
    },
    url: '/projects/stats'
  })
    .then(({ data: { projects, total } }) => {
      const mapped = projects.map(buildProjectObject);

      // TODO: move sorting to backend and have sorting occur before pagaination. sorting is currently happening after pagination, which doesn't make sense.
      const sorted = sortProjects(mapped);

      return { projects: sorted, total };
    })
    .catch(error => {
      toast('Failed to fetch projects', 'error');
      throw error;
    });

const buildProjectObject = (p: ProjectStats) => {
  return {
    ...p,
    budgetUsed: calcUsedBudget(p),
    hoursRemaining: calcUsedHours(
      p.estimatedHours,
      p.invoicingHours,
      p.hoursUninvoiced,
      p.hoursUnapproved
    )
  } as ProjectStats2;
};

function calcUsedBudget(current: ProjectStats) {
  if (
    current.budgetPercentagePreference === 'hours' ||
    current.budgetPercentagePreference === 'none' ||
    !current.budgetPercentagePreference
  ) {
    const estimate = current.estimatedHours || 1;
    const workedHours = current.invoicingHours + current.hoursUninvoiced;
    return Number(((workedHours / estimate) * 100).toFixed(0));
  } else if (
    current.estimatedCost !== 0 &&
    (current.budgetPercentagePreference === 'dollars' ||
      (current.estimatedHours === 0 && !current.budgetPercentagePreference))
  ) {
    const spent =
      current.amountInvoiced +
      current.amountUninvoiced +
      parseFloat(current.lineItemsInvoiced);
    return Number(((spent / current.estimatedCost) * 100).toFixed(0));
  }

  return 0;
}

function calcUsedHours(
  estimated: number,
  invoicingHours: number,
  hoursUninvoiced: number,
  hoursUnapproved: number
) {
  const newEstimate = estimated || 1;
  const workedHours = invoicingHours + hoursUninvoiced + hoursUnapproved;

  return Number(((workedHours * 100) / newEstimate).toFixed(0));
}

const sortProjects = (projectArray: ProjectStats2[]) =>
  projectArray.sort((a, b) => {
    if (a.projectName < b.projectName) {
      return -1;
    }
    if (a.projectName > b.projectName) {
      return 1;
    }
    return 0;
  });
