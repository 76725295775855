import TextField, { TextFieldProps } from '@mui/material/TextField';
import styled, { theme } from '../../theme';
import Fab, { FabProps } from '@mui/material/Fab';
import {
  Autocomplete,
  Box,
  FormControl,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';

export const CustomInput: React.ComponentType<TextFieldProps> = styled(
  TextField
)`
  && {
    width: 180px;
    margin-right: 16px;
    label {
      color: ${theme.black};
      font-weight: 600;
    }
    .MuiSelect-outlined {
      padding: 10px 9px 9px 9px;
      font-size: 14px;
      line-height: 18px;
    }
  }
` as any;

export const CustomInputSearch: React.ComponentType<TextFieldProps> = styled(
  CustomInput
)`
  && {
    width: 240px;
    input {
      font-size: 14px;
      line-height: 18px;
      padding: 1px 40px 1px 5px;
      margin: 9px 0;
    }
    .MuiAutocomplete-input {
      padding: 0 !important;
      margin: 0;
    }
    .MuiOutlinedInput-adornedStart {
      padding: 6px 9px;
    }
  }
` as any;

export const Container = styled.div`
  margin: 10px;
  min-width: 650px;
  width: 100%;
  flex-direction: row;
  display: flex;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  height: 66px;
`;

export const CustomFab: React.ComponentType<FabProps> = styled(Fab)`
  && {
    background-color: ${theme.tealDark};
    margin: 12px 0px 5px 0px;
    width: 36px;
    height: 36px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  }
` as any;

export const Details = styled(FormControl)`
  &.MuiFormControl-root {
    color: ${theme.teal};
    position: absolute;
    right: 50px;
    cursor: pointer;
    .MuiIconButton-label {
      color: ${theme.teal};
    }
  }
`;

export const OptionCheckboxWrapper = styled(Box)`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: ${theme.white};
  width: calc(100% - 16px);
  border-top: ${theme.border};
  border-bottom: ${theme.border};
  padding-left: 16px;
`;

export const IsolatedToggleGroup = styled(ToggleButtonGroup)`
  .MuiToggleButtonGroup-grouped {
    &:not(:last-child), &:not(:first-child) {
      border-radius: 15px;
  }
`;

export const IsolatedToggle = styled(ToggleButton)`
  &.MuiToggleButton-root {
    text-transform: none;
    background-color: ${theme.white};
    color: ${theme.teal};
    border: none;
    &.Mui-selected {
      background-color: ${theme.tealLight};
      color: ${theme.tealDark};
    }
  }
`;

export const PositionedAutocomplete = styled(Autocomplete)`
  position: absolute;
  right: 240px;
`;
