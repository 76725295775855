import React from 'react';
import { Panel, Row, Footer, Value } from '../styles';
import { ITimesheetUtilization } from './types';
import { Tooltip } from '@mui/material';

const TimesheetUtilization = ({
  timesheet: {
    totalUtilization,
    totalBillUtilization,
    averageWorkDay,
    totalWorkHours,
    totalHours
  }
}: ITimesheetUtilization) => (
  <Panel>
    <Tooltip title="Includes project, vacation, sick and holiday hours">
      <Row className="highlighted">
        <span>Total Hours</span>
        <span>
          <Value>{totalHours} h</Value>
        </span>
      </Row>
    </Tooltip>
    <Row className="highlighted">
      <span>Utilization*</span>
      <span>
        <Value>{totalUtilization}%</Value>
      </span>
    </Row>
    <Row>
      <span>Billable</span>
      <span>
        <Value>{totalBillUtilization}%</Value>
      </span>
    </Row>
    <Row>
      <span>Non-billable</span>
      <span>
        <Value>{totalUtilization - totalBillUtilization}%</Value>
      </span>
    </Row>
    <Row>
      <span>Average workday</span>
      <span>
        <Value>{averageWorkDay} h</Value>
      </span>
    </Row>
    <Footer>
      *Based on {totalWorkHours} hours available in this timesheet
    </Footer>
  </Panel>
);

export default TimesheetUtilization;
