import React, { useState } from 'react';
import moment from 'moment';
import { Menu, MenuItem } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';
import {
  FlexBoxMarginBottom4,
  FlexBoxJustifyStart,
  StyledHighlightOff
} from 'src/components/shared/RightSidebar/RightSidebarInfoPane/styles';
import {
  ColumnFlexBox,
  InvoiceNoteContainer,
  MarginLeftNeg20,
  MarginBottom16,
  StyledPrimaryButton,
  StyledCancelButton,
  HoverableTextField,
  StaticTextField
} from '../styles';
import { INote, ENoteType } from 'src/shared/invoice-list/types';
import { Italic } from 'src/components/InvoiceDetail/TimesheetRowTable/styles';
import InputField, { InputType } from 'src/components/shared/inputs/InputField';
import { ICurrentUser } from 'src/shared/auth/types';
import { isAdmin } from 'src/shared/auth/utils';
import { CharacterWarningLimit } from 'src/components/InvoiceDetail/LineItemModal/styles';
import { SHORT_TEXT_CHAR_LIMIT } from 'src/constants';

export const InvoiceNote = ({
  note,
  deleteInvoiceNote,
  updateInvoiceNote,
  immutable,
  currentUser
}: {
  note: INote;
  deleteInvoiceNote: ({ id }: { id: number }) => void;
  updateInvoiceNote: ({ text, id }: { text: string; id: number }) => void;
  immutable?: boolean;
  currentUser?: ICurrentUser;
}) => {
  const immutableLocal =
    immutable ||
    (currentUser && !isAdmin(currentUser) && currentUser.id !== note.userId);
  const [anchorEl, setAnchorEl] = useState<null | SVGSVGElement>(null);

  const openMenu = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const [isEditing, setIsEditing] = useState(false);

  const [text, setText] = useState(note.text);

  const [edited, setEdited] = useState(false);

  const updateNote = () => {
    setIsEditing(false);
    updateInvoiceNote({ text, id: note.id ? note.id : 0 });
    setEdited(true);
  };

  const isRejection = note.typeOf === ENoteType.INVOICE_REJECTION_REASON;

  const menu = note.id ? (
    <React.Fragment>
      <MoreVert onClick={openMenu} key="menu-anchor" />
      <Menu
        key="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem
          key="edit"
          onClick={() => {
            closeMenu();
            setIsEditing(true);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          key="delete"
          onClick={() => {
            closeMenu();
            deleteInvoiceNote({ id: note.id ? note.id : 1 });
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </React.Fragment>
  ) : null;

  let className = '';
  if (isRejection) {
    className = className.concat('rejection');
  }

  return (
    <InvoiceNoteContainer>
      {note.userName && note.createdAt ? (
        <FlexBoxMarginBottom4>
          <Italic>
            {note.userName} - {moment(note.createdAt).format('MMM DD')}
            {note.updatedAt !== note.createdAt || edited ? ' - modified' : ''}
          </Italic>
          {!immutableLocal && menu}
        </FlexBoxMarginBottom4>
      ) : null}

      {isEditing ? (
        <ColumnFlexBox>
          <MarginBottom16>
            <MarginLeftNeg20>
              <InputField
                autoFocus
                validation={'instruction'}
                type={InputType.LONG_STRING}
                value={text}
                modifyNewItemValue={(value: string) =>
                  setText(value.slice(0, SHORT_TEXT_CHAR_LIMIT))
                }
                immediateChange
                charLimit={SHORT_TEXT_CHAR_LIMIT}
              />
            </MarginLeftNeg20>
            <CharacterWarningLimit>
              {SHORT_TEXT_CHAR_LIMIT - text.length} characters remaining
            </CharacterWarningLimit>
          </MarginBottom16>
          <FlexBoxJustifyStart>
            <StyledPrimaryButton onClick={updateNote}>Save</StyledPrimaryButton>
            <StyledCancelButton
              onClick={() => {
                setText(note.text);
                setIsEditing(false);
              }}
            >
              Cancel
            </StyledCancelButton>
          </FlexBoxJustifyStart>
        </ColumnFlexBox>
      ) : immutableLocal ? (
        <StaticTextField className={className}>
          {isRejection && <StyledHighlightOff />}
          {note.text}
        </StaticTextField>
      ) : (
        <HoverableTextField
          onClick={() => setIsEditing(true)}
          className={className}
        >
          {note.text}
        </HoverableTextField>
      )}
    </InvoiceNoteContainer>
  );
};

export default InvoiceNote;
