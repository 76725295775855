import * as React from 'react';
import { H1, LogoText, SmallLogo, Span, StyledP } from './styles';

const variants = {
  H1: 'h1',
  LOGO: 'Logo',
  P: 'p',
  SMALL_LOGO: 'small-logo',
  SPAN: 'span'
};

interface ITypography {
  variant?: string;
  center?: boolean;
  children?: string;
}

const Typography: React.FC<ITypography> = ({
  variant,
  children,
  center = false
}) => {
  switch (variant) {
    case variants.LOGO:
      return <LogoText center={center}>{children}</LogoText>;
    case variants.SMALL_LOGO:
      return <SmallLogo center={center}>{children}</SmallLogo>;
    case variants.H1:
      return <H1>{children}</H1>;
    case variants.SPAN:
      return <Span center={center}>{children}</Span>;
    default:
      return <StyledP center={center}>{children}</StyledP>;
  }
};

export { variants };
export default Typography;
