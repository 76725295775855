import { createContext } from 'react';

export interface IAppContext {
  isAuthenticatedQuickBooksOnline: boolean;
  isLeftDrawerOpen: boolean;
  isRightDrawerOpen: boolean;
  isLeftMobileDrawerOpen: boolean;
  toggleRightDrawer: () => any;
  toggleLeftDrawer: () => any;
  toggleLeftMobileDrawer: () => any;
}

export const AppContext = createContext<IAppContext>({
  isAuthenticatedQuickBooksOnline: false,
  isLeftDrawerOpen: false,
  isRightDrawerOpen: false,
  isLeftMobileDrawerOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleRightDrawer: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleLeftDrawer: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleLeftMobileDrawer: () => {}
});
