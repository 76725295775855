import { Tabs } from '@mui/material';
import {
  FlexBox,
  RightDrawer
} from 'src/components/shared/RightSidebar/styles';
import styled, { theme } from 'src/theme';

export const TimesheetDrawer = styled(RightDrawer)`
  & .MuiPaper-root {
    margin-top: 161px;
  }
`;

export const TimesheetFlexBox = styled(FlexBox)`
  font-size: 20px;
`;

export const StyledTabs = styled(Tabs)`
  padding-top: 20px;
`;

export const Panel = styled.div`
  font-family: ${theme.font};
`;

export const Row = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  font-size: 14px;
  color: #717171;
  padding: 6px 18px;

  &.highlighted {
    color: #3f3f3f;
    font-weight: 600;
  }
`;

export const Footer = styled.div`
  align-items: center;
  flex-grow: 1;
  font-size: 10px;
  padding: 0 15px;
  color: #3f3f3f;
`;

export const Value = styled.span`
  white-space: nowrap;
  color: #3f3f3f;
`;

export const NoDataContainer = styled.div`
  position: absolute;
  white-space: nowrap;
  right: -85px;
  font-size: 23px;
`;

export const Title = styled.span`
  padding-left: 10px;
  white-space: nowrap;
`;

export const ClientIcon = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: ${props => props.color};
  flex-shrink: 0;
`;
