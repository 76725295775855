import _ from 'lodash';
import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { Deliverable } from '../types';

export const fetchDeliverables = (params: {
  allActive?: boolean;
  ids?: number | number[];
  isActive?: boolean;
  perPage?: number;
  projectId?: number;
}) =>
  callAPI<Deliverable[]>({
    method: 'get',
    params,
    url: '/deliverables',
    reqHeaders: {
      Accept: 'application/json'
    }
  })
    .then(response => response.data)
    .catch(e => {
      const error = _.get(e, 'response', null);
      const msg = error.data.sow || 'Failed to fetch deliverables';
      toast(msg, 'error');
      throw e;
    });
