import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FilledInput,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import * as React from 'react';
import DialogTransition from '../DialogTransition';
import messages from 'src/messages';

interface IAddDeliverable {
  open: boolean;
  handleClose?: () => void;
  add?: () => void;
  errors?: any;
  values?: any;
  loading: boolean;
  onChange?: (prop: string) => (event: any) => void;
  types: string[];
}

const AddDeliverable: React.FC<IAddDeliverable> = ({
  open = true,
  handleClose,
  add,
  errors = {},
  values = {},
  onChange = () => undefined,
  loading = false,
  types = []
}) => (
  <Dialog
    open={open}
    TransitionComponent={DialogTransition}
    keepMounted={true}
    onClose={handleClose}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle id="alert-dialog-slide-title">
      {'Add - Deliverable'}
    </DialogTitle>
    <DialogContent>
      <TextField
        required={false}
        id="sow-add-input"
        label={'SOW'}
        value={values.sow}
        error={errors.sow}
        onChange={onChange('sow')}
        margin="normal"
        fullWidth={true}
        variant="outlined"
      />
      <TextField
        required={true}
        id="name-add-input"
        label={'Name'}
        value={values.name}
        error={errors.name}
        onChange={onChange('name')}
        margin="normal"
        fullWidth={true}
        variant="outlined"
      />
      <FormControl variant="outlined" fullWidth={true} margin="normal">
        <InputLabel htmlFor="type-pref-add">{'Type'}</InputLabel>
        <Select
          multiple={false}
          value={values.type}
          onChange={onChange('type')}
          input={<FilledInput id="type-pref-add-chip" />}
        >
          {types.map(current => (
            <MenuItem key={current} value={current}>
              {current}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        error={errors.estimatedCost}
        required={false}
        id="estCost-add-input"
        label={'Estimated Cost'}
        value={values.estimatedCost}
        onChange={onChange('estimatedCost')}
        margin="normal"
        fullWidth={true}
        variant="outlined"
      />
      <TextField
        error={errors.estimatedHours}
        required={false}
        id="estHours-add-input"
        label={'Estimated Hours'}
        value={values.estimatedHours}
        onChange={onChange('estimatedHours')}
        margin="normal"
        fullWidth={true}
        variant="outlined"
      />
      <TextField
        required={false}
        error={errors.estimatedEndDate}
        id="estEndDate-add-input"
        label={'Estimated End Date'}
        value={values.estimatedEndDate}
        onChange={onChange('estimatedEndDate')}
        margin="normal"
        fullWidth={true}
        variant="outlined"
        type="date"
        InputLabelProps={{
          shrink: true
        }}
      />
      <TextField
        required={true}
        id="status-add-select"
        select={true}
        label={'Status'}
        value={values.isActive}
        onChange={onChange('isActive')}
        fullWidth={true}
        margin="normal"
        variant="outlined"
      >
        <MenuItem key={`status-1`} value={1}>
          Active
        </MenuItem>
        <MenuItem key={`status-0`} value={0}>
          Inactive
        </MenuItem>
      </TextField>
    </DialogContent>
    <DialogActions>
      <Button disabled={loading} onClick={handleClose} color="primary">
        {messages.CANCEL}
      </Button>
      <Button
        disabled={loading}
        onClick={add}
        color="primary"
        variant="contained"
      >
        {messages.ADD}
      </Button>
    </DialogActions>
  </Dialog>
);

export default AddDeliverable;
