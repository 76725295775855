import React from 'react';
import {
  CustomInputSearch,
  Container,
  InnerContainer,
  OptionCheckboxWrapper,
  IsolatedToggle,
  IsolatedToggleGroup,
  PositionedAutocomplete,
  Details
} from './styles';
import { IEntityStateUsersList } from 'src/shared/users/types';
import SearchIcon from '@mui/icons-material/Search';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FormControlLabel, Checkbox } from '@mui/material';

interface ITimesheetListFilters {
  isHidingInactiveUsers: boolean;
  isSelectedMyReports: boolean;
  loadingUsers: boolean;
  onChangeSearch: (prop: string) => void;
  onClickEveryone: () => void;
  onClickHideInactiveUsers: () => void;
  onClickMyReports: () => void;
  onClickUser: (userId?: number) => void;
  rightPanelOpen: boolean;
  search?: string;
  selectedUserName?: string;
  showMyReportsToggle: boolean;
  toggleRightPanel: () => void;
  users: IEntityStateUsersList[];
}

const TimesheetListFilters: React.FunctionComponent<ITimesheetListFilters> = ({
  isHidingInactiveUsers,
  isSelectedMyReports,
  loadingUsers,
  onChangeSearch,
  onClickEveryone,
  onClickHideInactiveUsers,
  onClickMyReports,
  onClickUser,
  rightPanelOpen,
  search,
  selectedUserName,
  showMyReportsToggle,
  toggleRightPanel,
  users
}) => {
  const TOGGLE_DUMMY = 'TOGGLE_DUMMY' as const;
  const userOptions = [...users, TOGGLE_DUMMY];

  return (
    <Container>
      <InnerContainer>
        {showMyReportsToggle && (
          <div>
            <span>View</span> &nbsp;
            <IsolatedToggleGroup value={isSelectedMyReports ? 1 : 2}>
              <IsolatedToggle value={1} onClick={onClickMyReports}>
                My Reports
              </IsolatedToggle>
              <IsolatedToggle value={2} onClick={onClickEveryone}>
                Everyone
              </IsolatedToggle>
            </IsolatedToggleGroup>
          </div>
        )}
        {selectedUserName && (
          <em>
            Showing results for &nbsp;
            <b>{selectedUserName}</b>
          </em>
        )}
        <PositionedAutocomplete
          loading={loadingUsers}
          options={userOptions}
          getOptionLabel={(user: IEntityStateUsersList) => user.fullName}
          renderOption={(props, option: typeof userOptions[number]) => {
            if (option === TOGGLE_DUMMY) {
              return (
                <OptionCheckboxWrapper>
                  <FormControlLabel
                    onClick={onClickHideInactiveUsers}
                    control={<Checkbox checked={isHidingInactiveUsers} />}
                    label="Hide Inactive"
                  />
                </OptionCheckboxWrapper>
              );
            } else {
              return <li {...props}>{option.fullName}</li>;
            }
          }}
          openOnFocus={false}
          ListboxProps={{ style: { paddingBottom: '48px' } }}
          inputValue={search}
          onChange={(_e, user?: IEntityStateUsersList) => onClickUser(user?.id)}
          renderInput={params => (
            <CustomInputSearch
              {...params}
              id="name-input"
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={search || null}
              onChange={e => onChangeSearch(e.target.value)}
              placeholder="Search employees"
              InputProps={{
                ...params.InputProps,
                startAdornment: <SearchIcon />
              }}
            />
          )}
        />
        <Details>
          <FormControlLabel
            control={
              <Checkbox
                onChange={toggleRightPanel}
                icon={<InfoOutlinedIcon />}
                checkedIcon={<InfoOutlinedIcon />}
              />
            }
            label={rightPanelOpen ? 'Hide details' : 'See details'}
            labelPlacement="start"
          />
        </Details>
      </InnerContainer>
    </Container>
  );
};

export default TimesheetListFilters;
