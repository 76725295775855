import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IAppState } from 'src/reducer';
import * as usersActions from 'src/shared/users/actions';
import { IEntityStateUsers } from 'src/shared/users/types';
import ProjectsEdit from '../../components/ProjectsEdit';
import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { QueryKeys } from 'src/queries/queryKeys';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Api } from 'src/api/api';

type IProjectsEditParamsContainer = {
  client: string | number;
  route: string;
};

type IProjectsEditContainerProps = IProjectsEditContainerReduxProps &
  IProjectsEditParamsContainer &
  IProjectsEditContainerDispatch;

interface IProjectsEditContainerDispatch {
  getManagers: () => void;
}

interface IProjectsEditContainerReduxProps {
  managers: IEntityStateUsers;
}

interface IProjectsEditContainerState {
  errors: any;
  name: string;
  nickname: string;
  manager: string | number;
  client: string | number;
  budgetPercentage: string;
  status: number;
  invoicingSpecialInstructions: string | null;
  [x: string]: any;
}

export const ProjectsEditContainer: FunctionComponent<IProjectsEditContainerProps> = props => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const params = useParams();
  const [state, setState] = useState<IProjectsEditContainerState>({
    budgetPercentage: 'hours',
    client: '',
    comms: 'unknown',
    errors: {
      budgetPercentage: false,
      client: false,
      manager: false,
      name: false,
      nickname: false,
      status: false
    },
    manager: '',
    morale: 'unknown',
    name: '',
    nickname: '',
    quality: 'unknown',
    status: 1,
    invoicingSpecialInstructions: null
  });

  const { data: project, isLoading: isLoadingProject } = useQuery(
    QueryKeys.projectDetail(Number(params.id)),
    () => {
      if (!params.id) return;

      return Api.fetchProjectDetail(params.id) as any;
    },
    { cacheTime: 0 }
  );

  const { mutate: updateProject, isLoading: isUpdatingProject } = useMutation(
    Api.updateProject,
    {
      onSuccess: () => {
        const { route } = props;
        const projectId = params.id;
        queryClient.invalidateQueries([QueryKeys.projectStats]);
        navigate(`/${route}/${projectId}/general`, { replace: true });
      }
    }
  );

  useEffect(() => {
    props.getManagers();
  }, []);

  useEffect(() => {
    if (project) {
      setState({
        ...state,
        budgetPercentage: project.budgetPercentagePreference || 'hours',
        client: project.client.id,
        clients: [project.client],
        comms: project.comms ? project.comms.replace('comms_', '') : 'unknown',
        manager: project.projectManager.id,
        morale: project.morale
          ? project.morale.replace('morale_', '')
          : 'unknown',
        name: project.name,
        nickname: project.nickname,
        quality: project.quality
          ? project.quality.replace('quality_', '')
          : 'unknown',
        status: Number(project.isActive),
        invoicingSpecialInstructions: project.invoicingSpecialInstructions
      });
    }
  }, [project]);

  const submitIsValid = () => {
    const { client, manager, name, nickname } = state;

    const errors = {
      client: client === '',
      manager: manager === '',
      name: name === '',
      nickname: nickname === ''
    };

    setState({ ...state, errors });

    return !(errors.client || errors.manager || errors.name || errors.nickname);
  };

  const onFormChange = (prop: string) => (event: any) => {
    setState({
      ...state,
      [prop]: event.target.value
    });
  };

  const onSave = () => {
    if (submitIsValid()) {
      updateProject({ id: Number(params.id), project: values() });
    }
  };

  const onCancel = () => {
    const { route } = props;
    const projectId = params.id;
    navigate(`/${route}/${projectId}/general`, { replace: true });
  };

  const values = () => {
    const {
      budgetPercentage,
      client,
      comms,
      manager,
      morale,
      name,
      nickname,
      quality,
      status,
      invoicingSpecialInstructions
    } = state;

    return {
      budgetPercentage,
      client,
      comms,
      manager,
      morale,
      name,
      nickname,
      quality,
      status,
      invoicingSpecialInstructions
    };
  };

  const errors = () => {
    const {
      budgetPercentage,
      client,
      comms,
      manager,
      morale,
      name,
      nickname,
      quality,
      status
    } = state.errors;

    return {
      budgetPercentage,
      client,
      comms,
      manager,
      morale,
      name,
      nickname,
      quality,
      status
    };
  };

  return (
    <ProjectsEdit
      isLoading={isLoadingProject}
      isUpdating={isUpdatingProject}
      errors={errors()}
      onSave={onSave}
      onCancel={onCancel}
      managers={props.managers}
      onChange={onFormChange}
      values={values()}
      clients={state.clients}
    />
  );
};

export const mapStateToProps = (state: IAppState) => {
  const managers = state.entities.projectManagers;
  return { managers };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IProjectsEditContainerDispatch => {
  return bindActionCreators(
    { getManagers: usersActions.actions.getProjectManagers },
    dispatch
  );
};

export default connect<
  IProjectsEditContainerReduxProps,
  IProjectsEditContainerDispatch
>(
  mapStateToProps,
  mapDispatchToProps
)(ProjectsEditContainer);
