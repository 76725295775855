import { Box, Button as MUIButton } from '@mui/material';
import styled, { theme } from 'src/theme';

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    margin-right: 16px;
  }
`;

export const Button = styled(MUIButton)`
  && {
    font-size: 16px;
    line-height: 24px;
    border-radius: 5px;
    margin-right: 16px;
    margin-left: 0;
    text-transform: none;
    height: 36px;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    background-color: #ffffff;
    color: #00838f;
    font-family: ${theme.font};
    padding: 6px 16px;
  }
`;

export const SubmitButton = styled(Button)`
  && {
    background-color: #00838f;
    color: #fff;
  }
  :hover {
    background-color: #60b2b9 !important;
  }
  &.MuiButton-root.Mui-disabled {
    background-color: #80c1c7;
  }
`;

export const DeleteButton = styled(Button)`
  && {
    color: ${theme.secondaryBackground};
    background-color: #dc1d1e;
    border: solid 1px #dc1d1e;
  }
`;
