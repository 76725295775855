import React, { FunctionComponent } from 'react';
import _ from 'lodash';
import { Grid, Tooltip } from '@mui/material';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import styled, { theme } from 'src/theme';
import {
  FlexGridItem,
  FlexGridItemJustifyStart,
  FlexGridItemJustifyEnd,
  FlexGridItemHeader,
  FlexGridItemHeaderJustifyStart,
  PaddingLeft16,
  StyledRow,
  FlexGridItemLongString,
  FlexGridItemHeaderJustifyEnd,
  DeliverableName
} from 'src/components/InvoiceDetail/TimesheetRowTable/styles';
import AttachFile from '@mui/icons-material/AttachFile';
import { IFixedLineItem } from 'src/shared/invoice/types';
import { formatAsCurrency, formatDateForApi } from 'src/shared/utils';
import { Deliverable } from 'src/api/types';

interface IInvoiceDetailTableProps {
  data: IFixedLineItem[];
  deleteItem: (item: IFixedLineItem) => void;
  deliverables: Deliverable[];
  canEdit: boolean;
  downloadFile: (id: number) => void;
  modLineItem: ({
    lineItem,
    index
  }: {
    lineItem: IFixedLineItem | null;
    index: number;
  }) => void;
  selectedItem: number;
  setSelectedItem: (index: number) => void;
  onEditLineItem: () => void;
}

const StyledTooltip = styled(Tooltip)`
  color: '#ffffff',
  fontFamily: ${theme.font},
  fontSize: '12px',
  fontWeight: 500,
  letterSpacing: 0,
  lineHeight: '16px',
  padding: '6px',
  borderRadius: '5px',
  backgroundColor: '#717171'
`;

const TooltippedAttachIcon = ({
  onClick,
  fileName
}: {
  onClick: () => void;
  fileName: string;
}) => {
  return (
    <StyledTooltip title={fileName} placement="top-start">
      <AttachFile onClick={onClick} />
    </StyledTooltip>
  );
};

export const LineItemTable: FunctionComponent<IInvoiceDetailTableProps> = props => {
  const rowClassName = (idx: number) => {
    const { selectedItem: expandedRow } = props;
    const { data } = props;
    let className = `row row-${idx}`;
    if (!data[idx]) {
      return className;
    }
    if (expandedRow > -1 && data[expandedRow]) {
      if (idx === expandedRow) {
        className = className.concat(' active');
      }
    }
    if (data[idx].modified) {
      className = className.concat(' modified');
    }
    return className;
  };

  const { data, deliverables, canEdit } = props;

  return (
    <Grid container>
      <FlexGridItem item xs={1}>
        <PaddingLeft16>
          <AttachFile />
        </PaddingLeft16>
      </FlexGridItem>
      <FlexGridItemHeaderJustifyStart item xs={2}>
        Description
      </FlexGridItemHeaderJustifyStart>
      <FlexGridItemHeaderJustifyStart item xs={2}>
        Deliverable
      </FlexGridItemHeaderJustifyStart>
      <FlexGridItemHeaderJustifyEnd item xs={2}>
        Date
      </FlexGridItemHeaderJustifyEnd>
      <FlexGridItemHeaderJustifyEnd item xs={2}>
        Amount
      </FlexGridItemHeaderJustifyEnd>
      <FlexGridItemHeader item xs={2}></FlexGridItemHeader>
      {data &&
        data.map((item: IFixedLineItem, idx: number) => {
          const deliverable = _.find(deliverables, (del: Deliverable) =>
            _.eq(item.deliverableId, del.id)
          ) || { name: 'unknown' };
          return (
            <StyledRow
              container
              className={rowClassName(idx)}
              key={item.id}
              onClick={() => props.setSelectedItem(idx)}
              onDoubleClick={() => {
                props.setSelectedItem(idx);
                return props.onEditLineItem();
              }}
            >
              <FlexGridItem item xs={1}>
                <PaddingLeft16>
                  {item.fileId && (
                    <TooltippedAttachIcon
                      onClick={() =>
                        item.fileId
                          ? props.downloadFile(item.fileId)
                          : Function.prototype
                      }
                      fileName={item['file.name']}
                    />
                  )}
                </PaddingLeft16>
              </FlexGridItem>
              <FlexGridItemLongString item xs={2}>
                <span>{item.description}</span>
              </FlexGridItemLongString>
              <FlexGridItemJustifyStart item xs={2}>
                <DeliverableName>{deliverable.name}</DeliverableName>
              </FlexGridItemJustifyStart>
              <FlexGridItemJustifyEnd item xs={2}>
                {formatDateForApi(item.date)}
              </FlexGridItemJustifyEnd>
              <FlexGridItemJustifyEnd item xs={2}>
                {formatAsCurrency(item.amount)}
              </FlexGridItemJustifyEnd>
              <FlexGridItem item xs={1}>
                {canEdit && item.id ? (
                  <RemoveCircle
                    onClick={() => props.deleteItem(item)}
                    className="dismiss"
                  />
                ) : null}
              </FlexGridItem>
              <FlexGridItem item xs={1} />
            </StyledRow>
          );
        })}
    </Grid>
  );
};

export default LineItemTable;
