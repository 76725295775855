import { IconButton, Snackbar } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import * as React from 'react';
import { ContentContainer, SnackContent } from './styles';

interface IGlobalAlert {
  type: string;
  message: string;
  open: boolean;
  onClose?: () => void;
}

const variantIcon = {
  error: ErrorIcon,
  info: InfoIcon,
  success: CheckCircleIcon,
  warning: WarningIcon
};

const GlobalAlert: React.FC<IGlobalAlert> = ({
  open = false,
  onClose,
  message,
  type
}) => {
  let Icon = InfoIcon;
  if (variantIcon[type]) {
    Icon = variantIcon[type];
  }
  return (
    <Snackbar
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top'
      }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <SnackContent
        className={type}
        aria-describedby="client-snackbar"
        message={
          <ContentContainer id="client-snackbar">
            <Icon />
            {message}
          </ContentContainer>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
};

export default GlobalAlert;
