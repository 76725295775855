import { get } from 'lodash';
import React from 'react';
import { IGetRowData } from './types';
import { toPercentage, formatDatePeriod } from 'src/shared/utils';
import { Avatar } from 'src/components/Avatar';
import { LeftShiftedFlexBox, PaddedSpan } from './styles';

export const getEmployee = ({ rowData }: IGetRowData) => {
  const data = get(rowData, 'owner.fullName');
  return (
    <LeftShiftedFlexBox>
      <Avatar name={data} />
      <PaddedSpan>{data}</PaddedSpan>
    </LeftShiftedFlexBox>
  );
};

export const getManager = ({ rowData }: IGetRowData) =>
  get(rowData, 'owner.manager.fullName');

export const getTotalUtilization = ({ rowData }: IGetRowData) =>
  toPercentage(get(rowData, 'totalUtilization'));

export const getBillableUtilization = ({ rowData }: IGetRowData) =>
  toPercentage(get(rowData, 'totalBillUtilization'));

export const getPeriod = ({ rowData }: IGetRowData) =>
  formatDatePeriod(rowData.startDate, rowData.endDate, true);
