import React, { useState } from 'react';
import { Button } from '@mui/material';
import InputField, { InputType } from 'src/components/shared/inputs/InputField';
import { CharacterWarningLimit } from 'src/components/InvoiceDetail/LineItemModal/styles';
import { SHORT_TEXT_CHAR_LIMIT } from 'src/constants';
import styled, { theme } from 'src/theme';

const AddInstructionsInputField = styled(InputField)`
  && {
    &.long-string {
      margin-left: 0;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-top: 8px;
`;

const SectionPrimaryHeader = styled.div`
  color: #212121;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  font-family: ${theme.font};
  padding: 8px 0;
`;

const StyledPrimaryButton = styled(Button)`
  && {
    padding: 5px 16px;
    border-radius: 4px;
    background-color: #00838f;
    color: #ffffff;
    font-family: ${theme.font};
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    margin-right: 16px;
    text-transform: none;
  }
  &&:hover {
    background-color: #4ca8b0;
  }
  &&.MuiButton-root.Mui-disabled {
    color: #fff;
    background-color: #7fc1c7;
  }
`;

const StyledCancelButton = styled(Button)`
  && {
    padding: 5px 16px;

    color: #00838f;
    height: 36px;
    font-family: ${theme.font};
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    text-transform: none;
  }
  &&:hover {
    background-color: #eee;
  }
`;

interface IProps {
  onCancel: () => void;
  onSave: (instruction: string) => void;
}

export default function AddInstructions({ onSave, onCancel }: IProps) {
  const [newInstruction, setNewInstruction] = useState('');

  const handleSave = () => {
    onSave(newInstruction);
  };

  return (
    <div>
      <SectionPrimaryHeader>Add an instruction</SectionPrimaryHeader>
      <AddInstructionsInputField
        autoFocus
        validation={'instruction'}
        type={InputType.LONG_STRING}
        value={newInstruction}
        modifyNewItemValue={(value: string) =>
          setNewInstruction(value.slice(0, SHORT_TEXT_CHAR_LIMIT))
        }
        immediateChange
        charLimit={SHORT_TEXT_CHAR_LIMIT}
      />
      <CharacterWarningLimit>
        {SHORT_TEXT_CHAR_LIMIT - newInstruction.length} characters remaining
      </CharacterWarningLimit>
      <ButtonGroup>
        <StyledPrimaryButton onClick={() => handleSave()}>
          Save
        </StyledPrimaryButton>
        <StyledCancelButton onClick={() => onCancel()}>
          Cancel
        </StyledCancelButton>
      </ButtonGroup>
    </div>
  );
}
