import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import numeral from 'numeral';

export const Currency: FunctionComponent<PropsWithChildren> = props => {
  const children = props.children as any;

  const label = useMemo(() => {
    return isNaN(children) ? '-' : numeral(children).format('$ 0,0.00');
  }, [children]);

  return <>{label}</>;
};

export default Currency;
