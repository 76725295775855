import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';

export const deleteTimesheetEntry = (id: number) =>
  callAPI({
    method: 'delete',
    url: `/timesheet_entries/${id}`
  })
    .then(response => response.data)
    .catch(error => {
      toast('Failed to delete TimesheetEntry', 'error');
      throw error;
    });
