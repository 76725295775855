import SnackbarContent, {
  SnackbarContentProps
} from '@mui/material/SnackbarContent';
import styled, { theme } from '../../theme';

export const ContentContainer = styled.span`
  display: flex;
  align-items: center;
  & svg {
    margin-right: 10px;
  }
`;

export const SnackContent: React.ComponentType<SnackbarContentProps> = styled(
  SnackbarContent
)`
  &.success {
    background-color: ${theme.green};
  }
  &.error {
    background-color: ${theme.red};
  }
  &.info {
    background-color: ${theme.googleBlue};
  }
`;
