import _ from 'lodash';
import {
  ProjectRoleHours,
  TimesheetEntryClassification,
  TimesheetRow
} from 'src/api/types';
import { UserClassification } from 'src/shared/users/types';

const DAILY_HOURS = 8;

class TimesheetDayUtil {
  totalHoursByClassification(day: TimesheetRow, classification: string) {
    const entries = _.filter(
      day.entries,
      entry => entry.classification === classification
    );
    const hours = _.map(entries, 'hours');
    const total = _.reduce(hours, (memo, num) => memo + (num || 0), 0);
    return total;
  }

  hasTimeWorked(day: TimesheetRow) {
    let hasTimeWorked;
    if (this.hasEntries(day)) {
      const timeoffEntries = _.filter(
        day.entries,
        entry =>
          entry.classification === TimesheetEntryClassification.PTO ||
          entry.classification === TimesheetEntryClassification.SICK_TIME
      );
      if (timeoffEntries.length < day.entries.length) {
        hasTimeWorked = true;
      }
    }
    return hasTimeWorked;
  }

  hasTimeOff(day: TimesheetRow) {
    const timeOffEntries = _.find(
      day.entries,
      entry =>
        entry.classification === TimesheetEntryClassification.PTO ||
        entry.classification === TimesheetEntryClassification.SICK_TIME
    );

    if (timeOffEntries) {
      return true;
    }
    return false;
  }

  hasEntries(day: TimesheetRow) {
    return day.entries.length > 0;
  }

  totalBillableHours(day: TimesheetRow) {
    return this.totalHoursByClassification(
      day,
      TimesheetEntryClassification.BILLABLE
    );
  }

  totalNonBillHours(day: TimesheetRow) {
    return this.totalHoursByClassification(
      day,
      TimesheetEntryClassification.NON_BILLABLE
    );
  }

  totalPTOHours(day: TimesheetRow) {
    return this.totalHoursByClassification(
      day,
      TimesheetEntryClassification.PTO
    );
  }

  totalSickHours(day: TimesheetRow) {
    return this.totalHoursByClassification(
      day,
      TimesheetEntryClassification.SICK_TIME
    );
  }

  totalUnpaidHours(day: TimesheetRow) {
    return this.totalHoursByClassification(
      day,
      TimesheetEntryClassification.UNPAID
    );
  }

  totalHolidayHours(day: TimesheetRow) {
    const entries = _.filter(
      day.entries,
      entry => entry.classification === TimesheetEntryClassification.HOLIDAY
    );
    const total = entries.length * DAILY_HOURS;
    return total;
  }

  hoursByProjectAndRole(day: TimesheetRow, items: ProjectRoleHours[]) {
    return day.entries.reduce((items, entry) => {
      const { project, client, role, hours } = entry;
      const item = _.find(
        items,
        item => item.project.id === project.id && item.role.id === role.id
      );
      if (item) {
        item.hours += hours;
      } else {
        items.push({
          hours: hours,
          project: project,
          client: client,
          role: role
        });
      }
      return items;
    }, items);
  }
}

class TimesheetsUtil {
  private timesheetDayUtil: any;
  constructor() {
    this.timesheetDayUtil = new TimesheetDayUtil();
  }

  totalBill(timesheetDays: TimesheetRow[]) {
    if (timesheetDays === undefined || !timesheetDays) return 0;
    let total = 0;
    _.forEach(timesheetDays, (day: TimesheetRow) => {
      if (this.timesheetDayUtil.hasEntries(day)) {
        total += this.timesheetDayUtil.totalBillableHours(day);
      }
    });
    return total;
  }

  totalNonBill(timesheetDays: TimesheetRow[]) {
    if (timesheetDays === undefined || !timesheetDays) return 0;
    let total = 0;
    _.forEach(timesheetDays, (day: TimesheetRow) => {
      if (this.timesheetDayUtil.hasEntries(day)) {
        total += this.timesheetDayUtil.totalNonBillHours(day);
      }
    });
    return total;
  }

  totalPTO(timesheetDays: TimesheetRow[]) {
    let total = 0;
    _.forEach(timesheetDays, (day: TimesheetRow) => {
      total += this.timesheetDayUtil.totalPTOHours(day);
    });
    return total;
  }

  totalHoliday(timesheetDays: TimesheetRow[]) {
    let total = 0;
    _.forEach(timesheetDays, (day: TimesheetRow) => {
      total += this.timesheetDayUtil.totalHolidayHours(day);
    });
    return total;
  }

  totalSick(timesheetDays: TimesheetRow[]) {
    let total = 0;
    _.forEach(timesheetDays, (day: TimesheetRow) => {
      total += this.timesheetDayUtil.totalSickHours(day);
    });
    return total;
  }

  totalHoursWorked(timesheetDays: TimesheetRow[]) {
    return this.totalBill(timesheetDays) + this.totalNonBill(timesheetDays);
  }

  totalHours(
    timesheetDays: TimesheetRow[],
    employeeClassification: UserClassification
  ) {
    if (employeeClassification === UserClassification.CONTRACTOR) {
      return this.totalHoursWorked(timesheetDays);
    }

    return (
      this.totalHoursWorked(timesheetDays) +
      this.totalPTO(timesheetDays) +
      this.totalSick(timesheetDays) +
      this.totalHoliday(timesheetDays)
    );
  }

  averageWorkday(
    timesheetDays: TimesheetRow[],
    employeeClassification: UserClassification
  ) {
    const totalHours = this.totalHours(timesheetDays, employeeClassification);
    let daysWorked = 0;

    _.forEach(timesheetDays, (day: TimesheetRow) => {
      // this if avoids unpaid timeoff from calculation
      if (
        this.timesheetDayUtil.hasTimeWorked(day) ||
        this.timesheetDayUtil.hasTimeOff(day)
      ) {
        daysWorked += 1;
      }
    });
    return daysWorked > 0 ? totalHours / daysWorked : 0;
  }

  hoursByProjectAndRole(timesheetDays: TimesheetRow[]) {
    return timesheetDays.reduce((projects, day) => {
      return this.timesheetDayUtil.hoursByProjectAndRole(day, projects);
    }, []);
  }
}

export default TimesheetsUtil;
