import * as React from 'react';
import Button from './Button';
import Icon from './Icon';

export type ICursorPaginatorProps = {
  hasNextPage?: boolean;
  hasPrevPage?: boolean;
  onNextPage: () => any;
  onPrevPage: () => any;
};

export const CursorPaginator = (props: ICursorPaginatorProps) => {
  if (!props.hasPrevPage && !props.hasNextPage) return null;

  return (
    <>
      <Button
        variant="text"
        disabled={!props.hasPrevPage}
        onClick={props.onPrevPage}
      >
        <Icon name="ChevronLeft" />
        Prev
      </Button>
      <Button
        variant="text"
        disabled={!props.hasNextPage}
        onClick={props.onNextPage}
      >
        Next
        <Icon name="ChevronRight" />
      </Button>
    </>
  );
};

export default CursorPaginator;
