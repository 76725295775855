import React from 'react';
import Draggable from 'react-draggable';
import Paper, { PaperProps } from '@mui/material/Paper';
import DialogContent from '@mui/material/DialogContent';
import RejectTimesheetDialog from 'src/components/TimesheetRejectDialog';
import {
  ChartAndUtilization,
  DivisionLine,
  Header,
  UserTitle,
  DialogLoad,
  ConfirmationDialogContainer,
  StyledDialog,
  StyledDialogActions,
  ErrorMessage
} from './styles';
import {
  Title,
  ColumnFlexBox,
  StyledDialogContent
} from 'src/components/InvoiceDetail/InvoiceSidebar/InvoiceEntryDialog/styles';
import TimesheetUtilization from '../TimesheetUtilization';
import TimesheetUtilizationChart from '../../TimesheetUtilizationChart';
import EmployeeTimeOffPane from '../EmployeeTimeOffPane';
import { Avatar } from 'src/components/Avatar';
import UserTimesheetEntryPane from './TimesheetEntriesPane';
import TimesheetPeriod from './TimesheetPeriod';
import { formatDatePeriod } from 'src/shared/utils';
import _ from 'lodash';
import { CircularProgress } from '@mui/material';
import ConfirmationDialog from 'src/components/shared/ConfirmationDialog';
import Button from 'src/components/core/Button';
import {
  Timesheet,
  TimesheetRow,
  TimesheetSidebarDetail,
  TimesheetStatusEnum
} from 'src/api/types';
import { UpdateTimesheetEntryParams } from 'src/api/queries/updateTimesheetEntry';

const SCROLLING_FROM_BOTTOM = 50;

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

interface ITimesheetEntryDialog {
  canManageTimesheet: (timesheetManagerId: number) => boolean;
  closeTimesheetEntryDialog: () => void;
  approveTimesheet: (timesheet: Timesheet) => void;
  reopenTimesheet: (timesheet: Timesheet) => void;
  rejectTimesheet: (timesheet: Timesheet, reason: string) => void;
  updateTimesheetEntry: (id: number, data: UpdateTimesheetEntryParams) => void;
  timesheetSidebarDetail?: TimesheetSidebarDetail;
  requestTimesheetById: (timesheetId: number) => void;
  isLoading: boolean;
}

export default class TimesheetEntryDialog extends React.Component<
  ITimesheetEntryDialog,
  {
    value: boolean;
    timesheetRejectDialog: boolean;
    showApproveConfirmationModal: boolean;
    approveConfirmationModalOpen: boolean;
    reopenConfirmationModalOpen: boolean;
  }
> {
  constructor(props: ITimesheetEntryDialog) {
    super(props);

    this.state = {
      value: false,
      timesheetRejectDialog: false,
      showApproveConfirmationModal: true,
      approveConfirmationModalOpen: false,
      reopenConfirmationModalOpen: false
    };
    this.onClickClose = this.onClickClose.bind(this);
    this.onClickApprove = this.onClickApprove.bind(this);
    this.onClickReopen = this.onClickReopen.bind(this);
    this.openTimesheetRejectDialog = this.openTimesheetRejectDialog.bind(this);
    this.closeTimesheetRejectDialog = this.closeTimesheetRejectDialog.bind(
      this
    );
    this.rejectTimesheet = this.rejectTimesheet.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.approveTimesheet = this.approveTimesheet.bind(this);
    this.reopenTimesheet = this.reopenTimesheet.bind(this);
  }

  private handleScroll(e: React.BaseSyntheticEvent) {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <=
      e.target.clientHeight + SCROLLING_FROM_BOTTOM;
    if (bottom) this.setState({ showApproveConfirmationModal: false });
  }

  private onClickClose() {
    this.props.closeTimesheetEntryDialog();
  }

  private onClickApprove() {
    if (!this.props.timesheetSidebarDetail) return;

    const {
      timesheetSidebarDetail: { timesheet }
    } = this.props;
    const { showApproveConfirmationModal } = this.state;
    if (timesheet) {
      if (showApproveConfirmationModal) {
        this.setState({ approveConfirmationModalOpen: true });
      } else {
        this.approveTimesheet();
      }
    }
  }

  private onClickReopen() {
    if (!this.props.timesheetSidebarDetail) return;

    const {
      timesheetSidebarDetail: { timesheet }
    } = this.props;
    if (timesheet) {
      this.setState({ reopenConfirmationModalOpen: true });
    }
  }

  private approveTimesheet() {
    if (!this.props.timesheetSidebarDetail) return;

    const {
      timesheetSidebarDetail: { timesheet }
    } = this.props;
    if (timesheet) {
      this.props.approveTimesheet(timesheet);
      this.props.closeTimesheetEntryDialog();
    }
  }

  private reopenTimesheet() {
    if (!this.props.timesheetSidebarDetail) return;

    const {
      timesheetSidebarDetail: { timesheet }
    } = this.props;
    if (timesheet) {
      this.props.reopenTimesheet(timesheet);
      this.props.closeTimesheetEntryDialog();
    }
  }

  private rejectTimesheet(reason: string) {
    if (!this.props.timesheetSidebarDetail) return;

    const {
      timesheetSidebarDetail: { timesheet }
    } = this.props;
    if (timesheet) {
      this.closeTimesheetRejectDialog();
      this.props.closeTimesheetEntryDialog();
      this.props.rejectTimesheet(timesheet, reason);
    }
  }

  private openTimesheetRejectDialog() {
    this.setState({ timesheetRejectDialog: true });
  }

  private closeTimesheetRejectDialog() {
    this.setState({ timesheetRejectDialog: false });
  }

  private timesheetManagerId() {
    if (!this.props.timesheetSidebarDetail) return;

    return this.props.timesheetSidebarDetail.timesheet?.owner.manager.id;
  }

  render() {
    if (!this.props.timesheetSidebarDetail) return null;

    const timesheetManagerId = this.timesheetManagerId();
    const canManageTimesheet =
      timesheetManagerId && this.props.canManageTimesheet(timesheetManagerId);
    const {
      timesheetSidebarDetail,
      timesheetSidebarDetail: { timesheet }
    } = this.props;
    const { timesheetRejectDialog } = this.state;
    const height = 160;
    const width = 300;

    const timesheetDate = timesheet
      ? formatDatePeriod(timesheet.startDate, timesheet.endDate, true)
      : null;

    const {
      approveConfirmationModalOpen,
      reopenConfirmationModalOpen
    } = this.state;

    let buttons = (
      <div>
        <Button.OutlinedUnstyled onClick={this.onClickClose}>
          Cancel
        </Button.OutlinedUnstyled>
      </div>
    );

    if (timesheet && timesheet.status === TimesheetStatusEnum.TO_APPROVE) {
      buttons = (
        <div>
          {approveConfirmationModalOpen && (
            <ConfirmationDialogContainer>
              <ConfirmationDialog
                onConfirm={() => {
                  this.approveTimesheet();
                }}
                onCancel={() => {
                  this.setState({
                    approveConfirmationModalOpen: false
                  });
                }}
                title="Approve Timesheet?"
                description="Be sure to review the entire timesheet carefully before you approve it."
                confirmText="Yes, Approve"
                cancelText="No, I’ll Review"
              />
            </ConfirmationDialogContainer>
          )}
          {canManageTimesheet && (
            <Button
              sx={{ marginRight: '0.75rem' }}
              onClick={this.onClickApprove}
            >
              Approve
            </Button>
          )}
          <Button.OutlinedUnstyled
            sx={{ marginRight: '0.75rem' }}
            onClick={this.onClickClose}
          >
            Cancel
          </Button.OutlinedUnstyled>
          {canManageTimesheet && (
            <Button.TextError onClick={this.openTimesheetRejectDialog}>
              Reject
            </Button.TextError>
          )}
        </div>
      );
    } else if (timesheet && timesheet.status === TimesheetStatusEnum.APPROVED) {
      buttons = (
        <div>
          {reopenConfirmationModalOpen && (
            <ConfirmationDialogContainer>
              <ConfirmationDialog
                onConfirm={() => {
                  this.reopenTimesheet();
                }}
                onCancel={() => {
                  this.setState({
                    reopenConfirmationModalOpen: false
                  });
                }}
                title="Reopen Timesheet?"
                description="Are you sure you want to reopen this timesheet?"
                confirmText="Yes, Reopen"
                cancelText="No, Cancel"
              />
            </ConfirmationDialogContainer>
          )}
          <Button
            sx={{ marginRight: '.75rem' }}
            disabled={!timesheet.canReopen}
            onClick={this.onClickReopen}
          >
            Reopen
          </Button>
          <Button.OutlinedUnstyled onClick={this.onClickClose}>
            Cancel
          </Button.OutlinedUnstyled>
          {!timesheet.canReopen && (
            <ErrorMessage>
              This timesheet is associated with one or more invoices and cannot
              be reopened.
            </ErrorMessage>
          )}
        </div>
      );
    }

    return (
      <div>
        <div>
          <RejectTimesheetDialog
            open={timesheetRejectDialog}
            onClose={this.closeTimesheetRejectDialog}
            onRejectTimesheet={this.rejectTimesheet}
          />
          {!timesheetRejectDialog && (
            <React.Fragment>
              <StyledDialog
                open
                PaperComponent={PaperComponent}
                maxWidth="md"
                fullWidth
                componentsProps={{
                  backdrop: {
                    style: {
                      backgroundColor: 'rgba(0, 0, 0, 0.3)'
                    }
                  }
                }}
              >
                {this.props.isLoading ? (
                  <DialogLoad>
                    <CircularProgress />
                  </DialogLoad>
                ) : (
                  <DialogContent onScroll={this.handleScroll}>
                    {timesheetSidebarDetail && timesheet && (
                      <React.Fragment>
                        <Header
                          role="div"
                          style={{ cursor: 'move' }}
                          id="draggable-dialog-title"
                        >
                          <UserTitle>
                            <Avatar name={timesheet.owner.fullName} />
                            <Title>{timesheet.owner.fullName}</Title>
                          </UserTitle>
                          <TimesheetPeriod
                            timesheetDate={String(timesheetDate)}
                            requestTimesheetById={
                              this.props.requestTimesheetById
                            }
                            previousId={timesheet.previousId || null}
                            nextId={timesheet.nextId || null}
                          />
                        </Header>
                      </React.Fragment>
                    )}
                    <StyledDialogContent>
                      {timesheet && (
                        <ChartAndUtilization>
                          <EmployeeTimeOffPane
                            employeeClassification={
                              timesheet.owner.classification
                            }
                            rows={timesheet.timesheetDays}
                            noPadding={true}
                          />
                          <TimesheetUtilizationChart
                            rows={timesheet.timesheetDays}
                            width={width}
                            height={height}
                          />
                          <DivisionLine />
                          <TimesheetUtilization timesheet={timesheet} />
                        </ChartAndUtilization>
                      )}
                      <ColumnFlexBox>
                        {this.renderUserTimesheetEntries()}
                      </ColumnFlexBox>
                    </StyledDialogContent>
                  </DialogContent>
                )}
                <StyledDialogActions>{buttons}</StyledDialogActions>
              </StyledDialog>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  private renderUserTimesheetEntries() {
    if (!this.props.timesheetSidebarDetail) return null;

    const { timesheetSidebarDetail } = this.props;
    const { userProjectRoles } = timesheetSidebarDetail;
    const { timesheet } = timesheetSidebarDetail;

    return (
      <>
        {timesheet
          ? timesheet.timesheetDays.map((item: TimesheetRow, index: number) => (
              <UserTimesheetEntryPane
                canChangeDeliverable={_.includes(
                  [TimesheetStatusEnum.TO_APPROVE, TimesheetStatusEnum.NEW],
                  timesheet.status
                )}
                key={index}
                date={String(item.date)}
                entries={item.entries}
                userProjectRoles={userProjectRoles}
                updateTimesheetEntry={this.props.updateTimesheetEntry}
                holiday={item.holiday}
                showEntriesDefault={true}
              />
            ))
          : null}
      </>
    );
  }
}
