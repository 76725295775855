import React, { useState } from 'react';
import { partition } from 'lodash';
import { ICurrentUser } from 'src/shared/auth/types';
import {
  ENoteType,
  IInvoiceSidebarDetail,
  INote
} from 'src/shared/invoice-list/types';
import InvoiceNote from 'src/components/InvoiceList/InvoiceListSidebar/InformationPane/InvoiceNotes/InvoiceNote';
import AddInstructions from './AddInstructions';
import {
  AddInstructionsButton,
  AddInstructionsIcon,
  Header,
  NoteHeader,
  NoteText,
  StyledComment
} from './styles';

interface IProps {
  canEdit: boolean;
  currentUser: ICurrentUser;
  invoiceSidebarDetail: IInvoiceSidebarDetail;
  onCreateInstruction: (instruction: string) => void;
  onDeleteNote: (id: number) => void;
  onUpdateNote: ({ text, id }: { text: string; id: number }) => void;
}

export default function SpecialInstructions({
  canEdit,
  currentUser,
  invoiceSidebarDetail,
  onCreateInstruction,
  onDeleteNote,
  onUpdateNote
}: IProps) {
  const [addInputOpen, setAddInputOpen] = useState(false);
  const { notes } = invoiceSidebarDetail;
  const [invoiceNotes, otherNotes] = partition(notes, 'userName');
  const specialInstructionNotes = invoiceNotes.filter(
    (note: INote) => note.typeOf === ENoteType.INVOICE_SPECIAL_INSTRUCTION
  );

  const handleAddInstructionSave = (instruction: string) => {
    onCreateInstruction(instruction);
    setAddInputOpen(false);
  };

  return (
    <div>
      <Header>
        <StyledComment />
        <h4 className="section-header">Special Instructions</h4>
      </Header>
      <NoteHeader>Invoice</NoteHeader>
      {specialInstructionNotes.length > 0 ? (
        specialInstructionNotes.map((note, i) => (
          <InvoiceNote
            key={'invoice_note_' + i}
            note={note}
            deleteInvoiceNote={({ id }) => onDeleteNote(id)}
            updateInvoiceNote={({ text, id }) => onUpdateNote({ text, id })}
            currentUser={currentUser}
          />
        ))
      ) : (
        <NoteText>--</NoteText>
      )}
      {addInputOpen && (
        <AddInstructions
          onCancel={() => setAddInputOpen(false)}
          onSave={instruction => handleAddInstructionSave(instruction)}
        />
      )}
      {!addInputOpen && canEdit && (
        <AddInstructionsButton onClick={() => setAddInputOpen(true)}>
          <AddInstructionsIcon />
          <span>Instruction</span>
        </AddInstructionsButton>
      )}

      <NoteHeader>Other</NoteHeader>
      {otherNotes.length > 0 ? (
        otherNotes.map((note, i) => (
          <NoteText key={'other_note_' + i}>{note.text}</NoteText>
        ))
      ) : (
        <NoteText>--</NoteText>
      )}
    </div>
  );
}
