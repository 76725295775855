import _ from 'lodash';
import * as invoiceActions from './actions';
import {
  IInvoiceListReducerState,
  InvoiceStartDate,
  ALL_MANAGERS,
  ALL_CLIENTS,
  SAVED_AND_REJECTED,
  MY_INVOICES,
  INote
} from './types';
import { InvoiceStatus } from 'src/shared/invoice-list/types';

export const initialInvoiceSidebarDetail = {
  deliverables: [],
  users: [],
  projectStats: {},
  invoices: [],
  projectEmails: [],
  totalInvoices: 0,
  notes: [],
  billableHours: 0,
  nonBillableHours: 0
};

const INITIAL_INVOICE_LIST_FILTERS = {
  status: SAVED_AND_REJECTED,
  startDate: InvoiceStartDate.ALL,
  projectManager: ALL_MANAGERS,
  client: ALL_CLIENTS,
  invoices: MY_INVOICES,
  sortBy: 'DATE',
  sortDirection: 'DESC'
};

const initialState: IInvoiceListReducerState = {
  invoicesByProjectId: [],
  invoices: [],
  filters: INITIAL_INVOICE_LIST_FILTERS,
  selectedInvoice: null,
  total: 0,
  page: 0,
  counts: {
    uninvoiced: 0,
    saved: 0,
    submitted: 0,
    approved: 0,
    sent: 0
  },
  projects: [],
  activeProjects: [],
  rightPanelOpen: false,
  invoiceSidebarDetail: initialInvoiceSidebarDetail,
  loading: false,
  showFilters: true
};

const reducer = (
  state: IInvoiceListReducerState = initialState,
  action: invoiceActions.Actions
) => {
  switch (action.type) {
    case invoiceActions.CHANGE_INVOICE_STATUS: {
      const { id, status } = action.payload;

      const invoicesCopy = [...state.invoices];
      let updatedInvoices;

      if (status === InvoiceStatus.DISMISSED) {
        updatedInvoices = invoicesCopy.filter(invoice => invoice.id !== id);
      } else {
        updatedInvoices = invoicesCopy.map(invoice => {
          if (invoice.id === id) {
            return {
              ...invoice,
              status
            };
          }

          return invoice;
        });
      }

      return {
        ...state,
        invoices: updatedInvoices
      };
    }
    case invoiceActions.GET_INVOICES_BY_PROJECT_ID_SUCCEDED: {
      return {
        ...state,
        invoicesByProjectId: [...action.payload.invoicesByProjectId],
        loading: false
      };
    }
    case invoiceActions.FETCH_INVOICES_SUCCEEDED: {
      return {
        ...state,
        invoices: action.payload.invoices,
        total: action.payload.total,
        offset: action.payload.offset,
        loading: false
      };
    }
    case invoiceActions.RESET_FILTER: {
      return {
        ...state,
        loading: true,
        filters: INITIAL_INVOICE_LIST_FILTERS,
        showFilters: true,
        projects: []
      };
    }
    case invoiceActions.DISABLE_FILTERS: {
      return {
        ...state,
        showFilters: false
      };
    }
    case invoiceActions.CHANGE_INVOICE_FILTER: {
      return {
        ...state,
        loading: true,
        filters: {
          ...state.filters,
          [action.payload.key]: action.payload.value
        }
      };
    }
    case invoiceActions.SELECT_INVOICE: {
      const { invoice } = action.payload;
      return {
        ...state,
        selectedInvoice: invoice
      };
    }
    case invoiceActions.CLEAR_INVOICE_DETAIL: {
      return {
        ...state,
        selectedInvoice: null
      };
    }
    case invoiceActions.POPULATE_COUNTS: {
      return {
        ...state,
        counts: {
          ...state.counts,
          ...action.payload
        }
      };
    }
    case invoiceActions.POPULATE_PROJECTS_ON_INVOICE_VIEW: {
      return {
        ...state,
        projects: action.payload
      };
    }
    case invoiceActions.POPULATE_ACTIVE_PROJECTS_ON_INVOICE_VIEW: {
      return {
        ...state,
        activeProjects: action.payload
      };
    }

    case invoiceActions.TOGGLE_RIGHT_PANEL: {
      return {
        ...state,
        rightPanelOpen: !state.rightPanelOpen
      };
    }

    case invoiceActions.FETCH_SIDEBAR_DETAIL: {
      return {
        ...state,
        invoiceSidebarDetail: action.payload.hideLoading
          ? state.invoiceSidebarDetail
          : initialInvoiceSidebarDetail,
        loading: true
      };
    }

    case invoiceActions.POPULATE_SIDEBAR_DETAIL: {
      return {
        ...state,
        invoiceSidebarDetail: action.payload,
        loading: false
      };
    }

    case invoiceActions.CONCAT_INVOICES: {
      return {
        ...state,
        invoiceSidebarDetail: {
          ...state.invoiceSidebarDetail,
          invoices: state.invoiceSidebarDetail.invoices.concat(action.payload)
        }
      };
    }

    case invoiceActions.PUSH_NOTE_TO_SIDEBAR: {
      return {
        ...state,
        invoiceSidebarDetail: {
          ...state.invoiceSidebarDetail,
          notes: state.invoiceSidebarDetail.notes.concat(action.payload)
        }
      };
    }

    case invoiceActions.DELETE_INVOICE_NOTE: {
      return {
        ...state,
        invoiceSidebarDetail: {
          ...state.invoiceSidebarDetail,
          notes: state.invoiceSidebarDetail.notes.filter(
            (note: INote) => note.id !== action.payload.id
          )
        }
      };
    }

    case invoiceActions.UPDATE_INVOICE_NOTE: {
      return {
        ...state,
        invoiceSidebarDetail: {
          ...state.invoiceSidebarDetail,
          notes: state.invoiceSidebarDetail.notes.map((note: INote) =>
            note.id !== action.payload.id
              ? note
              : { ...note, text: action.payload.text }
          )
        }
      };
    }

    default:
      return {
        ...state
      };
  }
};

export default reducer;
