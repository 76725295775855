import { normalize, schema } from 'normalizr';

import {
  INormalizedRole,
  INormalizedUser,
  INotificationPreference,
  IRole,
  IUserEntities,
  IUserResponse
} from './types';

const role = new schema.Entity('roles');
const roleList = [role];
const user = new schema.Entity(
  'users',
  { roles: roleList },
  {
    processStrategy: entity => {
      if (!entity.notificationPreferences) {
        return { ...entity };
      }
      const notificationPreferences = entity.notificationPreferences.map(
        (preference: INotificationPreference): string => {
          return preference.notificationMethod.toLowerCase();
        }
      );
      return { ...entity, notificationPreferences };
    }
  }
);
const userList = [user];

export const usersNormalizer = (userData: IUserResponse[]): IUserEntities =>
  normalize(userData, userList);
export const userNormalizer = (userData: IUserResponse): INormalizedUser =>
  normalize(userData, user);

const manager = new schema.Entity('managers');
const managerList = [manager];

export const managersNormalizer = (userData: IUserResponse[]): IUserEntities =>
  normalize(userData, managerList);

export const rolesNormalizer = (rolesData: IRole[]): INormalizedRole =>
  normalize(rolesData, roleList);
