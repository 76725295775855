import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';

export const approveTimesheet = (id: number) =>
  callAPI({
    method: 'post',
    url: `/timesheets/approve/${id}`
  })
    .then(response => response.data)
    .catch(error => {
      toast('Failed to approve timesheet', 'error');
      throw error;
    });
