import { Box } from '@mui/material';
import { StatusBox } from '../InvoiceList/InvoiceListTable/styles';
import styled from 'src/theme';

export const FlexBox = styled(Box)`
  display: flex;
  flex: 2;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  padding-bottom: 50px;
  padding-top: 20px;
  padding-right: 20px;
  background: white;
`;

export const FlexBoxJustifyEnd = styled(FlexBox)`
  justify-content: flex-end;
`;

export const StyledStatusBox = styled(StatusBox)`
  && {
    color: rgba(33, 33, 33, 0.87);
    font-size: 12px;
    font-weight: 600;
    margin-left: 28px;
  }
`;
