import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { Department } from '../types';

export const fetchDepartments = () =>
  callAPI<{ departments: Department[] }>({
    url: '/department',
    method: 'get'
  })
    .then(response => response.data.departments)
    .catch(error => {
      toast('Failed to fetch departments', 'error');
      throw error;
    });
