import React, { FunctionComponent, useState } from 'react';
import _ from 'lodash';
import { Tooltip } from '@mui/material';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import Restore from '@mui/icons-material/Restore';
import SortIcon from '@mui/icons-material/ImportExport';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Flag from '@mui/icons-material/Flag';
import {
  FlexGridItem,
  VerticalFlexGridItem,
  DeliverableName,
  RoleName,
  FlexGridItemHeader,
  FlexGridItemHeaderJustifyStart,
  FlexGridItemHeaderJustifyEnd,
  PaddingLeft16,
  StyledRow,
  FlexGridItemJustifyStart,
  FlexGridItemJustifyEnd,
  TealSpan,
  Italic,
  StyledGrid
} from './styles';

import Avatar from 'src/components/Avatar';
import { ITimesheetRow } from 'src/shared/invoice/types';
import { formatAsCurrency, isDoubleClick, sizeQuery } from 'src/shared/utils';
import { Deliverable, ProjectRole } from 'src/api/types';

interface ITimesheetRowTableProps {
  data: ITimesheetRow[];
  dismissItem: (item: ITimesheetRow) => void;
  deliverables: Deliverable[];
  projectRoles: ProjectRole[];
  canEdit: boolean;
  selectedItem: number;
  setSelectedItem: (index: number) => void;
  sendOverdueTimesheetNotification: (
    managerId: number,
    timesheetId: number
  ) => any;
  onDoubleClick: () => void;
  sidebarOpen: boolean;
}

interface IInvoiceDetailTableState {
  deliverables: Deliverable[];
  alertedTimesheetIds: number[];
  sorter?: string;
  direction: number;
}

export const TimesheetRowTable: FunctionComponent<ITimesheetRowTableProps> = props => {
  const [state, setState] = useState<IInvoiceDetailTableState>({
    deliverables: props.deliverables || [],
    alertedTimesheetIds: [],
    direction: 1
  });

  const rowClassName = (idx: number) => {
    const { selectedItem: expandedRow } = props;
    const { data } = props;
    let className = `row row-${idx}`;
    if (!data[idx]) {
      return className;
    }
    if (expandedRow > -1 && data[expandedRow]) {
      if (idx === expandedRow) {
        className = className.concat(' active');
      }
    }
    if (data[idx].modified) {
      className = className.concat(' modified');
    }
    return className;
  };

  const sort = (sorter: string) => {
    setState({ ...state, sorter, direction: state.direction * -1 });
  };

  const handleRowClick = (idx: number) => {
    if (isDoubleClick()) {
      return handleRowDoubleClick();
    }
    return props.setSelectedItem(idx);
  };

  const handleRowDoubleClick = () => {
    props.onDoubleClick();
  };

  const { data, deliverables, projectRoles, canEdit, sidebarOpen } = props;
  const { sorter = 'lastName', direction } = state;
  const displayAvatar = !(sizeQuery.isTablet() && sidebarOpen);

  return (
    <StyledGrid container className={`${displayAvatar ? '' : 'reduce-font'}`}>
      <FlexGridItemHeaderJustifyStart
        item
        xs={3}
        onClick={() => sort('lastName')}
        className="sortable"
      >
        <PaddingLeft16>Employee</PaddingLeft16>
        {sorter && sorter === 'lastName' ? (
          direction > 0 ? (
            <ArrowDownward />
          ) : (
            <ArrowUpward />
          )
        ) : (
          <SortIcon />
        )}
      </FlexGridItemHeaderJustifyStart>
      <FlexGridItemHeaderJustifyStart
        item
        xs={2}
        className="sortable"
        onClick={() => sort('deliverable')}
      >
        Deliverable/Role
        {sorter && sorter === 'deliverable' ? (
          direction > 0 ? (
            <ArrowDownward />
          ) : (
            <ArrowUpward />
          )
        ) : (
          <SortIcon />
        )}
      </FlexGridItemHeaderJustifyStart>
      <FlexGridItemHeaderJustifyEnd item xs={2}>
        Invoice Hr
      </FlexGridItemHeaderJustifyEnd>
      <FlexGridItemHeaderJustifyEnd item xs={2}>
        Amount
      </FlexGridItemHeaderJustifyEnd>
      <FlexGridItemHeader item xs={2}></FlexGridItemHeader>
      {data &&
        _(data)
          .sort((e: ITimesheetRow, i: ITimesheetRow) => {
            if (sorter) {
              if (sorter === 'deliverable') {
                const deliverable1 = _.find(deliverables, (del: Deliverable) =>
                  _.eq(e.deliverableId, del.id)
                ) || { name: 'unknown' };
                const deliverable2 = _.find(deliverables, (del: Deliverable) =>
                  _.eq(i.deliverableId, del.id)
                ) || { name: 'unknown' };
                return deliverable1.name > deliverable2.name
                  ? direction
                  : direction * -1;
              }
              return e[sorter] > i[sorter] ? direction : direction * -1;
            }
            return 0;
          })
          .map((item: ITimesheetRow, idx: number) => {
            const deliverable = _.find(deliverables, (del: Deliverable) =>
              _.eq(item.deliverableId, del.id)
            ) || { name: 'unknown' };
            const projectRole = _.find(projectRoles, (pRole: ProjectRole) =>
              _.eq(item.projectRoleId, pRole.id)
            ) || { name: 'unknown' };
            return (
              <StyledRow
                container
                onClick={() => handleRowClick(idx)}
                className={rowClassName(idx)}
                key={`${item.id} - ${deliverable.name} - ${projectRole.name}`}
                onDoubleClick={handleRowDoubleClick}
              >
                <FlexGridItemJustifyStart item xs={3} className="name-item">
                  {displayAvatar && (
                    <Avatar name={item.fullName} backgroundColor="#005662" />
                  )}
                  <TealSpan>{item.fullName}</TealSpan>
                </FlexGridItemJustifyStart>
                <VerticalFlexGridItem item xs={2}>
                  <DeliverableName>{deliverable.name}</DeliverableName>
                  <RoleName>{projectRole.name}</RoleName>
                </VerticalFlexGridItem>
                <FlexGridItemJustifyEnd item xs={2}>
                  {item.invoiceHours.toFixed(2)}
                </FlexGridItemJustifyEnd>
                <FlexGridItemJustifyEnd item xs={2}>
                  {formatAsCurrency(item.total)}
                </FlexGridItemJustifyEnd>
                <FlexGridItem item xs={2}>
                  {canEdit && !item.dismissed && (
                    <RemoveCircle
                      onClick={() => props.dismissItem(item)}
                      className="dismiss"
                    />
                  )}
                  {item.dismissed && (
                    <Restore
                      onClick={() => props.dismissItem(item)}
                      className="restore"
                    />
                  )}
                  {item.dismissed && <Italic>Dismissed</Italic>}

                  {!item.timesheetApproval && (
                    <Tooltip title="Send Manager an email to approve timesheet">
                      <Flag
                        onClick={() => {
                          props.sendOverdueTimesheetNotification(
                            item.managerId,
                            item.timesheetId
                          );
                        }}
                        className="contact"
                      />
                    </Tooltip>
                  )}
                </FlexGridItem>
              </StyledRow>
            );
          })
          .value()}
    </StyledGrid>
  );
};

export default TimesheetRowTable;
