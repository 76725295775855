import * as React from 'react';
import _ from 'lodash';
import {
  FlexStartBox,
  NavTab,
  Count,
  SubNavInvoiceWrapper,
  NavTabsContainer,
  ClientText
} from './styles';

import {
  SAVED_AND_REJECTED,
  SUBMITTED,
  APPROVED,
  MY_INVOICES,
  ALL_INVOICES,
  PENDING,
  SENT,
  PAID
} from 'src/shared/invoice-list/types';
import { InvoiceCounts } from 'src/api/types';
interface IInvoiceSubNav {
  counts?: InvoiceCounts;
  onClickStatusFilter: (filter: string) => void;
  onClickInvoicesFilter: (filter: string) => void;
  currentStatus?: string;
  currentInvoicesFilter?: string;
  isAdmin?: boolean;
  leftDrawerIsOpen: boolean;
  showFilters: boolean;
  clientElement?: JSX.Element;
}

export const InvoiceSubNav = ({
  counts,
  onClickStatusFilter,
  onClickInvoicesFilter,
  currentStatus,
  currentInvoicesFilter,
  isAdmin,
  leftDrawerIsOpen,
  showFilters,
  clientElement
}: IInvoiceSubNav) => {
  type NavHeaderItem = {
    title: string;
    count: number;
    value: string;
  };
  const subStatusNavList: NavHeaderItem[] = [
    {
      title: 'Pending',
      count: counts?.uninvoiced || 0,
      value: PENDING
    },
    {
      title: 'Created',
      count: counts?.new || 0,
      value: SAVED_AND_REJECTED
    },
    {
      title: 'Submitted',
      count: counts?.submitted || 0,
      value: SUBMITTED
    },
    {
      title: 'Approved',
      count: counts?.approved || 0,
      value: APPROVED
    },
    {
      title: 'Sent',
      count: counts?.sent || 0,
      value: SENT
    },
    {
      title: 'Paid',
      count: counts?.paid || 0,
      value: PAID
    }
  ];

  return (
    <SubNavInvoiceWrapper
      drawerClosed={!leftDrawerIsOpen}
      className={leftDrawerIsOpen ? '' : 'left-drawer-closed'}
    >
      {showFilters && (
        <FlexStartBox>
          {isAdmin && (
            <NavTabsContainer>
              <NavTab
                className={
                  _.eq(MY_INVOICES, currentInvoicesFilter) ? 'active' : ''
                }
                key={'myInvoices'}
                onClick={() => onClickInvoicesFilter(MY_INVOICES)}
              >
                My invoices
              </NavTab>
              <NavTab
                className={
                  _.eq(ALL_INVOICES, currentInvoicesFilter) ? 'active' : ''
                }
                key={'allInvoices'}
                onClick={() => onClickInvoicesFilter(ALL_INVOICES)}
              >
                All invoices
              </NavTab>
            </NavTabsContainer>
          )}
          <NavTabsContainer>
            {_.map(subStatusNavList, (item: NavHeaderItem, index: number) => {
              return (
                <NavTab
                  className={_.eq(item.value, currentStatus) ? 'active' : ''}
                  key={index}
                  onClick={() => onClickStatusFilter(item.value)}
                >
                  <div>{item.title}</div>
                  &nbsp;
                  <Count>{item.count}</Count>
                </NavTab>
              );
            })}
          </NavTabsContainer>
        </FlexStartBox>
      )}
      {!showFilters && (
        <FlexStartBox>
          <ClientText>Showing results for {clientElement}</ClientText>
        </FlexStartBox>
      )}
    </SubNavInvoiceWrapper>
  );
};

export default InvoiceSubNav;
