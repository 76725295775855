import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { UserProjectRole } from '../types';

export const updateUserProjectRole = ({
  id,
  deliverableId,
  projectRoleId,
  isVisible
}: {
  id: number;
  deliverableId?: number;
  projectRoleId?: number;
  isVisible?: boolean;
}) =>
  callAPI<UserProjectRole>({
    data: {
      deliverableId,
      projectRoleId,
      isVisible
    },
    method: 'put',
    url: `/user_project_prefs/${id}`
  })
    .then(response => response.data)
    .catch(error => {
      toast('Failed to update user project role', 'error');
      throw error;
    });
