import MenuItem from '@mui/material/MenuItem';
import DeleteSearch from '@mui/icons-material/Close';
import * as React from 'react';
import messages from 'src/messages';
import { Container, CustomFab, CustomInput, InnerContainer } from './styles';

interface IUserListFilters {
  clearFilters: () => void;
  roles?: any;
  name?: string | number;
  noFilter?: boolean;
  selectedRole?: string | number;
  status?: string | number;
  onChange: (prop: string) => (event: any) => void;
}

const UserListFilters: React.FC<IUserListFilters> = ({
  clearFilters,
  roles = {},
  noFilter = true,
  status = 'any',
  selectedRole = 'any',
  name = '',
  onChange
}) => (
  <Container>
    <InnerContainer>
      <CustomInput
        id="status-select"
        select={true}
        label="Display"
        value={status}
        onChange={onChange('status')}
        margin="normal"
        variant="outlined"
      >
        <MenuItem key={'any'} value={'any'}>
          {messages.ANY}
        </MenuItem>
        <MenuItem key={'active'} value={0}>
          {messages.ACTIVE}
        </MenuItem>
        <MenuItem key={'disabled'} value={1}>
          {messages.DISABLED}
        </MenuItem>
      </CustomInput>
      <CustomInput
        id="role-select"
        select={true}
        onChange={onChange('selectedRole')}
        label={messages.WHO_ARE}
        value={selectedRole}
        margin="normal"
        variant="outlined"
      >
        <MenuItem key={'any'} value={'any'}>
          {messages.ANY}
        </MenuItem>
        {Object.keys(roles).map(roleKey => {
          const role = roles[roleKey];
          if (role.isActive) {
            return (
              <MenuItem key={role.id} value={role.id}>
                {role.name}
              </MenuItem>
            );
          }
          return null;
        })}
      </CustomInput>
      <CustomInput
        id="name-input"
        onChange={onChange('name')}
        label="Name"
        value={name}
        margin="normal"
        variant="outlined"
      />
      <CustomFab
        onClick={clearFilters}
        disabled={noFilter}
        color="primary"
        size="small"
        aria-label="Delete search"
      >
        <DeleteSearch />
      </CustomFab>
    </InnerContainer>
  </Container>
);

export default UserListFilters;
