import React, { FunctionComponent } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { SubNavWrapper } from 'src/components/SubNav/styles';
import { InvoiceStatus } from 'src/shared/invoice-list/types';
import {
  BoxLabel,
  BoxValue,
  ColumnFlexBox,
  InstructionsBoxValue,
  InvoiceDetailsButton,
  JustifyEndFlexBox,
  ProjectManagerBoxValue,
  RightOnlyBorderColumnFlexBox,
  SubNavContent,
  ViewButton
} from './styles';
import Icon from 'src/components/core/Icon';
import { Box } from 'src/components/core/Box';

interface IInvoiceDetailSubNav {
  amount?: string;
  children: any;
  billableHours?: number;
  hasInstructions: boolean;
  invoiceHours?: number;
  isNew: boolean;
  leftDrawerIsOpen: boolean;
  nonBillableHours?: number;
  onInvoiceDetailsClick?: () => void;
  projectManager?: string;
  status?: InvoiceStatus;
}

export const InvoiceDetailSubNav: FunctionComponent<IInvoiceDetailSubNav> = props => {
  const {
    amount = 0,
    children,
    hasInstructions,
    invoiceHours = 0,
    isNew,
    leftDrawerIsOpen,
    onInvoiceDetailsClick,
    projectManager
  } = props;

  return (
    <SubNavWrapper
      className={!leftDrawerIsOpen ? 'left-drawer-closed' : ''}
      drawerClosed={!leftDrawerIsOpen}
    >
      <SubNavContent>
        <ColumnFlexBox>
          <BoxValue>{amount}</BoxValue>
          <BoxLabel>Invoice Amount</BoxLabel>
        </ColumnFlexBox>
        <RightOnlyBorderColumnFlexBox>
          <BoxValue>{invoiceHours}</BoxValue>
          <BoxLabel>Invoice Hr</BoxLabel>
        </RightOnlyBorderColumnFlexBox>
        <RightOnlyBorderColumnFlexBox>
          <ProjectManagerBoxValue>
            {projectManager || '--'}
          </ProjectManagerBoxValue>
          <BoxLabel>Project Manager</BoxLabel>
        </RightOnlyBorderColumnFlexBox>
        <ColumnFlexBox>
          {hasInstructions && (
            <ViewButton onClick={onInvoiceDetailsClick}>
              <Icon name="Comment" fontSize="small" />
              <Box marginLeft="6px" fontSize="14px">
                View
              </Box>
            </ViewButton>
          )}
          {!hasInstructions && <InstructionsBoxValue>--</InstructionsBoxValue>}
          <BoxLabel>Invoice Instructions</BoxLabel>
        </ColumnFlexBox>
      </SubNavContent>
      <JustifyEndFlexBox>
        {!isNew && (
          <InvoiceDetailsButton onClick={onInvoiceDetailsClick}>
            <span>Invoice Details</span>
            <InfoIcon className="info-icon" />
          </InvoiceDetailsButton>
        )}
      </JustifyEndFlexBox>
      {children}
    </SubNavWrapper>
  );
};

export default InvoiceDetailSubNav;
