import * as React from 'react';
import { Tooltip as MTooltip, TooltipProps } from '@mui/material';

let title: TooltipProps['title'];
let placement: TooltipProps['placement'];
let sx: TooltipProps['sx'];

export interface ITooltipProps
  extends Pick<
    TooltipProps,
    'className' | 'children' | 'id' | 'onClick' | 'onClose' | 'onOpen'
  > {
  placement?: typeof placement;
  title: typeof title;
  sx?: typeof sx;
}

export const Tooltip = ({
  placement = 'bottom',
  children,
  ...props
}: ITooltipProps) => {
  return (
    <MTooltip placement={placement} {...props}>
      {children}
    </MTooltip>
  );
};

export default Tooltip;
