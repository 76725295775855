import * as _ from 'lodash';
import Add from '@mui/icons-material/Add';
import GoodIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/ErrorRounded';
import BadIcon from '@mui/icons-material/RemoveCircle';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { CircularProgress } from '@mui/material';
import * as React from 'react';
import {
  AutoSizer,
  Column,
  TableRowProps,
  TableCellProps,
  RowMouseEventHandlerParams
} from 'react-virtualized';
import MomentPopper from 'src/components/shared/MomentPopper';
import { AddFab, ClientIcon, Container, CustomTable } from './styles';
import styled from '../../theme';
import { FunctionComponent, useEffect, useState } from 'react';
import { EMetric, EVal, Project } from 'src/api/types';

const LoadingPanel = styled.div`
  height: 100%;
  width: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IProjectsList {
  projects: Project[];
  onAddClick: () => void;
  goToDetail: (event: RowMouseEventHandlerParams) => void;
  selectedItem: Project | null;
  changeMetric: (event: any) => void;
  loading: boolean;
}

interface ProjectsListState {
  modal: boolean;
  anchorEl?: any;
  currentMetricItem?: TableCellProps | null;
  loadingMetricItem?: TableCellProps | null;
}
const iconColors = {
  ok: '#48CF77',
  warning: '#FFC361',
  danger: '#FF4066'
};

export const ProjectsList: FunctionComponent<IProjectsList> = props => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [state, setState] = useState<ProjectsListState>({ modal: false });

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }

    setState({ ...state, loadingMetricItem: null });
  }, [props.projects]);

  const rowClassName = (event: { index: number }) => {
    if (props.selectedItem && props.projects[event.index]) {
      if (props.projects[event.index].id === props.selectedItem.id) {
        return 'active row';
      }
    }
    return 'row';
  };

  const rowGetter = (event: TableRowProps) => {
    return props.projects[event.index];
  };

  const renderClientIcon = (event: TableCellProps) => {
    const data = event.cellData;
    if (!data) {
      return <ClientIcon color="black" />;
    }

    return <ClientIcon color={data} />;
  };

  const openModal = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: TableCellProps
  ) => {
    setState({
      ...state,
      modal: true,
      anchorEl: event.currentTarget,
      currentMetricItem: item
    });
  };

  const closeModal = (item?: TableCellProps) => {
    if (state.modal && _.isEqual(item, state.currentMetricItem)) {
      setState({
        ...state,
        modal: false,
        anchorEl: null,
        currentMetricItem: null
      });
    }
  };

  const onStatusSelect = (status: EVal, e: React.MouseEvent) => {
    e.stopPropagation();
    setStatus(status);
  };

  const setStatus = (status: EVal) => {
    const { currentMetricItem } = state;
    if (currentMetricItem) {
      setState({ ...state, loadingMetricItem: currentMetricItem });
      props.changeMetric({
        metric: currentMetricItem.dataKey,
        status,
        projectId: currentMetricItem.rowData.id
      });
    }
  };

  const getStatIcon = (cell: TableCellProps) => {
    const { currentMetricItem, loadingMetricItem } = state;
    const data: EVal | undefined = cell.cellData;
    const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      openModal(e, cell);
    };

    if (
      loadingMetricItem &&
      _.eq(cell.dataKey, loadingMetricItem.dataKey) &&
      _.eq(cell.rowIndex, loadingMetricItem.rowIndex)
    ) {
      return <CircularProgress size="1em" />;
    }

    let icon = <GoodIcon htmlColor={iconColors.ok}></GoodIcon>;
    if (_.eq(data, EVal.WARNING)) {
      icon = <WarningIcon htmlColor={iconColors.warning} />;
    } else if (_.eq(data, EVal.BAD)) {
      icon = <BadIcon htmlColor={iconColors.danger} />;
    }
    const iconWithProps = React.cloneElement(icon, {
      fontSize: 'small',
      onClick
    });

    if (
      currentMetricItem &&
      _.eq(cell.dataKey, currentMetricItem.dataKey) &&
      _.eq(cell.rowIndex, currentMetricItem.rowIndex)
    ) {
      return showModal(iconWithProps, cell);
    }
    return iconWithProps;
  };

  const showModal = (iconWithProps: JSX.Element, cell: TableCellProps) => {
    const { modal, anchorEl } = state;
    return (
      <React.Fragment>
        {iconWithProps}
        {anchorEl && (
          <MomentPopper open={modal} anchorEl={anchorEl}>
            <ClickAwayListener onClickAway={() => closeModal(cell)}>
              <div>
                <GoodIcon
                  htmlColor={iconColors.ok}
                  onClick={(e: React.MouseEvent) =>
                    onStatusSelect(EVal.GOOD, e)
                  }
                />
                <WarningIcon
                  htmlColor={iconColors.warning}
                  onClick={(e: React.MouseEvent) =>
                    onStatusSelect(EVal.WARNING, e)
                  }
                />
                <BadIcon
                  htmlColor={iconColors.danger}
                  onClick={(e: React.MouseEvent) => onStatusSelect(EVal.BAD, e)}
                />
              </div>
            </ClickAwayListener>
          </MomentPopper>
        )}
      </React.Fragment>
    );
  };

  return (
    <Container>
      <AutoSizer>
        {({ height, width }) => (
          <CustomTable
            onRowClick={props.goToDetail}
            headerClassName="headerRow"
            rowClassName={rowClassName}
            headerHeight={50}
            height={height}
            noRowsRenderer={() =>
              props.loading ? (
                <LoadingPanel>
                  <CircularProgress></CircularProgress>
                </LoadingPanel>
              ) : (
                <Container />
              )
            }
            rowCount={props.loading ? 0 : props.projects.length}
            rowHeight={60}
            rowGetter={rowGetter}
            width={width}
          >
            <Column
              className="column"
              label=""
              dataKey="clientColor"
              width={50}
              flexGrow={1}
              cellRenderer={renderClientIcon}
            />
            <Column
              className="column"
              label="Project Name"
              dataKey="projectName"
              width={120}
              flexGrow={40}
            />
            <Column
              className="column client-name"
              label="Client"
              dataKey="clientName"
              width={120}
              flexGrow={40}
            />
            <Column
              className="column"
              label="Budget"
              dataKey={EMetric.BUDGET}
              width={50}
              flexGrow={1}
              cellRenderer={getStatIcon}
            />
            <Column
              className="column"
              label="Schedule"
              dataKey={EMetric.SCHEDULE}
              width={50}
              flexGrow={1}
              cellRenderer={getStatIcon}
            />
            <Column
              className="column"
              label="Comms"
              dataKey={EMetric.COMMUNICATIONS}
              width={50}
              flexGrow={1}
              cellRenderer={getStatIcon}
            />
            <Column
              className="column"
              label="Quality"
              dataKey={EMetric.QUALITY}
              width={50}
              flexGrow={1}
              cellRenderer={getStatIcon}
            />
            <Column
              className="column"
              label="Morale"
              dataKey={EMetric.MORALE}
              width={50}
              flexGrow={1}
              cellRenderer={getStatIcon}
            />
          </CustomTable>
        )}
      </AutoSizer>
      <AddFab onClick={props.onAddClick} color="primary" aria-label="Add new">
        <Add />
      </AddFab>
    </Container>
  );
};

export default ProjectsList;
