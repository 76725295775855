import * as React from 'react';
import { Box as MBox, BoxProps } from '@mui/material';

interface IFlexProps extends BoxProps {
  isInlineFlex?: boolean;
}

export const Flex = ({
  isInlineFlex = false,
  children,
  ...props
}: IFlexProps) => {
  return (
    <MBox
      {...props}
      sx={{ display: isInlineFlex ? 'inline-flex' : 'flex', ...props?.sx }}
    >
      {children}
    </MBox>
  );
};

export default Flex;
