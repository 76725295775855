import * as React from 'react';
import Button, { IButtonProps } from '.';

export const outlinedUnstyledBaseProps: IButtonProps = {
  color: 'inherit',
  variant: 'outlined',
  sx: { borderColor: 'grey.300' }
};

export const OutlinedUnstyled = ({
  size = 'medium',
  width = 'auto',
  children,
  ...props
}: Omit<IButtonProps, 'color' | 'variant'>) => {
  let sx = {};
  if (props?.sx) {
    sx = props?.sx;
  }
  if (outlinedUnstyledBaseProps?.sx) {
    sx = { ...sx, ...outlinedUnstyledBaseProps?.sx };
  }

  return (
    <Button
      {...props}
      color={outlinedUnstyledBaseProps.color}
      variant={outlinedUnstyledBaseProps.variant}
      sx={sx}
    >
      {children}
    </Button>
  );
};

export default OutlinedUnstyled;
