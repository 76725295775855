import React from 'react';
import _ from 'lodash';
import { Popover } from '@mui/material';
import { TealComment, PopoverContent } from './styles';
import { TableCellProps } from 'react-virtualized';
import { InvoiceNote } from '../InvoiceListSidebar/InformationPane/InvoiceNotes/InvoiceNote';
import { INote, ENoteType } from 'src/shared/invoice-list/types';
import { SectionPrimaryHeader } from '../InvoiceListSidebar/InformationPane/InvoiceNotes/styles';

export const NotePopover = (props: TableCellProps) => {
  const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);

  const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
    if (event) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const {
    rowData: { notes }
  } = props;

  const [invoiceNotes, rejectionAndOtherNotes] = _.partition(
    notes,
    ({ typeOf }: { typeOf: ENoteType }) =>
      _.isNumber(typeOf) && typeOf === ENoteType.INVOICE_SPECIAL_INSTRUCTION
  );

  const [rejectionNotes, otherNotes] = _.partition(
    rejectionAndOtherNotes,
    ({ typeOf }: { typeOf: ENoteType }) =>
      _.isNumber(typeOf) && typeOf === ENoteType.INVOICE_REJECTION_REASON
  );

  let rejectionNote: INote | INote[] | null | undefined = _(rejectionNotes)
    .uniq()
    .compact()
    .sort((a: INote, b: INote) => (a.createdAt > b.createdAt ? -1 : 1))
    .value();

  if (rejectionNote.length) {
    rejectionNote = _.head(rejectionNote);
  } else {
    rejectionNote = null;
  }

  return (
    <React.Fragment>
      <TealComment
        onClick={handleClick}
        className={`note-popover 
          ${!notes || !notes.length ? 'transparent' : open ? 'active' : ''}`}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <PopoverContent>
          {invoiceNotes.length ? (
            <React.Fragment>
              <SectionPrimaryHeader>Invoice Instructions</SectionPrimaryHeader>
              {_(invoiceNotes)
                .filter(
                  ({ typeOf }: { typeOf: ENoteType }) =>
                    typeOf === ENoteType.INVOICE_SPECIAL_INSTRUCTION
                )
                .map((note: INote, idx: number) => (
                  <InvoiceNote
                    key={`${note.id} - ${idx}`}
                    note={note}
                    deleteInvoiceNote={() => Function.prototype}
                    updateInvoiceNote={() => Function.prototype}
                    immutable
                  />
                ))
                .value()}
            </React.Fragment>
          ) : null}
          {rejectionNote ? (
            <React.Fragment>
              <SectionPrimaryHeader>Rejection Note</SectionPrimaryHeader>
              {_.map([rejectionNote], (note: INote, i: number) => (
                <InvoiceNote
                  key={i}
                  note={note}
                  deleteInvoiceNote={() => Function.prototype}
                  updateInvoiceNote={() => Function.prototype}
                  immutable
                />
              ))}
            </React.Fragment>
          ) : null}
          {otherNotes.length ? (
            <React.Fragment>
              <SectionPrimaryHeader>Other Instructions</SectionPrimaryHeader>
              {_.map(otherNotes, (note: INote, i: number) => (
                <InvoiceNote
                  key={i}
                  note={note}
                  deleteInvoiceNote={() => Function.prototype}
                  updateInvoiceNote={() => Function.prototype}
                  immutable
                />
              ))}
            </React.Fragment>
          ) : null}
        </PopoverContent>
      </Popover>
    </React.Fragment>
  );
};
