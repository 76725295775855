import React from 'react';
import _ from 'lodash';
import { CircularProgress } from '@mui/material';
import { MAX_SIDEBAR_TIMESHEETS_PER_PAGE } from 'src/constants';
import { EmployeeDetailsPane } from './EmployeeDetailsPane';
import { TeamDetailsPane } from './TeamDetailsPane';
import {
  FixedBox,
  PaddedScrollingBox
} from 'src/components/shared/RightSidebar/styles';
import { NoDataContainer } from './styles';
import SideBarRight from '../SideBarRight';
import { Timesheet, TimesheetSidebarDetail } from 'src/api/types';

export interface ITimesheetSidebar {
  isLoading?: boolean;
  timesheetSidebarDetail?: TimesheetSidebarDetail;
  toggleSidePanel: () => void;
  fetchMoreTimesheets: (ownerId: number) => void;
  hideTeamDetails?: boolean;
  hideTimesheetLink?: boolean;
  pageCss?: string;
  onClickViewTimesheet?: () => void;
  onClickTimesheet?: (timesheetId: number) => void;
  teamTimesheets?: Timesheet[];
  teamTimesheetsStartDate?: string;
  onClickNextTeamTimesheets?: () => void;
  onClickPrevTeamTimesheets?: () => void;
}

export class TimesheetSidebar extends React.Component<
  ITimesheetSidebar,
  { tab: number; numTimesheetsToShow: number }
> {
  constructor(props: ITimesheetSidebar) {
    super(props);
    this.state = {
      tab: 0,
      numTimesheetsToShow: MAX_SIDEBAR_TIMESHEETS_PER_PAGE
    };
    this.handleTabChange = this.handleTabChange.bind(this);
    this.showMoreTimesheets = this.showMoreTimesheets.bind(this);
  }

  render() {
    const {
      toggleSidePanel,
      timesheetSidebarDetail,
      hideTeamDetails,
      hideTimesheetLink,
      pageCss,
      teamTimesheets,
      onClickNextTeamTimesheets,
      onClickPrevTeamTimesheets
    } = this.props;

    const { tab } = this.state;

    const showTeamTimesheetsTab =
      timesheetSidebarDetail &&
      timesheetSidebarDetail.teamTimesheets &&
      timesheetSidebarDetail.teamTimesheets.length > 0;
    const visibleTabs = showTeamTimesheetsTab
      ? [{ title: 'Timesheet Details' }, { title: 'Team Details' }]
      : [{ title: 'Timesheet Details' }];
    const tabs = hideTeamDetails ? undefined : visibleTabs;
    const timesheetSidebarDetailTabIndex = 0;
    const teamDetailsPaneIndex = showTeamTimesheetsTab ? 1 : undefined;

    let inner;

    if (this.props.isLoading) {
      inner = (
        <FixedBox>
          <CircularProgress />
        </FixedBox>
      );
    } else if (!timesheetSidebarDetail) {
      inner = (
        <FixedBox>
          <NoDataContainer>Select a timesheet</NoDataContainer>
        </FixedBox>
      );
    } else {
      inner = (
        <React.Fragment>
          <PaddedScrollingBox>
            {tab === timesheetSidebarDetailTabIndex &&
              timesheetSidebarDetail &&
              timesheetSidebarDetail.timesheet && (
                <EmployeeDetailsPane
                  timesheet={timesheetSidebarDetail.timesheet}
                  timesheetsList={timesheetSidebarDetail.timesheetsList}
                  showMoreTimesheets={this.showMoreTimesheets}
                  totalTimesheets={timesheetSidebarDetail.totalTimesheets}
                  hideTimesheetLink={hideTimesheetLink}
                  onClickViewTimesheet={this.props.onClickViewTimesheet}
                  onClickTimesheet={this.props.onClickTimesheet}
                />
              )}
            {tab === teamDetailsPaneIndex &&
              timesheetSidebarDetail &&
              timesheetSidebarDetail.timesheet &&
              teamTimesheets && (
                <TeamDetailsPane
                  owner={timesheetSidebarDetail.timesheet.owner}
                  onClickNextTeamTimesheets={onClickNextTeamTimesheets}
                  onClickPrevTeamTimesheets={onClickPrevTeamTimesheets}
                  teamTimesheets={teamTimesheets}
                  teamTimesheetsStartDate={this.props.teamTimesheetsStartDate}
                />
              )}
          </PaddedScrollingBox>
        </React.Fragment>
      );
    }

    return (
      <SideBarRight
        shouldShowHeader={false}
        headerTitle=""
        tabs={tabs}
        toggleSidePanel={toggleSidePanel}
        tabContent={inner}
        handleTabChange={this.handleTabChange}
        pageCss={pageCss}
      />
    );
  }

  private handleTabChange(newValue: number) {
    this.setState({ tab: newValue });
  }

  private showMoreTimesheets() {
    if (!this.props.timesheetSidebarDetail) return;

    const {
      timesheetSidebarDetail: { timesheetsList, totalTimesheets, timesheet }
    } = this.props;
    const { numTimesheetsToShow } = this.state;
    this.setState(
      {
        numTimesheetsToShow: timesheetsList.length
      },
      () => {
        if (
          timesheet &&
          timesheet.owner.id &&
          totalTimesheets > numTimesheetsToShow
        ) {
          this.props.fetchMoreTimesheets(timesheet.owner.id);
        }
      }
    );
  }
}

export default TimesheetSidebar;
