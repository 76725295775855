import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { UserProjectRole } from '../types';

export const fetchUserProjectRoles = () =>
  callAPI<UserProjectRole[]>({
    url: '/user_project_prefs',
    method: 'get'
  })
    .then(response => response.data)
    .catch(error => {
      toast('Failed to fetch user project roles', 'error');
      throw error;
    });
