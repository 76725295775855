import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IAppState } from 'src/reducer';
import * as usersActions from 'src/shared/users/actions';
import {
  IEntityStateRoles,
  IEntityStateUsersList,
  IEntityStateManagersObject
} from 'src/shared/users/types';
import UserList from '../../components/UserList';
import UserListFilters from '../../components/UserListFilters';
import withPagination from 'src/components/withPagination';
import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const UserListWithPagination = withPagination(UserList, 'users');

type IUserListContainer = IUserListContainerProps & IUserListContainerDispatch;

interface IUserListContainerDispatch {
  getUsers: any;
  getManagers: any;
  resetUserUpdatedAddedFlag: any;
}

interface IUserListContainerProps {
  users: IEntityStateUsersList[];
  roles: IEntityStateRoles;
  managers: IEntityStateManagersObject;
  reload: boolean;
}

interface IUserListContainerState {
  name: any;
  selectedRole: any;
  status: any;
  [x: string]: any;
}

export const UserListContainer: FunctionComponent<IUserListContainer> = props => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [state, setState] = useState<IUserListContainerState>({
    name: '',
    selectedRole: 'any',
    status: 0
  });

  useEffect(() => {
    retrieveNeededData();
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    if (isFirstRender || !props.reload) return;

    retrieveNeededData();
  }, [props, state]);

  const retrieveNeededData = () => {
    props.getUsers();
    props.getManagers();
    props.resetUserUpdatedAddedFlag();
  };

  const getUserListFiltersProps = () => {
    return {
      ...state,
      clearFilters: clearFilters,
      noFilter: checkIfThereIsNoFilter(),
      onChange: onFilterChanged,
      roles: props.roles
    };
  };

  const getUserListProps = () => {
    return {
      goToDetail: goToDetail,
      onAddClick: redirectToAdd,
      items: filteredUsers(),
      totalItems: filteredUsers().length,
      selectedItem: getSelectedUser()
    };
  };

  const onFilterChanged = (prop: string) => (event: any) => {
    const newState = {
      ...state,
      [prop]: event.target.value
    };
    setState(newState);
    navigate('/team-management');
  };

  const filteredUsers = () => {
    let { users } = props;
    const { managers } = props;
    if (users.length) {
      const { name, status, selectedRole } = state;

      users = users.map(user => {
        const { managerId } = user;
        if (managerId && managers[managerId]) {
          const { firstName, lastName } = managers[managerId];
          user.managerFullName = [firstName, lastName].join(' ');
        } else {
          user.managerFullName = 'Not set';
        }
        return user;
      });
      if (name !== '') {
        users = users.filter(u =>
          u.fullName.toUpperCase().includes(name.toUpperCase())
        );
      }

      if (status !== 'any') {
        users = users.filter(u => (u.isActive ? 0 : 1) === status);
      }

      if (selectedRole !== 'any') {
        users = users.filter(u => u.roles.includes(selectedRole));
      }
    }
    return users;
  };

  const checkIfThereIsNoFilter = () => {
    const { name, selectedRole, status } = state;

    return name === '' && selectedRole === 'any' && status === 'any';
  };

  const clearFilters = () => {
    setState({
      name: '',
      selectedRole: 'any',
      status: 'any'
    });
    navigate('/team-management');
  };

  const redirectToAdd = () => {
    navigate('/team-management/add');
  };

  const goToDetail = (event: any) => {
    navigate(`/team-management/${event.rowData.id}`);
  };

  const getSelectedUser = () => {
    if (id) {
      return parseInt(id) - 1 > -1
        ? props.users[(parseInt(id) - 1).toString()]
        : null;
    }
    return null;
  };

  return (
    <React.Fragment>
      <UserListFilters {...getUserListFiltersProps()} />
      <UserListWithPagination {...getUserListProps()} />
    </React.Fragment>
  );
};

export const mapStateToProps = (state: IAppState): IUserListContainerProps => ({
  roles: state.entities.roles,
  users: state.entities.usersList,
  managers: state.entities.managers,
  reload: state.entities.updateUserCompleted
});

const mapDispatchToProps = (dispatch: Dispatch): IUserListContainerDispatch => {
  return bindActionCreators(
    {
      getUsers: usersActions.actions.getUsers,
      getManagers: usersActions.actions.getManagers,
      resetUserUpdatedAddedFlag: usersActions.actions.resetUserUpdateFlag
    },
    dispatch
  );
};

export default connect<IUserListContainerProps, IUserListContainerDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(UserListContainer);
