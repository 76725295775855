import { IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import * as React from 'react';
import { AutoSizer, Column } from 'react-virtualized';
import messages from '../../messages';
import AddRole from './AddRole';
import EditRole from './EditRole';
import ProjectHeader from 'src/components/ProjectsDetailHeader';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  CustomTable,
  Disabled,
  Enabled,
  ListContainer,
  Separator
} from './styles';
import { find } from 'lodash';
import { FunctionComponent, useState } from 'react';
import { ProjectRole } from 'src/api/types';

const initialRoleValues = {
  accountingCategoryId: 1,
  departmentId: 1,
  estimatedEndDate: '',
  isActive: 1,
  name: '',
  rate: 0
};

const initialRoleValuesErrors = {
  accountingCategoryId: false,
  departmentId: false,
  estimatedEndDate: false,
  isActive: false,
  name: false,
  rate: false
};

interface IProjectsDetailRoles {
  selectedProject: any;
  accountingCategories: any[];
  departments: any[];
  loadingEditRole: boolean;
  loadingAddRole: boolean;
  createRole: (data: Partial<ProjectRole>, callback: () => void) => void;
  editRole: (data: Partial<ProjectRole>, callback: () => void) => void;
  path: string;
}

interface IProjectDetailsRolesState {
  openAddRole: boolean;
  roleAddValues: any;
  roleAddValuesError: any;
  openEditRole: boolean;
  roleEditValues: any;
  roleEditValuesError: any;
}

export const ProjectsDetailRoles: FunctionComponent<IProjectsDetailRoles> = props => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const getRoleInitialEditValues = (roleId: string) => {
    const { roles } = props.selectedProject;
    const role = find(roles, ['id', parseInt(roleId)]);
    const { estimatedEndDate, isActive } = getRoleCleanData(role);
    return {
      ...role,
      estimatedEndDate: estimatedEndDate,
      isActive: isActive
    };
  };

  const getRoleCleanData = (role: any) => {
    const estimatedEndDate = role.estimatedEndDate
      ? role.estimatedEndDate.substring(0, 10)
      : '';
    const isActive = Number(role.isActive);
    return { estimatedEndDate, isActive };
  };

  const [state, setState] = useState<IProjectDetailsRolesState>(() => {
    const projectPath = location.pathname.split('/');
    const RoleId = params.roleId;
    return {
      openAddRole: projectPath.includes('add'),
      openEditRole: projectPath.includes('edit'),
      roleAddValues: initialRoleValues,
      roleAddValuesError: initialRoleValuesErrors,
      roleEditValues: RoleId
        ? getRoleInitialEditValues(RoleId)
        : initialRoleValues,
      roleEditValuesError: initialRoleValuesErrors
    };
  });

  const openAddRole = () => {
    setState({ ...state, openAddRole: true });
  };

  const openEdit = (event: any) => {
    const role = event.rowData;
    const { estimatedEndDate, isActive } = getRoleCleanData(role);
    setState({
      ...state,
      openEditRole: true,
      roleEditValues: {
        ...role,
        estimatedEndDate: estimatedEndDate,
        isActive: isActive
      }
    });
    const { selectedProject } = props;
    const projectPath = projectTypeRoute();
    navigate(`/${projectPath}/${selectedProject.id}/roles/edit/${role.id}`);
  };

  const rowGetter = (event: any) => {
    return props.selectedProject.roles[event.index];
  };

  const noRows = () => {
    return (
      <Typography align="center" variant="subtitle1">
        No data
      </Typography>
    );
  };

  const renderStatus = (event: any) => {
    const status = event.cellData;
    if (status) {
      return <Enabled />;
    }

    return <Disabled />;
  };

  const projectTypeRoute = () => {
    // this getter returns client-projects or inhouse-projects
    // props.path can be /client-projects/:projectId or /inhouse-projects/:projectId
    return props.path.split('/')[1];
  };

  const onChange = (prop: string) => (event: any) => {
    setState({
      ...state,
      roleAddValues: {
        ...state.roleAddValues,
        [prop]: event.target.value
      }
    });
  };

  const onChangeEdit = (prop: string) => (event: any) => {
    setState({
      ...state,
      roleEditValues: {
        ...state.roleEditValues,
        [prop]: event.target.value
      }
    });
  };

  const onSuccess = () => {
    setState({
      ...state,
      openAddRole: false,
      openEditRole: false,
      roleAddValues: initialRoleValues,
      roleAddValuesError: initialRoleValuesErrors,
      roleEditValues: initialRoleValues,
      roleEditValuesError: initialRoleValuesErrors
    });
    const { selectedProject } = props;
    const projectPath = props.path.split('/')[1];
    navigate(`/${projectPath}/${selectedProject.id}/roles`);
  };

  const createRole = () => {
    const { name, rate } = state.roleAddValues;
    if (!name || rate === '' || rate === null) {
      setState({
        ...state,
        roleAddValuesError: {
          ...initialRoleValuesErrors,
          name: !name,
          rate: rate === '' || rate === null
        }
      });
    } else {
      props.createRole(state.roleAddValues, onSuccess);
    }
  };

  const editRole = () => {
    const { name, rate } = state.roleEditValues;
    const isInvalid = (v: string) => !v || parseFloat(v) < 0;
    const errors = {
      ...initialRoleValuesErrors,
      name: isInvalid(name),
      rate: isInvalid(rate)
    };
    // this function tests that every value in the errors object is false
    // https://stackoverflow.com/questions/17117712/how-to-know-if-all-javascript-object-values-are-true
    const valid = Object.values(errors).every(e => !e);

    if (!valid) {
      setState({
        ...state,
        roleEditValuesError: errors
      });
    } else {
      props.editRole(state.roleEditValues, onSuccess);
    }
  };

  const { selectedProject } = props;
  const projectPath = projectTypeRoute();

  return (
    <Container>
      {selectedProject ? (
        <React.Fragment>
          <ProjectHeader selectedProject={selectedProject} />
          <Separator>
            <Typography variant="h5">{`Roles`}</Typography>
            <Link to={`/${projectPath}/${selectedProject.id}/roles/add`}>
              <IconButton onClick={openAddRole}>
                <AddIcon />
              </IconButton>
            </Link>
          </Separator>
          <AddRole
            accountingCategories={props.accountingCategories}
            departments={props.departments}
            open={state.openAddRole}
            loading={props.loadingAddRole}
            values={state.roleAddValues}
            add={createRole}
            errors={state.roleAddValuesError}
            handleClose={onSuccess}
            onChange={onChange}
          />
          <EditRole
            accountingCategories={props.accountingCategories}
            departments={props.departments}
            open={state.openEditRole}
            loading={props.loadingEditRole}
            values={state.roleEditValues}
            errors={state.roleEditValuesError}
            edit={editRole}
            handleClose={onSuccess}
            onChange={onChangeEdit}
          />
          <ListContainer>
            <AutoSizer>
              {({ width, height }) => (
                <CustomTable
                  onRowClick={openEdit}
                  headerClassName="headerRow"
                  rowClassName="row"
                  headerHeight={50}
                  height={height}
                  noRowsRenderer={noRows}
                  rowCount={props.selectedProject.roles.length}
                  rowHeight={52}
                  rowGetter={rowGetter}
                  width={width}
                >
                  <Column
                    className="column"
                    label="Name"
                    dataKey="name"
                    width={100}
                    flexGrow={1}
                  />
                  <Column
                    className="column"
                    label="Rate"
                    dataKey="rate"
                    width={100}
                    flexGrow={1}
                  />
                  <Column
                    className="column"
                    label="Status"
                    dataKey="isActive"
                    width={100}
                    flexGrow={1}
                    cellRenderer={renderStatus}
                  />
                </CustomTable>
              )}
            </AutoSizer>
          </ListContainer>
        </React.Fragment>
      ) : (
        <Typography style={{ marginTop: 10 }} variant="h5">
          {messages.NO_PROJECT_SELECTED}
        </Typography>
      )}
    </Container>
  );
};

export default ProjectsDetailRoles;
