import React from 'react';
import { ClientColorBox, ClientBox } from './styles';
import { Box } from '@mui/material';
import { TableCellProps } from 'react-virtualized';
import { ClientIcon } from 'src/components/ProjectsList/styles';

import { NotePopover } from '../NotePopover';

interface IClientProject {
  clientName: string;
  projectNickname: string;
  clientColor: string;
  tableCellProps?: TableCellProps;
}

export const ClientProject = (props: IClientProject) => {
  const { clientName, projectNickname, clientColor, tableCellProps } = props;

  return (
    <ClientColorBox>
      {tableCellProps && <NotePopover {...tableCellProps} />}
      <Box className="client-color">
        <ClientIcon
          className="client-icon"
          color={clientColor ? clientColor : 'black'}
        />
      </Box>
      <ClientBox>
        {projectNickname} - {clientName}
      </ClientBox>
    </ClientColorBox>
  );
};
