import styled from 'src/theme';
import Comment from '@mui/icons-material/Comment';
import { Box } from '@mui/material';

export const TealComment = styled(Comment)`
  && {
    color: #00838f;
    margin-left: 10px;

    &.transparent {
      visibility: hidden;
    }
    &.active,
    &:hover {
      color: #005662;
    }
  }
`;

export const PopoverContent = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 240px;
`;
