import styled, { theme } from 'src/theme';
import { Grid } from '@mui/material';
import {
  ColumnFlexBox,
  SectionPrimaryHeader
} from 'src/components/shared/RightSidebar/RightSidebarInfoPane/styles';

// Note: Styles changed for Invoice popover
export const Container = styled(ColumnFlexBox)`
  padding: 0;
  width: auto;
`;

export const Header = styled(SectionPrimaryHeader)`
  && {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin: 0;
    margin-bottom: 16px;
    padding: 0;
    text-transform: uppercase;
  }
`;

export const HeaderGridItem = styled(Grid)`
  && {
    font-family: ${theme.font};
    color: #212121;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 8px;
  }
`;

export const GridItem = styled(Grid)`
  && {
    font-family: ${theme.font};
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
  }
`;

export const UnderlinedGridItem = styled(GridItem)`
  && {
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 16px;
  }
`;
