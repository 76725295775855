import styled, { theme } from 'src/theme';
import { Button } from '@mui/material';

export const AddButton = styled(Button)`
  && {
    font-size: 16px;
    width: 200px;
    height: 36px;
    white-space: nowrap;
    padding: 0 15px;
    border-radius: 4px;
    background-color: ${theme.button.primaryColor};
    color: #ffffff;
    font-family: ${theme.font};
  }

  &&:hover {
    background-color: #4ca8b0;
  }
`;
