import React from 'react';
import Copy from '@mui/icons-material/FileCopy';
import {
  IInvoiceSidebarDetail,
  IProjectEmail
} from 'src/shared/invoice-list/types';
import { copyTextToClipboard } from 'src/shared/utils';
import styled from 'src/theme';

const ProjectEmailTable = styled.table`
  font-size: 14px;
  width: 100%;
`;

interface IProps {
  invoiceSidebarDetail: IInvoiceSidebarDetail;
}

export default function ProjectEmails({ invoiceSidebarDetail }: IProps) {
  return (
    <div>
      <h4 className="section-header">Project Emails</h4>
      <ProjectEmailTable>
        <tbody>
          {invoiceSidebarDetail.projectEmails.map(
            (projectEmail: IProjectEmail) => (
              <tr key={projectEmail.email}>
                <td>{projectEmail.type}:</td>
                <td>{projectEmail.email}</td>
                <td>
                  <Copy
                    onClick={() => copyTextToClipboard(projectEmail.email)}
                  />
                </td>
              </tr>
            )
          )}
        </tbody>
      </ProjectEmailTable>
    </div>
  );
}
