import { Box, Typography } from '@mui/material';
import { StatusBox } from '../InvoiceList/InvoiceListTable/styles';
import styled from 'src/theme';

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 2;
`;

export const FlexBoxJustifyStart = styled(FlexBox)`
  justify-content: flex-start;
  align-items: center;
`;
export const FlexBoxJustifyEnd = styled(FlexBox)`
  justify-content: flex-end;
`;

export const Header = styled(Typography)`
  && {
    color: rgba(0, 0, 0, 0.87);
    font-size: 20px;
    font-weight: 600;
    margin-left: 20px;
    letter-spacing: 0;
  }
`;

export const Dates = styled.div`
  && {
    color: rgba(0, 0, 0, 0.87);
    font-size: 20px;
    letter-spacing: 0;
    margin-left: 16px;
  }
`;

export const StyledStatusBox = styled(StatusBox)`
  && {
    color: rgba(33, 33, 33, 0.87);
    font-size: 12px;
    font-weight: 600;
    margin-left: 28px;
  }
`;
