import Button, { ButtonProps } from '@mui/material/Button';
import Fab, { FabProps } from '@mui/material/Fab';
import Popover, { PopoverProps } from '@mui/material/Popover';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Table, TableProps } from 'react-virtualized';
import styled, { theme } from '../../theme';

export const Container = styled.div`
  width: 100%;
  flex: 1;
`;

export const ListContainer = styled.div`
  width: 100%;
  flex: 1;
  height: 100%;
`;

export const FilterButton: React.ComponentType<ButtonProps> = styled(Button)`
  && {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
  }
` as any;

export const SearchButton: React.ComponentType<ButtonProps> = styled(Button)`
  && {
    width: calc(100% - 20px);
    margin: 10px;
  }
` as any;

export const CustomTextField: React.ComponentType<TextFieldProps> = styled(
  TextField
)`
  && {
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
    margin-top: 12px;
    max-width: 700px;
  }
  && input {
    padding-top: 20px;
    padding-bottom: 20px;
  }
` as any;

export const FiltersContainer = styled.div`
  width: 100%;
  flex: 1;
  justify-content: space-between;
  height: 80px;
  flex-direction: column;
`;

export const CustomPopover: React.ComponentType<PopoverProps> = styled(Popover)`
  && {
    margin-top: 10px;
    margin-left: -8px;
  }
` as any;

export const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  padding-right: 10px;
  padding-left: 10px;
  min-width: 100px;
`;

export const NoRows = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const CustomFab: React.ComponentType<FabProps> = styled(Fab)`
  && {
    margin: 25px 5px 5px 5px;
  }
` as any;

export const CustomTableStyled: React.ComponentType<TableProps> = styled(Table)`
  & .row {
    box-sizing: border-box;
    border-bottom: 2px solid ${theme.primaryBackground};
    background-color: ${theme.secondaryBackground};
    display: -webkit-box;
    height: 50px;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: ${theme.font};
  }
  & .row.active {
    background-color: ${theme.separatorBackground};
  }
  & .row:focus {
    outline: none;
  }
  & .row:hover {
    background-color: ${theme.separatorBackground};
    cursor: pointer;
  }
  & .ReactVirtualized__Table__headerRow:hover {
    background-color: ${theme.primaryBackground};
    cursor: default;
  }
  & .ReactVirtualized__Table__headerRow {
    background-color: ${theme.primaryBackground};
    color: ${theme.secondaryColorInverted};
  }
  & .column,
  .headerRow {
    margin-left: 10px;
    margin-right: 10px;
  }
` as any;

export const AddFab: React.ComponentType<FabProps> = styled(Fab)`
  && {
    margin: 16px 5px 5px 5px;
    position: absolute;
    bottom: 12px;
    right: 10px;
  }
` as any;
