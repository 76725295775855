import React from 'react';
import { CustomInput, FullWidthAutoComplete } from './styles';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';
import { QBCustomer } from 'src/api/types';

interface IQBCustomerDropdown {
  qbCustomers: QBCustomer[];
  qbCustomerId?: number;
  setCustomerId: (id: number) => void;
}

export const QBCustomerDropdown = ({
  qbCustomers,
  qbCustomerId,
  setCustomerId
}: IQBCustomerDropdown) => {
  const [input, setInput] = React.useState('');
  React.useEffect(() => {
    const currentSelected = _.find(qbCustomers, { id: qbCustomerId });
    const name = currentSelected ? currentSelected.displayName : '';
    setInput(name);
  }, [qbCustomers, qbCustomerId]);

  return (
    <FullWidthAutoComplete
      options={qbCustomers}
      getOptionLabel={(qbClient: QBCustomer) => qbClient.displayName}
      onChange={(e: React.ChangeEvent, option: QBCustomer) => {
        if (option?.id) {
          setCustomerId(option.id);
        } else {
          setCustomerId(0);
        }
      }}
      inputValue={input}
      renderInput={params => (
        <CustomInput
          {...params}
          id="name-input"
          margin="normal"
          variant="outlined"
          fullWidth
          label="QB Customer Display Name"
          InputLabelProps={{ shrink: true }}
          onChange={({ target: { value } }) => setInput(value)}
          placeholder="Search QB Customers"
          InputProps={{
            ...params.InputProps,
            startAdornment: <SearchIcon />
          }}
        />
      )}
    />
  );
};
