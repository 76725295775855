import { ActionsUnion, createAction } from 'src/shared/types';
import {
  ITimesheetRow,
  IInvoiceDetailView,
  NewInvoiceState,
  NewInvoiceParams
} from 'src/shared/invoice/types';
import { InvoiceStatus } from 'src/shared/invoice-list/types';
import { TimesheetEntry } from 'src/api/types';
export const MODIFY_INVOICE = 'MODIFY_INVOICE';
export const HYDRATE_INVOICE = 'HYDRATE_INVOICE';
export const POPULATED_HYDRATED_INVOICE = 'POPULATED_HYDRATED_INVOICE';
export const CLEAR_INVOICE_DETAIL = 'CLEAR_INVOICE_DETAIL';
export const CREATE_NEW_INVOICE = 'CREATE_NEW_INVOICE';
export const DELETE_INVOICE = 'DELETE_INVOICE';
export const SAVE_TIMESHEET_ROW = 'SAVE_TIMESHEET_ROW';
export const SAVE_TIMESHEET_ENTRY = 'SAVE_TIMESHEET_ENTRY';
export const SAVE_LINE_ITEM = 'SAVE_LINE_ITEM';
export const DELETE_LINE_ITEM = 'DELETE_LINE_ITEM';
export const DISMISS_ALL_TIMESHEETS = 'DISMISS_ALL_TIMESHEETS';
export const DISMISS_TIMESHEET_ROW = 'DISMISS_TIMESHEET_ROW';
export const UPDATE_FIXED_LINE_ITEM = 'UPDATE_FIXED_LINE_ITEM';
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const UPLOAD_NEW_FILE = 'UPLOAD_NEW_FILE';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const SAVE_INVOICE = 'SAVE_INVOICE';
export const MODIFY_LOCAL_VIEW = 'MODIFY_LOCAL_VIEW';
export const RESET_TIMESHEET_ENTRY = 'RESET_TIMESHEET_ENTRY';
export const MOD_LINE_ITEM = 'MOD_LINE_ITEM';
export const POPULATE_FIXED_LINE_ITEM_ID = 'POPULATE_FIXED_LINE_ITEM_ID';
export const FETCH_TIMESHEET_ENTRIES = 'FETCH_TIMESHEET_ENTRIES';
export const POPULATE_TIMESHEET_ENTRIES = 'POPULATE_TIMESHEET_ENTRIES';
export const CREATE_NEW_INSTRUCTION = 'CREATE_NEW_INSTRUCTION';
export const EXPORT_TIMESHEET = 'EXPORT_TIMESHEET';
export const SET_IS_INVOICE_DETAIL_LOADING = 'SET_IS_INVOICE_DETAIL_LOADING';
export const DOWNLOAD_QB_PDF = 'DOWNLOAD_QB_PDF';

const modifyInvoice = () => createAction(MODIFY_INVOICE);
const clearDetail = () => createAction(CLEAR_INVOICE_DETAIL);

const hydrateInvoice = ({
  id,
  filters,
  dismiss
}: {
  id?: string;
  filters?: NewInvoiceState['filters'];
  dismiss?: boolean;
}) => createAction(HYDRATE_INVOICE, { id, filters, dismiss });

const populateHydratedInvoice = (invoice: any) =>
  createAction(POPULATED_HYDRATED_INVOICE, invoice);

const createNewInvoice = (
  callback: (path: string) => void,
  dismiss?: boolean,
  params?: NewInvoiceParams
) => createAction(CREATE_NEW_INVOICE, { callback, dismiss, params });

const deleteInvoice = (callback: (path: string) => void) =>
  createAction(DELETE_INVOICE, { callback });

const saveTimesheetRow = (item: any) => createAction(SAVE_TIMESHEET_ROW, item);

const saveTimesheetEntry = (item: any) =>
  createAction(SAVE_TIMESHEET_ENTRY, item);

const saveLineItem = (item: any) => createAction(SAVE_LINE_ITEM, { item });

const deleteLineItem = (item: any) => createAction(DELETE_LINE_ITEM, item);

const updateFixedLineItem = (fixedLineItem: any) =>
  createAction(UPDATE_FIXED_LINE_ITEM, fixedLineItem);

const dismissTimesheetRowItem = (item: ITimesheetRow) =>
  createAction(DISMISS_TIMESHEET_ROW, item);

const dismissAllTimesheets = () => createAction(DISMISS_ALL_TIMESHEETS);

const downloadFile = (id: number) => createAction(DOWNLOAD_FILE, id);

const uploadNewFile = ({
  lineItemId,
  file
}: {
  lineItemId?: number;
  file: File;
}) => createAction(UPLOAD_NEW_FILE, { lineItemId, file });

const populateFixedLineItemId = ({ id }: { id: number | null }) =>
  createAction(POPULATE_FIXED_LINE_ITEM_ID, { id });

const changeInvoiceStatus = (
  status: InvoiceStatus,
  message?: string,
  id?: number,
  callback?: (path: string) => void
) => createAction(CHANGE_STATUS, { status, message, id, callback });

const saveInvoice = ({
  view,
  callback
}: {
  callback?: (path: string) => void;
  view?: IInvoiceDetailView;
}) => createAction(SAVE_INVOICE, { callback, view });

const modifyLocalView = (mutatedView: IInvoiceDetailView) =>
  createAction(MODIFY_LOCAL_VIEW, mutatedView);

const cancelTimesheetEntryChanges = (
  userRowIndex: number,
  timesheetEntryId: number
) => createAction(RESET_TIMESHEET_ENTRY, { userRowIndex, timesheetEntryId });

const fetchTimesheetEntries = (timesheetId: number) =>
  createAction(FETCH_TIMESHEET_ENTRIES, { timesheetId });

const populateAllTimesheetEntriesForPeriod = (entries: TimesheetEntry[]) =>
  createAction(POPULATE_TIMESHEET_ENTRIES, { entries });

const createNewInstruction = ({
  text,
  typeOf
}: {
  text: string;
  typeOf: number;
}) => createAction(CREATE_NEW_INSTRUCTION, { text, typeOf });

const exportTimesheet = (id?: number) => createAction(EXPORT_TIMESHEET, { id });

const downloadQbPdf = () => createAction(DOWNLOAD_QB_PDF);

const setIsLoading = (loading: boolean) =>
  createAction(SET_IS_INVOICE_DETAIL_LOADING, { loading });

export const actions = {
  modifyInvoice,
  hydrateInvoice,
  clearDetail,
  populateHydratedInvoice,
  createNewInvoice,
  deleteInvoice,
  saveTimesheetRow,
  saveTimesheetEntry,
  saveLineItem,
  deleteLineItem,
  dismissAllTimesheets,
  dismissTimesheetRowItem,
  updateFixedLineItem,
  downloadFile,
  uploadNewFile,
  changeInvoiceStatus,
  saveInvoice,
  modifyLocalView,
  cancelTimesheetEntryChanges,
  populateFixedLineItemId,
  fetchTimesheetEntries,
  populateAllTimesheetEntriesForPeriod,
  createNewInstruction,
  exportTimesheet,
  setIsLoading,
  downloadQbPdf
};

export type Actions = ActionsUnion<typeof actions>;
