import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { DepartmentUser } from '../types';

export const deleteDepartmentUser = (id: number) =>
  callAPI<DepartmentUser>({
    method: 'delete',
    url: `/department_user/${id}`
  })
    .then(response => response.data)
    .catch(error => {
      toast('Failed to delete department user', 'error');
      throw error;
    });
