import React, { useState } from 'react';
import _ from 'lodash';
import {
  StyledChip,
  ChipContainer,
  RedCancel,
  FlexBoxJustifyStart
} from './styles';
import { DeleteUserAttributeProps } from './types';
import { Department, DepartmentUser } from 'src/api/types';

interface IUserAttributeChip {
  userAttribute: DepartmentUser;
  attribute?: Department;
  canEdit?: boolean;
  deleteUserAttribute: (props: DeleteUserAttributeProps) => void;
}

export const UserAttributeChip = ({
  userAttribute,
  attribute,
  canEdit,
  deleteUserAttribute
}: IUserAttributeChip) => {
  const [hovered, setHovered] = useState(false);

  return attribute ? (
    <ChipContainer
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <FlexBoxJustifyStart>
        <StyledChip
          key={userAttribute.id}
          label={`${attribute.name}`}
        ></StyledChip>
      </FlexBoxJustifyStart>
      {canEdit && (
        <RedCancel
          onClick={() =>
            deleteUserAttribute({
              id: userAttribute.id,
              userId: userAttribute.userId
            })
          }
          className={hovered ? 'visible' : ''}
        />
      )}
    </ChipContainer>
  ) : null;
};

export default UserAttributeChip;
