import { ActionsUnion, createAction } from 'src/shared/types';
import {
  IEntityStateRoles,
  IEntityStateUsersList,
  IRole,
  IUserPayload,
  IUserResponse
} from 'src/shared/users/types';
import {
  managersNormalizer,
  rolesNormalizer,
  userNormalizer,
  usersNormalizer
} from './normalizer';
import { IUserSearchParams } from './types';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCEEDED = 'ADD_USER_SUCCEEDED';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCEEDED = 'DELETE_USER_SUCCEEDED';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCEEDED = 'GET_CURRENT_USER_SUCCEEDED';
export const GET_MANAGERS = 'GET_MANAGERS';
export const GET_PROJECT_MANAGERS = 'GET_PROJECT_MANAGERS';
export const GET_PROJECT_MANAGERS_SUCCEEDED = 'GET_PROJECT_MANAGERS_SUCCEEDED';
export const GET_MANAGERS_SUCCEEDED = 'GET_MANAGERS_SUCCEEDED';
export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_SUCCEEDED = 'GET_ROLES_SUCCEEDED';
export const GET_USER = 'GET_USER';
export const GET_USER_HAS_RECORDS = 'GET_USER_HAS_RECORDS';
export const GET_USER_HAS_RECORDS_FAILED = 'GET_USER_HAS_RECORDS_FAILED';
export const GET_USER_HAS_RECORDS_SUCCEEDED = 'GET_USER_HAS_RECORDS_SUCCEEDED';
export const GET_USER_SUCCEEDED = 'GET_USER_SUCCEEDED';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCEEDED = 'GET_USERS_SUCCEEDED';
export const RESET_USER_UPDATE_FLAG = 'RESET_USER_UPDATE_FLAG';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';
export const UPDATE_USER_SUCCEEDED = 'UPDATE_USER_SUCCEEDED';

// Typesafe actions
const addUser = (callback: () => void, userData: IUserPayload) =>
  createAction(ADD_USER, {
    callback,
    userData
  });

const addUserSucceeded = (
  responseUserData: IUserResponse,
  userData: IUserPayload
) =>
  createAction(ADD_USER_SUCCEEDED, {
    responseUserData: userNormalizer(responseUserData),
    userData
  });

const addUserFailed = () => createAction(ADD_USER_FAILED, {});

const deleteUser = (userId: number) =>
  createAction(DELETE_USER, {
    userId
  });

const deleteUserSucceeded = (userId: number) =>
  createAction(DELETE_USER_SUCCEEDED, {
    userId
  });

const getCurrentUser = () => createAction(GET_CURRENT_USER, {});

const getCurrentUserSucceeded = (userData: IUserResponse[]) =>
  createAction(GET_CURRENT_USER_SUCCEEDED, {
    userData
  });

const getManagers = () => createAction(GET_MANAGERS, {});

const getManagersSucceeded = (userData: IUserResponse[]) =>
  createAction(GET_MANAGERS_SUCCEEDED, {
    userData: managersNormalizer(userData)
  });

const getProjectManagers = () => createAction(GET_PROJECT_MANAGERS, {});

const getProjectManagersSucceeded = (userData: IUserResponse[]) =>
  createAction(GET_PROJECT_MANAGERS_SUCCEEDED, {
    userData: managersNormalizer(userData)
  });

const getRoles = () => createAction(GET_ROLES, {});

const getRolesSucceeded = (roleData: IRole[]) =>
  createAction(GET_ROLES_SUCCEEDED, {
    roleData: rolesNormalizer(roleData)
  });

const getUser = (userId: number) =>
  createAction(GET_USER, {
    userId
  });

const getUserHasRecords = (userId: number) =>
  createAction(GET_USER_HAS_RECORDS, {
    userId
  });

const getUserHasRecordsFailed = (userId: number) =>
  createAction(GET_USER_HAS_RECORDS_FAILED, {
    userId
  });

const getUserHasRecordsSucceeded = (
  userData: IUserResponse[],
  userId: number
) =>
  createAction(GET_USER_HAS_RECORDS_SUCCEEDED, {
    userData,
    userId
  });

const getUserSucceeded = (userData: IUserResponse[], userId: number) =>
  createAction(GET_USER_SUCCEEDED, {
    userData: usersNormalizer(userData),
    userId
  });

const getUsers = (params?: IUserSearchParams) =>
  createAction(GET_USERS, { params });

const getUsersSucceeded = (
  usersList: IEntityStateUsersList[],
  roles: IEntityStateRoles
) =>
  createAction(GET_USERS_SUCCEEDED, {
    roles,
    usersList
  });

const resetUserUpdateFlag = () => createAction(RESET_USER_UPDATE_FLAG, {});

const updateUser = (
  userData: IUserPayload,
  userId: number,
  callback: () => void
) =>
  createAction(UPDATE_USER, {
    callback,
    userData,
    userId
  });

const updateUserFailed = () => createAction(UPDATE_USER_FAILED, {});

const updateUserSucceeded = (
  responseUserData: IUserResponse,
  userData: IUserPayload,
  userId: number
) =>
  createAction(UPDATE_USER_SUCCEEDED, {
    responseUserData: userNormalizer(responseUserData),
    userData,
    userId
  });

export const actions = {
  addUser,
  addUserFailed,
  addUserSucceeded,
  deleteUser,
  deleteUserSucceeded,
  getCurrentUser,
  getCurrentUserSucceeded,
  getManagers,
  getManagersSucceeded,
  getProjectManagers,
  getProjectManagersSucceeded,
  getRoles,
  getRolesSucceeded,
  getUser,
  getUserHasRecords,
  getUserHasRecordsFailed,
  getUserHasRecordsSucceeded,
  getUserSucceeded,
  getUsers,
  getUsersSucceeded,
  resetUserUpdateFlag,
  updateUser,
  updateUserFailed,
  updateUserSucceeded
};

export type Actions = ActionsUnion<typeof actions>;
