import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { UserProjectRole } from '../types';

export const createUserProjectRole = (data: {
  deliverableId?: number;
  projectRoleId?: number;
  isVisible?: boolean;
}) =>
  callAPI<UserProjectRole>({
    data,
    method: 'post',
    url: '/user_project_prefs'
  })
    .then(response => response.data)
    .catch(error => {
      toast('Failed to create user project role', 'error');
      throw error;
    });
