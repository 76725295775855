import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from 'src/reducer';
import { ICurrentUser } from 'src/shared/auth/types';
import { actions as invoiceDetailActions } from 'src/shared/invoice/actions';
import { actions as invoiceListActions } from 'src/shared/invoice-list/actions';
import {
  ENoteType,
  IInvoiceSidebarDetail
} from 'src/shared/invoice-list/types';
import SpecialInstructions from './component';

interface IDispatch {
  createNewInstruction: ({
    text,
    typeOf
  }: {
    text: string;
    typeOf: number;
  }) => void;
  deleteInvoiceNote: ({ id }: { id: number }) => void;
  updateInvoiceNote: ({ text, id }: { text: string; id: number }) => void;
}

interface IProps extends IDispatch {
  canEdit: boolean;
  currentUser: ICurrentUser;
  invoiceSidebarDetail: IInvoiceSidebarDetail;
}

function SpecialInstructionsContainer({
  canEdit,
  currentUser,
  createNewInstruction,
  deleteInvoiceNote,
  updateInvoiceNote,
  invoiceSidebarDetail
}: IProps) {
  return (
    <SpecialInstructions
      canEdit={canEdit}
      currentUser={currentUser}
      invoiceSidebarDetail={invoiceSidebarDetail}
      onCreateInstruction={instruction =>
        createNewInstruction({
          text: instruction,
          typeOf: ENoteType.INVOICE_SPECIAL_INSTRUCTION
        })
      }
      onDeleteNote={id => deleteInvoiceNote({ id })}
      onUpdateNote={({ text, id }) => updateInvoiceNote({ text, id })}
    />
  );
}

export const mapStateToProps = (state: IAppState) => {
  const currentUser = state.auth.currentUser;

  return {
    currentUser
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatch => {
  return bindActionCreators(
    {
      createNewInstruction: invoiceDetailActions.createNewInstruction,
      deleteInvoiceNote: invoiceListActions.deleteInvoiceNote,
      updateInvoiceNote: invoiceListActions.updateInvoiceNote
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecialInstructionsContainer);
