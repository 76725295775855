import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import constants from 'src/constants';
import messages from 'src/messages';
import {
  UserClassification,
  IEntityStateRoles,
  IUserResponse,
  IEntityStateManagersObject
} from 'src/shared/users/types';
import { parseDateForDatepicker } from 'src/shared/utils';
import { Container, CustomButton } from './styles';
import { Department, DepartmentUser } from 'src/api/types';
import UserAttributeChip from '../UserAttributeChip';
import Flex from '../core/Flex';

export type UserEditErrors = {
  [x in keyof IUserResponse]?: boolean;
};

type Props = {
  user: IUserResponse;
  roles: IEntityStateRoles;
  managers: IEntityStateManagersObject;
  departments: Department[];
  departmentUsers: DepartmentUser[];
  loading: boolean;
  errors: UserEditErrors;
  onChange: (prop: string, newValue: string | number | boolean) => void;
  onSave: () => void;
  onCancel: () => void;
  createDepartmentUser: ({
    userId,
    departmentId
  }: {
    userId: number;
    departmentId: number;
  }) => void;
  onDeleteDepartmentUser: (id: number) => any;
};

const UserEdit = ({
  errors,
  managers,
  createDepartmentUser,
  departmentUsers,
  departments,
  user,
  onSave,
  roles,
  onCancel,
  onChange,
  loading,
  onDeleteDepartmentUser
}: Props): JSX.Element => {
  const { id } = user;

  function roleSelectRenderer(selected: any) {
    return (
      <>
        {selected
          .filter((value: number) =>
            roles[value] ? roles[value].name !== constants.USER_ROLE : false
          )
          .map((value: any) => (
            <Chip key={value} label={roles[value] ? roles[value].name : ''} />
          ))}
      </>
    );
  }

  function notificationSelectRenderer(selected: string[]) {
    return (
      <>
        {_.map(selected, (value: string) => {
          const lab = constants.NOTIFICATION_PREF.find(
            (cur: { key: string; label: string }) => cur.key === value
          );
          return <Chip key={value} label={lab ? lab.label : null} />;
        })}
      </>
    );
  }

  return (
    <Container>
      <h3>{messages.EDIT_MEMBER}</h3>
      <TextField
        required={true}
        error={!!errors.firstName}
        id="first-name-add-input"
        label={messages.LABELS.FIRST_NAME}
        helperText={errors.firstName ? messages.NAME_ERROR : ''}
        value={user.firstName}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChange('firstName', event.target.value)
        }
        margin="normal"
        fullWidth={true}
        variant="outlined"
      />
      <TextField
        required={true}
        id="last-name-add-input"
        error={!!errors.lastName}
        label={messages.LABELS.LAST_NAME}
        value={user.lastName}
        helperText={errors.lastName ? messages.NAME_ERROR : ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChange('lastName', event.target.value)
        }
        margin="normal"
        fullWidth={true}
        variant="outlined"
      />
      <TextField
        id="start-add-input"
        label={messages.LABELS.START_DATE}
        type="date"
        variant="outlined"
        margin="normal"
        fullWidth={true}
        value={parseDateForDatepicker(
          moment(user.startDate).format('MM/DD/YYYY')
        )}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChange('startDate', event.target.value)
        }
        InputLabelProps={{
          shrink: true
        }}
      />
      <TextField
        error={!!errors.email}
        required={true}
        id="email-add-input"
        label={messages.LABELS.EMAIL}
        value={user.email}
        helperText={errors.email ? messages.EMAIL_ERROR : ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChange('email', event.target.value)
        }
        margin="normal"
        fullWidth={true}
        variant="outlined"
      />
      <FormControl
        error={!!errors.notificationPreferences}
        variant="outlined"
        fullWidth={true}
        margin="normal"
      >
        <InputLabel htmlFor="notification-pref-add">
          {messages.LABELS.NOTIFICATION_PREF}
        </InputLabel>
        <Select
          multiple={true}
          value={_.map(user.notificationPreferences, (pref: string) =>
            _.upperCase(pref)
          )}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChange('notificationPreferences', event.target.value)
          }
          input={<FilledInput id="notification-pref-add-chip" />}
          renderValue={notificationSelectRenderer}
        >
          {constants.NOTIFICATION_PREF.map(current => (
            <MenuItem key={current.key} value={current.key}>
              {current.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        error={!!errors.title}
        required={true}
        id="title-add-input"
        label="Title"
        fullWidth={true}
        value={user.title}
        helperText={errors.title ? messages.TITLE_ERROR : ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChange('title', event.target.value)
        }
        margin="normal"
        variant="outlined"
      />
      <FormControl
        error={!!errors.roles}
        required={false}
        variant="outlined"
        fullWidth={true}
        margin="normal"
      >
        <InputLabel htmlFor="roles-add">{messages.LABELS.ROLES}</InputLabel>
        <Select
          multiple={true}
          value={user.roles}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChange('roles', event.target.value)
          }
          input={<FilledInput id="roles-add-chip" />}
          renderValue={roleSelectRenderer}
        >
          {Object.keys(roles)
            .filter((current: string) =>
              roles[current]
                ? roles[current].name !== constants.USER_ROLE
                : false
            )
            .map((current: string) => (
              <MenuItem
                key={`roles-l-${roles[current].id}`}
                value={roles[current].id}
              >
                {roles[current] ? roles[current].name : null}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>{errors.roles ? messages.REQUIRED : ''}</FormHelperText>
      </FormControl>
      <TextField
        error={!!errors.managerId}
        required={true}
        id="manager-add-select"
        select={true}
        label={messages.LABELS.MANAGER}
        value={user.managerId}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChange('managerId', event.target.value)
        }
        fullWidth={true}
        margin="normal"
        variant="outlined"
        helperText={errors.managerId ? messages.REQUIRED : ''}
      >
        {Object.keys(managers).map((current: any) => (
          <MenuItem
            key={`managers-l-${managers[current].id}`}
            value={managers[current].id}
          >
            {managers[current].fullName}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        error={!!errors.classification}
        required={true}
        id="employment-add-select"
        select={true}
        label={messages.LABELS.EMPLOYMENT_TYPE}
        value={user.classification}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChange('classification', event.target.value)
        }
        fullWidth={true}
        margin="normal"
        variant="outlined"
      >
        {[
          UserClassification.SALARIED,
          UserClassification.HOURLY,
          UserClassification.CONTRACTOR
        ].map(current => (
          <MenuItem key={current} value={current}>
            {current}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        select
        margin="normal"
        variant="outlined"
        label={messages.LABELS.ADD_DEPARTMENT}
        value=""
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const newDepartments: string[] = _.difference(
            [event.target.value],
            departmentUsers.map(({ departmentId }: DepartmentUser) =>
              departmentId.toString()
            )
          );
          if (newDepartments.length) {
            const newDepartment: string | undefined = _.head(newDepartments);
            if (newDepartment) {
              createDepartmentUser({
                userId: id,
                departmentId: parseInt(newDepartment, 10)
              });
              onChange('departments', event.target.value);
            }
          }
        }}
      >
        {_(departments)
          .filter(
            ({ id }: Department) =>
              _.findIndex(departmentUsers, { departmentId: id }) < 0
          )
          .map(department => (
            <MenuItem key={department.id} value={department.id}>
              {department.name}
            </MenuItem>
          ))
          .value()}
      </TextField>
      <Flex width="100%" flexDirection="column">
        {_.map(departmentUsers, (departmentUser: DepartmentUser) => (
          <UserAttributeChip
            key={departmentUser.id}
            canEdit={true}
            attribute={_.find(departments, {
              id: departmentUser.departmentId
            })}
            userAttribute={departmentUser}
            deleteUserAttribute={({ id }) => onDeleteDepartmentUser(id)}
          />
        ))}
      </Flex>
      {loading ? (
        <CircularProgress />
      ) : (
        <React.Fragment>
          <CustomButton
            fullWidth={true}
            color="primary"
            onClick={onSave}
            variant="contained"
          >
            {messages.SAVE}
          </CustomButton>
          <CustomButton fullWidth={true} variant="contained" onClick={onCancel}>
            {messages.CANCEL}
          </CustomButton>
        </React.Fragment>
      )}
    </Container>
  );
};

export default UserEdit;
