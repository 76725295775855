import styled, { theme } from 'src/theme';
import { Box } from '@mui/material';
export const width = 532;

export const PaperDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${width}px;
  background-color: ${theme.palette.background.paper};
  border: 1px solid #212121;
  box-shadow: ${theme.shadows[5]};
  box-sizing: content-box;
  padding: 0;
  outline: none !important;
`;

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f2f2;
  padding: 8px;
`;

export const calendarTheme = {
  selectionColor: theme.button.lightenedPrimaryColor,
  weekdayColor: theme.button.primaryColor
};
