import styled, { theme } from 'src/theme';
import { FlexBox } from 'src/components/SubNav/styles';
import { SubNavWrapper } from 'src/components/SubNav/styles';

export const SubNavInvoiceWrapper = styled(SubNavWrapper)`
  border-bottom: none;
`;

export const FlexStartBox = styled(FlexBox)`
  justify-content: flex-start;
  margin-left: 24px;
`;

export const ClientText = styled.div`
  display: flex;
  font-style: italic;
`;

export const NavTab = styled.div`
  display: flex;
  font-size: 14px;
  color: rgba(33, 33, 33, 0.87);
  border: ${theme.border};
  border-right: none;
  background-color: ${theme.white};
  padding: 9px 16px;
  cursor: pointer;

  &:first-child {
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    border-radius: 0 8px 8px 0;
    border-right: ${theme.border};
  }

  &.active {
    background-color: rgba(0, 131, 143, 0.1);
    color: ${theme.tealDark};
  }
`;

export const Count = styled.div`
  font-weight: 600;
`;

export const NavTabsContainer = styled.div`
  display: flex;
  &:first-child {
    margin-right: 40px;
  }
`;

export const ClientItem = styled.div`
  display: flex;
  align-items: center;
`;
