import React, { useState } from 'react';
import { Alert } from '@mui/material';
import { ActionButton, Container, SpanButton } from './styles';
import messages from 'src/messages';
import { IImpersonateDialogProps } from './types';

const ImpersonateDialog = ({ stopImpersonate }: IImpersonateDialogProps) => {
  const [isCollapsed, collapse] = useState(false);
  return (
    <Container className={isCollapsed ? 'collapsed' : ''}>
      <Alert
        style={{ alignItems: 'center' }}
        severity="warning"
        variant="outlined"
        action={
          <ActionButton color="inherit" onClick={() => stopImpersonate()}>
            {messages.IMPERSONATE_SWITCH}
          </ActionButton>
        }
      >
        {!isCollapsed && messages.IMPERSONATE_MESSAGE}{' '}
        <SpanButton onClick={() => collapse(!isCollapsed)}>
          {isCollapsed ? 'Expand' : 'Collapse'}
        </SpanButton>
      </Alert>
    </Container>
  );
};

export default ImpersonateDialog;
