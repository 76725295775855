import * as React from 'react';
import ClientsList from '../../components/ClientList';
import ClientsListFilters from '../../components/ClientListFilters';
import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { QueryKeys } from 'src/queries/queryKeys';
import { useQuery } from 'react-query';
import { Api } from 'src/api/api';

interface IClientListContainerState {
  name: any;
  status: any;
  [x: string]: any;
}

const ClientListContainer: FunctionComponent = () => {
  const navigate = useNavigate();
  const [state, setState] = useState<IClientListContainerState>({
    name: '',
    status: 0
  });

  const { data: clientsList = [] } = useQuery(QueryKeys.clients, () =>
    Api.fetchClients()
  );

  const onFilterChanged = (prop: string) => (event: any) => {
    const newState = {
      ...state,
      [prop]: event.target.value
    };
    setState(newState);
  };

  // strange Object.keys call on array
  const filteredClients = () => {
    let clients: any = Object.keys(clientsList)
      .filter(key => key !== 'undefined')
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: clientsList[key]
        };
      }, {});

    if (state.name !== '') {
      clients = Object.keys(clients)
        .filter(key =>
          clients[key].name.toUpperCase().includes(state.name.toUpperCase())
        )
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: clients[key]
          };
        }, {});
    }
    if (state.status !== 'any') {
      clients = Object.keys(clients)
        .filter(key => (clients[key].isActive ? 0 : 1) === state.status)
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: clients[key]
          };
        }, {});
    }

    return clients;
  };

  const checkIfThereIsNoFilter = () => {
    const { name, status } = state;

    return name === '' && status === 'any';
  };

  const clearFilters = () => {
    setState({
      name: '',
      status: 'any'
    });
  };

  const redirectToAdd = () => {
    navigate('/client-management/add');
  };

  const goToDetail = (event: any) => {
    navigate(`/client-management/${event.rowData.id}`);
  };

  const { name, status } = state;

  return (
    <React.Fragment>
      <ClientsListFilters
        clearFilters={clearFilters}
        noFilter={checkIfThereIsNoFilter()}
        onChange={onFilterChanged}
        name={name}
        status={status}
      />
      <ClientsList
        goToDetail={goToDetail}
        onAddClick={redirectToAdd}
        clients={filteredClients()}
      />
    </React.Fragment>
  );
};

export default ClientListContainer;
