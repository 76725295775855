import TextField from '@mui/material/TextField';
import Button, { ButtonProps } from '@mui/material/Button';
import styled from '../../theme';

export const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`;

export const CustomButton: React.ComponentType<ButtonProps> = styled(Button)`
  && {
    margin: 10px 0 10px 0;
  }
` as any;

export const CustomInput = styled(TextField)`
  && {
    width: 100%;
    input {
      font-size: 14px;
    }
    .MuiOutlinedInput-adornedStart {
      padding-left: 9px;
    }
  }
`;
