import styled from 'src/theme';
import { Icon } from '@mui/material';
import { Row, Panel } from '../styles';

export const TimeOffPanel = styled(Panel)`
  position: relative;
  padding-bottom: 10px;
  border-bottom: ${props => props['border-data']};
`;

export const TimeOffRow = styled(Row)`
  min-width: 60px;
  color: #3f3f3f;
  &.no-padding {
    padding: 0 18px;
  }
`;

export const CustomIcon = styled(Icon)`
  position: absolute;
  padding-bottom: 4px;
`;
