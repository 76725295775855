import { AxiosError } from 'axios';
import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { IClient } from 'src/types/IClient';
import { transformClientResponse } from './utils';

const SUCCESS_MESSAGE = 'Client created';
const DEFAULT_ERROR_MESSAGE = 'Failed to create client';

export const createClient = (client: Partial<IClient>) =>
  callAPI({
    data: { client },
    method: 'post',
    url: '/clients',
    transform: transformClientResponse
  })
    .then(({ data: { client } }) => {
      toast(SUCCESS_MESSAGE, 'success');
      return client as IClient;
    })
    .catch(error => {
      const message = makeErrorMessage(error);
      toast(message, 'error');
      throw error;
    });

const makeErrorMessage = (error: AxiosError) => {
  const messages = [DEFAULT_ERROR_MESSAGE];
  const errorData = error.response?.data as any;
  if (errorData) {
    Object.keys(errorData).map(key => {
      messages.push(`${key} ${errorData[key]}`);
    });
  }
  return messages.join('. ');
};
