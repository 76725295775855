import _ from 'lodash';
import { callAPI } from 'src/services/api';
import { handleProjectRoleUpsertError } from './utils';

export type UpdateTimesheetEntryParams = {
  classification?: string;
  deliverable?: number;
  description?: string;
  dismissed?: boolean;
  hours?: number;
  invoicingDescription?: string;
  invoicingHours?: number;
  projectRole?: number;
  timesheetId?: number;
};

export const updateTimesheetEntry = ({
  id,
  entry
}: {
  id: number;
  entry: UpdateTimesheetEntryParams;
}) => {
  const payload = _.omit(entry, ['project', 'deliverable']);
  if (entry.deliverable && entry.projectRole) {
    payload.deliverable = entry.deliverable;
    payload.projectRole = entry.projectRole;
  }

  return callAPI({
    method: 'put',
    url: `/timesheet_entries/${id}`,
    data: { entry }
  })
    .then(response => response.data.projectRole)
    .catch(error => {
      handleProjectRoleUpsertError(error);
      throw error;
    });
};
