import { Button, Box } from '@mui/material';
import styled, { theme } from 'src/theme';

export const FlexBox = styled(Box)`
  display: flex;
`;

export const Wrapper = styled(FlexBox)`
  padding: 24px;
  flex-direction: column;
  p {
    color: #212121;
    font-family: ${theme.font};
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 48px;
  }
`;

export const Footer = styled(FlexBox)``;

export const CancelButton = styled(Button)`
  && {
    border: 1px solid #000000;
    border-radius: 5px;
    background-color: #ffffff;
    height: 37px;
    width: 109px;
    margin-left: 16px;
    font-family: ${theme.font};
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    text-transform: none;
  }
`;

export const LeaveButton = styled(Button)`
  && {
    border-radius: 5px;
    background-color: #000000;
    height: 37px;
    width: 109px;
    color: #fff;
    font-family: ${theme.font};
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    text-transform: none;
  }
  &&:hover {
    background-color: #212121;
  }
`;
