import { Hidden, IconButton } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import * as React from 'react';
import constants from 'src/constants';
import UserInfoContainer from 'src/containers/UserInfoContainer';
import {
  CustomAppBar,
  SeparatorLeft,
  CustomLink,
  FlexBox,
  FlexBoxGrow,
  PageTitle
} from './styles';
import { FunctionComponent } from 'react';
export interface IAppBar {
  mobileDrawerIsOpened: boolean;
  drawerIsOpen: boolean;
  toogleMenuMobile: () => void;
  hideMenuClick: () => void;
  path: string | null | undefined;
  position?:
    | 'fixed'
    | 'absolute'
    | 'sticky'
    | 'static'
    | 'relative'
    | undefined;
  children?: JSX.Element | JSX.Element[] | null;
}

export const AppBar: FunctionComponent<IAppBar> = props => {
  const { position, children } = props;

  const header = (() => {
    let title = '';
    let parentLink = '';
    let breadCrumb = false;
    const { path } = props;

    if (path) {
      constants.BACK_PATHS.forEach((backPath: RegExp) => {
        if (backPath.test(path)) {
          breadCrumb = true;
        }
      });
      constants.MENUS.forEach(current => {
        current.inner.forEach(inner => {
          if (path && path.includes(inner.key)) {
            title = inner.text;
            parentLink = inner.key;
          }
        });
      });
    }

    return { title, parentLink, breadCrumb };
  })();

  return (
    <CustomAppBar
      drawerOpened={props.drawerIsOpen}
      position={position || 'fixed'}
    >
      <FlexBox>
        <FlexBoxGrow>
          <Hidden mdUp={true} implementation="css">
            <IconButton
              onClick={props.toogleMenuMobile}
              aria-label="Open drawer"
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden smDown={true} implementation="css">
            {!props.drawerIsOpen ? (
              <IconButton
                onClick={props.hideMenuClick}
                aria-label="Open drawer"
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <SeparatorLeft />
            )}
          </Hidden>
          {header.breadCrumb ? (
            <CustomLink to={`${header.parentLink}`}>
              <ChevronLeft />
              <span className="appBarTitle">{header.title}</span>
            </CustomLink>
          ) : (
            <PageTitle>{header.title}</PageTitle>
          )}
        </FlexBoxGrow>
        {children}
        <UserInfoContainer />
      </FlexBox>
    </CustomAppBar>
  );
};

export default AppBar;
