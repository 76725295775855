import React from 'react';
import { Box } from '@mui/material';
import styled from 'src/theme';
import { formatDatePeriod, parseDateToMomentDate } from 'src/shared/utils';
import { TableCellProps } from 'react-virtualized';
import { removeUTCSignal, isTwoPeriodsOld } from 'src/shared/invoice/utils';

const InvoiceDateColumn = styled(Box)`
  display: flex;
`;

export const InvoiceDate = (props: TableCellProps) => {
  const {
    rowData: { startDate, endDate, status }
  } = props;

  const invoicePeriod = formatDatePeriod(
    removeUTCSignal(startDate),
    removeUTCSignal(endDate),
    true
  );

  const statusBool = !['SENT', 'DISMISSED', 'PAID'].includes(status);
  const dateColor =
    statusBool && isTwoPeriodsOld(parseDateToMomentDate(endDate)) ? 'red' : '';

  return (
    <InvoiceDateColumn className={`${dateColor}`}>
      {' '}
      {invoicePeriod}{' '}
    </InvoiceDateColumn>
  );
};
