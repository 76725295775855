import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { removeEmpty } from 'src/shared/utils';
import {
  Timesheet,
  TimesheetSearchOrderEnum,
  TimesheetStatusEnum
} from '../types';

export const DEFAULT_FETCH_TIMESHEETS_DIRECTION = 'desc';
export const DEFAULT_FETCH_TIMESHEETS_ORDER =
  TimesheetSearchOrderEnum.START_DATE;
export const DEFAULT_FETCH_TIMESHEETS_PAGE = 1;
export const DEFAULT_FETCH_TIMESHEETS_PER_PAGE = 10;
export const DEFAULT_FETCH_TIMESHEETS_STATUS =
  TimesheetStatusEnum.APPROVAL_VIEW;

export const fetchTimesheets = (
  params: {
    direction?: string;
    endDate?: string;
    managerId?: number;
    order?: string;
    ownerId?: number;
    page?: number;
    perPage?: number;
    search?: string;
    startDate?: string;
    status?: TimesheetStatusEnum;
  } = {}
) => {
  const direction = params.direction || DEFAULT_FETCH_TIMESHEETS_DIRECTION;
  const order = params.order || DEFAULT_FETCH_TIMESHEETS_ORDER;
  const page = params.page || DEFAULT_FETCH_TIMESHEETS_PAGE;
  const perPage = params.perPage || DEFAULT_FETCH_TIMESHEETS_PER_PAGE;
  const status = params.status || DEFAULT_FETCH_TIMESHEETS_STATUS;
  const urlPart1 = '/timesheets';
  const urlPart2 = {
    [TimesheetStatusEnum.APPROVED]: '/approved',
    [TimesheetStatusEnum.CURRENT]: '/in_progress',
    [TimesheetStatusEnum.TO_APPROVE]: '/to_approve',
    [TimesheetStatusEnum.APPROVAL_VIEW]: '/approval_view',
    [TimesheetStatusEnum.ALL]: '/all',
    [TimesheetStatusEnum.REJECTED]: '',
    [TimesheetStatusEnum.NEW]: '',
    [TimesheetStatusEnum.DISMISSED]: ''
  }[status];

  return callAPI<Timesheet[]>({
    method: 'get',
    url: `${urlPart1}${urlPart2}`,
    params: {
      ...removeEmpty(params),
      direction,
      order,
      page,
      perPage,
      status
    }
  })
    .then(response => response.data)
    .catch(error => {
      toast('Failed to fetch current timesheet', 'error');
      throw error;
    });
};
