import styled, { theme } from 'src/theme';
import { Wrapper } from 'src/components/shared/ConfirmModal/styles';
import { PrimaryButton } from 'src/shared/primary-button';
import { SecondaryButton } from 'src/shared/secondary-button';

export const Dialog = styled(Wrapper)`
  && {
    position: absolute;
    background: white;
    margin-top: -250px;
    z-index: 999;
    border-radius: 5px;
    h4 {
      margin: 0;
    }
    border: 1px solid ${theme.borderColor};

    &:after,
    &:before {
      position: absolute;
      content: ' ';
      height: 0;
      width: 0;
    }

    &:after {
      bottom: -9px;
      left: 26px;
      border-style: solid;
      border-width: 9px 11px 0 11px;
      border-color: ${theme.white} transparent transparent transparent;
    }
    &:before {
      bottom: -10px;
      left: 25px;
      border-style: solid;
      border-width: 10px 12px 0 12px;
      border-color: ${theme.borderColor} transparent transparent transparent;
    }
  }
`;

export const CustomPrimaryButton = styled(PrimaryButton)`
  && {
    margin-right: 10px;
    white-space: nowrap;
  }
`;

export const CustomSecondaryButton = styled(SecondaryButton)`
  && {
    white-space: nowrap;
  }
`;
