import MenuItem from '@mui/material/MenuItem';
import DeleteSearch from '@mui/icons-material/Close';
import * as React from 'react';
import messages from 'src/messages';
import { Container, CustomFab, CustomInput, InnerContainer } from './styles';
import { IClient } from 'src/types/IClient';
import { IUserResponse } from 'src/shared/users/types';
import { ChangeEvent } from 'react';

interface IProjectsListFilters {
  clearFilters: () => void;
  clients?: IClient[];
  clientId?: number;
  managerId?: number;
  managers?: IUserResponse[];
  noFilter?: boolean;
  searchText?: string;
  shouldHideClients: boolean;
  shouldHideManager: boolean;
  status: boolean;
  onChangeClientId: (value?: number) => any;
  onChangeManagerId: (value?: number) => any;
  onChangeSearchText: (value?: string) => any;
  onChangeStatus: (value: boolean) => any;
}

const ProjectsListFilters: React.FunctionComponent<IProjectsListFilters> = ({
  clearFilters,
  clients = [],
  clientId,
  managerId,
  managers = [],
  noFilter = true,
  searchText,
  shouldHideClients,
  shouldHideManager,
  status,
  onChangeClientId,
  onChangeManagerId,
  onChangeSearchText,
  onChangeStatus
}) => {
  const onChangeClientSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === 'any' ? undefined : Number(e.target.value);
    onChangeClientId(value);
  };

  const onChangeManagerSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === 'any' ? undefined : Number(e.target.value);
    onChangeManagerId(value);
  };

  return (
    <Container>
      <InnerContainer>
        <CustomInput
          id="status-select"
          select={true}
          label="Display"
          value={Number(status)}
          onChange={e => onChangeStatus(e.target.value === '1')}
          margin="normal"
          variant="outlined"
        >
          <MenuItem key={'active'} value="1">
            {messages.ACTIVE}
          </MenuItem>
          <MenuItem key={'disabled'} value="0">
            {messages.INACTIVE}
          </MenuItem>
        </CustomInput>
        {!shouldHideManager && (
          <CustomInput
            id="role-select"
            select={true}
            onChange={onChangeManagerSelect}
            label={messages.LABELS.PROJECT_MANAGER}
            value={managerId || 'any'}
            margin="normal"
            variant="outlined"
          >
            <MenuItem key={'any'} value={'any'}>
              {messages.ANY_MANAGER}
            </MenuItem>
            {managers.map(manager => (
              <MenuItem key={manager.id} value={manager.id}>
                {manager.fullName}
              </MenuItem>
            ))}
          </CustomInput>
        )}
        {!shouldHideClients && (
          <CustomInput
            id="client-select"
            select={true}
            onChange={onChangeClientSelect}
            label={'Client'}
            value={clientId || 'any'}
            margin="normal"
            variant="outlined"
          >
            <MenuItem key={'any'} value={'any'}>
              {messages.ANY_CLIENT}
            </MenuItem>
            {clients.map(client => (
              <MenuItem key={client.id} value={client.id}>
                {client.name}
              </MenuItem>
            ))}
          </CustomInput>
        )}
        <CustomInput
          id="name-input"
          onChange={e => onChangeSearchText(e.target.value)}
          label="Name"
          value={searchText || ''}
          margin="normal"
          variant="outlined"
        />
        <CustomFab
          onClick={clearFilters}
          disabled={!clientId && !managerId && !searchText?.length && status}
          color="primary"
          size="small"
          aria-label="Delete search"
        >
          <DeleteSearch />
        </CustomFab>
      </InnerContainer>
    </Container>
  );
};

export default ProjectsListFilters;
