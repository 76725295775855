import { gql, TypedDocumentNode } from '@apollo/client';
import { Invoice } from '../types';

export type SearchInvoicesResponse =
  | {
      data: {
        nodes: Invoice[];
        pageInfo: {
          hasNextPage: boolean;
          hasPreviousPage: boolean;
          startCursor: string;
          endCursor: string;
        };
      };
    }
  | undefined;

export type SearchInvoicesVariables = {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  searchAll?: boolean;
  searchText?: string;
  sortBy?: 'PROJECT' | 'DATE' | 'STATUS';
  sortDirection?: 'ASC' | 'DESC';
  status?:
    | 'NEW'
    | 'IN_PROGRESS'
    | 'SUBMITTED'
    | 'APPROVED'
    | 'REJECTED'
    | 'DISMISSED'
    | 'PAID'
    | 'SENT';
};

export const searchInvoices: TypedDocumentNode<
  SearchInvoicesResponse,
  SearchInvoicesVariables
> = gql`
  query searchInvoices(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchAll: Boolean
    $searchText: String
    $sortBy: SearchInvoicesSortBy
    $sortDirection: SortDirection
    $status: [SearchInvoicesStatus!]
  ) {
    data: searchInvoices(
      after: $after
      before: $before
      first: $first
      last: $last
      searchAll: $searchAll
      searchText: $searchText
      sortBy: $sortBy
      sortDirection: $sortDirection
      status: $status
    ) {
      nodes {
        amount
        clientCode
        clientColor
        clientId
        clientName
        clientQbCustomerId
        dateCreated
        endDate
        id
        lastUpdated
        projectId
        projectIsActive
        projectManagerId
        projectManagerName
        projectName
        projectNickname
        rejectionReason
        startDate
        status
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;
