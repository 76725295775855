import { callAPI } from 'src/services/api';
import { toast } from 'src/services/toast';
import { InvoiceCounts } from '../types';

export const fetchInvoiceCounts = (searchAll: boolean, searchText?: string) =>
  callAPI<InvoiceCounts>({
    params: { searchAll, searchText },
    method: 'get',
    url: '/invoices/counts'
  })
    .then(response => response.data)
    .catch(error => {
      toast('Failed to fetch invoice counts', 'error');
      throw error;
    });
