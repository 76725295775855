import { RowMouseEventHandlerParams } from 'react-virtualized';

export interface IRole {
  default: boolean;
  id: number;
  isActive: boolean;
  name: string;
  label: string;
}

export interface IGetUserParams {
  hasRecords?: boolean;
}

// user payload with optional parameters
export interface IUserPayload {
  classification?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  isActive?: boolean;
  managerId?: number;
  notificationPreferences?: string[];
  roleIds?: number[];
  slackDisplayName?: string;
  startDate?: string;
  title?: string;
  id: number;
}

export interface IImpersonateUserResponse {
  firstName: string;
  fullName: string;
  id: number;
  lastName: string;
}

export interface INotificationPreference {
  id: number;
  notificationMethod: string;
  userId: number;
}
export enum UserClassification {
  CONTRACTOR = 'Contractor',
  HOURLY = 'Hourly',
  SALARIED = 'Salaried'
}

// user without roles returned from impersonate user api
export interface IUser extends IImpersonateUserResponse {
  canBeDeleted?: boolean;
  classification: UserClassification;
  contractor: boolean;
  email: string;
  isActive: boolean;
  managerId?: number;
  notificationPreferences?: string[];
  slackDisplayName: string;
  startDate: string;
  title: string;
}

// Transformed(camelized) response from server
export interface IUserResponse extends IUser {
  roles: number[];
}

export interface IEntityStateRoles {
  [id: number]: IRole;
}

export interface IEntityStateUsers {
  [id: number]: IUserResponse;
}

// respresentation of single normalized user
export interface INormalizedUser {
  entities: {
    roles: IEntityStateRoles;
    users: IEntityStateUsers;
  };
  result: number;
}

// respresentation of single normalized role
export interface INormalizedRole {
  entities: {
    roles: IEntityStateRoles;
  };
  result: number;
}

// respresentation of normalized users
export interface IUserEntities {
  entities: {
    managers: IEntityStateUsers;
    roles: IEntityStateRoles;
    users: IEntityStateUsers;
  };
}

export interface IUserSearchParams {
  departmentId?: number;
  id?: number;
  isActive?: boolean;
  roleIds?: number[];
  roleName?: string;
}

// representation of entity state in redux store
export interface IEntityState {
  managers: IEntityStateUsers;
  projectManagers: IEntityStateUsers;
  roles: IEntityStateRoles;
  users: IEntityStateUsers;
  usersList: IEntityStateUsersList[];
  updateUserCompleted: boolean;
  loadingUserAdd: boolean;
  loadingUserEdit: boolean;
  loadingUsers: boolean;
}
export interface IUserListProps {
  users: IEntityStateUsersList[];
  loading?: boolean;
  goToDetail: (event: RowMouseEventHandlerParams) => void;
  onAddClick: () => void;
  selectedItem?: any;
}

export interface IEntityStateUsersList {
  fullName: string;
  id: number;
  isActive: boolean;
  roles: number[];
  title: string;
  managerId: number;
  managerFullName: string;
}

export interface IEntityStateManagersObject {
  [id: number]: IUser;
}
