import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IAppState } from 'src/reducer';
import * as usersActions from 'src/shared/users/actions';
import { IEntityStateUsers } from 'src/shared/users/types';
import ProjectsAdd from '../../components/ProjectsAdd';
import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { QueryKeys } from 'src/queries/queryKeys';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Api } from 'src/api/api';

export type ProjectsParamsContainerState = {
  client: string | number;
  route: string;
  isClientDisabled: boolean;
};

type IProjectsAddContainerProps = IProjectsAddContainerReduxProps &
  ProjectsParamsContainerState &
  IProjectsAddContainerDispatch;

interface IProjectsAddContainerDispatch {
  getManagers: () => void;
}

interface IProjectsAddContainerReduxProps {
  managers: IEntityStateUsers;
}

interface IProjectsAddContainerState {
  errors: any;
  name: string;
  nickname: string;
  manager: string | number;
  client: string | number;
  budgetPercentage: string;
  status: number;
  invoicingSpecialInstructions: string | null;
  [x: string]: any;
}

const ProjectsAddContainer: FunctionComponent<IProjectsAddContainerProps> = props => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [state, setState] = useState<IProjectsAddContainerState>({
    budgetPercentage: 'hours',
    client: props.client,
    errors: {
      budgetPercentage: false,
      client: false,
      manager: false,
      name: false,
      nickname: false,
      status: false
    },
    manager: '',
    name: '',
    nickname: '',
    status: 1,
    invoicingSpecialInstructions: null
  });

  useEffect(() => {
    props.getManagers();
  }, []);

  const { mutate: createProject, isLoading: isCreatingProject } = useMutation(
    Api.createProject,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.projectStats]);
        navigate(`/${props.route}`, { replace: true });
      }
    }
  );

  const values = () => {
    const {
      budgetPercentage,
      client,
      manager,
      name,
      nickname,
      status,
      invoicingSpecialInstructions
    } = state;

    return {
      budgetPercentage,
      client,
      manager,
      name,
      nickname,
      status,
      invoicingSpecialInstructions
    };
  };

  const errors = () => {
    const {
      budgetPercentage,
      client,
      manager,
      name,
      nickname,
      status
    } = state.errors;

    return {
      budgetPercentage,
      client,
      manager,
      name,
      nickname,
      status
    };
  };

  const submitIsValid = () => {
    const { client, manager, name, nickname } = state;
    const errors = {
      client: client === '',
      manager: manager === '',
      name: name === '',
      nickname: nickname === ''
    };

    setState({
      ...state,
      errors
    });

    return !(errors.client || errors.manager || errors.name || errors.nickname);
  };

  const onFormChange = (prop: string, value: string) => {
    setState({
      ...state,
      [prop]: value
    });
  };

  const onSave = () => {
    if (submitIsValid()) {
      createProject(values());
    }
  };

  const onCancel = () => {
    navigate(`/${props.route}`, { replace: true });
  };

  const { data: activeClients = [] } = useQuery(QueryKeys.clients, () =>
    Api.fetchClients({ isActive: true })
  );

  return (
    <ProjectsAdd
      loading={isCreatingProject}
      errors={errors()}
      onSave={onSave}
      onCancel={onCancel}
      managers={props.managers}
      onChange={onFormChange}
      values={values()}
      clients={activeClients}
      isClientDisabled={props.isClientDisabled}
    />
  );
};

export const mapStateToProps = (state: IAppState) => {
  const managers = state.entities.projectManagers;
  return {
    managers
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IProjectsAddContainerDispatch => {
  return bindActionCreators(
    { getManagers: usersActions.actions.getProjectManagers },
    dispatch
  );
};

export default connect<
  IProjectsAddContainerReduxProps,
  IProjectsAddContainerDispatch
>(
  mapStateToProps,
  mapDispatchToProps
)(ProjectsAddContainer);
