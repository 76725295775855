import React from 'react';
import { StyledMoreVert } from './styles';
import { Menu, MenuItem } from '@mui/material';

export const MoreVertMenu = ({
  showDismiss,
  dismissItems,
  resetAllItems
}: {
  showDismiss: boolean;
  dismissItems: (value: boolean) => void;
  resetAllItems: () => void;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);

  const openMenu = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };
  const menu = (
    <React.Fragment>
      <StyledMoreVert onClick={openMenu} key="menu-anchor" />
      <Menu
        key="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem
          key="dismiss"
          onClick={() => {
            closeMenu();
            dismissItems(showDismiss);
          }}
        >
          {showDismiss ? 'Dismiss' : 'Restore'}
        </MenuItem>
        <MenuItem
          key="reset"
          onClick={() => {
            closeMenu();
            resetAllItems();
          }}
        >
          Reset All Entries
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
  return menu;
};

export default MoreVertMenu;
