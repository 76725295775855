import React from 'react';
import _ from 'lodash';
import { Modal, Box } from '@mui/material';
import Close from '@mui/icons-material/Close';
import {
  CustomInput,
  Header,
  Title,
  Footer,
  CancelButton,
  SaveButton,
  SubTitle,
  FlexBox,
  RatesBox,
  Warning,
  StyledError,
  StyledMenuItem,
  WarningBox
} from './styles';
import { formatAsCurrency, formatAsTitle } from 'src/shared/utils';
import styled, { theme } from 'src/theme';
import { ProjectRole } from 'src/api/types';

interface IRejectionModal {
  onSave: (val: number) => void;
  onCancel: () => void;
  list: any[];
  current: number;
  currentOther: number;
  title: string;
  projectNickname: string;
  conflicts: { deliverableId: number; projectRoleId: number }[];
}

const PaperDiv = styled.div`
  position: absolute;
  top: 50%,
  left: 50%,
  transform: translate(-50%, -50%);
  width: 532px;
  background-color: ${theme.palette.background.paper};
  border: 1px solid #212121;
  border-radius: 5px;
  box-shadow: ${theme.shadows[5]};
  padding: 0;
  outline: none !important;
`;

export const EditDeliverableRoleModal = ({
  onSave,
  onCancel,
  list,
  current,
  currentOther,
  title,
  projectNickname,
  conflicts
}: IRejectionModal) => {
  const isDeliverableModal = title === 'Deliverable';
  const [val, setVal] = React.useState(current);
  const type = isDeliverableModal ? 'deliverableId' : 'projectRoleId';
  const other = isDeliverableModal ? 'projectRoleId' : 'deliverableId';
  return (
    <Modal open onClose={onCancel} onBackdropClick={onCancel}>
      <PaperDiv>
        <Box display="flex" flexDirection="column">
          <Header>
            <Title>Edit {formatAsTitle(title)}</Title>
            <Close onClick={onCancel} />
          </Header>
          {_.some(
            conflicts,
            (conflict: { deliverableId: number; projectRoleId: number }) =>
              conflict[type] === val && conflict[other] === currentOther
          ) && (
            <WarningBox>
              <Warning>WARNING</Warning> You are about to combine the timesheet
              entries for this deliverable with one that is already on this
              invoice. This action cannot be undone.
            </WarningBox>
          )}
          <CustomInput
            label={formatAsTitle(title)}
            classes={{ root: 'dropdown' }}
            size="small"
            margin="normal"
            variant="outlined"
            select
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              e.stopPropagation();
              setVal(parseInt(e.target.value));
            }}
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
              e.stopPropagation()
            }
            value={val}
          >
            {list.map(item => (
              <StyledMenuItem key={item.id} value={item.id}>
                {item.name}{' '}
                {_.some(
                  conflicts,
                  (conflict: {
                    projectRoleId: number;
                    deliverableId: number;
                  }) =>
                    conflict[type] === item.id &&
                    conflict[other] === currentOther
                ) && <StyledError />}
              </StyledMenuItem>
            ))}
          </CustomInput>
          {title === 'Role' && (
            <RatesBox>
              <SubTitle>Roles and Rates for {projectNickname}</SubTitle>
              {_.map(list, (item: ProjectRole) => (
                <FlexBox>
                  <span>{item.name}</span>
                  <span>{formatAsCurrency(item.rate)}</span>
                </FlexBox>
              ))}
            </RatesBox>
          )}
          <Footer>
            <SaveButton disabled={!val} onClick={() => onSave(val)}>
              Save
            </SaveButton>
            <CancelButton onClick={() => onCancel()}>Cancel</CancelButton>
          </Footer>
        </Box>
      </PaperDiv>
    </Modal>
  );
};

export default EditDeliverableRoleModal;
