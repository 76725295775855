import { IAuthState } from 'src/shared/auth/types';
import * as userActions from 'src/shared/users/actions';
import * as authActions from './actions';

const initialState = {
  impersonateUsers: [],
  isLoggedIn: false,
  isImpersonating: false,
  loadingImpersonate: false,
  verfiedLoginState: false
};

const reducer = (
  state: IAuthState = initialState,
  action: authActions.Actions | userActions.Actions
) => {
  switch (action.type) {
    case authActions.GET_IMPERSONATE_USERS: {
      return {
        ...state,
        loadingImpersonate: true
      };
    }
    case authActions.GET_IMPERSONATE_USERS_SUCCEEDED: {
      const { userData } = action.payload;
      return {
        ...state,
        impersonateUsers: userData,
        loadingImpersonate: false
      };
    }
    case userActions.GET_CURRENT_USER_SUCCEEDED: {
      const { userData } = action.payload;
      return {
        ...state,
        verfiedLoginState: true,
        currentUser: userData,
        isLoggedIn: true
      };
    }
    case authActions.IMPERSONATE_SUCCEEDED: {
      return {
        ...state,
        isImpersonating: true
      };
    }

    case authActions.STOP_IMPERSONATE_SUCCEEDED: {
      return {
        ...state,
        isImpersonating: false
      };
    }

    case authActions.LOGIN_SUCCEEDED: {
      return {
        ...state
      };
    }
    case authActions.LOGOUT_SUCCEEDED: {
      return {
        ...state,
        verfiedLoginState: true,
        currentUser: null,
        isLoggedIn: false
      };
    }
    case authActions.LOAD_ORIGINAL_USER_SUCCEEDED: {
      return {
        ...state,
        originalUser: action.payload.user
      };
    }
    default:
      return state;
  }
};

export default reducer;
