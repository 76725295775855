import Tabs, { TabsProps } from '@mui/material/Tabs';
import styled from '../../theme';

const CustomTab: React.ComponentType<TabsProps> = styled(Tabs)`
  && a {
    font-size: 12px;
    min-width: 0;
  }
` as any;

export default CustomTab;
