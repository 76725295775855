import * as React from 'react';
import _ from 'lodash';
import InvoiceDetail from 'src/components/InvoiceDetail';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from 'src/reducer';
import { actions as invoiceDetailActions } from 'src/shared/invoice/actions';
import { actions as invoiceActions } from 'src/shared/invoice-list/actions';
import {
  IInvoiceSidebarDetail,
  InvoiceStatus
} from 'src/shared/invoice-list/types';
import { ICurrentUser } from 'src/shared/auth/types';
import { isAdmin, isFinance } from 'src/shared/auth/utils';
import {
  IInvoiceDetailView,
  IFixedLineItem,
  ITimesheetRow,
  NewInvoiceState
} from 'src/shared/invoice/types';
import LoadingPage from 'src/components/shared/LoadingPage';
import InvoiceDetailFooter from 'src/components/InvoiceDetailFooter';
import { FunctionComponent, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from 'src/contexts/app-context';
import { Api } from 'src/api/api';
import { ProjectDetail, TimesheetEntry } from 'src/api/types';

export interface IInvoiceDetailContainerStateProps {
  view?: IInvoiceDetailView;
  storeFilters?: NewInvoiceState['filters'] | null;
  currentUser?: ICurrentUser;
  invoiceSidebarDetail: IInvoiceSidebarDetail;
  loading: boolean;
  projects: ProjectDetail[];
}

type IInvoiceDetailContainerProps = IInvoiceDetailContainerStateProps &
  IInvoiceDetailContainerDispatch;

const InvoiceDetailContainer: FunctionComponent<IInvoiceDetailContainerProps> = props => {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;

    if (id !== 'new') {
      props.clearDetail();
      props.hydrateInvoice({ id });
    }
  }, [id]);

  const { loading } = props;

  if (loading) {
    return <LoadingPage />;
  }

  const sendOverdueTimesheetNotification = (
    managerId: number,
    timesheetId: number
  ) => {
    if (!props.currentUser) return;

    Api.sendOverdueTimesheetNotification(
      props.currentUser,
      managerId,
      timesheetId
    );
  };

  return (
    <React.Fragment>
      <InvoiceDetail
        fetchMoreInvoices={props.fetchMoreSidebarInvoices}
        invoiceSidebarDetail={props.invoiceSidebarDetail}
        view={props.view}
        saveTimesheetRow={props.saveTimesheetRow}
        saveTimesheetEntry={props.saveTimesheetEntry}
        saveLineItem={props.saveLineItem}
        deleteLineItem={props.deleteLineItem}
        dismissAllTimesheets={props.dismissAllTimesheets}
        dismissTimesheetRowItem={props.dismissTimesheetRowItem}
        updateFixedLineItem={props.updateFixedLineItem}
        downloadFile={props.downloadFile}
        uploadNewFile={props.uploadNewFile}
        isNew={id === 'new'}
        createNewInvoice={dismiss => props.createNewInvoice(navigate, dismiss)}
        getNewInvoiceView={props.getNewInvoiceView}
        storeFilters={props.storeFilters}
        clearDetail={props.clearDetail}
        sendOverdueTimesheetNotification={sendOverdueTimesheetNotification}
        isAdmin={props.currentUser ? isAdmin(props.currentUser) : false}
        modifyLocalView={props.modifyLocalView}
        cancelTimesheetEntryChanges={props.cancelTimesheetEntryChanges}
        retrieveAllTimesheetEntriesFromPeriod={props.fetchTimesheetEntries}
        saveInvoice={props.saveInvoice}
        removeAttachedFileFromNewLineItem={props.clearAttachedFile}
        createNewInstruction={props.createNewInstruction}
        deleteInvoiceNote={props.deleteInvoiceNote}
        updateInvoiceNote={props.updateInvoiceNote}
        user={props.currentUser}
        openInvoiceDetail={(id: number) => {
          navigate(`/invoicing/${id}`);
        }}
        leftDrawerIsOpen={context.isLeftDrawerOpen}
      />
      <InvoiceDetailFooter
        isAdmin={props.currentUser ? isAdmin(props.currentUser) : false}
        isFinance={props.currentUser ? isFinance(props.currentUser) : false}
        changeStatus={(status: InvoiceStatus, reason?: string, id?: number) =>
          props.changeStatus(status, reason, id, navigate)
        }
        deleteInvoice={() => props.deleteInvoice(navigate)}
        saveInvoice={() =>
          props.saveInvoiceFromFooter({
            callback: navigate
          })
        }
        createNewInvoice={dismiss => props.createNewInvoice(navigate, dismiss)}
        view={props.view}
        exportTimesheet={props.exportTimesheet}
        downloadQbPdf={props.downloadQbPdf}
      />
    </React.Fragment>
  );
};

export const mapStateToProps = (state: IAppState) => {
  const view = state.invoice.localView;
  const storeFilters = state.invoice.filters;
  const currentUser = state.auth.currentUser;
  const invoiceSidebarDetail = state.invoices.invoiceSidebarDetail;
  const loading = state.invoice.loading || state.invoices.loading;
  const projects = state.invoices.projects;
  return {
    view,
    storeFilters,
    currentUser,
    invoiceSidebarDetail,
    loading,
    projects
  };
};

interface IInvoiceDetailContainerDispatch {
  modifyInvoice: () => void;
  hydrateInvoice: ({ id }: { id?: string }) => void;
  clearDetail: () => void;
  saveTimesheetRow: (item: ITimesheetRow) => void;
  saveTimesheetEntry: (item: TimesheetEntry) => void;
  saveLineItem: (item: IFixedLineItem) => void;
  deleteLineItem: (item: IFixedLineItem) => void;
  dismissAllTimesheets: () => void;
  dismissTimesheetRowItem: (item: ITimesheetRow) => void;
  updateFixedLineItem: (fixedLineItem: IFixedLineItem) => void;
  downloadFile: (id: number) => void;
  uploadNewFile: ({
    lineItemId,
    file
  }: {
    lineItemId?: number;
    file: File;
  }) => void;
  createNewInvoice: (cb: (path: string) => void, dismiss?: boolean) => void;
  getNewInvoiceView: ({
    filters
  }: {
    filters: NewInvoiceState['filters'];
  }) => void;
  modifyLocalView: (mutatedView: IInvoiceDetailView) => void;
  saveInvoice: ({ view }: { view: IInvoiceDetailView }) => void;
  cancelTimesheetEntryChanges: (
    userRowIndex: number,
    timesheetEntryId: number
  ) => void;
  fetchMoreSidebarInvoices: () => void;
  fetchTimesheetEntries: (timesheetId: number) => void;
  clearAttachedFile: () => void;
  createNewInstruction: ({
    text,
    typeOf
  }: {
    text: string;
    typeOf: number;
  }) => void;
  deleteInvoiceNote: ({ id }: { id: number }) => void;
  updateInvoiceNote: ({ text, id }: { text: string; id: number }) => void;
  changeStatus: (
    status: InvoiceStatus,
    reason?: string,
    id?: number,
    callback?: (path: string) => void
  ) => void;
  deleteInvoice: (cb: (path: string) => void) => void;
  saveInvoiceFromFooter: ({
    callback
  }: {
    callback: (path: string) => void;
  }) => void;
  exportTimesheet: () => void;
  downloadQbPdf: () => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch
): IInvoiceDetailContainerDispatch => {
  return bindActionCreators(
    {
      modifyLocalView: invoiceDetailActions.modifyLocalView,
      modifyInvoice: invoiceDetailActions.modifyInvoice,
      hydrateInvoice: invoiceDetailActions.hydrateInvoice,
      getNewInvoiceView: invoiceDetailActions.hydrateInvoice,
      saveInvoice: invoiceDetailActions.saveInvoice,
      clearDetail: invoiceDetailActions.clearDetail,
      deleteInvoice: (cb: (path: string) => void) =>
        invoiceDetailActions.deleteInvoice(cb),
      saveTimesheetRow: invoiceDetailActions.saveTimesheetRow,
      saveTimesheetEntry: invoiceDetailActions.saveTimesheetEntry,
      saveLineItem: invoiceDetailActions.saveLineItem,
      deleteLineItem: invoiceDetailActions.deleteLineItem,
      dismissAllTimesheets: invoiceDetailActions.dismissAllTimesheets,
      dismissTimesheetRowItem: invoiceDetailActions.dismissTimesheetRowItem,
      updateFixedLineItem: invoiceDetailActions.updateFixedLineItem,
      downloadFile: invoiceDetailActions.downloadFile,
      uploadNewFile: invoiceDetailActions.uploadNewFile,
      createNewInvoice: (cb: (path: string) => void, dismiss?: boolean) =>
        invoiceDetailActions.createNewInvoice(cb, dismiss),
      cancelTimesheetEntryChanges:
        invoiceDetailActions.cancelTimesheetEntryChanges,
      fetchMoreSidebarInvoices: invoiceActions.fetchMoreSidebarInvoices,
      fetchTimesheetEntries: invoiceDetailActions.fetchTimesheetEntries,
      clearAttachedFile: () =>
        invoiceDetailActions.populateFixedLineItemId({ id: null }),
      createNewInstruction: invoiceDetailActions.createNewInstruction,
      deleteInvoiceNote: invoiceActions.deleteInvoiceNote,
      updateInvoiceNote: invoiceActions.updateInvoiceNote,
      changeStatus: invoiceDetailActions.changeInvoiceStatus,
      saveInvoiceFromFooter: invoiceDetailActions.saveInvoice,
      exportTimesheet: invoiceDetailActions.exportTimesheet,
      downloadQbPdf: invoiceDetailActions.downloadQbPdf
    },
    dispatch
  );
};

export default connect<
  IInvoiceDetailContainerStateProps,
  IInvoiceDetailContainerDispatch
>(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceDetailContainer);
