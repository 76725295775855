import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  createInvoiceEmail as createInvoiceEmailApi,
  deleteInvoiceEmail as deleteInvoiceEmailApi,
  handleError
} from 'src/services/api';
import { toast } from 'src/services/toast';
import * as invoiceEmailActions from './actions';

function* createInvoiceEmail(action: invoiceEmailActions.Actions): any {
  try {
    if (action.type === invoiceEmailActions.CREATE_INVOICE_EMAIL) {
      const { projectId, invoiceEmailData, callback } = action.payload;
      const response = yield call(
        createInvoiceEmailApi,
        projectId,
        invoiceEmailData
      );
      yield put(invoiceEmailActions.actions.createInvoiceEmailSucceded());
      yield call(callback, response.data.invoiceEmail);
      toast('Project updated', 'success');
    }
  } catch (e) {
    yield put(invoiceEmailActions.actions.createInvoiceEmailSucceded());
    toast(
      'There was an error creating the invoice email, Please check the inputs and try again.',
      'error'
    );
    yield call(handleError, e as Error);
  }
}

function* deleteInvoiceEmail(action: invoiceEmailActions.Actions) {
  try {
    if (action.type === invoiceEmailActions.DELETE_INVOICE_EMAIL) {
      const { id, callback } = action.payload;
      yield call(deleteInvoiceEmailApi, id);
      if (callback) {
        callback(id);
      }
      toast('Project updated', 'success');
    }
  } catch (e) {
    toast('Error deleting the invoice email', 'error');
    yield call(handleError, e as Error);
    throw e;
  }
}

function* invoiceEmailsSaga() {
  yield all([
    takeLatest(invoiceEmailActions.CREATE_INVOICE_EMAIL, createInvoiceEmail),
    takeLatest(invoiceEmailActions.DELETE_INVOICE_EMAIL, deleteInvoiceEmail)
  ]);
}

export default invoiceEmailsSaga;
