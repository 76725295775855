import styled from 'src/theme';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { AddButton } from 'src/shared/add-button';

import * as React from 'react';

export const Container = styled.div`
  padding: 0 24px 0 24px;
  flex-direction: row;
  align-items: center;
  display: flex;
  top: 175px;
  z-index: 3;
  background-color: #fff;
`;

export const CustomInput: React.ComponentType<TextFieldProps> = styled(
  TextField
)`
  && {
    width: 200px;
    margin-right: 10px;
  }
` as any;

export const StyledAddButton = styled(AddButton)`
  && {
    margin: 25px 0 0 25px;
  }
`;
