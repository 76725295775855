import * as React from 'react';
import _ from 'lodash';
import AppBar, { IAppBar } from '../../components/AppBar';
import InvoiceAppBarContainer from 'src/containers/InvoiceAppBarContainer';
import InvoiceDetailAppBarContainer from '../InvoiceDetailAppBarContainer';
import TimesheetAppBarContainer from '../TimesheetAppBarContainer';
import { FunctionComponent, useContext } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AppContext } from 'src/contexts/app-context';

export const AppBarContainer: FunctionComponent = props => {
  const context = useContext(AppContext);
  const location = useLocation();

  const appBar = (children?: IAppBar['children']) => (
    <AppBar
      drawerIsOpen={context.isLeftDrawerOpen}
      mobileDrawerIsOpened={context.isLeftMobileDrawerOpen}
      toogleMenuMobile={context.toggleLeftMobileDrawer}
      hideMenuClick={context.toggleLeftDrawer}
      path={location.pathname}
      children={children}
    />
  );

  return (
    <Routes>
      <Route path="/invoicing" element={appBar(<InvoiceAppBarContainer />)} />
      <Route
        path="/invoicing/:id"
        element={appBar(<InvoiceDetailAppBarContainer />)}
      />
      <Route
        path="/timesheet/:id"
        element={appBar(<TimesheetAppBarContainer />)}
      />
      <Route path="*" element={appBar()} />
    </Routes>
  );
};

export default AppBarContainer;
