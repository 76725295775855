import { Button } from '@mui/material';
import AddCircle from '@mui/icons-material/AddCircle';
import Comment from '@mui/icons-material/Comment';
import styled from 'src/theme';

export const AddInstructionsButton = styled(Button)`
  && {
    color: #00838f;
    padding: 0;
    text-transform: none;
  }
`;

export const AddInstructionsIcon = styled(AddCircle)`
  && {
    color: #00838f;
    height: 14px;
    padding-right: 4px;
    width: 14px;
  }
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
`;

export const NoteHeader = styled.h5`
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin: 0;
  margin-top: 16px;
  padding: 0;
`;

export const NoteText = styled.p`
  color: rgba(33, 33, 33, 0.87);
  font-size: 14px;
  line-height: 18px;
  margin-top: 4px;
`;

export const StyledComment = styled(Comment)`
  && {
    height: 18px;
    width: 18px;
  }

  padding-right: 8px;
`;
