import Fab, { FabProps } from '@mui/material/Fab';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import styled from '../../theme';

export const CustomInput: React.ComponentType<TextFieldProps> = styled(
  TextField
)`
  && {
    min-width: 100px;
    margin-right: 20px;
  }
` as any;

export const Container = styled.div`
  margin: 20px 10px 0 10px;
  min-width: 650px;
  width: 100%;
  flex-direction: row;
  display: flex;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const CustomFab: React.ComponentType<FabProps> = styled(Fab)`
  && {
    margin: 25px 5px 5px 5px;
  }
` as any;
