import Fab, { FabProps } from '@mui/material/Fab';
import { Table, TableProps } from 'react-virtualized';
import styled, { theme } from '../../theme';

export const Container = styled.div`
  margin: 0 10px 20px 0;
  min-width: 650px;
  width: 100%;
  flex: 1;
`;

export const CustomTable: React.ComponentType<TableProps> = styled(Table)`
  & .row {
    box-sizing: border-box;
    border-bottom: 2px solid ${theme.primaryBackground};
    background-color: ${theme.secondaryBackground};
    display: -webkit-box;
    height: 50px;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: ${theme.font};
  }
  & .row:focus {
    outline: none;
  }
  & .row:hover {
    background-color: ${theme.separatorBackground};
    cursor: pointer;
  }
  & .row.active {
    background-color: ${theme.separatorBackground};
  }
  & .ReactVirtualized__Table__headerRow:hover {
    background-color: ${theme.primaryBackground};
    cursor: default;
  }
  & .ReactVirtualized__Table__headerRow {
    background-color: #fff;
    color: ${theme.secondaryColorInverted};
  }
  & .column,
  .headerRow {
    margin-left: 10px;
    margin-right: 10px;
  }
  & .client-name-project {
    text-transform: uppercase;
  }
  & .client-name-project .client-project-box {
    padding-top: 3.7%;
  }
  & .client-name-project .bold {
    font-weight: bold;
  }

  & .red {
    color: ${theme.red};
  }

  & .hidden {
    display: none;
  }
` as any;

export const Disabled = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${theme.secondaryColorInverted};
`;

export const ClientIcon = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin-left: 5px;
  background-color: ${props => props.color};
`;

export const AddFab: React.ComponentType<FabProps> = styled(Fab)`
  && {
    margin: 16px 5px 5px 5px;
    position: absolute;
    bottom: -22px;
    right: 10px;
  }
` as any;
