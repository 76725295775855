import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  useMediaQuery
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import * as React from 'react';
import DialogTransition from '../DialogTransition';
import { Container, CustomDrawer, RightToggle } from './styles';
import { theme } from 'src/theme';

type IRightDrawerProps = {
  children: any;
  opened: boolean;
  toggle: () => void;
  showOnSm: boolean;
  onSmClose?: () => void;
};

const RightDrawer = (props: IRightDrawerProps): JSX.Element => {
  const { opened, toggle, showOnSm, onSmClose, children } = props;

  const isSmOrXs = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Container smDown implementation="css" open={opened}>
        <CustomDrawer
          anchor="right"
          variant="permanent"
          classes={{ docked: 'custom-docked' }}
          open={opened}
        >
          <RightToggle onClick={toggle}>
            {opened ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          </RightToggle>
          {children}
        </CustomDrawer>
      </Container>
      <Dialog
        fullScreen={true}
        open={showOnSm && isSmOrXs}
        onClose={onSmClose}
        TransitionComponent={DialogTransition}
      >
        <AppBar>
          <Toolbar>
            <IconButton color="inherit" onClick={onSmClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {children}
      </Dialog>
    </>
  );
};

export default RightDrawer;
