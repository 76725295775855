import { Link } from 'react-router-dom';
import styled, { theme } from '../../../theme';

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  padding-bottom: 0px;

  .close-button {
    padding: 4px;
  }
`;

export const HeaderTitle = styled.div`
  align-items: center;
  display: flex;

  .title {
    color: #212121;
    font-family: 'Source Sans Pro';
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    margin-left: 8px;
    padding: 0;
  }
`;

export const HeaderIcon = styled.div`
  background-color: ${props => props.color};
  border-radius: 100%;
  height: 16px;
  width: 16px;
`;

export const InvoiceLink = styled(Link)`
  && {
    color: #00838f;
    font-family: ${theme.font};
    font-size: 14px;
    line-height: 18px;
    text-decoration: none;
  }
`;

export const InvoicePopoverContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  height: 80vh;
  position: absolute;
  overflow: auto;
  right: 16px;
  top: 20px;
  width: 386px;
`;

export const Section = styled.div`
  border-bottom: 1px solid #d4d4d4;
  padding: 16px;

  .section-header {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin: 0;
    margin-bottom: 4px;
    padding: 0;
    text-transform: uppercase;
  }
`;

export const SectionInfo = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  .section-info {
    align-items: center;
    display: flex;
    padding: 4px 0;

    &.value-end {
      justify-content: space-between;

      .section-info-label {
        width: auto;
      }
    }
  }

  .section-info-label {
    color: #757575;
    display: block;
    font-size: 14px;
    line-height: 18px;
    padding: 4px 0;
    width: 76px;
  }

  .section-info-value {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    line-height: 18px;
    margin-left: 40px;
  }

  .team-list {
    padding: 0;
  }
`;
