import * as React from 'react';
import { StyledPopper } from './styles';
import { PopperProps } from '@mui/material/Popper';
import { FunctionComponent, ReactNode, useRef } from 'react';

export const MomentPopper: FunctionComponent<PopperProps> = props => {
  const arrowRef = useRef(null);

  const { anchorEl, children, open } = props;

  if (!open) {
    return null;
  }

  return (
    <StyledPopper
      placement="top"
      modifiers={{
        flip: {
          enabled: true
        },
        arrow: {
          enabled: true,
          element: arrowRef.current
        }
      }}
      open
      anchorEl={anchorEl}
    >
      {true && <span className="arrow" ref={arrowRef} />}
      <div className="inner">{children as ReactNode}</div>
    </StyledPopper>
  );
};

export default MomentPopper;
