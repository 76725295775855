import * as React from 'react';
import ProjectsEditContainer from 'src/containers/ProjectsEditContainer';
import { GRIO_ORG_ID } from 'src/constants';
import { FunctionComponent } from 'react';

export const InHouseProjectsEditContainer: FunctionComponent = () => (
  <ProjectsEditContainer client={GRIO_ORG_ID} route={'inhouse-projects'} />
);

export default InHouseProjectsEditContainer;
