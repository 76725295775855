import * as React from 'react';
import ProjectsAddContainer from 'src/containers/ProjectsAddContainer';
import { FunctionComponent } from 'react';

export const ClientProjectsAddContainer: FunctionComponent = () => (
  <ProjectsAddContainer
    client={''}
    route={'client-projects'}
    isClientDisabled={false}
  />
);

export default ClientProjectsAddContainer;
