import { Table, TableProps } from 'react-virtualized';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import styled, { theme } from '../../theme';

export const Container = styled.div`
  display: flex;
  flex: 1;
  min-width: 650px;
  width: 100%;
  height: 100%;

  &.compressed {
    width: calc(100% - 384px);
  }
`;

export const FlexBox = styled(Box)`
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const LeftShiftedFlexBox = styled(FlexBox)`
  margin-left: -4px;
`;

export const PaddedSpan = styled.span`
  padding: 0 4px;
`;

export const CustomTable: React.ComponentType<TableProps> = styled(Table)`
  & .row {
    box-sizing: border-box;
    border-bottom: 2px solid ${theme.primaryBackground};
    background-color: ${theme.secondaryBackground};
    display: -webkit-box;
    height: 50px;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: ${theme.font};
  }
  & .row:focus {
    outline: none;
  }
  & .row:hover {
    background-color: ${theme.separatorBackground};
    cursor: pointer;
  }
  & .active {
    background-color: ${theme.separatorBackground};
  }
  & .ReactVirtualized__Table__headerRow:hover {
    background-color: #fff;
    cursor: default;
  }
  & .ReactVirtualized__Table__headerRow {
    background-color: #fff;
    color: ${theme.secondaryColorInverted};
  }
  & .headerRow {
    outline: transparent;
    :last-child {
      min-width: 142px;
    }
  }
  & .column,
  .headerRow {
    margin-left: 10px;
    margin-right: 10px;
    text-overflow: ellipsis;
  }
  & .action {
    min-width: 142px;
  }
` as any;

export const ActionContainer = styled.div`
  display: flex;
  width: 160px;

  & > * {
    width: 100%;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .MuiSvgIcon-root {
    fill: #00838f;
  }
`;

export const StyledButton = styled(Button)`
  && {
    background-color: ${theme.teal};
    font-size: 16px;
    height: 36px;
    color: white;
    white-space: nowrap;
    padding: 6px 15px;
    margin: 8px 18px 0;
    text-transform: none;
    & span {
      width: 108px;
      font-weight: 200;
    }
  }

  &&:hover {
    background-color: ${theme.tealAlt};
  }
`;

export const ImpersonateActionButton = styled(Button)`
  width: 134px;
`;
