// NOTE: Target Box positioning on DOM does not work correctly with dev console open!!
// Close dev console before dropping file, or find the target box
// somewhat down and to the right of where you think it should be

import React from 'react';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop, DropTargetMonitor } from 'react-dnd';
import { StyledBox } from './styles';

const style: React.CSSProperties = {
  position: 'absolute',
  display: 'hidden',
  width: '50vw',
  height: '15em',
  lineHeight: '16px',
  marginTop: '28px'
};

export interface TargetBoxProps {
  onDrop: (props: TargetBoxProps, monitor: DropTargetMonitor) => void;
  lineItemCanBeAdded: boolean;
}

const TargetBox: React.FC<TargetBoxProps> = props => {
  const { onDrop, lineItemCanBeAdded } = props;

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      if (onDrop) {
        onDrop(props, monitor);
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  const isActive = canDrop && isOver;

  let text = isActive ? 'Release to drop' : 'Drop file here';

  if (!lineItemCanBeAdded) {
    text = 'Only one upload file per line item supported';
  }

  return (
    <>
      <div ref={drop} style={style}>
        {' '}
      </div>
      <StyledBox>{text}</StyledBox>
    </>
  );
};

export default TargetBox;
