import _ from 'lodash';
import { SelectOption } from './components/core/Select';

// Project types as passed down by create_options call
const PROJECT_FIXED_BID_TYPE = 'PROJECT_FIXED_BID';
const PROJECT_TIME_MATERIALS_TYPE = 'PROJECT_TIME_MATERIALS';
const STAFF_AUGMENTATION_TYPE = 'STAFF_AUGMENTATION';
const INTERNAL_TYPE = 'INTERNAL';
const USER_ROLE = 'USER';

// TODO: For now set this number high to hide pagination. We will need to address
// this later to handle pagination for invoice created + uninvoiced
export const DEFAULT_ITEMS_PER_PAGE = 20;

export const MAX_SEARCH_ITEMS = 5;
export const MAX_HOURS_PER_DAY = 24;
export const MAX_WORK_HOURS_IN_A_DAY = 8;
export const MAX_SIDEBAR_TIMESHEETS_PER_PAGE = 6;
export const MAXIMUM_API_RETURN_AMOUNT = 500;

export const PM = 'PM';
export const ADMIN = 'ADMIN';
export const FINANCE = 'FINANCE';
export const MANAGER = 'MANAGER';
export const ANY_USER = 'ANY';

export const SHORT_TEXT_CHAR_LIMIT = 255;

// Grio Organization Id
export const GRIO_ORG_ID = 1;

export type RoutePermission = {
  route: RegExp;
  roles: string[];
};

export const ROUTE_PERMISSIONS: RoutePermission[] = [
  {
    route: /administration.*/gi,
    roles: [ADMIN]
  },
  {
    route: /client-management.*/gi,
    roles: [PM, ADMIN]
  },
  {
    route: /invoicing.*/gi,
    roles: [PM, FINANCE, ADMIN]
  },
  { route: /client-projects.*/gi, roles: [PM, ADMIN] },
  {
    route: /inhouse-projects.*/gi,
    roles: [PM, ADMIN]
  },
  {
    route: /team-management.*/gi,
    roles: [MANAGER, ADMIN]
  },
  { route: /profile.*/gi, roles: [ANY_USER] },
  {
    route: /timesheet-approvals.*/gi,
    roles: [ADMIN, MANAGER, PM]
  },
  {
    route: /administration.*/gi,
    roles: [ADMIN]
  }
];

export const BENCH = 'BENCH';
export const CLIENT_ALLOCATION_PROJECT_TYPE = 'PROJECT';

export const BENCH_ALLOCATION_TYPE = {
  id: BENCH,
  name: 'Bench',
  bgColor: '#C7A1A6',
  borderColor: '#92454D',
  showOnDropdown: false,
  gradients: [
    '#C7A1A6',
    '#C7A5A9',
    '#C7A8AC',
    '#C7ACAF',
    '#C7AFB2',
    '#C7B3B5',
    '#C8B6B9',
    '#C8BABC',
    '#C8BDBF',
    '#C8C1C2',
    '#C8C4C5',
    '#C8C8C8'
  ]
};

export const ALLOCATION_PROJECT_TYPES = [
  BENCH_ALLOCATION_TYPE,
  {
    id: CLIENT_ALLOCATION_PROJECT_TYPE,
    name: 'Client',
    bgColor: '#8aaed3',
    borderColor: '#1F55A1',
    showOnDropdown: true,
    gradients: [
      '#8aaed3',
      '#7fa9d2',
      '#8bb0d4',
      '#9abad7',
      '#a6c0da',
      '#b2c7db',
      '#b7cadc',
      '#c3d1de',
      '#c8d3df',
      '#d4dae1',
      '#dadde2',
      '#e2e2e3'
    ]
  },
  {
    id: 'IN_HOUSE_PROJECT',
    name: 'GRIO',
    bgColor: '#96c7a4',
    borderColor: '#4B8C51',
    showOnDropdown: true,
    gradients: [
      '#96c7a4',
      '#9ac9a8',
      '#9fccac',
      '#a7d1b3',
      '#afd4b9',
      '#b6d8bf',
      '#b9dac2',
      '#badac3',
      '#c6e0cd',
      '#c8e1d0',
      '#c9e1d0',
      '#d0e5d6'
    ]
  },
  {
    id: 'OOO',
    name: 'OOO',
    bgColor: '#A4A4A4',
    borderColor: '#4A4A4A',
    showOnDropdown: true,
    gradients: [
      '#A4A4A4',
      '#A7A7A7',
      '#ABABAB',
      '#AEAEAE',
      '#B1B1B1',
      '#B4B4B4',
      '#B8B8B8',
      '#BBBBBB',
      '#BEBEBE',
      '#C1C1C1',
      '#C5C5C5',
      '#C8C8C8'
    ]
  }
];

const BACK_PATHS: RegExp[] = [new RegExp('invoicing/d*')]; // tests true for 'invoicing/123'

export default {
  BACK_PATHS,
  PROJECT_FIXED_BID_TYPE,
  PROJECT_TIME_MATERIALS_TYPE,
  STAFF_AUGMENTATION_TYPE,
  INTERNAL_TYPE,
  USER_ROLE,
  DEFAULT_ITEMS_PER_PAGE,
  MENUS: [
    {
      inner: [
        {
          implemented: true,
          key: '/timesheet',
          roles: [ANY_USER],
          text: 'My Timesheets'
        },
        {
          implemented: true,
          key: '/projects',
          roles: [ANY_USER],
          text: 'My Projects'
        },
        {
          implemented: true,
          key: '/profile',
          roles: [ANY_USER],
          text: 'My Profile'
        }
      ],
      key: 'user',
      roles: [ANY_USER],
      text: 'User'
    },
    {
      inner: [
        {
          implemented: true,
          key: '/timesheet-approvals',
          roles: [ADMIN, MANAGER, PM],
          text: 'Timesheets'
        },
        {
          implemented: true,
          key: '/team-management',
          roles: [MANAGER, ADMIN],
          text: 'Manage'
        }
      ],
      key: 'team',
      roles: [ADMIN, MANAGER, PM],
      text: 'Team'
    },
    {
      inner: [
        {
          implemented: true,
          key: '/client-projects',
          roles: [PM, ADMIN],
          text: 'Client Projects'
        },
        {
          implemented: true,
          key: '/inhouse-projects',
          roles: [PM, ADMIN],
          text: 'Internal Projects'
        },
        {
          implemented: true,
          key: '/invoicing',
          roles: [PM, ADMIN, FINANCE],
          text: 'Invoicing'
        }
      ],
      key: 'projects',
      roles: [PM, ADMIN, FINANCE],
      text: 'Projects'
    },
    {
      inner: [
        {
          implemented: true,
          key: '/client-management',
          roles: [PM, ADMIN],
          text: 'Client Management'
        }
      ],
      key: 'client-related',
      roles: [PM, ADMIN, FINANCE],
      text: 'Clients'
    }
  ],
  NOTIFICATION_PREF: [
    {
      key: 'EMAIL',
      label: 'Email'
    },
    {
      key: 'SLACK',
      label: 'Slack'
    }
  ]
};

// Icon list from material UI
export const ICON: { [key: string]: string } = {
  Abc: 'Abc',
  AccessAlarm: 'AccessAlarm',
  AccessAlarms: 'AccessAlarms',
  Accessibility: 'Accessibility',
  AccessibilityNew: 'AccessibilityNew',
  Accessible: 'Accessible',
  AccessibleForward: 'AccessibleForward',
  AccessTime: 'AccessTime',
  AccountBalance: 'AccountBalance',
  AccountBalanceWallet: 'AccountBalanceWallet',
  AccountBox: 'AccountBox',
  AccountCircle: 'AccountCircle',
  AccountTree: 'AccountTree',
  AcUnit: 'AcUnit',
  Adb: 'Adb',
  Add: 'Add',
  AddAlarm: 'AddAlarm',
  AddAlert: 'AddAlert',
  AddAPhoto: 'AddAPhoto',
  AddBox: 'AddBox',
  AddBusiness: 'AddBusiness',
  AddCard: 'AddCard',
  Addchart: 'Addchart',
  AddCircle: 'AddCircle',
  AddCircleOutline: 'AddCircleOutline',
  AddComment: 'AddComment',
  AddIcCall: 'AddIcCall',
  AddLink: 'AddLink',
  AddLocation: 'AddLocation',
  AddLocationAlt: 'AddLocationAlt',
  AddModerator: 'AddModerator',
  AddPhotoAlternate: 'AddPhotoAlternate',
  AddReaction: 'AddReaction',
  AddRoad: 'AddRoad',
  AddShoppingCart: 'AddShoppingCart',
  AddTask: 'AddTask',
  AddToDrive: 'AddToDrive',
  AddToHomeScreen: 'AddToHomeScreen',
  AddToPhotos: 'AddToPhotos',
  AddToQueue: 'AddToQueue',
  AdfScanner: 'AdfScanner',
  Adjust: 'Adjust',
  AdminPanelSettings: 'AdminPanelSettings',
  AdUnits: 'AdUnits',
  Agriculture: 'Agriculture',
  Air: 'Air',
  Airlines: 'Airlines',
  AirlineSeatFlat: 'AirlineSeatFlat',
  AirlineSeatFlatAngled: 'AirlineSeatFlatAngled',
  AirlineSeatIndividualSuite: 'AirlineSeatIndividualSuite',
  AirlineSeatLegroomExtra: 'AirlineSeatLegroomExtra',
  AirlineSeatLegroomNormal: 'AirlineSeatLegroomNormal',
  AirlineSeatLegroomReduced: 'AirlineSeatLegroomReduced',
  AirlineSeatReclineExtra: 'AirlineSeatReclineExtra',
  AirlineSeatReclineNormal: 'AirlineSeatReclineNormal',
  AirlineStops: 'AirlineStops',
  AirplanemodeActive: 'AirplanemodeActive',
  AirplanemodeInactive: 'AirplanemodeInactive',
  AirplaneTicket: 'AirplaneTicket',
  Airplay: 'Airplay',
  AirportShuttle: 'AirportShuttle',
  Alarm: 'Alarm',
  AlarmAdd: 'AlarmAdd',
  AlarmOff: 'AlarmOff',
  AlarmOn: 'AlarmOn',
  Album: 'Album',
  AlignHorizontalCenter: 'AlignHorizontalCenter',
  AlignHorizontalLeft: 'AlignHorizontalLeft',
  AlignHorizontalRight: 'AlignHorizontalRight',
  AlignVerticalBottom: 'AlignVerticalBottom',
  AlignVerticalCenter: 'AlignVerticalCenter',
  AlignVerticalTop: 'AlignVerticalTop',
  AllInbox: 'AllInbox',
  AllInclusive: 'AllInclusive',
  AllOut: 'AllOut',
  AlternateEmail: 'AlternateEmail',
  AltRoute: 'AltRoute',
  Analytics: 'Analytics',
  Anchor: 'Anchor',
  Android: 'Android',
  Animation: 'Animation',
  Announcement: 'Announcement',
  Aod: 'Aod',
  Apartment: 'Apartment',
  Api: 'Api',
  AppBlocking: 'AppBlocking',
  Apple: 'Apple',
  AppRegistration: 'AppRegistration',
  Approval: 'Approval',
  Apps: 'Apps',
  AppSettingsAlt: 'AppSettingsAlt',
  AppShortcut: 'AppShortcut',
  AppsOutage: 'AppsOutage',
  Architecture: 'Architecture',
  Archive: 'Archive',
  ArrowBack: 'ArrowBack',
  ArrowBackIos: 'ArrowBackIos',
  ArrowBackIosNew: 'ArrowBackIosNew',
  ArrowCircleDown: 'ArrowCircleDown',
  ArrowCircleLeft: 'ArrowCircleLeft',
  ArrowCircleRight: 'ArrowCircleRight',
  ArrowCircleUp: 'ArrowCircleUp',
  ArrowDownward: 'ArrowDownward',
  ArrowDropDown: 'ArrowDropDown',
  ArrowDropDownCircle: 'ArrowDropDownCircle',
  ArrowDropUp: 'ArrowDropUp',
  ArrowForward: 'ArrowForward',
  ArrowForwardIos: 'ArrowForwardIos',
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
  ArrowRightAlt: 'ArrowRightAlt',
  ArrowUpward: 'ArrowUpward',
  Article: 'Article',
  ArtTrack: 'ArtTrack',
  AspectRatio: 'AspectRatio',
  Assessment: 'Assessment',
  Assignment: 'Assignment',
  AssignmentInd: 'AssignmentInd',
  AssignmentLate: 'AssignmentLate',
  AssignmentReturn: 'AssignmentReturn',
  AssignmentReturned: 'AssignmentReturned',
  AssignmentTurnedIn: 'AssignmentTurnedIn',
  Assistant: 'Assistant',
  AssistantDirection: 'AssistantDirection',
  AssistantPhoto: 'AssistantPhoto',
  AssuredWorkload: 'AssuredWorkload',
  Atm: 'Atm',
  AttachEmail: 'AttachEmail',
  AttachFile: 'AttachFile',
  Attachment: 'Attachment',
  AttachMoney: 'AttachMoney',
  Attractions: 'Attractions',
  Attribution: 'Attribution',
  AudioFile: 'AudioFile',
  Audiotrack: 'Audiotrack',
  AutoAwesome: 'AutoAwesome',
  AutoAwesomeMosaic: 'AutoAwesomeMosaic',
  AutoAwesomeMotion: 'AutoAwesomeMotion',
  AutoDelete: 'AutoDelete',
  AutoFixHigh: 'AutoFixHigh',
  AutoFixNormal: 'AutoFixNormal',
  AutoFixOff: 'AutoFixOff',
  AutofpsSelect: 'AutofpsSelect',
  AutoGraph: 'AutoGraph',
  AutoMode: 'AutoMode',
  Autorenew: 'Autorenew',
  AutoStories: 'AutoStories',
  AvTimer: 'AvTimer',
  BabyChangingStation: 'BabyChangingStation',
  Backpack: 'Backpack',
  Backspace: 'Backspace',
  Backup: 'Backup',
  BackupTable: 'BackupTable',
  Badge: 'Badge',
  BakeryDining: 'BakeryDining',
  Balance: 'Balance',
  Balcony: 'Balcony',
  Ballot: 'Ballot',
  BarChart: 'BarChart',
  BatchPrediction: 'BatchPrediction',
  Bathroom: 'Bathroom',
  Bathtub: 'Bathtub',
  Battery0Bar: 'Battery0Bar',
  Battery1Bar: 'Battery1Bar',
  Battery20: 'Battery20',
  Battery2Bar: 'Battery2Bar',
  Battery30: 'Battery30',
  Battery3Bar: 'Battery3Bar',
  Battery4Bar: 'Battery4Bar',
  Battery50: 'Battery50',
  Battery5Bar: 'Battery5Bar',
  Battery60: 'Battery60',
  Battery6Bar: 'Battery6Bar',
  Battery80: 'Battery80',
  Battery90: 'Battery90',
  BatteryAlert: 'BatteryAlert',
  BatteryCharging20: 'BatteryCharging20',
  BatteryCharging30: 'BatteryCharging30',
  BatteryCharging50: 'BatteryCharging50',
  BatteryCharging60: 'BatteryCharging60',
  BatteryCharging80: 'BatteryCharging80',
  BatteryCharging90: 'BatteryCharging90',
  BatteryChargingFull: 'BatteryChargingFull',
  BatteryFull: 'BatteryFull',
  BatterySaver: 'BatterySaver',
  BatteryStd: 'BatteryStd',
  BatteryUnknown: 'BatteryUnknown',
  BeachAccess: 'BeachAccess',
  Bed: 'Bed',
  BedroomBaby: 'BedroomBaby',
  BedroomChild: 'BedroomChild',
  BedroomParent: 'BedroomParent',
  Bedtime: 'Bedtime',
  BedtimeOff: 'BedtimeOff',
  Beenhere: 'Beenhere',
  Bento: 'Bento',
  BikeScooter: 'BikeScooter',
  Biotech: 'Biotech',
  Blender: 'Blender',
  BlindsClosed: 'BlindsClosed',
  Block: 'Block',
  Bloodtype: 'Bloodtype',
  Bluetooth: 'Bluetooth',
  BluetoothAudio: 'BluetoothAudio',
  BluetoothConnected: 'BluetoothConnected',
  BluetoothDisabled: 'BluetoothDisabled',
  BluetoothDrive: 'BluetoothDrive',
  BluetoothSearching: 'BluetoothSearching',
  BlurCircular: 'BlurCircular',
  BlurLinear: 'BlurLinear',
  BlurOff: 'BlurOff',
  BlurOn: 'BlurOn',
  Bolt: 'Bolt',
  Book: 'Book',
  Bookmark: 'Bookmark',
  BookmarkAdd: 'BookmarkAdd',
  BookmarkAdded: 'BookmarkAdded',
  BookmarkBorder: 'BookmarkBorder',
  BookmarkRemove: 'BookmarkRemove',
  Bookmarks: 'Bookmarks',
  BookOnline: 'BookOnline',
  BorderAll: 'BorderAll',
  BorderBottom: 'BorderBottom',
  BorderClear: 'BorderClear',
  BorderColor: 'BorderColor',
  BorderHorizontal: 'BorderHorizontal',
  BorderInner: 'BorderInner',
  BorderLeft: 'BorderLeft',
  BorderOuter: 'BorderOuter',
  BorderRight: 'BorderRight',
  BorderStyle: 'BorderStyle',
  BorderTop: 'BorderTop',
  BorderVertical: 'BorderVertical',
  Boy: 'Boy',
  BrandingWatermark: 'BrandingWatermark',
  BreakfastDining: 'BreakfastDining',
  Brightness1: 'Brightness1',
  Brightness2: 'Brightness2',
  Brightness3: 'Brightness3',
  Brightness4: 'Brightness4',
  Brightness5: 'Brightness5',
  Brightness6: 'Brightness6',
  Brightness7: 'Brightness7',
  BrightnessAuto: 'BrightnessAuto',
  BrightnessHigh: 'BrightnessHigh',
  BrightnessLow: 'BrightnessLow',
  BrightnessMedium: 'BrightnessMedium',
  BrokenImage: 'BrokenImage',
  BrowseGallery: 'BrowseGallery',
  BrowserNotSupported: 'BrowserNotSupported',
  BrowserUpdated: 'BrowserUpdated',
  BrunchDining: 'BrunchDining',
  Brush: 'Brush',
  BubbleChart: 'BubbleChart',
  BugReport: 'BugReport',
  Build: 'Build',
  BuildCircle: 'BuildCircle',
  Bungalow: 'Bungalow',
  BurstMode: 'BurstMode',
  BusAlert: 'BusAlert',
  Business: 'Business',
  BusinessCenter: 'BusinessCenter',
  Cabin: 'Cabin',
  Cable: 'Cable',
  Cached: 'Cached',
  Cake: 'Cake',
  Calculate: 'Calculate',
  CalendarMonth: 'CalendarMonth',
  CalendarToday: 'CalendarToday',
  CalendarViewDay: 'CalendarViewDay',
  CalendarViewMonth: 'CalendarViewMonth',
  CalendarViewWeek: 'CalendarViewWeek',
  Call: 'Call',
  CallEnd: 'CallEnd',
  CallMade: 'CallMade',
  CallMerge: 'CallMerge',
  CallMissed: 'CallMissed',
  CallMissedOutgoing: 'CallMissedOutgoing',
  CallReceived: 'CallReceived',
  CallSplit: 'CallSplit',
  CallToAction: 'CallToAction',
  Camera: 'Camera',
  CameraAlt: 'CameraAlt',
  CameraEnhance: 'CameraEnhance',
  CameraFront: 'CameraFront',
  CameraIndoor: 'CameraIndoor',
  CameraOutdoor: 'CameraOutdoor',
  CameraRear: 'CameraRear',
  CameraRoll: 'CameraRoll',
  Cameraswitch: 'Cameraswitch',
  Campaign: 'Campaign',
  Cancel: 'Cancel',
  CancelPresentation: 'CancelPresentation',
  CancelScheduleSend: 'CancelScheduleSend',
  CandlestickChart: 'CandlestickChart',
  CarCrash: 'CarCrash',
  CardGiftcard: 'CardGiftcard',
  CardMembership: 'CardMembership',
  CardTravel: 'CardTravel',
  Carpenter: 'Carpenter',
  CarRental: 'CarRental',
  CarRepair: 'CarRepair',
  Cases: 'Cases',
  Casino: 'Casino',
  Cast: 'Cast',
  CastConnected: 'CastConnected',
  CastForEducation: 'CastForEducation',
  Castle: 'Castle',
  CatchingPokemon: 'CatchingPokemon',
  Category: 'Category',
  Celebration: 'Celebration',
  CellTower: 'CellTower',
  CellWifi: 'CellWifi',
  CenterFocusStrong: 'CenterFocusStrong',
  CenterFocusWeak: 'CenterFocusWeak',
  Chair: 'Chair',
  ChairAlt: 'ChairAlt',
  Chalet: 'Chalet',
  ChangeCircle: 'ChangeCircle',
  ChangeHistory: 'ChangeHistory',
  ChargingStation: 'ChargingStation',
  Chat: 'Chat',
  ChatBubble: 'ChatBubble',
  ChatBubbleOutline: 'ChatBubbleOutline',
  Check: 'Check',
  CheckBox: 'CheckBox',
  CheckBoxOutlineBlank: 'CheckBoxOutlineBlank',
  CheckCircle: 'CheckCircle',
  CheckCircleOutline: 'CheckCircleOutline',
  Checkroom: 'Checkroom',
  ChevronLeft: 'ChevronLeft',
  ChevronRight: 'ChevronRight',
  ChildCare: 'ChildCare',
  ChildFriendly: 'ChildFriendly',
  ChromeReaderMode: 'ChromeReaderMode',
  Church: 'Church',
  Circle: 'Circle',
  CircleNotifications: 'CircleNotifications',
  Class: 'Class',
  CleanHands: 'CleanHands',
  CleaningServices: 'CleaningServices',
  Clear: 'Clear',
  ClearAll: 'ClearAll',
  Close: 'Close',
  ClosedCaption: 'ClosedCaption',
  ClosedCaptionDisabled: 'ClosedCaptionDisabled',
  ClosedCaptionOff: 'ClosedCaptionOff',
  CloseFullscreen: 'CloseFullscreen',
  Cloud: 'Cloud',
  CloudCircle: 'CloudCircle',
  CloudDone: 'CloudDone',
  CloudDownload: 'CloudDownload',
  CloudOff: 'CloudOff',
  CloudQueue: 'CloudQueue',
  CloudSync: 'CloudSync',
  CloudUpload: 'CloudUpload',
  Co2: 'Co2',
  Code: 'Code',
  CodeOff: 'CodeOff',
  Coffee: 'Coffee',
  CoffeeMaker: 'CoffeeMaker',
  Collections: 'Collections',
  CollectionsBookmark: 'CollectionsBookmark',
  Colorize: 'Colorize',
  ColorLens: 'ColorLens',
  Comment: 'Comment',
  CommentBank: 'CommentBank',
  CommentsDisabled: 'CommentsDisabled',
  Commit: 'Commit',
  Commute: 'Commute',
  Compare: 'Compare',
  CompareArrows: 'CompareArrows',
  CompassCalibration: 'CompassCalibration',
  Compress: 'Compress',
  Computer: 'Computer',
  ConfirmationNumber: 'ConfirmationNumber',
  ConnectedTv: 'ConnectedTv',
  ConnectingAirports: 'ConnectingAirports',
  ConnectWithoutContact: 'ConnectWithoutContact',
  Construction: 'Construction',
  Contactless: 'Contactless',
  ContactMail: 'ContactMail',
  ContactPage: 'ContactPage',
  ContactPhone: 'ContactPhone',
  Contacts: 'Contacts',
  ContactSupport: 'ContactSupport',
  ContentCopy: 'ContentCopy',
  ContentCut: 'ContentCut',
  ContentPaste: 'ContentPaste',
  ContentPasteGo: 'ContentPasteGo',
  ContentPasteOff: 'ContentPasteOff',
  ContentPasteSearch: 'ContentPasteSearch',
  Contrast: 'Contrast',
  ControlCamera: 'ControlCamera',
  ControlPoint: 'ControlPoint',
  ControlPointDuplicate: 'ControlPointDuplicate',
  Cookie: 'Cookie',
  CoPresent: 'CoPresent',
  CopyAll: 'CopyAll',
  Copyright: 'Copyright',
  Coronavirus: 'Coronavirus',
  CorporateFare: 'CorporateFare',
  Cottage: 'Cottage',
  Countertops: 'Countertops',
  Create: 'Create',
  CreateNewFolder: 'CreateNewFolder',
  CreditCard: 'CreditCard',
  CreditCardOff: 'CreditCardOff',
  CreditScore: 'CreditScore',
  Crib: 'Crib',
  CrisisAlert: 'CrisisAlert',
  Crop: 'Crop',
  Crop169: 'Crop169',
  Crop32: 'Crop32',
  Crop54: 'Crop54',
  Crop75: 'Crop75',
  CropDin: 'CropDin',
  CropFree: 'CropFree',
  CropLandscape: 'CropLandscape',
  CropOriginal: 'CropOriginal',
  CropPortrait: 'CropPortrait',
  CropRotate: 'CropRotate',
  CropSquare: 'CropSquare',
  Css: 'Css',
  CurrencyBitcoin: 'CurrencyBitcoin',
  CurrencyExchange: 'CurrencyExchange',
  CurrencyFranc: 'CurrencyFranc',
  CurrencyLira: 'CurrencyLira',
  CurrencyPound: 'CurrencyPound',
  CurrencyRuble: 'CurrencyRuble',
  CurrencyRupee: 'CurrencyRupee',
  CurrencyYen: 'CurrencyYen',
  CurrencyYuan: 'CurrencyYuan',
  Curtains: 'Curtains',
  CurtainsClosed: 'CurtainsClosed',
  Cyclone: 'Cyclone',
  Dangerous: 'Dangerous',
  DarkMode: 'DarkMode',
  Dashboard: 'Dashboard',
  DashboardCustomize: 'DashboardCustomize',
  DataArray: 'DataArray',
  DataObject: 'DataObject',
  DataSaverOff: 'DataSaverOff',
  DataSaverOn: 'DataSaverOn',
  DataThresholding: 'DataThresholding',
  DataUsage: 'DataUsage',
  DateRange: 'DateRange',
  Deblur: 'Deblur',
  Deck: 'Deck',
  Dehaze: 'Dehaze',
  Delete: 'Delete',
  DeleteForever: 'DeleteForever',
  DeleteOutline: 'DeleteOutline',
  DeleteSweep: 'DeleteSweep',
  DeliveryDining: 'DeliveryDining',
  DensityLarge: 'DensityLarge',
  DensityMedium: 'DensityMedium',
  DensitySmall: 'DensitySmall',
  DepartureBoard: 'DepartureBoard',
  Description: 'Description',
  Deselect: 'Deselect',
  DesignServices: 'DesignServices',
  DesktopAccessDisabled: 'DesktopAccessDisabled',
  DesktopMac: 'DesktopMac',
  DesktopWindows: 'DesktopWindows',
  Details: 'Details',
  DeveloperBoard: 'DeveloperBoard',
  DeveloperBoardOff: 'DeveloperBoardOff',
  DeveloperMode: 'DeveloperMode',
  DeviceHub: 'DeviceHub',
  Devices: 'Devices',
  DevicesFold: 'DevicesFold',
  DevicesOther: 'DevicesOther',
  DeviceThermostat: 'DeviceThermostat',
  DeviceUnknown: 'DeviceUnknown',
  DialerSip: 'DialerSip',
  Dialpad: 'Dialpad',
  Diamond: 'Diamond',
  Difference: 'Difference',
  Dining: 'Dining',
  DinnerDining: 'DinnerDining',
  Directions: 'Directions',
  DirectionsBike: 'DirectionsBike',
  DirectionsBoat: 'DirectionsBoat',
  DirectionsBus: 'DirectionsBus',
  DirectionsCar: 'DirectionsCar',
  DirectionsOff: 'DirectionsOff',
  DirectionsRailway: 'DirectionsRailway',
  DirectionsRun: 'DirectionsRun',
  DirectionsSubway: 'DirectionsSubway',
  DirectionsTransit: 'DirectionsTransit',
  DirectionsWalk: 'DirectionsWalk',
  DirtyLens: 'DirtyLens',
  DisabledByDefault: 'DisabledByDefault',
  DiscFull: 'DiscFull',
  Discount: 'Discount',
  DisplaySettings: 'DisplaySettings',
  Dns: 'Dns',
  Dock: 'Dock',
  DocumentScanner: 'DocumentScanner',
  DoDisturb: 'DoDisturb',
  DoDisturbAlt: 'DoDisturbAlt',
  DoDisturbOff: 'DoDisturbOff',
  DoDisturbOn: 'DoDisturbOn',
  Domain: 'Domain',
  DomainAdd: 'DomainAdd',
  DomainDisabled: 'DomainDisabled',
  DomainVerification: 'DomainVerification',
  Done: 'Done',
  DoneAll: 'DoneAll',
  DoneOutline: 'DoneOutline',
  DoNotDisturb: 'DoNotDisturb',
  DoNotDisturbAlt: 'DoNotDisturbAlt',
  DoNotDisturbOff: 'DoNotDisturbOff',
  DoNotDisturbOn: 'DoNotDisturbOn',
  DoNotDisturbOnTotalSilence: 'DoNotDisturbOnTotalSilence',
  DoNotStep: 'DoNotStep',
  DoNotTouch: 'DoNotTouch',
  DonutLarge: 'DonutLarge',
  DonutSmall: 'DonutSmall',
  DoorBack: 'DoorBack',
  Doorbell: 'Doorbell',
  DoorFront: 'DoorFront',
  DoorSliding: 'DoorSliding',
  DoubleArrow: 'DoubleArrow',
  DownhillSkiing: 'DownhillSkiing',
  Download: 'Download',
  DownloadDone: 'DownloadDone',
  DownloadForOffline: 'DownloadForOffline',
  Downloading: 'Downloading',
  Drafts: 'Drafts',
  DragHandle: 'DragHandle',
  DragIndicator: 'DragIndicator',
  DriveEta: 'DriveEta',
  DriveFileMove: 'DriveFileMove',
  DriveFileRenameOutline: 'DriveFileRenameOutline',
  DriveFolderUpload: 'DriveFolderUpload',
  Dry: 'Dry',
  DryCleaning: 'DryCleaning',
  Duo: 'Duo',
  Dvr: 'Dvr',
  DynamicFeed: 'DynamicFeed',
  DynamicForm: 'DynamicForm',
  Earbuds: 'Earbuds',
  EarbudsBattery: 'EarbudsBattery',
  East: 'East',
  EdgesensorHigh: 'EdgesensorHigh',
  EdgesensorLow: 'EdgesensorLow',
  Edit: 'Edit',
  EditAttributes: 'EditAttributes',
  EditLocation: 'EditLocation',
  EditLocationAlt: 'EditLocationAlt',
  EditNotifications: 'EditNotifications',
  EditOff: 'EditOff',
  EditRoad: 'EditRoad',
  Egg: 'Egg',
  EggAlt: 'EggAlt',
  EighteenMp: 'EighteenMp',
  EightK: 'EightK',
  EightKPlus: 'EightKPlus',
  EightMp: 'EightMp',
  EightteenMp: 'EightteenMp',
  Eject: 'Eject',
  Elderly: 'Elderly',
  ElderlyWoman: 'ElderlyWoman',
  ElectricalServices: 'ElectricalServices',
  ElectricBike: 'ElectricBike',
  ElectricBolt: 'ElectricBolt',
  ElectricCar: 'ElectricCar',
  ElectricMeter: 'ElectricMeter',
  ElectricMoped: 'ElectricMoped',
  ElectricRickshaw: 'ElectricRickshaw',
  ElectricScooter: 'ElectricScooter',
  Elevator: 'Elevator',
  ElevenMp: 'ElevenMp',
  Email: 'Email',
  EmergencyRecording: 'EmergencyRecording',
  EmergencyShare: 'EmergencyShare',
  EMobiledata: 'EMobiledata',
  EmojiEmotions: 'EmojiEmotions',
  EmojiEvents: 'EmojiEvents',
  EmojiFlags: 'EmojiFlags',
  EmojiFoodBeverage: 'EmojiFoodBeverage',
  EmojiNature: 'EmojiNature',
  EmojiObjects: 'EmojiObjects',
  EmojiPeople: 'EmojiPeople',
  EmojiSymbols: 'EmojiSymbols',
  EmojiTransportation: 'EmojiTransportation',
  EnergySavingsLeaf: 'EnergySavingsLeaf',
  Engineering: 'Engineering',
  EnhancedEncryption: 'EnhancedEncryption',
  Equalizer: 'Equalizer',
  Error: 'Error',
  ErrorOutline: 'ErrorOutline',
  Escalator: 'Escalator',
  EscalatorWarning: 'EscalatorWarning',
  Euro: 'Euro',
  EuroSymbol: 'EuroSymbol',
  Event: 'Event',
  EventAvailable: 'EventAvailable',
  EventBusy: 'EventBusy',
  EventNote: 'EventNote',
  EventRepeat: 'EventRepeat',
  EventSeat: 'EventSeat',
  EvStation: 'EvStation',
  ExitToApp: 'ExitToApp',
  Expand: 'Expand',
  ExpandCircleDown: 'ExpandCircleDown',
  ExpandLess: 'ExpandLess',
  ExpandMore: 'ExpandMore',
  Explicit: 'Explicit',
  Explore: 'Explore',
  ExploreOff: 'ExploreOff',
  Exposure: 'Exposure',
  Extension: 'Extension',
  ExtensionOff: 'ExtensionOff',
  Face: 'Face',
  Facebook: 'Facebook',
  FaceRetouchingNatural: 'FaceRetouchingNatural',
  FaceRetouchingOff: 'FaceRetouchingOff',
  FactCheck: 'FactCheck',
  Factory: 'Factory',
  FamilyRestroom: 'FamilyRestroom',
  Fastfood: 'Fastfood',
  FastForward: 'FastForward',
  FastRewind: 'FastRewind',
  Favorite: 'Favorite',
  FavoriteBorder: 'FavoriteBorder',
  Fax: 'Fax',
  FeaturedPlayList: 'FeaturedPlayList',
  FeaturedVideo: 'FeaturedVideo',
  Feed: 'Feed',
  Feedback: 'Feedback',
  Female: 'Female',
  Fence: 'Fence',
  Festival: 'Festival',
  FiberDvr: 'FiberDvr',
  FiberManualRecord: 'FiberManualRecord',
  FiberNew: 'FiberNew',
  FiberPin: 'FiberPin',
  FiberSmartRecord: 'FiberSmartRecord',
  FifteenMp: 'FifteenMp',
  FileCopy: 'FileCopy',
  FileDownload: 'FileDownload',
  FileDownloadDone: 'FileDownloadDone',
  FileDownloadOff: 'FileDownloadOff',
  FileOpen: 'FileOpen',
  FilePresent: 'FilePresent',
  FileUpload: 'FileUpload',
  Filter: 'Filter',
  Filter1: 'Filter1',
  Filter2: 'Filter2',
  Filter3: 'Filter3',
  Filter4: 'Filter4',
  Filter5: 'Filter5',
  Filter6: 'Filter6',
  Filter7: 'Filter7',
  Filter8: 'Filter8',
  Filter9: 'Filter9',
  Filter9Plus: 'Filter9Plus',
  FilterAlt: 'FilterAlt',
  FilterAltOff: 'FilterAltOff',
  FilterBAndW: 'FilterBAndW',
  FilterCenterFocus: 'FilterCenterFocus',
  FilterDrama: 'FilterDrama',
  FilterFrames: 'FilterFrames',
  FilterHdr: 'FilterHdr',
  FilterList: 'FilterList',
  FilterListOff: 'FilterListOff',
  FilterNone: 'FilterNone',
  FilterTiltShift: 'FilterTiltShift',
  FilterVintage: 'FilterVintage',
  FindInPage: 'FindInPage',
  FindReplace: 'FindReplace',
  Fingerprint: 'Fingerprint',
  FireExtinguisher: 'FireExtinguisher',
  Fireplace: 'Fireplace',
  FirstPage: 'FirstPage',
  Fitbit: 'Fitbit',
  FitnessCenter: 'FitnessCenter',
  FitScreen: 'FitScreen',
  FiveG: 'FiveG',
  FiveK: 'FiveK',
  FiveKPlus: 'FiveKPlus',
  FiveMp: 'FiveMp',
  FivteenMp: 'FivteenMp',
  Flag: 'Flag',
  FlagCircle: 'FlagCircle',
  Flaky: 'Flaky',
  Flare: 'Flare',
  FlashAuto: 'FlashAuto',
  FlashlightOff: 'FlashlightOff',
  FlashlightOn: 'FlashlightOn',
  FlashOff: 'FlashOff',
  FlashOn: 'FlashOn',
  Flatware: 'Flatware',
  Flight: 'Flight',
  FlightClass: 'FlightClass',
  FlightLand: 'FlightLand',
  FlightTakeoff: 'FlightTakeoff',
  Flip: 'Flip',
  FlipCameraAndroid: 'FlipCameraAndroid',
  FlipCameraIos: 'FlipCameraIos',
  FlipToBack: 'FlipToBack',
  FlipToFront: 'FlipToFront',
  Flood: 'Flood',
  Flourescent: 'Flourescent',
  FlutterDash: 'FlutterDash',
  FmdBad: 'FmdBad',
  FmdGood: 'FmdGood',
  Folder: 'Folder',
  FolderCopy: 'FolderCopy',
  FolderDelete: 'FolderDelete',
  FolderOff: 'FolderOff',
  FolderOpen: 'FolderOpen',
  FolderShared: 'FolderShared',
  FolderSpecial: 'FolderSpecial',
  FolderZip: 'FolderZip',
  FollowTheSigns: 'FollowTheSigns',
  FontDownload: 'FontDownload',
  FontDownloadOff: 'FontDownloadOff',
  FoodBank: 'FoodBank',
  Forest: 'Forest',
  ForkLeft: 'ForkLeft',
  ForkRight: 'ForkRight',
  FormatAlignCenter: 'FormatAlignCenter',
  FormatAlignJustify: 'FormatAlignJustify',
  FormatAlignLeft: 'FormatAlignLeft',
  FormatAlignRight: 'FormatAlignRight',
  FormatBold: 'FormatBold',
  FormatClear: 'FormatClear',
  FormatColorFill: 'FormatColorFill',
  FormatColorReset: 'FormatColorReset',
  FormatColorText: 'FormatColorText',
  FormatIndentDecrease: 'FormatIndentDecrease',
  FormatIndentIncrease: 'FormatIndentIncrease',
  FormatItalic: 'FormatItalic',
  FormatLineSpacing: 'FormatLineSpacing',
  FormatListBulleted: 'FormatListBulleted',
  FormatListNumbered: 'FormatListNumbered',
  FormatListNumberedRtl: 'FormatListNumberedRtl',
  FormatOverline: 'FormatOverline',
  FormatPaint: 'FormatPaint',
  FormatQuote: 'FormatQuote',
  FormatShapes: 'FormatShapes',
  FormatSize: 'FormatSize',
  FormatStrikethrough: 'FormatStrikethrough',
  FormatTextdirectionLToR: 'FormatTextdirectionLToR',
  FormatTextdirectionRToL: 'FormatTextdirectionRToL',
  FormatUnderlined: 'FormatUnderlined',
  Fort: 'Fort',
  Forum: 'Forum',
  Forward: 'Forward',
  Forward10: 'Forward10',
  Forward30: 'Forward30',
  Forward5: 'Forward5',
  ForwardToInbox: 'ForwardToInbox',
  Foundation: 'Foundation',
  FourGMobiledata: 'FourGMobiledata',
  FourGPlusMobiledata: 'FourGPlusMobiledata',
  FourK: 'FourK',
  FourKPlus: 'FourKPlus',
  FourMp: 'FourMp',
  FourteenMp: 'FourteenMp',
  FreeBreakfast: 'FreeBreakfast',
  Fullscreen: 'Fullscreen',
  FullscreenExit: 'FullscreenExit',
  Functions: 'Functions',
  Gamepad: 'Gamepad',
  Games: 'Games',
  Garage: 'Garage',
  GasMeter: 'GasMeter',
  Gavel: 'Gavel',
  Gesture: 'Gesture',
  GetApp: 'GetApp',
  Gif: 'Gif',
  GifBox: 'GifBox',
  Girl: 'Girl',
  Gite: 'Gite',
  GitHub: 'GitHub',
  GMobiledata: 'GMobiledata',
  GolfCourse: 'GolfCourse',
  Google: 'Google',
  GppBad: 'GppBad',
  GppGood: 'GppGood',
  GppMaybe: 'GppMaybe',
  GpsFixed: 'GpsFixed',
  GpsNotFixed: 'GpsNotFixed',
  GpsOff: 'GpsOff',
  Grade: 'Grade',
  Gradient: 'Gradient',
  Grading: 'Grading',
  Grain: 'Grain',
  GraphicEq: 'GraphicEq',
  Grass: 'Grass',
  Grid3x3: 'Grid3x3',
  Grid4x4: 'Grid4x4',
  GridGoldenratio: 'GridGoldenratio',
  GridOff: 'GridOff',
  GridOn: 'GridOn',
  GridView: 'GridView',
  Group: 'Group',
  GroupAdd: 'GroupAdd',
  GroupRemove: 'GroupRemove',
  Groups: 'Groups',
  GroupWork: 'GroupWork',
  GTranslate: 'GTranslate',
  Hail: 'Hail',
  Handshake: 'Handshake',
  Handyman: 'Handyman',
  Hardware: 'Hardware',
  Hd: 'Hd',
  HdrAuto: 'HdrAuto',
  HdrAutoSelect: 'HdrAutoSelect',
  HdrEnhancedSelect: 'HdrEnhancedSelect',
  HdrOff: 'HdrOff',
  HdrOffSelect: 'HdrOffSelect',
  HdrOn: 'HdrOn',
  HdrOnSelect: 'HdrOnSelect',
  HdrPlus: 'HdrPlus',
  HdrStrong: 'HdrStrong',
  HdrWeak: 'HdrWeak',
  Headphones: 'Headphones',
  HeadphonesBattery: 'HeadphonesBattery',
  Headset: 'Headset',
  HeadsetMic: 'HeadsetMic',
  HeadsetOff: 'HeadsetOff',
  Healing: 'Healing',
  HealthAndSafety: 'HealthAndSafety',
  Hearing: 'Hearing',
  HearingDisabled: 'HearingDisabled',
  HeartBroken: 'HeartBroken',
  HeatPump: 'HeatPump',
  Height: 'Height',
  Help: 'Help',
  HelpCenter: 'HelpCenter',
  HelpOutline: 'HelpOutline',
  Hevc: 'Hevc',
  Hexagon: 'Hexagon',
  HideImage: 'HideImage',
  HideSource: 'HideSource',
  Highlight: 'Highlight',
  HighlightAlt: 'HighlightAlt',
  HighlightOff: 'HighlightOff',
  HighQuality: 'HighQuality',
  Hiking: 'Hiking',
  History: 'History',
  HistoryEdu: 'HistoryEdu',
  HistoryToggleOff: 'HistoryToggleOff',
  Hive: 'Hive',
  Hls: 'Hls',
  HlsOff: 'HlsOff',
  HMobiledata: 'HMobiledata',
  HolidayVillage: 'HolidayVillage',
  Home: 'Home',
  HomeMax: 'HomeMax',
  HomeMini: 'HomeMini',
  HomeRepairService: 'HomeRepairService',
  HomeWork: 'HomeWork',
  HorizontalRule: 'HorizontalRule',
  HorizontalSplit: 'HorizontalSplit',
  Hotel: 'Hotel',
  HotTub: 'HotTub',
  HourglassBottom: 'HourglassBottom',
  HourglassDisabled: 'HourglassDisabled',
  HourglassEmpty: 'HourglassEmpty',
  HourglassFull: 'HourglassFull',
  HourglassTop: 'HourglassTop',
  House: 'House',
  Houseboat: 'Houseboat',
  HouseSiding: 'HouseSiding',
  HowToReg: 'HowToReg',
  HowToVote: 'HowToVote',
  HPlusMobiledata: 'HPlusMobiledata',
  Html: 'Html',
  Http: 'Http',
  Https: 'Https',
  Hub: 'Hub',
  Hvac: 'Hvac',
  Icecream: 'Icecream',
  IceSkating: 'IceSkating',
  Image: 'Image',
  ImageAspectRatio: 'ImageAspectRatio',
  ImageNotSupported: 'ImageNotSupported',
  ImageSearch: 'ImageSearch',
  ImagesearchRoller: 'ImagesearchRoller',
  ImportantDevices: 'ImportantDevices',
  ImportContacts: 'ImportContacts',
  ImportExport: 'ImportExport',
  Inbox: 'Inbox',
  IndeterminateCheckBox: 'IndeterminateCheckBox',
  Info: 'Info',
  Input: 'Input',
  InsertChart: 'InsertChart',
  InsertComment: 'InsertComment',
  InsertDriveFile: 'InsertDriveFile',
  InsertEmoticon: 'InsertEmoticon',
  InsertInvitation: 'InsertInvitation',
  InsertLink: 'InsertLink',
  InsertPageBreak: 'InsertPageBreak',
  InsertPhoto: 'InsertPhoto',
  Insights: 'Insights',
  Instagram: 'Instagram',
  InstallDesktop: 'InstallDesktop',
  InstallMobile: 'InstallMobile',
  IntegrationInstructions: 'IntegrationInstructions',
  Interests: 'Interests',
  InterpreterMode: 'InterpreterMode',
  Inventory: 'Inventory',
  Inventory2: 'Inventory2',
  InvertColors: 'InvertColors',
  InvertColorsOff: 'InvertColorsOff',
  IosShare: 'IosShare',
  Iron: 'Iron',
  Iso: 'Iso',
  Javascript: 'Javascript',
  JoinFull: 'JoinFull',
  JoinInner: 'JoinInner',
  JoinLeft: 'JoinLeft',
  JoinRight: 'JoinRight',
  Kayaking: 'Kayaking',
  KebabDining: 'KebabDining',
  Key: 'Key',
  Keyboard: 'Keyboard',
  KeyboardAlt: 'KeyboardAlt',
  KeyboardArrowDown: 'KeyboardArrowDown',
  KeyboardArrowLeft: 'KeyboardArrowLeft',
  KeyboardArrowRight: 'KeyboardArrowRight',
  KeyboardArrowUp: 'KeyboardArrowUp',
  KeyboardBackspace: 'KeyboardBackspace',
  KeyboardCapslock: 'KeyboardCapslock',
  KeyboardCommandKey: 'KeyboardCommandKey',
  KeyboardControlKey: 'KeyboardControlKey',
  KeyboardDoubleArrowDown: 'KeyboardDoubleArrowDown',
  KeyboardDoubleArrowLeft: 'KeyboardDoubleArrowLeft',
  KeyboardDoubleArrowRight: 'KeyboardDoubleArrowRight',
  KeyboardDoubleArrowUp: 'KeyboardDoubleArrowUp',
  KeyboardHide: 'KeyboardHide',
  KeyboardOptionKey: 'KeyboardOptionKey',
  KeyboardReturn: 'KeyboardReturn',
  KeyboardTab: 'KeyboardTab',
  KeyboardVoice: 'KeyboardVoice',
  KeyOff: 'KeyOff',
  KingBed: 'KingBed',
  Kitchen: 'Kitchen',
  Kitesurfing: 'Kitesurfing',
  Label: 'Label',
  LabelImportant: 'LabelImportant',
  LabelOff: 'LabelOff',
  Lan: 'Lan',
  Landscape: 'Landscape',
  Landslide: 'Landslide',
  Language: 'Language',
  Laptop: 'Laptop',
  LaptopChromebook: 'LaptopChromebook',
  LaptopMac: 'LaptopMac',
  LaptopWindows: 'LaptopWindows',
  LastPage: 'LastPage',
  Launch: 'Launch',
  Layers: 'Layers',
  LayersClear: 'LayersClear',
  Leaderboard: 'Leaderboard',
  LeakAdd: 'LeakAdd',
  LeakRemove: 'LeakRemove',
  LegendToggle: 'LegendToggle',
  Lens: 'Lens',
  LensBlur: 'LensBlur',
  LibraryAdd: 'LibraryAdd',
  LibraryAddCheck: 'LibraryAddCheck',
  LibraryBooks: 'LibraryBooks',
  LibraryMusic: 'LibraryMusic',
  Light: 'Light',
  Lightbulb: 'Lightbulb',
  LightbulbCircle: 'LightbulbCircle',
  LightMode: 'LightMode',
  LinearScale: 'LinearScale',
  LineAxis: 'LineAxis',
  LineStyle: 'LineStyle',
  LineWeight: 'LineWeight',
  Link: 'Link',
  LinkedCamera: 'LinkedCamera',
  LinkedIn: 'LinkedIn',
  LinkOff: 'LinkOff',
  Liquor: 'Liquor',
  List: 'List',
  ListAlt: 'ListAlt',
  LiveHelp: 'LiveHelp',
  LiveTv: 'LiveTv',
  Living: 'Living',
  LocalActivity: 'LocalActivity',
  LocalAirport: 'LocalAirport',
  LocalAtm: 'LocalAtm',
  LocalBar: 'LocalBar',
  LocalCafe: 'LocalCafe',
  LocalCarWash: 'LocalCarWash',
  LocalConvenienceStore: 'LocalConvenienceStore',
  LocalDining: 'LocalDining',
  LocalDrink: 'LocalDrink',
  LocalFireDepartment: 'LocalFireDepartment',
  LocalFlorist: 'LocalFlorist',
  LocalGasStation: 'LocalGasStation',
  LocalGroceryStore: 'LocalGroceryStore',
  LocalHospital: 'LocalHospital',
  LocalHotel: 'LocalHotel',
  LocalLaundryService: 'LocalLaundryService',
  LocalLibrary: 'LocalLibrary',
  LocalMall: 'LocalMall',
  LocalMovies: 'LocalMovies',
  LocalOffer: 'LocalOffer',
  LocalParking: 'LocalParking',
  LocalPharmacy: 'LocalPharmacy',
  LocalPhone: 'LocalPhone',
  LocalPizza: 'LocalPizza',
  LocalPlay: 'LocalPlay',
  LocalPolice: 'LocalPolice',
  LocalPostOffice: 'LocalPostOffice',
  LocalPrintshop: 'LocalPrintshop',
  LocalSee: 'LocalSee',
  LocalShipping: 'LocalShipping',
  LocalTaxi: 'LocalTaxi',
  LocationCity: 'LocationCity',
  LocationDisabled: 'LocationDisabled',
  LocationOff: 'LocationOff',
  LocationOn: 'LocationOn',
  LocationSearching: 'LocationSearching',
  Lock: 'Lock',
  LockClock: 'LockClock',
  LockOpen: 'LockOpen',
  LockReset: 'LockReset',
  Login: 'Login',
  LogoDev: 'LogoDev',
  Logout: 'Logout',
  Looks: 'Looks',
  Looks3: 'Looks3',
  Looks4: 'Looks4',
  Looks5: 'Looks5',
  Looks6: 'Looks6',
  LooksOne: 'LooksOne',
  LooksTwo: 'LooksTwo',
  Loop: 'Loop',
  Loupe: 'Loupe',
  LowPriority: 'LowPriority',
  Loyalty: 'Loyalty',
  LteMobiledata: 'LteMobiledata',
  LtePlusMobiledata: 'LtePlusMobiledata',
  Luggage: 'Luggage',
  LunchDining: 'LunchDining',
  Lyrics: 'Lyrics',
  Mail: 'Mail',
  MailLock: 'MailLock',
  MailOutline: 'MailOutline',
  Male: 'Male',
  Man: 'Man',
  ManageAccounts: 'ManageAccounts',
  ManageHistory: 'ManageHistory',
  ManageSearch: 'ManageSearch',
  Map: 'Map',
  MapsHomeWork: 'MapsHomeWork',
  MapsUgc: 'MapsUgc',
  Margin: 'Margin',
  MarkAsUnread: 'MarkAsUnread',
  MarkChatRead: 'MarkChatRead',
  MarkChatUnread: 'MarkChatUnread',
  MarkEmailRead: 'MarkEmailRead',
  MarkEmailUnread: 'MarkEmailUnread',
  Markunread: 'Markunread',
  MarkUnreadChatAlt: 'MarkUnreadChatAlt',
  MarkunreadMailbox: 'MarkunreadMailbox',
  Masks: 'Masks',
  Maximize: 'Maximize',
  MediaBluetoothOff: 'MediaBluetoothOff',
  MediaBluetoothOn: 'MediaBluetoothOn',
  Mediation: 'Mediation',
  MedicalInformation: 'MedicalInformation',
  MedicalServices: 'MedicalServices',
  Medication: 'Medication',
  MedicationLiquid: 'MedicationLiquid',
  MeetingRoom: 'MeetingRoom',
  Memory: 'Memory',
  Menu: 'Menu',
  MenuBook: 'MenuBook',
  MenuOpen: 'MenuOpen',
  Merge: 'Merge',
  MergeType: 'MergeType',
  Message: 'Message',
  Mic: 'Mic',
  MicExternalOff: 'MicExternalOff',
  MicExternalOn: 'MicExternalOn',
  MicNone: 'MicNone',
  MicOff: 'MicOff',
  Microwave: 'Microwave',
  MilitaryTech: 'MilitaryTech',
  Minimize: 'Minimize',
  MinorCrash: 'MinorCrash',
  MiscellaneousServices: 'MiscellaneousServices',
  MissedVideoCall: 'MissedVideoCall',
  Mms: 'Mms',
  MobiledataOff: 'MobiledataOff',
  MobileFriendly: 'MobileFriendly',
  MobileOff: 'MobileOff',
  MobileScreenShare: 'MobileScreenShare',
  Mode: 'Mode',
  ModeComment: 'ModeComment',
  ModeEdit: 'ModeEdit',
  ModeEditOutline: 'ModeEditOutline',
  ModeFanOff: 'ModeFanOff',
  ModelTraining: 'ModelTraining',
  ModeNight: 'ModeNight',
  ModeOfTravel: 'ModeOfTravel',
  ModeStandby: 'ModeStandby',
  MonetizationOn: 'MonetizationOn',
  Money: 'Money',
  MoneyOff: 'MoneyOff',
  MoneyOffCsred: 'MoneyOffCsred',
  Monitor: 'Monitor',
  MonitorHeart: 'MonitorHeart',
  MonitorWeight: 'MonitorWeight',
  MonochromePhotos: 'MonochromePhotos',
  Mood: 'Mood',
  MoodBad: 'MoodBad',
  Moped: 'Moped',
  More: 'More',
  MoreHoriz: 'MoreHoriz',
  MoreTime: 'MoreTime',
  MoreVert: 'MoreVert',
  Mosque: 'Mosque',
  MotionPhotosAuto: 'MotionPhotosAuto',
  MotionPhotosOff: 'MotionPhotosOff',
  Mouse: 'Mouse',
  MoveDown: 'MoveDown',
  MoveToInbox: 'MoveToInbox',
  MoveUp: 'MoveUp',
  Movie: 'Movie',
  MovieCreation: 'MovieCreation',
  MovieFilter: 'MovieFilter',
  Moving: 'Moving',
  Mp: 'Mp',
  MultilineChart: 'MultilineChart',
  MultipleStop: 'MultipleStop',
  Museum: 'Museum',
  MusicNote: 'MusicNote',
  MusicOff: 'MusicOff',
  MusicVideo: 'MusicVideo',
  MyLocation: 'MyLocation',
  Nat: 'Nat',
  Nature: 'Nature',
  NaturePeople: 'NaturePeople',
  NavigateBefore: 'NavigateBefore',
  NavigateNext: 'NavigateNext',
  Navigation: 'Navigation',
  NearbyError: 'NearbyError',
  NearbyOff: 'NearbyOff',
  NearMe: 'NearMe',
  NearMeDisabled: 'NearMeDisabled',
  NestCamWiredStand: 'NestCamWiredStand',
  NetworkCell: 'NetworkCell',
  NetworkCheck: 'NetworkCheck',
  NetworkLocked: 'NetworkLocked',
  NetworkPing: 'NetworkPing',
  NetworkWifi: 'NetworkWifi',
  NetworkWifi1Bar: 'NetworkWifi1Bar',
  NetworkWifi2Bar: 'NetworkWifi2Bar',
  NetworkWifi3Bar: 'NetworkWifi3Bar',
  NewReleases: 'NewReleases',
  Newspaper: 'Newspaper',
  NextPlan: 'NextPlan',
  NextWeek: 'NextWeek',
  Nfc: 'Nfc',
  Nightlife: 'Nightlife',
  Nightlight: 'Nightlight',
  NightlightRound: 'NightlightRound',
  NightShelter: 'NightShelter',
  NightsStay: 'NightsStay',
  NineK: 'NineK',
  NineKPlus: 'NineKPlus',
  NineMp: 'NineMp',
  NineteenMp: 'NineteenMp',
  NoAccounts: 'NoAccounts',
  NoBackpack: 'NoBackpack',
  NoCell: 'NoCell',
  NoCrash: 'NoCrash',
  NoDrinks: 'NoDrinks',
  NoEncryption: 'NoEncryption',
  NoEncryptionGmailerrorred: 'NoEncryptionGmailerrorred',
  NoFlash: 'NoFlash',
  NoFood: 'NoFood',
  NoiseAware: 'NoiseAware',
  NoiseControlOff: 'NoiseControlOff',
  NoLuggage: 'NoLuggage',
  NoMeals: 'NoMeals',
  NoMeetingRoom: 'NoMeetingRoom',
  NoPhotography: 'NoPhotography',
  NordicWalking: 'NordicWalking',
  North: 'North',
  NorthEast: 'NorthEast',
  NorthWest: 'NorthWest',
  NoSim: 'NoSim',
  NoStroller: 'NoStroller',
  NotAccessible: 'NotAccessible',
  Note: 'Note',
  NoteAdd: 'NoteAdd',
  NoteAlt: 'NoteAlt',
  Notes: 'Notes',
  NotificationAdd: 'NotificationAdd',
  NotificationImportant: 'NotificationImportant',
  Notifications: 'Notifications',
  NotificationsActive: 'NotificationsActive',
  NotificationsNone: 'NotificationsNone',
  NotificationsOff: 'NotificationsOff',
  NotificationsPaused: 'NotificationsPaused',
  NotInterested: 'NotInterested',
  NotListedLocation: 'NotListedLocation',
  NoTransfer: 'NoTransfer',
  NotStarted: 'NotStarted',
  Numbers: 'Numbers',
  OfflineBolt: 'OfflineBolt',
  OfflinePin: 'OfflinePin',
  OfflineShare: 'OfflineShare',
  OilBarrel: 'OilBarrel',
  OndemandVideo: 'OndemandVideo',
  OnDeviceTraining: 'OnDeviceTraining',
  OneK: 'OneK',
  OneKk: 'OneKk',
  OneKPlus: 'OneKPlus',
  OnlinePrediction: 'OnlinePrediction',
  Opacity: 'Opacity',
  OpenInBrowser: 'OpenInBrowser',
  OpenInFull: 'OpenInFull',
  OpenInNew: 'OpenInNew',
  OpenInNewOff: 'OpenInNewOff',
  OpenWith: 'OpenWith',
  OtherHouses: 'OtherHouses',
  Outbound: 'Outbound',
  Outbox: 'Outbox',
  OutdoorGrill: 'OutdoorGrill',
  Outlet: 'Outlet',
  Output: 'Output',
  Padding: 'Padding',
  Pages: 'Pages',
  Pageview: 'Pageview',
  Paid: 'Paid',
  Palette: 'Palette',
  Panorama: 'Panorama',
  PanoramaFishEye: 'PanoramaFishEye',
  PanoramaHorizontal: 'PanoramaHorizontal',
  PanoramaHorizontalSelect: 'PanoramaHorizontalSelect',
  PanoramaPhotosphere: 'PanoramaPhotosphere',
  PanoramaPhotosphereSelect: 'PanoramaPhotosphereSelect',
  PanoramaVertical: 'PanoramaVertical',
  PanoramaVerticalSelect: 'PanoramaVerticalSelect',
  PanoramaWideAngle: 'PanoramaWideAngle',
  PanoramaWideAngleSelect: 'PanoramaWideAngleSelect',
  PanTool: 'PanTool',
  PanToolAlt: 'PanToolAlt',
  Paragliding: 'Paragliding',
  Park: 'Park',
  PartyMode: 'PartyMode',
  Password: 'Password',
  Pattern: 'Pattern',
  Pause: 'Pause',
  PauseCircle: 'PauseCircle',
  PauseCircleOutline: 'PauseCircleOutline',
  PausePresentation: 'PausePresentation',
  Payment: 'Payment',
  Payments: 'Payments',
  PedalBike: 'PedalBike',
  Pending: 'Pending',
  PendingActions: 'PendingActions',
  Pentagon: 'Pentagon',
  People: 'People',
  PeopleAlt: 'PeopleAlt',
  PeopleOutline: 'PeopleOutline',
  Percent: 'Percent',
  PermCameraMic: 'PermCameraMic',
  PermContactCalendar: 'PermContactCalendar',
  PermDataSetting: 'PermDataSetting',
  PermDeviceInformation: 'PermDeviceInformation',
  PermIdentity: 'PermIdentity',
  PermMedia: 'PermMedia',
  PermPhoneMsg: 'PermPhoneMsg',
  PermScanWifi: 'PermScanWifi',
  Person: 'Person',
  PersonAdd: 'PersonAdd',
  PersonAddAlt: 'PersonAddAlt',
  PersonAddAlt1: 'PersonAddAlt1',
  PersonAddDisabled: 'PersonAddDisabled',
  PersonalVideo: 'PersonalVideo',
  PersonOff: 'PersonOff',
  PersonOutline: 'PersonOutline',
  PersonPin: 'PersonPin',
  PersonPinCircle: 'PersonPinCircle',
  PersonRemove: 'PersonRemove',
  PersonRemoveAlt1: 'PersonRemoveAlt1',
  PersonSearch: 'PersonSearch',
  PestControl: 'PestControl',
  PestControlRodent: 'PestControlRodent',
  Pets: 'Pets',
  Phishing: 'Phishing',
  Phone: 'Phone',
  PhoneAndroid: 'PhoneAndroid',
  PhoneBluetoothSpeaker: 'PhoneBluetoothSpeaker',
  PhoneCallback: 'PhoneCallback',
  PhoneDisabled: 'PhoneDisabled',
  PhoneEnabled: 'PhoneEnabled',
  PhoneForwarded: 'PhoneForwarded',
  PhoneInTalk: 'PhoneInTalk',
  PhoneIphone: 'PhoneIphone',
  Phonelink: 'Phonelink',
  PhonelinkErase: 'PhonelinkErase',
  PhonelinkLock: 'PhonelinkLock',
  PhonelinkOff: 'PhonelinkOff',
  PhonelinkRing: 'PhonelinkRing',
  PhonelinkSetup: 'PhonelinkSetup',
  PhoneLocked: 'PhoneLocked',
  PhoneMissed: 'PhoneMissed',
  PhonePaused: 'PhonePaused',
  Photo: 'Photo',
  PhotoAlbum: 'PhotoAlbum',
  PhotoCamera: 'PhotoCamera',
  PhotoCameraBack: 'PhotoCameraBack',
  PhotoCameraFront: 'PhotoCameraFront',
  PhotoFilter: 'PhotoFilter',
  PhotoLibrary: 'PhotoLibrary',
  PhotoSizeSelectActual: 'PhotoSizeSelectActual',
  PhotoSizeSelectLarge: 'PhotoSizeSelectLarge',
  PhotoSizeSelectSmall: 'PhotoSizeSelectSmall',
  Php: 'Php',
  Piano: 'Piano',
  PianoOff: 'PianoOff',
  PictureAsPdf: 'PictureAsPdf',
  PictureInPicture: 'PictureInPicture',
  PictureInPictureAlt: 'PictureInPictureAlt',
  PieChart: 'PieChart',
  PieChartOutline: 'PieChartOutline',
  Pin: 'Pin',
  Pinch: 'Pinch',
  PinDrop: 'PinDrop',
  Pinterest: 'Pinterest',
  PivotTableChart: 'PivotTableChart',
  Pix: 'Pix',
  Place: 'Place',
  Plagiarism: 'Plagiarism',
  PlayArrow: 'PlayArrow',
  PlayCircle: 'PlayCircle',
  PlayCircleOutline: 'PlayCircleOutline',
  PlayDisabled: 'PlayDisabled',
  PlayForWork: 'PlayForWork',
  PlayLesson: 'PlayLesson',
  PlaylistAdd: 'PlaylistAdd',
  PlaylistAddCheck: 'PlaylistAddCheck',
  PlaylistAddCheckCircle: 'PlaylistAddCheckCircle',
  PlaylistAddCircle: 'PlaylistAddCircle',
  PlaylistPlay: 'PlaylistPlay',
  PlaylistRemove: 'PlaylistRemove',
  Plumbing: 'Plumbing',
  PlusOne: 'PlusOne',
  Podcasts: 'Podcasts',
  PointOfSale: 'PointOfSale',
  Policy: 'Policy',
  Poll: 'Poll',
  Polyline: 'Polyline',
  Pool: 'Pool',
  PortableWifiOff: 'PortableWifiOff',
  Portrait: 'Portrait',
  PostAdd: 'PostAdd',
  Power: 'Power',
  PowerInput: 'PowerInput',
  PowerOff: 'PowerOff',
  PowerSettingsNew: 'PowerSettingsNew',
  PrecisionManufacturing: 'PrecisionManufacturing',
  PregnantWoman: 'PregnantWoman',
  PresentToAll: 'PresentToAll',
  Preview: 'Preview',
  PriceChange: 'PriceChange',
  PriceCheck: 'PriceCheck',
  Print: 'Print',
  PrintDisabled: 'PrintDisabled',
  PriorityHigh: 'PriorityHigh',
  PrivacyTip: 'PrivacyTip',
  ProductionQuantityLimits: 'ProductionQuantityLimits',
  Propane: 'Propane',
  PropaneTank: 'PropaneTank',
  Psychology: 'Psychology',
  Public: 'Public',
  PublicOff: 'PublicOff',
  Publish: 'Publish',
  PublishedWithChanges: 'PublishedWithChanges',
  PunchClock: 'PunchClock',
  PushPin: 'PushPin',
  QrCode: 'QrCode',
  QrCode2: 'QrCode2',
  QrCodeScanner: 'QrCodeScanner',
  QueryBuilder: 'QueryBuilder',
  QueryStats: 'QueryStats',
  QuestionAnswer: 'QuestionAnswer',
  QuestionMark: 'QuestionMark',
  Queue: 'Queue',
  QueueMusic: 'QueueMusic',
  QueuePlayNext: 'QueuePlayNext',
  Quickreply: 'Quickreply',
  Quiz: 'Quiz',
  Radar: 'Radar',
  Radio: 'Radio',
  RadioButtonChecked: 'RadioButtonChecked',
  RadioButtonUnchecked: 'RadioButtonUnchecked',
  RailwayAlert: 'RailwayAlert',
  RamenDining: 'RamenDining',
  RampLeft: 'RampLeft',
  RampRight: 'RampRight',
  RateReview: 'RateReview',
  RawOff: 'RawOff',
  RawOn: 'RawOn',
  ReadMore: 'ReadMore',
  Receipt: 'Receipt',
  ReceiptLong: 'ReceiptLong',
  RecentActors: 'RecentActors',
  Recommend: 'Recommend',
  RecordVoiceOver: 'RecordVoiceOver',
  Rectangle: 'Rectangle',
  Reddit: 'Reddit',
  Redeem: 'Redeem',
  Redo: 'Redo',
  ReduceCapacity: 'ReduceCapacity',
  Refresh: 'Refresh',
  RememberMe: 'RememberMe',
  Remove: 'Remove',
  RemoveCircle: 'RemoveCircle',
  RemoveCircleOutline: 'RemoveCircleOutline',
  RemoveDone: 'RemoveDone',
  RemoveFromQueue: 'RemoveFromQueue',
  RemoveModerator: 'RemoveModerator',
  RemoveRedEye: 'RemoveRedEye',
  RemoveRoad: 'RemoveRoad',
  RemoveShoppingCart: 'RemoveShoppingCart',
  Reorder: 'Reorder',
  Repeat: 'Repeat',
  RepeatOn: 'RepeatOn',
  RepeatOne: 'RepeatOne',
  RepeatOneOn: 'RepeatOneOn',
  Replay: 'Replay',
  Replay10: 'Replay10',
  Replay30: 'Replay30',
  Replay5: 'Replay5',
  Reply: 'Reply',
  ReplyAll: 'ReplyAll',
  Report: 'Report',
  ReportGmailerrorred: 'ReportGmailerrorred',
  ReportOff: 'ReportOff',
  ReportProblem: 'ReportProblem',
  RequestPage: 'RequestPage',
  RequestQuote: 'RequestQuote',
  ResetTv: 'ResetTv',
  RestartAlt: 'RestartAlt',
  Restaurant: 'Restaurant',
  RestaurantMenu: 'RestaurantMenu',
  Restore: 'Restore',
  RestoreFromTrash: 'RestoreFromTrash',
  RestorePage: 'RestorePage',
  Reviews: 'Reviews',
  RiceBowl: 'RiceBowl',
  RingVolume: 'RingVolume',
  RMobiledata: 'RMobiledata',
  Rocket: 'Rocket',
  RocketLaunch: 'RocketLaunch',
  RollerShades: 'RollerShades',
  RollerShadesClosed: 'RollerShadesClosed',
  RollerSkating: 'RollerSkating',
  Roofing: 'Roofing',
  Room: 'Room',
  RoomPreferences: 'RoomPreferences',
  RoomService: 'RoomService',
  Rotate90DegreesCcw: 'Rotate90DegreesCcw',
  Rotate90DegreesCw: 'Rotate90DegreesCw',
  RotateLeft: 'RotateLeft',
  RotateRight: 'RotateRight',
  RoundaboutLeft: 'RoundaboutLeft',
  RoundaboutRight: 'RoundaboutRight',
  Route: 'Route',
  Router: 'Router',
  Rowing: 'Rowing',
  RssFeed: 'RssFeed',
  Rsvp: 'Rsvp',
  Rtt: 'Rtt',
  Rule: 'Rule',
  RuleFolder: 'RuleFolder',
  RunCircle: 'RunCircle',
  RunningWithErrors: 'RunningWithErrors',
  RvHookup: 'RvHookup',
  SafetyCheck: 'SafetyCheck',
  SafetyDivider: 'SafetyDivider',
  Sailing: 'Sailing',
  Sanitizer: 'Sanitizer',
  Satellite: 'Satellite',
  SatelliteAlt: 'SatelliteAlt',
  Save: 'Save',
  SaveAlt: 'SaveAlt',
  SaveAs: 'SaveAs',
  SavedSearch: 'SavedSearch',
  Savings: 'Savings',
  Scale: 'Scale',
  Scanner: 'Scanner',
  ScatterPlot: 'ScatterPlot',
  Schedule: 'Schedule',
  ScheduleSend: 'ScheduleSend',
  Schema: 'Schema',
  School: 'School',
  Science: 'Science',
  Score: 'Score',
  Scoreboard: 'Scoreboard',
  ScreenLockLandscape: 'ScreenLockLandscape',
  ScreenLockPortrait: 'ScreenLockPortrait',
  ScreenLockRotation: 'ScreenLockRotation',
  ScreenRotation: 'ScreenRotation',
  ScreenRotationAlt: 'ScreenRotationAlt',
  ScreenSearchDesktop: 'ScreenSearchDesktop',
  ScreenShare: 'ScreenShare',
  Screenshot: 'Screenshot',
  ScreenshotMonitor: 'ScreenshotMonitor',
  ScubaDiving: 'ScubaDiving',
  Sd: 'Sd',
  SdCard: 'SdCard',
  SdCardAlert: 'SdCardAlert',
  SdStorage: 'SdStorage',
  Search: 'Search',
  SearchOff: 'SearchOff',
  Security: 'Security',
  SecurityUpdate: 'SecurityUpdate',
  SecurityUpdateGood: 'SecurityUpdateGood',
  SecurityUpdateWarning: 'SecurityUpdateWarning',
  Segment: 'Segment',
  SelectAll: 'SelectAll',
  SelfImprovement: 'SelfImprovement',
  Sell: 'Sell',
  Send: 'Send',
  SendAndArchive: 'SendAndArchive',
  SendTimeExtension: 'SendTimeExtension',
  SendToMobile: 'SendToMobile',
  SensorDoor: 'SensorDoor',
  SensorOccupied: 'SensorOccupied',
  Sensors: 'Sensors',
  SensorsOff: 'SensorsOff',
  SensorWindow: 'SensorWindow',
  SentimentDissatisfied: 'SentimentDissatisfied',
  SentimentNeutral: 'SentimentNeutral',
  SentimentSatisfied: 'SentimentSatisfied',
  SentimentSatisfiedAlt: 'SentimentSatisfiedAlt',
  SentimentVeryDissatisfied: 'SentimentVeryDissatisfied',
  SentimentVerySatisfied: 'SentimentVerySatisfied',
  SetMeal: 'SetMeal',
  Settings: 'Settings',
  SettingsAccessibility: 'SettingsAccessibility',
  SettingsApplications: 'SettingsApplications',
  SettingsBackupRestore: 'SettingsBackupRestore',
  SettingsBluetooth: 'SettingsBluetooth',
  SettingsBrightness: 'SettingsBrightness',
  SettingsCell: 'SettingsCell',
  SettingsEthernet: 'SettingsEthernet',
  SettingsInputAntenna: 'SettingsInputAntenna',
  SettingsInputComponent: 'SettingsInputComponent',
  SettingsInputComposite: 'SettingsInputComposite',
  SettingsInputHdmi: 'SettingsInputHdmi',
  SettingsInputSvideo: 'SettingsInputSvideo',
  SettingsOverscan: 'SettingsOverscan',
  SettingsPhone: 'SettingsPhone',
  SettingsPower: 'SettingsPower',
  SettingsRemote: 'SettingsRemote',
  SettingsSuggest: 'SettingsSuggest',
  SettingsSystemDaydream: 'SettingsSystemDaydream',
  SettingsVoice: 'SettingsVoice',
  SevenK: 'SevenK',
  SevenKPlus: 'SevenKPlus',
  SevenMp: 'SevenMp',
  SeventeenMp: 'SeventeenMp',
  SevereCold: 'SevereCold',
  Share: 'Share',
  ShareLocation: 'ShareLocation',
  Shield: 'Shield',
  ShieldMoon: 'ShieldMoon',
  Shop: 'Shop',
  Shop2: 'Shop2',
  ShoppingBag: 'ShoppingBag',
  ShoppingBasket: 'ShoppingBasket',
  ShoppingCart: 'ShoppingCart',
  ShoppingCartCheckout: 'ShoppingCartCheckout',
  ShopTwo: 'ShopTwo',
  Shortcut: 'Shortcut',
  ShortText: 'ShortText',
  ShowChart: 'ShowChart',
  Shower: 'Shower',
  Shuffle: 'Shuffle',
  ShuffleOn: 'ShuffleOn',
  ShutterSpeed: 'ShutterSpeed',
  Sick: 'Sick',
  SignalCellular0Bar: 'SignalCellular0Bar',
  SignalCellular1Bar: 'SignalCellular1Bar',
  SignalCellular2Bar: 'SignalCellular2Bar',
  SignalCellular3Bar: 'SignalCellular3Bar',
  SignalCellular4Bar: 'SignalCellular4Bar',
  SignalCellularAlt: 'SignalCellularAlt',
  SignalCellularAlt1Bar: 'SignalCellularAlt1Bar',
  SignalCellularAlt2Bar: 'SignalCellularAlt2Bar',
  SignalCellularConnectedNoInternet0Bar:
    'SignalCellularConnectedNoInternet0Bar',
  SignalCellularConnectedNoInternet1Bar:
    'SignalCellularConnectedNoInternet1Bar',
  SignalCellularConnectedNoInternet2Bar:
    'SignalCellularConnectedNoInternet2Bar',
  SignalCellularConnectedNoInternet3Bar:
    'SignalCellularConnectedNoInternet3Bar',
  SignalCellularConnectedNoInternet4Bar:
    'SignalCellularConnectedNoInternet4Bar',
  SignalCellularNodata: 'SignalCellularNodata',
  SignalCellularNoSim: 'SignalCellularNoSim',
  SignalCellularNull: 'SignalCellularNull',
  SignalCellularOff: 'SignalCellularOff',
  SignalWifi0Bar: 'SignalWifi0Bar',
  SignalWifi1Bar: 'SignalWifi1Bar',
  SignalWifi1BarLock: 'SignalWifi1BarLock',
  SignalWifi2Bar: 'SignalWifi2Bar',
  SignalWifi2BarLock: 'SignalWifi2BarLock',
  SignalWifi3Bar: 'SignalWifi3Bar',
  SignalWifi3BarLock: 'SignalWifi3BarLock',
  SignalWifi4Bar: 'SignalWifi4Bar',
  SignalWifi4BarLock: 'SignalWifi4BarLock',
  SignalWifiBad: 'SignalWifiBad',
  SignalWifiConnectedNoInternet4: 'SignalWifiConnectedNoInternet4',
  SignalWifiOff: 'SignalWifiOff',
  SignalWifiStatusbar4Bar: 'SignalWifiStatusbar4Bar',
  SignalWifiStatusbarConnectedNoInternet4:
    'SignalWifiStatusbarConnectedNoInternet4',
  SignalWifiStatusbarNull: 'SignalWifiStatusbarNull',
  SignLanguage: 'SignLanguage',
  Signpost: 'Signpost',
  SimCard: 'SimCard',
  SimCardAlert: 'SimCardAlert',
  SimCardDownload: 'SimCardDownload',
  SingleBed: 'SingleBed',
  Sip: 'Sip',
  SixK: 'SixK',
  SixKPlus: 'SixKPlus',
  SixMp: 'SixMp',
  SixteenMp: 'SixteenMp',
  SixtyFps: 'SixtyFps',
  SixtyFpsSelect: 'SixtyFpsSelect',
  Skateboarding: 'Skateboarding',
  SkipNext: 'SkipNext',
  SkipPrevious: 'SkipPrevious',
  Sledding: 'Sledding',
  Slideshow: 'Slideshow',
  SlowMotionVideo: 'SlowMotionVideo',
  SmartButton: 'SmartButton',
  SmartDisplay: 'SmartDisplay',
  Smartphone: 'Smartphone',
  SmartScreen: 'SmartScreen',
  SmartToy: 'SmartToy',
  SmokeFree: 'SmokeFree',
  SmokingRooms: 'SmokingRooms',
  Sms: 'Sms',
  SmsFailed: 'SmsFailed',
  SnippetFolder: 'SnippetFolder',
  Snooze: 'Snooze',
  Snowboarding: 'Snowboarding',
  Snowmobile: 'Snowmobile',
  Snowshoeing: 'Snowshoeing',
  Soap: 'Soap',
  SocialDistance: 'SocialDistance',
  SolarPower: 'SolarPower',
  Sort: 'Sort',
  SortByAlpha: 'SortByAlpha',
  Sos: 'Sos',
  SoupKitchen: 'SoupKitchen',
  Source: 'Source',
  South: 'South',
  SouthAmerica: 'SouthAmerica',
  SouthEast: 'SouthEast',
  SouthWest: 'SouthWest',
  Spa: 'Spa',
  SpaceBar: 'SpaceBar',
  SpatialAudio: 'SpatialAudio',
  SpatialAudioOff: 'SpatialAudioOff',
  SpatialTracking: 'SpatialTracking',
  Speaker: 'Speaker',
  SpeakerGroup: 'SpeakerGroup',
  SpeakerNotes: 'SpeakerNotes',
  SpeakerNotesOff: 'SpeakerNotesOff',
  SpeakerPhone: 'SpeakerPhone',
  Speed: 'Speed',
  Spellcheck: 'Spellcheck',
  Splitscreen: 'Splitscreen',
  Spoke: 'Spoke',
  Sports: 'Sports',
  SportsBar: 'SportsBar',
  SportsBaseball: 'SportsBaseball',
  SportsBasketball: 'SportsBasketball',
  SportsCricket: 'SportsCricket',
  SportsEsports: 'SportsEsports',
  SportsFootball: 'SportsFootball',
  SportsGolf: 'SportsGolf',
  SportsGymnastics: 'SportsGymnastics',
  SportsHandball: 'SportsHandball',
  SportsHockey: 'SportsHockey',
  SportsKabaddi: 'SportsKabaddi',
  SportsMartialArts: 'SportsMartialArts',
  SportsMma: 'SportsMma',
  SportsMotorsports: 'SportsMotorsports',
  SportsRugby: 'SportsRugby',
  SportsScore: 'SportsScore',
  SportsSoccer: 'SportsSoccer',
  SportsTennis: 'SportsTennis',
  SportsVolleyball: 'SportsVolleyball',
  Square: 'Square',
  SquareFoot: 'SquareFoot',
  SsidChart: 'SsidChart',
  StackedBarChart: 'StackedBarChart',
  StackedLineChart: 'StackedLineChart',
  Stadium: 'Stadium',
  Stairs: 'Stairs',
  Star: 'Star',
  StarBorder: 'StarBorder',
  StarBorderPurple500: 'StarBorderPurple500',
  StarHalf: 'StarHalf',
  StarOutline: 'StarOutline',
  StarPurple500: 'StarPurple500',
  StarRate: 'StarRate',
  Stars: 'Stars',
  Start: 'Start',
  StayCurrentLandscape: 'StayCurrentLandscape',
  StayCurrentPortrait: 'StayCurrentPortrait',
  StayPrimaryLandscape: 'StayPrimaryLandscape',
  StayPrimaryPortrait: 'StayPrimaryPortrait',
  StickyNote2: 'StickyNote2',
  Stop: 'Stop',
  StopCircle: 'StopCircle',
  StopScreenShare: 'StopScreenShare',
  Storage: 'Storage',
  Store: 'Store',
  Storefront: 'Storefront',
  StoreMallDirectory: 'StoreMallDirectory',
  Storm: 'Storm',
  Straight: 'Straight',
  Straighten: 'Straighten',
  Stream: 'Stream',
  Streetview: 'Streetview',
  StrikethroughS: 'StrikethroughS',
  Stroller: 'Stroller',
  Style: 'Style',
  SubdirectoryArrowLeft: 'SubdirectoryArrowLeft',
  SubdirectoryArrowRight: 'SubdirectoryArrowRight',
  Subject: 'Subject',
  Subscript: 'Subscript',
  Subscriptions: 'Subscriptions',
  Subtitles: 'Subtitles',
  SubtitlesOff: 'SubtitlesOff',
  Subway: 'Subway',
  Summarize: 'Summarize',
  Superscript: 'Superscript',
  SupervisedUserCircle: 'SupervisedUserCircle',
  SupervisorAccount: 'SupervisorAccount',
  Support: 'Support',
  SupportAgent: 'SupportAgent',
  Surfing: 'Surfing',
  SurroundSound: 'SurroundSound',
  SwapCalls: 'SwapCalls',
  SwapHoriz: 'SwapHoriz',
  SwapHorizontalCircle: 'SwapHorizontalCircle',
  SwapVert: 'SwapVert',
  SwapVerticalCircle: 'SwapVerticalCircle',
  Swipe: 'Swipe',
  SwipeDown: 'SwipeDown',
  SwipeDownAlt: 'SwipeDownAlt',
  SwipeLeft: 'SwipeLeft',
  SwipeLeftAlt: 'SwipeLeftAlt',
  SwipeRight: 'SwipeRight',
  SwipeRightAlt: 'SwipeRightAlt',
  SwipeUp: 'SwipeUp',
  SwipeUpAlt: 'SwipeUpAlt',
  SwipeVertical: 'SwipeVertical',
  SwitchAccessShortcut: 'SwitchAccessShortcut',
  SwitchAccessShortcutAdd: 'SwitchAccessShortcutAdd',
  SwitchAccount: 'SwitchAccount',
  SwitchCamera: 'SwitchCamera',
  SwitchLeft: 'SwitchLeft',
  SwitchRight: 'SwitchRight',
  SwitchVideo: 'SwitchVideo',
  Synagogue: 'Synagogue',
  Sync: 'Sync',
  SyncAlt: 'SyncAlt',
  SyncDisabled: 'SyncDisabled',
  SyncLock: 'SyncLock',
  SyncProblem: 'SyncProblem',
  SystemSecurityUpdate: 'SystemSecurityUpdate',
  SystemSecurityUpdateGood: 'SystemSecurityUpdateGood',
  SystemSecurityUpdateWarning: 'SystemSecurityUpdateWarning',
  SystemUpdate: 'SystemUpdate',
  SystemUpdateAlt: 'SystemUpdateAlt',
  Tab: 'Tab',
  TableBar: 'TableBar',
  TableChart: 'TableChart',
  TableRestaurant: 'TableRestaurant',
  TableRows: 'TableRows',
  Tablet: 'Tablet',
  TabletAndroid: 'TabletAndroid',
  TabletMac: 'TabletMac',
  TableView: 'TableView',
  TabUnselected: 'TabUnselected',
  Tag: 'Tag',
  TagFaces: 'TagFaces',
  TakeoutDining: 'TakeoutDining',
  TapAndPlay: 'TapAndPlay',
  Tapas: 'Tapas',
  Task: 'Task',
  TaskAlt: 'TaskAlt',
  TaxiAlert: 'TaxiAlert',
  Telegram: 'Telegram',
  TempleBuddhist: 'TempleBuddhist',
  TempleHindu: 'TempleHindu',
  TenMp: 'TenMp',
  Terminal: 'Terminal',
  Terrain: 'Terrain',
  TextDecrease: 'TextDecrease',
  TextFields: 'TextFields',
  TextFormat: 'TextFormat',
  TextIncrease: 'TextIncrease',
  TextRotateUp: 'TextRotateUp',
  TextRotateVertical: 'TextRotateVertical',
  TextRotationAngledown: 'TextRotationAngledown',
  TextRotationAngleup: 'TextRotationAngleup',
  TextRotationDown: 'TextRotationDown',
  TextRotationNone: 'TextRotationNone',
  Textsms: 'Textsms',
  TextSnippet: 'TextSnippet',
  Texture: 'Texture',
  TheaterComedy: 'TheaterComedy',
  Theaters: 'Theaters',
  Thermostat: 'Thermostat',
  ThermostatAuto: 'ThermostatAuto',
  ThirteenMp: 'ThirteenMp',
  ThirtyFps: 'ThirtyFps',
  ThirtyFpsSelect: 'ThirtyFpsSelect',
  ThreeDRotation: 'ThreeDRotation',
  ThreeGMobiledata: 'ThreeGMobiledata',
  ThreeK: 'ThreeK',
  ThreeKPlus: 'ThreeKPlus',
  ThreeMp: 'ThreeMp',
  ThreeP: 'ThreeP',
  ThreeSixty: 'ThreeSixty',
  ThumbDown: 'ThumbDown',
  ThumbDownAlt: 'ThumbDownAlt',
  ThumbDownOffAlt: 'ThumbDownOffAlt',
  ThumbsUpDown: 'ThumbsUpDown',
  ThumbUp: 'ThumbUp',
  ThumbUpAlt: 'ThumbUpAlt',
  ThumbUpOffAlt: 'ThumbUpOffAlt',
  Thunderstorm: 'Thunderstorm',
  Timelapse: 'Timelapse',
  Timeline: 'Timeline',
  Timer: 'Timer',
  Timer10: 'Timer10',
  Timer10Select: 'Timer10Select',
  Timer3: 'Timer3',
  Timer3Select: 'Timer3Select',
  TimerOff: 'TimerOff',
  TimesOneMobiledata: 'TimesOneMobiledata',
  TimeToLeave: 'TimeToLeave',
  TipsAndUpdates: 'TipsAndUpdates',
  TireRepair: 'TireRepair',
  Title: 'Title',
  Toc: 'Toc',
  Today: 'Today',
  ToggleOff: 'ToggleOff',
  ToggleOn: 'ToggleOn',
  Token: 'Token',
  Toll: 'Toll',
  Tonality: 'Tonality',
  Topic: 'Topic',
  Tornado: 'Tornado',
  TouchApp: 'TouchApp',
  Tour: 'Tour',
  Toys: 'Toys',
  TrackChanges: 'TrackChanges',
  Traffic: 'Traffic',
  Train: 'Train',
  Tram: 'Tram',
  TransferWithinAStation: 'TransferWithinAStation',
  Transform: 'Transform',
  Transgender: 'Transgender',
  TransitEnterexit: 'TransitEnterexit',
  Translate: 'Translate',
  TravelExplore: 'TravelExplore',
  TrendingDown: 'TrendingDown',
  TrendingFlat: 'TrendingFlat',
  TrendingUp: 'TrendingUp',
  TripOrigin: 'TripOrigin',
  Try: 'Try',
  Tsunami: 'Tsunami',
  Tty: 'Tty',
  Tune: 'Tune',
  Tungsten: 'Tungsten',
  TurnedIn: 'TurnedIn',
  TurnedInNot: 'TurnedInNot',
  TurnLeft: 'TurnLeft',
  TurnRight: 'TurnRight',
  TurnSlightLeft: 'TurnSlightLeft',
  TurnSlightRight: 'TurnSlightRight',
  Tv: 'Tv',
  TvOff: 'TvOff',
  TwelveMp: 'TwelveMp',
  TwentyFourMp: 'TwentyFourMp',
  TwentyOneMp: 'TwentyOneMp',
  TwentyThreeMp: 'TwentyThreeMp',
  TwentyTwoMp: 'TwentyTwoMp',
  TwentyZeroMp: 'TwentyZeroMp',
  Twitter: 'Twitter',
  TwoK: 'TwoK',
  TwoKPlus: 'TwoKPlus',
  TwoMp: 'TwoMp',
  TwoWheeler: 'TwoWheeler',
  Umbrella: 'Umbrella',
  Unarchive: 'Unarchive',
  Undo: 'Undo',
  UnfoldLess: 'UnfoldLess',
  UnfoldMore: 'UnfoldMore',
  Unpublished: 'Unpublished',
  Unsubscribe: 'Unsubscribe',
  Upcoming: 'Upcoming',
  Update: 'Update',
  UpdateDisabled: 'UpdateDisabled',
  Upgrade: 'Upgrade',
  Upload: 'Upload',
  UploadFile: 'UploadFile',
  Usb: 'Usb',
  UsbOff: 'UsbOff',
  UTurnLeft: 'UTurnLeft',
  UTurnRight: 'UTurnRight',
  Vaccines: 'Vaccines',
  VapeFree: 'VapeFree',
  VapingRooms: 'VapingRooms',
  Verified: 'Verified',
  VerifiedUser: 'VerifiedUser',
  VerticalAlignBottom: 'VerticalAlignBottom',
  VerticalAlignCenter: 'VerticalAlignCenter',
  VerticalAlignTop: 'VerticalAlignTop',
  VerticalShades: 'VerticalShades',
  VerticalShadesClosed: 'VerticalShadesClosed',
  VerticalSplit: 'VerticalSplit',
  Vibration: 'Vibration',
  VideoCall: 'VideoCall',
  Videocam: 'Videocam',
  VideoCameraBack: 'VideoCameraBack',
  VideoCameraFront: 'VideoCameraFront',
  VideocamOff: 'VideocamOff',
  VideoFile: 'VideoFile',
  VideogameAsset: 'VideogameAsset',
  VideogameAssetOff: 'VideogameAssetOff',
  VideoLabel: 'VideoLabel',
  VideoLibrary: 'VideoLibrary',
  VideoSettings: 'VideoSettings',
  VideoStable: 'VideoStable',
  ViewAgenda: 'ViewAgenda',
  ViewArray: 'ViewArray',
  ViewCarousel: 'ViewCarousel',
  ViewColumn: 'ViewColumn',
  ViewComfy: 'ViewComfy',
  ViewComfyAlt: 'ViewComfyAlt',
  ViewCompact: 'ViewCompact',
  ViewCompactAlt: 'ViewCompactAlt',
  ViewCozy: 'ViewCozy',
  ViewDay: 'ViewDay',
  ViewHeadline: 'ViewHeadline',
  ViewInAr: 'ViewInAr',
  ViewKanban: 'ViewKanban',
  ViewList: 'ViewList',
  ViewModule: 'ViewModule',
  ViewQuilt: 'ViewQuilt',
  ViewSidebar: 'ViewSidebar',
  ViewStream: 'ViewStream',
  ViewTimeline: 'ViewTimeline',
  ViewWeek: 'ViewWeek',
  Vignette: 'Vignette',
  Villa: 'Villa',
  Visibility: 'Visibility',
  VisibilityOff: 'VisibilityOff',
  VoiceChat: 'VoiceChat',
  Voicemail: 'Voicemail',
  VoiceOverOff: 'VoiceOverOff',
  Volcano: 'Volcano',
  VolumeDown: 'VolumeDown',
  VolumeMute: 'VolumeMute',
  VolumeOff: 'VolumeOff',
  VolumeUp: 'VolumeUp',
  VolunteerActivism: 'VolunteerActivism',
  VpnKey: 'VpnKey',
  VpnKeyOff: 'VpnKeyOff',
  VpnLock: 'VpnLock',
  Vrpano: 'Vrpano',
  Wallpaper: 'Wallpaper',
  Warehouse: 'Warehouse',
  Warning: 'Warning',
  WarningAmber: 'WarningAmber',
  Wash: 'Wash',
  Watch: 'Watch',
  WatchLater: 'WatchLater',
  WatchOff: 'WatchOff',
  Water: 'Water',
  WaterDamage: 'WaterDamage',
  WaterfallChart: 'WaterfallChart',
  Waves: 'Waves',
  WbAuto: 'WbAuto',
  WbCloudy: 'WbCloudy',
  WbIncandescent: 'WbIncandescent',
  WbIridescent: 'WbIridescent',
  WbShade: 'WbShade',
  WbSunny: 'WbSunny',
  WbTwilight: 'WbTwilight',
  Wc: 'Wc',
  Web: 'Web',
  WebAsset: 'WebAsset',
  WebAssetOff: 'WebAssetOff',
  Webhook: 'Webhook',
  Weekend: 'Weekend',
  West: 'West',
  WhatsApp: 'WhatsApp',
  Whatshot: 'Whatshot',
  WheelchairPickup: 'WheelchairPickup',
  WhereToVote: 'WhereToVote',
  Widgets: 'Widgets',
  Wifi: 'Wifi',
  Wifi1Bar: 'Wifi1Bar',
  Wifi2Bar: 'Wifi2Bar',
  WifiCalling: 'WifiCalling',
  WifiCalling3: 'WifiCalling3',
  WifiChannel: 'WifiChannel',
  WifiFind: 'WifiFind',
  WifiLock: 'WifiLock',
  WifiOff: 'WifiOff',
  WifiPassword: 'WifiPassword',
  WifiProtectedSetup: 'WifiProtectedSetup',
  WifiTethering: 'WifiTethering',
  WifiTetheringError: 'WifiTetheringError',
  WifiTetheringOff: 'WifiTetheringOff',
  Window: 'Window',
  WindPower: 'WindPower',
  WineBar: 'WineBar',
  Woman: 'Woman',
  Work: 'Work',
  WorkHistory: 'WorkHistory',
  WorkOff: 'WorkOff',
  WorkOutline: 'WorkOutline',
  WorkspacePremium: 'WorkspacePremium',
  Workspaces: 'Workspaces',
  WrapText: 'WrapText',
  WrongLocation: 'WrongLocation',
  Wysiwyg: 'Wysiwyg',
  Yard: 'Yard',
  YouTube: 'YouTube',
  YoutubeSearchedFor: 'YoutubeSearchedFor',
  ZoomIn: 'ZoomIn',
  ZoomInMap: 'ZoomInMap',
  ZoomOut: 'ZoomOut',
  ZoomOutMap: 'ZoomOutMap'
};

export const DELIVERABLES = ['Hours', 'Dollars'];

export const DELIVERABLE_OPTIONS: SelectOption[] = DELIVERABLES.map(
  (value, index) => ({
    id: index,
    label: value
  })
);

export const DELIVERABLE_MAP = DELIVERABLES.reduce(
  (result, next, index) => ({
    ...result,
    [index]: next
  }),
  {}
);

export const DELIVERABLE_MAP_INVERTED = _.invert(DELIVERABLE_MAP);

// color names provided by: https://www.color-name.com/hex
export enum Color {
  MEXICAN_PINK = '#ec008c',
  VIOLET_CRAYOLA = '#92278f',
  REBECCA_PURPLE = '#662d91',
  COBALT_BLUE = '#0054a6',
  PERSIAN_GREEN = '#00a99d',
  GREEN_PIGMENT = '#00a651',
  YELLOW_GREEN = '#8dc63f',
  YELLOW_ROSE = '#fff200',
  BRIGHT_YELLOW_CRAYOLA = '#f7ad19'
}

export const CLIENT_COLORS = [
  Color.MEXICAN_PINK,
  Color.VIOLET_CRAYOLA,
  Color.REBECCA_PURPLE,
  Color.COBALT_BLUE,
  Color.PERSIAN_GREEN,
  Color.GREEN_PIGMENT,
  Color.YELLOW_GREEN,
  Color.YELLOW_ROSE,
  Color.BRIGHT_YELLOW_CRAYOLA
];

export const MUI_TEXT_FIELD_DATE_FORMAT = 'YYYY-MM-DD';

export const OVERDUE_TIMESHEET_APPROVAL = 'OVERDUE_TIMESHEET_APPROVAL';

export const notificationResourceTypes = {
  INVOICE: 'INVOICE',
  TIMESHEET: 'TIMESHEET',
  PROJECT: 'PROJECT',
  PROJECT_ROLE: 'PROJECT_ROLE'
};
